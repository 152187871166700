import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CalculateHeader as Header, FormFooter, Text, Link } from "components";
import { merge } from "lodash";
import { useHistory } from "react-router-dom";
import { getSubscriptionPrices } from "services/api";

const Wrapper = styled.section`
  display: grid;
  height: 100%;
  place-items: center;
`;

const GridWrapper = styled.div`
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 15px;
`;

const BoxWrapper = styled.div`
  margin-top: 15px;
  width: 316px;
  border-radius: 15px;
  height: 80px;
  overflow: hidden;
  display: flex;
`;

const BoxLeftWrapper = styled.div`
  background-color: #00a19b;
  width: 117px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const BoxRightWrapper = styled.div`
  background-color: rgba(81, 165, 162, 0.15);
  width: 199px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 10px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: ${({ row }) => (row ? "row" : "column")};
  justify-content: center;
  align-items: ${({ center }) => (center ? "center" : "flex-start")};
  min-width: 216px;
  max-width: ${({ small }) => (small ? "300px" : "480px")};
`;

const subscriptionDetails = {
  Individual: {
    tier: 1,
    price: "$199.99",
  },
  Household: {
    tier: 2,
    price: "$349.99",
  },
  Business: {
    tier: 3,
    price: "$699.99",
  },
};

const OnboardingPaymentSection = ({
  _form,
  report,
  handleNext,
  handleChangeStep,
}) => {
  const history = useHistory();
  const [agree, setAgree] = useState(false);

  const [subscriptionPrices, setSubscriptionPrices] = useState(['','','']);

  const isPaid = report.isPaid;
  const subscription = report.forBusiness
    ? "Business"
    : report.type !== "individual"
    ? "Household"
    : "Individual";

  useEffect(() => {
    if (isPaid) {
      const updatedForm = merge({}, _form, { onboardingStep: "complete" });
      handleNext(updatedForm);
    }
  }, [isPaid, _form, handleNext]);

  const handleRedirectToCheckout = () => {
    history.push({
      pathname: "/checkout",
      subscriptionTier: report.forBusiness || report.type,
    });
  };

  const handleBack = () => {
    handleChangeStep("review");
  };

  useEffect( async ()=> {
    try{
      const {data: stripeSubscriptionPrices} = await getSubscriptionPrices();
      if(stripeSubscriptionPrices){
        setSubscriptionPrices(stripeSubscriptionPrices)
      }
    } catch(e){
    }
  }, [])

  return (
    <Wrapper>
      <Header
        bigTitle={true}
        title={
          isPaid
            ? "You’re all set – thank you for sending us your information!"
            : "Subscribe to Receive Your Recommendation"
        }
      />
      {isPaid ? (
        <GridWrapper>
          <TextWrapper center>
            <Text green center fontSize="14">
              We will review your details, contact you if we have any additional
              questions, and let you know when your recommendation is ready.
            </Text>
          </TextWrapper>
          <br></br>
          <TextWrapper center>
            <Text blue center large>
              Total cost: $0
            </Text>
          </TextWrapper>
          <br></br>
          <TextWrapper center>
            <Text blue center fontSize="12">
              Since you remained on the same plan, there were no charges applied
              to your account.
            </Text>
          </TextWrapper>
          <br></br>
          <br></br>
          <TextWrapper center>
            <Link to="/recommendations" blue>
              Visit My Recommendations Home
            </Link>
          </TextWrapper>
        </GridWrapper>
      ) : (
        <GridWrapper>
          <TextWrapper center>
            <Text green center fontSize="14">
              Based on your responses, we think you are interested in a
              Household Account. Please confirm and proceed to checkout.
            </Text>
          </TextWrapper>
          <BoxWrapper>
            <BoxLeftWrapper>
              <Text white fontSize="16" bold>
                {subscription}
              </Text>
            </BoxLeftWrapper>
            <BoxRightWrapper>
              <Text green bold fontSize="16">
                {subscriptionDetails[subscription].price}
              </Text>
              <Text green fontSize="14">
                unlimited recommendations
              </Text>
              <Text green fontSize="14">
                per year
              </Text>
            </BoxRightWrapper>
          </BoxWrapper>
          <br />

          <TextWrapper row small>
            <input
              id="subscription agreement"
              type="checkbox"
              onClick={() => setAgree(!agree)}
              value={agree}
            />
            <label for="subscription agreement">
              <Text blue fontSize="12" padding="0 6px" lineHeight="15.08px">
                By clicking this box, I understand that the fee listed above is
                solely for predictaBill's services of examining or reviewing my
                (our) insurance coverage, and I agree to pay the fee listed
                above in exchange for predictaBill's services.
              </Text>
            </label>
          </TextWrapper>
        </GridWrapper>
      )}
      {!isPaid && (
        <FormFooter
          leftAction={handleBack}
          mainAction={handleRedirectToCheckout}
          mainActionActive={agree}
        />
      )}
    </Wrapper>
  );
};

export default OnboardingPaymentSection;
