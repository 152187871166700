import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import Text from "components/atoms/Text";
import CheckboxField from "components/molecules/CheckboxField";
import { Space } from "antd";
import { familyTypeOptions, networkOptions, OutOfNetworkCoverageOptions, yesOrNoOptions } from "../utils/options";
import TableField from "components/molecules/TableField";
import TableMenu from "components/molecules/TableMenu";
import { handleFamilyType } from "../utils/table";

const columns = [
  {
    title: "",
    dataIndex: "idx",
    width: 20,
    editable: false,
    fixed: "left",
  },
  {
    title: "Label",
    dataIndex: "label",
    width: 133,
    editable: true,
    inputType: "text",
    shouldCellUpdate: () => false,
    fixed: "left",
    disabled: true,
  },
  {
    title: "Insurance Network",
    dataIndex: "insuranceNetwork",
    className: "insuranceNetwork-col",
    width: 200,
    editable: true,
    inputType: "autocomplete",
    dropdownMatchSelectWidth: 350,
    options: networkOptions,
    shouldCellUpdate: () => false,
    disabled: true,
  },
  {
    title: "Out-of-Network Coverage",
    dataIndex: "outOfNetworkCoverage",
    width: 110,
    editable: true,
    inputType: "select",
    dropdownMatchSelectWidth: 200,
    options: OutOfNetworkCoverageOptions,
    shouldCellUpdate: () => false,
    disabled: true,
  },
  {
    title: "Out-of-Network and In-Network Limits Cross-Accumulate",
    dataIndex: "outOfNetworkAndInNetworkCrossAccumulate",
    width: 110,
    editable: true,
    inputType: "select",
    options: yesOrNoOptions,
    shouldCellUpdate: () => false,
  },
  {
    title: "Family Deductible Type",
    dataIndex: "familyType",
    width: 135,
    editable: true,
    inputType: "select",
    options: familyTypeOptions,
    disableCondition: "hasinNetworkIndividualInFamily",
    shouldCellUpdate: () => false,
  },
  {
    title: "Family OOP Limit Type",
    dataIndex: "familyOOPType",
    width: 135,
    editable: true,
    inputType: "select",
    options: familyTypeOptions,
    disableCondition: "hasinNetworkIndividualInFamilyOOP",
    shouldCellUpdate: () => false,
  },
  {
    title: "",
    key: "operation",
    fixed: "right",
    width: 54,
    render: (_, record) => <TableMenu step={8} record={record} />,
  },
].map((col) => {
  return {
    ...col,
    onCell: (_, idx) => ({
      index: idx,
      editable: col.editable,
      dataIndex: col.dataIndex,
      title: col.title,
      inputType: col.inputType,
      options: col.options,
      dropdownMatchSelectWidth: col.dropdownMatchSelectWidth,
      disabled: col.disabled || false,
      emptyValue: col.emptyValue,
      disableCondition: col.disableCondition,
    }),
  };
});

const filteredColumns = columns.filter((column) => !column?.dataIndex?.includes("IndividualInFamily"));

const Step = () => {
  const { values, setErrors, setTouched, setFieldValue } = useFormikContext();
  const { table, showIndividualInFamilyOOP, planOptions, showNotSoSureAboutThese } = values;

  useEffect(() => {
    document.getElementById("form-content").scrollTo({ top: 0, behavior: "smooth" });
    setErrors({});
    setTouched({}, false);
  }, []);

  useEffect(() => {
    const data = handleFamilyType(table);
    setFieldValue("table", data);
  }, []);

  const data = table.slice(0, planOptions);

  return (
    <>
      <Text sectionTitle>Advanced Deductibles and Limits</Text>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Text formLabel>
          When you are on a family plan, do you have to hit the entire family deductible yourself? If so, then you have
          a non-embedded deductible. When you hit your in-network deductible, does that count towards your
          out-of-network deductible too? If so, then your deductibles cross-accumulate.
        </Text>
        {showNotSoSureAboutThese && <CheckboxField name="notSureAboutThese" label="I'm not sure about these" />}

        <TableField
          name="table"
          columns={showIndividualInFamilyOOP ? columns : filteredColumns}
          dataSource={data}
          scroll={{ x: showIndividualInFamilyOOP ? "80vw" : false }}
          tableLayout="fixed"
        />
      </Space>
    </>
  );
};

export default Step;
