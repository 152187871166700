import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/report/actions";
import { CheckboxGroup } from "../components/CheckboxGroup";

export const CategoriesFilter = ({ categoriesOptions }) => {
  const dispatch = useDispatch();
  const selectedCategory = useSelector((state) => state.report.selectedCategory);

  if (!categoriesOptions?.length) return null;

  return (
    <CheckboxGroup
      label="Categories"
      options={categoriesOptions?.map((option) => ({ value: option, label: option }))}
      onChange={(value) => {
        dispatch(
          Actions.Creators.switchState({
            selectedCategory: value,
          }),
        );
      }}
      value={selectedCategory}
      hasSelectionHelpers
    />
  );
};
