import React from "react";
import { Tooltip } from "antd";
import { TableRow, TableCell, Currency, Text } from "components";

const TableHeader = ({
  isDoctorInNetwork,
  date,
  practice,
  hospital,
  insuranceRates,
  procedures,
  chargesWithInsurance,
  chargesPayCompletely,
  totalCharges,
  bills,
}) => {
  const toolTipText = (procedures, number, name) => (
    <Tooltip placement="topLeft" title={<Text>{procedures}</Text>}>
      <TableCell big short className={name}>
        {procedures.substr(0, number) + "..."}
      </TableCell>
    </Tooltip>
  );

  return (
    <tbody>
      <TableRow color="green">
        <TableCell white />
        <TableCell ms className="mobile-hide">
          {date}
        </TableCell>
        {practice && practice.length > 23 ? (
          toolTipText(practice, 23, "mobile-hide")
        ) : (
          <TableCell big short>
            {practice}
          </TableCell>
        )}

        <TableCell big short className="mobile-hide">
          {hospital}
        </TableCell>

        {procedures && procedures.length > 23 ? (
          toolTipText(procedures, 23, "mobile-hide")
        ) : (
          <TableCell big short className="mobile-hide">
            {procedures}
          </TableCell>
        )}
        <TableCell className="mobile-hide">{insuranceRates}</TableCell>

        <TableCell center className="mobile-hide">
          {isDoctorInNetwork}
        </TableCell>

        <TableCell right className="mobile-hide">
          {chargesWithInsurance > 0 ? (
            <Currency price={chargesWithInsurance} />
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell right className="mobile-hide">
          {chargesPayCompletely > 0 ? (
            <Currency price={chargesPayCompletely} />
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell right>
          {totalCharges > 0 ? <Currency price={totalCharges} /> : "-"}
        </TableCell>
        <TableCell />
      </TableRow>
    </tbody>
  );
};

export default TableHeader;
