import React from "react";
import styled from "styled-components";
import { Text } from "components";

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: white;
  height: 60px;
  box-shadow: 0px -2px 8px rgba(33, 52, 91, 0.5);
  z-index: 2;
`;

const FlexWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;

  padding: 10px 5px;
`;

const MainButton = styled.button`
  background-color: ${({ active }) =>
    active ? "#1c355e" : "rgba(146, 146, 146, 0.37)"};
  border-radius: 30px;
  border: none;
  outline: none;
  padding: 4px 30px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  justify-self: center;

  &:hover {
    background-color: ${({ active }) =>
      active ? "#1c355e" : "rgba(146, 146, 146, 0.37)"};
    cursor: ${({ active }) => active && "pointer"};
  }
`;

const LeftButton = styled.button`
  background: none;
  border: none;
  outline: none;
  padding: 4px 30px;
  height: 38px;
  cursor: pointer;
  width: 200px;
`;

const RightButton = styled.button`
  background: #1c355e;
  border-radius: 30px;
  border: none;
  outline: none;
  padding: 4px 10px;
  height: 38px;
  cursor: pointer;
  width: 200px;

  &:hover {
    background-color: "#1c355e";
    cursor: "pointer";
  }
`;

const FormFooter = ({
  leftAction,
  leftText = "< Back",
  mainAction,
  mainActionActive,
  mainText = "Next >",
  rightAction,
  rightText,
}) => {
  return (
    <Wrapper>
      <FlexWrapper>
        {leftAction ? (
          <LeftButton onClick={leftAction}>
            <Text blue left fontSize="16">
              {leftText}
            </Text>
          </LeftButton>
        ) : (
          <div></div>
        )}
        {mainAction ? (
          <MainButton active={mainActionActive} onClick={mainAction}>
            <Text white center fontSize="24">
              {mainText}
            </Text>
          </MainButton>
        ) : (
          <div></div>
        )}
        {rightAction ? (
          <RightButton onClick={rightAction}>
            <Text white center fontSize="16">
              {rightText}
            </Text>
          </RightButton>
        ) : (
          <div></div>
        )}
      </FlexWrapper>
    </Wrapper>
  );
};

export default FormFooter;
