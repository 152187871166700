import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { PageTemplate, Header, GroupVerificationSteps } from "components";
import { getGroup } from "services/api/groups.api";

const GroupVerificationPage = () => {
  const history = useHistory();
  const { search, state } = useLocation();
  const groupFromQuery = new URLSearchParams(search).get("group");
  const [titleFromGroup, setTitleFromGroup] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (groupFromQuery) {
      getGroup({ name: groupFromQuery }).then(res => {
        if (res.status === 200) {
          setTitleFromGroup(res.data.group.displayName)
        }
        setLoading(false)
      })
    }
  }, [])

  localStorage.removeItem("workEmail")
  localStorage.removeItem("jobOfferEmail")
  const { group, title } = state || {};

  if (!group && (!loading && !titleFromGroup)) {
    history.push("/groups");
  }

  return (
    <PageTemplate padding="0 12.5%" header={<Header V2 />}>
      {(title || titleFromGroup) && (<GroupVerificationSteps title={titleFromGroup ? titleFromGroup : title} group={groupFromQuery ? groupFromQuery : group} />)}
    </PageTemplate>
  )
}

export default GroupVerificationPage;
