import {
  put,
  takeLatest,
  call,
  select,
  takeEvery,
  fork,
} from "redux-saga/effects";
import Actions from "./actions";
import ActionsForm from "../formData/actions";
import { maxBy } from "lodash";
import { sendForm, sendPremiumForm } from "../formData/sagas";
import { deliveryTypes } from "../formData";
import { getAllOptionsForm } from "services/api";
import { loadBills, loadSummary } from "../bills/sagas";
import { removeTokenSaga, removeTokenWithoutToken } from "../auth/sagas";
import { getToken, isValidToken } from "services/utils";
import { EventOne, EventSelect, EventPremiumSelect } from "services/google";
import {
  loadSelectOptionInsurance,
  loadGroups,
  loadGroupsPremium,
  loadSelectOptionPremiumInsurance,
} from "./workflow.sagas";
import { Fields } from "enums/google.enum";
import { defaultOptions } from "./defaultSaga";
import history from "../history";

const {
  Types: {
    CHANGE_BIRTH_PLAN,
    CHANGE_BIRTH_PLAN_OPTIONS,
    CHANGE_INSURANCE_PLAN,
    LOAD_OPTIONS_REQUEST,
    CHANGE_PREV_VALUE_BIRTH,
    CHANGE_PREMIUM_PLAN,
    CHANGE_CURRENT,
  },
  Creators,
} = Actions;

export function* watchLoadOptions() {
  yield takeEvery(LOAD_OPTIONS_REQUEST, function* () {
    try {
      yield put(Creators.resetStorePlan());
      yield put(ActionsForm.Creators.calculateFailure());
      const token = yield call(getToken);
      const isValid = yield call(isValidToken, token);

      const { pathname } = history.location;
      if (!isValid && pathname !== "/calculator") {
        yield call(removeTokenWithoutToken);
      } else {
        const response = yield call(getAllOptionsForm, token);
        const { doctors, hospitals } = response.data;
        const privateRoomRate = maxBy(
          hospitals,
          "privateRoomRate"
        ).privateRoomRate;
        const deluxe = maxBy(hospitals, "deluxe").deluxe;
        const maxPrice = privateRoomRate < deluxe ? deluxe : privateRoomRate;
        doctors.unshift({
          _id: "all",
          name: "All Doctors",
          isDoctorInNetwork: true,
          practice: {
            hospital: {
              _id: "all",
              name: "All Hospitals",
              isShared: true,
              privateRoomRate: maxPrice,
              deluxe: null,
              upTo: true,
            },
          },
        });
        hospitals.unshift({
          _id: "all",
          name: "All Hospitals",
          isShared: true,
          privateRoomRate: maxPrice,
          deluxe: null,
          upTo: true,
        });
        const { data } = response;
        data.doctors = doctors;
        data.hospitals = hospitals;
        data.deliveryTypes = deliveryTypes;

        yield put(Creators.loadOptionsSuccess(data));
        pathname === "/premium"
          ? yield call(loadGroupsPremium, token)
          : yield call(loadGroups, token);
        yield call(defaultOptions);
        yield call(loadBills);
        yield call(loadSummary);
      }
    } catch (error) {
      yield call(removeTokenSaga, error);
      yield put(Creators.loadOptionsFailure());
    }
  });
}

// Birth Plan Options: Search by Doctor or Hospital?
export function* sendFormBirth() {
  yield takeEvery(CHANGE_BIRTH_PLAN, function* ({ name, value }) {
    const plan = yield select((state) => state.plan);

    if (name === "doctors") {
      yield call(resetDoctorInNetwork, plan, true, name, value);
      yield call(setPracticeId, true, value);
      yield fork(EventSelect, name, value, plan.doctors.data);
    } else if (name === "hospitals") {
      yield call(setPracticeId, false);
      yield fork(EventSelect, name, value, plan.hospitals.data);
      yield call(resetDoctorInNetwork, plan, false, name, value);
    } else yield fork(EventSelect, name, value);
    yield call(changePrevValue, { name, value }, plan);
  });
}

// checks Birth Plan Options - TypeOfDelivery and roomType
export function* watchChangeOptionsBirth() {
  yield takeLatest(CHANGE_BIRTH_PLAN_OPTIONS, function* ({ name, value }) {
    if (name === "deliveryTypes") {
      yield call(loadBills);
    }
    if (name !== "practice") {
      yield fork(EventSelect, name, value);
      yield call(loadSummary);
      yield call(sendForm);
    }
  });
}

export function* sendFormInsurance() {
  yield takeEvery(CHANGE_INSURANCE_PLAN, function* ({ name, value }) {
    yield call(loadSelectOptionInsurance, name, value);
    const plan = yield select((state) => state.plan);
    yield fork(EventSelect, name, value, plan[name] ? plan[name].data : []);
    if (name === "broaderNetworksMySelf") {
      yield call(loadBills);
    } else if (name === "isDoctorInNetwork") {
      yield call(loadBills);
      if (value.value || value.value === false) {
        const planValue = plan.plansNetwork.value;
        const beforeOrAfter = planValue ? "Yes" : "No";
        yield fork(
          EventOne,
          "Form",
          Fields.selectDoctorInNetwork,
          beforeOrAfter
        );
      }
    }
    yield call(loadSummary);
    yield call(sendForm);
  });
}

export function* sendPremiumFormInsurance() {
  yield takeEvery(CHANGE_PREMIUM_PLAN, function* ({ name, value }) {
    yield call(loadSelectOptionPremiumInsurance, name, value);
    const plan = yield select((state) => state.plan);
    yield fork(
      EventPremiumSelect,
      name,
      value,
      plan[name] ? plan[name].data : []
    );
    yield call(loadSummary);
    yield call(sendPremiumForm);
  });
}

function* resetDoctorInNetwork(
  { isDoctorInNetwork, ...dataCur },
  isDoc,
  name,
  value
) {
  const currentSelect = value;
  let data = {
    data: ["No", "Yes"],
    doctors: {
      select: isDoc
        ? dataCur.doctors.prevValue
        : isDoctorInNetwork.doctors.select,
      prevValue: isDoc
        ? isDoctorInNetwork.value
        : isDoctorInNetwork.doctors.prevValue,
    },
    hospitals: {
      select: !isDoc
        ? dataCur.hospitals.prevValue
        : isDoctorInNetwork.hospitals.select,
      prevValue: !isDoc
        ? isDoctorInNetwork.value
        : isDoctorInNetwork.hospitals.prevValue,
    },
    prevValue: isDoctorInNetwork[name].prevValue,
  };

  // data.prevValue = isDoctorInNetwork[name].prevValue;

  if (dataCur.doctors.current && !dataCur.doctors.current.isDoctorInNetwork) {
    data.data = ["No"];
    data.value = false;
  } else {
    data.value =
      isDoctorInNetwork[name].select === currentSelect
        ? isDoctorInNetwork[name].prevValue
        : true;
  }

  yield put(
    Actions.Creators.changeInsurancePlan("isDoctorInNetwork", {
      ...data,
    })
  );
}

function* setPracticeId(isDoc, value) {
  if (!isDoc || value === "all")
    yield put(
      Actions.Creators.changeBirthPlanOptions("practice", {
        _id: null,
        name: null,
      })
    );
  else {
    const getPlan = (state) => state.plan;

    const plan = yield select(getPlan);
    const practice = plan.doctors.data.find(
      ({ _id }) => _id === value
    ).practice;
    yield put(
      Actions.Creators.changeBirthPlanOptions("practice", {
        _id: practice._id,
        name: practice.name,
      })
    );
  }
}

export function* watchChangePrevValue() {
  yield takeLatest(CHANGE_PREV_VALUE_BIRTH, function* () {
    yield call(sendForm);
  });
}

function* changePrevValue({ name, value }, plan) {
  if (name !== "planNames") return;
  const nextName = [value.toLowerCase(), "s"].join("");
  const prevValue = plan[nextName].prevValue;
  const nextValue = plan[nextName].value;
  yield put(Creators.changePrevValueBirth(nextName, nextValue));
  if (prevValue) {
    yield put(Creators.changeBirthPlan(nextName, prevValue));
  }
}
