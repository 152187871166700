import React from "react";

// import { Input, InputNumber } from "antd";
import { useField } from "formik";
import Text from "components/atoms/Text";
import { AutoComplete } from "formik-antd";
import styled, { css } from "styled-components";
import RequiredField from "../../atoms/RequiredField";
import "./styles.css";

const StyledInput = styled(AutoComplete)`
  height: 40px !important;
  width: 50% !important;
  ${({ size }) => size === "small" && `width: 15% !important`};
  ${({ size }) => size === "fullwidth" && `width: 100% !important`};

  .ant-select-selector {
    margin: 0 !important;
    padding: 0 !important;

    .ant-select-selection-search {
      position: initial !important;
      width: 50% !important;
      ${({ size }) => size === "small" && `width: 15% !important`};
      ${({ size }) => size === "fullwidth" && `width: 100% !important`};

      input {
        height: 40px !important;
        font-size: 18px !important;
      }
    }
  }

  div {
    height: 40px !important;

    input {
      height: 40px !important;
    }
  }
`;

const Wrapper = styled.div`
  ${({ inline }) =>
    inline &&
    css`
      display: flex;
      > * {
        margin-right: 10px;
      }
    `}
`;

const AutoCompleteField = ({
  label,
  type,
  name,
  setExpanded,
  condition,
  size,
  inline,
  required,
  options,
  minCharacters = 0,
  ...props
}) => {
  const [field, meta] = useField(name);
  const inputID = `autocomplet-field-${name}`.replace(/\[|\]|\./g, "");

  const matchesOption = (value, option) => {
    if (value?.length < minCharacters) return false;
    return option?.value.toUpperCase().indexOf(value.toUpperCase()) !== -1;
  };

  return (
    <>
      <Wrapper inline={inline}>
        {label && (
          <Text formLabel>
            {label}
            {required && <RequiredField error={meta.touched && meta.error} />}
          </Text>
        )}
        <StyledInput
          {...field}
          {...props}
          value={props.disabled ? field.value?.replace(";", " ") : field.value}
          options={minCharacters === 0 || field.value?.length >= minCharacters ? options : []}
          size={size}
          id={inputID}
          status={meta.touched && meta.error && "error"}
          defaultActiveFirstOption={true}
          onKeyDown={(event) => {
            const isTab = event.key === "Tab";
            if (isTab) {
              // if the current text already matches one of the available options, just passthrough
              // otherwise select the first option if exists
              const matchingOption = options?.find((option) => option.value === event.target.value);
              const doesAPossibleMatchExist = options?.find((option) => matchesOption(event.target.value, option));
              if (!matchingOption && doesAPossibleMatchExist) {
                event.preventDefault();
                const thisList = document.querySelector(`#${inputID}_list`)?.nextSibling;
                const firstOption = thisList?.querySelector(
                  ".ant-select-item.ant-select-item-option.ant-select-item-option-active",
                );
                if (firstOption) {
                  firstOption.click();
                }
              }
            }
          }}
          filterOption={(inputValue, option) => matchesOption(inputValue, option)}
          onSelect={(value) => {
            props.handleOnSelect && props.handleOnSelect(value);
          }}
        />
      </Wrapper>
      {meta?.touched && meta?.error && <Text red>{meta.error}</Text>}
    </>
  );
};

export default AutoCompleteField;
