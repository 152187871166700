import React from "react";
import { Text } from "components";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/plan/actions";
import styled from "styled-components";
import { useFormikContext } from "formik";
import { message, Popconfirm } from "antd";
import {
  validateNetworksTable,
  warnEmptyUrl,
  validateProvidersList,
} from "components/pages/AddNetworksPage/utils/validateSteps/";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: white;
  padding: 4px;
  gap: 4px;
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.2);
  z-index: 2;
`;

const FlexWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 4px;
  align-items: center;
  padding: 0 20px;
`;

const Button = styled.div`
  background-color: ${({ active }) => (active ? "#1c355e" : "rgba(146, 146, 146, 0.37)")};
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 22px !important;
  padding: 26px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  cursor: ${({ active, type }) => (active || type === "back") && "pointer"};
  transition: 0.3s;
  &:hover {
    background-color: ${({ active }) => (active ? "#132541" : "rgba(146, 146, 146, 0.37)")};
  }
  ${({ type }) => type === "back" && "background-color: white; border: 1px solid #1c355e"};

  @media (min-width: 768px) {
    width: 200px;
  }
  @media (max-width: 768px) {
    ${({ grow }) => grow && `flex-grow: 1`}
  }
`;

const AddNetworksFooter = ({ isSubmitting }) => {
  const dispatch = useDispatch();
  const { values, validateForm, submitForm, setFieldTouched, setErrors } = useFormikContext();
  const { networksTable, providersList } = values;

  const current = useSelector((state) => state.plan.current);

  const steps = JSON.parse(JSON.stringify(useSelector((state) => state.plan.networkSteps)));

  const handleSubmit = () => {
    submitForm();
  };

  const messageValidation = (res) => {
    const fields = Object.keys(res);
    if (fields.length) {
      fields.map((field) => setFieldTouched(field));
      message.error("Missing coverage for some required services. Please check dropdown.");
    }
  };

  const advanceStep = () => {
    dispatch(Actions.Creators.changeCurrent(current + 1));
    setErrors({});
  };

  const onValidate = () => {
    if (steps[current].title === "Networks") {
      validateNetworksTable(networksTable) && advanceStep();
    } else if (steps[current].title === "Providers") {
      validateProvidersList(providersList) && advanceStep();
    } else {
      advanceStep();
    }
  };

  return (
    <Wrapper>
      <FlexWrapper>
        {current > 0 && (
          <Button type="back" onClick={() => dispatch(Actions.Creators.changeCurrent(current - 1))}>
            <Text blue center fontSize="24">
              {`< Back`}
            </Text>
          </Button>
        )}

        {current < steps.length - 1 && (
          <Popconfirm
            title="You left some URLs empty. Are you sure you want to continue?"
            disabled={warnEmptyUrl(networksTable).length === 0}
            onConfirm={() => {
              validateForm().then((res) => {
                onValidate(res);
              });
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="next"
              grow
              active={true}
              onClick={() => {
                if (steps[current].title === "Networks") {
                  warnEmptyUrl(networksTable).length === 0 &&
                    validateForm().then((res) => {
                      onValidate(res);
                    });
                } else {
                  validateForm().then((res) => {
                    onValidate(res);
                  });
                }
              }}
            >
              <Text white center fontSize="24">
                {`Next >`}
              </Text>
            </Button>
          </Popconfirm>
        )}
        {current === steps.length - 1 && (
          <Button
            active={true}
            disabled={isSubmitting}
            type="submit"
            grow
            onClick={() => {
              validateForm().then((res) => {
                messageValidation(res);
              });
              handleSubmit();
            }}
          >
            <Text white center fontSize="24">
              {`Done`}
            </Text>
          </Button>
        )}
      </FlexWrapper>
    </Wrapper>
  );
};

export default AddNetworksFooter;
