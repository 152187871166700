/**
 * @typedef OptimizationPlan
 * @property {'separatePlans'|'samePlan'} planType - The recommended plan type
 * @property {'birthParent'|'partner'|null} childrenPlanType - The recommended children to join
 * @property {Object} birthParentPlan - The recommended birth parent plan
 * @property {Object} partnerPlan - The recommended partner plan
 */
/**
 * @returns {OptimizationPlan} - The recommended plans information
 */
export const getRecommendedPlansInformation = (optimization) => {
  if (!optimization?.optimizationDetails) return null;
  const { birthParent, partner, childrenJoin, join } = optimization?.optimizationDetails || {};
  const planType = join === "separate" ? "separatePlans" : "samePlan";

  const birthParentPlan = birthParent;
  const partnerPlan = partner;
  return {
    planType,
    childrenPlanType: childrenJoin,
    birthParentPlan,
    partnerPlan,
  };
};

export const getAllRecommendedPlansInformation = (firstOptimization) => {
  const selected = [firstOptimization?.selected].flat();
  const otherOptimizations = [firstOptimization?.otherOptimizations].flat();
  const allOptimizations = [...selected, ...otherOptimizations];
  const allRecommendedPlans = allOptimizations.map((optimization) => {
    return getRecommendedPlansInformation(optimization);
  });
  return allRecommendedPlans;
};
