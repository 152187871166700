import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  CalculateHeader as Header,
  Text,
  ButtonLink,
  FormInput as Input,
} from "components";
import { Icon } from "@ant-design/compatible";

const Wrapper = styled.section``;

const FlexWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.button`
  border: 3px solid #00a19b;
  border-radius: 25px;
  margin: 15px 12px;
  width: 400px;
  height: 400px;
  outline: none;
  cursor: pointer;
  color: #00a19b;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: #fff;

  ${({ active }) =>
    active
      ? `color: orange; border: 5px solid orange;`
      : `color: #00a19b; border: 3px solid #00a19b;`}
  :focus {
    color: orange;
    border: 5px solid orange;
  }
`;

const QuestionSection = ({ handleNext, onChange }) => {
  const [active, setActive] = useState(null);
  const [question, setQuestion] = useState("");
  const [sample, setSample] = useState("What if our due date isn't this year?");

  useEffect(() => {
    const interval = setInterval(() => {
      if (sample === "What if our due date isn't this year?") {
        setSample("What if we need fertility services?");
      } else if (sample === "What if we need fertility services?") {
        setSample("How does a chronic condition impact costs?");
      } else {
        setSample("What if our due date isn't this year?");
      }
    }, 3000);
    return () => clearInterval(interval);
  });

  const handleChange = ({ target: { name, value } }) => {
    setQuestion(value);
  };

  const handleSubmit = () => {
    onChange("question", question);
    handleNext();
  };

  const handleActive = (e) => {
    setActive(true);
  };

  return (
    <Wrapper>
      <Header
        bigTitle={true}
        title="What is your top question for us?"
        greenText="We'll do our best to answer it!"
      />
      <FlexWrapper>
        <ButtonWrapper onClick={handleActive}>
          <Icon type="message" style={{ fontSize: "40px", padding: "5px" }} />
          {active ? (
            <Input
              placeholder={sample}
              name="question"
              handleChange={handleChange}
              value={question}
              textArea={true}
            />
          ) : (
            <>
              <Text arial bold>
                Type your question(s) for us!
              </Text>
              <Text select>{sample}</Text>
            </>
          )}
        </ButtonWrapper>
      </FlexWrapper>

      <ButtonLink withBG button fontSize="18" onClick={handleSubmit}>
        {question.length > 0 ? "Continue" : "Skip"}
      </ButtonLink>
    </Wrapper>
  );
};

export default QuestionSection;
