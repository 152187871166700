import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DynamicField, DynamicFieldJobOffer } from "components";
import { UploadOutlined } from "@ant-design/icons";
import "./styles.css";
import { Form, Input, Space, Select, Switch, Upload, Button, Tooltip, message, Modal, Checkbox } from "antd";
import { getToken } from "services/utils";
import { uploadEmployeesJSON, importEmployeesCheckHeaders } from "services/api/groups.api";
import { ResultsModal, NewFileFormatModal } from "../../organisms/MyPlansDetailsCards/modals";
import * as XLSX from "xlsx/xlsx.mjs";
import _ from "lodash";
const { Item } = Form;

const CustomModal = styled(Modal)`
  min-width: 60vw;

  @media (min-width: 769px) {
    width: 90vw;
  }

  label {
    width: 100% !important;
  }
`;

const GroupForm = ({
  imageUrl,
  setLogo,
  disableRequiresFollowUpQuestions,
  disableMasterReportsInput,
  employeesAdded,
  currentEmployees,
  newHires,
  employeesRemoved,
  employeesWithWrongRole,
  setEmployeesWithWrongRole,
  setEmployeesAdded,
  setEmployeesRemoved,
  skipEmailVerificationWatch,
  requiresEmailVerificationWatch,
  verifyWorkEmailAgainstDatabaseWatch,
  doesntHaveLinkedinWatch,
  form,
  group,
  reports,
  selectedReportId,
  setSelectedReportId,
  year,
  setYear,
  premiumTiers,
}) => {
  const [modal, setModal] = useState(false);
  const [modalOption, setModalOption] = useState("");
  const [wrongRolesAdjustments, setWrongRolesAdjustments] = useState({});
  const [employeesFileList, setEmployeesFileList] = useState([]);

  const headerList =
    premiumTiers.length > 1
      ? ["Email", "Premium Tier", "Hire Date", "Termination Date"]
      : ["Email", "Hire Date", "Termination Date"];
  const [jsonToSubmit, setJsonToSubmit] = useState([]);
  const [newFileFormatHeaders, setNewFileFormatHeaders] = useState([]);
  const [newFileFormatHeadersAdjustments, setNewFileFormatHeadersAdjustments] = useState({});
  const [dataContainsHeaders, setDataContainsHeaders] = useState(false);

  const handleEINChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, "");

    let formattedValue = "";
    for (let i = 0; i < value.length && i < 9; i++) {
      if (i === 2) {
        formattedValue += "-";
      }
      formattedValue += value[i];
    }

    form.setFieldsValue({ ein: formattedValue });
  };

  function getDataRange(data) {
    const dataWithValues = _.pickBy(data, (value) => !!value.v);
    const cellNamesWithValues = _.keys(dataWithValues);
    const cellsWithValues = cellNamesWithValues.map((cell) => XLSX.utils.decode_cell(cell));
    const maxRow = _.max(cellsWithValues.map((cell) => cell.r));
    const maxColumn = _.max(cellsWithValues.map((cell) => cell.c));
    const lastCellName = XLSX.utils.encode_cell({ c: maxColumn, r: maxRow });
    return `A1:${lastCellName}`;
  }

  const onInsertFile = (event) => {
    const file = event.file;
    setEmployeesFileList([file]);
    if (
      file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      event.fileList.length > 0
    ) {
      file.arrayBuffer().then((res) => {
        const jsonList = [];
        const data = new Uint8Array(res);
        const workbook = XLSX.read(data, { type: "array" });
        workbook.SheetNames.forEach((sheetName) => {
          let worksheet = workbook.Sheets[sheetName];
          worksheet["!ref"] = getDataRange(worksheet);
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false, defval: null });
          jsonList.push(jsonData);
        });
        if (jsonList.length > 0) {
          checkHeaders(jsonList);
        }
      });
    } else if (file.type === "text/csv" && event.fileList.length > 0) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const text = e.target.result;
        const json = csvToJson(text);
        // csvs are always a single tab, but function expects an array of tabs, hence the wrapping
        checkHeaders([json]);
      };

      reader.readAsText(file);
    } else {
      setEmployeesAdded([]);
      setEmployeesRemoved([]);
      setEmployeesWithWrongRole([]);
    }
  };

  const csvToJson = (csv) => {
    const lines = csv.split("\n");
    const result = [];
    const headers = lines[0].split(",");

    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const currentLine = lines[i].split(",");

      headers.forEach((header, j) => {
        obj[header.trim()] = currentLine[j] ? currentLine[j].trim() : "";
      });

      result.push(obj);
    }

    return result;
  };

  const checkHeaders = async (json) => {
    try {
      const token = await getToken();
      setJsonToSubmit(json);
      const { status, data } = await importEmployeesCheckHeaders({ json, token });
      if (status === 200) {
        if (data === "quinnipiac") {
          submitFormJSON(json);
        } else if (data) {
          setModal(true);
          setNewFileFormatHeaders(data);
          setModalOption("new-file-format");
        }
      }
    } catch (e) {
      console.log(e);
      message.error("Error uploading file");
    }
  };

  const submitFormJSON = async (jsonList) => {
    try {
      const token = await getToken();
      const response = await uploadEmployeesJSON({
        json: jsonList,
        name: group.name,
        reportId: selectedReportId,
        year,
        isCensus: false,
        token,
      });
      if (response.status === 200 && response.data) {
        setEmployeesAdded(response.data.employeesAdded);
        setEmployeesRemoved(response.data.employeesRemoved);
        setEmployeesWithWrongRole(response.data.employeesWithWrongRole);
        if (
          response.data.employeesAdded.length +
            response.data.employeesRemoved.length +
            response.data.employeesWithWrongRole.length >
          0
        ) {
          setModal(true);
          setModalOption("results");
        }
      }
    } catch (e) {
      console.log(e);
      message.error("Error uploading file");
    }
  };

  const closeModalCleanStates = () => {
    setModal(false);
    setEmployeesFileList([]);
    setEmployeesAdded([]);
    setEmployeesRemoved([]);
    setEmployeesWithWrongRole([]);
    setNewFileFormatHeaders([]);
    setNewFileFormatHeadersAdjustments({});
  };

  return (
    <>
      <Space direction="horizontal" align="start">
        <div>
          {reports && reports.length > 0 && (
            <Select
              style={{ minWidth: "350px" }}
              popupMatchSelectWidth={500}
              onChange={(value) => {
                if (value?.split(" - ").length === 2) {
                  setSelectedReportId(value.split(" - ")[0]);
                  setYear(value.split(" - ")[1]);
                }
                form.setFieldsValue({
                  jobOfferMap: group.jobOfferMap
                    ? Object.keys(group.jobOfferMap).map((key) => ({
                        email: key,
                        role: group.jobOfferMap[key]?.roles?.[value.split(" - ")[1]],
                      }))
                    : [],
                });
              }}
              value={selectedReportId && year && `${selectedReportId} - ${year}`}
              options={reports.map((report) => ({
                value: `${report._id} - ${report.year}`,
                label: `${report._id} - ${report.year}`,
              }))}
            />
          )}
          <Item
            label="Display Name (name that appears in Groups Page and Search Bar)"
            name="displayName"
            labelCol={{ span: 24 }}
          >
            <Input placeholder="Quinnipiac University" autoComplete="off" />
          </Item>
          <Item
            label="Unique Name (name that we use internally to identify the group and in Typeform)"
            name="name"
            labelCol={{ span: 24 }}
          >
            <Input placeholder="quinnipiac" autoComplete="off" />
          </Item>
          <Item label="Master Report" name="masterReports" labelCol={{ span: 24 }}>
            <Input placeholder="O3bmF_jRy" autoComplete="off" disabled={disableMasterReportsInput} />
          </Item>
          <Item label="Prestige Ranking" name="prestigeRanking" labelCol={{ span: 24 }}>
            <Input type="number" placeholder="1.5" autoComplete="off" />
          </Item>
          {doesntHaveLinkedinWatch ? (
            <Item
              label="Federal Employer Identification Number (EIN)"
              name="ein"
              labelCol={{ span: 24 }}
              style={{ marginBottom: 0 }}
            >
              <Input placeholder="99-9999999" autoComplete="off" onChange={handleEINChange} maxLength={10} />
            </Item>
          ) : (
            <Item label="LinkedIn URL" name="linkedinUrl" labelCol={{ span: 24 }} style={{ marginBottom: 0 }}>
              <Input placeholder="https://www.linkedin.com/company/apple" autoComplete="off" />
            </Item>
          )}
          <Item name="doesntHaveLinkedin" valuePropName="checked" labelCol={{ span: 24 }}>
            <Checkbox className={"has-linkedin-checkbox"}>This company doesn&apos;t have a LinkedIn page</Checkbox>
          </Item>
          <Item label="Logo" name="logo" labelCol={{ span: 24 }}>
            <div style={{ display: "inline" }}>
              <Upload
                maxCount={1}
                beforeUpload={() => false}
                onChange={(event) => {
                  setLogo(event.file);
                }}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
              {imageUrl && <img src={imageUrl} style={{ marginTop: "24px", maxWidth: "60px", maxHeight: "60px" }} />}
            </div>
          </Item>
          <Item
            label="Hide from search page"
            name="hideOnSearchPage"
            valuePropName="checked"
            labelCol={{ span: 6.5 }}
            className={"no-colon"}
          >
            <Switch />
          </Item>
          <Item
            label="Lock Editing on Clones"
            name="lockEditing"
            valuePropName="checked"
            labelCol={{ span: 6.5 }}
            className={"no-colon"}
          >
            <Switch />
          </Item>
          <Item
            label="Requires User to Enter Own Premiums"
            name="requiresUserPremiums"
            valuePropName="checked"
            labelCol={{ span: 6.5 }}
            className={"no-colon"}
          >
            <Switch />
          </Item>
          <Item
            label="Requires Follow-Up Questions to Redirect to Multiple Reports"
            name="requiresFollowUpQuestions"
            valuePropName="checked"
            labelCol={{ span: 6.5 }}
            className={"no-colon"}
          >
            {disableRequiresFollowUpQuestions ? (
              <Tooltip title="This group's multiple reports have not yet been added">
                <Switch disabled={true} checked={false} />
              </Tooltip>
            ) : (
              <Switch />
            )}
          </Item>
          <Item
            label="Require user to enter verification code sent to work email domain"
            name="requiresEmailVerification"
            valuePropName="checked"
            labelCol={{ span: 6.5 }}
            className={"no-colon"}
          >
            {skipEmailVerificationWatch || verifyWorkEmailAgainstDatabaseWatch ? (
              <Tooltip
                title={`${
                  verifyWorkEmailAgainstDatabaseWatch ? "Verify Work Email against database" : "Skip Email Verification"
                }  is turned ON`}
              >
                <Switch disabled={true} checked={false} />
              </Tooltip>
            ) : (
              <Switch />
            )}
          </Item>
          <Item
            label="Include Only Listed Domains"
            name="onlyAllowListedDomains"
            valuePropName="checked"
            labelCol={{ span: 6.5 }}
            className={"no-colon"}
          >
            <Switch />
          </Item>
          <Item
            label="Exclude Known Personal Domains"
            name="excludePersonalDomains"
            valuePropName="checked"
            labelCol={{ span: 6.5 }}
            className={"no-colon"}
          >
            <Switch />
          </Item>
          <Item
            label="Skip work email verification"
            name="skipEmailVerification"
            valuePropName="checked"
            labelCol={{ span: 6.5 }}
            className={"no-colon"}
          >
            {requiresEmailVerificationWatch || verifyWorkEmailAgainstDatabaseWatch ? (
              <Tooltip
                title={`${
                  verifyWorkEmailAgainstDatabaseWatch
                    ? "Verify Work Email against database"
                    : "Requires Email Verification"
                }  is turned ON`}
              >
                <Switch disabled={true} checked={false} />
              </Tooltip>
            ) : (
              <Switch />
            )}
          </Item>
          <Item
            label="Verify work email against database"
            name="verifyWorkEmailAgainstDatabase"
            valuePropName="checked"
            labelCol={{ span: 6.5 }}
            className={"no-colon"}
          >
            {skipEmailVerificationWatch || requiresEmailVerificationWatch ? (
              <Tooltip
                title={`${
                  requiresEmailVerificationWatch ? "Requires Email Verification" : "Skip Email Verification"
                } is turned ON`}
              >
                <Switch disabled={true} checked={false} />
              </Tooltip>
            ) : (
              <Switch />
            )}
          </Item>
          <Item
            label="Verify job offer against database"
            name="verifyJobOfferAgainstDatabase"
            valuePropName="checked"
            labelCol={{ span: 6.5 }}
            className={"no-colon"}
          >
            <Switch />
          </Item>
          <Item
            label="Start on Healthcare Spending Only"
            name="startOnHealthcareSpendingOnly"
            valuePropName="checked"
            labelCol={{ span: 6.5 }}
            className={"no-colon"}
          >
            <Switch />
          </Item>
          {selectedReportId && (
            <Item label="Employees File" name="employeeCSV" labelCol={{ span: 24 }}>
              <div>
                <Upload
                  accept={".csv, .xlsx"}
                  maxCount={1}
                  beforeUpload={() => false}
                  fileList={employeesFileList}
                  onChange={(event) => {
                    onInsertFile(event);
                  }}
                >
                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
                {employeesAdded.length + employeesRemoved.length + employeesWithWrongRole.length > 0 && (
                  <>
                    <Button type="primary" style={{ marginTop: "5px" }} onClick={() => setModal(true)}>
                      See Results
                    </Button>
                    {employeesWithWrongRole.length > 0 && (
                      <div style={{ color: "red", fontSize: "10px" }}>
                        Some rows failed to upload due to errors in file.
                      </div>
                    )}
                  </>
                )}
                {employeesFileList.length > 0 &&
                  employeesAdded.length + employeesRemoved.length + employeesWithWrongRole.length === 0 && (
                    <div style={{ fontSize: "12px" }}>No changes detected in uploaded file.</div>
                  )}
              </div>
            </Item>
          )}
        </div>
        <Space direction="vertical" align="start">
          <Space direction="horizontal" align="start">
            <DynamicField
              name="otherSearchTerms"
              label="Other Search Terms"
              placeholder="JPM, Chase, JPMorgan, JP Morgan"
              tiers={premiumTiers}
            />
            <DynamicField name="otherDomains" label="Valid Email Domain(s)" placeholder="jpmorgan.com, chase.com" />
          </Space>
          {selectedReportId && (
            <DynamicFieldJobOffer
              name="jobOfferMap"
              label="Job Offer Emails"
              placeholder="jim@gmail.com"
              premiumTiers={premiumTiers}
            />
          )}
        </Space>
      </Space>
      {modalOption === "new-file-format" && (
        <NewFileFormatModal
          modal={modal}
          closeModalCleanStates={closeModalCleanStates}
          jsonToSubmit={jsonToSubmit}
          dataContainsHeaders={dataContainsHeaders}
          setDataContainsHeaders={setDataContainsHeaders}
          headerList={headerList}
          newFileFormatHeaders={newFileFormatHeaders}
          newFileFormatHeadersAdjustments={newFileFormatHeadersAdjustments}
          setNewFileFormatHeadersAdjustments={setNewFileFormatHeadersAdjustments}
          submitFormJSON={submitFormJSON}
          premiumTiers={premiumTiers}
        />
      )}

      {modalOption === "results" && (
        <CustomModal
          title="New hires and terminations"
          open={modal}
          destroyOnClose={true}
          onCancel={() => {
            if (employeesWithWrongRole.length === 0) {
              setWrongRolesAdjustments({});
              setModal(false);
            } else {
              setWrongRolesAdjustments({});
              setEmployeesAdded([]);
              setEmployeesRemoved([]);
              setEmployeesWithWrongRole([]);
              setEmployeesFileList([]);
              setModal(false);
            }
          }}
          onOk={() => {
            if (employeesWithWrongRole.length === 0) {
              setWrongRolesAdjustments({});
              setModal(false);
            } else {
              setWrongRolesAdjustments({});
              setEmployeesAdded([]);
              setEmployeesRemoved([]);
              setEmployeesWithWrongRole([]);
              setEmployeesFileList([]);
              setModal(false);
            }
          }}
          okButtonProps={{ disabled: employeesWithWrongRole.length > 0 }}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <div>
            {employeesWithWrongRole.length > 0 && (
              <div style={{ marginBottom: "20px" }}>
                <h3>Please confirm which premium tiers the following text from your file refers to</h3>
                {[...new Set(employeesWithWrongRole.map((employee) => employee.role))].map((role) => (
                  <div key={role}>
                    <div style={{ display: "inline-flex", alignItems: "center", marginBottom: "5px", width: "100%" }}>
                      <div style={{ width: "300px" }}>{role}</div>
                      <Select
                        style={{ width: "100%", maxWidth: "350px", marginLeft: "5px" }}
                        popupMatchSelectWidth={500}
                        onChange={(value) => {
                          setWrongRolesAdjustments({ ...wrongRolesAdjustments, [role]: value });
                        }}
                        options={premiumTiers.map((tier) => ({ value: tier, label: tier }))}
                      />
                    </div>
                  </div>
                ))}
                <Button
                  type="primary"
                  disabled={Object.keys(wrongRolesAdjustments).length === 0}
                  style={{ marginBottom: "20px" }}
                  onClick={() => {
                    const newEmployeesAdded = employeesAdded.map((employee) => {
                      if (Object.keys(wrongRolesAdjustments).includes(employee.role)) {
                        return {
                          ...employee,
                          role: wrongRolesAdjustments[employee.role],
                          roles: { ...employee.roles, [year]: wrongRolesAdjustments[employee.role] },
                        };
                      }
                      return employee;
                    });
                    const newEmployeesRemoved = employeesRemoved.map((employee) => {
                      if (Object.keys(wrongRolesAdjustments).includes(employee.role)) {
                        return {
                          ...employee,
                          role: wrongRolesAdjustments[employee.role],
                          roles: { ...employee.roles, [year]: wrongRolesAdjustments[employee.role] },
                        };
                      }
                      return employee;
                    });
                    setEmployeesAdded(newEmployeesAdded);
                    setEmployeesRemoved(newEmployeesRemoved);
                    setEmployeesWithWrongRole(
                      employeesWithWrongRole.filter(
                        (employee) => !Object.keys(wrongRolesAdjustments).includes(employee.role),
                      ),
                    );
                  }}
                >
                  Apply
                </Button>
                <h2 style={{ marginBottom: "5px" }}>Errors</h2>
                {employeesWithWrongRole.map((employee, idx) => (
                  <div key={idx}>
                    {employee.email} - {employee.role} - {employee.date}
                  </div>
                ))}
              </div>
            )}
            {newHires.length > 0 && (
              <div style={{ marginBottom: "20px" }}>
                <h2 style={{ marginBottom: "5px" }}>New Hires</h2>
                {newHires.map((employee, idx) => (
                  <div key={idx}>
                    {employee.email} - {employee.role} - {employee.date}
                  </div>
                ))}
              </div>
            )}
            {currentEmployees.length > 0 && (
              <div style={{ marginBottom: "20px" }}>
                <h2 style={{ marginBottom: "5px" }}>Current Employees</h2>
                {currentEmployees.map((employee, idx) => (
                  <div key={idx}>
                    {employee.email} - {employee.role} - {employee.date}
                  </div>
                ))}
              </div>
            )}
            {employeesRemoved.length > 0 && (
              <div style={{ marginBottom: "20px" }}>
                <h2 style={{ marginBottom: "5px" }}>Terminations</h2>
                {employeesRemoved.map((employee, idx) => (
                  <div key={idx}>
                    {employee.email} - {employee.role} - {employee.date}
                  </div>
                ))}
              </div>
            )}
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default GroupForm;
