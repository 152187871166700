import React, { useEffect, useState } from "react";

import { getProfile } from "services/api/account.api";
import { getToken } from "services/utils";

import { useDebounce } from "components";

import { Form, Input, Button, Select, message, Modal } from "antd";
import { accountRegister } from "services/api";

const { Item } = Form;
const { Option } = Select;

const UserSearch = ({ disable, handleChange }) => {
  const [form] = Form.useForm();

  const onSubmit = async ({ searchBy, search }) => {
    const token = await getToken();

    const response =
      searchBy === "id" ? await getProfile(search, token) : await getProfile(null, token, search.toLowerCase());

    const { status, data } = response;
    if (status === 200) {
      const user = data.user;
      handleChange({
        userId: user._id,
        user,
      });
    } else {
      message.error("User not found");
    }
  };

  const onClear = () => {
    form.resetFields();
    handleChange({
      userId: null,
      user: null,
    });
  };

  const onFail = () => {
    console.log("Invalid Submission");
  };

  return (
    <Form form={form} layout="inline" onFinish={onSubmit} onFinishFailed={onFail} initialValues={{ searchBy: "email" }}>
      <Item label="Search By" name="searchBy" rules={[{ required: true, message: "Item required!" }]}>
        <Select style={{ width: "100px" }}>
          <Option value="email">Email</Option>
          <Option value="id">Id</Option>
        </Select>
      </Item>

      <Item name="search" rules={[{ required: true, message: "ID required!" }]}>
        <Input />
      </Item>

      <Item>
        <Button disabled={disable} type="primary" htmlType="submit">
          Search
        </Button>
      </Item>

      {disable && (
        <Item>
          <Button onClick={onClear}>Reset</Button>
        </Item>
      )}
      <CreateUserModal />
    </Form>
  );
};

export default UserSearch;

const CreateUserModal = (props) => {
  const [createUserForm] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);

  const handleSubmit = async ({ username, confirmUsername }) => {
    if (username && username === confirmUsername) {
      try {
        const token = await getToken();
        const response = await accountRegister(username, token);
        if (response.status === 200) {
          message.success("User created successfully");
          handleCancel();
        } else {
          message.error(response.data.message || "Failed to create a user");
        }
      } catch (e) {
        message.error("Failed to create a user");
      }
    }
  };

  const handleCancel = () => {
    createUserForm.resetFields();
    setModalVisible(false);
  };

  return (
    <>
      <Button onClick={() => setModalVisible(!modalVisible)} type="primary">
        Create User
      </Button>
      <Modal
        title="Create User"
        visible={modalVisible}
        onCancel={handleCancel}
        footer={[
          <Button form="createUserForm" type="primary" key="submit" htmlType="submit">
            Submit
          </Button>,
        ]}
      >
        <Form id="createUserForm" layout="vertical" onFinish={handleSubmit} form={createUserForm}>
          <Item
            type="email"
            label="User Email"
            name="username"
            rules={[{ required: true, message: "Username required!" }]}
          >
            <Input autocomplete="off" />
          </Item>
          <Item
            type="email"
            label="Confirm Email"
            name="confirmUsername"
            rules={[
              {
                required: true,
                message: "Confirm Email required!",
              },
              ({ getFieldValue }) => ({
                validator: (rule, value) => {
                  if (!value || getFieldValue("username") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("The two emails that you entered do not match!");
                },
              }),
            ]}
          >
            <Input autocomplete="off" />
          </Item>
        </Form>
      </Modal>
    </>
  );
};
