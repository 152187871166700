import React, { useState } from "react";
import { FieldArray, useField, useFormikContext } from "formik";
import Text from "components/atoms/Text";
import FormField from "components/molecules/FormField";
import SelectField from "components/molecules/SelectField";
import CheckboxField from "components/molecules/CheckboxField";
import { Space, Table } from "antd";
import { formatterNumber, parserNumber } from "utils/currency";
import useCollapse from "react-collapsed";
import EditableCell from "components/molecules/EditableCell";
import { useEffect } from "react";
import { costForOptions, frequencyOptions, OutOfNetworkCoverageOptions } from "../utils/options";
import TableField from "components/molecules/TableField";
import { typeform } from "services/api";
import { useLocation } from "react-router-dom";

const columns = [
  {
    title: "",
    dataIndex: "idx",
    width: "2%",
    editable: false,
  },
  {
    title: "In-Network",
    dataIndex: "inNetwork",
    width: "20%",
    editable: true,
    inputType: "currency",
    shouldCellUpdate: () => false,
  },
  {
    title: "Out-Of-Network",
    dataIndex: "outOfNetwork",
    width: "20%",
    editable: true,
    inputType: "currency",
    shouldCellUpdate: () => false,
  },
].map((col) => {
  return {
    ...col,
    onCell: (_, idx) => ({
      index: idx,
      editable: col.editable,
      dataIndex: col.dataIndex,
      title: col.title,
      inputType: col.inputType,
      options: col.options,
      dropdownMatchSelectWidth: col.dropdownMatchSelectWidth,
    }),
  };
});

const handleCheckboxes = (idx, checkedList, value) => {
  checkedList[idx] = value;
  return checkedList;
};

const Step = () => {
  const { values } = useFormikContext();
  const { premiumTier, table, importedFromTypeform, negativeValue, partnerJustForComparison } = values;
  const { outOfNetworkCoverage } = table[0];
  const [field, meta, helpers] = useField("table");
  const [isExpanded, setExpanded] = useState(premiumTier && premiumTier !== 0);
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded,
  });
  const [isTableExpanded, setTableExpanded] = useState(outOfNetworkCoverage === "yes");
  const { getCollapseProps: getTableCollapseProps, getTableToggleProps } = useCollapse({
    isExpanded: isTableExpanded,
  });

  const { setFieldValue } = useFormikContext();

  const [data, setData] = useState([]);

  const location = useLocation();
  const { state } = location;
  let formType, responseId, isPartnerPlans;
  if (state) {
    formType = state.formType;
    responseId = state.responseId;
    isPartnerPlans = state.isPartnerPlans || !!state.partnerJustForComparison || false;
  }

  useEffect(() => {
    if (!importedFromTypeform) {
      if (formType && responseId) {
        typeform({ formType, responseId }).then((res) => {
          if (res.status === 200) {
            const data = res.data;
            data?.email && setFieldValue("emailFromTypeform", data.email);
            if (data.planOptions) setFieldValue("planOptions", data.planOptions);
            if (data.premiumTier != null) {
              data.premiumTier = data.premiumTier === "" ? 0 : data.premiumTier;
              setFieldValue("premiumTier", data.premiumTier);
              setExpanded(data.premiumTier !== 0);
            }
            if (data.frequency)
              setFieldValue(
                "frequency",
                frequencyOptions.filter((element) => element.name === data.frequency)[0].value,
              );
            if (data.costFor)
              setFieldValue("costFor", costForOptions.filter((element) => element.name === data.costFor)[0].value);
            if (data.outOfNetworkCoverage) {
              data.outOfNetworkCoverage = data.outOfNetworkCoverage === "Yes" ? "yes" : "no";
              setFieldValue("table[0].outOfNetworkCoverage", data.outOfNetworkCoverage);
              setTableExpanded(data.outOfNetworkCoverage === "yes");
            }
            if (data.individualDeductible != null) {
              setFieldValue("deductibles[0].inNetwork", data.individualDeductible);
              if (data.individualDeductible === 0) {
                setFieldValue("deductibles[1].inNetwork", data.individualDeductible);
                if (data.outOfNetworkCoverage === "yes") {
                  setFieldValue("deductibles[0].outOfNetwork", 3000);
                  setFieldValue("deductibles[1].outOfNetwork", 6000);
                }
              } else {
                setFieldValue("deductibles[1].inNetwork", data.individualDeductible * 2);
                if (data.outOfNetworkCoverage === "yes") {
                  setFieldValue("deductibles[0].outOfNetwork", data.individualDeductible * 2);
                  setFieldValue("deductibles[1].outOfNetwork", data.individualDeductible * 4);
                }
              }
            }
            if (data.label) setFieldValue("table[0].label", data.label);
            if (data.insuranceNetwork) setFieldValue("table[0].insuranceNetwork", data.insuranceNetwork);
          }
        });
      }
      setFieldValue("isPartnerPlans", isPartnerPlans);
      setFieldValue("importedFromTypeform", true);
    }

    setFieldValue("partnerJustForComparison", state?.partnerJustForComparison);
    if (state?.partnerJustForComparison === "marketplace") {
      setFieldValue("frequency", 1);
      setFieldValue("table[0].outOfNetworkCoverage", "no");
    }

    setData([
      { idx: "Individual", "In-Network": "", "Out-Of-Network": "" },
      { idx: "Family", "In-Network": "", "Out-Of-Network": "" },
    ]);

    if (Object.keys(values.waivers).every((key) => values.waivers[key].value === 0))
      setFieldValue("withWaiverPayment", false);
    if (values.spousalSurcharge === 0) setFieldValue("withSpouseSurcharge", false);
  }, []);

  useEffect(() => {
    if (premiumTier) {
      const premiumValue = (negativeValue * -1 || 1) * Math.abs(premiumTier);
      setFieldValue("premiumTier", premiumValue);
      setExpanded(premiumValue !== 0);
    }
  }, [negativeValue]);

  useEffect(() => {
    setTableExpanded(outOfNetworkCoverage === "yes");
  }, [table]);

  const isMarketplacePlans = partnerJustForComparison === "marketplace";

  return (
    <>
      <Text sectionTitle>Number of Options</Text>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <FormField
          name="planOptions"
          type="number"
          label={`How many plan options do you have ${isMarketplacePlans ? "" : "with this job?"}`}
          hyperlink={
            isMarketplacePlans && {
              label: "through the Marketplace?",
              url: "https://www.healthcare.gov/see-plans",
            }
          }
          inline
          min={1}
          required
        />
      </Space>
      <br />
      <Text sectionTitle>First Option</Text>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <FormField
          name="premiumTier"
          type="number"
          label="How much does it cost?"
          inline
          defaultValue="$"
          formatter={formatterNumber}
          parser={parserNumber}
          setExpanded={setExpanded}
          handleOnChange={(value) => setFieldValue("negativeValue", value < 0)}
          condition="frequency"
          required
          min={Number.MIN_SAFE_INTEGER}
        />
        <div {...getCollapseProps()}>
          <SelectField
            name="frequency"
            options={frequencyOptions}
            label={`How often does it cost you the ${new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(premiumTier)}?`}
            required
          />
        </div>
        <SelectField
          name="costFor"
          options={costForOptions}
          label="Is this cost just for you, or for others in your household?"
          required
        />
        {!isMarketplacePlans && (
          <CheckboxField name="negativeValue" label="My organization pays me to join this plan (negative cost)" />
        )}
      </Space>
      <br />
      <Text sectionTitle>Annual Deductible</Text>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <SelectField
          name="table[0].outOfNetworkCoverage"
          options={OutOfNetworkCoverageOptions}
          label="Does this plan have out-of-network coverage?"
          required
          setExpanded={setTableExpanded}
          condition="deductibles"
        />
        <TableField
          name="deductibles"
          columns={isTableExpanded ? columns : columns.slice(0, -1)}
          dataSource={data}
          style={{ width: isTableExpanded ? "50vw" : "28vw" }}
        />

        <FormField
          name="table[0].label"
          type="text"
          label="How would you label this plan?"
          size="small"
          touched={meta?.touched}
          inline
          required
        />
        <br />
      </Space>
    </>
  );
};

export default Step;
