import React, { useState } from "react";
import styled from "styled-components";
import {
  CalculateHeader as Header,
  Text,
  FormDate as Date,
  ButtonLink,
} from "components";
import { Icon } from "@ant-design/compatible";

const Wrapper = styled.section``;

const FlexWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const InnerWrapper = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.button`
  border: 3px solid #00a19b;
  border-radius: 25px;
  margin: 15px 12px;
  width: 250px;
  height: 100px;
  outline: none;
  cursor: pointer;
  color: #00a19b;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  ${({ active }) =>
    active
      ? `color: orange; border: 5px solid orange;`
      : `color: #00a19b; border: 3px solid #00a19b;`}
  :hover {
    opacity: 0.5;
  }
  :focus {
    color: orange;
    border: 5px solid orange;
  }
`;

const OnboardingDateSection = ({
  onChange,
  option,
  title,
  value,
  optionText,
  action,
  actionText,
}) => {
  const [active, setActive] = useState(null);
  const [date, setDate] = useState(value);

  const handleChange = ({ target: { name, value } }) => {
    setDate(value);
    setActive("opt1");
    onChange(name, value);
  };

  const handleSecondOption = () => {
    setDate(null);
    setActive("opt2");
    onChange(option, optionText);
  };

  const handleActive1 = (e) => {
    if (e.target.name === "opt1") setActive("opt3");
  };

  const handleActive2 = (e) => {
    setActive("opt3");
  };

  return (
    <Wrapper>
      <Header bigTitle={true} title={title} />
      <FlexWrapper onClick={handleActive1}>
        <ButtonWrapper name="opt1" active={active === "opt1" ? true : false}>
          {active === "opt3" ? (
            <Date
              placeholder="MM/DD/YYYY"
              name={option}
              handleChange={handleChange}
              value={date}
              open={true}
            />
          ) : (
            <InnerWrapper onClick={handleActive2}>
              <Icon type="calendar" style={{ padding: "0 5px" }} />
              <Text select>{date ? date : "mm/dd/yyyy"}</Text>
            </InnerWrapper>
          )}
        </ButtonWrapper>
        {optionText && (
          <ButtonWrapper
            name="opt2"
            active={active === "opt2" ? true : false}
            onClick={handleSecondOption}
          >
            <Icon type="bulb" style={{ padding: "0 5px" }} />
            <Text select>{optionText}</Text>
          </ButtonWrapper>
        )}
        <br></br>
        {action && (
          <ButtonLink withBG button fontSize="18" onClick={action}>
            {actionText}
          </ButtonLink>
        )}
      </FlexWrapper>
    </Wrapper>
  );
};

export default OnboardingDateSection;
