export const WordEnum = {
  "Primary Care Sick Visit": "visit",
  "Preventive Care": "visit",
  "Specialist Visit": "visit",
  "ER Care": "visit",
  "Urgent Care": "visit",
  "Psychotherapy Office Visit": "visit",
  "Home Health Care": "visit",
  "Rehabilitation Services (PT, OT, Chiropractor)": "visit",
  "Hospice Services": "visit",
  "Childen's Eye Exam": "visit",
  Telemedicine: "session",
  "X-Ray": "X-ray",
  Labwork: "lab",
  "CT Scan": "scan",
  "PET Scan": "scan",
  MRI: "MRI",
  "Fertility Services (IVF, Egg Freezing)": "cycle",
  "Outpatient Surgery Facility Fee": "surgery",
  "Outpatient Surgery Physician Fees": "surgery",
  "Inpatient Surgery Physician Fees": "surgery",
  "Emergency Medical Transportation (Ambulance)": "ride",
  "Mental Health Inpatient Services": "admission",
  "Skilled Nursing Care": "admission",
  "Durable Medical Equipment": "item",
  "In-network Therapy": "visit",
  "Out-of-network Therapy": "visit",
  Therapy: "visit",
  "Outpatient Ambulatory Surgery Center Facility Fee": "surgery",
};
