import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CalculateHeader as Header, Text } from "components";
import { Row, Col } from "antd";

const Wrapper = styled.div`
  width: 95vw;
  display: flex;
  margin: 10px auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InnerWrapper = styled.div`
  padding: 10px;
  display: block;
  overflow-x: scroll;
  width: 95%;
  margin: auto;
`;

const BoxWrapper = styled.div`
  border: 1px solid black;
  border-radius: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 25px 100px;
`;

const StyledCell = styled(Col)`
  text-align: ${(props) => (props.align ? props.align : "center")};
  padding: 8px;
  height: ${(props) => (props.tall ? "75px" : props.short ? "30px" : "40px")};
  display: ${(props) => (props.hide ? "none" : "flex !important")};
  align-items: center !important;
  justify-content: center !important;

  ${({ background, opacity }) =>
    background === "green"
      ? `background: rgba(136, 189, 150, ${opacity ? opacity : 1})`
      : background === "red"
      ? `background: rgba(247, 172, 175, ${opacity ? opacity : 1})`
      : background === "grey"
      ? "background: rgba(249, 249, 249)"
      : background === "company"
      ? "background: rgba(2, 151, 144)"
      : background === "catastrophic"
      ? "background: rgba(177, 30, 26)"
      : background === "bronze"
      ? "background: rgba(188, 106, 46)"
      : background === "silver"
      ? "background: rgba(157, 155, 155)"
      : background === "gold"
      ? "background: rgba(248, 191, 80)"
      : background === "platinum"
      ? "background: rgba(192,192,192)"
      : "background: #fff"};

  ${({ borderTopLeft }) => borderTopLeft && "border-top-left-radius: 20px"};

  ${({ borderTopRight }) => borderTopRight && "border-top-right-radius: 20px"};

  ${({ borderBottomLeft }) =>
    borderBottomLeft && "border-bottom-left-radius: 20px"};

  ${({ borderBottomRight }) =>
    borderBottomRight && "border-bottom-right-radius: 20px"};
`;

const calculateValue = (array) => {
  const min = Math.round(Math.min(...array));
  const max = Math.round(Math.max(...array));

  let results = {
    displayValue: null,
    avgValue: null,
  };

  if (array.length === 1) {
    results.displayValue = `$${Math.round(array[0]).toLocaleString()}`;
    results.avgValue = array[0];
  } else if (array.length === 2) {
    results.displayValue = `$${min.toLocaleString()}, $${max.toLocaleString()}`;
    results.avgValue = (min + max) / 2;
  } else {
    results.displayValue = `$${min.toLocaleString()} - $${max.toLocaleString()}`;
    results.avgValue = (min + max) / 2;
  }

  return results;
};

// Display plan monthly premiums as a grid (broader network v. plan name)
// row = broader network
// column = plan name (bronze, silver, gold, platinum, catastrophic)
const MonthlyCost = ({ report, birthParentPlans, partnerPlans }) => {
  const {
    birthParent,
    partner,
    type,
    event: {
      general: { children },
    },
  } = report;

  const groupType =
    type === "individual" && !children
      ? "mo"
      : type === "household (with no spouse)" && children > 0
      ? "mc"
      : type === "household" && !children
      ? "ms"
      : type === "household" && children > 0
      ? "mf"
      : "mo";

  const deductibleType = groupType === "mo" ? "individual" : "family";

  const premiumTierBP = birthParent.premiumTier;
  const premiumTierPartner = partner.premiumTier;

  const [adjustedBPPlans, setAdjustedBPPlans] = useState([]);
  const [adjustedPartnerPlans, setAdjustedPartnerPlans] = useState([]);

  useEffect(() => {
    async function adjustPlans() {
      const _adjustedBPPlans = birthParentPlans.map((_plan) => {
        let adjustedPlan = _plan;
        adjustedPlan.premium.tiers[premiumTierBP][groupType] *=
          _plan.premium.frequency;
        return adjustedPlan;
      });

      const _adjustedPartnerPlans = partnerPlans.map((_plan) => {
        let adjustedPlan = _plan;
        adjustedPlan.premium.tiers[premiumTierPartner][groupType] *=
          _plan.premium.frequency;
        return adjustedPlan;
      });

      await setAdjustedBPPlans(_adjustedBPPlans);
      await setAdjustedPartnerPlans(_adjustedPartnerPlans);
    }
    adjustPlans();
  }, [
    birthParentPlans,
    partnerPlans,
    premiumTierBP,
    premiumTierPartner,
    groupType,
  ]);

  // normalize all premiums to be monthly rates

  const sortedBirthParentPlans = adjustedBPPlans.sort(
    (plan1, plan2) =>
      plan1.premium.tiers[premiumTierBP][groupType] -
      plan2.premium.tiers[premiumTierBP][groupType]
  );

  const sortedPartnerPlans = adjustedPartnerPlans.sort(
    (plan1, plan2) =>
      plan1.premium.tiers[premiumTierPartner][groupType] -
      plan2.premium.tiers[premiumTierPartner][groupType]
  );

  const minPremium = Math.min(
    sortedBirthParentPlans[0]
      ? sortedBirthParentPlans[0].premium.tiers[premiumTierBP][groupType]
      : Infinity,
    sortedPartnerPlans[0]
      ? sortedPartnerPlans[0].premium.tiers[premiumTierPartner][groupType]
      : Infinity
  );

  const maxPremium = Math.max(
    sortedBirthParentPlans[sortedBirthParentPlans.length - 1]
      ? sortedBirthParentPlans
          .filter(
            (_plan) => _plan.premium.tiers[premiumTierBP][groupType] < 1000000
          )
          .reverse()[0].premium.tiers[premiumTierBP][groupType]
      : 0,
    sortedPartnerPlans[sortedPartnerPlans.length - 1]
      ? sortedPartnerPlans
          .filter(
            (_plan) =>
              _plan.premium.tiers[premiumTierPartner][groupType] < 1000000
          )
          .reverse()[0].premium.tiers[premiumTierPartner][groupType]
      : 0
  );

  const avgPremium = (maxPremium + minPremium) / 2;

  let columnHeadersBP = sortedBirthParentPlans.reduce(
    (res, item) =>
      !res.some((plan) => item.name === plan) ? [...res, item.name] : res,
    []
  );

  for (let _i = 0; _i < columnHeadersBP.length - 1; ) {
    const name = columnHeadersBP[_i];
    const nextName = columnHeadersBP[_i + 1];
    if (
      (name.includes("Bronze") && nextName.includes("Catastrophic")) ||
      (name.includes("Silver") && nextName.includes("Bronze")) ||
      (name.includes("Gold") && nextName.includes("Silver"))
    ) {
      [columnHeadersBP[_i], columnHeadersBP[_i + 1]] = [
        columnHeadersBP[_i + 1],
        columnHeadersBP[_i],
      ];
      if (_i !== 0) _i--;
    } else {
      _i++;
    }
  }

  let columnHeadersPartner = sortedPartnerPlans.reduce(
    (res, item) =>
      !res.some((plan) => item.name === plan) ? [...res, item.name] : res,
    []
  );

  for (let _i = 0; _i < columnHeadersPartner.length - 1; ) {
    const name = columnHeadersPartner[_i];
    const nextName = columnHeadersPartner[_i + 1];
    if (
      (name.includes("Bronze") && nextName.includes("Catastrophic")) ||
      (name.includes("Silver") && nextName.includes("Bronze")) ||
      (name.includes("Gold") && nextName.includes("Silver"))
    ) {
      [columnHeadersPartner[_i], columnHeadersPartner[_i + 1]] = [
        columnHeadersPartner[_i + 1],
        columnHeadersPartner[_i],
      ];
      if (_i !== 0) _i--;
    } else {
      _i++;
    }
  }

  const rowHeaders = [...sortedBirthParentPlans, ...sortedPartnerPlans].reduce(
    (res, item) =>
      !res.some((plan) => item.broaderNetwork === plan)
        ? [...res, item.broaderNetwork]
        : res,
    []
  );

  return (
    <Wrapper>
      <Header title="How Much Do These Plans Cost?" bigTitle />
      <Text big center>
        {"Monthly premiums across " +
          [...birthParentPlans, ...partnerPlans].length +
          " different plans range from $" +
          Math.round(minPremium).toLocaleString() +
          " to $" +
          Math.round(maxPremium).toLocaleString()}
        {groupType !== "mo" ? " (assumes everyone on same plan)" : ""}
      </Text>
      <br></br>
      <br></br>

      <InnerWrapper>
        <Row
          style={{ flexFlow: "row" }}
          align="middle"
          justify="center"
          wrap={false}
        >
          <StyledCell span={4} />
          <StyledCell
            hide={!columnHeadersBP.length}
            span={2 * columnHeadersBP.length}
            background="company"
            tall
            borderTopLeft
            borderTopRight
          >
            <Text white fontSize="20">
              {birthParent.company}
            </Text>
          </StyledCell>
          <StyledCell span={0.3} />
          <StyledCell
            hide={!columnHeadersPartner.length}
            span={2 * columnHeadersPartner.length}
            background="company"
            tall
            borderTopLeft
            borderTopRight
          >
            <Text white fontSize="20">
              {partner.company}
            </Text>
          </StyledCell>
        </Row>

        <Row
          style={{ flexFlow: "row" }}
          align="middle"
          justify="center"
          wrap={false}
        >
          <StyledCell span={4} />
          {columnHeadersBP.map((_planName, _i) => (
            <StyledCell
              span={2}
              key={_i}
              tall
              background={
                _planName.includes("Catastrophic")
                  ? "catastrophic"
                  : _planName.includes("Bronze")
                  ? "bronze"
                  : _planName.includes("Silver")
                  ? "silver"
                  : _planName.includes("Gold")
                  ? "gold"
                  : _planName.includes("Platinum")
                  ? "platinum"
                  : "grey"
              }
            >
              <Text
                black
                fontSize="14"
                white={
                  _planName.includes("Catastrophic") ||
                  _planName.includes("Bronze") ||
                  _planName.includes("Silver")
                }
              >
                {_planName}
              </Text>
            </StyledCell>
          ))}

          <StyledCell span={0.3} />
          {columnHeadersPartner.map((_planName, _i) => (
            <StyledCell
              span={2}
              key={_i}
              tall
              background={
                _planName.includes("Catastrophic")
                  ? "catastrophic"
                  : _planName.includes("Bronze")
                  ? "bronze"
                  : _planName.includes("Silver")
                  ? "silver"
                  : _planName.includes("Gold")
                  ? "gold"
                  : _planName.includes("Platinum")
                  ? "platinum"
                  : "grey"
              }
            >
              <Text
                black
                fontSize="14"
                white={
                  _planName.includes("Catastrophic") ||
                  _planName.includes("Bronze") ||
                  _planName.includes("Silver")
                }
              >
                {_planName}
              </Text>
            </StyledCell>
          ))}
        </Row>

        <Row
          style={{ flexFlow: "row" }}
          align="middle"
          justify="center"
          wrap={false}
        >
          <StyledCell span={4} short />
          {columnHeadersBP.map((_planName, _i) => {
            const numberOfPlans = sortedBirthParentPlans.filter(
              (_plan) => _plan.name === _planName
            ).length;
            return (
              <StyledCell span={2} key={_i} short background="grey">
                <Text black fontSize="10">
                  {numberOfPlans > 1 ? `(${numberOfPlans} plans)` : ""}
                </Text>
              </StyledCell>
            );
          })}
          <StyledCell span={0.3} short />
          {columnHeadersPartner.map((_planName, _i) => {
            const numberOfPlans = sortedPartnerPlans.filter(
              (_plan) => _plan.name === _planName
            ).length;
            return (
              <StyledCell span={2} key={_i} short background="grey">
                <Text black fontSize="10">
                  {numberOfPlans > 1 ? `(${numberOfPlans} plans)` : ""}
                </Text>
              </StyledCell>
            );
          })}
        </Row>

        <Row
          style={{ flexFlow: "row" }}
          align="middle"
          justify="center"
          wrap={false}
        >
          <StyledCell span={4} align="start" style={{ height: "40px" }}>
            <Text black small underline>
              In-Network Limits
            </Text>
          </StyledCell>
          <StyledCell
            hide={!columnHeadersBP.length}
            span={columnHeadersBP.length * 2}
            align="start"
            background="grey"
          />
          <StyledCell span={0.3} />
          <StyledCell
            hide={!columnHeadersPartner.length}
            span={columnHeadersPartner.length * 2}
            align="start"
            background="grey"
          />
        </Row>

        <Row
          style={{ flexFlow: "row" }}
          align="middle"
          justify="center"
          wrap={false}
        >
          <StyledCell span={4} align="start">
            <Text black fontSize="14">
              Medical Deductible
            </Text>
          </StyledCell>
          {columnHeadersBP.map((_planName, _i) => {
            const valueArray = sortedBirthParentPlans
              .filter((_plan) => _plan.name === _planName)
              .reduce(
                (res, item) =>
                  !res.some(
                    (plan) => item.inNetwork[deductibleType].deductible === plan
                  )
                    ? [...res, item.inNetwork[deductibleType].deductible]
                    : res,
                []
              );

            const { displayValue } = calculateValue(valueArray);

            if (valueArray.length) {
              return (
                <StyledCell span={2} align="center" key={_i} background="grey">
                  <Text black fontSize="12">
                    {displayValue}
                  </Text>
                </StyledCell>
              );
            } else {
              return (
                <StyledCell span={2} align="center" key={_i} background="grey">
                  <Text black fontSize="12">
                    -
                  </Text>
                </StyledCell>
              );
            }
          })}

          <StyledCell span={0.3} />

          {columnHeadersPartner.map((_planName, _i) => {
            const valueArray = sortedPartnerPlans
              .filter((_plan) => _plan.name === _planName)
              .reduce(
                (res, item) =>
                  !res.some(
                    (plan) => item.inNetwork[deductibleType].deductible === plan
                  )
                    ? [...res, item.inNetwork[deductibleType].deductible]
                    : res,
                []
              );

            const { displayValue } = calculateValue(valueArray);

            if (valueArray.length) {
              return (
                <StyledCell span={2} align="center" key={_i} background="grey">
                  <Text black fontSize="12">
                    {displayValue}
                  </Text>
                </StyledCell>
              );
            } else {
              return (
                <StyledCell span={2} align="center" key={_i} background="grey">
                  <Text black fontSize="12">
                    -
                  </Text>
                </StyledCell>
              );
            }
          })}
        </Row>

        <Row
          style={{ flexFlow: "row" }}
          align="middle"
          justify="center"
          wrap={false}
        >
          <StyledCell span={4} align="start">
            <Text black fontSize="14">
              Out-Of-Pocket Maximum
            </Text>
          </StyledCell>
          {columnHeadersBP.map((_planName, _i) => {
            const valueArray = sortedBirthParentPlans
              .filter((_plan) => _plan.name === _planName)
              .reduce(
                (res, item) =>
                  !res.some(
                    (plan) => item.inNetwork[deductibleType].oopMax === plan
                  )
                    ? [...res, item.inNetwork[deductibleType].oopMax]
                    : res,
                []
              );

            const { displayValue } = calculateValue(valueArray);

            if (valueArray.length) {
              return (
                <StyledCell
                  span={2}
                  align="center"
                  key={_i}
                  background="grey"
                  borderBottomLeft={_i === 0}
                  borderBottomRight={
                    _i === columnHeadersBP.length - 1 &&
                    !columnHeadersPartner.length
                  }
                >
                  <Text black fontSize="12">
                    {displayValue}
                  </Text>
                </StyledCell>
              );
            } else {
              return (
                <StyledCell
                  span={2}
                  align="center"
                  key={_i}
                  background="grey"
                  borderBottomLeft={_i === 0}
                  borderBottomRight={
                    _i === columnHeadersBP.length - 1 &&
                    !columnHeadersPartner.length
                  }
                >
                  <Text black fontSize="12">
                    -
                  </Text>
                </StyledCell>
              );
            }
          })}

          <StyledCell span={0.3} />

          {columnHeadersPartner.map((_planName, _i) => {
            const valueArray = sortedPartnerPlans
              .filter((_plan) => _plan.name === _planName)
              .reduce(
                (res, item) =>
                  !res.some(
                    (plan) => item.inNetwork[deductibleType].oopMax === plan
                  )
                    ? [...res, item.inNetwork[deductibleType].oopMax]
                    : res,
                []
              );

            const { displayValue } = calculateValue(valueArray);

            if (valueArray.length) {
              return (
                <StyledCell
                  span={2}
                  align="center"
                  key={_i}
                  background="grey"
                  borderBottomLeft={_i === 0 && !columnHeadersBP.length}
                  borderBottomRight={_i === columnHeadersPartner.length - 1}
                >
                  <Text black fontSize="12">
                    {displayValue}
                  </Text>
                </StyledCell>
              );
            } else {
              return (
                <StyledCell
                  span={2}
                  align="center"
                  key={_i}
                  background="grey"
                  borderBottomLeft={_i === 0 && !columnHeadersBP.length}
                  borderBottomRight={_i === columnHeadersPartner.length - 1}
                >
                  <Text black fontSize="12">
                    -
                  </Text>
                </StyledCell>
              );
            }
          })}
        </Row>

        <br></br>

        <Row
          style={{ flexFlow: "row" }}
          align="middle"
          justify="center"
          wrap={false}
        >
          <StyledCell span={4} align="start">
            <Text black small underline>
              Monthly Premiums
            </Text>
          </StyledCell>
          <StyledCell span={columnHeadersBP.length * 2} align="start" />
          <StyledCell span={0.3} />
          <StyledCell span={columnHeadersPartner.length * 2} align="start" />
        </Row>

        {rowHeaders.map((_network, _i) => (
          <Row
            style={{ flexFlow: "row" }}
            justify="center"
            wrap={false}
            key={_i}
          >
            <StyledCell span={4} align="start">
              <Text black fontSize="14">
                {_network}
              </Text>
            </StyledCell>

            {columnHeadersBP.map((_planName, _j) => {
              const valueArray = sortedBirthParentPlans
                .filter(
                  (_plan) =>
                    _plan.name === _planName &&
                    _plan.broaderNetwork === _network
                )
                .reduce(
                  (res, item) =>
                    !res.some(
                      (_value) =>
                        item.premium.tiers[premiumTierBP][groupType] === _value
                    )
                      ? [...res, item.premium.tiers[premiumTierBP][groupType]]
                      : res,
                  []
                );

              const { displayValue, avgValue } = calculateValue(valueArray);
              const isGreen = avgPremium - avgValue >= 0;

              const opacity = (avgPremium - avgValue) / avgPremium;

              if (avgValue < 1000000) {
                return (
                  <StyledCell
                    span={2}
                    align="center"
                    key={_j}
                    background={isGreen ? "green" : "red"}
                    opacity={isGreen ? opacity : Math.abs(opacity)}
                    borderTopLeft={_i === 0 && _j === 0}
                    borderTopRight={
                      _i === 0 &&
                      _j === columnHeadersBP.length - 1 &&
                      !columnHeadersPartner.length
                    }
                    borderBottomLeft={_i === rowHeaders.length - 1 && _j === 0}
                    borderBottomRight={
                      _i === rowHeaders.length - 1 &&
                      _j === columnHeadersBP.length - 1
                    }
                  >
                    <Text black fontSize="12">
                      {displayValue}
                    </Text>
                  </StyledCell>
                );
              } else {
                return (
                  <StyledCell
                    span={2}
                    align="center"
                    key={_j}
                    background="grey"
                    borderTopLeft={_i === 0 && _j === 0}
                    borderTopRight={
                      _i === 0 &&
                      _j === columnHeadersBP.length - 1 &&
                      !columnHeadersPartner.length
                    }
                    borderBottomLeft={_i === rowHeaders.length - 1 && _j === 0}
                    borderBottomRight={
                      _i === rowHeaders.length - 1 &&
                      _j === columnHeadersBP.length - 1
                    }
                  >
                    <Text black fontSize="12">
                      -
                    </Text>
                  </StyledCell>
                );
              }
            })}

            <StyledCell span={0.3} />

            {columnHeadersPartner.map((_planName, _j) => {
              const valueArray = sortedPartnerPlans
                .filter(
                  (_plan) =>
                    _plan.name === _planName &&
                    _plan.broaderNetwork === _network
                )
                .reduce(
                  (res, item) =>
                    !res.some(
                      (_value) =>
                        item.premium.tiers[premiumTierPartner][groupType] ===
                        _value
                    )
                      ? [
                          ...res,
                          item.premium.tiers[premiumTierPartner][groupType],
                        ]
                      : res,
                  []
                );

              const { displayValue, avgValue } = calculateValue(valueArray);
              const isGreen = avgPremium - avgValue >= 0;
              const opacity = (avgPremium - avgValue) / avgPremium;

              if (avgValue < 1000000) {
                return (
                  <StyledCell
                    span={2}
                    align="center"
                    key={_j}
                    background={isGreen ? "green" : "red"}
                    opacity={isGreen ? opacity : Math.abs(opacity)}
                    borderTopLeft={
                      _i === 0 && _j === 0 && !columnHeadersBP.length
                    }
                    borderTopRight={
                      _i === 0 && _j === columnHeadersPartner.length - 1
                    }
                    borderBottomLeft={_i === rowHeaders.length - 1 && _j === 0}
                    borderBottomRight={
                      _i === rowHeaders.length - 1 &&
                      _j === columnHeadersPartner.length - 1
                    }
                  >
                    <Text black fontSize="12">
                      {displayValue}
                    </Text>
                  </StyledCell>
                );
              } else {
                return (
                  <StyledCell
                    span={2}
                    align="center"
                    key={_j}
                    background="grey"
                    borderTopLeft={
                      _i === 0 && _j === 0 && !columnHeadersBP.length
                    }
                    borderTopRight={
                      _i === 0 && _j === columnHeadersPartner.length - 1
                    }
                    borderBottomLeft={_i === rowHeaders.length - 1 && _j === 0}
                    borderBottomRight={
                      _i === rowHeaders.length - 1 &&
                      _j === columnHeadersPartner.length - 1
                    }
                  >
                    <Text black fontSize="12">
                      -
                    </Text>
                  </StyledCell>
                );
              }
            })}
          </Row>
        ))}
      </InnerWrapper>

      <br></br>
      <br></br>
      <BoxWrapper>
        <Text green fontSize="12" padding="2px">
          Green
        </Text>
        <Text black fontSize="12">
          indicates lower monthly premiums;
        </Text>
        <Text red fontSize="12" padding="2px">
          red
        </Text>
        <Text black fontSize="12">
          indicates higher monthly premiums.
        </Text>
      </BoxWrapper>
    </Wrapper>
  );
};

export default MonthlyCost;
