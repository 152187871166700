import React, { useState } from "react";
import AddEmployerFooter from "./footer";
import Questions from "./questions";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

const Wrapper = styled.div`
  padding: 10% 25% 0 25%;
  width: 100%;
  overflow: hidden;
  @media (max-width: 1800px) {
    padding: 10% 20% 0 20%;
  }
  @media (max-width: 1600px) {
    padding: 10% 17.5% 0 17.5%;
  }
  @media (max-width: 1280px) {
    padding: 10% 10% 0 10%;
  }
  @media (max-width: 900px) {
    padding: 20% 5% 0 5%;
  }
`;

const AddEmployerQuestions = () => {
  const { state } = useLocation();
  const { reportId, partner } = state || {};
  const [option, setOption] = useState("upload");
  return (
    <Wrapper>
      <Questions option={option} setOption={setOption} />
      <AddEmployerFooter option={option} reportId={reportId} partner={partner} />
    </Wrapper>
  );
};

export default AddEmployerQuestions;
