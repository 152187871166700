export default {
  formId: "premiumsForm",
  formField: {
    // step 1
    planOptions: {
      name: "planOptions",
      requiredErrorMsg: "This field is required",
    },
    withMultiplePremiumTiers: {
      name: "withMultiplePremiumTiers",
    },
    premiumTiersNumber: {
      name: "premiumTiersNumber",
    },
    selectedPremiumTier: {
      name: "selectedPremiumTier",
    },
    premiumTier: {
      name: "premiumTier",
      requiredErrorMsg: "This field is required",
    },
    costFor: {
      name: "costFor",
      requiredErrorMsg: "This field is required",
    },
    outOfNetworkCoverage: {
      name: "outOfNetworkCoverage",
      requiredErrorMsg: "This field is required",
    },
    label: {
      name: "label",
      requiredErrorMsg: "This field is required",
    },
    deductibles: {
      name: "deductibles",
      requiredErrorMsg: "This field is required",
    },
    table: {
      name: "table",
      requiredErrorMsg: "All table fields are required",
    },
    // step 2
    frequency: {
      name: "frequency",
      requiredErrorMsg: "This field is required",
    },
  },
};
