import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PageTemplate, Header } from "components";
import { AddPremiumsStepper as Stepper, AddPremiumsSteps as Steps, AddPremiumsFooter as Footer } from "components";
import { Formik } from "formik";
import { Form } from "formik-antd";
import { withFormikDevtools } from "formik-devtools-extension";
import { message } from "antd";
import { getToken, setToken, getUserId } from "services/utils";
import { groupNewAccount, groupNewReport, groupAddPartner, updatePremiums } from "services/api";
import { formatPlans } from "./utils/format";
import FormikPersist from "./utils/formikPersist";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Actions from "store/auth/actions";
import initialValues from "./utils/initialValues";
import { loadValues } from "./utils/loadValues";
import styled from "styled-components";

const CustomForm = styled(Form)`
  /* background-color: red !important;

  .ant-space-item{
    width: 100% !important;
    overflow-x: scroll !important;
  } */
`;

const AddPremiumsPage = () => {
  const history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const userUsername = useSelector((state) => state.profile.username);

  const [formikInitialValues, setFormikInitialValues] = useState(initialValues);

  //report to add partner plans
  const reportId = state?.reportId;
  const isEditing = state?.isEditing;
  const option = state?.option
    ? state.option === "partner" || state.option === "jobOffer"
      ? "partner"
      : state.option
    : "";
  const group = state?.group;
  const bankOfAmericaGroup = state?.bankOfAmericaGroup;
  const reportToVerify = state?.reportToVerify;
  const workEmail = state?.workEmail;
  const jobOfferEmail = state?.jobOfferEmail;
  const premiumTier = state?.premiumTier;
  const canRedirectToReport = state?.canRedirectToReport != null ? state?.canRedirectToReport : true;
  const [showEndMessage, setShowEndMessage] = useState(false);
  let token = {};

  const getValuesFromBaseReports = async (group) => {
    token = await getToken();
    setFormikInitialValues(
      await loadValues({ group, token, reportToVerify, premiumTier, currentReportId: reportId, isEditing, option }),
    );
  };

  useEffect(() => {
    (group || isEditing) && getValuesFromBaseReports(group);
  }, []);

  if (state == null || Object.keys(state)?.length === 0) {
    window.location.href = "https://predictabill.com";
    return null;
  }

  const partnerJustForComparison = state?.partnerJustForComparison
    ? state?.partnerJustForComparison
    : state?.option
    ? state.option === "jobOffer"
      ? "jobOffer"
      : ""
    : "";

  const userTypeformResponse =
    (localStorage.getItem("userTypeformResponse") && JSON.parse(localStorage.getItem("userTypeformResponse"))) || {};
  const pregnancyTypeformResponse =
    (localStorage.getItem("pregnancyTypeformResponse") &&
      JSON.parse(localStorage.getItem("pregnancyTypeformResponse"))) ||
    {};

  return (
    <PageTemplate overrideCloseIconOnClick={!isEditing} isContentHover padding="0 12.5%" header={<Header V2 />}>
      <Formik
        initialValues={formikInitialValues}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const plansValues = await formatPlans(values);
            setSubmitting(false);
            const additionalInformation = {
              bankOfAmericaGroup,
              workEmail,
              jobOfferEmail,
            };
            let { reportStatus, reportData } = {};
            const userId = await getUserId();
            if (isEditing && reportId) {
              const { status, data } = await updatePremiums({ reportId, isPartner: option, plansValues });
              reportStatus = status;
              reportData = data;
            } else if (reportId && option === "partner") {
              const { status, data } = await groupAddPartner({
                reportId,
                partnerType: partnerJustForComparison === "jobOffer" ? "jobOffer" : "partner",
                group,
                additionalInformation,
                plansValues,
                userId,
              });
              reportStatus = status;
              reportData = data;
            } else {
              if (!userId) {
                const { status, data } = await groupNewAccount({
                  userTypeformResponse,
                  pregnancyTypeformResponse,
                  group,
                  additionalInformation,
                  plansValues,
                });
                reportStatus = status;
                reportData = data;
              } else {
                const { status, data } = await groupNewReport({
                  userId,
                  userTypeformResponse,
                  pregnancyTypeformResponse,
                  group,
                  additionalInformation,
                  plansValues,
                });
                reportStatus = status;
                reportData = data;
              }
            }

            if (reportStatus === 200) {
              if (canRedirectToReport) {
                message.success({
                  content: <>{"Plans Created Successfully"}</>,
                  icon: (
                    <span
                      role="img"
                      aria-label="check-circle"
                      className="anticon anticon-check-circle"
                      style={{
                        color: "#00a19b",
                      }}
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="check-circle"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path>
                      </svg>
                    </span>
                  ),
                });
                localStorage.removeItem("userTypeformResponse");
                localStorage.removeItem("pregnancyTypeformResponse");
                localStorage.removeItem("premiumsForm");
                if (reportData.authenticatedUser) {
                  const { username, token } = reportData.authenticatedUser;
                  setToken(token);
                  dispatch(Actions.Creators.loginSuccess(username, token));
                }
                if (reportId) {
                  history.push(`/optimizeV2/${reportId}`, {
                    activeTab: 2, // all my options
                  });
                } else {
                  history.push(`/optimizeV2/${reportData.reportId}`, {
                    activeTab: 2, // all my options
                  });
                }
              }
              setShowEndMessage(true);
            } else message.error("Fail!");
          } catch (e) {
            console.log(e);
          }
        }}
      >
        {(formikProps) => {
          withFormikDevtools(formikProps);
          return (
            <CustomForm>
              <Steps showEndMessage={showEndMessage} email={userTypeformResponse.email || userUsername} />
              {!showEndMessage && <Footer isSubmitting={formikProps.isSubmitting} />}
              <FormikPersist name="premiumsForm" />
            </CustomForm>
          );
        }}
      </Formik>
    </PageTemplate>
  );
};

export default AddPremiumsPage;
