import React, { useState } from "react";
import styled from "styled-components";
import {
  CalculateHeader as Header,
  FormFooter,
  FormCheckboxAltV2 as Checkbox,
  Icon,
} from "components";

const Wrapper = styled.section`
  display: grid;
  height: 100%;
  place-items: center;
`;

const GridWrapper = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 15px;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const OnboardingGeneralSection = ({ _form, handleNext }) => {
  const [form, setForm] = useState({
    newJob: _form?.newJob,
    leftJob: _form?.leftJob,
    isDue: _form?.isDue,
    gettingMarried: _form?.gettingMarried,
    turning26: _form?.turning26,
    graduating: _form?.graduating,
    moving: _form?.moving,
    generalAdvice: _form?.generalAdvice,
    onboardingStep: "job",
  });

  const handleChange = ({ name, value }) => {
    if (name === "generalAdvice" && value === true) {
      setForm({
        newJob: null,
        leftJob: null,
        isDue: null,
        gettingMarried: null,
        turning26: null,
        graduating: null,
        moving: null,
        generalAdvice: true,
        onboardingStep: "job",
      });
    } else {
      setForm({
        ...form,
        [name]: value,
        generalAdvice: null,
        onboardingStep: "job",
      });
    }
  };

  const active = Object.values(form).some((value) => value === true);

  return (
    <Wrapper>
      <Header
        bigTitle={true}
        title="What do you need health insurance advice on?"
        greenText="Select all that apply."
      />
      <GridWrapper>
        <Checkbox
          name="newJob"
          value={form.newJob}
          Icon={<Icon onboardingJobIcon small />}
          text="New Job"
          handleChange={handleChange}
        />
        <Checkbox
          name="leftJob"
          value={form.leftJob}
          Icon={<Icon onboardingJobIcon small />}
          text="Left Job"
          handleChange={handleChange}
        />
        <Checkbox
          name="isDue"
          value={form.isDue}
          Icon={<Icon onboardingBabyIcon small />}
          text="Having a baby"
          handleChange={handleChange}
        />
        <Checkbox
          name="gettingMarried"
          value={form.gettingMarried}
          Icon={<Icon onboardingWeddingIcon small />}
          text="Getting married"
          handleChange={handleChange}
        />
        <Checkbox
          name="turning26"
          value={form.turning26}
          Icon={<Icon onboardingTurning26Icon small />}
          text="Turning 26"
          handleChange={handleChange}
        />
        <Checkbox
          name="graduating"
          value={form.graduating}
          Icon={<Icon onboardingGraduatingIcon small />}
          text="Graduation from school"
          handleChange={handleChange}
        />
        <Checkbox
          name="moving"
          value={form.moving}
          Icon={<Icon onboardingMovingIcon small />}
          text="Moving"
          handleChange={handleChange}
        />
        <Checkbox
          name="generalAdvice"
          value={form.generalAdvice}
          text="None of these, but I still want advice!"
          handleChange={handleChange}
        />
      </GridWrapper>

      <FormFooter
        mainAction={() => handleNext(form)}
        mainActionActive={active}
      />
    </Wrapper>
  );
};

export default OnboardingGeneralSection;
