import joi from "joi";

const customPlanCompleteFormSendSchema = joi
  .object({
    id: joi.string().required(),
    group: joi.string().required(),
    name: joi.string().required(),
    maternityCopayDetails: joi.object().keys({
      subjToCopay: joi.bool().required(),
      hospital: joi.number().when("subjToCopay", {
        is: true,
        then: joi.required(),
        otherwise: joi.allow(null)
      }),
      unit: joi.string().when("subjToCopay", {
        is: true,
        then: joi.valid("stay", "night").required(),
        otherwise: joi.valid("n/a")
      }),
      maxUnits: joi.number().when("unit", {
        is: "night",
        then: joi
          .number()
          .greater(0)
          .required(),
        otherwise: joi.allow(null)
      }),
      whoPays: joi.string().when("subjToCopay", {
        is: true,
        then: joi.valid("Parent", "Both").required(),
        otherwise: joi.valid("n/a")
      }),
      subjToPercentCoins: joi.bool().required()
    }),
    inNetwork: joi.object().keys({
      individual: joi.object().keys({
        deductible: joi.number().required(),
        oopMax: joi
          .number()
          .greater(0)
          .required()
      }),
      family: joi.object().keys({
        deductible: joi.number().required(),
        individualDeductible: joi.number().allow(null),
        individualOopMax: joi.number().when("individualDeductible", {
          is: joi.number().greater(0),
          then: joi
            .number()
            .greater(0)
            .required(),
          otherwise: joi.allow(null)
        }),
        oopMax: joi
          .number()
          .greater(0)
          .required()
      }),
      coinsurance: joi.object().keys({
        rate: joi.number().allow(null)
      })
    }),
    outOfNetwork: joi
      .object()
      .keys({
        coverage: joi.bool().required()
      })
      .unknown()
      .when(
        joi.object({
          coverage: true
        }),
        {
          then: joi.object().keys({
            individual: joi.object().keys({
              deductible: joi.number(),
              oopMax: joi.number()
            }),
            family: joi.object().keys({
              deductible: joi.number(),
              individualDeductible: joi.number(),
              individualOopMax: joi.number(),
              oopMax: joi.number()
            }),
            coinsurance: joi.object().keys({
              rate: joi.number()
            })
          })
        }
      ),
    family: joi.object().keys({
      deductible: joi.object().keys({
        type: joi.string().valid("Individual", "Combined")
      })
    }),
    baby: joi.object().keys({
      deductible: joi.object().keys({
        isUsed: joi.bool().allow(null)
      })
    }),
    deductibles: joi.object().keys({
      isApply: joi.boolean().required(),
      cross: joi
        .object()
        .keys({
          isAccumulatable: joi.bool().allow(null)
        })
        .when("isApply", {
          is: true,
          then: joi.object({ isAccumulatable: joi.required() })
        })
    }),
    premium: joi.object().keys({
      isUnique: joi.bool().required(),
      frequency: joi.number().required(),
      tiers: joi.object().keys({
        1: joi.object().keys({
          mo: joi.number().required(),
          ms: joi.number().required(),
          mc: joi.number().required(),
          mf: joi.number().required()
        })
      })
    }),
    hsa: joi.object().keys({
      isApply: joi.bool().required(),
      frequency: joi.number().when("isApply", {
        is: true,
        then: joi.required(),
        otherwise: joi.allow(null)
      }),
      tiers: joi.object().when("isApply", {
        is: true,
        then: joi.object().keys({
          1: joi.object().keys({
            individual: joi.number().allow(null),
            family: joi.number().allow(null)
          })
        }),
        otherwise: joi.object().keys({
          empty: joi
            .string()
            .valid("")
            .allow(null)
        })
      })
    })
  })
  .unknown()
  .required();

export default customPlanCompleteFormSendSchema;
