import { StayPrimaryLandscapeDimensions } from "styled-icons/material/StayPrimaryLandscape";
import configure from "./configure";
import stringify from "./query";

const { setHeadersWithAuth, setHeaderContentMultipartFormDataWithAuth, axios } = configure;
axios.defaults.timeout = 60 * 1000;

export const getRole = (token) => {
  const headers = setHeadersWithAuth(token);
  return axios.get(`admin/`, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const isProfessional = (token) => {
  const headers = setHeadersWithAuth(token);
  return axios.get(`admin/professional`, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const notifyUsers = ({ token, emails, reportId }) => {
  const headers = setHeadersWithAuth(token);
  const body = { reportId, emails };
  return axios.post(`admin/notify-users-report`, body, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const searchReport = (reportId, token) => {
  const headers = setHeadersWithAuth(token);
  const query = stringify({ reportId });
  return axios.get(`admin/report${query}`, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const getObamacarePlans = (userInput, token) => {
  const headers = setHeadersWithAuth(token);
  const body = { userInput };
  return axios
    .post("admin/obamacare-plans", body, {
      ...headers,
      responseType: "arraybuffer",
      timeout: 0,
    })
    .then(
      (res) => res,
      (err) => err,
    );
};

export const getPlansArr = (planIds, token, sort = true) => {
  if (!planIds) return;
  const headers = setHeadersWithAuth(token);
  const query = stringify({ planIds, sort });
  return axios.get(`admin/plans-array${query}`, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const getUnconfirmedReports = (token) => {
  const headers = setHeadersWithAuth(token);
  return axios.get(`reports/get-admin-reports`, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const updateReport = (reportId, form, token) => {
  const headers = setHeadersWithAuth(token);
  const body = { reportId, form };
  return axios.patch("admin/report", body, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const typeformUpdateReport = (form) => {
  const { reportId, username, typeformResponse, isNotLogged } = form;
  const body = { reportId, username, typeformResponse, isNotLogged };
  return axios.patch("admin/typeform-update-report", body).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const updateMarketplacePreferences = (reportId, plans, token) => {
  const headers = setHeadersWithAuth(token);
  const body = { reportId, plans };
  return axios.patch("admin/report-marketplace-preferences", body, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

// unused route
export const optimize = (form, token) => {
  const headers = setHeadersWithAuth(token);
  return axios.post("optimize/", form, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const accountRegister = (username, token) => {
  const headers = setHeadersWithAuth(token);
  return axios.post("account/admin-register-user", { username }, headers);
};

export const optimizeV2 = (form, token) => {
  const headers = setHeadersWithAuth(token);
  return axios.post("optimize/V2", form, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

// unused route
export const optimizeSwitching = (form, token) => {
  const headers = setHeadersWithAuth(token);
  return axios.post("optimize/switching", form, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

// unused route
export const optimizeNoLabor = (form, token) => {
  const headers = setHeadersWithAuth(token);
  return axios.post("optimize/no-labor", form, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const optimizeKnown = (form, token) => {
  const headers = setHeadersWithAuth(token);
  return axios.post("optimize/obamacare", form, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const getKnownOpt = (id, token) => {
  const headers = setHeadersWithAuth(token);
  const query = stringify({ id });
  return axios.get(`optimize/get-known${query}`, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const getKnownOpts = (token) => {
  const headers = setHeadersWithAuth(token);
  return axios.get("optimize/get-known-all", headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const getAllPlans = (token) => {
  const headers = setHeadersWithAuth(token);
  return axios.get("admin/plans", headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const createPlans = (isPublic, token, form, allowMultipleServices = false) => {
  const headers = setHeadersWithAuth(token);
  const body = {
    isPublic,
    form,
    allowMultipleServices,
  };
  return axios.post("admin/plans", body, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const attachPlanInfoToReport = async (
  reportId,
  token,
  userId,
  planIds,
  isPartnerPlans,
  formValues,
  form,
  partnerJustForComparison,
  userTypeformResponse,
  pregnancyTypeformResponse,
  currentReport,
  oldPlansIds,
  group,
  customReportId,
  groupObj,
) => {
  const headers = setHeadersWithAuth(token);
  const body = {
    reportId,
    userId,
    planIds,
    isPartnerPlans,
    formValues,
    form,
    partnerJustForComparison,
    userTypeformResponse,
    pregnancyTypeformResponse,
    currentReport,
    oldPlansIds,
    group,
    customReportId,
    groupObj,
  };
  return axios.patch(`/admin/report-attach-form-info`, body, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const updatePlans = (isPublic, form, token) => {
  const headers = setHeadersWithAuth(token);
  const body = { isPublic, form };
  return axios.patch("admin/plans", body, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const updateServices = (isPublic, form, token) => {
  const headers = setHeadersWithAuth(token);
  const body = { isPublic, form };
  return axios.patch("admin/services", body, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const updateGroup = (isPublic, prevGroup, newGroup, token) => {
  const headers = setHeadersWithAuth(token);
  const body = { isPublic, prevGroup, newGroup };
  return axios.patch("admin/plans-group", body, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const deletePlan = (isPublic, planId, token) => {
  const headers = setHeadersWithAuth(token);
  return axios.delete("admin/plan", { data: { isPublic, planId } }, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const getAllUsers = (token) => {
  const headers = setHeadersWithAuth(token);
  return axios.get("admin/users", headers);
};

export const getTracking = (token) => {
  const headers = setHeadersWithAuth(token);
  return axios.get("tracking/", headers);
};

export const getDatedTracking = (startDate, endDate, token) => {
  const headers = setHeadersWithAuth(token);
  const query = stringify({ startDate, endDate });
  return axios.get(`tracking/dates${query}`, headers);
};

export const getFriends = (token) => {
  const headers = setHeadersWithAuth(token);
  return axios.get("tracking/get-friends", headers);
};

export const addFriend = (username, token) => {
  const headers = setHeadersWithAuth(token);
  const body = { username };
  return axios.post("tracking/add-friend", body, headers);
};

export const removeFriend = (username, token) => {
  const headers = setHeadersWithAuth(token);
  const body = { username };
  return axios.post("tracking/remove-friend", body, headers);
};

export const cloneReportForUsers = (reportId, userArray, token, keepAllInformation = false, changes = {}) => {
  const headers = setHeadersWithAuth(token);
  const body = { users: userArray, keepAllInformation, changes };
  return axios.post(`admin/clone-report/${reportId}`, body, headers);
};

export const downloadPlanTemplate = (type, token) => {
  const headers = setHeadersWithAuth(token);
  return axios.get(`admin/new-plans-template`, headers);
};

export const getPlansTemplateForEdit = ({ searchBy, searchTerm }, servicesIncluded, token) => {
  const queryObj = {};
  if (servicesIncluded) queryObj.servicesIncluded = servicesIncluded;
  queryObj[searchBy] = searchTerm || true;
  const query = stringify(queryObj);
  const headers = setHeadersWithAuth(token);
  return axios.get(`admin/edit-plans-template${query}`, headers);
};

export const uploadNewPlansCSV = (formData, token) => {
  const headers = setHeaderContentMultipartFormDataWithAuth(token, formData);
  return axios.post("admin/import/new-insurance-plans", formData, headers);
};

export const uploadEditPlansCSV = (formData, token) => {
  const headers = setHeaderContentMultipartFormDataWithAuth(token, formData);
  return axios.post("admin/import/edit-insurance-plans", formData, headers);
};

export const uploadEditPlansServicesCSV = (formData, token) => {
  const headers = setHeaderContentMultipartFormDataWithAuth(token, formData);
  return axios.post("admin/import/new-plans-services", formData, headers);
};

export const updatePlansServicesForm = (plans, reportId, token) => {
  const headers = setHeadersWithAuth(token);
  const body = { plans, reportId };
  return axios.patch("admin/services-form", body, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const updateReportServices = (reportId, services, formValues, token) => {
  const headers = setHeadersWithAuth(token);
  const body = { reportId, services, formValues };
  return axios.patch("admin/report-services-form", body, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};
