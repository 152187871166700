import React from "react";
import { Tooltip } from "components";
import { QuestionCircleOutlined } from "@ant-design/icons";

export const TableTitleTooltip = ({ title, tooltip }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <span>{title}</span>
    <Tooltip title={tooltip}>
      <QuestionCircleOutlined style={{ cursor: "help" }} />
    </Tooltip>
  </div>
);
