import React, { useState } from "react";
import {
  getGroup,
} from "services/api/groups.api";
import {
  Card, message, Form, Input, Button
} from "antd";
const { Item } = Form;

const ListGroupsStoredEmailsPanel = ({ }) => {
  const [searchForm] = Form.useForm();
  const [group, setGroup] = useState(null);

  const handleSearchGroup = async (changes) => {
    const response = await getGroup({ name: changes.search })
    if (response.status === 200) {
      const group = response.data.group;
      setGroup(group);
    } else {
      message.error(response.response.data.message);
    }
  }
  return (
    <Card title="Group's Stored Emails">
      <Form
        form={searchForm}
        layout="inline"
        onFinish={handleSearchGroup}
        style={{ marginBottom: "2%" }}
        initialValues={{
          search: "",
        }}
      >
        <Item name="search" rules={[{ required: true, message: "name required!" }]}>
          <Input autoComplete="off" />
        </Item>
        <Item>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Item>
      </Form>
      {group ? group?.userStoredEmails?.length > 0 ? group.userStoredEmails.map((emailObj, idx) => (
        <div key={idx}>{emailObj.email} - {emailObj.date}</div>
      )) : "0 emails found" : ""}
    </Card>
  );
};

export default ListGroupsStoredEmailsPanel;
