import React from "react";
import styled from "styled-components";
import { Text } from "components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50vw;
  min-width: 480px;
`;

const StepWrapper = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Bar = styled.hr`
  width: 100%;
  height: 5px;
  background: ${({ active }) =>
    active ? "#1c355e" : "rgba(33, 52, 91, 0.25)"};
  border: none;
  border-radius: 50px;
  margin-top: 1px;
`;

const ProgressTracker = ({ steps, currentStep }) => {
  return (
    <Wrapper>
      <FlexWrapper>
        {steps.map((_step, _idx) => (
          <StepWrapper key={_idx} active={_idx <= currentStep}>
            <Text
              blue={_idx <= currentStep}
              lightGrey={_idx > currentStep}
              fontSize="14"
            >
              {_step}
            </Text>
            <Bar active={_idx <= currentStep} />
          </StepWrapper>
        ))}
      </FlexWrapper>
    </Wrapper>
  );
};

export default React.memo(ProgressTracker);
