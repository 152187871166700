import joi from "joi";

const formSendSchema = joi.object({
  hospital: joi.string(),
  practice: joi.string(),
  proceduresTitle: joi.string(),
  insuranceRates: joi.string(),
  isDoctorInNetwork: joi.bool()
});

export default formSendSchema;
