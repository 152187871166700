import React from "react";
import styled from "styled-components";
import { CopyOutlined } from "@ant-design/icons";

const MiddleWrapper = styled.div`
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const ImageWrapper = styled.div`
  width: 100px;
  margin-right: 5%;
`;

const Image = styled.img`
  border-radius: 20%;
  width: 100px;
  height: 100px;
`;

const MiddleRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ onlyDisplayTwoRows }) => (onlyDisplayTwoRows ? `center` : `space-between`)};
  height: 100px;
`;

const Year = styled.p`
  font-size: 50px;
  line-height: 50px;
  font-weight: 700;
  margin: 0;
  max-height: ${({ onlyDisplayTwoRows }) => (onlyDisplayTwoRows ? `50px` : `40px`)};
`;

const MonthsRange = styled.p`
  font-size: 15px;
  font-weight: 400;
  text-wrap: nowrap;
  margin: 0;
  max-height: ${({ onlyDisplayTwoRows }) => (onlyDisplayTwoRows ? `50px` : `30px`)};
  color: #666666;
`;

const Copy = styled.p`
  font-size: 22px;
  font-weight: 400;
  margin: 0;
  max-height: 30px;
  color: #666666;
  cursor: pointer;

  :hover {
    color: #888;
  }
`;

const MyPlansMiddleWrapper = ({ title, imageSrc, year, monthsRange, handleOnClickCopy }) => {
  return (
    <MiddleWrapper>
      {imageSrc && (
        <ImageWrapper>
          <Image src={imageSrc} alt={title} />
        </ImageWrapper>
      )}
      <MiddleRightWrapper onlyDisplayTwoRows={!handleOnClickCopy}>
        <Year onlyDisplayTwoRows={!handleOnClickCopy}>{year}</Year>
        <MonthsRange onlyDisplayTwoRows={!handleOnClickCopy}>{monthsRange}</MonthsRange>
        {handleOnClickCopy && (
          <Copy onClick={() => handleOnClickCopy()}>
            <CopyOutlined /> Copy
          </Copy>
        )}
      </MiddleRightWrapper>
    </MiddleWrapper>
  );
};

export default MyPlansMiddleWrapper;
