import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import SelectField from "components/molecules/SelectField";
import CheckboxField from "components/molecules/CheckboxField";
import FormField from "components/molecules/FormField";
import { Space } from "antd";
import {
  copayOptions,
  networkOptions,
  OutOfNetworkCoverageOptions,
  yesOrNoOptions,
  haveToPayOptions,
} from "../utils/options";
import TableField from "components/molecules/TableField";
import TableMenu from "components/molecules/TableMenu";
import Text from "components/atoms/Text";
import styled from "styled-components";
import { formatterNumber, parserNumber } from "utils/currency";

const PremiumsTitlesWrapper = styled.div`
  @media (max-width: 768px) {
    padding: 28px;
  }
`;

const columns = (values, setFieldValue) => {
  const forceUpdate = values.forceUpdate;
  const currentService = values.currentServiceG1;
  const mappedColumns = [
    {
      title: "",
      dataIndex: "idx",
      width: 25,
      fixed: "left",
      editable: false,
    },
    {
      title: "Label",
      dataIndex: "label",
      width: 130,
      editable: true,
      inputType: "text",
      fixed: "left",
      shouldCellUpdate: () => false,
      disabled: true,
    },
    {
      title: "Insurance Network",
      dataIndex: "insuranceNetwork",
      className: "insuranceNetwork-col",
      width: 200,
      editable: true,
      inputType: "autocomplete",
      dropdownMatchSelectWidth: 350,
      options: networkOptions,
      shouldCellUpdate: () => false,
      disabled: true,
    },
    {
      title: "",
      dataIndex: "coverage",
      width: 110,
      inputType: "coverage",
      twice: true,
      editable: true,
    },
    {
      title: "You Have to Pay",
      dataIndex: "haveToPay",
      width: 140,
      dropdownMatchSelectWidth: 300,
      inputType: "select2xReadOnly",
      editable: true,
      twice: true,
      disabledValue: "coinsurance",
      options: haveToPayOptions,
      shouldCellUpdate: (record, prevRecord) =>
        forceUpdate ||
        record.services?.[currentService]?.haveToPay !== prevRecord.services?.[currentService]?.haveToPay,
    },
    {
      title: "Coinsurance Rate",
      dataIndex: "CoinsuranceRate",
      width: 115,
      editable: true,
      inputType: "rate2x",
      twice: true,
      disabled: true,
      shouldCellUpdate: (record, prevRecord) =>
        forceUpdate ||
        record.services?.[currentService]?.outOfNetworkCoverage !==
          prevRecord.services?.[currentService]?.outOfNetworkCoverage ||
        record.services?.[currentService]?.haveToPay !== prevRecord.services?.[currentService]?.haveToPay,
    },
    {
      title: `How much is the copay for ${currentService}?`,
      dataIndex: ["amount", "unit", "maxUnits"],
      width: 375,
      editable: true,
      inputType: "copay",
      options: copayOptions,
      shouldCellUpdate: (record, prevRecord) =>
        forceUpdate ||
        record.services?.[currentService]?.unit !== prevRecord.services?.[currentService]?.unit ||
        record.services?.[currentService]?.haveToPay !== prevRecord.services?.[currentService]?.haveToPay,
    },
    {
      title: "Subject to Deductible",
      dataIndex: "inNetworkSubjectToDeductible",
      width: 105,
      inputType: "select2xReadOnly",
      editable: true,
      twice: true,
      disabledValue: "yes",
      options: yesOrNoOptions,
      shouldUpdateCell: (record, prevRecord) =>
        forceUpdate ||
        record.services?.[currentService]?.deductibles !== prevRecord.services?.[currentService]?.deductibles,
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 1,
    },
  ].map((col) => {
    return {
      ...col,
      onCell: (_, idx) => ({
        index: idx,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: col.inputType,
        options: col.options,
        dropdownMatchSelectWidth: col.dropdownMatchSelectWidth,
        twice: col.twice,
        disabled: col.disabled || false,
        disableCondition: col.disableCondition,
        disabledValue: col.disabledValue,
        serviceOption: currentService,
      }),
    };
  });
  if (forceUpdate) setFieldValue("forceUpdate", false);
  return mappedColumns;
};

const filterColumns = (values, table, columns) => {
  const currentService = values.currentServiceG1;
  if (
    table &&
    !table.some(
      (element) =>
        element.services?.[currentService]?.haveToPay != undefined &&
        element.services?.[currentService]?.haveToPay !== "coinsurance",
    )
  )
    columns = columns.filter((element) => !element.title.match("How much is the copay for"));
  if (values && values.subjectToDeductibleG1) {
    columns = columns.filter((element) => element.title !== "Subject to Deductible");
  }
  return columns;
};

const shouldScroll = (values, filteredColumns) => {
  return filteredColumns.some((element) => element.title.match("How much is the copay for")) &&
    values &&
    !values.subjectToDeductibleG2
    ? "80vw"
    : false;
};

const CoverageG1 = ({ isMasterReport, updateValidationSchema }) => {
  let { values, setFieldValue } = useFormikContext();
  let { expectedTable, table, currentServiceG1 } = values;
  const group1Name = values.group1;

  let serviceList = [];

  if (isMasterReport) {
    serviceList = [
      "Inpatient Hospital Stay",
      "Primary Care Sick Visit",
      "Specialist Visit",
      "Labwork",
      "Outpatient Surgery Physician Fees",
      "Urgent Care",
      "Therapy",
      "Rehabilitation Services (PT, OT, Chiropractor)",
      "Fertility Services (IVF, Egg Freezing)",
    ];
  } else {
    serviceList = [
      ...new Set(expectedTable.map((element) => element.billedAs).filter((element) => element !== "Preventive Care")),
    ];
  }
  serviceList = serviceList.map((element) => {
    return {
      name: element,
      value: element,
    };
  });

  const defaultCurrentService =
    serviceList && serviceList.length > 1 && serviceList[0].name === "Inpatient Hospital Stay"
      ? serviceList[1].name
      : serviceList[0].name;

  useEffect(() => {
    document.getElementById("form-content").scrollTo({ top: 0, behavior: "smooth" });
    // to prevent weird interactions between formik and api call
    // this is a hacky solution, but I didn't have time to find a better one
    setTimeout(() => {
      setFieldValue("forceUpdate", true);
      values.forceUpdate = true;
      if (
        defaultCurrentService &&
        (!currentServiceG1 || (currentServiceG1 && !serviceList.map((el) => el.value).includes(currentServiceG1)))
      ) {
        setFieldValue("currentServiceG1", defaultCurrentService);
      }
      setFieldValue(
        "serviceList",
        serviceList.map((el) => el.value),
      );
    }, 1);
  }, []);

  useEffect(() => {
    updateValidationSchema && updateValidationSchema();
  }, [values.serviceList]);

  useEffect(() => {
    setFieldValue("forceUpdate", true);
    values.forceUpdate = true;
  }, [currentServiceG1]);

  useEffect(() => {
    table.forEach((element) => {
      serviceList
        .map((service) => service.name)
        .filter((service) => service !== "Inpatient Hospital Stay")
        .forEach((service) => {
          if (!element.services[service]) {
            element.services[service] = {};
          }
          if (
            element.services?.[service]?.haveToPay == undefined &&
            element.services?.["Inpatient Hospital Stay"]?.haveToPay === "coinsurance"
          ) {
            element.services[service].haveToPay = "coinsurance";
            if (element.services?.[service].inNetworkCoinsuranceRate == undefined) {
              element.services[service].inNetworkCoinsuranceRate =
                element.services?.["Inpatient Hospital Stay"]?.inNetworkCoinsuranceRate;
            }
          }
          if (element.outOfNetworkCoverage === "yes") {
            element.services[service].outOfNetworkCoinsuranceRate =
              element.services?.["Inpatient Hospital Stay"]?.outOfNetworkCoinsuranceRate;
          }
        });
    });
    setFieldValue("table", table);
  }, [table]);

  const filteredColumns = filterColumns(values, table, columns(values, setFieldValue));

  return (
    <PremiumsTitlesWrapper>
      <Text sectionTitle>How are these visits, drugs, and services covered by {group1Name}?</Text>
      <SelectField
        name="currentServiceG1"
        options={serviceList}
        label="Coverage for"
        defaultValue={defaultCurrentService}
        inline
      />
      {currentServiceG1 === "Fertility Services (IVF, Egg Freezing)" && (
        <>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Text small noWrap>
              {group1Name} covers fertility services for a specific
            </Text>
            <SelectField
              name="FSTypeG1"
              options={[
                { name: "$ amount", value: "amount" },
                { name: "number of cycles", value: "units" },
              ]}
              style={{
                margin: "5px",
                maxWidth: "200px",
              }}
              defaultValue={"amount"}
            />
            <Text small noWrap>
              , for up to
            </Text>
            {values.FSTypeG1 === "units" ? (
              <div
                style={{
                  marginLeft: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormField name="FSMaxUnitsG1" size="fullwidth" type="number" absoluteErrorMessage />
                <Text small noWrap style={{ marginLeft: "5px" }}>
                  cycles
                </Text>
              </div>
            ) : (
              <div
                style={{
                  marginLeft: "5px",
                }}
              >
                <FormField
                  name="FSMaxAmountG1"
                  size="fullwidth"
                  style={{
                    minWidth: "140px",
                  }}
                  min={0}
                  defaultValue="$"
                  formatter={formatterNumber}
                  parser={parserNumber}
                  type="number"
                  absoluteErrorMessage
                />
              </div>
            )}
          </div>
        </>
      )}
      <CheckboxField
        name="subjectToDeductibleG1"
        label="I always have to hit my deductible first"
        style={{ marginTop: "10px" }}
      />
      <TableField
        name="table"
        columns={filteredColumns}
        dataSource={table}
        scroll={{ x: shouldScroll(values, filteredColumns) }}
        tableLayout="fixed"
      />
    </PremiumsTitlesWrapper>
  );
};

export default CoverageG1;
