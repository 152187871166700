import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import Text from "components/atoms/Text";
import { Space, Table } from "antd";
import { networkOptions, categoryOptions } from "../utils/options";
import TableMenu from "components/molecules/TableMenu";
import TableField from "components/molecules/TableField";
import CheckboxField from "components/molecules/CheckboxField";

import FormField from "components/molecules/FormField";
import { useSelector } from "react-redux";

const handledCategoryOptions = (table) => {
  const usedCategoryOptions = [...new Set(table.map((item) => item.planName))].filter(
    (value) => value != undefined && value !== "",
  );
  if (usedCategoryOptions.length >= 4) {
    if (!usedCategoryOptions.some((value) => value === "Catastrophic")) usedCategoryOptions.push("Catastrophic");
    return usedCategoryOptions.sort().map((value) => {
      return { value: value, label: value };
    });
  } else return categoryOptions;
};

const columns = (table) =>
  [
    {
      title: "",
      dataIndex: "idx",
      width: 20,
      editable: true,
      disabled: true,
      fixed: "left",
    },
    {
      title: "Label",
      dataIndex: "label",
      width: 125,
      editable: true,
      inputType: "text",
      shouldCellUpdate: () => false,
      fixed: "left",
    },
    {
      title: "Insurance Network",
      dataIndex: "insuranceNetwork",
      className: "insuranceNetwork-col",
      width: 200,
      editable: true,
      inputType: "autocomplete",
      dropdownMatchSelectWidth: 350,
      options: networkOptions,
      shouldCellUpdate: () => false,
    },
    {
      title: "Category",
      dataIndex: "planName",
      width: 100,
      editable: true,
      inputType: "autocomplete",
      dropdownMatchSelectWidth: 350,
      options: handledCategoryOptions(table),
      shouldCellUpdate: () => false,
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 48,
      render: (_, record) => <TableMenu step={7} record={record} />,
    },
  ].map((col) => {
    return {
      ...col,
      onCell: (_, idx) => ({
        index: idx,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: col.inputType,
        options: col.options,
        dropdownMatchSelectWidth: col.dropdownMatchSelectWidth,
        min: col.min,
      }),
    };
  });

const Step = () => {
  const { values, setFieldValue, setErrors, setTouched } = useFormikContext();
  const { planOptions, table, dontAddMoreCategories } = values;

  const categoryOptionsLength = [...new Set(table.map((item) => item.planName))].filter(
    (value) => value != undefined && value !== "" && value !== "Catastrophic",
  ).length;

  useEffect(() => {
    document.getElementById("form-content").scrollTo({ top: 0, behavior: "smooth" });
    setErrors({});
    setTouched({}, false);
    setFieldValue("categoryOptionsLength", categoryOptionsLength);
  }, []);

  useEffect(() => {
    setFieldValue("categoryOptionsLength", categoryOptionsLength);
  }, [table]);

  return (
    <>
      <Text sectionTitle>What would you like to call these plans?</Text>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <FormField placeholder="For example, 'My Wife's Job' or 'Starbucks'" name="group" type="text" required />
      </Space>
      <br />
      {planOptions < 4 ? <CheckboxField name="dontAddMoreCategories" label="Don't add more categories" /> : null}
      {planOptions >= 4 || !dontAddMoreCategories ? (
        <>
          <Text sectionTitle>And how would you like to organize these plans?</Text>
          <TableField name="table" columns={columns(table)} dataSource={table} tableLayout="fixed" />
          {handledCategoryOptions(table).length !== categoryOptions.length && categoryOptionsLength > 4 ? (
            <Text small red margin={"10px"}>
              ⚠ You can have at most four categories + Catastrophic
            </Text>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default Step;
