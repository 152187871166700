import { connect } from "react-redux";
import Calculate from "../components/organisms/PredictedTotal";

const mapStateToProps = state => ({
  ...state.formData,
  isDoctorInNetwork: state.plan.isDoctorInNetwork.value,
  isDoctorInNetworkPrev: state.plan.isDoctorInNetwork.value
});

export default connect(mapStateToProps)(Calculate);
