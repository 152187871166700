import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Text } from "components";

const Wrapper = styled.div`
  min-height: 194px;
  width: 310px;
  border-radius: 10px;
  overflow: hidden;
`;

const Header = styled.div`
  height: 35px;
  width: inherit;
  background-color: #51a5a2;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
`;

const Body = styled.div`
  min-height: 163px;
  width: inherit;
  background-color: #f8f7f7;
  padding: 5px 15px;
`;

const BodyItem = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function Billing({ subscriptionHistory }) {
  const [form, setForm] = useState({
    subscriptionHistory: [],
  });

  useEffect(() => {
    if (subscriptionHistory) {
      setForm({
        subscriptionHistory: subscriptionHistory,
      });
    }
  }, [subscriptionHistory]);
  return (
    <Wrapper>
      <Header>
        <Text white bold fontSize="14">
          Date
        </Text>
        <Text white bold fontSize="14">
          Amount
        </Text>
      </Header>
      <Body>
        {form.subscriptionHistory.map((subscriptionEvent, i) => (
          <BodyItem key={i}>
            <Text black fontSize="14">
              {subscriptionEvent.date}
            </Text>
            <Text black fontSize="14">
              ${subscriptionEvent.amount}
            </Text>
          </BodyItem>
        ))}
      </Body>
    </Wrapper>
  );
}

export default Billing;
