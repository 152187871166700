import React, { useEffect, useState } from "react";

import { searchReport, getPlansArr, getAllUsers, cloneReportForUsers } from "services/api/admin.api";
import { getToken } from "services/utils";

import { Form, Input, Button, message, Modal, Select } from "antd";

const { Item } = Form;

const CloneReportModal = ({setAndSearchReport}) => {
  const [cloneReportForm] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false)
  const [usersOptions, setUsersOptions] = useState([])

  const loadUserOptions = async () => {
    const token = await getToken();
    const users = await getAllUsers(token);
    return users
  }

  useEffect( async () => {
    const options =  await loadUserOptions()
    setUsersOptions(options.data) 
  }, [])
  
  const handleSubmit = async ({users, reportId}) => {
    const token = await getToken();
    try{
      const {data, status} = await cloneReportForUsers(reportId, users, token)
      if (status === 200 || status === 206 ) {
        setModalVisible(false)
        cloneReportForm.resetFields()
        if(status == 206) {
          message.warn("Report failed to attach to some users")
        } else {
          message.success(`Report cloned successfully`)
        }
        setAndSearchReport(data.report._id)
      }
    }catch(e) {
      const messageError = e.response ? e.response.data.message : 'Report not found' 
      message.error(messageError)
    }
  }

  const handleCancel = () => {
    setModalVisible(false)
  }

  return( 
    <>
      <Button onClick={() => setModalVisible(!modalVisible)} type="primary">
        Clone Report
      </Button>
      <Modal 
        title="Clone Report"
        visible={modalVisible}
        onCancel={handleCancel}
        footer={[
          <Button form="cloneReportForm" key="submit" htmlType="submit">
              Submit
          </Button>
          ]}
      >
        <Form 
          id="cloneReportForm"
          layout="vertical"
          onFinish={handleSubmit}
          form={cloneReportForm}
        >
          <Item
            label="Users"
            name="users"
            rules={[{ required: true, message: "User required!" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              mode="multiple"
              placeholder="Select at least one user"
              filterOption  = {(input, option) => 
                option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {
                usersOptions.map(user => 
                  <Select.Option value={user._id}>
                    {user.username}
                  </Select.Option>
                )
              }
            </Select>
          </Item>
          <Item
            label="Report ID"
            name="reportId"
            rules={[{ required: true, message: "Report ID required!" }]}
          >
            <Input />
          </Item>
        </Form>
      </Modal>
    </>
  )
}

const ReportSearch = ({ disable, handleChange }) => {
  const [form] = Form.useForm();

  const setAndSearchReport = (id) => {
    onClear();
    form.setFieldsValue({id})
    onSubmit({id})
  }

  const onSubmit = async ({ id }) => {
    const token = await getToken();
    const response = await searchReport(id, token);

    const { status, data } = response;
    if (status === 200 || status === 206) {
      const { report, plansArr, services } = data;

      const getNewServices = parent => {
        // separates plans array based on out-of-network coverage
        const { planIds } = parent;
        const parentPlans = plansArr.filter(plan =>
          planIds.includes(plan.externalId)
        );
        const parentPlansWithOON = parentPlans.filter(
          plan => plan.outOfNetwork.coverage
        );

        let newOutOfNetworkServices = { reimbursementRates: { default: null } };

        parentPlansWithOON.forEach(
          plan =>
            (newOutOfNetworkServices.reimbursementRates[plan.externalId] = 0)
        );

        return newOutOfNetworkServices;
      };

      let newReport = report;

      if (!report.birthParent.outOfNetworkServices) {
        newReport.birthParent.outOfNetworkServices = getNewServices(
          report.birthParent
        );
      }

      if (!report.partner.outOfNetworkServices) {
        newReport.partner.outOfNetworkServices = getNewServices(report.partner);
      }

      let birthParentPlans = [],
        partnerPlans = [];
      if (report.birthParent.planIds.length) {
        const plans = await getPlansArr(report.birthParent.planIds, token);
        if (plans.status === 200) {
          birthParentPlans = plans.data.plansArr;
        }
      }

      if (report.partner.planIds.length) {
        const plans = await getPlansArr(report.partner.planIds, token);
        if (plans.status === 200) {
          partnerPlans = plans.data.plansArr;
        }
      }

      handleChange({
        search: id,
        report: newReport,
        birthParentPlans,
        partnerPlans,
        services
      });
    } else {
      message.error("Report not found.");
    }
  };

  const onClear = () => {
    form.resetFields();
    handleChange({
      search: null,
      report: null,
      plans: [],
      services: [],
    });
  };

  const onFail = () => {
    console.log("Invalid Submission");
  };

  return (
      <Form
        form={form}
        layout="inline"
        onFinish={onSubmit}
        onFinishFailed={onFail}
      >
        <Item
          label="Search By Report ID"
          name="id"
          rules={[{ required: true, message: "Report ID required!" }]}
        >
          <Input />
        </Item>

        <Item>
          <Button disabled={disable} type="primary" htmlType="submit">
            Search
          </Button>
        </Item>

        {disable && (
          <Item>
            <Button onClick={onClear}>Reset</Button>
          </Item>
        )}

        <CloneReportModal
          setAndSearchReport={setAndSearchReport}
        />
      </Form>
  );
};

export default ReportSearch;
