import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  ${({ isContentHover }) => isContentHover && "position: relative;"}
  background-color: ${({ secondaryBackground }) => secondaryBackground && "#d9f3f1"};
`;

const Overlay = styled.div`
  background-color: #000;
  height: 100%;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 100;
`;

const CloseIcon = styled(CloseOutlined)`
  position: absolute;
  top: 10px;
  right: 30px;
  cursor: pointer;
  z-index: 101;
  height: 30px;
  width: 30px;

  svg {
    height: 30px;
    width: 30px;
    color: #fff;
  }
`;

const Header = styled.header`
  width: 100%;
  background: #1c355e;

  ${({ newHeader }) =>
    newHeader
      ? `
          height: 85px;
          background: #142444;
          margin: 0 auto;
        `
      : `
          background: #1c355e;
          height: 76px;
        `}
  padding: ${({ padding }) => padding};
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 101;
  border-radius: 30px 30px 0 0;
  overflow: hidden;
  max-width: 100vw;
  width: 100vw;
`;

const Content = styled.div`
  width: 100%;
  ${({ newHeader }) => newHeader && "margin-top: 95px;"}

  @media (max-width: 800px) {
    ${({ newHeader }) => newHeader && "margin-top: 40px;"}
  }

  ${({ isContentHover }) =>
    isContentHover &&
    `
    max-height: 90vh;
    background-color: #fff;
    border-radius: 30px 30px 0 0;
    overflow-y: scroll;
  `}
`;

const Footer = styled.footer`
  margin-top: auto;
`;

const PageTemplate = ({
  header,
  children,
  footer,
  newHeader,
  padding,
  isContentHover,
  overrideCloseIconOnClick,
  ...props
}) => {
  const history = useHistory();

  return (
    <Wrapper isContentHover={isContentHover} {...props}>
      {isContentHover ? (
        <>
          <Overlay />
          <CloseIcon onClick={() => (overrideCloseIconOnClick ? (window.location.href = "/") : history.goBack())} />
        </>
      ) : (
        <></>
      )}
      <Header newHeader={newHeader} padding={padding}>
        {header}
      </Header>
      {isContentHover ? (
        <ContentWrapper>
          <Content isContentHover={isContentHover} newHeader={newHeader} id={isContentHover ? "form-content" : ""}>
            {children}
          </Content>
        </ContentWrapper>
      ) : (
        <Content isContentHover={isContentHover} newHeader={newHeader}>
          {children}
        </Content>
      )}
      {footer && <Footer>{footer}</Footer>}
    </Wrapper>
  );
};

PageTemplate.propTypes = {
  header: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  children: PropTypes.any.isRequired,
};

export default PageTemplate;
