import { useMemo } from "react";

/**
 * @param {Object} options - The options object.
 * @param {import("doctypes/plans").PlanDetails} options.plan - The item to select the value from.
 * @param {"samePlan" | "separatePlans"} options.planMode - The plan mode ("samePlan" or "separatePlans").
 * @param {"monthly" | "yearly"} options.costPeriod - The cost period ("monthly" or "yearly").
 * @param {"birthParent" | "partner"} options.children - The childrenMode ("birthParent" or "partner").
 * @param {boolean} options.hasKid - Whether the user has a kid.
 * @param {boolean} options.isSpouseTable - Whether the table is for the spouse.
 *
 * @returns {number} The value of the item.
 */
const selectValue = ({ plan, plansMode, costPeriod, children, hasKid, isSpouseTable }) => {
  if (plansMode === "samePlan") {
    return plan.costs?.samePlan[costPeriod]["grandTotal"];
  } else {
    if (children === "birthParent") {
      if (!isSpouseTable && hasKid) {
        return plan.costs?.separatePlans?.withChildren[costPeriod]["grandTotal"];
      } else {
        return plan.costs?.separatePlans?.individual[costPeriod]["grandTotal"];
      }
    } else {
      if (isSpouseTable && hasKid) {
        return plan.costs?.separatePlans?.withChildren[costPeriod]["grandTotal"];
      } else {
        return plan.costs?.separatePlans?.individual[costPeriod]["grandTotal"];
      }
    }
  }
};
/**
 * Returns the range of plans categories for a given person type.
 * @param {"birthParent" | "partner"} options.personType - The person type ("birthParent" or "partner").
 * @param {Object} options - The options object.
 * @param {number} options.plansCategoriesCount - The number of plans categories.
 * @returns {Array<number>} The range of plans categories.
 * @throws {Error} If an invalid person type is provided.
 */
const getPlansRangeForPersonType = (personType, { plansCategoriesCount }) => {
  const birthParentPlanRange = [0, plansCategoriesCount]; // Plan for main user comes first
  const partnerPlanRange = [plansCategoriesCount, plansCategoriesCount * 2]; // Then comes plans for partner
  const plansRange =
    personType === "birthParent" ? birthParentPlanRange : personType === "partner" ? partnerPlanRange : null;

  if (!plansRange) throw new Error(`Invalid type: ${personType}`);

  return plansRange;
};
/**
 * Returns the plans for a given person type.
 * @param {"birthParent" | "partner"} options.personType - The person type ("birthParent" or "partner").
 * @param {Object} options - The options object.
 * @param {Array<Object>} options.allNetworksPlansCategories - The plans categories for all networks.
 * @param {number} options.plansCategoriesCount - The number of plans categories.
 * @returns {Array<Object>} The plans for the given person type.
 * @throws {Error} If an invalid person type is provided.
 */
const getPlansForPersonType = (personType, { allNetworksPlansCategories, plansCategoriesCount }) => {
  const plansRange = getPlansRangeForPersonType(personType, { plansCategoriesCount });

  return allNetworksPlansCategories
    .flatMap((categories) => categories.slice(...plansRange))
    .flatMap((category) => category.plans);
};
/**
 * Returns the values for a given person type.
 * @param {"birthParent" | "partner"} options.personType - The person type ("birthParent" or "partner").
 * @param {Object} options - The options object.
 * @param {Array<Object>} options.allNetworksPlansCategories - The plans categories for all networks.
 * @param {number} options.plansCategoriesCount - The number of plans categories.
 * @param {"samePlan" | "separatePlans"} options.plansMode - The plan mode ("samePlan" or "separatePlans").
 * @param {"monthly" | "yearly"} options.costPeriod - The cost period ("monthly" or "yearly").
 * @param {"birthParent" | "partner"} options.childrenMode - The childrenMode ("birthParent" or "partner").
 * @param {boolean} options.isSpouseTable - Whether the table is for the spouse.
 * @param {boolean} options.hasKid - Whether the user has a kid.
 * @returns {Array<number>} The values for the given person type.
 * @throws {Error} If an invalid person type is provided.
 *
 */
export const getPlanValuesForPersonType = (
  personType,
  { allNetworksPlansCategories, plansCategoriesCount, plansMode, costPeriod, childrenMode, hasKid },
) => {
  const plans = getPlansForPersonType(personType, { allNetworksPlansCategories, plansCategoriesCount });
  const isSpouseTable = personType === "partner";
  return plans.map((plan) => selectValue({ plan, plansMode, costPeriod, childrenMode, hasKid, isSpouseTable }));
};
/**
 * Calculates the minimum, maximum, and average values of an array of numbers.
 * @param {number[]} plansArray - The array of numbers to calculate stats for.
 * @returns {{min: number, max: number, avg: number}} An object containing the minimum, maximum, and average values of the input array.
 */
export const calculatePlansStats = (plansValuesArray) => {
  const min = Math.min(...plansValuesArray);
  const max = Math.max(...plansValuesArray);
  const avg = (min + max) / 2;
  return { min, max, avg };
};

/**
 * Calculates statistics for all plans based on various input parameters.
 * @param {Object} options - An object containing the input parameters for the calculation.
 * @param {Array} options.birthParentPlans - An array of plans for the birth parent.
 * @param {Array} options.partnerPlans - An array of plans for the partner.
 * @param {Array} options.broaderNetworks - An array of objects representing broader networks.
 * @param {number} options.plansCategoriesCount - The number of plan categories.
 * @param {"samePlan" | "separatePlans"} options.plansMode - The plan mode ("samePlan" or "separatePlans").
 * @param {"monthly" | "yearly"} options.costPeriod - The cost period ("monthly" or "yearly").
 * @param {"birthParent" | "partner"} options.childrenMode - The childrenMode ("birthParent" or "partner").
 * @param {boolean} options.hasKid - Whether the user has a child.
 * @param {Object} options.report - An object containing a report for the user.
 *
 * @returns {{birthParentPlansStats: import("doctypes/plans").PlansStatistics, partnerPlansStats: import("doctypes/plans").PlansStatistics, allPlansStats: import("doctypes/plans").PlansStatistics, totalPlansCount: number}} An object containing the statistics for the birth parent plans, the partner plans, the combined plans, and the total number of plans.
 *
 */
export const useAllMyOptionsPlansStatistics = ({
  birthParentPlans,
  partnerPlans,
  broaderNetworks,
  plansCategoriesCount,
  plansMode,
  costPeriod,
  childrenMode,
  hasKid,
  report,
}) => {
  const allNetworksPlansCategories = broaderNetworks.map(({ plansNames }) => plansNames);

  const birthParentPlansArray = getPlanValuesForPersonType("birthParent", {
    allNetworksPlansCategories,
    plansCategoriesCount,
    plansMode,
    costPeriod,
    childrenMode,
    hasKid,
  });

  const partnerPlansArray = getPlanValuesForPersonType("partner", {
    allNetworksPlansCategories,
    plansCategoriesCount,
    plansMode,
    costPeriod,
    childrenMode,
    hasKid,
  });

  const birthParentPlansStats = calculatePlansStats(birthParentPlansArray);
  const partnerPlansStats = calculatePlansStats(partnerPlansArray);
  const allPlansStats = calculatePlansStats([...birthParentPlansArray, ...partnerPlansArray]);

  const totalPlansCount = useMemo(() => {
    const birthParentOptions = birthParentPlans?.length;
    const partnerOptions = partnerPlans?.length;
    const spouseFormula =
      birthParentOptions + partnerOptions + (1 + (hasKid ? 1 : 0)) * (birthParentOptions * partnerOptions);
    const jobOfferFormula = birthParentOptions + partnerOptions;
    return report.partner?.justForComparison === "jobOffer" ? jobOfferFormula : spouseFormula;
  }, [birthParentPlans, partnerPlans, hasKid, report]);

  return {
    birthParentPlansStats,
    partnerPlansStats,
    allPlansStats,
    totalPlansCount,
  };
};
