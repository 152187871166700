import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "components";

const Form = styled.form`
  max-width: 550px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  align-items: center;
  justify-items: center;
`;

const Datepicker = styled.input.attrs({ type: "date" })`
  background-color: rgba(81, 165, 162, 0.15);
  width: 310px;
  height: 60px;
  outline: none;
  border: none;
  padding: 5px 25px;
  border-radius: 15px;
  color: rgba(81, 165, 162);
`;

const CheckboxWrapper = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  margin: 5px 7px;
`;

const Label = styled.label`
  margin: 5px 7px;
`;

const formatFormDateValue = (_val) => {
  const array = _val?.split("-"); // MM-DD-YYYY
  const formattedDate = array ? [array[2], array[0], array[1]].join("-") : null;
  return formattedDate;
};

const formatDatepickerValue = (_val) => {
  const array = _val?.split("-"); // YYYY-MM-DD
  const formattedDate = array ? [array[1], array[2], array[0]].join("-") : null;
  return formattedDate;
};

const DatepickerCustom = ({ name, value, handleChange, max, min, allowNA }) => {
  const [date, setDate] = useState(formatFormDateValue(value));
  const [checked, setChecked] = useState(value === "n/a");

  const onChangeDate = (e) => {
    setDate(e.target.value);
    setChecked(false);
    handleChange({ name, value: formatDatepickerValue(e.target.value) });
  };

  const onChangeChecked = () => {
    setChecked(true);
    setDate("");
    handleChange({ name, value: "n/a" });
  };

  return (
    <Form>
      <Datepicker value={date} onChange={onChangeDate} min={min} max={max} />
      {allowNA && (
        <CheckboxWrapper>
          <Checkbox
            id={name}
            name={name}
            checked={checked}
            onClick={onChangeChecked}
          />
          <Label for={name}>
            <Text fontSize="14">We don't have a date yet</Text>
          </Label>
        </CheckboxWrapper>
      )}
    </Form>
  );
};

export default React.memo(DatepickerCustom);
