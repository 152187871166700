import React from "react";
import styled from "styled-components";
import Text from "components/atoms/Text";
import { Radio } from "antd";
import styles from "./styles.css"

const Wrapper = styled.div`
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${(props) => props.step === 0 ? "none" : " translateX(-100%)"};
  opacity: ${(props) => props.step === 0 ? 1 : 0};
  visibility: ${(props) => props.step === 0 ? "visible" : "hidden"};
  transition: 600ms;
`

const Questions = ({ title, step, option, setOption }) => {
  return (
    <div style={{ position: "relative" }}>
      <Wrapper step={step}>
        <Text sectionTitle left styles={{ marginBottom: 0 }}>{`Awesome! ${title} plans are synced and available in our systems. How would you like to verify your access to these plans?`}</Text>
        <Radio.Group value={option} onChange={(e) => setOption(e.target.value)}>
          <Radio value="currentEmployee">I'm a current employee</Radio>
          <Radio value="jobOffer">I have a job offer to join this employer</Radio>
          <Radio value="formerEmployee">I'm a former employee with access to COBRA</Radio>
        </Radio.Group>
      </Wrapper >
    </div>
  )
}

export default Questions;