import { useFormikContext } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 100px;
  min-height: calc(100vh);

  @media (min-width: 768px) {
    width: 90%;
    margin: 50px auto 100px auto;
  }
`;

const AddServicesSteps = ({ report, isMasterReport, updateValidationSchema }) => {
  let steps = [...useSelector((state) => state.plan.serviceSteps)];
  if (isMasterReport) {
    steps = [steps[1]];
  } else if (!report.birthParent.canEditPlans && !report.partner.canEditPlans) {
    steps.splice(1, 2);
  } else if (!report.partner.canEditPlans || !report.partner.planIds?.length) {
    steps.splice(2, 1);
  } else if (!report.birthParent.canEditPlans) {
    steps.splice(1, 1);
  }
  const current = useSelector((state) => state.plan.current);

  if (isMasterReport) {
    steps[current].content = React.cloneElement(steps[current].content, { isMasterReport, updateValidationSchema });
  }

  return <Wrapper>{steps[current].content}</Wrapper>;
};

export default AddServicesSteps;
