import React from "react";

// import { Input, InputNumber } from "antd";
import { useFormikContext, useField } from "formik";
import Text from "components/atoms/Text";
import { Input, InputNumber } from "formik-antd";
import styled, { css } from "styled-components";
import RequiredField from "../../atoms/RequiredField";
import "./styles.css";

const StyledInput = styled(Input)`
  height: 32px !important;
  font-size: 18px !important;
  color: #344054 !important;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd !important;
  padding: 20px 10px !important;
  min-height: max-content !important;
  width: 100% !important;

  ${({ size }) => size === "small" && `width: 15% !important`};
  ${({ size }) => size === "fullwidth" && `width: 100% !important`};
`;

const StyledInputTextArea = styled(Input.TextArea)`
  height: 100% !important;
  font-size: 18px !important;
  color: #344054 !important;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd !important;
  padding: 0px 10px 40px 10px !important;
  min-height: max-content !important;
  width: 100% !important;
  resize: none;

  ${({ size }) => size === "small" && `width: 15% !important`};
  ${({ size }) => size === "fullwidth" && `width: 100% !important`};
`;

const StyledInputNumber = styled(InputNumber)`
  height: 40px !important;
  font-size: 18px !important;
  color: #344054 !important;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd !important;
  padding: 0 !important;

  .ant-input-number-input-wrap {
    height: 100% !important;

    input {
      height: 100% !important;
      padding: 5px 10px !important;
    }
  }
  ${({ showArrows }) => `
    .ant-input-number-handler-wrap {
      display: ${showArrows ? "block" : "none"} !important;
    }`}
`;

const Wrapper = styled.div`
  @media (min-width: 768px) {
    ${({ maxHalf }) => maxHalf && `max-width: 50% !important`};
  }

  ${({ inline }) =>
    inline &&
    css`
      display: flex;
      > * {
        margin-right: 10px;
      }
    `}

  @media (max-width: 768px) {
    flex-direction: column !important;
  }
`;

const FormField = ({
  label,
  type,
  name,
  setExpanded,
  condition,
  size,
  inline,
  required,
  touched,
  hyperlink,
  showArrows,
  maxLength,
  handleOnChange = () => {},
  index,
  dataIndex,
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext();
  const { networksTable } = values;
  const isOnNetworksTable = name.includes("networksTable");

  const [field, meta, helpers] = useField(name);

  return (
    <div key={name} style={{ position: "relative" }}>
      <Wrapper inline={inline} maxHalf={!inline && type === "text"}>
        {label && (
          <Text formLabel>
            {label}
            {hyperlink && (
              <a href={hyperlink.url} target="_blank" rel="noopener noreferrer">
                {hyperlink.label}
              </a>
            )}
            {required && <RequiredField error={(meta.touched || touched || touched) && meta.error} />}
          </Text>
        )}
        {type === "number" ? (
          <StyledInputNumber
            min={0}
            {...field}
            {...props}
            key={name}
            size={size}
            onChange={(value) => {
              handleOnChange(value);
              helpers.setValue(Number(value));
              if (condition) {
                if (value > 0) {
                  setExpanded(true);
                } else {
                  setExpanded(false);
                }
              }
            }}
            keyboard={false}
            status={(meta.touched || touched) && meta.error && "error"}
            showArrows={showArrows}
          />
        ) : type === "textarea" ? (
          <StyledInputTextArea
            {...field}
            {...props}
            size={size}
            key={name}
            status={(meta.touched || touched) && meta.error && "error"}
            className={
              isOnNetworksTable &&
              networksTable &&
              !name.includes("name") &&
              networksTable[index].isSuggested?.[`${dataIndex}`]
                ? "suggestion"
                : ""
            }
            onFocus={() => {
              isOnNetworksTable &&
                networksTable &&
                setFieldValue(`networksTable[${index}].isSuggested.${dataIndex}`, false);
            }}
          />
        ) : (
          <StyledInput
            {...field}
            {...props}
            size={size}
            key={name}
            status={(meta.touched || touched) && meta.error && "error"}
            maxLength={maxLength}
            className={
              isOnNetworksTable &&
              networksTable &&
              !name.includes("name") &&
              networksTable[index].isSuggested?.[`${dataIndex}`]
                ? "suggestion"
                : ""
            }
            onFocus={() => {
              isOnNetworksTable &&
                networksTable &&
                setFieldValue(`networksTable[${index}].isSuggested.${dataIndex}`, false);
            }}
          />
        )}
      </Wrapper>
      {(meta.touched || touched) && meta?.error && (
        <Text red style={{ position: props.absoluteErrorMessage ? "absolute" : "inherit" }}>
          {meta.error}
        </Text>
      )}
    </div>
  );
};

export default FormField;
