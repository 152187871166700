import React, { useEffect, useMemo, useState } from "react";
import { EventOne } from "services/google";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { PageTemplate, newHeader as Header, Text, Link } from "components";
import { PageView } from "services/google";
import { AuthenticationFormSection, AuthenticationFormBlock } from "components";
import Actions from "store/auth/actions";
import { useParams } from "react-router-dom";

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 580px) {
    width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const RegisterPage = () => {
  const dispatch = useDispatch();

  const { email } = useParams();

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: email || "",
    password: "",
    passwordConfirm: "",
    agreeTerms: false,
  });
  const [signUpError, setSignUpError] = useState("");

  useEffect(() => {
    PageView();
  }, []);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === "agreeTerms") {
      setFormState({ ...formState, agreeTerms: !formState.agreeTerms });
    } else setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      firstName,
      lastName,
      email,
      password,
      passwordConfirm,
      agreeTerms,
    } = formState;

    if (!agreeTerms) {
      setSignUpError("Please agree to our terms & conditions to sign up");
    } else if (
      !firstName ||
      !lastName ||
      !email ||
      !password ||
      !passwordConfirm
    ) {
      setSignUpError("Please fill in all the required information");
    } else if (!validation.isLong) {
      setSignUpError("Password must be at least 8 characters");
    } else if (!validation.isUpperLower) {
      setSignUpError("Passowrd must have both uppercase and lowercase letters");
    } else if (!validation.isNumber) {
      setSignUpError("Password must have at least 1 number");
    } else if (!validation.isSpecial) {
      setSignUpError("Password must have at least 1 special character");
    } else if (password !== passwordConfirm) {
      setSignUpError("Hmm... it looks like your passwords don't match");
    } else {
      EventOne("Onboarding", "Sign up", "user signed up");
      const form = {
        firstName: formState.firstName,
        lastName: formState.lastName,
        email: formState.email,
        password: formState.password,
      };
      // dispatch(Actions.Creators.registerRequest(form, destinationRoute));
      dispatch(Actions.Creators.registerRequest(form, "typeform"));
      setSignUpError("");
    }
  };

  const destinationRoute = useSelector(
    (state) => state.router.location.destinationRoute
  );
  const error = useSelector((state) => state.auth.error);

  const validation = useMemo(() => {
    return {
      isLong: formState.password.length >= 8,
      isUpperLower:
        /([A-Z])/.test(formState.password) &&
        /([a-z])/.test(formState.password),
      isNumber: /([0-9])/.test(formState.password),
      isSpecial: /[#?!@$%^&*-]/.test(formState.password),
      isSame: formState.password === formState.passwordConfirm,
    };
  }, [formState]);

  const validPasswordText = useMemo(
    () => (
      <>
        <Text>Password must contain:</Text>
        <Text red={!validation.isLong}> - at least 8 characters</Text>
        <Text red={!validation.isUpperLower}>
          {" "}
          - both uppercase and lowercase letters
        </Text>
        <Text red={!validation.isNumber}> - at least 1 number</Text>
        <Text red={!validation.isSpecial}> - at least 1 special character</Text>
      </>
    ),
    [validation],
    [validation]
  );

  const formInputs = useMemo(() => {
    return [
      {
        name: "firstName",
        type: "text",
        placeholder: "First Name",
        title: "First Name",
      },
      {
        name: "lastName",
        type: "text",
        placeholder: "Last Name",
        title: "Last Name",
      },

      {
        name: "email",
        type: "text",
        placeholder: "Email",
        title: "Email",
        defaultValue: email || "",
      },
      {
        name: "password",
        type: "password",
        placeholder: "Password",
        title: "Password",
        tooltip: validPasswordText,
      },
      {
        name: "passwordConfirm",
        type: "password",
        placeholder: "Confirm Password",
        title: "Confirm Password",
      },
    ];
  }, [validPasswordText, email]);

  return (
    <PageTemplate newHeader header={<Header />}>
      <AuthenticationFormSection
        title="Sign Up."
        linkText="Already have an account?"
        linkContent="Sign in now"
        linkDestiny="/signin"
        destinationRoute={destinationRoute}
      >
        <AuthenticationFormBlock
          submitButtonText="Sign up"
          inputs={formInputs}
          error={error ? error : signUpError}
          handleFormChange={handleFormChange}
          handleSubmit={handleSubmit}
          optionsComponent={
            <CheckboxWrapper>
              <input
                name="agreeTerms"
                type="checkbox"
                onClick={handleFormChange}
                style={{ marginRight: "8px" }}
              />
              <Text fontSize="10px">
                <Link
                  blue
                  fontSize="14"
                  underline="true"
                  href="https://predictabill.com/terms-and-conditions-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {"Agree to terms & conditions"}
                </Link>
              </Text>
            </CheckboxWrapper>
          }
        />
      </AuthenticationFormSection>
    </PageTemplate>
  );
};

export default RegisterPage;
