import moment from "moment";
import { getUserId } from "services/utils";
import { getDateFromMonth } from "utils/date";

export const formatPlans = async (values) => {
  const { table, startDate, frequency, group, planOptions, selectedPremiumTier } = values;
  const plans = table.slice(0, planOptions);

  let groupName = "";
  try {
    const userId = await getUserId();
    groupName = group + " " + userId;
  } catch (error) {
    groupName =
      group + " " + Math.random().toString(36).slice(-6).toUpperCase();
  }

  const formattedPlans = plans.map((item) => {
    const {
      id,
      tiers
    } = item;

    return {
      id: id,
      externalId: "CP" + Math.random().toString(36).slice(-6).toUpperCase(),
      premium: {
        tiers: tiers
      },
      frequency: frequency,
    };
  });
  return formattedPlans;
};
