import { spawn } from "redux-saga/effects";
import {
  sendFormBirth,
  sendFormInsurance,
  watchChangeOptionsBirth,
  watchLoadOptions,
  watchChangePrevValue,
  sendPremiumFormInsurance,
} from "./plan/sagas";
import { watchLoadSummary, watchLoadBillById } from "./bills/sagas";
import {
  loginFlow,
  loadFlow,
  registerFlow,
  logout,
  resetPasswordFlow,
  getUserByTokenFlow,
} from "./auth/sagas";
import { watchLoadTopDoctors } from "./doctors/sagas";

import { watchLoadProfile, watchUpdatePamphlets } from "./profile/sagas";

import {
  watchChangeCustomOptions,
  watchChangeCustomPlan,
  watchLoadCustomOptions,
} from "./custom/sagas";

export default function* () {
  yield spawn(sendFormInsurance);
  yield spawn(watchLoadOptions);
  yield spawn(sendFormBirth);
  yield spawn(watchChangePrevValue);
  yield spawn(watchLoadSummary);
  yield spawn(watchLoadBillById);
  yield spawn(watchChangeOptionsBirth);
  yield spawn(loginFlow);
  yield spawn(registerFlow);
  yield spawn(resetPasswordFlow);
  yield spawn(getUserByTokenFlow);
  yield spawn(loadFlow);
  yield spawn(logout);
  yield spawn(watchLoadTopDoctors);
  yield spawn(sendPremiumFormInsurance);
  yield spawn(watchLoadProfile);
  yield spawn(watchUpdatePamphlets);
  yield spawn(watchChangeCustomOptions);
  yield spawn(watchLoadCustomOptions);
  yield spawn(watchChangeCustomPlan);
}
