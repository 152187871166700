import React, { PureComponent, useEffect, useMemo, useState, useCallback } from "react";
import { PageTemplate, AuthenticationFormSection, AuthenticationFormBlock, newHeader as Header } from "components";

import { forgotPassword } from "services/api/account.api";

import { PageView } from "services/google";

const ForgotPasswordPage = () => {
  const [emailInput, setEmailInput] = useState("");
  const [error, setError] = useState("");
  const [errorLink, setErrorLink] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    PageView();
  }, []);

  const handleFormChange = (e) => {
    const { value } = e.target;
    setEmailInput(value);
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        const response = await forgotPassword(emailInput);
        if (response.status === 200) {
          setSuccess(true);
          setError("");
        }
      } catch (e) {
        let errorMessage = e.response ? e.response.data.message : e.message;
        if (e.response && e.response.status === 404) {
          setErrorLink({ text: "here", path: "/groups" });
          errorMessage = "We don't have an account for that address. Sign up ";
        }
        setError(errorMessage);
      }
    },
    [emailInput],
  );

  const formInputs = [
    {
      tytype: "email",
      name: "email",
      placeholder: "Email",
      autoComplete: "email",
    },
  ];

  return (
    <PageTemplate newHeader header={<Header />}>
      <AuthenticationFormSection title="Confirm Email.">
        {success ? (
          <p>Thank you! Check your email for a password reset.</p>
        ) : (
          <AuthenticationFormBlock
            submitButtonText="Send Email"
            inputs={formInputs}
            error={error}
            errorLink={errorLink}
            handleFormChange={handleFormChange}
            handleSubmit={handleSubmit}
          />
        )}
      </AuthenticationFormSection>
    </PageTemplate>
  );
};

export default ForgotPasswordPage;
