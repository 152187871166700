import React from "react";
import { connect } from "react-redux";
import { PageTemplate, Header, Footer, CustomMain } from "components";
import customActions from "store/custom/actions";

import { PageView } from "services/google";

class CustomPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  async componentDidMount() {
    document.title = "Self Serve Calculation- predictaBill";
    PageView();

    const { customId, loadCustom } = this.props;
    if (customId) {
      await loadCustom(customId);
      this.setState({ loaded: true });
    } else {
      this.setState({ loaded: true });
    }
  }

  async shouldComponentUpdate(nextProps) {
    const { customId, loadCustom } = this.props;
    if (customId !== nextProps.customId) {
      loadCustom(nextProps.customId);
    }
    return true;
  }

  render() {
    return (
      <PageTemplate
        header={<Header destinationRoute={this.props.destinationRoute} />}
        footer={<Footer />}
      >
        {this.state.loaded && (
          <CustomMain
            isAuth={this.props.isAuth}
            custom={this.props.custom}
            customId={this.props.customId}
          />
        )}
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state) => {
  const { profile, auth, custom } = state;
  return {
    customId: profile.currentCustom,
    isAuth: auth.isAuth,
    custom,
    destinationRoute: "/self-serve",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadCustom: (customId) => {
      dispatch(customActions.Creators.loadCustomRequest(customId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomPage);
