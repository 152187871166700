import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  OnboardingGeneralSectionV2,
  OnboardingJobEventSectionV2,
  OnboardingAgeEventSectionV2,
  OnboardingSpouseEventSectionV2,
  OnboardingChildrenEventSectionV2,
  OnboardingLocationEventSectionV2,
  OnboardingPamphletSectionV2,
  OnboardingReviewSectionV2,
  OnboardingPaymentSectionV2,
  Spinner,
} from "components";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-bottom: 100px;
`;

const OnboardingMain = ({
  report,
  handleCreateReport,
  handleUpdateReport,
  plan,
}) => {
  const [form, setForm] = useState({ onboardingStep: "" });
  const [nameSelections, setNameSelections] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (report) {
      const considerEmploymentPlan =
        report.event?.employment?.considerEmploymentPlan;
      const considerCobraPlan = report.event?.employment?.considerCobraPlan;
      const considerSpousePlan = report.event?.marriage?.considerSpousePlan;
      const considerCommercialPlan =
        report.event?.general?.considerCommercialPlan;
      const considerParentPlan = report.event?.age?.considerParentPlan;

      setForm({
        id: report._id,
        type: report.type,
        onboardingStep: report.onboardingStep || "",
        isPaid: report.isPaid,

        // general event
        newJob: report.event?.employment?.newJob,
        leftJob: report.event?.employment?.leftJob,
        isDue: report.isDue,
        gettingMarried: report.event?.marriage?.isApply,
        turning26: report.event?.age?.isApply,
        graduating: report.event?.graduation?.isApply,
        moving: report.event?.residence?.isApply,
        generalAdvice: report.event?.general?.isApply,

        // job event
        considerEmploymentPlan,
        jobDate: report.event?.employment?.jobDate,
        considerCobraPlan,
        cobraDate: report.event?.employment?.cobraDate,
        considerCommercialPlan,
        forBusiness: report.forBusiness,

        // dob event
        dob: report.event?.general?.dob,
        under26: report.event?.age?.isApply,
        ageDate: report.event?.age?.startDate,
        graduationDate: report.event?.graduation?.startDate,
        considerParentPlan,

        // spouse event
        isSpouse: report.event?.general?.isSpouse,
        marriageDate: report.event?.marriage?.startDate,
        considerSpousePlan,
        spouseRequireIns: report.event?.marriage?.spouseRequireIns,

        // children event
        haveChildren: report.event?.general?.children,
        children: report.event?.general?.children,
        childrenRequireIns: report.event?.general?.childrenRequireIns,
        dueDate: report.dueDate,

        // residence event
        moveDate: report.event?.residence?.startDate,
        zipCode: report.event?.general?.zipCode,
        countyFips: report.event?.general?.countyFips,

        birthParentName: report.birthParent?.name,
        birthParentCompany: report.birthParent?.company,
        partnerName: report.partner?.name,
        partnerCompany: report.partner?.company,
      });

      const options = [];
      if (considerEmploymentPlan || considerCommercialPlan)
        options.push(report.birthParent.name || "Me");
      if (considerCobraPlan) options.push("COBRA Plan");
      if (considerSpousePlan)
        options.push(report.partner.name || "Partner/Spouse");
      if (considerParentPlan) options.push("Parent's Plan");
      setNameSelections(options);
    }
  }, [report]);

  const handleNext = (_form) => {
    setLoading(true);
    if (report) {
      handleUpdateReport(_form);
    } else {
      handleCreateReport(_form);
    }
    setLoading(false);
  };

  const handleChangeStep = (step) => {
    setForm({ ...form, onboardingStep: step });
  };

  if (loading) return <Spinner center loading />;

  return (
    <Wrapper>
      {form?.onboardingStep.includes("job") ? (
        <OnboardingJobEventSectionV2 _form={form} handleNext={handleNext} />
      ) : form?.onboardingStep.includes("dob") ? (
        <OnboardingAgeEventSectionV2 _form={form} handleNext={handleNext} />
      ) : form?.onboardingStep.includes("spouse") ? (
        <OnboardingSpouseEventSectionV2 _form={form} handleNext={handleNext} />
      ) : form?.onboardingStep.includes("children") ? (
        <OnboardingChildrenEventSectionV2
          _form={form}
          handleNext={handleNext}
        />
      ) : form?.onboardingStep.includes("residence") ? (
        <OnboardingLocationEventSectionV2
          _form={form}
          handleNext={handleNext}
          nameSelections={nameSelections}
        />
      ) : form?.onboardingStep.includes("pamphlet") ? (
        <OnboardingPamphletSectionV2
          _form={form}
          plan={plan}
          handleNext={handleNext}
          nameSelections={nameSelections}
        />
      ) : form?.onboardingStep === "review" ? (
        <OnboardingReviewSectionV2
          _form={form}
          handleNext={handleNext}
          handleChangeStep={handleChangeStep}
        />
      ) : form?.onboardingStep === "payment" ||
        form?.onboardingStep === "complete" ? (
        <OnboardingPaymentSectionV2
          _form={form}
          report={report}
          handleNext={handleNext}
          handleChangeStep={handleChangeStep}
        />
      ) : (
        <OnboardingGeneralSectionV2 _form={form} handleNext={handleNext} />
      )}
    </Wrapper>
  );
};

export default OnboardingMain;
