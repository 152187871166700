import styled from "styled-components";

const StyledInput = styled.input`
  border: none;
  display: block;
  margin: 8px auto;
  background-color: #1c355e;
  color: white;
  width: 88%;
  border-bottom: 1px solid white;
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-transition-delay: 9999s;
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
  }
  :focus {
    outline: none;
  }
  ::placeholder {
    opacity: 0.5;
    color: ${({ invalid }) => (invalid ? `red` : "white")};
  }
  ${({ login }) => login && `margin:0; border-bottom: none; padding: 10px 5px;`}

  ${({ register }) =>
    register && `margin:0; border-bottom: none; padding: 10px 5px;`}
`;

export default StyledInput;
