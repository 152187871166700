import joi from "joi";
import { roomTypes, insuranceType } from "enums";

const formSendSchema = joi
  .object({
    roomType: joi
      .string()
      .valid(roomTypes.Private, roomTypes.Shared, roomTypes.Deluxe)
      .required(),
    hospitalId: joi.string().required(),
    deliveryType: joi.string().required(),
    insurancePlanType: joi
      .string()
      .valid(insuranceType.Individual, insuranceType.Family)
      .required(),
    billIds: joi
      .array()
      .items(joi.string())
      .min(1)
      .required(),
    broaderNetwork: joi.string(),
    isDoctorInNetwork: joi.bool().required(),
    insurancePlanId: joi.string().required()
  })
  .unknown()
  .required();

export default formSendSchema;
