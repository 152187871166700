import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { createCheckoutSession, getRole } from "services/api";
import { getToken } from "services/utils";
import { Text, Spinner } from "components";
import { getUserId } from "services/utils/tokens";

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 200px;
`;
/* global Stripe */
// const stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

class CheckoutPage extends React.Component {
  async componentDidMount() {
    document.title = "Checkout Page";

    const script = document.createElement("script");
    script.async = true;
    script.src = "https://js.stripe.com/v3/";
    document.head.appendChild(script);

    const { subscriptionTier } = this.props.location;
    if (!subscriptionTier) {
      this.props.history.push("/recommendations");
    } else {
      const packageTier =
        subscriptionTier.toLowerCase() === "business"
          ? 3
          : subscriptionTier.toLowerCase() === "individual"
          ? 1
          : 2;

      script.onload = async function () {
        const token = await getToken();
        const roleResponse = await getRole(token);
        const stripeKey =
          roleResponse.status === 200
            ? process.env.REACT_APP_STRIPE_ADMIN_KEY
            : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
        const stripe = Stripe(stripeKey);

        const userId = await getUserId();
        const response = await createCheckoutSession(userId, packageTier);
        const { sessionId } = response.data;

        const checkout = () => {
          stripe
            .redirectToCheckout({
              sessionId: `${sessionId}`,
            })
            .then(function (result) {
              // using `result.error.message`.
            });
        };
        checkout();
      };
    }
  }

  render() {
    return (
      <Wrapper>
        <Text> Redirecting to Stripe Checkout Page</Text>
        <Spinner center />
      </Wrapper>
    );
  }
}

export default connect(null, null)(CheckoutPage);
