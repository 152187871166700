import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomOptions from "../components/organisms/CustomOptions";
import customActions from "store/custom/actions";

const mapStateToProps = state => {
  const { custom, profile } = state;
  return {
    username: profile.username,
    ...custom
  };
};

const mapDispatchToProps = dispatch => ({
  changeOptions: (name, value) => {
    dispatch(customActions.Creators.changeCustomOptions(name, value));
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomOptions)
);
