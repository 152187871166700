export const percentToFloat = percent => {
  const result = parseFloat(percent) / 100;
  if (isNaN(result)) return null;
  return result;
};

export const floatToPercent = float => {
  const result = parseFloat(float) * 100;
  if (isNaN(result)) return null;
  return result;
};
