export const formatCurrency = (value, useTwoFractionalDigits = false) => {
  if (value == null) return "...";
  if (typeof value !== "number") return value;
  value = useTwoFractionalDigits ? value : Math.round(value || 0);
  return value.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: useTwoFractionalDigits ? 2 : 0,
    maximumFractionDigits: useTwoFractionalDigits ? 2 : 0,
  });
};

export const formatterNumber = (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const parserNumber = (value) => value?.replace(/\$\s?|(,*)/g, "");
