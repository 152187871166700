import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  createCustomReportRequest: ["payload"],
  createCustomReportSuccess: ["report"],
  createCustomReportFailure: ["error"],

  changeCustomOptions: ["name", "value"],
  changeCustomPlan: ["name", "value"],

  updateCustomPlanIds: ["parent", "ids"],

  loadCustomRequest: ["customId"],
  loadCustomSuccess: ["custom", "birthParentPlans", "partnerPlans"],
  loadCustomFailure: ["error"],

  resetStoreCustom: [null]
});

const Actions = {
  Types,
  Creators
};

export default Actions;
