import React from "react";
import styled from "styled-components";
import { Row } from "antd";
import { Text } from "components";
import {
  SPACE_BEFORE_TABLES,
  SPACE_BETWEEN_TABLES,
  SPACE_CELL_TABLES,
  StyledCell,
} from "components/organisms/AllMyOptions";
import TableTag from "components/molecules/Table/Tag";
import { UpOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { checkColumnIsFiltered } from "../Body";
import { getNonFilteredColumnLength } from "../Title";

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
`;

const selectTier = (planName, recommended) =>
  recommended
    ? "blue"
    : planName.includes("Catastrophic")
    ? "catastrophic"
    : planName.includes("Bronze")
    ? "bronze"
    : planName.includes("Silver")
    ? "silver"
    : planName.includes("Gold")
    ? "gold"
    : planName.includes("Platinum")
    ? "platinum"
    : ""; //"lightblue";

const headerCell = (recommended, background, numberOfPlans, _i, _planName, preference, joinType) => {
  const isColumnFiltered = checkColumnIsFiltered({ name: _planName }, preference, joinType);
  if (isColumnFiltered) return null;

  return (
    <StyledCell span={3} key={_i} tall bordered recommended background={background}>
      <LabelWrapper>
        <Text
          lightGrey2
          fontSize="14"
          white={recommended || _planName.includes("Catastrophic")}
          bold={!!background}
          customLSpacing="0"
        >
          {_planName} ({numberOfPlans})
        </Text>
      </LabelWrapper>
    </StyledCell>
  );
};

const TableHeader = ({
  columnHeadersBP,
  columnHeadersPartner,
  setExpanded,
  isExpanded,
  getToggleProps,
  deductibleType,
  label,
  isJobOffer,
  showHeaderAndRecommended,
  hideHeaderBar = false,
  preference,
}) => {
  const recommendedPlan = useSelector((state) => state.report.recommendedPlan);
  const join = useSelector((state) => state.report.join);
  const detailsJoin = useSelector((state) => state.report.detailsJoin);
  const plansMode = useSelector((state) => state.report.plansMode);
  const children = useSelector((state) => state.report.children);
  const childrenRecommended = useSelector((state) => state.report.childrenRecommended);

  const sameRecommendation = join === plansMode && children === childrenRecommended;

  const birthParentHeadersLength = getNonFilteredColumnLength(columnHeadersBP, preference, "birthParent");
  const partnerHeadersLength = getNonFilteredColumnLength(columnHeadersPartner, preference, "partner");

  return (
    <>
      {showHeaderAndRecommended ? (
        <>
          <TableTag
            columnHeadersBP={columnHeadersBP}
            columnHeadersPartner={columnHeadersPartner}
            isJobOffer={isJobOffer}
            preference={preference}
          />
          <Row
            style={{ flexFlow: "row", marginBottom: 5, width: "max-content" }}
            align="middle"
            justify={birthParentHeadersLength + partnerHeadersLength > 7 ? "start" : ""}
            wrap={false}
          >
            {/* <button
          {...getToggleProps({
            onClick: () => setExpanded((current) => !current),
          })}
          style={{
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: 'none',
            border: 'none',
            width: 150,
            minWidth: 150,
          }}
        >
          <Text blue small bold end>
            {label}
          </Text>

          <div
            style={{
              transform: isExpanded ? "rotateX(0)" : "rotateX(180deg)",
              transition: "400ms",
            }}
          >
            <UpOutlined
              style={{ fontSize: 16, color: "#21355B" }}
            />
          </div>
        </button> */}
            <StyledCell customWidth={`${SPACE_BEFORE_TABLES}px`} />
            {columnHeadersBP.map((column, _i) => {
              const recommended =
                (plansMode === "samePlan"
                  ? recommendedPlan[0] === column.name && detailsJoin === "birthParent"
                  : recommendedPlan[0] === column.name) &&
                sameRecommendation &&
                !deductibleType;
              const background = selectTier(column.name, recommended);
              const numberOfPlans = column.numberOfPlans;

              return headerCell(recommended, background, numberOfPlans, _i, column.name, preference, "birthParent");
            })}

            <StyledCell hide={!columnHeadersPartner.length} customWidth={`${SPACE_BETWEEN_TABLES}px`} />
            {columnHeadersPartner.map((column, _i) => {
              const recommended =
                (plansMode === "samePlan"
                  ? recommendedPlan[0] === column.name && detailsJoin === "partner"
                  : recommendedPlan[1] === column.name) &&
                sameRecommendation &&
                !deductibleType &&
                !isJobOffer;
              const background = selectTier(column.name, recommended);
              const numberOfPlans = column.numberOfPlans;
              return headerCell(recommended, background, numberOfPlans, _i, column.name, preference, "partner");
            })}
          </Row>
        </>
      ) : (
        <></>
      )}
      {hideHeaderBar ? null : (
        <div
          style={{
            backgroundColor: "#F2F4F7",
            color: "#667085",
            fontSize: "18px",
            padding: "4px 12px",
            margin: "4px 0",
            fontFamily: "circular",
            width:
              SPACE_BEFORE_TABLES +
              birthParentHeadersLength * SPACE_CELL_TABLES +
              (partnerHeadersLength ? SPACE_BETWEEN_TABLES : 0) +
              partnerHeadersLength * SPACE_CELL_TABLES +
              (partnerHeadersLength ? 10 : 0),
          }}
        >
          {label}
        </div>
      )}
    </>
  );
};

export default TableHeader;
