import React from "react";
import { Route, withRouter, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: ComponentToRender, isAuth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuth ? (
          <ComponentToRender {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              destinationRoute: rest.computedMatch.url
            }}
          />
        )
      }
    />
  );
};

export default withRouter(PrivateRoute);
