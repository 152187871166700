import React from "react";
import styled from "styled-components";

const MiddleWrapper = styled.div`
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 300px;
  min-height: 100px;
  max-height: 100px;
`;

const Tag = styled.div`
  min-width: 100px;
  margin: 5px;
  border-radius: 100px;
  padding: ${({ fontSize }) => (fontSize === "24px" ? "3px" : "0px")} 5px 0 5px;
  background-color: #d9d9d9;
  color: #fff;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "24px")}
  line-height: 40px;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 40%;
`;

const MyPlansMiddleWrapperTags = ({ tags }) => {
  const maxNumberOfCharacters = Math.max(...tags.map((tag) => tag.length));
  return (
    <MiddleWrapper>
      {tags.map((tag, index) => (
        <Tag key={index} fontSize={maxNumberOfCharacters > 6 ? "18px" : "24px"}>
          {tag}
        </Tag>
      ))}
    </MiddleWrapper>
  );
};

export default MyPlansMiddleWrapperTags;
