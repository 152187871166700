import React from "react";
import { Text } from "components";
import styled from "styled-components";

const Block = styled.div`
  text-align: center;
  display: flex;
`;

const LineText = ({ title, text }) => {
  return (
    <Block>
      <Text bold>{title}</Text>
      <Text>{text}</Text>
    </Block>
  );
};

export default LineText;
