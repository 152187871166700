import model from "./model";
const {
  formField: {
    planOptions,
    withMultiplePremiumTiers,
    premiumTiersNumber,
    selectedPremiumTier,
    premiumTier,
    withWaiverPayment,
    waiverTiersNumber,
    selectedWaiverTier,
    waivers,
    waiverPaymentValue,
    waiverPaymentFrequency,
    withSpouseSurcharge,
    spousalSurcharge,
    spousalSurchargeFrequency,
    costFor,
    outOfNetworkCoverage,
    label,
    table,
    deductibles,
    frequency,
    startDate,
    OOPStarts,
    showIndividualInFamilyDeductibles,
    showIndividualInFamilyOOP,
    subjectToDeductible,
    group,
    negativeValue,
    notSureAboutThese,
    enterAdvancedDetails,
    serviceList,
    importedFromTypeform,
    emailFromTypeform,
    showNotSoSureAboutThese,
    dontAddMoreCategories,
    enterNetworkRow2,
  },
} = model;

export default {
  //step 1
  [planOptions.name]: 1,
  [withMultiplePremiumTiers.name]: false,
  [premiumTiersNumber.name]: 1,
  [selectedPremiumTier.name]: 1,
  [premiumTier.name]: "",
  [withWaiverPayment.name]: false,
  [waiverTiersNumber.name]: 0,
  [selectedWaiverTier.name]: 1,
  [waivers.name]: {},
  [waiverPaymentValue.name]: 0,
  [waiverPaymentFrequency]: 1,
  [withSpouseSurcharge.name]: false,
  [spousalSurcharge.name]: 0,
  [spousalSurchargeFrequency.name]: 1,
  [costFor.name]: "",
  [negativeValue.name]: false,
  [outOfNetworkCoverage.name]: "yes",
  [table.name]: [
    {
      idx: "1",
      outOfNetworkCoverage: "yes",
      hasoutOfNetworkIndividualOOP: true,
    },
  ],
  [label.name]: "",
  [deductibles.name]: [],
  [importedFromTypeform.name]: false,
  [emailFromTypeform.name]: "",
  //step 2
  [frequency.name]: "",
  //step 3
  [startDate.name]: "01",
  [showIndividualInFamilyDeductibles.name]: false,
  //step 4
  [showIndividualInFamilyOOP.name]: false,
  [OOPStarts.name]: "",
  //step 5
  [subjectToDeductible.name]: true,
  //step 6
  [enterAdvancedDetails.name]: false,
  [serviceList.name]: [],
  //step 7
  [group.name]: "",
  //step Advanced
  [notSureAboutThese.name]: false,
  [showNotSoSureAboutThese.name]: true,
  [dontAddMoreCategories.name]: true,
  [serviceList.name]: [{ name: "Inpatient Hospital Stay", value: "Inpatient Hospital Stay" }],
};
