import React from "react";
import { TableCell, Text, Currency } from "components";

const OptimizationCell = ({ type, data, grandTotal }) => {
  let value, insurance, hsa;
  switch (type) {
    case "grandTotal":
      value = data.total;
      break;
    case "premiumTotal":
      value =
        data.charges.before[0].total.value + data.charges.after[0].total.value;
      break;
    case "insuranceTotal":
      insurance = data.charges.before.filter((charge) => {
        return charge.id === "insurance";
      });
      value = insurance.length > 0 ? insurance[0].total.value : 0;
      break;
    case "hsaTotal":
      hsa = data.charges.before.filter((charge) => {
        return charge.id === "hsa";
      });
      value = hsa.length > 0 ? hsa[0].total.value : 0;
      break;
    case "hsaUnused":
      hsa = data.charges.after.filter((charge) => {
        return charge.id === "hsa";
      });
      value = hsa.length > 0 ? hsa[0].total.value : 0;
      break;
    default:
      break;
  }

  return (
    <TableCell center grandTotal={grandTotal ? "right" : false}>
      {value > 0 ? (
        type === "grandTotal" ? (
          <Text fontSize="20">
            <Currency price={value} />
          </Text>
        ) : (
          <Text arial fontSize="20">
            <Currency price={value} />
          </Text>
        )
      ) : (
        "-"
      )}
    </TableCell>
  );
};

export default OptimizationCell;
