import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Button, Input, Select, DatePicker } from "antd";
const { Item } = Form;

const DynamicFieldAddEmployee = ({ name, label, placeholder, premiumTiers }) => {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          <Item label={label} labelCol={{ span: 24 }} style={{ marginBottom: "0px" }} />
          {fields.map((field, index) => (
            <Item key={field.key}>
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                <Item name={[index, "email"]} noStyle>
                  <Input placeholder={placeholder} style={{ width: "200px", marginRight: "5px" }} />
                </Item>
                {premiumTiers.length > 1 ? (
                  <Item name={[index, "role"]} noStyle>
                    <Select
                      style={{ width: "250px", marginRight: "5px", marginLeft: "5px" }}
                      options={premiumTiers.map((tier) => ({ value: tier, label: tier }))}
                    />
                  </Item>
                ) : null}
                <Item name={[index, "date"]} noStyle>
                  <DatePicker format={["MM/DD/YYYY", "MM/DD/YY", "M/D/YYYY", "M/D/YY"]} style={{ marginLeft: "5px" }} />
                </Item>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    style={{ margin: "5px 8px" }}
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </div>
            </Item>
          ))}
          <Button
            type="dashed"
            onClick={() => add()}
            style={{
              width: "100%",
              minWidth: "150px",
            }}
          >
            Add Row <PlusOutlined />
          </Button>
        </>
      )}
    </Form.List>
  );
};

export default DynamicFieldAddEmployee;
