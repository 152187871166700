// prototype of new validation methods to stop using Yup in the future
import { message } from "antd";

const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};

export const validateNetworkTab = (networkTable) => {
  const invalidUrlIdx = [];
  if (networkTable) {
    networkTable.forEach((network) => {
      if (!isValidUrl(network.url) && network.url && network.url !== "") {
        invalidUrlIdx.push(network.idx);
      }
    });

    if (invalidUrlIdx.length) {
      message.error(
        `Invalid URL in row${invalidUrlIdx.length > 1 ? "s" : ""} ${invalidUrlIdx.join(
          ", ",
        )} - Make sure the URL has the correct http:// or https:// prefix`,
      );
      return false;
    }

    const repeatedUrlWithSameDetailsIdx = [];

    for (let i = 0; i < networkTable.length; i++) {
      if (!networkTable[i].url || networkTable[i].url === "") continue;
      const repeatedUrls = [];
      for (let j = i + 1; j < networkTable.length; j++) {
        if (
          networkTable[i].url === networkTable[j].url &&
          networkTable[i].extraInstructions === networkTable[j].extraInstructions &&
          networkTable[i].extraInstructions &&
          networkTable[i].extraInstructions !== ""
        ) {
          if (repeatedUrls.length === 0) repeatedUrls.push(networkTable[i]);
          repeatedUrls.push(networkTable[j]);
        }
      }

      if (repeatedUrls.length > 0) {
        repeatedUrlWithSameDetailsIdx.push(repeatedUrls.map((item) => item.idx));
        break;
      }
    }

    if (repeatedUrlWithSameDetailsIdx.length) {
      message.error(
        `The insurance networks in rows ${repeatedUrlWithSameDetailsIdx.join(
          ", ",
        )} have the same website. Please add Extra Instructions to explain how these networks are different. If the website and instructions are the same, go back to the prior tab and change the Insurance Networks to match.`,
      );
      return false;
    }

    return true;
  }
};
