import { InputNumber } from "antd";
import { formatterNumber, parserNumber } from "utils/currency";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/report/actions";
import { SingleCheckbox } from "../components/CheckboxGroup";
import { RadioGroup } from "../components/RadioGroup";

export const PlansToJoinFilter = ({ report }) => {
  const dispatch = useDispatch();
  const plansToJoin = useSelector((state) => state.report.plansToJoin);
  const hasSeparateFilter = useSelector((state) => state.report.hasSeparateFilter);
  const separateFilterValue = useSelector((state) => state.report.separateFilterValue);

  const handleSeparateFilterValueChange = (value) => {
    dispatch(
      Actions.Creators.switchState({
        separateFilterValue: value,
      }),
    );
  };

  const birthParentCompany = report?.birthParent?.company;
  const partnerCompany = report?.partner?.company;

  const plansToJoinOptions = [
    { value: "all", label: "All Combinations" },
    { value: "same", label: "Same Plan" },
    // Removed for now
    // { value: "separate", label: "Separate Plans" },
    {
      value: "birthParent",
      label: birthParentCompany,
    },
    { value: "partner", label: partnerCompany },
  ];

  if (
    !birthParentCompany ||
    !partnerCompany ||
    (report.partner?.justForComparison && report.partner?.justForComparison !== "partner")
  )
    return null;

  return (
    <>
      <RadioGroup
        name="radio-plans-to-join"
        label="Plans to Join"
        options={plansToJoinOptions.map((option) => ({ value: option.value, label: option.label }))}
        onChange={(value) => {
          dispatch(
            Actions.Creators.switchState({
              plansToJoin: value,
            }),
          );
        }}
        value={plansToJoin}
      />

      {plansToJoin === "all" && (
        <>
          <SingleCheckbox
            label="Savings Threshold"
            option={{
              value: "has-separate-filter",
              label: "Only show separate combinations when savings are at least:",
            }}
            checked={hasSeparateFilter}
            onChange={(checked) => {
              dispatch(
                Actions.Creators.switchState({
                  hasSeparateFilter: checked,
                }),
              );
            }}
          />
          <div style={{ paddingLeft: "30px", marginTop: -15 }}>
            <InputNumber
              style={{
                background: "inherit",
                backgroundColor: "inherit",
                border: "2px solid #D0D5DD",
                borderRadius: "5px",
                marginTop: "3px",
                marginBottom: "7px",
                width: "100%",
                color: "#344054",
                padding: "8px",
              }}
              value={separateFilterValue}
              onChange={handleSeparateFilterValueChange}
              formatter={formatterNumber}
              parser={parserNumber}
            />
          </div>
        </>
      )}
    </>
  );
};
