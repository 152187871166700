export const providersOptions = [
  { name: "Hospital", value: "Hospital" },
  { name: "Doctor", value: "Doctor" },
  { name: "Drug", value: "Drug" },
  { name: "Other", value: "Other" },
];

export const yesOrNo = [
  { name: "Yes", value: true },
  { name: "No", value: false },
];

export const networkOptions = [
  {
    name: "Aetna",
    value: "Aetna",
    autocomplete: [
      {
        url: "https://aetna.com/docfind",
        row2: "Choice® POS II (Open Access)",
        extraInstructions: `In the "Continue as a guest" section, the enter the location of the doctor, then hit "Search" and click on the correct Plan Name`,
      },
    ],
  },
  {
    name: "Blue Cross and/or Blue Shield (including Anthem)",
    value: "Blue Cross and/or Blue Shield (including Anthem)",
    autocomplete: [
      {
        name: "Anthem Blue Cross and/or Blue Shield",
        url: "https://anthem.com/find-care",
        row2: "National BlueCard PPO",
        extraInstructions:
          'Click on "Basic search as a guest" then choose "Medical Plan or Network," your state, "Medical (Employer-Sponsored)," and then "National PPO (BlueCard PPO)"',
      },
      {
        name: "Arkansas Blue Cross and Blue Shield",
        url: "https://arkbluecross.healthsparq.com/healthsparq/public/#/one/city=&state=&postalCode=&country=&insurerCode=ARKBLUE_I&brandCode=ARKBLUE&productCode=all",
        row2: "True Blue PPO (with BlueCard)",
        extraInstructions: 'Enter the location, then choose "Browse a list of plans" at the bottom',
      },
      {
        name: "Blue Cross and Blue Shield of Alabama",
        url: "https://www.bcbsal.org/web/provider-finder#/?meganav=true",
        row2: "",
        extraInstructions: "",
      },
      {
        name: "Blue Cross and Blue Shield of Hawaii",
        url: "https://www.hmsa.com/search/providers/",
        row2: "Health Plan Hawaii",
        extraInstructions: "Check off the name of your network",
      },
      {
        name: "Blue Cross and Blue Shield of Illinois",
        url: "https://my.providerfinderonline.com/?ci=IL-UUX&corp_code=IL",
        row2: "BlueEdge",
        extraInstructions:
          'Enter the location and hit "Continue" then select "Employer Plans", choose whether it is an HMO or PPO, and choose your network name',
      },
      {
        name: "Blue Cross and Blue Shield of Kansas",
        url: "https://bcbsks.sapphirecareselect.com/",
        row2: "BlueChoice Network",
        extraInstructions: 'Choose "Continue" under "Just browsing?", then add the location and choose your plan name',
      },
      {
        name: "Blue Cross and Blue Shield of Kansas City",
        url: "https://bluekc.sapphirethreesixtyfive.com/",
        row2: "Blue-Care",
        extraInstructions: "Choose your plan name from the Network dropdown and enter the location",
      },
      {
        name: "Blue Cross and Blue Shield of Louisiana",
        url: "https://www.bcbsla.com/find-a-doctor/local-provider",
        row2: "OchPlus",
        extraInstructions:
          'Click on "All Networks" above the search bar and choose your network name from the dropdown, then click on Location to the right of the search bar and add the location',
      },
      {
        name: "Blue Cross and Blue Shield of Massachusetts",
        url: "https://member.bluecrossma.com/fad?locale=en",
        row2: "HMO Blue Select",
        extraInstructions: "Go into the Network dropdown menu and choose yours, then add the location",
      },
      {
        name: "Blue Cross and Blue Shield of Minnesota",
        url: "https://findadoctormn.sapphirecareselect.com/?ci=bcbsmn",
        row2: "Aware",
        extraInstructions:
          'Choose "Continue" under "Just browsing?", then add the location and choose your network name in the Network dropdown',
      },
      {
        name: "Blue Cross and Blue Shield of Mississippi",
        url: "https://www.bcbsms.com/im-a-member/find-a-provider",
        row2: "",
        extraInstructions: "",
      },
      {
        name: "Blue Cross and Blue Shield of Montana",
        url: "https://my.providerfinderonline.com/?ci=MT-UUX&corp_code=MT",
        row2: "Blue Focus POS",
        extraInstructions:
          'Enter the location and hit "Continue" then select "Employer Plans", choose whether it is an HMO or PPO, and choose your network name',
      },
      {
        name: "Blue Cross and Blue Shield of Nebraska",
        url: "https://bcbsne.healthsparq.com/healthsparq/public/#/one/state=ne&postalCode=&country=&insurerCode=BCBSNE_I&brandCode=BCBSNE",
        row2: "Blueprint Health",
        extraInstructions:
          'Click "Choose a location", enter one and click "Yes, this is correct" then in the top right corner, click on the orange "All Plans" button; choose "Find a different plan" and then "Browse a list of plans" at the bottom',
      },
      {
        name: "Blue Cross and Blue Shield of New Mexico",
        url: "https://my.providerfinderonline.com/?ci=NM-UUX&corp_code=NM",
        row2: "Blue Preferred Plus",
        extraInstructions:
          'Enter the location and hit "Continue" then select "Employer Plans", choose whether it is an HMO or PPO, and choose your network name',
      },
      {
        name: "Blue Cross and Blue Shield of North Carolina",
        url: "https://healthnav.bcbsnc.com/?ci=COMMERCIAL",
        row2: "Blue Value",
        extraInstructions: 'Enter the location, then choose your plan from the dropdown menu and hit "Continue"',
      },
      {
        name: "Blue Cross and Blue Shield of Oklahoma",
        url: "https://my.providerfinderonline.com/?ci=OK-UUX&corp_code=OK",
        row2: "NativeBlue",
        extraInstructions:
          'Enter the location and hit "Continue" then select "Employer Plans", choose whether it is an HMO or PPO, and choose your network name',
      },
      {
        name: "Blue Cross and Blue Shield of Rhode Island",
        url: "https://bcbsri.sapphirecareselect.com/",
        row2: "Blue Choice New England",
        extraInstructions: 'Choose "Continue" under "Just browsing?", then add the location and choose your plan name',
      },
      {
        name: "Blue Cross and Blue Shield of South Carolina",
        url: "https://shoppingforcare.sapphirethreesixtyfive.com/?ci=CP012",
        row2: "Preferred Blue",
        extraInstructions: 'Choose "Continue" under "Just browsing?", then add the location',
      },
      {
        name: "Blue Cross and Blue Shield of Texas",
        url: "https://my.providerfinderonline.com/?ci=TX-UUX&corp_code=TX",
        row2: "Blue Premier",
        extraInstructions:
          'Enter the location and hit "Continue" then select "Employer Plans", choose whether it is an HMO or PPO, and choose your network name',
      },
      {
        name: "Blue Cross and Blue Shield of Vermont",
        url: "https://bcbsvt.healthtrioconnect.com/anonymous/guestReistrationSubmit.cfm?userType=member&object=26%2C11.00&payorID=1034",
        row2: "New England Health Plan Any Network Tier",
        extraInstructions:
          "Choose your Network from the dropdown menu, and if applicable also choose your Network Tier",
      },
      {
        name: "Blue Cross Blue Shield of Arizona",
        url: "https://azblue.healthsparq.com/healthsparq/public/#/one/city=&state=AZ&insurerCode=BCBSAZ_I&brandCode=BCBSAZBLUE",
        row2: "Alliance HMO",
        extraInstructions:
          'Click "Choose a location", enter one and click "Yes, this is correct" then in the top right corner, click on the orange "All Plans" button; choose "Find a different plan" and then "Browse a list of plans" at the bottom',
      },
      {
        name: "Blue Cross Blue Shield of Michigan",
        url: "https://doctors.bcbsm.com/app/public/#/one/city=&state=MI&postalCode=&country=&insurerCode=BCBSMI_I&productCode=&brandCode=BCBSMI",
        row2: "Healthy Blue Choices POS",
        extraInstructions:
          'Click "Choose a location", enter one and click "Yes, this is correct" then in the top right corner, click on the orange "All Plans" button; choose "Find a different plan" and then "Browse a list of plans" at the bottom',
      },
      {
        name: "Blue Cross Blue Shield of North Dakota",
        url: "https://bcbsnd.sapphirecareselect.com/",
        row2: "Preferred Blue PPO",
        extraInstructions: 'Choose "Continue" under "Just browsing?", then add the location',
      },
      {
        name: "Blue Cross Blue Shield of Wyoming",
        url: "https://bcbswy.sapphirecareselect.com/?ci=bcbswy&network_id=",
        row2: "Wyoming Choice",
        extraInstructions: 'Choose "Continue" under "Just browsing?", then add the location',
      },
      {
        name: "Blue Cross of Idaho",
        url: "https://bcid.sapphirecareselect.com/",
        row2: "HPN(BlueHPN)",
        extraInstructions:
          'Choose "Continue" under "Just browsing?", then add the location, and choose your Network name from the dropdown menu',
      },
      {
        name: "Blue Shield of California",
        url: "https://www.blueshieldca.com/fad/home",
        row2: "Trio HMO",
        extraInstructions:
          'Enter the location in the "Enter an address" field, then choose your network name from the "Select a plan" dropdown',
      },
      {
        name: "BlueCross BlueShield of Puerto Rico",
        url: "https://salud.grupotriples.com/wp-content/uploads/directorio-proveedores-2024.pdf",
        row2: "Triple-S",
        extraInstructions: "",
      },
      {
        name: "BlueCross BlueShield of Tennessee",
        url: "https://bcbst.sapphirecareselect.com/",
        row2: "Blue Network S",
        extraInstructions:
          'Choose "Continue" under "Just browsing?", then add the location, and choose your Network name from the dropdown menu',
      },
      {
        name: "Capital Blue Cross",
        url: "https://capitalbluecross.healthsparq.com/healthsparq/public/#/one/insurerCode=CAPBC_I&locale=en_US&brandCode=CAPBC",
        row2: "Capital Advantage EPO",
        extraInstructions:
          'Click "Continue" then choose "Search by plan type" then "Medical plans" and choose your plan network name',
      },
      {
        name: "CareFirst BlueCross BlueShield",
        url: "https://carefirst.sapphirecareselect.com/?ci=DFT",
        row2: "BlueHPN",
        extraInstructions: "Choose the location and then use the dropdown menu to choose your plan network name",
      },
      {
        name: "Excellus BlueCross BlueShield",
        url: "https://excellusbcbs.healthsparq.com/healthsparq/public/#/one/insurerCode=EXC_I&brandCode=EXC",
        row2: "SimplyBlue-(Excellus Commercial)",
        extraInstructions:
          'Click "Choose a location", enter one and click "Yes, this is correct" then in the top right corner, click on the orange "All Plans" button; choose "Find a different plan" and then "Browse a list of plans" at the bottom',
      },
      {
        name: "Florida Blue",
        url: "https://providersearch.floridablue.com/visitor/fb/#",
        row2: "BlueSelect",
        extraInstructions: "Enter the location, then choose your plan from the dropdown menu",
      },
      {
        name: "Highmark Blue Cross and/or Blue Shield",
        url: "https://highmark.sapphirecareselect.com/",
        row2: "BCBS EPO",
        extraInstructions:
          'Choose "Continue" under "Just browsing?", then add the location, then in the Network dropdown menu, choose your Network',
      },
      {
        name: "Horizon Blue Cross and Blue Shield of New Jersey",
        url: "https://horizon.sapphirecareselect.com/?ci=commercial",
        row2: "ADVANTAGE EPO",
        extraInstructions: "Choose your plan from the dropdown menu, then enter the location",
      },
      {
        name: "Independence Blue Cross",
        url: "https://ibx.healthsparq.com/healthsparq/public/#/one/city=&state=&country=&insurerCode=IBXBLUE_I&brandCode=IBXBLUECOMM&productCode=all",
        row2: "Personal Choice PPO",
        extraInstructions:
          'Click "Choose a location", enter one and click "Yes, this is correct" then in the top right corner, click on the orange "All Plans" button; choose "Find a different plan" and then "Browse a list of plans" at the bottom',
      },
      {
        name: "Premera Blue Cross and/or Blue Shield",
        url: "https://premera.sapphirecareselect.com/",
        row2: "BlueCard PPO",
        extraInstructions:
          'Choose "Continue" under "Just browsing?", then add the location, then in the Networks dropdown menu, choose your Network',
      },
      {
        name: "Regence Blue Cross and/or Blue Shield",
        url: "https://providers.regence.com/healthsparq/public/#/one/insurerCode=RG_I&brandCode=RG&productCode=&postalCode=",
        row2: "Blue High Performance Network",
        extraInstructions: 'Click "Choose a network" and then click "Browse a list of networks" at the bottom',
      },
      {
        name: "Wellmark Blue Cross and Blue Shield",
        url: "https://web.healthsparq.com/healthsparq/public/#/one/city=&state=&postalCode=&country=&insurerCode=WMRK_I&brandCode=WELLMARK&alphaPrefix=&bcbsaProductId",
        row2: "Wellmark Blue POS",
        extraInstructions:
          'Enter the location, then choose "Browse a list of plans" at the bottom (you can only use the 3-letter prefix if you are already on this plan)',
      },
    ],
  },
  {
    name: "Cigna",
    value: "Cigna",
    autocomplete: [
      {
        url: "https://hcpdirectory.cigna.com/web/public/consumer/directory",
        row2: "Open Access Plus",
        extraInstructions: `Select the "Employer or School" button, then enter the location and search for the doctor/location, then click "Search" and "Continue as guest." Once you are on the doctor/location's profile page, scroll down to "Plans Accepted at this Location" and see if yours is included`,
      },
    ],
  },
  {
    name: "Kaiser",
    value: "Kaiser",
    autocomplete: [
      {
        name: "California - Northern",
        url: "https://healthy.kaiserpermanente.org/northern-california/doctors-locations",
        row2: "HMO",
      },
      {
        name: "California - Southern",
        url: "https://healthy.kaiserpermanente.org/southern-california/doctors-locations",
        row2: "HMO",
      },
      {
        name: "Colorado",
        url: "https://healthy.kaiserpermanente.org/colorado/doctors-locations",
        row2: "HMO",
      },
      {
        name: "Georgia",
        url: "https://healthy.kaiserpermanente.org/georgia/doctors-locations",
        row2: "HMO",
      },
      {
        name: "Hawaii",
        url: "https://healthy.kaiserpermanente.org/hawaii/doctors-locations",
        row2: "HMO",
      },
      {
        name: "Maryland / Virginia / Washington D.C.",
        url: "https://healthy.kaiserpermanente.org/maryland-virginia-washington-dc/doctors-locations",
        row2: "HMO",
      },
      {
        name: "Oregon / SW Washington",
        url: "https://healthy.kaiserpermanente.org/oregon-washington/doctors-locations",
        row2: "HMO",
      },
      {
        name: "Washington",
        url: "https://healthy.kaiserpermanente.org/washington/doctors-locations",
        row2: "HMO",
      },
    ],
  },
  {
    name: "UnitedHealthcare",
    value: "UnitedHealthcare",
    autocomplete: [
      {
        url: "https://connect.werally.com/plans/uhc/273",
        row2: "Choice HMO Advanced",
        extraInstructions: `Select "Employer and Individual Plans" then choose your network`,
      },
    ],
  },
  {
    name: "Other",
    value: "Other",
    autocomplete: [
      {
        name: "MetroPlus Health",
        url: "https://www.metroplus.org/Member/provider-directory",
        row2: "",
        extraInstructions: "",
      },
      {
        name: "Ambetter from Fidelis Care",
        url: "https://www.fideliscare.org/Find-A-Doctor",
        row2: "",
        extraInstructions: "",
      },
      {
        name: "Healthfirst",
        url: "https://healthfirst.org/find-a-doctor",
        row2: "",
        extraInstructions: "",
      },
      {
        name: "Oscar",
        url: "https://www.hioscar.com/search/?networkId=001&year=2024",
        row2: "",
        extraInstructions: "",
      },
      {
        name: "EmblemHealth",
        url: "https://my.emblemhealth.com/member/s/find-care-services",
        row2: "",
        extraInstructions: "",
      },
      {
        name: "Curative",
        url: "https://curative.com/providers",
        row2: "",
        extraInstructions: "",
      },
    ],
  },
];

export const blueCrossBlueShieldOptions = [
  "Anthem Blue Cross and/or Blue Shield",
  "Arkansas Blue Cross and Blue Shield",
  "Blue Cross and Blue Shield of Alabama",
  "Blue Cross and Blue Shield of Hawaii",
  "Blue Cross and Blue Shield of Illinois",
  "Blue Cross and Blue Shield of Kansas",
  "Blue Cross and Blue Shield of Kansas City",
  "Blue Cross and Blue Shield of Louisiana",
  "Blue Cross and Blue Shield of Massachusetts",
  "Blue Cross and Blue Shield of Minnesota",
  "Blue Cross and Blue Shield of Mississippi",
  "Blue Cross and Blue Shield of Montana",
  "Blue Cross and Blue Shield of Nebraska",
  "Blue Cross and Blue Shield of New Mexico",
  "Blue Cross and Blue Shield of North Carolina",
  "Blue Cross and Blue Shield of Oklahoma",
  "Blue Cross and Blue Shield of Rhode Island",
  "Blue Cross and Blue Shield of South Carolina",
  "Blue Cross and Blue Shield of Texas",
  "Blue Cross and Blue Shield of Vermont",
  "Blue Cross Blue Shield of Arizona",
  "Blue Cross Blue Shield of Michigan",
  "Blue Cross Blue Shield of North Dakota",
  "Blue Cross Blue Shield of Wyoming",
  "Blue Cross of Idaho",
  "Blue Shield of California",
  "BlueCross BlueShield of Puerto Rico",
  "BlueCross BlueShield of Tennessee",
  "Capital Blue Cross",
  "CareFirst BlueCross BlueShield",
  "Excellus BlueCross BlueShield",
  "Florida Blue",
  "Highmark Blue Cross and/or Blue Shield",
  "Horizon Blue Cross and Blue Shield of New Jersey",
  "Independence Blue Cross",
  "Premera Blue Cross and/or Blue Shield",
  "Regence Blue Cross and/or Blue Shield",
  "Wellmark Blue Cross and Blue Shield",
];
