import React from "react";
import styled from "styled-components";
import { Link, Text } from "components";

const Wrapper = styled.div`
  margin-bottom: 24px;
`;

const Content = styled.div`
  text-align: center;
  padding: 56px 0 0;
  margin-bottom: 10px;
`;

const FooterNavigation = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Content>
      <Wrapper>
        <Link
          href="https://predictabill.com/your-privacy"
          target="_blank"
          rel="noopener noreferrer"
          blue
        >
          YOUR PRIVACY
        </Link>
        <Link
          href="https://predictabill.com/terms-and-conditions-of-use"
          target="_blank"
          rel="noopener noreferrer"
          blue
        >
          TERMS OF CONDITION AND USE
        </Link>
      </Wrapper>
      <Text>© 2019-{currentYear} PREDICTABILL</Text>
    </Content>
  );
};

export default FooterNavigation;
