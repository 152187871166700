import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Text, FormFooter } from "components";
import { changeSubscription } from "services/api/account.api";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div``;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${({ col }) => (col ? "column" : "row")};
  min-height: 141px;
  width: 310px;
`;

const SubscriptionWrapper = styled.div`
  display: flex;
  min-height: 141px;
  width: 310px;
  border-radius: 10px;
  overflow: hidden;
`;

const SubscriptionLeftSection = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? "310px" : "108px")};
  height: inherit;
  background-color: #51a5a2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SubscriptionRightSection = styled.div`
  width: 202px;
  height: inherit;
  background-color: #f8f7f7;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;

const CancelReasonForm = styled.form`
  > div {
    display: grid;
    grid-template-columns: 35px 270px;
    margin-bottom: 10px;
  }

  > div > input {
    justify-self: center;
  }

  > div > textarea {
    outline: none;
    border: 1px solid #21345b;
    border-radius: 10px;
    box-sizing: border-box;
    margin-top: 10px;
    width: 270px;
    height: 62px;
    padding: 5px;
  }
`;

const Button = styled.button`
  outline: none;
  border: none;
  background: none;
  display: grid;
  grid-template-columns: 35px 1fr;
  align-items: center;
  justify-items: center;
  cursor: pointer;

  > input {
    transform: scale(1.2);
    margin-right: 10px;
  }
`;

const ChangeSubscriptionForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  row-gap: 10px;
`;

const subscriptionPlans = {
  free: {
    name: "Consumer Free",
    price: "Free",
    limits: "",
  },
  individual: {
    name: "Consumer Individual",
    price: "$199.99",
    limits: "1 Individual",
  },
  household: {
    name: "Consumer Household",
    price: "$349.99",
    limits: "1 Household (spouse, kids)",
  },
  business: {
    name: "Small Business",
    price: "$699.99",
    limits: "1 Business",
  },
};

const cancelSubscriptionReasons = [
  "I’m not interested in this service anymore.",
  "I can’t afford it anymore.",
  "I’m getting recommendations elsewhere.",
  "I’m dissatisfied with the recommendations I received.",
  "I had a poor experience with customer service.",
  "Other (please explain).",
];

// convert Date object into MM-DD-YYYY format
const formatDateText = (date, autoRenew, nextTier) => {
  if (date) {
    const today = new Date();
    const dateArray = date.split("-");
    const year = dateArray[0];
    const month = dateArray[1];
    const day = dateArray[2].split("T")[0];
    const expirationDate = new Date(`${month}-${day}-${year}`);
    const expirationText = autoRenew
      ? `Renews${nextTier ? ` with consumer ${nextTier}` : ""}`
      : today > expirationDate
      ? "Expired"
      : "Expires";

    return (
      <Text
        blue={expirationText.includes("Renews")}
        red={!expirationText.includes("Renews")}
        bold
        fontSize="12"
      >
        {expirationText}
        {" on "}
        {month}-{day}-{year}
      </Text>
    );
  } else {
    return "n/a";
  }
};

// display subscription details in Account Page
function ManageSubscription({
  subscriptionTier,
  subscriptionValidUntil,
  subscriptionAutoRenew,
  subscriptionNextTier,
}) {
  const history = useHistory();

  const [subscriptionDetails, setSubscriptionDetails] = useState({
    tier: null,
    autoRenew: null,
    validUntil: null,
    nextTier: null,
  });

  const [cancelForm, setCancelForm] = useState({
    // subscription cancellation reason
    "I’m not interested in this service anymore.": null,
    "I can’t afford it anymore.": null,
    "I’m getting recommendations elsewhere.": null,
    "I’m dissatisfied with the recommendations I received.": null,
    "I had a poor experience with customer service.": null,
    "Other (please explain).": null,
    cancellationReasonOtherText: "",
  });

  // new tier to upgrade or downgrade to
  const [newTier, setNewTier] = useState(null);

  const [toggleCancelSubscription, setToggleCancelSubscription] = useState(
    false
  );
  const [toggleChangeSubscription, setToggleChangeSubscription] = useState(
    false
  );
  // Sets the response after a cancellation or change subscription request
  const [textStatus, setTextStatus] = useState("");

  useEffect(() => {
    setSubscriptionDetails({
      tier: subscriptionTier,
      autoRenew: subscriptionAutoRenew,
      validUntil: subscriptionValidUntil,
      nextTier: subscriptionNextTier,
    });

    if (newTier) {
      const tiersArray = Object.keys(subscriptionPlans);
      if (tiersArray.indexOf(newTier) > tiersArray.indexOf(subscriptionTier)) {
        // user is deciding to upgrade subscription
        setTextStatus(
          "By clicking continue, you will be redirected to Stripe checkout to upgrade your subscription. On a successful upgrade, your current subscription will be cancelled."
        );
      } else {
        // user is deciding to downgrade subscription
        setTextStatus(
          "By clicking continue, your new subscription will replace your current subscription starting your next subscription renewal period"
        );
      }
    }
  }, [
    subscriptionTier,
    subscriptionAutoRenew,
    subscriptionValidUntil,
    newTier,
    subscriptionNextTier,
  ]);

  const handleCancellationReasons = (e) => {
    setCancelForm({ ...cancelForm, [e.target.name]: e.target.checked });
  };

  const handleCancelSubscription = () => {
    // make cancel subscription request with the reasons

    changeSubscription("cancel", cancelForm).then((res) => {
      if (res.status === 200) {
        setToggleCancelSubscription(false);
        setSubscriptionDetails({
          ...subscriptionDetails,
          autoRenew: false,
        });
        setTextStatus(
          "Your subscription with expire at the end of subscription period"
        );
      } else {
        setToggleCancelSubscription(false);
        setTextStatus(
          "An error occurred, please contact us at HI@PREDICTABILL.COM"
        );
      }
    });
  };

  const handleChangeSubscription = () => {
    // if subscription is free, !autoRenew, expired - we do not have user's payment details - must go through stripe checkout

    const tiersArray = Object.keys(subscriptionPlans);
    if (
      !subscriptionDetails.autoRenew ||
      tiersArray.indexOf(newTier) > tiersArray.indexOf(subscriptionTier)
    ) {
      // user is deciding to upgrade subscription
      // redirect to stripe checkout
      history.push({
        pathname: "/checkout",
        subscriptionTier: newTier,
      });
    } else {
      // user is deciding to downgrade subscription
      // downgrade subscription via stripe api

      changeSubscription(newTier).then((res) => {
        if (res.status === 200) {
          setToggleChangeSubscription(false);
          setSubscriptionDetails({
            ...subscriptionDetails,
            nextTier: newTier,
          });
          setTextStatus("Your subscription has successfully been updated");
        } else {
          setToggleChangeSubscription(false);
          setTextStatus(
            "An error occurred, please contact us at HI@PREDICTABILL.COM"
          );
        }
      });
    }
  };

  return (
    <Wrapper>
      {toggleCancelSubscription ? (
        // Cancel Subscription - Reasons for cancellation form
        <FlexWrapper col>
          <Text blue center fontSize="18" bolder>
            Cancel my plan
          </Text>
          <br></br>
          <Text blue center fontSize="13">
            We’re sorry to see you go! Please help us understand the reason so
            we can improve our services in the future.
          </Text>
          <br></br>
          <CancelReasonForm>
            {cancelSubscriptionReasons.map((_reason, _idx) => (
              <div key={`cancel_reason_${_idx}`}>
                <input
                  type="checkbox"
                  id={`cancel_reason_${_idx}`}
                  name={_reason}
                  checked={cancelForm[_reason]}
                  onClick={handleCancellationReasons}
                />
                <label for={`cancel_reason_${_idx}`}>
                  <Text blue fontSize="14">
                    {_reason}
                  </Text>
                </label>

                <div></div>
                {_reason.includes("Other") && (
                  <textarea
                    onChange={(e) =>
                      setCancelForm({
                        ...cancelForm,
                        cancellationReasonOtherText: e.target.value,
                      })
                    }
                  />
                )}
              </div>
            ))}
          </CancelReasonForm>
          <FormFooter
            leftAction={handleCancelSubscription}
            leftText="Cancel my plan"
            rightAction={() =>
              setToggleCancelSubscription(!toggleCancelSubscription)
            }
            rightText="Nevermind!"
          />
        </FlexWrapper>
      ) : toggleChangeSubscription ? (
        // Change subscription section
        <FlexWrapper col>
          <ChangeSubscriptionForm>
            {Object.keys(subscriptionPlans).map((_subscription, _idx) => {
              if (
                _subscription !== subscriptionDetails.tier &&
                _subscription !== "free"
              ) {
                return (
                  <Button
                    active={newTier === _subscription}
                    onClick={(e) => {
                      e.preventDefault();
                      setNewTier(_subscription);
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={newTier === _subscription ? true : false}
                      onClick={(e) => {
                        e.preventDefault();
                        setNewTier(_subscription);
                      }}
                    />
                    <SubscriptionWrapper>
                      <SubscriptionLeftSection>
                        <Text white fontSize="18" center>
                          {subscriptionPlans[_subscription].name}
                        </Text>
                      </SubscriptionLeftSection>

                      <SubscriptionRightSection>
                        <Text green bold fontSize="18">
                          {subscriptionPlans[_subscription].price}
                        </Text>
                        <Text green fontSize="12">
                          per year
                        </Text>
                        <br></br>
                        <Text fontSize="12">
                          Includes unlimited support for:
                        </Text>
                        <Text fontSize="12">
                          {" "}
                          • {subscriptionPlans[_subscription].limits}
                        </Text>
                        <Text fontSize="12"> • 1 year</Text>
                      </SubscriptionRightSection>
                    </SubscriptionWrapper>
                  </Button>
                );
              } else return null;
            })}
            {textStatus}
          </ChangeSubscriptionForm>
          <FormFooter
            leftAction={() =>
              setToggleChangeSubscription(!toggleChangeSubscription)
            }
            leftText="Nevermind!"
            rightAction={newTier ? handleChangeSubscription : null}
            rightText="Change my plan"
          />
        </FlexWrapper>
      ) : (
        // Display current subscription
        <FlexWrapper col>
          <SubscriptionWrapper>
            <SubscriptionLeftSection
              fullWidth={subscriptionDetails.tier === "free"}
            >
              <Text white fontSize="18" center>
                {subscriptionPlans[subscriptionDetails.tier]?.name}
              </Text>
            </SubscriptionLeftSection>
            {subscriptionDetails.tier !== "free" && (
              <SubscriptionRightSection>
                <Text green bold fontSize="18">
                  {subscriptionPlans[subscriptionDetails.tier]?.price}
                </Text>
                <Text green fontSize="12">
                  per year
                </Text>
                <br></br>
                <Text fontSize="12">Includes unlimited support for:</Text>
                <Text fontSize="12">
                  {" "}
                  • {subscriptionPlans[subscriptionDetails.tier]?.limits}
                </Text>
                <Text fontSize="12"> • 1 year</Text>
                <br></br>

                {formatDateText(
                  subscriptionDetails.validUntil,
                  subscriptionDetails.autoRenew,
                  subscriptionDetails.nextTier
                )}
              </SubscriptionRightSection>
            )}
          </SubscriptionWrapper>

          <br></br>
          <Text fontSize="12">{textStatus}</Text>
          <FormFooter
            leftAction={
              subscriptionDetails.autoRenew
                ? () => setToggleCancelSubscription(!toggleCancelSubscription)
                : null
            }
            leftText={subscriptionDetails.autoRenew ? "Cancel my plan" : null}
            rightAction={() =>
              setToggleChangeSubscription(!toggleChangeSubscription)
            }
            rightText="Switch my plan"
          />
        </FlexWrapper>
      )}
    </Wrapper>
  );
}

export default ManageSubscription;
