import configure from "./configure";

const { axios } = configure;
export const logLogin = async ({ username, route }) => {
  const body = { username, route };
  return axios.post(`/login-logs`, body).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};
