import React from "react";
import { connect } from "react-redux";
import planActions from "store/plan/actions";
import { PageTemplate, Header, Footer, PremiumMain } from "components";
import { PageView } from "services/google";

class PremiumPage extends React.Component {
  componentDidMount() {
    document.title = "Premium Calculation - predictaBill";
    PageView();
    this.props.loadOptions();
  }

  render() {
    return (
      <PageTemplate header={<Header />} footer={<Footer />}>
        <PremiumMain />
      </PageTemplate>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadOptions: () => {
      dispatch(planActions.Creators.loadOptionsRequest());
    },
  };
};
export default connect(null, mapDispatchToProps)(PremiumPage);
