import React, { useState } from "react";
import styled from "styled-components";
import {
  CalculateHeader as Header,
  ButtonLink,
  FormInput as Input,
} from "components";

import { message } from "antd";

const Wrapper = styled.section``;

const FlexWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  border: 3px solid #00a19b;
  border-radius: 25px;
  margin: 15px 12px;
  padding: 30px;
  width: 350px;
  outline: none;
  color: #00a19b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  background: #fff;
`;

const OnboardingInputSection = ({
  onChange,
  option,
  title,
  type,
  value,
  validation,
  validLength = 1,
  action,
  actionText,
}) => {
  const [input, setInput] = useState(value);

  const handleChange = ({ target: { name, value } }) => {
    setInput(value);
  };

  const handleValidation = () => {
    if (validation === "zip code") {
      if (input.length !== 5) {
        message.error("Invalid zip code length.");
        return false;
      }

      const zipCode = parseInt(input, 10);

      if (zipCode >= 35000 && zipCode <= 36999) {
        // st = "AL";
        // state = "Alabama";
      } else if (zipCode >= 99500 && zipCode <= 99999) {
        // st = "AK";
        // state = "Alaska";
      } else if (zipCode >= 85000 && zipCode <= 86999) {
        // st = "AZ";
        // state = "Arizona";
      } else if (zipCode >= 71600 && zipCode <= 72999) {
        // st = "AR";
        // state = "Arkansas";
      } else if (zipCode >= 90000 && zipCode <= 96699) {
        // st = "CA";
        // state = "California";
      } else if (zipCode >= 80000 && zipCode <= 81999) {
        // st = "CO";
        // state = "Colorado";
      } else if (zipCode >= 6000 && zipCode <= 6999) {
        // st = "CT";
        // state = "Connecticut";
      } else if (zipCode >= 19700 && zipCode <= 19999) {
        // st = "DE";
        // state = "Delaware";
      } else if (zipCode >= 32000 && zipCode <= 34999) {
        // st = "FL";
        // state = "Florida";
      } else if (zipCode >= 30000 && zipCode <= 31999) {
        // st = "GA";
        // state = "Georgia";
      } else if (zipCode >= 96700 && zipCode <= 96999) {
        // st = "HI";
        // state = "Hawaii";
      } else if (zipCode >= 83200 && zipCode <= 83999) {
        // st = "ID";
        // state = "Idaho";
      } else if (zipCode >= 60000 && zipCode <= 62999) {
        // st = "IL";
        // state = "Illinois";
      } else if (zipCode >= 46000 && zipCode <= 47999) {
        // st = "IN";
        // state = "Indiana";
      } else if (zipCode >= 50000 && zipCode <= 52999) {
        // st = "IA";
        // state = "Iowa";
      } else if (zipCode >= 66000 && zipCode <= 67999) {
        // st = "KS";
        // state = "Kansas";
      } else if (zipCode >= 40000 && zipCode <= 42999) {
        // st = "KY";
        // state = "Kentucky";
      } else if (zipCode >= 70000 && zipCode <= 71599) {
        // st = "LA";
        // state = "Louisiana";
      } else if (zipCode >= 3900 && zipCode <= 4999) {
        // st = "ME";
        // state = "Maine";
      } else if (zipCode >= 20600 && zipCode <= 21999) {
        // st = "MD";
        // state = "Maryland";
      } else if (zipCode >= 1000 && zipCode <= 2799) {
        // st = "MA";
        // state = "Massachusetts";
      } else if (zipCode >= 48000 && zipCode <= 49999) {
        // st = "MI";
        // state = "Michigan";
      } else if (zipCode >= 55000 && zipCode <= 56999) {
        // st = "MN";
        // state = "Minnesota";
      } else if (zipCode >= 38600 && zipCode <= 39999) {
        // st = "MS";
        // state = "Mississippi";
      } else if (zipCode >= 63000 && zipCode <= 65999) {
        // st = "MO";
        // state = "Missouri";
      } else if (zipCode >= 59000 && zipCode <= 59999) {
        // st = "MT";
        // state = "Montana";
      } else if (zipCode >= 27000 && zipCode <= 28999) {
        // st = "NC";
        // state = "North Carolina";
      } else if (zipCode >= 58000 && zipCode <= 58999) {
        // st = "ND";
        // state = "North Dakota";
      } else if (zipCode >= 68000 && zipCode <= 69999) {
        // st = "NE";
        // state = "Nebraska";
      } else if (zipCode >= 88900 && zipCode <= 89999) {
        // st = "NV";
        // state = "Nevada";
      } else if (zipCode >= 3000 && zipCode <= 3899) {
        // st = "NH";
        // state = "New Hampshire";
      } else if (zipCode >= 7000 && zipCode <= 8999) {
        // st = "NJ";
        // state = "New Jersey";
      } else if (zipCode >= 87000 && zipCode <= 88499) {
        // st = "NM";
        // state = "New Mexico";
      } else if (zipCode >= 10000 && zipCode <= 14999) {
        // st = "NY";
        // state = "New York";
      } else if (zipCode >= 43000 && zipCode <= 45999) {
        // st = "OH";
        // state = "Ohio";
      } else if (zipCode >= 73000 && zipCode <= 74999) {
        // st = "OK";
        // state = "Oklahoma";
      } else if (zipCode >= 97000 && zipCode <= 97999) {
        // st = "OR";
        // state = "Oregon";
      } else if (zipCode >= 15000 && zipCode <= 19699) {
        // st = "PA";
        // state = "Pennsylvania";
      } else if (zipCode >= 300 && zipCode <= 999) {
        // st = "PR";
        // state = "Puerto Rico";
      } else if (zipCode >= 2800 && zipCode <= 2999) {
        // st = "RI";
        // state = "Rhode Island";
      } else if (zipCode >= 29000 && zipCode <= 29999) {
        // st = "SC";
        // state = "South Carolina";
      } else if (zipCode >= 57000 && zipCode <= 57999) {
        // st = "SD";
        // state = "South Dakota";
      } else if (zipCode >= 37000 && zipCode <= 38599) {
        // st = "TN";
        // state = "Tennessee";
      } else if (
        (zipCode >= 75000 && zipCode <= 79999) ||
        (zipCode >= 88500 && zipCode <= 88599)
      ) {
        // st = "TX";
        // state = "Texas";
      } else if (zipCode >= 84000 && zipCode <= 84999) {
        // st = "UT";
        // state = "Utah";
      } else if (zipCode >= 5000 && zipCode <= 5999) {
        // st = "VT";
        // state = "Vermont";
      } else if (zipCode >= 22000 && zipCode <= 24699) {
        // st = "VA";
        // state = "Virgina";
      } else if (zipCode >= 20000 && zipCode <= 20599) {
        // st = "DC";
        // state = "Washington DC";
      } else if (zipCode >= 98000 && zipCode <= 99499) {
        // st = "WA";
        // state = "Washington";
      } else if (zipCode >= 24700 && zipCode <= 26999) {
        // st = "WV";
        // state = "West Virginia";
      } else if (zipCode >= 53000 && zipCode <= 54999) {
        // st = "WI";
        // state = "Wisconsin";
      } else if (zipCode >= 82000 && zipCode <= 83199) {
        // st = "WY";
        // state = "Wyoming";
      } else {
        message.error("Invalid zip code");
        return false;
      }

      return true;
    } else {
      return true;
    }
  };

  const handleSubmit = () => {
    const isValid = handleValidation();
    if (isValid) onChange(option, input);
  };

  return (
    <Wrapper>
      <Header bigTitle={true} title={title} />
      <FlexWrapper>
        <ButtonWrapper>
          <Input
            handleChange={handleChange}
            name={option}
            value={input}
            type={type}
            min={0}
          />
        </ButtonWrapper>

        {input && input.length >= validLength && (
          <ButtonLink withBG button fontSize="18" onClick={handleSubmit}>
            Continue
          </ButtonLink>
        )}

        <br></br>
        {action && (
          <ButtonLink withBG button fontSize="18" onClick={action}>
            {actionText}
          </ButtonLink>
        )}
      </FlexWrapper>
    </Wrapper>
  );
};

export default OnboardingInputSection;
