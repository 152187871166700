import React from "react";
import styled, { css } from "styled-components";
import { Link as ReactLink } from "react-router-dom";

const styles = css`
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;

  ${({ black, noMargin }) =>
    black
      ? css`
          color: #111;
          font-weight: bold;
          font-size: 30px;
          :hover {
            color: #939393;
          }
        `
      : css`
          color: #f9fafa;
          margin: ${noMargin ? 0 : "0 1.5em"};
          :hover {
            color: #7ae1d7;
          }
        `};

  ${({ red }) => red && `color: #DC4C2D;  `}
  ${({ darkred }) => darkred && `color: #A10000;  `}
  ${({ blue }) =>
    blue &&
    css`
      color: #1c355e;
      font-size: 20px;
      line-height: 1em;
      :hover {
        color: #54565a;
      }
    `}

  ${({ green, noMargin, fontSize }) =>
    green &&
    css`
      color: rgb(15, 162, 157);
      font-weight: 500;
      font-size: ${fontSize}px;
      margin: ${noMargin ? 0 : "0 10px"};
    `}

  ${({ gray, noMargin, fontSize }) =>
    gray &&
    css`
      color: #6b6b6b;
      font-weight: 500;
      font-size: ${fontSize}px;
      line-height: 15px;
      text-style: italic;
      margin: ${noMargin ? 0 : "0 10px"};
    `}

  ${({ white }) =>
    white &&
    css`
      color: #fff;
    `}

  ${({ button }) =>
    button &&
    css`
      color: #fff;
      margin: 0;
      width: 100%;
    `}

  ${({ icon }) =>
    icon &&
    css`
      margin-left: 16px;
      height: 28px;
    `}

  ${({ underline }) =>
    underline &&
    css`text-decoration: underline;
    margin:0;

    :hover {
      text-decoration: underline;
    }
  }`}

  ${({ bold }) => bold && `font-weight: bold;`}

  ${({ italic }) => italic && `font-style: italic`}

  ${({ withBG, fontSize }) =>
    withBG &&
    css`
      background-color: #00a19b;
      padding: 7px 12px;
      border-radius: 20px;
      color: white;
      margin: 0;
      text-align: center;
      font-weight: bold;
      font-size: ${fontSize}px;
      white-space: nowrap;
      overflow: hidden;
      :hover {
        color: white;
        background: #7ae1d7;
      }
    `}

  ${({ withBorder, fontSize }) =>
    withBorder &&
    css`
      background-color: #fff;
      padding: 3px 20px;
      border: 3px solid #7ae1d7;
      border-radius: 50px;
      color: #00a19b;
      margin: 0;
      text-align: center;
      font-weight: bold;
      font-size: ${fontSize}px;
      :hover {
        color: white;
        background: #7ae1d7;
      }
    `}

  position: relative;

  ${({ small }) =>
    small &&
    `font-size: 16px;
  font-family: circular;
  margin: 0;
  font-weight: 400;
  font-style: normal;
  line-height: 1.7em;`}

  ${({ height }) => height && `height:${height};`}
${({ lHeight }) => lHeight && `line-height:${lHeight};`}
${({ justBetween }) =>
    justBetween &&
    `
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`}

  ${({ textAlign }) =>
    textAlign &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

    ${({ margin }) => margin && `margin:${margin}`}

  ${({ hidden }) => hidden && `visibility: hidden;`}
  
  ${({ text }) =>
    text &&
    `
    font-family: circular;
    margin: 0;
    font-weight: 400;
    font-style: normal;
    line-height: 13px;
  `}

  ${({ color }) => color && `color: ${color};`}

  ${({ circular }) => circular && `font-family: circular;`}

  ${({ fontSize }) =>
    fontSize &&
    `
  font-size:${fontSize}px
  `}
`;

const StyledNavLink = styled.a`
  ${styles}
`;

const StyledReactLink = styled(ReactLink)`
  ${styles}
`;

const Link = ({ ...props }) => {
  if (props.to) return <StyledReactLink {...props} />;
  return <StyledNavLink {...props} />;
};

export default Link;
