import React, { createContext, useCallback, useContext, useState } from "react";

export const RefreshContext = createContext({
  refreshState: () => {},
});

export const RefreshProvider = ({ children }) => {
  const [, setRefresh] = useState(1);

  const refreshState = useCallback(() => {
    setRefresh((prev) => prev + 1);
  }, [setRefresh]);

  return <RefreshContext.Provider value={{ refreshState }}>{children}</RefreshContext.Provider>;
};

export const useRefreshProvider = () => {
  const { refreshState } = useContext(RefreshContext);

  return {
    refreshState,
  };
};
