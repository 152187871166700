import styled, { css } from "styled-components";
import logoImage from "./icons/logo.png";
import predictaBillLogo from "./icons/predictabill-logo.svg";
import logoImageBlue from "./icons/PredictabillLogo-Clear.svg";
import instagramImage from "./icons/instagram.png";
import facebookImage from "./icons/facebook.png";
import inImage from "./icons/in-logo.png";
import lockImage from "./icons/lock.png";
import lockImageBlue from "./icons/lock-blue.png";
import arrowImage from "./icons/right-arrow.png";
import arrowImageBlack from "./icons/right-arrow-black.png";
import calculatorImage from "./icons/calculator.png";
import calculatorHoverImage from "./icons/calculatorimage_mouseover.png";
import babyImage from "./icons/baby.png";
import kidImage from "./icons/kid.png";
import baldImage from "./icons/bald.png";
import curlyImage from "./icons/curly.png";
import flipImage from "./icons/flip.png";
import partedImage from "./icons/parted.png";
import authBackgroundSVG from "./icons/auth-background.svg";
import magnifyingGlassImage from "./icons/magnifying-glass.png";

// onboarding flow icons
import babyStroller from "./icons/baby-stroller.png";
import birthdayCake from "./icons/birthday-cake.png";
import graduationCap from "./icons/graduation-cap.png";
import medicalChart from "./icons/medical-chart.png";
import movingTruck from "./icons/moving-truck.png";
import suitcase from "./icons/suitcase.png";
import weddingRing from "./icons/wedding-ring.png";

import yes from "./icons/yes.png";
import no from "./icons/no.png";

const Icon = styled.svg`
  ${({ inIcon }) =>
    inIcon &&
    css`
      background: url(${inImage}) no-repeat 50%;
      background-size: 23px;
      width: 28px;
      height: 28px;
      :hover {
        opacity: 0.5;
      }
    `}

  ${({ auth }) =>
    auth &&
    css`
      background: url(${authBackgroundSVG});
      background-position: 0 -13vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-origin: border-box;
      background-clip: border-box;
      background-color: #d9f3f1;
      width: 60vw;
      height: 100vh;
    `}

  ${({ logo, invert }) =>
    logo &&
    css`
      background: url(${invert ? logoImageBlue : predictaBillLogo}) no-repeat
        25px 10px;
      background-size: 185px;
      height: 50px;
      width: 236px;
    `}
  ${({ instagram }) =>
    instagram &&
    css`
      background: url(${instagramImage}) no-repeat 50%;
      background-size: 27px;
      width: 28px;
      height: 28px;

      :hover {
        opacity: 0.5;
      }
    `}

  ${({ facebook }) =>
    facebook &&
    css`
      background: url(${facebookImage}) no-repeat 50%;
      background-size: 27px;
      width: 28px;
      height: 28px;

      :hover {
        opacity: 0.5;
      }
    `}

  ${({ lock, blue }) =>
    lock &&
    css`
      background: url(${blue ? lockImageBlue : lockImage}) no-repeat 15px 10px;
      background-size: 60px;
      width: 100px;
      height: 80px;
    `}

  ${({ arrow }) =>
    arrow &&
    css`
      background: url(${arrowImage}) no-repeat 10px;
      background-size: 25px;
      height: 40px;
      width: 40px;
      cursor: "pointer";
    `}

    ${({ blackArrow }) =>
    blackArrow &&
    css`
      background: url(${arrowImageBlack}) no-repeat 0 18px;
      background-size: 38px;
      height: 40px;
      width: 40px;
    `}

    ${({ calculator }) =>
    calculator &&
    css`
      background: url(${calculatorImage}) no-repeat 63px;
      background-size: 14px;
      height: 21px;
      transition: 0.3s;
      position: absolute;
      width: 83px;
      top: 1px;
      right: 1px;

      &:hover {
        background: url(${calculatorHoverImage}) no-repeat 63px;
        background-size: 14px;
        height: 21px;
        transition: 0.3s;
        position: absolute;
        width: 83px;
        top: 1px;
        right: 1px;
      }
    `}

      ${({ baby }) =>
    baby &&
    css`
      background: url(${babyImage}) no-repeat center;
      background-size: 80px;
      height: 60px;
      width: 80px;
    `}

    ${({ kid }) =>
    kid &&
    css`
      background: url(${kidImage}) no-repeat center;
      background-size: 15px;
      height: 15px;
      width: 15px;
    `}

      ${({ bald }) =>
    bald &&
    css`
      background: url(${baldImage}) no-repeat;
      background-size: 80px;
      height: 20px;
      width: 80px;
      ${({ backgroundSize }) =>
        backgroundSize &&
        `
            background-size: ${backgroundSize}px;
            height: ${backgroundSize}px;
            width: ${backgroundSize}px;
          `}
    `}

      ${({ flip }) =>
    flip &&
    css`
      background: url(${flipImage}) no-repeat;
      background-size: 80px;
      height: 60px;
      width: 80px;
      ${({ backgroundSize }) =>
        backgroundSize &&
        `
            background-size: ${backgroundSize}px;
            height: ${backgroundSize}px;
            width: ${backgroundSize}px;
          `}
    `}

    ${({ magnifyingGlass }) =>
    magnifyingGlass &&
    css`
      background: url(${magnifyingGlassImage}) no-repeat;
      background-size: 24px;
      height: 24px;
      width: 24px;
      ${({ backgroundSize }) =>
        backgroundSize &&
        `
            background-size: ${backgroundSize}px;
            height: ${backgroundSize}px;
            width: ${backgroundSize}px;
          `}
    `}

      ${({ curly }) =>
    curly &&
    css`
      background: url(${curlyImage}) no-repeat;
      background-size: 80px;
      height: 60px;
      width: 80px;
      ${({ backgroundSize }) =>
        backgroundSize &&
        `
            background-size: ${backgroundSize}px;
            height: ${backgroundSize}px;
            width: ${backgroundSize}px;
          `}
    `}

      ${({ parted }) =>
    parted &&
    css`
      background: url(${partedImage}) no-repeat;
      background-size: 80px;
      height: 60px;
      width: 80px;
      ${({ backgroundSize }) =>
        backgroundSize &&
        `
            background-size: ${backgroundSize}px;
            height: ${backgroundSize}px;
            width: ${backgroundSize}px;
          `}
    `}

        ${({ onboardingJobIcon, small }) =>
    onboardingJobIcon &&
    css`
      background: url(${suitcase}) no-repeat;
      background-size: contain;
      width: ${small ? "30px" : "71px"};
      height: ${small ? "25px" : "60px"};
    `}

        ${({ onboardingBabyIcon, small }) =>
    onboardingBabyIcon &&
    css`
      background: url(${babyStroller}) no-repeat;
      background-size: contain;
      width: ${small ? "30px" : "71px"};
      height: ${small ? "25px" : "60px"};
    `}

          ${({ onboardingTurning26Icon, small }) =>
    onboardingTurning26Icon &&
    css`
      background: url(${birthdayCake}) no-repeat;
      background-position-x: 3px;
      background-position-y: 1px;
      background-size: contain;
      width: ${small ? "30px" : "71px"};
      height: ${small ? "25px" : "60px"};
    `}

        ${({ onboardingGraduatingIcon, small }) =>
    onboardingGraduatingIcon &&
    css`
      background: url(${graduationCap}) no-repeat;
      background-position-y: 5px;
      background-size: 73px;
      background-size: contain;
      width: ${small ? "30px" : "71px"};
      height: ${small ? "25px" : "60px"};
    `}

        ${({ onboardingMovingIcon, small }) =>
    onboardingMovingIcon &&
    css`
      background: url(${movingTruck}) no-repeat;
      background-position-y: 7px;
      background-size: 71px;
      background-size: contain;
      width: ${small ? "30px" : "71px"};
      height: ${small ? "25px" : "60px"};
    `}

        ${({ onboardingWeddingIcon, small, medium }) =>
    onboardingWeddingIcon &&
    css`
      background: url(${weddingRing}) no-repeat;
      background-size: contain;
      width: ${small ? "30px" : medium ? "45px" : "71px"};
      height: ${small ? "25px" : medium ? "40px" : "60px"};
    `}

        ${({ onboardingGeneralIcon, small }) =>
    onboardingGeneralIcon &&
    css`
      background: url(${medicalChart}) no-repeat;
      background-size: contain;
      width: ${small ? "30px" : "71px"};
      height: ${small ? "25px" : "60px"};
    `}

    ${({ yesIcon, small }) =>
    yesIcon &&
    css`
      background: url(${yes}) no-repeat;
      background-size: contain;
      width: ${small ? "30px" : "71px"};
      height: ${small ? "25px" : "60px"};
    `}

    ${({ noIcon, small }) =>
    noIcon &&
    css`
      background: url(${no}) no-repeat;
      background-size: contain;
      width: ${small ? "30px" : "71px"};
      height: ${small ? "25px" : "60px"};
    `}
`;

export default Icon;
