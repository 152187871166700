import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Link, Text } from "components";
import { updateReport, getPlansArr, notifyUsers } from "services/api/admin.api";
import { getToken } from "services/utils";

import {
  Button,
  Card,
  Checkbox,
  Radio,
  // Col,
  Form,
  Input,
  InputNumber,
  // Row,
  Select,
  Space,
  message,
  Modal,
  Divider,
} from "antd";
import { MinusOutlined, MinusCircleOutlined, WarningOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { getReportUsers } from "services/api";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { Item, List } = Form;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const innerLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 12 },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: { span: 14, offset: 6 },
};

const cardLayout = {
  style: { width: "1400px" },
  headStyle: { background: "#d9f3f1" },
};

const DisabledInput = styled.input`
  border-style: none;
  font-size: 16;
  font-weight: bold;
  background-color: transparent;
`;

const textCelStyle = {
  fontWeight: "bold",
  textAlign: "center",
  width: 100,
  borderWidth: 0,
  marginTop: 15,
};

const leftColGrid = {
  width: 500,
};

const ReportUsersModal = (props) => {
  const { visible, allowNotification, reportId } = props;

  const [users, setUsers] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);

  useEffect(() => {
    (async () => {
      setSelectedEmails([]);
      const token = await getToken();
      const response = await getReportUsers(reportId, token);
      if (response.status === 200) {
        setUsers(response.data.map((user) => ({ ...user, notify: false })));
      }
    })();
  }, [visible]);

  const title = allowNotification ? `Would you like to notify these users?` : `Users Attached to report ${reportId}`;

  const okText = allowNotification && "Notify";
  const cancelText = allowNotification && "Don't notify";

  const onClickCheckbox = (email) => {
    if (selectedEmails.includes(email)) setSelectedEmails(selectedEmails.filter((el) => el !== email));
    else setSelectedEmails([...selectedEmails, email]);
  };

  const onOk = () => props.onOk(selectedEmails);

  const bottomText = useMemo(() => {
    if (allowNotification && selectedEmails.length) return `You are about to notify ${selectedEmails.length} emails`;
  }, [selectedEmails.length, allowNotification]);

  return (
    <Modal {...props} title={title} onOk={onOk} okText={okText} cancelText={cancelText}>
      <div style={{ marginLeft: -24, justifyContent: "space-between" }}>
        {users.length ? (
          <ol>
            {users.map(({ _id, username, firstName, alternativeEmails, isCurrent }) => (
              <div>
                <li key={username} style={{ marginBottom: 8 }}>
                  <span style={{ fontWeight: "bold" }}>
                    {firstName} {isCurrent && " - Current Report"}
                  </span>
                </li>
                <Space
                  direction="vertical"
                  size="small"
                  style={{
                    display: "flex",
                  }}
                >
                  {[username, ...alternativeEmails].map((item) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {item}
                      {allowNotification && (
                        <input
                          type={"checkbox"}
                          checked={selectedEmails.includes(item)}
                          onChange={() => onClickCheckbox(item)}
                        />
                      )}
                    </div>
                  ))}
                </Space>
                <Divider />
              </div>
            ))}
          </ol>
        ) : (
          <p>No Users Attached</p>
        )}
        {<p style={{ color: "red", margin: 20 }}>{bottomText}</p>}
      </div>
    </Modal>
  );
};

const ReportForm = ({ report, birthParentPlans, partnerPlans, services, optimizations }) => {
  const [form] = Form.useForm();

  const {
    birthParent,
    event: {
      general: { children },
    },
    partner,
    status,
    type,
    confirmed,
    broaderNetworks,
  } = report;

  const [currentType, setCurrentType] = useState(type);

  const groupType =
    type === "individual" && !children
      ? "mo"
      : type === "household (with no spouse)" && children > 0
      ? "mc"
      : type === "household" && !children
      ? "ms"
      : type === "household" && children > 0
      ? "mf"
      : "mo";

  const premiumTierBP = birthParent.premiumTier;
  const premiumTierPartner = partner.premiumTier;

  const [labelRow, setLabelRow] = useState(broaderNetworks || []);

  const [modalVisible, setModalVisible] = useState(false);
  const [allowNotification, setAllowNotification] = useState(false);

  useEffect(() => {
    const fillNetworksField = async () => {
      const plansArr = [];
      try {
        const token = await getToken();

        //Get plansArr sorted by value as in Optimization/ monthlyCostTab
        //First BirthParent then Partner
        if (birthParent.planIds.length > 0) {
          const {
            data: { plansArr: birthParentPlans },
          } = await getPlansArr(birthParent.planIds, token);

          const adjustedBPPlans = birthParentPlans.map((_plan) => {
            let adjustedPlan = _plan;
            adjustedPlan.premium.tiers[premiumTierBP][groupType] *= _plan.premium.frequency;
            return adjustedPlan;
          });

          const sortedBirthParentPlans = adjustedBPPlans.sort(
            (plan1, plan2) =>
              plan1.premium.tiers[premiumTierBP][groupType] - plan2.premium.tiers[premiumTierBP][groupType],
          );

          plansArr.push(...sortedBirthParentPlans);
        }

        if (partner.planIds.length > 0) {
          const {
            data: { plansArr: partnerPlans },
          } = await getPlansArr(partner.planIds, token);

          const adjustedPartnerPlans = partnerPlans.map((_plan) => {
            let adjustedPlan = _plan;
            adjustedPlan.premium.tiers[premiumTierPartner][groupType] *= _plan.premium.frequency;
            return adjustedPlan;
          });
          const sortedPartnerPlans = adjustedPartnerPlans.sort(
            (plan1, plan2) =>
              plan1.premium.tiers[premiumTierPartner][groupType] - plan2.premium.tiers[premiumTierPartner][groupType],
          );
          plansArr.push(...sortedPartnerPlans);
        }

        //Transform plansArray into networkArray and remove duplicates
        const networksNames = plansArr.reduce((networkArray, plan) => {
          const network = plan.broaderNetwork;
          if (!networkArray.includes(network)) {
            return [...networkArray, network];
          }
          return networkArray;
        }, []);

        const newBroaderNetworks = networksNames.map((networkName) => {
          const existingNetwork = broaderNetworks.find((e) => e.name === networkName);
          return existingNetwork || { name: networkName, url: "" };
        });

        form.setFieldsValue({ broaderNetworks: newBroaderNetworks });

        changeGrid();
      } catch (e) {
        console.log(e);
      }
    };
    fillNetworksField();
  }, []);

  const setAllRow = (index, value) => {
    const grid = form.getFieldValue("providersGrid");
    const newNetworksValue = grid[index].networks.map((net) => ({
      name: net.name,
      accept: value,
    }));
    grid[index].networks = newNetworksValue;
    form.setFieldsValue({ providersGrid: grid });
  };

  const changeGrid = () => {
    const { providersList, broaderNetworks: _broaderNetworks, providersGrid } = form.getFieldsValue();
    setLabelRow(_broaderNetworks);
    const grid = providersList
      .filter((provider) => provider.name.length > 0)
      .map((provider) => {
        return {
          ...provider,
          networks: _broaderNetworks.map(({ name }) => ({
            name,
            accept: Boolean(
              providersGrid.find((el) => el.name === provider.name)?.networks.find((el) => el.name === name)?.accept,
            ),
          })),
        };
      });
    form.setFieldsValue({ providersGrid: grid });
  };

  const validateDate = (dateString) => {
    if (!dateString || !(typeof dateString === "string")) return false;
    const date = new Date(dateString);
    const normalizedDateString = dateString.replace(/[/\.]/g, "-");
    let [month, day, year] = normalizedDateString.split("-").map(Number);

    const cutoff = 30;
    if (year < 100) {
      year = year <= cutoff ? year + 2000 : year + 1900;
    }

    return (
      date instanceof Date &&
      !isNaN(date) &&
      (date.getFullYear() === year || date.getYear) &&
      date.getMonth() + 1 === month &&
      date.getDate() === day
    );
  };

  const showError = (messageText) => {
    message.error(messageText);
    return false;
  };

  const validateDateRange = (startDate, endDate) => {
    if (new Date(startDate) > new Date(endDate)) {
      return showError("Start Date cannot be greater than End Date");
    }
    return true;
  };

  const validateDueDateInRange = (startDate, endDate, dueDate) => {
    if (new Date(dueDate) < new Date(startDate) || new Date(dueDate) > new Date(endDate)) {
      return showError("Due Date must be between Start Date and End Date");
    }
    return true;
  };

  const validateDates = (startDate, endDate, isDue, dueDate) => {
    if (!validateDate(startDate)) return showError("Invalid Start Date");
    if (!validateDate(endDate)) return showError("Invalid End Date");

    if (!validateDateRange(startDate, endDate)) return false;

    if (isDue) {
      if (!validateDate(dueDate)) return showError("Invalid Due Date");
      if (!validateDueDateInRange(startDate, endDate, dueDate)) return false;
    }

    return true;
  };

  const handleReportUpdate = async (values, token) => {
    const response = await updateReport(report._id, values, token);

    if (response.status === 200) {
      message.success("Report Updated Successfully");

      if (values.status === 3) {
        setAllowNotification(true);
        setModalVisible(true);
      }
    } else {
      message.error("Error Updating Report");
    }
  };

  const onSubmit = async (values) => {
    const token = await getToken();

    if (!validateDates(values.dates[0], values.dates[1], values.isDue, values.dueDate)) {
      return;
    }

    await handleReportUpdate(values, token);
  };

  const ServiceField = ({ field, serviceType, serviceOptions, index }) => {
    const outOfNetworkTherapyOption = {
      serviceName: "Out-of-network Therapy",
      plansMissing: [],
    };

    const setServiceValue = (field, value) => {
      const servicesList = form.getFieldValue(serviceType);
      if (servicesList && servicesList.services && servicesList.services[index]) {
        servicesList.services[index][field] = value;
        form.setFieldsValue({ [serviceType]: servicesList });
      }
    };

    const service = form.getFieldValue(serviceType).services[index];
    const name = service && service.name;
    const isOut = name && name.includes("out-of-network");
    const [isOutOfNetwork, setIsOutOfNetwork] = useState(isOut);

    const defineServiceUnit = (serviceName) => {
      let definedValue;
      if (!isOut && serviceName) {
        const val = serviceOptions.find((el) => el.serviceName.toLowerCase().trim().replace(/ /g, "_") === serviceName);
        const unit = (val && val.service.maternityCopayDetails.unit) || null;
        definedValue = unit && unit !== "n/a" ? unit : undefined;
      }
      setServiceValue("unit", definedValue);
    };

    useEffect(() => defineServiceUnit(name), [isOutOfNetwork]);

    const renderServiceOption = (service) => {
      const value = service.serviceName.toLowerCase().trim().replace(/ /g, "_");
      const spanText = service.plansMissing.join(", ");
      const content = (
        <text>
          {service.serviceName} {service.plansMissing.length ? <WarningOutlined /> : ""}
        </text>
      );

      return (
        <Option key={value} disabled={service.plansMissing.length} value={value}>
          {service.plansMissing.length ? <span title={`Missing in plans ${spanText}`}>{content}</span> : content}
        </Option>
      );
    };

    const onChangeServiceName = (val, index) => {
      if (val.includes("out-of-network")) {
        setServiceValue("inNetwork", false);
        setIsOutOfNetwork(true);
      } else {
        setIsOutOfNetwork(false);
        if (
          serviceOptions.find((el) => el.service.serviceName.toLowerCase().trim().replace(/ /g, "_") === val)
            .plansMissing.length
        ) {
          message.warn("The service selected is missing in one or more plans");
        }
      }
      defineServiceUnit(val);
    };

    return (
      <Item required={false} key={field.key}>
        <Card type="inner" style={{ width: 500 }}>
          <Item {...field} label="Name" name={[field.name, "name"]} fieldKey={[field.fieldKey]} {...layout}>
            <Select onChange={(val) => onChangeServiceName(val, index)} placeholder="Select an Option">
              {[outOfNetworkTherapyOption, ...serviceOptions].map(renderServiceOption)}
            </Select>
          </Item>

          <Item {...field} label="Member" name={[field.name, "member"]} fieldKey={[field.fieldKey]} {...layout}>
            <Select placeholder="Select an Option">
              <Option value="birthParent">{birthParent.name}</Option>
              {type !== "individual" && partner.name ? <Option value="partner">{partner.name}</Option> : null}
              <Option value="baby">Baby</Option>
              {children ? <Option value="child">Child</Option> : null}
            </Select>
          </Item>

          <Item {...field} label="In network" name={[field.name, "inNetwork"]} fieldKey={[field.fieldKey]} {...layout}>
            <Select disabled={isOutOfNetwork} placeholder="Select an Option">
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Item>

          <Item {...field} label="Unit" name={[field.name, "unit"]} fieldKey={[field.fieldKey]} {...layout}>
            <Select placeholder="Select an Option">
              <Option value={"night"}>Night</Option>
              <Option value={"visit"}>Visit</Option>
            </Select>
          </Item>

          <Item
            {...field}
            label="Type of charge"
            name={[field.name, "chargeType"]}
            fieldKey={[field.fieldKey]}
            {...layout}
          >
            <Select placeholder="Select an Option">
              <Option value={"perUnit"}>Per Unit</Option>
              <Option value={"total"}>Total</Option>
            </Select>
          </Item>

          <Item
            {...field}
            label="Charge"
            name={[field.name, "charge"]}
            fieldKey={[field.fieldKey]}
            {...layout}
            extra="For pregnancy, input 25000 for the birth parent or 15403 for the baby"
          >
            <InputNumber
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            />
          </Item>

          <Item
            {...field}
            label="Quantity"
            name={[field.name, "qty"]}
            fieldKey={[field.fieldKey]}
            {...layout}
            extra="For hospital - input # of nights"
          >
            <InputNumber />
          </Item>
        </Card>
      </Item>
    );
  };

  const renderServices = (serviceType, serviceOptions) => {
    return (
      <Card type="inner" title={`${serviceType} services`} style={{ width: 660 }}>
        <Item label="Title" name={[serviceType, "title"]}>
          <Input placeholder={`${serviceType} Charges`} />
        </Item>
        <List name={[serviceType, "services"]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, i) => {
                return (
                  <div style={{ marginTop: "25px" }}>
                    <ServiceField field={field} index={i} serviceType={serviceType} serviceOptions={serviceOptions} />
                    <Button
                      danger
                      onClick={() => {
                        remove(field.name);
                      }}
                      block
                    >
                      <MinusOutlined /> Remove service
                    </Button>
                  </div>
                );
              })}
              <Item style={{ marginTop: "25px" }} {...formItemLayoutWithOutLabel}>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  block
                >
                  <PlusOutlined /> Add service
                </Button>
              </Item>
            </>
          )}
        </List>
      </Card>
    );
  };

  const renderInsuranceForm = (parent, isSpouse = false) => {
    const { company, name, pamphlet, parent: parentType } = parent;
    const parentPlans = parentType === "birthParent" ? birthParentPlans : partnerPlans;
    const parentPlansWithOON = parentPlans.filter((plan) => plan.outOfNetwork.coverage);

    return (
      <Card title={`Insurance Option - ${name ? name : "Not Provided"}`} extra={company} {...cardLayout}>
        <Item label="Person's Name" required name={[`${parentType}`, "name"]}>
          <Input placeholder="Name" />
        </Item>

        <Item label="Company" required name={[`${parentType}`, "company"]}>
          <Input placeholder="company" />
        </Item>

        <Item label="Premium Tier" required name={[`${parentType}`, "premiumTier"]}>
          <InputNumber />
        </Item>

        <Item label="Waiver Tier" required name={[`${parentType}`, "waiverTier"]}>
          <InputNumber />
        </Item>

        <Item label="Opt Out Payments Amt" name={[`${parentType}`, "optOut", "amount"]}>
          <InputNumber />
        </Item>

        <Item label="Opt Out Payments Frequency" name={[`${parentType}`, "optOut", "frequency"]}>
          <InputNumber />
        </Item>
        <Item label="Premiums are editable" name={[`${parentType}`, "canEditPremiums"]}>
          <Select placeholder="Select an Option" style={{ width: "50%" }}>
            <Option value={true}>Yes</Option>
            <Option value={false}>No</Option>
          </Select>
        </Item>
        <Item label="Plans are editable" name={[`${parentType}`, "canEditPlans"]}>
          <Select placeholder="Select an Option" style={{ width: "50%" }}>
            <Option value={true}>Yes</Option>
            <Option value={false}>No</Option>
          </Select>
        </Item>

        {isSpouse && (
          <Item label="Comparison Type" name={[`${parentType}`, "justForComparison"]}>
            <Select placeholder="Select an option" style={{ width: "50%" }}>
              {currentType === "household" && <Option value="partner">Partner</Option>}
              <Option value="jobOffer">Job Offer</Option>
            </Select>
          </Item>
        )}

        <Space type="horizontal" align="start">
          <Card type="inner" title="Plans" style={{ width: 445 }}>
            <List name={[`${parentType}`, "planIds"]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, i) => (
                    <Item {...layout} key={field.key} style={{ marginBottom: "2px" }}>
                      <div style={{ display: "flex" }}>
                        <Item
                          {...field}
                          label={i + 1}
                          name={field.name}
                          fieldKey={[field.fieldKey]}
                          rules={[{ required: true, message: "Can't be empty!" }]}
                        >
                          <Input />
                        </Item>

                        <MinusCircleOutlined
                          style={{ margin: "5px 8px" }}
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </div>
                    </Item>
                  ))}
                  <Item {...formItemLayoutWithOutLabel}>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add Plan
                    </Button>
                  </Item>
                </>
              )}
            </List>
          </Card>

          <Card type="inner" title="Pamphlet Details" style={{ width: 445 }}>
            <List name={[`${parentType}`, "pamphlet"]}>
              {(fields) => (
                <>
                  {fields.map((field, i) => (
                    <Item {...field} label={i + 1}>
                      <Link
                        href={`https://drive.google.com/open?id=${pamphlet[i].id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        black
                        fontSize="16"
                      >
                        <Text>{pamphlet[i].filename.slice(0, 40) + "..."}</Text>
                      </Link>
                    </Item>
                  ))}
                </>
              )}
            </List>

            <Item {...innerLayout} label="General Complete" name={[`${parentType}`, "generalIsComplete"]}>
              <Select placeholder="Select an Option" style={{ width: "50%" }}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Item>

            <Item {...innerLayout} label="Premium Complete" name={[`${parentType}`, "premiumIsComplete"]}>
              <Select placeholder="Select an Option" style={{ width: "50%" }}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Item>

            <Item {...innerLayout} label="HSA Complete" name={[`${parentType}`, "hsaIsComplete"]}>
              <Select placeholder="Select an Option" style={{ width: "50%" }}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Item>
          </Card>

          <Card type="inner" title="Out-Of-Network Details" style={{ width: 445 }}>
            <Item label="Reimbursement Rates" {...innerLayout} />
            <Item
              {...innerLayout}
              label="Default"
              name={[`${parentType}`, "outOfNetworkServices", "reimbursementRates", "default"]}
            >
              <InputNumber
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                placeholder="default"
              />
            </Item>

            {parentPlansWithOON.map((plan) => {
              const { name, network, externalId } = plan;
              return (
                <Item
                  {...innerLayout}
                  label={`${network} ${name}`}
                  name={[`${parentType}`, "outOfNetworkServices", "reimbursementRates", `${externalId}`]}
                  key={externalId}
                >
                  <InputNumber
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Item>
              );
            })}
          </Card>
        </Space>
      </Card>
    );
  };

  const renderPreferences = (preference, confirmed) => (
    <Card type="inner" title={`Preference ${preference}`} {...cardLayout}>
      <Item label="Apply Preference" name={["preferences", preference, "apply"]}>
        <Select placeholder="Select an Option" disabled={confirmed} style={{ width: "50%" }}>
          <Option value={true}>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      </Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.preferences[preference]?.apply !== currentValues.preferences[preference]?.apply
        }
      >
        {({ getFieldValue }) => {
          if (getFieldValue("preferences")[preference]?.apply === true)
            return (
              <>
                <Item label="Column Heading" name={["preferences", preference, "title"]}>
                  <Input placeholder="Lowest Cost" disabled={confirmed} />
                </Item>
                <Item label="Column Subheading" name={["preferences", preference, "subTitle"]}>
                  <Input disabled={confirmed} />
                </Item>
                <Item label="Plans to Join" name={["preferences", preference, "join"]}>
                  <Select placeholder="Select an Option" style={{ width: "50%" }} disabled={confirmed}>
                    <Option value="all">Join All</Option>
                    {type !== "individual" && <Option value="same">Same Plan</Option>}
                    {type !== "individual" && <Option value="separate">Separate Plans</Option>}
                    {birthParent.company && <Option value="birthParent">{birthParent.company}</Option>}
                    {partner.company && <Option value="partner">{partner.company}</Option>}
                  </Select>
                </Item>
                <Item label="Optimize for" name={["preferences", preference, "cost"]}>
                  <Select placeholder="Select an Option" style={{ width: "50%" }} disabled={confirmed}>
                    <Option value="lowest">Lowest Cost</Option>
                    <Option value="lowest 1">2nd Lowest Cost</Option>
                    <Option value="lowest 2">3rd Lowest Cost</Option>
                    <Option value="highest">Highest Cost</Option>
                    <Option value="highest 1">2nd Highest Cost</Option>
                    <Option value="highest 2">3rd Highest Cost</Option>
                  </Select>
                </Item>
                <Item label="Healthcare Assumptions" name={["preferences", preference, "onlyExpected"]}>
                  <Select placeholder="Select an Option" style={{ width: "50%" }} disabled={confirmed}>
                    <Option value={true}>Expected Services Only</Option>
                    <Option value={false}>Expected and Unexpected Services</Option>
                  </Select>
                </Item>
                <Item label="Tax and Investment Assumptions" name={["preferences", preference, "hsaHraImpact"]}>
                  <Select placeholder="Select an Option" style={{ width: "50%" }} disabled={confirmed}>
                    <Option value={"spending-only"}>Healthcare Spending Only</Option>
                    <Option value={"spending-hsa-hra"}>Healthcare Spending with HSA Benefit</Option>
                  </Select>
                </Item>
                <Item label="HSA Contribution" name={["preferences", preference, "hsaContribution"]}>
                  <Select placeholder="Select an Option" style={{ width: "50%" }} disabled={confirmed}>
                    <Option value={"nothing"}>Nothing</Option>
                    <Option value={"max"}>Maximum</Option>
                    <Option value={"other"}>Other Value</Option>
                  </Select>
                </Item>
                <Item
                  label="HSA Contribution Other Value"
                  name={["preferences", preference, "hsaContributionOtherValue"]}
                >
                  <InputNumber style={{ width: "50%" }} disabled={confirmed} />
                </Item>
                <Item label="Invest HSA" name={["preferences", preference, "hsaBalanceInvest"]}>
                  <Radio.Group style={{ border: "none" }}>
                    <Radio value={true} disabled={confirmed}>
                      Yes
                    </Radio>
                    <Radio value={false} disabled={confirmed}>
                      No
                    </Radio>
                  </Radio.Group>
                </Item>

                <Item label="Investment Return" name={["preferences", preference, "hsaBalanceReturn"]}>
                  <InputNumber style={{ width: "50%" }} disabled={confirmed} />
                </Item>
                <Item label="Investment Time Period" name={["preferences", preference, "hsaBalanceTimePeriod"]}>
                  <InputNumber style={{ width: "50%" }} disabled={confirmed} />
                </Item>
                <Item label="Tax Bracket" name={["preferences", preference, "taxBracket"]}>
                  <Select placeholder="Select an Option" style={{ width: "50%" }} disabled={confirmed}>
                    <Option value={10}>10%</Option>
                    <Option value={12}>12%</Option>
                    <Option value={22}>22%</Option>
                    <Option value={24}>24%</Option>
                    <Option value={32}>32%</Option>
                    <Option value={35}>35%</Option>
                    <Option value={37}>37%</Option>
                  </Select>
                </Item>

                {/* hsaContribution hsaContributionOtherValue taxBracket hsaBalanceInvest hsaBalanceReturn
                hsaBalanceTimePeriod */}
                <Card type="inner" title="Select Plans to include in optimization">
                  <Space direction="horizontal" style={{ alignItems: "start" }}>
                    {birthParent.company && (
                      <Item
                        label={birthParent.company}
                        disabled={confirmed}
                        name={["preferences", preference, "include", "birthParentPlans"]}
                        style={{
                          minWidth: "40vw",
                          whiteSpace: "nowrap",
                          display: "block",
                        }}
                      >
                        <Checkbox.Group>
                          {birthParentPlans.map((plan) => {
                            const { externalId, network, name } = plan;
                            return (
                              <div style={{ display: "block" }}>
                                <Checkbox
                                  value={externalId}
                                  style={{ lineHeight: "32px" }}
                                  disabled={confirmed}
                                  key={externalId}
                                >
                                  {`${network} ${name}`}
                                </Checkbox>
                              </div>
                            );
                          })}
                        </Checkbox.Group>
                      </Item>
                    )}
                    {partner.company && (
                      <Item
                        label={partner.company}
                        name={["preferences", preference, "include", "partnerPlans"]}
                        style={{
                          minWidth: "40vw",
                          whiteSpace: "nowrap",
                          display: "block",
                        }}
                      >
                        <Checkbox.Group>
                          {partnerPlans.map((plan) => {
                            const { externalId, network, name } = plan;
                            return (
                              <div style={{ display: "block" }}>
                                <Checkbox
                                  value={externalId}
                                  style={{ lineHeight: "32px" }}
                                  disabled={confirmed}
                                  key={externalId}
                                >
                                  {`${network} ${name}`}
                                </Checkbox>
                              </div>
                            );
                          })}
                        </Checkbox.Group>
                      </Item>
                    )}
                  </Space>
                </Card>
              </>
            );
        }}
      </Form.Item>
    </Card>
  );

  const sendNotification = async (emails, reportId) => {
    try {
      const token = await getToken();
      const response = await notifyUsers({ token, emails, reportId });
      if (response.status === 200) {
        message.success("Email sent");
      } else {
        message.success(response.response.message);
      }
    } catch (e) {
      message.error("Failed to send email");
    }
  };

  const onOkModal = (selectedEmails) => {
    if (allowNotification) {
      sendNotification(selectedEmails, report._id);
    }
    setAllowNotification(false);
    setModalVisible(false);
  };

  const onCancelModal = () => {
    setAllowNotification(false);
    setModalVisible(false);
  };

  const showCustomOverviewTile = form.getFieldValue("customOverviewTile")?.showCustomOverviewTile;
  const [isCustomOverviewTileExpanded, setCustomOverviewTileExpanded] = useState(showCustomOverviewTile);

  const showButton = form.getFieldValue("customOverviewTile")?.showButton;
  const [isButtonExpanded, setButtonExpanded] = useState(showButton);

  const toolbarOptions = [["bold", "italic", "underline", "strike"], ["link"], ["clean"]];
  return (
    <Form
      {...layout}
      form={form}
      initialValues={{ ...report, ...optimizations }}
      layout="horizontal"
      onFinish={onSubmit}
    >
      <Link blue onClick={() => setModalVisible(true)}>
        View users attached to this report
      </Link>
      {report && (
        <ReportUsersModal
          reportId={report._id}
          visible={modalVisible}
          allowNotification={allowNotification}
          onOk={onOkModal}
          onCancel={onCancelModal}
        />
      )}
      <div>
        <br></br>
        <p>
          *Save updates and reload report often, especially after changing household member/company/insurance details
        </p>
      </div>
      <Space direction="vertical">
        <Card title="Report Details" {...cardLayout}>
          <>
            <Item label="Is Confirmed" name="confirmed">
              <Select placeholder="Select an Option" style={{ width: "50%" }}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Item>

            <Item label="Is Paid" required name="isPaid">
              <Select placeholder="Select an Option" style={{ width: "50%" }}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Item>

            <Item
              label="Report Type"
              required
              name="type"
              rules={[
                ({ getFieldValue }) => ({
                  validator: (rule, value) => {
                    if (getFieldValue("status") === 3 && !value) {
                      return Promise.reject("Required when Completion Status = 3 - Complete");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select
                placeholder="Select an Option"
                style={{ width: "50%" }}
                onChange={(newValue) => {
                  setCurrentType(newValue);
                }}
              >
                <Option value="individual">Individual</Option>
                <Option value="household (with no spouse)">Household (with no spouse)</Option>
                <Option value="household">Household (with spouse)</Option>
              </Select>
            </Item>

            <Item label="For Business" name="forBusiness">
              <Select placeholder="Select an Option" style={{ width: "50%" }}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Item>

            <Item label="Zip Code" name={["event", "general", "zipCode"]}>
              <Input />
            </Item>

            <Item label="County Fips" name={["event", "general", "countyFips"]}>
              <Input />
            </Item>

            <Item label="Date of Birth" name={["event", "general", "dob"]}>
              <Input placeholder="MM/DD/YYYY" />
            </Item>

            <Item
              label="Is spouse?"
              required
              name={["event", "general", "isSpouse"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator: (rule, value) => {
                    if (getFieldValue("status") === 3 && value !== true && value !== false) {
                      return Promise.reject("Required when Completion Status = 3 - Complete");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select placeholder="Select an Option" style={{ width: "50%" }}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Item>

            <Item label="Consider Spouse Plan" name={["event", "marriage", "considerSpousePlan"]}>
              <Select placeholder="Select an Option" style={{ width: "50%" }}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Item>

            <Item label="Spouse Require Insurance" name={["event", "marriage", "spouseRequireIns"]}>
              <Select placeholder="Select an Option" style={{ width: "50%" }}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Item>

            <Item label="Children" name={["event", "general", "children"]}>
              <InputNumber />
            </Item>

            <List name="dates">
              {(fields) => (
                <>
                  {fields.map((field, i) => (
                    <Item key={i} {...field} required label={i === 0 ? "Start Date" : "End Date"}>
                      <Input placeholder="MM/DD/YYYY" />
                    </Item>
                  ))}
                </>
              )}
            </List>

            <Item label="Expecting a Baby?" name="isDue">
              <Select placeholder="Select an Option" style={{ width: "50%" }}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Item>

            <Item label="Event Date" name="dueDate" extra="For Baby - input due date">
              <Input placeholder="MM/DD/YYYY" />
            </Item>

            <Item label="Customized Options?" name="customizedOptions">
              <Select placeholder="Select an Option" style={{ width: "50%" }}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Item>

            <Item
              label="Supplements URL"
              name="supplementsUrl"
              rules={[
                ({ getFieldValue }) => ({
                  validator: (rule, value) => {
                    if (getFieldValue("customizedOptions") && !value) {
                      return Promise.reject("Required when Customized Options = Yes");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input type="url" />
            </Item>

            <Item
              label="Supplements Security Key"
              name="supplementsSecurityKey"
              rules={[
                ({ getFieldValue }) => ({
                  validator: (rule, value) => {
                    const isCustomized = getFieldValue("customizedOptions");
                    if (isCustomized && !value) {
                      return Promise.reject("Required when Customized Options = Yes");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input style={{ width: "50%" }} />
            </Item>

            <Item
              label="Completion Status"
              required
              name="status"
              rules={[
                ({ getFieldValue }) => ({
                  validator: (rule, value) => {
                    const preferenceOne = getFieldValue("preferences")[1];
                    if (
                      value === 3 &&
                      (!preferenceOne ||
                        !preferenceOne.apply ||
                        !preferenceOne.include ||
                        (!(preferenceOne.include.partnerPlans && preferenceOne.include.partnerPlans.length) &&
                          !(preferenceOne.include.birthParentPlans && preferenceOne.include.birthParentPlans.length)))
                    ) {
                      message.error("Completion status couldn't be updated. Missing preference 1");
                      return Promise.reject("Recommendation is missing; cannot update without recommendation");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select placeholder="Select an Option" style={{ width: "50%" }}>
                <Option value={0}>1 - Incomplete - missing details</Option>
                <Option value={2}>2 - Pending Review</Option>
                <Option value={3}>3 - Complete</Option>
              </Select>
            </Item>

            {status === 3 ? (
              <Item label="Completed Date" name="fulfilledDate">
                <Input placeholder="MM/DD/YYYY" />
              </Item>
            ) : null}

            <Item label="Allow Adding Partner Plans" name={["event", "marriage", "needToAddPartnerPlans"]}>
              <Select placeholder="Select an Option" style={{ width: "50%" }}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Item>

            <Item label="Report Version" name={["version"]}>
              <Select placeholder="Select an Option" style={{ width: "50%" }}>
                <Option value={"v1"}>v1</Option>
                <Option value={"v2"}>v2</Option>
              </Select>
            </Item>

            <Space direction="horizontal" align="start">
              {renderServices("expected", services)}
              {renderServices("unexpected", services)}
            </Space>
          </>

          {/* New Feature */}
          <Card title="Providers and Networks">
            <Item label="Display" required name="isProvidersAndNetworksDisplay">
              <Select placeholder="Display" defaultValue={false} style={{ width: "10%" }}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Item>
          </Card>

          <Space direction="horizontal" align="start">
            <Item>
              <Card type="inner" title="Networks" style={{ width: 660 }}>
                <List name="broaderNetworks">
                  {(fields) => (
                    <>
                      {fields.map((field, i) => {
                        return (
                          <Item key={"broaderNetworks" + i} style={{ marginBottom: "2px" }}>
                            <div style={{ display: "flex" }}>
                              <Item
                                style={{ width: 200, marginLeft: 10 }}
                                name={[field.fieldKey, "name"]}
                                fieldKey={[field.fieldKey, "name"]}
                              >
                                {/* <input disabled/> */}
                                <DisabledInput disabled />
                              </Item>
                              <Item
                                style={{ width: 200, marginLeft: 10 }}
                                name={[field.fieldKey, "url"]}
                                fieldKey={[field.fieldKey, "url"]}
                              >
                                <Input placeholder="URL" />
                              </Item>
                            </div>
                          </Item>
                        );
                      })}
                    </>
                  )}
                </List>
              </Card>
            </Item>
            <Item>
              <Card type="inner" title="Providers" style={{ width: 660 }}>
                <List name="providersList">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, i) => (
                        <Item key={"providersList" + i} style={{ marginBottom: "2px" }}>
                          <div style={{ display: "flex" }}>
                            <Item
                              style={{ width: 200 }}
                              rules={[{ required: true, message: "Can't be empty!" }]}
                              name={[field.name, "name"]}
                              fieldKey={[field.fieldKey, "name"]}
                            >
                              <Input placeholder="Name" />
                            </Item>

                            <Item fieldKey={[field.fieldKey, "type"]} name={[field.name, "type"]}>
                              <Select placeholder="Type" style={{ width: 100 }}>
                                <Option value="Doctor">Doctor</Option>
                                <Option value="Drug">Drug</Option>
                                <Option value="Hospital">Hospital</Option>
                                <Option value="Other">Other</Option>
                              </Select>
                            </Item>

                            <MinusCircleOutlined
                              style={{ margin: "5px 8px" }}
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          </div>
                        </Item>
                      ))}

                      <Item {...formItemLayoutWithOutLabel}>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                          block
                        >
                          <PlusOutlined /> Add Provider
                        </Button>
                      </Item>
                    </>
                  )}
                </List>
              </Card>
            </Item>
          </Space>
          <Item>
            <Card
              type="inner"
              title="Provider Grid"
              style={labelRow.length > 6 ? { width: `${500 + labelRow.length * 120}px` } : { width: "1300px" }}
            >
              <div style={{ display: "flex" }}>
                <div style={leftColGrid} />
                <Space direction="horizontal">
                  <>
                    {labelRow.map((network, i) => (
                      <p key={"proviGrid" + i + "broadNet"} style={textCelStyle}>
                        {network.name}
                      </p>
                    ))}
                  </>
                </Space>
              </div>
              <List name="providersGrid">
                {(fields) => (
                  <>
                    {fields.map((field, i) => (
                      <Item style={{ marginBottom: "2px" }} key={`proviGrid${i}`}>
                        <div style={{ display: "flex" }}>
                          <Space direction="horizontal" style={leftColGrid}>
                            <Item
                              style={textCelStyle}
                              name={[field.fieldKey, "name"]}
                              fieldKey={[field.fieldKey, "name"]}
                            >
                              {/* <input disabled/> */}
                              <DisabledInput disabled />
                            </Item>
                            <Item
                              style={textCelStyle}
                              name={[field.fieldKey, "type"]}
                              fieldKey={[field.fieldKey, "type"]}
                            >
                              {/* <input disabled/> */}
                              <DisabledInput disabled />
                            </Item>
                            <Item>
                              <Button onClick={() => setAllRow(i, true)}>{"Yes for all"}</Button>
                            </Item>
                            <Item>
                              <Button onClick={() => setAllRow(i, false)}>{"No for all"}</Button>
                            </Item>
                          </Space>
                          <Item>
                            <List name={[field.fieldKey, "networks"]}>
                              {(innerFields) => (
                                <Space direction="horizontal">
                                  {innerFields.map((innerField, j) => (
                                    <Item style={textCelStyle} name={[innerField.fieldKey, "accept"]}>
                                      <Select>
                                        <Option value={true}>Yes</Option>
                                        <Option value={false}>No</Option>
                                      </Select>
                                    </Item>
                                  ))}
                                </Space>
                              )}
                            </List>
                          </Item>
                        </div>
                      </Item>
                    ))}
                  </>
                )}
              </List>
            </Card>
          </Item>
        </Card>

        <Card title="Optimization Results" {...cardLayout}>
          <Item label="Recommended Option" required name="title">
            <Input.TextArea />
          </Item>

          <Item label="Plan Comparison Page Title" required name={["results", "comparisonTitle"]}>
            <Input.TextArea />
          </Item>

          <Item label="Overview - Rationale" required name={["results", "downside"]}>
            <Input.TextArea />
          </Item>

          <List name={["results", "whatNow"]}>
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field, i) => (
                    <Item
                      {...(i === 0 ? layout : formItemLayoutWithOutLabel)}
                      label={i === 0 ? "Overview - What Now?" : ""}
                      required={false}
                      key={field.key}
                    >
                      <Item {...field} name={field.name} fieldKey={[field.fieldKey]} style={{ marginBottom: 0 }}>
                        <Input.TextArea style={{ display: "inline" }} />
                      </Item>

                      {fields.length > 1 ? (
                        <Button
                          danger
                          onClick={() => {
                            remove(field.name);
                          }}
                          block
                        >
                          <MinusOutlined /> Remove step
                        </Button>
                      ) : null}
                    </Item>
                  ))}
                  <Item {...formItemLayoutWithOutLabel}>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add step
                    </Button>
                  </Item>
                </>
              );
            }}
          </List>
          <Card title="Custom Overview Tile">
            <Item label="Display" required name={["customOverviewTile", "showCustomOverviewTile"]}>
              <Select
                placeholder="Display"
                defaultValue={false}
                style={{ width: "10%" }}
                onChange={() => setCustomOverviewTileExpanded(!isCustomOverviewTileExpanded)}
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Item>
            {showCustomOverviewTile && (
              <div>
                <Item label="Heading" required name={["customOverviewTile", "heading"]}>
                  <Input placeholder="..." />
                </Item>
                <Item label="Subheading" name={["customOverviewTile", "subheading"]}>
                  <Input placeholder="..." />
                </Item>
                <Item label="Mini-Title" required name={["customOverviewTile", "miniTitle"]}>
                  <Input placeholder="..." />
                </Item>

                <Item label="Body" required name={["customOverviewTile", "body"]}>
                  <ReactQuill theme="snow" modules={{ toolbar: toolbarOptions }} />
                </Item>
                <Item label="Display Button" required name={["customOverviewTile", "showButton"]}>
                  <Select
                    placeholder="Display Button"
                    defaultValue={false}
                    style={{ width: "10%" }}
                    onChange={() => setButtonExpanded(!isButtonExpanded)}
                  >
                    <Option value={true}>Yes</Option>
                    <Option value={false}>No</Option>
                  </Select>
                </Item>
                {showButton && (
                  <>
                    <Item label="Button Text" required name={["customOverviewTile", "buttonText"]}>
                      <Input placeholder="..." />
                    </Item>
                    <Item label="Button Link" required name={["customOverviewTile", "buttonLink"]}>
                      <Input placeholder="URL" />
                    </Item>
                  </>
                )}
              </div>
            )}
          </Card>
        </Card>

        {renderInsuranceForm(birthParent)}
        {renderInsuranceForm(partner, true)}
        {/* {renderPreferences("recommendation", confirmed)} */}
        {renderPreferences("1", confirmed)}
        {renderPreferences("2", confirmed)}
        {renderPreferences("3", confirmed)}
        {report.preferences["4"] && renderPreferences("4", confirmed)}
        {report.preferences["5"] && renderPreferences("5", confirmed)}
        {report.preferences["6"] && renderPreferences("6", confirmed)}

        <Item name={["preferences", "custom"]} hidden />
        <Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Item>
      </Space>
    </Form>
  );
};

export default ReportForm;
