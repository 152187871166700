import React from "react";
import styled from "styled-components";
import { Text, Currency } from "components";

const marginChild = (identation) => {
  console.log('id',identation)
  if (!identation) return "4px";
  return  4 + 16 * identation + "px";
};

const StyledBody = styled.div`
  width: 100%;
  max-height: 225px;
  @media print {
    max-height: fit-content;
    overflow-y: none;
  }
`;

const cost = (price) => (price ? <Currency price={price} /> : "-");

const Row = styled.div`
  display: grid;
  align-items: end;
  width: 100%;
  padding: 8px 25px;
  grid-template-columns: 20% 12% 23% 10% 12% 12% 11%;
  ${({ parent }) => parent && `border-bottom: 1px solid #e4e4e46b;`}
`;

const StyledText = styled(Text)`
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #21355B;
  ${({ bold }) => bold && "font-weight: bold;"}
  ${({ price }) => price && `text-align: end;`}
  ${({ center }) => center && `text-align: center;`}
  margin-left: 4px;
  ${({ identation }) => identation && ` margin-left: ${marginChild(identation)}`};

`;

const network = inNetwork => inNetwork === true ? 'Yes' : inNetwork === false ? 'No' : '';

const Cell = styled.div`
`;

const BillText = ({ children, ...props }) => {
  return (
    <Cell>
      <StyledText identation={marginChild(props.identation)} circular {...props}>{children}</StyledText>
    </Cell>
  )
};

const Parent = ({
  parent: {
    provider,
    doctorCharges,
    insuranceRates,
    notCovered,
    inNetwork,
    service,
  },
}) => (
  <Row parent color={"white"}>
    <BillText>
      {provider}
    </BillText>
    <BillText center>
      {network(inNetwork)} 
    </BillText>
    
    <BillText>
      {service.description}
    </BillText>
    
    <BillText>
      {service.code}
    </BillText>
    <BillText right>
      {cost(doctorCharges)}
    </BillText>
    <BillText right>
      {cost(insuranceRates)}
    </BillText>
    <BillText right>
      {cost(notCovered)}
    </BillText>
  </Row>
);

const Children = ({ children, identation }) => (
  <>
    {children.map(
      ({
        id,
        children,
        data: {
          provider,
          doctorCharges,
          insuranceRates,
          notCovered,
          inNetwork,
          service,
        },
      }) => (
        <React.Fragment key={id}>
          <Row>
            <BillText identation={identation}>
              {provider}
            </BillText>
            <BillText center>
              {network(inNetwork)} 
            </BillText>
            <BillText identation={identation}>
              {service.description}
            </BillText>
            <BillText center>
            </BillText>
            <BillText right>
              {cost(doctorCharges)}
            </BillText>

            <BillText right>
            {cost(insuranceRates)}
            </BillText>

            <BillText right>
            {cost(notCovered)}
            </BillText>
          </Row>
          {hasChildren(children) && <Children children={children} identation={identation+1}/>}
        </React.Fragment>
      )
    )}
  </>
);

const hasChildren = (children) => {
  return children && children.length > 0;
};

const renderRow = (row) => (
  <React.Fragment key={row.id}>
    <Parent className={'parent'} parent={row.data} />
    <Children className={'children'} children={row.children} identation={1} />
  </React.Fragment>
);

const BillContentMain = ({ tree }) => {
  return (
    <StyledBody>
      {tree.map(renderRow)}
    </StyledBody>
  ) 
};

export default React.memo(BillContentMain);
