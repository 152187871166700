import React from "react";
import styled from "styled-components";
import { Text, ButtonLink, Link } from "components";

const Wrapper = styled.div`
  font-family: 15px;
  color: #123;
  padding: 20px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div`
  max-width: 557px;
  width: 100%;
`;

// Onboarding is incomplete for current report
// Provide redirect link to onboarding
const IncompleteOnboarding = ({
  setIsCurrentOnboarding,
  toggleBackgroundColor,
}) => {
  const handleRedirectToRecommendations = () => {
    setIsCurrentOnboarding(false);
    toggleBackgroundColor(false);
  };

  return (
    <Wrapper>
      <br></br>
      <TextWrapper>
        <Text green center fontSize="18" lineHeight="22.63">
          We noticed that you didn’t complete your account the last time you
          were here, and we need a few more details from you in order to give
          you your recommendation. Would you like to continue where you left
          off?
        </Text>
      </TextWrapper>
      <br></br>
      <ButtonLink withBG button fontSize="18" to="/onboarding">
        Yes, I want to continue
      </ButtonLink>
      <br></br>
      <ButtonLink withBorder button fontSize="14" to="/onboarding/new">
        No, I want to start over
      </ButtonLink>
      <br></br>
      <br></br>
      <Link blue fontSize="14" onClick={handleRedirectToRecommendations}>
        Take me to My Recommendations Home
      </Link>
    </Wrapper>
  );
};

export default IncompleteOnboarding;
