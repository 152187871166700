export const frequencyOptions = [
  {
    name: "Monthly",
    value: 1,
  },
  {
    name: "Per semi-monthly paycheck (24 paychecks per year)",
    value: 2,
  },
  {
    name: "Per bi-weekly paycheck (26 paychecks per year)",
    value: 26 / 12,
  },
  {
    name: "Weekly",
    value: 52 / 12,
  },
  {
    name: "Yearly",
    value: 1/12,
  },
];

export const surchargeWaiverFrequencyOptions = [
  {
    name: "Monthly",
    value: 1,
  },
  {
    name: "Yearly",
    value: 1/12,
  }
]

export const healthFrequencyOptions = [
  {
    name: "once a year",
    value: 1,
  },
  {
    name: "twice a year",
    value: 2,
  },
  {
    name: "monthly",
    value: 12,
  },
  {
    name: "per semi-monthly paycheck (24 paychecks per year)",
    value: 24,
  },
  {
    name: "per bi-weekly paycheck (26 paychecks per year)",
    value: 26,
  },
];

export const healthTowardOptions = [
  {
    name: "first",
    value: "first",
  },
  {
    name: "last",
    value: "last",
  },
];

export const costForOptions = [
  {
    value: "mo",
    name: "Me only",
  },
  {
    value: "ms",
    name: "Me and my spouse/domestic partner",
  },
  {
    value: "mc",
    name: "Me and my child(ren)",
  },
  {
    value: "mf",
    name: "Me, my spouse/domestic partner, and my child(ren)",
  },
];

export const OutOfNetworkCoverageOptions = [
  {
    value: "yes",
    name: "Yes",
  },
  {
    value: "no",
    name: "No, it's in-network only",
  },
];

export const networkOptions = [
  {
    value: "Aetna",
    label: "Aetna",
  },
  {
    value: "Blue Cross and/or Blue Shield (including Anthem)",
    label: "Blue Cross and/or Blue Shield (including Anthem)",
  },
  {
    value: "Cigna",
    label: "Cigna",
  },
  {
    value: "Kaiser",
    label: "Kaiser",
  },
  {
    value: "United",
    label: "United",
  },
  {
    value: "",
    label: "Other",
  },
];

export const categoryOptions = [
  { value: 'HDHP', label: 'HDHP' },
  { value: 'PPO', label: 'PPO' },
  { value: 'POS', label: 'POS' },
  { value: 'PPO/POS', label: 'PPO/POS' },
  { value: 'EPO', label: 'EPO' },
  { value: 'HMO', label: 'HMO' },
  { value: 'EPO/HMO', label: 'EPO/HMO' },
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Silver', label: 'Silver' },
  { value: 'Gold', label: 'Gold' },
  { value: 'Platinum', label: 'Platinum' },
  { value: 'Catastrophic', label: 'Catastrophic' },
  { value: 'Other', label: 'Other' }
]

export const yesOrNoOptions = [
  {
    value: "yes",
    name: "Yes",
  },
  {
    value: "no",
    name: "No",
  },
];

export const yesOrNoHealthOptions = [
  {
    value: "no",
    name: "No, they don't contribute anything",
  },
  {
    value: "HSA",
    name: "Yes, an HSA (Health Savings Account)",
  },
  {
    value: "HRA",
    name: "Yes, an HRA (Health Reimbursement Acccount)",
  },
  {
    value: "both",
    name: "Yes, both an HSA and an HRA",
  },
];

export const familyTypeOptions = [
  {
    value: "Embedded",
    name: "Embedded",
  },
  {
    value: "Aggregated",
    name: "Aggregate",
  },
];

export const copayOptions = [
  {
    value: "stay",
    name: "stay",
  },
  {
    value: "night",
    name: "night",
  },
];

export const haveToPayOptions = [
  {
    value: "coinsurance",
    name: "Coinsurance",
  },
  {
    value: "copay",
    name: "Copay",
  },
  {
    value: "both",
    name: "Copay and Coinsurance",
  },
];
