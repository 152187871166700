export default {
  formId: "servicesForm",
  formField: {
    expectedTable: {
      name: "expectedTable",
      requiredErrorMsg: "All Service Table fields are required",
    },
    prescriptionsTable: {
      name: "prescriptionsTable",
      requiredErrorMsg: "All Prescriptions Table fields are required",
    },
    pregnancyTable: {
      name: "pregnancyTable",
      requiredErrorMsg: "All Pregnancy Table fields are required",
    },
    fertilityTable: {
      name: "fertilityTable",
      requiredErrorMsg: "All Fertility Table fields are required",
    },
    unexpectedTable: {
      name: "unexpectedTable",
      requiredErrorMsg: "All Unexpected Table fields are required",
    },
    table: {
      name: "table",
      requiredErrorMsg: "All Plan Table fields are required",
    },
    partnerTable: {
      name: "partnerTable",
      requiredErrorMsg: "All Plan Table fields are required",
    },
    subjectToDeductibleG1: {
      name: "subjectToDeductibleG1",
    },
    subjectToDeductibleG2: {
      name: "subjectToDeductibleG2",
    },
    FSTypeG1: {
      name: "FSTypeG1",
    },
    FSMaxAmountG1: {
      name: "FSMaxAmountG1",
    },
    FSMaxUnitsG1: {
      name: "FSMaxUnitsG1",
    },
    FSTypeG2: {
      name: "FSTypeG2",
    },
    FSMaxAmountG2: {
      name: "FSMaxAmountG2",
    },
    FSMaxUnitsG2: {
      name: "FSMaxUnitsG2",
    },
  },
};
