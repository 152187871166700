import React, { useState } from "react";
import styled from "styled-components";
import {
  AntModalView as ModalView,
  ButtonLink,
  CalculateHeader as Header,
  Text,
  OptimizeReport,
} from "components";
import { Redirect } from "react-router-dom";
import { getBillIds } from "services/api/bills.api";

import { CustomOptions, CustomPlans } from "containers";
import useModal from "services/hooks/useModal.hook";
import { Steps } from "antd";
import "antd/dist/antd.css";

import { checkCustomPlanFormData } from "services/utils";
import {
  validation,
  customPlanCompleteFormSendSchema,
} from "services/validation";

const { Step } = Steps;

const Wrapper = styled.div`
  font-family: 15px;
  color: #123;
  padding: 20px;
`;

const StyledSteps = styled(Steps)`
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
    border-color: #00a19b;
    > .ant-steps-icon {
      color: #00a19b;
    }
  }

  .ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-icon {
    background: #00a19b;
    border-color: #00a19b;
  }
`;

const FlexWrapper = styled.section`
  display: flex;
  width: 100%;
  padding: 10px 60px;
  justify-content: space-between;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
`;

const CustomMain = ({ isAuth, custom, customId }) => {
  const [step, setStep] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [billIds, setBillIds] = useState([]);
  const { handleClick, isShow } = useModal();

  const next = () => {
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
    setStep(step + 1);
  };

  const prev = () => {
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
    setStep(step - 1);
  };

  const handleRedirect = () => {
    setRedirect(true);
  };

  const checkStep = () => {
    const {
      birthParent,
      partner,
      isDue,
      dueDate,
      firstBaby,
      useOutOfNetwork,
    } = custom;

    if (step === 0) {
      if (!(birthParent.optionsAmount || partner.optionsAmount)) {
        return false;
      }

      if (
        !(
          (birthParent.optionsAmount &&
            birthParent.group &&
            birthParent.startDate &&
            ((birthParent.optOut.isApply &&
              birthParent.optOut.amount &&
              birthParent.optOut.frequency.value) ||
              birthParent.optOut.isApply === false)) ||
          birthParent.optionsAmount === 0
        )
      ) {
        return false;
      }

      if (
        !(
          (partner.optionsAmount &&
            partner.group &&
            partner.startDate &&
            ((partner.optOut.isApply &&
              partner.optOut.amount &&
              partner.optOut.frequency.value) ||
              partner.optOut.isApply === false)) ||
          partner.optionsAmount === 0
        )
      ) {
        return false;
      }

      if (
        !(
          firstBaby !== null &&
          useOutOfNetwork !== null &&
          (isDue === false || (isDue && dueDate))
        )
      ) {
        return false;
      }

      if (
        firstBaby === false &&
        birthParent.optionsAmount > 0 &&
        birthParent.extraChildren.value !== false
      ) {
        return false;
      }

      if (
        firstBaby === false &&
        partner.optionsAmount > 0 &&
        partner.extraChildren.value !== false
      ) {
        return false;
      }

      return true;
    }

    if (step === 2) {
      let check = true;

      for (let i = 0; i < birthParent.options.length; i++) {
        if (check === false) break;

        const plan = birthParent.options[i];
        const formDataSend = checkCustomPlanFormData({
          ...plan,
          group: birthParent.group,
          useOutOfNetwork,
          startDate: birthParent.startDate,
        });
        const valid = validation(
          customPlanCompleteFormSendSchema,
          formDataSend
        );
        if (!valid) check = false;
      }

      for (let i = 0; i < partner.options.length; i++) {
        if (check === false) break;

        const plan = partner.options[i];
        const formDataSend = checkCustomPlanFormData({
          ...plan,
          group: partner.group,
          useOutOfNetwork,
          startDate: partner.startDate,
        });
        const valid = validation(
          customPlanCompleteFormSendSchema,
          formDataSend
        );
        if (!valid) check = false;
      }

      return check;
    }

    return true;
  };

  const handleContinue = async () => {
    if (step === 0 && !isAuth) {
      handleClick();
    } else if (step === 0) {
      next();
    } else if (step === 2) {
      const formBillsSend = {
        practice: null,
        hospital: "all",
        network: true,
        proceduresTitle: "",
        insuranceRates: "",
      };

      const { data } = await getBillIds(formBillsSend);
      await setBillIds(data);
      await next();
    } else {
      next();
    }
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: "/signin",
          destinationRoute: "/self-serve",
        }}
      />
    );
  }

  return (
    <>
      <ModalView
        close={handleClick}
        isShow={isShow}
        isInfo
        isCancel
        handleRegister={handleRedirect}
        handleAction={() => {
          handleClick();
          next();
        }}
        textButton="Continue without saving"
        title="Save your choices!"
      >
        <Text modal arial>
          Sign in now to save your choices. You can choose to continue without
          saving.
        </Text>
      </ModalView>

      <Wrapper>
        <Header
          title="How much will it cost you to have a baby in Manhattan?"
          greenText=""
          text=""
        />
        <InnerWrapper>
          <StyledSteps current={step}>
            <Step title="Getting Started" description="Your Options" />
            <Step title="Birth Parent" description="Plan Details" />
            <Step title="Non-Birth Parent" description="Plan Details" />
            <Step title="Optimization" description="Your Results" />
          </StyledSteps>

          {step === 0 && <CustomOptions />}
          {step === 1 && <CustomPlans parent="birthParent" />}
          {step === 2 && <CustomPlans parent="partner" />}

          {step === 3 && billIds.length > 0 && (
            <OptimizeReport
              customId={customId}
              custom={custom}
              billIds={billIds}
            />
          )}

          <FlexWrapper row>
            {step !== 0 ? (
              <ButtonLink textAlign withBG button fontSize="18" onClick={prev}>
                Previous
              </ButtonLink>
            ) : (
              <div></div>
            )}

            {checkStep() && step !== 3 && (
              <ButtonLink
                textAlign
                withBG
                button
                fontSize="18"
                onClick={handleContinue}
              >
                Continue
              </ButtonLink>
            )}
          </FlexWrapper>
        </InnerWrapper>
      </Wrapper>
    </>
  );
};

export default CustomMain;
