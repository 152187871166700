import { getNetworksGrid } from "services/api";
import initialValues from "./initialValues";
import model from "./model";

const {
  formField: { networksTable, providersList },
} = model;

const loadValuesFromNetworksGrid = async (gridId) => {
  const { status, data } = await getNetworksGrid({ id: gridId });
  if (status !== 200) {
    return initialValues;
  }

  const { grid } = data;

  return {
    [networksTable.name]: grid.networks.map((network, index) => {
      return {
        tableName: networksTable.name,
        idx: index + 1,
        ...network,
      };
    }),
    [providersList.name]: grid.providersGrid.map((provider, index) => {
      return {
        tableName: providersList.name,
        idx: index + 1,
        ...provider,
      };
    }),
  };
};

const loadValuesFromReport = async (reportId, token) => {
  // todo
};

export const loadValues = async ({ gridId, reportId, token }) => {
  if (gridId) {
    return await loadValuesFromNetworksGrid(gridId);
  } else if (reportId) {
    return await loadValuesFromReport(reportId, token);
  } else {
    return initialValues;
  }
};
