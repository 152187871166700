import configure from "./configure";
import stringify from "./query";
import getFullQuery from "./query";
import { getUserId } from "../utils/tokens";

const { setHeadersWithAuth, axios, standardHeaders } = configure;

export const getReport = async (reportId, token) => {
  const headers = setHeadersWithAuth(token);
  const query = stringify({ reportId });
  return axios.get(`reports/get-report${query}`, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    }
  );
};

export const createReport = async (form) => {
  const userId = await getUserId();
  const body = { userId, form };
  return axios.post("reports/createV2", body, standardHeaders).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    }
  );
};

export const setReportSeen = async (reportId) => {
  const body = { reportId };
  const response = await axios.post(
    "reports/report-seen",
    body,
    standardHeaders
  );
  return response;
};

export const uploadPamphlets = async (data) => {
  const userId = await getUserId();
  data.append("userId", userId);

  const response = await axios.post("reports/upload-pamphlets", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

// update route when going through onboarding
export const updateReportV2 = async (reportId, form) => {
  const userId = await getUserId();
  const body = { userId, reportId, form };
  return axios.post("reports/updateV2", body, standardHeaders).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    }
  );
};

// update route when form matches report structure
export const updateReport = async (reportId, form, token) => {
  const headers = setHeadersWithAuth(token);
  const body = { reportId, form };
  return axios.post("reports/update", body, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    }
  );
};

export const viewReport = (documentId, token) => {
  const headers = setHeadersWithAuth(token);
  const query = stringify({ documentId });
  return axios.get(`reports/get-document${query}`, headers);
};

export const getDocumentById = (documentId, token) => {
  const headers = {
    responseType: "blob",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/pdf",
      Authorization: `Bearer ${token}`,
    },
  };
  const query = stringify({ documentId });
  return axios.get(`reports/get-document${query}`, headers);
};

export const getReportsHeader = (reportIds, token) => {
  const headers = setHeadersWithAuth(token);
  const query = getFullQuery({ reportIds });
  return axios.get("reports/get-reports" + query, headers);
};

export const updatePreferenceResults = ({
  reportId,
  token,
  preferenceKey,
  results,
}) => {
  const headers = setHeadersWithAuth(token);
  const body = { reportId, preferenceKey, results };
  return axios.post("reports/update-preference-results", body, headers);
};

export const getAllMyOptions = async (reportId, token, onlyExpected = false) => {
  const headers = setHeadersWithAuth(token);
  const body = { reportId, onlyExpected };
  return axios.post(`/optimize/all-my-options`, body, headers).then(
    (res) => {
      return res.data.allMyOptions;
    },
    (error) => {
      return error;
    }
  );
};

export const createNewPreference = async (
  reportId,
  preferenceKey,
  preference,
  token
) => {
  const headers = setHeadersWithAuth(token);
  const body = { preference };
  return axios
    .patch(`/reports/${reportId}/preference/${preferenceKey}`, body, headers)
    .then(
      (res) => {
        return res;
      },
      (error) => {
        return error;
      }
    );
};

export const disablePreference = async (reportId, preferenceKey, token) => {
  const preference = {
    title: ``,
    subTitle: "",
    apply: false,
    join: null,
    cost: "lowest",
    childrenJoin: null,
    include: {
      birthParentPlans: [],
      partnerPlans: [],
    },
    exclude: {
      birthParentPlans: [],
      partnerPlans: [],
    },
    lifeEvent: {
      name: null,
      date: null,
      allowSwitch: false,
    },
    bills: {
      inNetworkParent: 25285,
      hospitalVisits: 1,
    },
  };

  const headers = setHeadersWithAuth(token);
  const body = { preference };
  return axios
    .patch(`/reports/${reportId}/preference/${preferenceKey}`, body, headers)
    .then(
      (res) => {
        return res;
      },
      (error) => {
        return error;
      }
    );
};

export const groupNewReport = async ({
  userId,
  userTypeformResponse,
  pregnancyTypeformResponse,
  group,
  additionalInformation,
  plansValues
}) => {
  const body = {
    userId,
    userTypeformResponse,
    pregnancyTypeformResponse,
    group,
    additionalInformation,
    plansValues
  };
  return axios.post(`reports/group-new-report`, body, configure.standardHeaders).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    }
  );
}

export const groupAddPartner = async ({
  reportId,
  partnerType,
  group,
  additionalInformation,
  plansValues,
  userId,
  primary
}) => {
  const body = {
    reportId,
    partnerType,
    group,
    additionalInformation,
    plansValues,
    userId,
    primary
  };
  return axios.patch(`reports/group-add-partner`, body, configure.standardHeaders).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    }
  );
}

export const getGroupReportInfo = async ({ group, additionalInformation }) => {
  const query = stringify({ group, additionalInformation: JSON.stringify(additionalInformation) });
  return axios.get(`reports/group-report-info${query}`, configure.standardHeaders).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    }
  );
}

export const updatePremiums = async ({
  reportId,
  isPartner,
  plansValues
}) => {
  const body = {
    reportId,
    isPartner,
    plansValues
  }
  return axios.patch(`reports/update-premiums`, body, configure.standardHeaders).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    }
  );
}