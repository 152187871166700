import React from "react";
import { Text, TableCell, Link } from "components";
import { formatCurrency } from "utils/currency";
import { getFieldKey } from "./utils";
import { CollapseSeparator, IconWrapper, colorFromLevel, Row } from "./styles";
import { useWhatIfContext } from "./context";
import { Collapse } from "react-collapse";

export const CurrencyCellPair = ({ values, fontSize = "14", underline, variation = "default" }) => {
  const first = values?.[0] || 0;
  const second = values?.[1] || 0;

  const textProps =
    variation === "bold"
      ? {
          black: true,
          small: true,
          center: true,
        }
      : { grey: true, center: true, fontSize: fontSize, underline: underline };

  return (
    <>
      <TableCell tall middle>
        <Text {...textProps}>{formatCurrency(second)}</Text>
      </TableCell>
      <TableCell tall width="20px" />
      <TableCell tall middle>
        <Text {...textProps}>{formatCurrency(first)}</Text>
      </TableCell>
    </>
  );
};

export const ExpandIcon = ({ expanded }) => {
  return <IconWrapper type={expanded ? "up" : "down"} />;
};

/**
 * RowItemTitle component.
 *
 * @param {Object} props - Component props.
 * @param {number} props.level - The level for the row item.
 * @param {boolean} props.canExpand - Whether the row item can be expanded.
 * @param {string} props.field - The field for the row item. oneOf(FIELD_OPTIONS)
 * @param {string} props.companyType - The company type for the row item. oneOf(COMPANY_TYPES)
 * @param {string} props.period - The period for the row item. oneOf(PERIOD_OPTIONS)
 * @param {React.ReactNode} props.children - The text for the row item.
 * @param {"category"|"subCategory"} [props.rowType] - The type for the item row
 *
 * @returns {JSX.Element} The RowItemTitle component.
 */
export const RowItemTitle = ({ level, canExpand, field, companyType, period, children, rowType = null }) => {
  const { handleExpand, isExpanded } = useWhatIfContext();
  const expandKey = getFieldKey({ field, companyType, period });
  const expanded = isExpanded({ field, companyType, period });

  return (
    <Row justify="start" category={rowType === "category"} subCategory={rowType === "subCategory"}>
      {canExpand ? (
        <Link
          color={colorFromLevel(expanded ? level : -1)}
          bold
          fontSize={16}
          lHeight={"1rem"}
          justBetween
          onClick={() => handleExpand(expandKey)}
        >
          {children}
          <ExpandIcon expanded={expanded} />
        </Link>
      ) : (
        <Text color={colorFromLevel(expanded ? level : -1)} bold fontSize={16} lHeight={"1rem"} justStart>
          {children}
        </Text>
      )}
    </Row>
  );
};

export function CustomCollapse({ children, isOpened, level, hideSeparator }) {
  const selectedColor = colorFromLevel(level);
  return (
    <Collapse isOpened={isOpened}>
      {hideSeparator ? <>{children}</> : <CollapseSeparator bColor={selectedColor}>{children}</CollapseSeparator>}
    </Collapse>
  );
}
