import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "antd";
import Text from "components/atoms/Text";

const WrapperStep1 = styled.div`
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${(props) =>
    props.step === 1
      ? "none"
      : props.previousStep > props.step || props.previousStep === props.step
      ? "translateX(100%)"
      : "translateX(-100%)"};
  opacity: ${(props) => (props.step === 1 ? 1 : 0)};
  visibility: ${(props) => (props.step === 1 ? "visible" : "hidden")};
  transition: 600ms;
`;

const WrapperStep2 = styled.div`
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${(props) => (props.step === 2 ? "none" : "translateX(100%)")};
  opacity: ${(props) => (props.step === 2 ? 1 : 0)};
  visibility: ${(props) => (props.step === 2 ? "visible" : "hidden")};
  transition: 600ms;
`;

const DatabaseEmailVerification = ({
  title,
  subtitle,
  step,
  previousStep,
  setEmail,
  setCanSendEmail,
  group,
  groupObj,
  type,
}) => {
  const [validEmail, setValidEmail] = useState(false);
  const [validQuinnipiacDomain, setValidQuinnipiacDomain] = useState(false);
  const [isInDatabase, setIsInDatabase] = useState(false);

  if (validEmail && isInDatabase) {
    if (type === "currentEmployee" && group === "quinnipiac" && !validQuinnipiacDomain) {
      setCanSendEmail(false);
    } else {
      setCanSendEmail(true);
    }
  } else {
    setCanSendEmail(false);
  }

  const validateEmail = (email) => {
    const emailRegex =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    setValidEmail(emailRegex.test(email));
    if (
      (type === "currentEmployee" &&
        (groupObj.currentEmployeeMap?.[email] ||
          groupObj.currentEmployeeMap?.[email.replace("qu.edu", "quinnipiac.edu")])) ||
      (type === "jobOffer" &&
        (groupObj.jobOfferMap?.[email] || groupObj.jobOfferMap?.[email.replace("qu.edu", "quinnipiac.edu")]))
    ) {
      setIsInDatabase(true);
    } else {
      setIsInDatabase(false);
    }

    if (group === "quinnipiac") {
      const quinnipiacRegex =
        /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@quinnipiac\.edu/;
      const quRegex =
        /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@qu\.edu/;
      setValidQuinnipiacDomain(quinnipiacRegex.test(email) || quRegex.test(email));
    }
  };

  const Wrapper = type === "currentEmployee" ? WrapperStep1 : WrapperStep2;

  return (
    <div style={{ position: "relative" }}>
      <Wrapper step={step} previousStep={previousStep}>
        <Text sectionTitle left style={{ marginBottom: 0 }}>
          {title}
        </Text>
        <Text italic left tiny style={{ marginTop: "10px" }}>
          {subtitle}
        </Text>
        <Input
          type="email"
          style={{ width: "100%", maxWidth: "500px" }}
          placeholder={type === "jobOffer" ? "Enter email" : "Enter work email"}
          onChange={(e) => {
            const email = e.target.value?.toLowerCase();
            setEmail(email);
            validateEmail(email);
          }}
        />
        {validEmail && type === "currentEmployee" && group === "quinnipiac" && !validQuinnipiacDomain && (
          <Text tiny left red>
            Please enter the version of your email address in the format FirstName.LastName@quinnipiac.edu. If you do
            not have a valid email address in this format, please email{" "}
            <a href={"mailto:info@predictabill.com"}>info@predictabill.com</a>.
          </Text>
        )}
        {type === "jobOffer" && validEmail && !isInDatabase && (
          <Text tiny left red>
            That email address is not in our database as a valid job offer with this employer. Please contact your
            recruiter to have your email address added.
          </Text>
        )}
      </Wrapper>
    </div>
  );
};

export default DatabaseEmailVerification;
