import React, { useEffect, useState } from "react";
import ReactJoyride, { STATUS, ACTIONS } from "react-joyride";

export default function Tour({
  run = true,
  continuous = true,
  showProgress = true,
  disableOverlayClose = true,
  steps,
  /**
   * A function that receives the index and target of the current step
   * @param {Object} data
   * @param {Number} data.index
   * @param {String} data.target
   * @returns {void}
   * @example
   * <Tour onStepChange={({ index, target }) => console.log(index, target)} />
   *
   */
  onStepChange,
  onFinish,
}) {
  const [hasRun, sethasRun] = useState(false);
  const [firstID, setfirstID] = useState(null);

  useEffect(() => {
    if (!steps?.length) return;

    const newFirstID = steps?.[0]?.target || "";
    const newHasRun = localStorage.getItem(firstID);
    setfirstID(newFirstID);
    sethasRun(Boolean(newHasRun));
  }, [steps]);

  if (hasRun) {
    return <></>;
  }

  return (
    <>
      <ReactJoyride
        run={run}
        callback={(data) => {
          if ([STATUS.FINISHED, STATUS.SKIPPED].includes(data.status)) {
            localStorage.setItem(firstID, "true");
            if (onFinish) onFinish();
          }

          if (onStepChange && data.status === STATUS.RUNNING && data.action === ACTIONS.UPDATE) {
            onStepChange({ index: data?.index, target: data?.step?.target });
          }
        }}
        steps={steps}
        continuous={continuous}
        showProgress={showProgress}
        disableOverlayClose={disableOverlayClose}
        styles={{
          options: {
            arrowColor: "#fff",
            backgroundColor: "#21355B",
            textColor: "#fff",
          },
          tooltipContainer: {
            textAlign: "center",
          },
          tooltipTitle: {
            paddingTop: "20px",
          },
          buttonNext: {
            backgroundColor: "#1890ff",
          },
          buttonBack: {
            marginRight: 10,
          },
        }}
      />
    </>
  );
}
