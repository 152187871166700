export const formatValues = (values, gridId) => {
  const { networksTable, providersList } = values;
  const networks = networksTable.map((network) => {
    delete network.idx;
    delete network.tableName;
    delete network.isSuggested;
    return network;
  });

  const providersGrid = providersList.map((provider) => {
    const newProvider = JSON.parse(JSON.stringify(provider)); // simple enough deep copy
    delete newProvider.idx;
    delete newProvider.tableName;
    return newProvider;
  });

  const fixedProvidersList = providersList.map((provider) => {
    const newProvider = JSON.parse(JSON.stringify(provider)); // simple enough deep copy
    delete newProvider.networks;
    return newProvider;
  });

  return {
    _id: gridId,
    networks,
    providersList: fixedProvidersList,
    providersGrid,
  };
};
