export const roomTypes = {
  Shared: "shared",
  Private: "private",
  Deluxe: "deluxe"
};

export const insuranceType = {
  Family: "family",
  Individual: "individual"
};

export const planTypes = {
  private: "private-plans",
  public: "public-plans"
}