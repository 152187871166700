import { HSA_RULES, LABELS, NETWORKS } from "utils/constants";

export const createTable = (size) => {
  const originData = [];
  for (let i = 1; i <= size; i++) {
    originData.push({
      idx: i.toString(),
      hasoutOfNetworkIndividualOOP: true,
      hasoutOfNetworkFamilyOOP: true,
    });
  }

  return originData;
};

export const editTable = (currentTable, size) => {
  for (let i = currentTable.length + 1; i <= size; i++) {
    currentTable.push({
      idx: i.toString(),
      hasoutOfNetworkIndividualOOP: true,
      hasoutOfNetworkFamilyOOP: true,
    });
  }

  window.sessionStorage.setItem("costData", JSON.stringify(currentTable));

  return currentTable;
};

export const createFirstRow = (values, data) => {
  const { premiumTier, costFor, deductibles } = values;

  const inNetworkIndividual = deductibles[0]?.inNetwork;
  const inNetworkFamily = deductibles[1]?.inNetwork;
  const outOfNetworkIndividual = deductibles[0]?.outOfNetwork;
  const outOfNetworkFamily = deductibles[1]?.outOfNetwork;

  const row = {
    ...data[0],
    idx: "1",
    inNetworkIndividual: data[0]?.inNetworkIndividual || inNetworkIndividual,
    inNetworkFamily: data[0]?.inNetworkFamily || inNetworkFamily,
    outOfNetworkIndividual: data[0]?.outOfNetworkIndividual || outOfNetworkIndividual,
    outOfNetworkFamily: data[0]?.outOfNetworkFamily || outOfNetworkFamily,
    hasoutOfNetworkIndividualOOP: true,
    hasoutOfNetworkFamilyOOP: true,
  };

  if (!row.tiers || !row.tiers["1"]) {
    row.tiers = {
      1: {},
    };
  }

  const premiumValue = premiumTier;
  row.tiers["1"][costFor] = row.tiers["1"][costFor] || premiumValue;
  data[0] = row;
};

export const TableRows = (table, size, values) => {
  const length = table.length || 0;
  for (let i = length + 1; i <= size; i++) {
    table = [
      ...table,
      {
        idx: i.toString(),
        hasoutOfNetworkIndividualOOP: true,
        hasoutOfNetworkFamilyOOP: true,
        tiers: {},
      },
    ];
  }

  createFirstRow(values, table);
  return table;
};

export const handleTableChange = (index, field, value) => {
  const costData = JSON.parse(window.sessionStorage.getItem("costData"));
  costData[index][field] = value;
  window.sessionStorage.setItem("costData", JSON.stringify(costData));
};

export const duplicateRow = (selectedIndex, table) => {
  const newTable = table.flatMap((item) => {
    let idx = Number(item.idx);
    if (idx >= selectedIndex) {
      idx = idx + 1;
      if (item.idx == selectedIndex)
        return [
          item,
          {
            ...table[selectedIndex - 1],
            idx: idx.toString(),
            label: table[selectedIndex - 1].label + " (copy)",
          },
        ];
    }
    return { ...item, idx: idx.toString() };
  });
  return newTable;
};

export const deleteRow = (index, table) => {
  let newTable = table
    .filter(({ idx }) => idx !== index)
    .map((item, i) => {
      return { ...item, idx: i + 1 };
    });

  return newTable;
};

const getUniqueValuesFromStep = (step) => {
  if (step === 3) {
    return [
      "inNetworkIndividual",
      "inNetworkFamily",
      "outOfNetworkIndividual",
      "outOfNetworkFamily",
      "outOfNetworkAndInNetworkCrossAccumulate",
      "familyType",
    ];
  }
  if (step === 4) {
    return [
      "inNetworkIndividualOOP",
      "inNetworkFamilyOOP",
      "outOfNetworkIndividualOOP",
      "outOfNetworkFamilyOOP",
      "outOfNetworkAndInNetworkCrossAccumulate",
      "familyOOPType",
    ];
  }
  if (step === 5) {
    return [
      "isHSACompatible",
      "enrollHealthCareFSA",
      "isHsaOrHraCompatible",
      "hsaOrHra",
      "individualHSA",
      "familyHSA",
      "largeFamilyHSA",
      "individualHRA",
      "familyHRA",
      "largeFamilyHRA",
      "depositedHSA",
      "towardHRA",
    ];
  }
  if (step === 6) {
    return [
      "haveToPay",
      "inNetworkCoinsuranceRate",
      "outOfNetworkCoinsuranceRate",
      "amount",
      "unit",
      "maxUnits",
      "inNetworkSubjectToDeductible",
      "services",
    ];
  }
  if (step === 7) {
    return ["label", "insuranceNetwork", "planName"];
  }
  //ADVANCED
  if (step === 8) {
    return ["outOfNetworkAndInNetworkCrossAccumulate", "familyType", "familyOOPType"];
  }
  return [];
};

export const copyFromRowAbove = (idx, table, step) => {
  idx = idx - 1;
  if (idx === 0 || !table || !table[idx]) return;
  const rowToCopyFrom = table[idx - 1];
  const currentRow = table[idx];

  const uniqueValues = getUniqueValuesFromStep(step);
  uniqueValues.forEach((value) => {
    currentRow[value] = rowToCopyFrom[value];
  });

  table[idx] = currentRow;

  return table;
};

export const autoFillOutOfNetworkCoverage = (table, isMarketplacePlans) => {
  const newTable = table.map((item) => {
    const autoFillValue =
      LABELS.some((w) => item?.label?.toUpperCase().includes(w)) ||
      NETWORKS.includes(item?.insuranceNetwork) ||
      isMarketplacePlans
        ? "no"
        : "yes";
    return {
      ...item,
      outOfNetworkCoverage:
        item.label !== item.previousLabel || item.insuranceNetwork !== item.previousInsuranceNetwork
          ? autoFillValue
          : item.outOfNetworkCoverage,
      previousLabel: item.label,
      previousInsuranceNetwork: item.insuranceNetwork,
    };
  });
  return newTable;
};

export const autoFillIsHSACompatible = (table) => {
  const newTable = table.map((item) => {
    const autoFillValue =
      item?.inNetworkIndividual < HSA_RULES.inNetworkIndividual ||
      item?.inNetworkFamily < HSA_RULES.inNetworkFamily ||
      item?.inNetworkIndividualOOP > HSA_RULES.inNetworkIndividualOOP ||
      item?.inNetworkFamilyOOP > HSA_RULES.inNetworkFamilyOOP
        ? "no"
        : "yes";

    return {
      ...item,
      isHSACompatible: autoFillValue,
      isHSACompatibleDisabled: autoFillValue === "no",
    };
  });
  return newTable;
};

export const autoFillHSAMarketplace = (table) => {
  const newTable = table.map((item) => {
    return {
      ...item,
      enrollHealthCareFSA: "no",
      isHsaOrHraCompatible: "no",
    };
  });
  return newTable;
};

export const handleFamilyType = (table) => {
  const newTable = table.map((item) => {
    return {
      ...item,
      familyType: item?.familyType ? item.familyType : item?.hasinNetworkIndividualInFamily && "Aggregated",
      familyOOPType: item?.familyOOPType ? item.familyOOPType : item?.hasinNetworkIndividualInFamilyOOP && "Aggregated",
    };
  });
  return newTable;
};
