export const Fields = {
  roomType: "Private Room",
  deluxe: "Deluxe Room",
  planName: "Search By",
  firstBaby: "First Baby",
  name: "Your Plan",
  group: "Your Insurance",
  networkPlan: "Your Network",
  insurancePlanType: "Plan Your Baby Will Join",
  isDoctorInNetwork: "Doctor In-Network?",
  deliveryType: "Delivery type",
  doctor: "Doctor",
  hospital: "Hospital",
  searchBy: "Search By",
  selectDoctorInNetwork:
    "Select Doctor In-Network after fill out Insurance Plan",
  broaderNetworksMySelf: "Insurance Rates Used to Predict Your Cost",

  isDue: "Do you have a due date yet?",
  dueDate: "Due Date",
  birthParentName: "Birth Parent's name",
  nonBirthParentName: "Non-Birth Parent's name",

  parentsPlan: "Plan(s) Birth Parent and Non-Birth Parent Will Join",
  familyPlan: "Plan(s) Child(ren) Will Join",

  tiers: "Birth Parent Premium Tier",
  hsa: "Birth Parent HSA Tier",
  surcharges: "Birth Parent Plan Surcharges",

  parterName: "Non-Birth Parent Plan",
  partnerGroup: "Non-Birth Parent Insurance",
  partnerNetworkPlan: "Non-Birth Parent Network",
  partnerTiers: "Non-Birth Parent Premium Tier",
  partnerHsa: "Non-Birth Parent HSA Tier",
  partnerSurcharges: "Non-Birth Parent Plan Surcharges"
};
