import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import Text from "components/atoms/Text";
import CheckboxField from "components/molecules/CheckboxField";
import { Space } from "antd";
import { networkOptions, OutOfNetworkCoverageOptions } from "../utils/options";
import TableField from "components/molecules/TableField";
import DateField from "components/molecules/DateField";
import TableMenu from "components/molecules/TableMenu";
import { autoFillOutOfNetworkCoverage } from "../utils/table";
import { useLocation } from "react-router-dom";

const columns = [
  {
    title: "",
    dataIndex: "idx",
    width: 22.5,
    editable: false,
    fixed: "left",
  },
  {
    title: "Label",
    dataIndex: "label",
    width: 110,
    editable: true,
    inputType: "text",
    shouldCellUpdate: () => false,

    fixed: "left",
    disabled: true,
  },
  {
    title: "Insurance Network",
    dataIndex: "insuranceNetwork",
    className: "insuranceNetwork-col",
    width: 200,
    editable: true,
    inputType: "autocomplete",
    dropdownMatchSelectWidth: 350,
    options: networkOptions,
    shouldCellUpdate: () => false,
    disabled: true,
  },
  {
    title: "Out-of-Network Coverage",
    dataIndex: "outOfNetworkCoverage",
    width: 110,
    editable: true,
    inputType: "select",
    dropdownMatchSelectWidth: 200,
    options: OutOfNetworkCoverageOptions,
    shouldCellUpdate: () => false,
  },
  {
    title: "In-Network Individual Deductible",
    dataIndex: "inNetworkIndividual",
    width: 120,
    editable: true,
    inputType: "currency",
    shouldCellUpdate: () => false,
  },
  {
    title: "In-Network Individual-in-Family Deductible",
    dataIndex: "inNetworkIndividualInFamily",
    width: 152,
    editable: true,
    inputType: "currencyAddable",
    emptyValue: "None",
    shouldCellUpdate: (record, prevRecord) =>
      record.hasinNetworkIndividualInFamily !== prevRecord.hasinNetworkIndividualInFamily,
  },
  {
    title: (
      <>
        <p style={{ margin: 0 }}>In-Network Family Deductible</p>
        <CheckboxField name="showIndividualInFamilyDeductibles" label="more" />
      </>
    ),
    dataIndex: "inNetworkFamily",
    width: 120,
    editable: true,
    inputType: "currency",
    shouldCellUpdate: () => false,
  },
  {
    title: "Out-Of-Network Individual Deductible",
    dataIndex: "outOfNetworkIndividual",
    width: 120,
    editable: true,
    inputType: "currency",
    shouldCellUpdate: () => false,
  },
  {
    title: "Out-Of-Network Individual-in-Family Deductible",
    dataIndex: "outOfNetworkIndividualInFamily",
    width: 152,
    editable: true,
    inputType: "currencyAddable",
    emptyValue: "None",
    shouldCellUpdate: (record, prevRecord) =>
      record.hasoutOfNetworkIndividualInFamily !== prevRecord.hasoutOfNetworkIndividualInFamily,
  },
  {
    title: (
      <>
        <p style={{ margin: 0 }}>Out-Of-Network Family Deductible</p>
        <CheckboxField name="showIndividualInFamilyDeductibles" label="more" />
      </>
    ),
    dataIndex: "outOfNetworkFamily",
    width: 120,
    editable: true,
    inputType: "currency",
    shouldCellUpdate: () => false,
  },
  {
    title: "",
    key: "operation",
    fixed: "right",
    width: 62,
    render: (_, record) => <TableMenu step={3} record={record} />,
  },
].map((col) => {
  return {
    ...col,
    onCell: (_, idx) => ({
      index: idx,
      editable: col.editable,
      dataIndex: col.dataIndex,
      title: col.title,
      inputType: col.inputType,
      options: col.options,
      dropdownMatchSelectWidth: col.dropdownMatchSelectWidth,
      disabled: col.disabled || false,
      emptyValue: col.emptyValue,
    }),
  };
});

const filteredColumns = columns.filter((column) => !column?.dataIndex?.includes("IndividualInFamily"));

const Step = () => {
  const { state } = useLocation();
  const { values, setErrors, setTouched, setFieldValue } = useFormikContext();
  const { table, showIndividualInFamilyDeductibles, planOptions, partnerJustForComparison } = values;

  useEffect(() => {
    if (Object.keys(values.waivers).every((key) => values.waivers[key].value === 0))
      setFieldValue("withWaiverPayment", false);
    if (values.spousalSurcharge === 0) setFieldValue("withSpouseSurcharge", false);
    document.getElementById("form-content").scrollTo({ top: 0, behavior: "smooth" });
    setErrors({});
    setTouched({}, false);
  }, []);

  useEffect(() => {
    if (!state?.option) {
      const isMarketplacePlans = partnerJustForComparison === "marketplace";
      const data = autoFillOutOfNetworkCoverage(table, isMarketplacePlans);
      setFieldValue("table", data);
    }
  }, []);

  const data = table.slice(0, planOptions);

  return (
    <>
      <Text sectionTitle>Annual Deductibles</Text>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <DateField name="startDate" label="My deductible starts at $0 (zero) in:" inline size="small" />

        {window.location.pathname.includes("create-plan") && (
          <CheckboxField
            name="enterAdvancedDetails"
            label="I want to enter Advanced details about my deductibles and limits"
          />
        )}

        <TableField
          name="table"
          columns={showIndividualInFamilyDeductibles ? columns : filteredColumns}
          dataSource={data}
          scroll={{ x: showIndividualInFamilyDeductibles ? "80vw" : false }}
          tableLayout="fixed"
        />
      </Space>
    </>
  );
};

export default Step;
