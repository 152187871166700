import React from "react";
import styled from "styled-components";
import { Text, Link } from "components";

const Wrapper = styled.div`
  padding: 1rem;
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Text)`
  font-weight: bold;
  margin-bottom: 0.7rem;
  text-align: center;

  @media (max-width: 480px) {
    margin-bottom: 0.2rem;
  }
`;

const LanguageText = styled(Text)`
  color: rgba(0, 0, 0, 0.66);
  font-weight: 600;
`;

const Container = styled.div`
  margin-top: 4rem;
  text-align: center;
`;

const DoctorsFooter = ({ handleClick }) => {
  const text = process.env.REACT_APP_LANGUAGE_LAWYER;

  return (
    <Wrapper>
      <Title green medium>
        Ready to see these bills with your own plan?{" "}
        <Link
          green
          bold
          underline="true"
          onClick={handleClick}
          to="/calculator"
        >
          Let's go >
        </Link>{" "}
      </Title>
      <Title green medium>
        Looking for more top doctors with insurance plans at leading companies?{" "}
        <Link
          green
          bold
          underline="true"
          onClick={handleClick}
          to="/calculator"
        >
          Use our calculator >
        </Link>{" "}
      </Title>
      <Container>
        <LanguageText small>{text}</LanguageText>
      </Container>
    </Wrapper>
  );
};

export default DoctorsFooter;
