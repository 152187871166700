import model from "./model";
const {
  formField: { networksTable, providersList, providersGrid },
} = model;

export default {
  [networksTable.name]: [{ idx: 1, name: "", url: "", row2: "", extraInstructions: "", tableName: "networksTable" }],
  [providersList.name]: [
    {
      idx: 1,
      name: "Hospital #1",
      type: "Hospital",
      tableName: "providersList",
    },
    {
      idx: 2,
      name: "Hospital #2",
      type: "Hospital",
      tableName: "providersList",
    },
    {
      idx: 3,
      name: "Hospital #3",
      type: "Hospital",
      tableName: "providersList",
    },
  ],
  [providersGrid.name]: [],
};
