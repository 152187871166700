import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Icon, Link } from "components";

const fontSize = ({ height }) => (height ? `${height / 3 / 16}rem` : "0.75em");

const margin = ({ hasText, right }) => {
  if (hasText) {
    return right ? "0 0 0 0.25em" : "0 0.25em 0 0";
  }
  return 0;
};

const StyledIcon = styled(Icon)`
  font-size: ${fontSize};
  margin: ${margin};
`;

const IconLink = ({ height, logo, twitter, ...props }) => {
  const iconElement = <StyledIcon height={height} logo={logo} twitter={twitter} {...props} />;
  return <Link {...props}>{iconElement}</Link>;
};

IconLink.propTypes = {
  icon: PropTypes.string,
  height: PropTypes.number,
  palette: PropTypes.string,
  reverse: PropTypes.bool,
  responsive: PropTypes.bool,
  right: PropTypes.bool,
  children: PropTypes.node,
};

export default IconLink;
