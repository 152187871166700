import React, { useEffect, useMemo, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  PageTemplate,
  AuthenticationFormBlock,
  AuthenticationFormSection,
  Link,
  newHeader as Header,
} from "components";
import { PageView } from "services/google";
import Actions from "store/auth/actions";

const IconWrapper = styled.div`
  background-color: #d9f3f1;
  height: 100vh;

  @media (max-width: 1100px) {
    display: none;
  }
`;

const AuthPage = (props) => {  
  useEffect(() => {
    PageView();
  })

  const dispatch = useDispatch()

  const [formState, setFormState] = useState({
    email: '',
    password: '',
  })

  const [authError, setAuthError] = useState('')

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormState({...formState, [name]: value})
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const { email, password } = formState;

    if (!email || !password) {
      setAuthError("There are one or more missing fields " );
    } else {
      setAuthError("")
      dispatch(Actions.Creators.loginRequest(email, password, destinationRoute));
    }
  }

  const destinationRoute = useSelector(state => state.router.location.destinationRoute);
  const error = useSelector(state => state.auth.error);

  const formInputs = useMemo(() => {
    return [
      {
        name:"email",
        type:"text",
        placeholder:"Enter email address",
        title:"Email Address",
      },
      {
        name:"password",
        type:"password",
        placeholder:"Enter password",
        title:"Password",
      },
    ]
  })
  
  return (
    <PageTemplate
      newHeader
      header={<Header/>}
    >
      <AuthenticationFormSection 
        title="Log In."
        linkText="Don't have an account yet?"
        linkContent="Sign up now "
        linkDestiny="/groups"
        destinationRoute={destinationRoute}
      >
        <AuthenticationFormBlock
          submitButtonText="Sign in"
          inputs={formInputs}
          error={error ? error : authError}
          handleFormChange={handleFormChange}
          handleSubmit={handleSubmit}
          destinationRoute={destinationRoute}
          optionsComponent={
            <Link
              noMargin
              style={{alignSelf: "flex-start"}}
              color="#3F598A"
              circular
              fontSize="16"
              to={{
                pathname: "/forgot-password",
                destinationRoute,
              }}
              > Forgot password?
            </Link>
          }/>
      </AuthenticationFormSection>
    </PageTemplate>
  );
}

export default AuthPage;
