import joi from "joi";

export { default as formSendSchema } from "./form-send.validation";
export { default as summarySchema } from "./summary.validation";
export { default as queryTableBills } from "./query-table-bills.validation";
export { default as premiumFormSendSchema } from "./premiumForm-send.validation";
export { default as customOptionsFormSendSchema } from "./customOptionsForm-send.validation";
export { default as customPlanFormSendSchema } from "./customPlanForm-send.validation";
export { default as customPlanCompleteFormSendSchema } from "./customPlanFormComplete-send.validation";

export const validation = (schema, object) => {
  const { error } = joi.validate(object, schema);
  if (error) return false;
  return true;
};
