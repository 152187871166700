import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import Text from "components/atoms/Text";
import { Space } from "antd";
import SelectField from "components/molecules/SelectField";
import CheckboxField from "components/molecules/CheckboxField";
import FormField from "components/molecules/FormField";
import { copayOptions, networkOptions, yesOrNoOptions, haveToPayOptions } from "../utils/options";
import TableField from "components/molecules/TableField";
import TableMenu from "components/molecules/TableMenu";
import { formatterNumber, parserNumber } from "utils/currency";
import { isCreatingPlan } from "../utils/helpers";

const columns = (values, setFieldValue) => {
  const forceUpdate = values.forceUpdate;
  const currentService = values.currentService;
  const mappedColumns = [
    {
      title: "",
      dataIndex: "idx",
      width: 25,
      fixed: "left",
      editable: false,
    },
    {
      title: "Label",
      dataIndex: "label",
      width: 130,
      editable: true,
      inputType: "text",
      fixed: "left",
      shouldCellUpdate: () => false,
      disabled: true,
    },
    {
      title: "Insurance Network",
      dataIndex: "insuranceNetwork",
      className: "insuranceNetwork-col",
      width: 200,
      editable: true,
      inputType: "autocomplete",
      dropdownMatchSelectWidth: 350,
      options: networkOptions,
      shouldCellUpdate: () => false,
      disabled: true,
    },
    {
      title: "",
      dataIndex: "coverage",
      width: 110,
      inputType: "coverage",
      twice: true,
      editable: true,
    },
    {
      title: "You Have to Pay",
      dataIndex: "haveToPay",
      width: 140,
      dropdownMatchSelectWidth: 300,
      inputType: "select2xReadOnly",
      editable: true,
      twice: true,
      disabledValue: "coinsurance",
      options: haveToPayOptions,
      shouldCellUpdate: (record, prevRecord) =>
        forceUpdate ||
        record.services?.[currentService]?.haveToPay !== prevRecord.services?.[currentService]?.haveToPay,
    },
    {
      title: "Coinsurance Rate",
      dataIndex: "CoinsuranceRate",
      width: 115,
      editable: true,
      inputType: "rate2x",
      twice: true,
      disabled: true,
      shouldCellUpdate: (record, prevRecord) =>
        forceUpdate ||
        record.services?.[currentService]?.outOfNetworkCoverage !==
          prevRecord.services?.[currentService]?.outOfNetworkCoverage ||
        record.services?.[currentService]?.haveToPay !== prevRecord.services?.[currentService]?.haveToPay,
    },
    {
      title: `How much is the copay for ${currentService}?`,
      dataIndex: ["amount", "unit", "maxUnits"],
      width: 375,
      editable: true,
      inputType: "copay",
      options: copayOptions,
      shouldCellUpdate: (record, prevRecord) =>
        forceUpdate ||
        record.services?.[currentService]?.unit !== prevRecord.services?.[currentService]?.unit ||
        record.services?.[currentService]?.haveToPay !== prevRecord.services?.[currentService]?.haveToPay,
    },
    {
      title: "Subject to Deductible",
      dataIndex: "inNetworkSubjectToDeductible",
      width: 105,
      inputType: "select2xReadOnly",
      editable: true,
      twice: true,
      disabledValue: "yes",
      options: yesOrNoOptions,
      shouldUpdateCell: (record, prevRecord) =>
        forceUpdate ||
        record.services?.[currentService]?.deductibles !== prevRecord.services?.[currentService]?.deductibles,
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 48,
      render: (_, record) => <TableMenu step={6} record={record} />,
    },
  ].map((col) => {
    return {
      ...col,
      onCell: (_, idx) => ({
        index: idx,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: col.inputType,
        options: col.options,
        dropdownMatchSelectWidth: col.dropdownMatchSelectWidth,
        twice: col.twice,
        disabled: col.disabled || false,
        disableCondition: col.disableCondition,
        disabledValue: col.disabledValue,
        serviceOption: currentService,
      }),
    };
  });
  if (forceUpdate) setFieldValue("forceUpdate", false);
  return mappedColumns;
};

const filterColumns = (values, table, columns) => {
  const currentService = values.currentService;
  if (
    table &&
    !table.some(
      (element) =>
        element.services?.[currentService]?.haveToPay != undefined &&
        element.services?.[currentService]?.haveToPay !== "coinsurance",
    )
  )
    columns = columns.filter((element) => !element.title.match("How much is the copay for"));
  if (values && values.subjectToDeductible) {
    columns = columns.filter((element) => element.title !== "Subject to Deductible");
  }
  return columns;
};

const shouldScroll = (values, filteredColumns) => {
  return filteredColumns.some((element) => element.title.match("How much is the copay for")) &&
    values &&
    !values.subjectToDeductible
    ? "80vw"
    : false;
};

const Step = () => {
  const { values, setErrors, setTouched, setFieldValue } = useFormikContext();
  const { table, planOptions, serviceList, currentService } = values;
  const defaultCurrentService =
    serviceList && serviceList.length > 1 && serviceList[0].name === "Inpatient Hospital Stay"
      ? serviceList[1].name
      : serviceList[0].name;

  useEffect(() => {
    document.getElementById("form-content").scrollTo({ top: 0, behavior: "smooth" });
    setErrors({});
    setTouched({}, false);
    if (values && !values.currentService) setFieldValue("currentService", defaultCurrentService);
  }, []);

  useEffect(() => {
    setFieldValue("forceUpdate", true);
    values.forceUpdate = true;
  }, [currentService]);
  const data = table.slice(0, planOptions);

  const filteredColumns = filterColumns(values, data, columns(values, setFieldValue));

  return (
    <>
      {isCreatingPlan() ? (
        <Text sectionTitle>What happens if you get in a car accident?</Text>
      ) : (
        <Text sectionTitle>How are these visits, drugs, and services covered?</Text>
      )}

      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        {isCreatingPlan() && (
          <Text formLabel>
            Look at your coverage for an Inpatient Hospital Stay. This might also be called Inpatient Care or Inpatient
            Admission if you don&apos;t see Inpatient Hospital Stay anywhere in your documents.
          </Text>
        )}

        <SelectField
          name="currentService"
          options={serviceList}
          label="Coverage for"
          defaultValue={defaultCurrentService}
          inline
        />

        {values.currentService === "Fertility Services (IVF, Egg Freezing)" && (
          <>
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Text small noWrap>
                {values.group} covers fertility services for a specific
              </Text>
              <SelectField
                name="FSType"
                options={[
                  { name: "$ amount", value: "amount" },
                  { name: "number of cycles", value: "units" },
                ]}
                style={{
                  margin: "5px",
                  maxWidth: "200px",
                }}
                defaultValue={"amount"}
              />
              <Text small noWrap>
                , for up to
              </Text>
              {values.FSType === "units" ? (
                <div
                  style={{
                    marginLeft: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormField name="FSMaxUnits" size="fullwidth" type="number" absoluteErrorMessage />
                  <Text small noWrap style={{ marginLeft: "5px" }}>
                    cycles
                  </Text>
                </div>
              ) : (
                <div
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  <FormField
                    name="FSMaxAmount"
                    size="fullwidth"
                    style={{
                      minWidth: "140px",
                    }}
                    min={0}
                    defaultValue="$"
                    formatter={formatterNumber}
                    parser={parserNumber}
                    type="number"
                    absoluteErrorMessage
                  />
                </div>
              )}
            </div>
          </>
        )}

        {<CheckboxField name="subjectToDeductible" label="I always have to hit my deductible first" />}

        <TableField
          name="table"
          columns={filteredColumns}
          dataSource={data}
          scroll={{ x: shouldScroll(values, filteredColumns) }}
          tableLayout="fixed"
        />
      </Space>
    </>
  );
};

export default Step;
