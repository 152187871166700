import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-restricted-globals
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_FB_APP_CHECK_DEBUG_TOKEN;
}
initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_FB_APP_CHECK_PUBLIC_KEY),
    isTokenAutoRefreshEnabled: true
});
export const storage = getStorage(app);
