import axios from "axios";

const api = {
  url: process.env.REACT_APP_API_URL,
  suffix: "/api/v1",
  timeout: 5000,
  getBaseUri: function() {
    return new URL(this.suffix, this.url).toString();
  }
};
Object.freeze(api);

axios.defaults.baseURL = api.getBaseUri();
axios.defaults.timeout = api.timeout;

const standardHeaders = {
  headers: {
    "Content-Type": "application/json;charset=utf-8"
  }
};

const setHeadersWithAuth = token => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  return config;
};

const setHeaderContentMultipartFormDataWithAuth = (token, formData) => {
  const config = {
    headers: {
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      Authorization: `Bearer ${token}`
    }
  };
  return config;
}

export default {
  api,
  standardHeaders,
  setHeadersWithAuth,
  setHeaderContentMultipartFormDataWithAuth,
  axios
};
