import {
  getPremiumFrequencyToUnit,
  getHsaFrequencyToUnit
} from "services/utils/getFrequencyFormat";

import { percentToFloat } from "services/utils/getPercentFormat";

export const checkCustomPlanFormData = ({
  id,
  group,
  startDate,
  name,
  maternityCopayDetails,
  inNetwork,
  outOfNetwork,
  deductibles,
  charge,
  hsa,
  useOutOfNetwork
}) => {
  const formDataSend = {
    id,
    group,
    name,
    maternityCopayDetails: {
      subjToCopay: maternityCopayDetails.subjToCopay,
      hospital: maternityCopayDetails.hospital,
      unit:
        maternityCopayDetails.unit === "Per stay"
          ? "stay"
          : maternityCopayDetails.unit === "Per night"
          ? "night"
          : "n/a",
      maxUnits: maternityCopayDetails.maxUnits,
      whoPays:
        maternityCopayDetails.whoPays === "Just the birth parent"
          ? "Parent"
          : maternityCopayDetails.whoPays ===
            "Both the birth parent and the baby"
          ? "Both"
          : "n/a",
      subjToPercentCoins: maternityCopayDetails.subjToPercentCoins
    },
    inNetwork: {
      individual: {
        deductible: deductibles.subjToDeductible
          ? inNetwork.individual.deductible
          : 0,
        oopMax: inNetwork.individual.oopMax
      },
      family: {
        deductible: deductibles.subjToDeductible
          ? inNetwork.family.deductible
          : 0,
        individualDeductible: inNetwork.family.individualDeductible,
        individualOopMax: inNetwork.family.individualOopMax,
        oopMax: inNetwork.family.oopMax
      },
      coinsurance: {
        rate: percentToFloat(inNetwork.coinsurance.rate)
      }
    },
    outOfNetwork: {
      coverage: useOutOfNetwork,
      individual: {
        deductible: deductibles.subjToDeductible
          ? outOfNetwork.individual.deductible
          : 0,
        oopMax: outOfNetwork.individual.oopMax
      },
      family: {
        deductible: deductibles.subjToDeductible
          ? outOfNetwork.family.deductible
          : 0,
        individualDeductible: outOfNetwork.family.individualDeductible,
        individualOopMax: outOfNetwork.family.individualOopMax,
        oopMax: outOfNetwork.family.oopMax
      },
      coinsurance: {
        rate: percentToFloat(outOfNetwork.coinsurance.rate)
      }
    },
    family: {
      deductible: {
        type:
          deductibles.familyType === true
            ? "Combined"
            : deductibles.familyType === false
            ? "Individual"
            : "Combined"
      }
    },
    baby: {
      deductible: {
        isUsed: deductibles.baby.isUsed
      }
    },
    deductibles: {
      isApply: deductibles.subjToDeductible,
      cross: {
        isAccumulatable: deductibles.crossAccumulatable
      }
    },
    startDate,
    premium: {
      isUnique: true,
      frequency: getPremiumFrequencyToUnit(charge.frequency.value),
      tiers: {
        1: {
          mo: charge.mo,
          ms: charge.ms,
          mc: charge.ms,
          mf: charge.mf
        }
      }
    },
    hsa: {
      isApply: hsa.isApply,
      frequency: getHsaFrequencyToUnit(hsa.frequency.value),
      tiers: hsa.isApply
        ? {
            1: {
              individual: hsa.amountIndividual,
              family: hsa.amountFamily
            }
          }
        : {
            empty: ""
          }
    }
  };

  return formDataSend;
};
