import React from "react";
import styled from "styled-components";

import { Text } from "components";

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  align-items: center;
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  width: 100%;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100px;
  margin: 0 10px;
`;

const RightWrapper = styled.div`
  padding: 10px;
`;

const Box = styled.div`
  border-radius: 20px;
  margin: 0 10px;
  overflow: hidden;
`;

const BoxHeader = styled.div`
  background-color: ${(props) => (props.fill ? "#86dbd5" : "none")};
  height: 45px;
  width: 100%;
  padding: 10px 15px;
`;

const BoxBody = styled.div`
  background-color: ${(props) => (props.fill ? "#d9f3f1" : "none")};
  width: 100%;
  padding: 10px 15px;
`;

const Divider = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 25px;
`;

const Dot = styled.div`
  width: 22px;
  height: 22px;
  border: 3px solid #1c355e;
  border-radius: 50%;
  background-color: ${(props) => (props.fill ? "#1c355e" : "none")};
`;

const Line = styled.div`
  border: 2px solid #1c355e;
  min-height: 62px;
`;

const formatDate = (date) => {
  const months = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sept",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  const fields = date.split("/");
  const month = months[fields[0]];
  const day = fields[1];
  const year = fields[2];

  const format = (
    <>
      <Text black small>
        {month}
      </Text>
      <Text black fontSize="22" bold>
        {day}
      </Text>
      <Text black small>
        {year}
      </Text>
    </>
  );

  return format;
};

const Timeline = ({ timeline, ...props }) => {
  const dates = timeline.map((event) => {
    const fields = event.date.split("/");
    return fields[2];
  });

  const startYear = dates[0];
  // const endYear = dates[dates.length - 1];

  return (
    <FlexWrapper col>
      <FlexWrapper justify="flex-start">
        <LeftWrapper>
          <Text black fontSize="22" bold>
            {startYear}
          </Text>
        </LeftWrapper>
        <Divider>
          <Line />
        </Divider>
        <RightWrapper>
          <Text black small>
            predictaBill comparison period starts
          </Text>
        </RightWrapper>
      </FlexWrapper>

      {timeline.map((event, i) => {
        const { date, content, details = [] } = event;
        return (
          <FlexWrapper key={i} justify="flex-start">
            <LeftWrapper>{formatDate(date)}</LeftWrapper>
            <Divider>
              <Line />
              <Dot fill={i === 0 || i === timeline.length - 1} />
              <Line />
            </Divider>
            <RightWrapper>
              <Box>
                <BoxHeader fill={i === 0 || i === timeline.length - 1}>
                  <Text black small bold>
                    {content}
                  </Text>
                </BoxHeader>
                {details.length > 0 && (
                  <BoxBody fill={i === 0 || i === timeline.length - 1}>
                    {details.map((detail, i) => (
                      <Text black small key={i}>
                        {detail}
                      </Text>
                    ))}
                  </BoxBody>
                )}
              </Box>
            </RightWrapper>
          </FlexWrapper>
        );
      })}

      <FlexWrapper justify="flex-start">
        <LeftWrapper>
          <Text></Text>
        </LeftWrapper>
        <Divider>
          <Line />
        </Divider>
        <RightWrapper>
          <Text black small>
            predictaBill comparison period ends
          </Text>
        </RightWrapper>
      </FlexWrapper>
    </FlexWrapper>
  );
};

export default Timeline;
