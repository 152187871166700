import styled from "styled-components";
import { Tabs as ReactTabs } from "antd";

const Tabs = styled(ReactTabs)`
  > .ant-tabs-bar {
    background-color: #1c355e;
    display: flex;
    justify-content: space-evenly;

    > * {
      margin: 0;
    }

    > .ant-tabs-nav-container {
      color: #fff;

      > .ant-tabs-nav-wrap > .ant-tabs-nav-scroll {
        > .ant-tabs-nav .ant-tabs-tab-active {
          color: #86dbd5;
        }

        > .ant-tabs-nav .ant-tabs-tab:hover {
          color: #86dbd5;
        }
      }
    }
  }

  .ant-tabs-ink-bar {
    background-color: #86dbd5;
  }
`;

export default Tabs;
