import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/report/actions";
import { CheckboxGroup } from "../components/CheckboxGroup";

export const InsuranceNetworksFilter = ({ broaderNetworksOptions }) => {
  const dispatch = useDispatch();
  const broaderNetworkIncludes = useSelector((state) => state.report.broaderNetworkIncludes);

  if (!broaderNetworksOptions?.length) return null;

  return (
    <CheckboxGroup
      label="Insurance Networks"
      options={broaderNetworksOptions.map((option) => ({ value: option, label: option }))}
      onChange={(value) => {
        dispatch(
          Actions.Creators.switchState({
            broaderNetworkIncludes: value,
          }),
        );
      }}
      value={broaderNetworkIncludes}
    />
  );
};
