import PropTypes from "prop-types";
import styled from "styled-components";

const Heading = styled.h1`
  font-family: circular;
  font-size: 34px;
  max-height: 85px;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  line-height: 85px;
  color: #00a39e;
  margin: 0;
  margin-top: -16px;

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

Heading.propTypes = {
  level: PropTypes.number,
  children: PropTypes.node
};

Heading.defaultProps = {
  level: 1
};

export default Heading;
