import React, { useState } from "react";

import { FieldArray, useField } from "formik";
import Text from "components/atoms/Text";
import EditableCell from "../EditableCell";
import { Table, Pagination, Button } from "antd";
import "./styles.css";
import { processColumns } from "../TableField";

const TableFieldExternalPagination = ({ name, dataSource, pageSize, columns, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const [page, setPage] = useState(1);

  const pageSizeWithDefaultValue = pageSize ? pageSize : 10;

  if (
    dataSource.slice(
      pageSizeWithDefaultValue * (page - 1),
      pageSizeWithDefaultValue * (page - 1) + pageSizeWithDefaultValue,
    ).length === 0
  )
    setPage(page - 1 || 1);
  return (
    <>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            <Table
              {...props}
              tableLayout="auto"
              components={{
                body: {
                  cell: (props) =>
                    EditableCell({
                      ...props,
                      name: name,
                      table: dataSource,
                      page: page,
                      pageSize: pageSizeWithDefaultValue,
                    }),
                },
              }}
              dataSource={dataSource.slice(
                pageSizeWithDefaultValue * (page - 1),
                pageSizeWithDefaultValue * (page - 1) + pageSizeWithDefaultValue,
              )}
              rowKey={(item) => item.idx}
              columns={processColumns(columns)}
              className="customTable"
              pagination={false}
            />
            <div style={{ margin: "10px 0 0 0", display: "flex", justifyContent: "space-between" }}>
              <Button type="primary" onClick={props.handleAddButton} style={{ fontSize: 20, lineHeight: 0 }}>
                +
              </Button>
              <Pagination
                current={page}
                total={dataSource.length}
                defaultPageSize={pageSizeWithDefaultValue}
                onChange={(pageIndex) => setPage(pageIndex || 1)}
              />
            </div>
          </>
        )}
      />
      {meta?.touched && meta?.error && <Text red>All table fields are required</Text>}
    </>
  );
};

export default TableFieldExternalPagination;
