import React, { useState } from "react";
import styled from "styled-components";
import {
  CalculateHeader as Header,
  FormFooter,
  Text,
  Icon as FormIcon,
} from "components";
import { Icon } from "@ant-design/compatible";
import { merge } from "lodash";

const Wrapper = styled.section`
  display: grid;
  height: 100%;
  place-items: center;
`;

const GridWrapper = styled.div`
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 15px;
`;

const Button = styled.button`
  background-color: rgba(81, 165, 162, 0.15);
  width: 408px;
  height: 100px
  outline: none;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 13% 73% 10%;
  column-gap: 2%
  align-items: center;
  justify-items: start;
  padding: 5px 15px;

  &:hover {
    background: rgba(81, 165, 162, 0.3);
    border: 2px solid rgba(81, 165, 162);
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: ${({ row }) => (row ? "row" : "column")};
  justify-content: center;
  align-items: ${({ center }) => (center ? "center" : "flex-start")};
  min-width: 216px;
  max-width: ${({ small }) => (small ? "300px" : "480px")};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const iconEditStyles = {
  fontSize: "14px",
  color: "rgba(81, 165, 162)",
};

const iconButtonStyles = {
  fontSize: "40px",
  color: "rgba(81, 165, 162)",
};

const questions = {
  details: "Review Your Information",
  subscriptionPlan: "Subscribe to Receive Your Recommendation",
};

const OnboardingReviewSection = ({ _form, handleNext, handleChangeStep }) => {
  const [steps, setSteps] = useState(["details"]);
  const step = steps[steps.length - 1];

  const [agree, setAgree] = useState(false);

  // handleEdit will take the user back to the review section after that step
  const handleEdit = (step) => handleChangeStep(`review-${step}`);

  const handleStep = () => {
    const updatedForm = merge({}, _form, { onboardingStep: "payment" });
    handleNext(updatedForm);
  };

  const handleBack = () => {
    const updatedSteps = steps.slice(0, -1);
    setAgree(false);
    switch (steps.length) {
      case 1:
        handleChangeStep("pamphlet");
        break;
      default:
        setSteps(updatedSteps);
        break;
    }
  };

  const active = step === "details" || agree;

  return (
    <Wrapper>
      <Header bigTitle={true} title={questions[step]} />
      <GridWrapper>
        <Button onClick={() => handleEdit("job")}>
          <Icon type="idcard" style={iconButtonStyles} />
          <TextWrapper>
            <Text select bolder green left>
              Commercial Insurance
            </Text>
            {_form?.considerEmploymentPlan ? (
              <Text select left green style={{ maxWidth: "350px" }}>
                {`New Job Coverage: ${_form.jobDate || "n/a"}`}
              </Text>
            ) : null}
            {_form?.considerCobraPlan ? (
              <Text select left green style={{ maxWidth: "350px" }}>
                {`Left Job: ${_form.cobraDate || "n/a"}`}
              </Text>
            ) : null}
            <Text select left green style={{ maxWidth: "350px" }}>
              {`Access to Job, School, or Union Insurance: ${
                _form?.considerCommercialPlan ? "Yes" : "No"
              }`}
            </Text>
          </TextWrapper>
          <IconWrapper>
            <Icon type="edit" style={iconEditStyles} />
            <Text select green>
              Edit
            </Text>
          </IconWrapper>
        </Button>

        <Button onClick={() => handleEdit("dob")}>
          <Icon type="gift" style={iconButtonStyles} />
          <TextWrapper>
            <Text select bolder green left>
              Age
            </Text>
            {_form?.dob ? (
              <Text select left green style={{ maxWidth: "350px" }}>
                {`Date of Birth: ${_form.dob}`}
              </Text>
            ) : null}
            {_form?.graduationDate ? (
              <Text select left green style={{ maxWidth: "350px" }}>
                {`Graduation Date: ${_form.graduationDate}`}
              </Text>
            ) : null}
            {_form?.under26 ? (
              <Text select left green style={{ maxWidth: "350px" }}>
                {`Consider Parent's Plan: ${_form.parentPlan ? "Yes" : "No"}`}
              </Text>
            ) : null}
          </TextWrapper>
          <IconWrapper>
            <Icon type="edit" style={iconEditStyles} />
            <Text select green>
              Edit
            </Text>
          </IconWrapper>
        </Button>

        {_form?.isSpouse ? (
          <Button onClick={() => handleEdit("spouse")}>
            <FormIcon onboardingWeddingIcon medium />
            <TextWrapper>
              <Text select bolder green left>
                Spouse
              </Text>
              {_form?.gettingMarried ? (
                <Text select left green style={{ maxWidth: "350px" }}>
                  {`Marriage Date: ${_form.marriageDate}`}
                </Text>
              ) : null}
              <Text select left green style={{ maxWidth: "350px" }}>
                {`Consider Spouse's Plan: ${
                  _form?.considerSpousePlan ? "Yes" : "No"
                }`}
              </Text>
              <Text select left green style={{ maxWidth: "350px" }}>
                {`Spouse Requires Insurance: ${
                  _form?.spouseRequireIns ? "Yes" : "No"
                }`}
              </Text>
            </TextWrapper>
            <IconWrapper>
              <Icon type="edit" style={iconEditStyles} />
              <Text select green>
                Edit
              </Text>
            </IconWrapper>
          </Button>
        ) : null}

        {_form.children ||
          (_form.isDue && (
            <Button onClick={() => handleEdit("children")}>
              <Icon type="smile" style={iconButtonStyles} />
              <TextWrapper>
                <Text select bolder green left>
                  Children
                </Text>
                <Text select left green style={{ maxWidth: "350px" }}>
                  {`Number of children: ${
                    _form.children ? _form.children : "None"
                  }`}
                </Text>
                {_form?.childrenRequireIns ? (
                  <Text select left green style={{ maxWidth: "350px" }}>
                    Children Requires Insurance: Yes
                  </Text>
                ) : null}
                {_form?.isDue ? (
                  <Text select left green style={{ maxWidth: "350px" }}>
                    {`Due Date: ${_form.dueDate}`}
                  </Text>
                ) : null}
              </TextWrapper>
              <IconWrapper>
                <Icon type="edit" style={iconEditStyles} />
                <Text select green>
                  Edit
                </Text>
              </IconWrapper>
            </Button>
          ))}

        <Button onClick={() => handleEdit("residence")}>
          <Icon type="home" style={iconButtonStyles} />
          <TextWrapper>
            <Text select bolder green left>
              Residence
            </Text>
            {_form?.moving ? (
              <Text select left green style={{ maxWidth: "350px" }}>
                {`Move Date: ${_form.moveDate}`}
              </Text>
            ) : null}
            <Text select left green style={{ maxWidth: "350px" }}>
              {`Zip Code: ${_form.zipCode}`}
            </Text>
            <Text select left green style={{ maxWidth: "350px" }}>
              {`County Fips: ${_form.countyFips}`}
            </Text>
          </TextWrapper>
          <IconWrapper>
            <Icon type="edit" style={iconEditStyles} />
            <Text select green>
              Edit
            </Text>
          </IconWrapper>
        </Button>
      </GridWrapper>

      <FormFooter
        leftAction={handleBack}
        mainAction={handleStep}
        mainActionActive={active}
      />
    </Wrapper>
  );
};

export default OnboardingReviewSection;
