import React from "react";
import { PageTemplate, Header, AddEmployerQuestions } from "components";

const AddEmployer = () => {
  return (
    <PageTemplate padding="0 12.5%" header={<Header V2 />}>
      <AddEmployerQuestions />
    </PageTemplate>
  )
}

export default AddEmployer;
