import React from "react";
import { connect } from "react-redux";
import planActions from "store/plan/actions";

import { PageTemplate, newHeader as Header, newFooter as Footer, CalculateMain } from "components";
import { PageView } from "services/google";

class CalculatePage extends React.Component {
  componentDidMount() {
    document.title = "Calculator - predictaBill";
    PageView();
    this.props.load();
  }

  render() {
    return (
      <PageTemplate newHeader header={<Header/>} footer={<Footer />}>
        <CalculateMain />
      </PageTemplate>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    load: () => {
      dispatch(planActions.Creators.loadOptionsRequest());
    },
  };
};
export default connect(null, mapDispatchToProps)(CalculatePage);
