import React, { useState } from "react";
import styled from "styled-components";
import {
  Text,
  Table,
  TableRow,
  TableHead,
  TableCell,
  OptimizationCell,
  RowTotalCost,
  Link,
} from "components";
import { Icon } from "@ant-design/compatible";

const FlexWrapper = styled.section`
  display: flex;

  justify-content: center;
  ${({ row }) =>
    row
      ? `
    flex-direction: column;
  `
      : " padding: 0;"}

  flex-wrap: wrap;

  padding: 20px 0;
`;

const Wrapper = styled.div`
  font-family: 15px;
  color: #123;
  padding: 20px;
  padding-top: 0;
`;

const Rotater = styled(Link)`
  display: inline-block;
  margin-left: 5px;
  transition: all 0.5s ease;
  transform: ${(props) => (props.show ? `rotate(0deg)` : `rotate(-90deg)`)};
`;

const OptimizeTable = ({ data, reportType }) => {
  const options = data;
  const [isShowAll, setIsShowAll] = useState(["total"]);

  const handleReveal = (term) => {
    const isShowing = isShowAll.includes(term);
    const showAll = isShowing
      ? isShowAll.filter((show) => show !== term)
      : [...isShowAll, term];

    setIsShowAll(showAll);
  };

  let premiumCharges = { before: [], after: [] },
    insuranceCharges = { before: [], after: [] },
    outOfNetworkCharges = { before: [], after: [] },
    hsaCharges = { before: [], after: [] },
    hraCharges = { before: [], after: [] };
  options.forEach((option) => {
    premiumCharges.before.push(
      option.charges.before.find((charge) => charge.id === "premium")
    );
    premiumCharges.after.push(
      option.charges.after.find((charge) => charge.id === "premium")
    );
    insuranceCharges.before.push(
      option.charges.before.find((charge) => charge.id === "insurance")
    );
    outOfNetworkCharges.before.push(
      option.charges.before.find((charge) => charge.id === "out-of-network")
    );
    hsaCharges.before.push(
      option.charges.before.find((charge) => charge.id === "hsa")
    );
    hsaCharges.after.push(
      option.charges.after.find((charge) => charge.id === "hsa")
    );
    hraCharges.before.push(
      option.charges.before.find((charge) => charge.id === "hra")
    );
    hraCharges.after.push(
      option.charges.after.find((charge) => charge.id === "hra")
    );
  });

  return (
    <>
      <Wrapper>
        <FlexWrapper>
          <Table id="optimization-table">
            <thead>
              <TableRow>
                <TableCell colSpan="4" width="300px" />
                {options.map((option, i) => {
                  if (option.subHeading.length > 1) {
                    option.subHeading[0] = option.subHeading[0] + "\n";
                  }
                  return (
                    <TableHead key={i} center width="200px">
                      <Text fontSize="14">{option.title}</Text>
                      <Text lg bolder>
                        {option.heading}
                      </Text>
                      {option.subHeading.map((words, i) => {
                        return (
                          <Text key={`${i}sH`} fontSize="10">
                            {words}
                          </Text>
                        );
                      })}
                    </TableHead>
                  );
                })}
              </TableRow>
            </thead>
            <tbody>
              {isShowAll.includes("total") && (
                <>
                  <RowTotalCost
                    type="premium"
                    data={premiumCharges}
                    isOther={false}
                  />
                  <RowTotalCost
                    type="insurance"
                    data={insuranceCharges}
                    reportType={reportType}
                    isOther={false}
                  />
                  <RowTotalCost
                    type="out-of-network"
                    data={outOfNetworkCharges}
                    reportType={reportType}
                    isOther={false}
                  />
                  <RowTotalCost type="hsa" data={hsaCharges} isOther={false} />
                  <RowTotalCost type="hra" data={hraCharges} isOther={false} />
                </>
              )}
              <TableRow color="green">
                <TableHead width="300px" colSpan="4" grandTotal="left">
                  <Text fontSize="20">
                    <Rotater
                      margin="0 10px"
                      show={isShowAll.includes("total")}
                      onClick={() => handleReveal("total")}
                    >
                      <Icon type="down" />
                    </Rotater>
                    Estimated Grand Total
                  </Text>
                </TableHead>
                {options.map((option, i) => {
                  return (
                    <OptimizationCell
                      key={`${i}-grandTotal`}
                      type="grandTotal"
                      data={option}
                      grandTotal={i === options.length - 1 ? true : false}
                    />
                  );
                })}
              </TableRow>
              <RowTotalCost type="hsa" data={hsaCharges} isOther={true} />
              <RowTotalCost type="hra" data={hraCharges} isOther={true} />
            </tbody>
          </Table>
        </FlexWrapper>
      </Wrapper>
    </>
  );
};

export default OptimizeTable;
