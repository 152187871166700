import React, { useState } from "react";
import styled from "styled-components";
import {
  AntModalView as ModalView,
  Text,
  FormInput as Input,
  FormUpload as Upload,
  FormSelectV2 as Select,
  FormText,
} from "components";
import useModal from "services/hooks/useModal.hook";

const Wrapper = styled.section`
  // background-color: rgba(81, 165, 162, 0.15);
  border-radius: 15px;
  padding: 20px;
  width: 336px;
  min-height: 400px;
`;

const BoxWrapper = styled.div``;

const UploadPamphlets = ({ parent, setParent, companySelections }) => {
  const { parentName, nameSelections, company } = parent;
  const [type, setType] = useState(null);

  const { handleClick, isShow } = useModal();

  const handleChange = ({ target: { name, value } }) => {
    // reset pamphlets array when parent name/company changes
    setParent({ ...parent, pamphlets: [] });

    if (name === "parentName") {
      setParent({ ...parent, parentName: value });
    } else {
      switch (value) {
        case "Mine isn't one of these":
          setParent({ ...parent, company: null });
          setType("please enter company name");
          break;
        case "Insurance is through a school or union":
          setParent({ ...parent, company: null });
          setType("please enter school or union name");
          break;
        default:
          setParent({ ...parent, company: value });
          setType(null);
          break;
      }
    }
  };

  const handleChangeInput = ({ target: { name, value } }) => {
    setParent({ ...parent, company: value });
  };

  const handleUpload = (name, fileList) => {
    setParent({ ...parent, pamphlets: fileList });
  };

  return (
    <>
      <ModalView
        close={handleClick}
        isShow={isShow}
        isInfo
        title="Why do we need to know this?"
      >
        <Text modal arial>
          Different companies, unions, or schools can purchase very different
          insurance plans for their employees, members, and students. So even if
          you have a United plan, JP Morgan employees could have a different
          United plan from Goldman Sachs employees. We want to get you the most
          accurate calculations, which is why we are asking!
        </Text>
      </ModalView>
      <Wrapper>
        <BoxWrapper>
          <Text select>Who are you uploading pamphlets for?</Text>
          <Select
            selection={nameSelections}
            name="parentName"
            isSearch
            handleChange={handleChange}
            value={parentName}
          />
        </BoxWrapper>
        <br></br>
        <BoxWrapper>
          <Text select>Select Company Name</Text>

          <Select
            selection={companySelections}
            name="company"
            isSearch
            handleChange={handleChange}
            value={
              type === "please enter company name"
                ? "Mine isn't one of these"
                : type === "please enter school or union name"
                ? "Insurance is through a school or union"
                : company
            }
          />
        </BoxWrapper>

        {!company && !type && (
          <FormText
            small="true"
            text='Select "Marketplace" if you do not have a company, school or union'
          />
        )}

        {type && (
          <Input
            placeholder={type}
            name="company"
            handleChange={handleChangeInput}
          />
        )}
        <br></br>

        {company && (
          <BoxWrapper>
            {process.env.REACT_APP_COMPLETE_COMPANIES.includes(company) ? (
              <Text select>
                Easy! We already have their insurance plans in our systems
              </Text>
            ) : (
              <>
                <FormText
                  text="Upload any pamphlets you've received for your enrollment period including where it shows what you have to pay for each option (you can take a photo with your phone if you only have a paper version)."
                  small="true"
                />

                <Upload width={300} handleChange={handleUpload} />
              </>
            )}
          </BoxWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default UploadPamphlets;
