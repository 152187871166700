import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Text from "components/atoms/Text";
import { useDispatch } from "react-redux";
import Actions from "store/plan/actions";
import { CREATE_PLAN_START, CREATE_PLAN_PREMIUMS } from "utils/constants"

const FooterWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
`

const Button = styled.div`
  background-color: ${(props) => props.disabled ? "#8e9fbd" : "#1c355e"}
  border-radius: 15px;
  border: none;
  outline: none;
  padding: 4px 30px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  justify-self: center;
  cursor: ${(props) => props.disabled ? "not-allowed" : "pointer"}};
  transition: 0.1s;
  &:hover {
    background-color: ${(props) => props.disabled ? "#8e9fbd" : "#2e528c"}};
  }
  @media (max-width: 900px){
    width: 150px;
    font-size: 16px;
  }
`;

const AddEmployerFooter = ({ option, reportId, partner }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <FooterWrapper>
      <Button
        onClick={() => {
          history.push("/groups")
        }}
      >
        <Text white center>
          {"< Back"}
        </Text>
      </Button>
      <Button
        onClick={() => {
          if (option === "upload") {
            history.push('/typeform/new-recommendation', {
              url: "https://predictabill.typeform.com/to/PmhrqHUu?typeform-welcome=0&u=1&dontredirect=1",
              addEmployer: true,
            })
          } else if (option === "create") {
            dispatch(Actions.Creators.changeCurrent(partner ? CREATE_PLAN_PREMIUMS : CREATE_PLAN_START));
            localStorage.removeItem("form")
            history.push("/create-plan", {
              reportId: reportId,
              option: partner,
            })
          }
        }}
      >
        <Text white center>
          {`Next >`}
        </Text>
      </Button>
    </FooterWrapper >
  )
};

export default AddEmployerFooter;
