import React from "react";

import styled from "styled-components";

const StyledSpan = styled.span`
  ${({ error }) => error && `color: #DC4C2D`};
`;

const RequiredField = ({ error }) => {
  return <StyledSpan error={error}> *</StyledSpan>;
};

export default RequiredField;
