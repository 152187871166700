import React, { useState } from "react";
import styled from "styled-components";
import {
  CalculateHeader as Header,
  Text,
  ButtonLink,
  FormDateV2 as Date,
  FormSelect as Select,
} from "components";
import { message } from "antd";

const Wrapper = styled.section``;

const FlexWrapper = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BoxWrapper = styled.div`
  border: 3px solid #00a19b;
  border-radius: 25px;
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 50px;
  padding-bottom: 20px;
  width: 400px;
  min-height: 180px;
  outline: none;
  color: #00a19b;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: left;
  background: #fff;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const OnboardingSpouseSection = ({ gettingMarried, form, onChange }) => {
  const [date, setDate] = useState(form.marriageDate);
  const [isSpouse, setIsSpouse] = useState(form.isSpouse);
  const [considerSpousePlan, setConsiderSpousePlan] = useState(
    form.considerSpousePlan
  );
  const [spouseRequireIns, setSpouseRequireIns] = useState(
    form.spouseRequireInsurance
  );

  const handleChange = ({ target: { name, value } }) => {
    switch (name) {
      case "date":
        if (value !== "n/a" && !validateDate(value)) {
          message.error("Moving date is invalid");
        } else {
          setDate(value);
        }
        break;
      case "isSpouse":
        setIsSpouse(strToBool(value));
        break;
      case "considerSpousePlan":
        setConsiderSpousePlan(strToBool(value));
        break;
      case "spouseRequireIns":
        setSpouseRequireIns(strToBool(value));
        break;
      default:
        break;
    }
  };

  const onSubmit = () => {};

  return (
    <Wrapper>
      <Header bigTitle={true} title="Location" />

      <FlexWrapper>
        <BoxWrapper>
          {gettingMarried ? (
            <ItemWrapper>
              <Text black small>
                Marriage Date:
              </Text>
              <Date
                name="date"
                value={date}
                allowSkip
                handleChange={handleChange}
              />
            </ItemWrapper>
          ) : (
            <ItemWrapper>
              <Text black small>
                Are you married?
              </Text>
              <Select
                placeholder="Select an option"
                isBool
                name="isSpouse"
                value={isSpouse}
                selection={["No", "Yes"]}
                handleChange={handleChange}
              />
            </ItemWrapper>
          )}

          {gettingMarried || isSpouse ? (
            <>
              <ItemWrapper>
                <Text black small>
                  Would you consider your spouse's plan?
                </Text>
                <Select
                  placeholder="Select an option"
                  isBool
                  name="considerSpousePlan"
                  value={considerSpousePlan}
                  selection={["No", "Yes"]}
                  handleChange={handleChange}
                />
              </ItemWrapper>

              {!considerSpousePlan && (
                <>
                  <ItemWrapper>
                    <Text black small>
                      Would you like the insurance to cover you and your spouse?
                    </Text>
                    <Select
                      placeholder="Select an option"
                      isBool
                      name="spouseRequireIns"
                      value={spouseRequireIns}
                      selection={[
                        "No, it's just for me",
                        "Yes, we both need insurance",
                      ]}
                      handleChange={handleChange}
                    />
                  </ItemWrapper>
                </>
              )}
            </>
          ) : null}
        </BoxWrapper>
        <br></br>
        <ButtonLink withBG button fontSize="18" onClick={onSubmit}>
          Continue
        </ButtonLink>
      </FlexWrapper>
    </Wrapper>
  );
};

const validateDate = (input) => {
  if (!input) {
    return false;
  }

  const dateArray = input.split("/");
  const day = dateArray[1];
  const year = dateArray[2];
  if (parseInt(day) === 0) {
    return false;
  } else if (year.length !== 4 || parseInt(year) === 0) {
    return false;
  } else {
    return true;
  }
};

const strToBool = (str) => {
  switch (str) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return str;
  }
};

export default OnboardingSpouseSection;
