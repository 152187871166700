import TableBillsContainer from "./TableBills.container";
import BirthPlanContainer from "./CalculateBirthPlan.container";
import InsurancePlanContainer from "./CalculateInsurancePlan.container";
import InsuranceBroaderContainer from "./CalculateInsuranceBroader.container";
import InsurancePrice from "./CalculatePriceForm.container";
import PremiumPlanContainer from "./CalculatePremiumPlan.container";
import InsurancePremium from "./CalculatePremiumPriceForm.container";
import CustomOptionsContainer from "./CustomOptions.container";
import CustomPlansContainer from "./CustomPlans.container";

export const TableBills = TableBillsContainer,
  BirthPlan = BirthPlanContainer,
  InsurancePlan = InsurancePlanContainer,
  PredictedTotal = InsurancePrice,
  InsuranceBroader = InsuranceBroaderContainer,
  PremiumPlan = PremiumPlanContainer,
  PremiumPredictedTotal = InsurancePremium,
  CustomOptions = CustomOptionsContainer,
  CustomPlans = CustomPlansContainer;
