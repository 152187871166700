export const copyFromRowAbove = (idx, table) => {
  idx = idx - 1;
  if (idx === 0 || !table || !table[idx]) return;
  const rowToCopyFrom = table[idx - 1];
  const currentRow = table[idx];

  const uniqueValues = ["label", "expectedOrUnexpected", "billedAs", "for", "type", "frequency", "price", "unit", "tableName"]
  uniqueValues.forEach((value) => {
    currentRow[value] = rowToCopyFrom[value];
  });

  table[idx] = currentRow;

  return table;
};

export const resetFrequency = (idx, table) => {
  idx = idx - 1
  if (!table || !table[idx]) return;
  table[idx].frequency = "";

  return table;
}
