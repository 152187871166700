export const roomTypes = [
  { value: "false", name: "Shared room" },
  { value: "true", name: "Private room" }
];

export const plansBaby = [
  {
    name: "Joining plan I'm on with my spouse",
    value: "family",
    isFirst: false
  },
  {
    name: "Joining my individual plan",
    value: "individual",
    isFirst: true
  },
  {
    name: "Joining plan I'm on with my spouse",
    value: "family",
    isFirst: true
  }
];

export const deliveryTypes = [
  "All Deliveries",
  "Vaginal Delivery",
  "C-Section",
  "VBAC"
];

export const broaderAllNetworks = {
  _id: "All Networks",
  broaderNetwork: "All Networks"
};
