import React from "react";
import { Spin } from "antd";
import { Icon } from "@ant-design/compatible";
import styled, { css } from "styled-components";

const StyledSpin = styled(Spin)`
  font-family: circular;
`;

const Wrapper = styled.div`
  ${({ center, top }) =>
    center &&
    css`
      .ant-spin {
        color: #00a19b;
        position: absolute;
        left: 0;
        right: 0;
        ${top && `top: 100px;`}
      }
    `}
  .ant-spin {
    color: "#00a19b";
  }
`;

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const Spinner = ({ loading, center, top, small }) => {
  return (
    <Wrapper center={center} top={top}>
      {!small ? (
        <StyledSpin indicator={antIcon} tip="Loading..." spinning={loading} />
      ) : (
        <StyledSpin
          size="small"
          style={{ color: "white", opacity: 0.5 }}
          indicator={antIcon}
          spinning={loading}
        />
      )}
    </Wrapper>
  );
};

export default Spinner;
