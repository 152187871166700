import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Icon } from "@ant-design/compatible";
import { Collapse } from "react-collapse";

import {
  CalculateHeader as Header,
  ButtonLink,
  Currency,
  Text,
  Link,
  OptimizeModalV3 as OptimizeModal,
  TiedOptimizationsModalV2,
  Spinner,
} from "components";
import OptimizationResultColumnV2 from "components/molecules/OptimizationResultColumnV2";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "utils/object";
import { List } from "antd";
import Actions from "store/report/actions";
import FilterSortButton from "components/molecules/FilterSortButton";
import useIsMobile from "components/hooks/useIsMobile";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: left 0.4s ease-in-out;
  position: relative;
  left: 0;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 769px) {
    margin: 10px auto;
    width: 82%;
  }
`;

export const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 80%;
  .ant-pagination {
    text-align: center !important;
  }
  .ant-pagination-item-active {
    border-color: #00a19b;
  }
  .ant-pagination-item-active a {
    color: #00a19b;
  }
  .ant-pagination-item:hover {
    border-color: #00a19b !important;
  }
  .ant-pagination-item:focus {
    border-color: #00a19b !important;
  }
  .ant-pagination-item-active:focus a,
  .ant-pagination-item-active:hover a {
    color: #00a19b !important;
  }

  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a {
    color: #00a19b !important;
  }
  .ant-row {
    width: 95%;
    min-width: 200px;
    > div {
      min-width: 200px !important;
    }
  }
  .ant-list {
    width: 75%;
  }
  .first-opt-item {
    min-width: 200px;
  }

  @media (min-width: 768px) {
    .first-opt-item {
      width: 25%;
      max-width: 25%;
    }
  }

  @media (max-width: 768px) {
    .ant-row {
      > div {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
`;

const FlexWrapper = styled.div`
  overflow-y: hidden;
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  align-items: ${(props) => (props.align ? props.align : "flex-start")};
  /* justify-content: ${(props) => (props.justify ? props.justify : "space-evenly")}; */
  width: 100%;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`;

const InnerWrapper = styled.div`
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: column;
  align-items: ${(props) => (props.align ? `flex-${props.align}` : "center")};
  justify-content: start;
  width: ${(props) => (props.header ? "" : props.width ? props.width : "95%")};
  /* margin: 0 15px; */
  ${({ gap }) => gap && `gap: ${gap};`}

  > .ReactCollapse--collapse {
    transition: height 500ms;

    .ReactCollapse--content {
      width: 100%;

      .ReactCollapse--collapse {
        width: 100%;
      }
    }

    > * {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
`;

export const Row = styled.div`
  height: ${(props) => (props.header ? "160px" : props.height ? props.height : "71px")};
  ${({ padding }) => (padding ? `padding: ${padding};` : "padding: 4px 5px;")}
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  ${({ border }) => border && `border: ${border};`}
  ${({ bRadius }) => bRadius && `border-radius: ${bRadius};`}

  background-color: ${(color) => {
    switch (color) {
      case "primary":
        return "transparent";
        break;
      case "secondary":
        return "#E8EEFA";
        break;
      case "tertiary":
        return "#d9f3f1";
        break;
      case "quaternary":
        return "#fafafa";
        break;
      default:
        return "#ffffff";
        break;
    }
  }};
  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`}
`;

export const OptWrapper = styled.div`
  display: flex;
  border: ${(props) => (props.custom ? "1px solid lightgrey" : "0px transparent")};
  width: inherit;
  flex-flow: column;
  min-width: 100px;
  gap: 2px;
  margin-top: 2px;

  > .ReactCollapse--collapse {
    transition: height 500ms;
  }
`;

const IconWrapper = styled(Icon)`
  display: inline-block;
  margin-left: 5px;
`;

const YearlyCostV2 = ({
  report,
  updatePreference,
  allOptimizations,
  updateState,
  broaderNetworksFromAllMyOptions,
  toggleSideBar,
  toggleAssumptions,
}) => {
  const {
    preferences,
    birthParent,
    partner,
    type,
    results,
    event: {
      general: { children },
      marriage: { considerSpousePlan },
    },
  } = report;

  const { hsaHraImpact } = report.preferences[1];

  const dispatch = useDispatch();

  const [customPrefrenceModal, setCustomPrefrenceModal] = useState(false);
  const [tiedOptimizationsModal, setTiedOptimizationsModal] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const { isMobile } = useIsMobile();

  const [showRows, setShowRows] = useState({
    total: false,
    Premium: false,
    Surcharge: false,
    Insurance: false,
    "Out-Of-Network": false,
    "HSA before": false,
    "HRA before": false,
    leftover: false,
    "HSA after": false,
    "HRA after": false,
    "HSA/HRA Impact": false,
    "Opt-Out Payments": false,
    "Healthcare Spending Total": false,
    "Not Covered": false,
  });

  const handleShow = (show, value) => {
    if (show === "total" || !showRows[show]) setShowRows({ ...showRows, [show]: value ? value : !showRows[show] });
  };

  useEffect(() => {
    Array.prototype.insert = function (index, ...items) {
      this.splice(index, 0, ...items);
    };
    const arr = allOptimizations.slice(1).map((item, index) => {
      return { ...item, index: index + 1 };
    });
    const itensPerPage = 3;
    const pages = arr.length / itensPerPage;

    const lastFullPage = Math.trunc(pages);
    const lastItemColumn = Math.round(Number((pages - lastFullPage).toFixed(2)) / 0.33);

    if (!lastItemColumn) {
      setDataSource(arr);
    } else {
      const repeatableItens = arr.slice(
        lastFullPage * itensPerPage - (itensPerPage - lastItemColumn),
        lastFullPage * itensPerPage,
      );

      arr.insert(lastFullPage * itensPerPage, ...repeatableItens);

      setDataSource(arr);
    }
  }, [allOptimizations]);

  return (
    <Wrapper>
      <FilterSortButton toggleSideBar={toggleSideBar} toggleAssumptions={toggleAssumptions} />

      {allOptimizations && (
        <TiedOptimizationsModalV2
          show={tiedOptimizationsModal}
          otherOptimizations={allOptimizations[tiedOptimizationsModal]?.otherOptimizations}
          selectedOptimizations={allOptimizations[tiedOptimizationsModal]?.selected}
          close={() => setTiedOptimizationsModal(false)}
          report={report}
          updateState={updateState}
          broaderNetworksFromAllMyOptions={broaderNetworksFromAllMyOptions}
        />
      )}

      <FlexWrapper hide={!report} justify="center" align="start">
        <InnerWrapper header align="end" gap="2px">
          <Row height="100px"></Row>
          <Row></Row>
          <Row header></Row>

          <Collapse isOpened={showRows.total}>
            {hsaHraImpact === "spending-hsa-hra" && (
              <>
                <Collapse isOpened={showRows["HSA/HRA Impact"]}>
                  <Row justify="left">
                    <Text blue bold fontSize={16} lHeight="1rem" justBetween>
                      HSA Benefit
                    </Text>
                  </Row>
                </Collapse>
                <Collapse isOpened={showRows["Opt-Out Payments"]}>
                  <Row justify="left">
                    <Text blue bold fontSize={16} lHeight="1rem" justBetween>
                      Opt-Out Payments
                    </Text>
                  </Row>
                </Collapse>
                <Collapse isOpened={showRows["Healthcare Spending Total"]}>
                  <Row justify="left">
                    <Text blue bold fontSize={16} lHeight="1rem" justBetween>
                      Healthcare Spending Total
                    </Text>
                  </Row>
                </Collapse>
              </>
            )}
            {hsaHraImpact === "spending-only" && (
              <>
                <Collapse isOpened={showRows.Premium}>
                  <Row justify="left">
                    <Text blue bold fontSize={16} lHeight="1rem" justBetween>
                      Premiums (or Paycheck Deductions)
                    </Text>
                  </Row>
                </Collapse>

                <Collapse isOpened={showRows.Surcharge}>
                  <Row justify="left">
                    <Text blue bold fontSize={16} lHeight="1rem" justBetween>
                      Surcharges
                    </Text>
                  </Row>
                </Collapse>

                <Collapse isOpened={showRows.Insurance}>
                  <Row justify="left">
                    <Text blue bold fontSize={16} lHeight="1rem" justBetween>
                      In-Network Healthcare Charges
                    </Text>
                  </Row>
                </Collapse>

                <Collapse isOpened={showRows["Out-Of-Network"]} noWrap>
                  <Row justify="left">
                    <Text blue bold fontSize={16} lHeight="1rem" justBetween>
                      Out-Of-Network Charges
                    </Text>
                  </Row>
                </Collapse>

                <Collapse isOpened={showRows["Opt-Out Payments"]}>
                  <Row justify="left">
                    <Text blue bold fontSize={16} lHeight="1rem" justBetween>
                      Opt-Out Payments
                    </Text>
                  </Row>
                </Collapse>

                <Collapse isOpened={showRows["HSA before"]}>
                  <Row justify="left">
                    <Text blue bold fontSize={16} lHeight="1rem" justBetween>
                      HSA
                    </Text>
                  </Row>
                </Collapse>

                <Collapse isOpened={showRows["HRA before"]}>
                  <Row justify="left">
                    <Text blue bold fontSize={16} lHeight="1rem" justBetween>
                      HRA
                    </Text>
                  </Row>
                </Collapse>

                <Collapse isOpened={showRows["Not Covered"]}>
                  <Row justify="left">
                    <Text blue bold fontSize={16} lHeight="1rem" justBetween>
                      Not Covered
                    </Text>
                  </Row>
                </Collapse>
              </>
            )}
          </Collapse>
          <Row
            justify="start"
            align="center"
            bgColor="#E8EEFA"
            padding={"4px 10px;"}
            style={{
              boxShadow: "60px 0px #E8EEFA",
              width: "100%",
            }}
          >
            <Link bold blue textAlign justBetween fontSize={20} onClick={() => handleShow("total")}>
              Estimated Grand Total
              <IconWrapper type={showRows.total ? "up" : "down"} />
            </Link>
          </Row>
        </InnerWrapper>

        <ScrollWrapper>
          {allOptimizations.length > 1 && (
            <div className="first-opt-item">
              <InnerWrapper>
                <OptimizationResultColumnV2
                  optimization={allOptimizations[0].selected}
                  preferenceKey={1}
                  index={0}
                  report={report}
                  otherOptimizations={allOptimizations[0].otherOptimizations}
                  showRows={showRows}
                  handleShow={handleShow}
                  checkOtherOptions={() => {
                    setTiedOptimizationsModal(0);
                  }}
                />
              </InnerWrapper>
            </div>
          )}
          {allOptimizations.length > 1 && (
            <List
              grid={{ gutter: 0, column: 3 }}
              dataSource={dataSource}
              pagination={{
                defaultPageSize: isMobile ? 1 : 3,
                pageSize: isMobile ? 1 : 3,
                responsive: false,
                showSizeChanger: false,
              }}
              renderItem={(optimization) => (
                <InnerWrapper>
                  <OptimizationResultColumnV2
                    optimization={optimization.selected}
                    preferenceKey={1}
                    index={optimization.index}
                    report={report}
                    otherOptimizations={optimization.otherOptimizations}
                    showRows={showRows}
                    handleShow={handleShow}
                    checkOtherOptions={() => {
                      setTiedOptimizationsModal(optimization.index);
                    }}
                  />
                </InnerWrapper>
              )}
            />
          )}
        </ScrollWrapper>
      </FlexWrapper>
    </Wrapper>
  );
};

export default YearlyCostV2;
