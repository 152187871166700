import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "@ant-design/compatible";

import { Text } from "components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 60px;
`;

const FlexWrapper = styled.div`
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  justify-content: ${(props) =>
    props.justify ? props.justify : "space-evenly"};
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : "0")};
`;

const Tabs = styled.div`
  border: 1px solid transparent;
  border-radius: 20px;
  width: 261px;
  margin-right: 40px;
  overflow: hidden;
`;

const Pane = styled.button`
  width: 100%;
  height: 74px;
  outline: none;
  border: none;
  border-bottom: 1px solid #fff;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => (props.active ? "#00a19b" : "#d9f3f1")};
  :hover {
    background-color: #86dbd5;
    cursor: pointer;
  }
`;

const InnerWrapper = styled.div`
  width: 754px;
  display: ${(props) => (props.active ? "flex" : "none")};
  flex-direction: column;
`;

const ListItem = styled.li`
  display: list-item;
`;

const Education = () => {
  const [show, setShow] = useState("copay");

  const handleShow = (display) => {
    setShow(display);
  };
  return (
    <Wrapper>
      <FlexWrapper justify="center" align="flex-start">
        <Tabs>
          <Pane active={show === "copay"} onClick={() => handleShow("copay")}>
            <Text black lg left bold={show === "copay"}>
              What is a Copay?
            </Text>
            <Icon type="right" />
          </Pane>
          <Pane
            active={show === "coinsurance"}
            onClick={() => handleShow("coinsurance")}
          >
            <Text black lg left bold={show === "coinsurance"}>
              What is Coinsurance?
            </Text>
            <Icon type="right" />
          </Pane>
          <Pane
            active={show === "deductible"}
            onClick={() => handleShow("deductible")}
          >
            <Text black lg left bold={show === "deductible"}>
              What is a Deductible?
            </Text>
            <Icon type="right" />
          </Pane>
          <Pane
            active={show === "in-network"}
            onClick={() => handleShow("in-network")}
          >
            <Text black lg left bold={show === "in-network"}>
              What counts as in-network?
            </Text>
            <Icon type="right" />
          </Pane>
        </Tabs>
        <InnerWrapper active={show === "copay"}>
          <Text black big bold>
            How Copays Work
          </Text>
          <br></br>
          <ul>
            <ListItem>
              <Text black small>
                For most procedures and visits, you will owe copays
              </Text>
            </ListItem>
            <ListItem>
              <Text black small>
                The amount of the copay depends on the type of visit, procedure,
                or prescription
              </Text>
            </ListItem>
          </ul>
        </InnerWrapper>
        <InnerWrapper active={show === "coinsurance"}>
          <Text black big bold>
            How Coinsurance Works
          </Text>
          <br></br>
          <ul>
            <ListItem>
              <Text black small inline>
                Once you hit your deductible, for many plans, you will pay a
                percentage of the doctor's charges (your{" "}
                <Text black small bold underline inline>
                  coinsurance
                </Text>
                )
              </Text>
            </ListItem>
            <ListItem>
              <Text black small inline>
                For certain plans, instead of paying a percentage of the
                charges, you pay a{" "}
                <Text black small bold underline inline>
                  copay
                </Text>
                )
              </Text>
            </ListItem>
            <ListItem>
              <Text black small inline>
                Your coinsurance (including any copays) and deductible count
                toward your{" "}
                <Text black small bold underline inline>
                  out-of-pocket maximum
                </Text>
              </Text>
            </ListItem>
          </ul>
        </InnerWrapper>
        <InnerWrapper active={show === "deductible"}>
          <Text black big bold>
            How Deductibles Work
          </Text>
          <br></br>
          <ul>
            <ListItem>
              <Text black small inline>
                For most procedures and visits, you will be required to pay a
                certain amount of charges each year (your{" "}
                <Text black small bold underline inline>
                  deductible
                </Text>
                )
              </Text>
            </ListItem>
            <ListItem>
              <Text black small inline>
                Once you hit your deductible, you will pay a percentage of the
                doctor's charges (your{" "}
                <Text black small bold underline inline>
                  coinsurance
                </Text>
                )
              </Text>
            </ListItem>
          </ul>
        </InnerWrapper>
        <InnerWrapper active={show === "in-network"}>
          <Text black big bold>
            What Counts As In-Network
          </Text>
          <br></br>
          <ul>
            <ListItem>
              <Text black small inline>
                If a provider is not in-network, you will pay the full price
                (for example, a therapist might charge $350 for an initial
                consultation and $260 for recurring visits)
              </Text>
              <ul>
                <ListItem>
                  <Text black small inline>
                    For plans with out-of-network coverage, part of this
                    spending would accrue towards a maximum out-of-pocket limit,
                    and if you have enough visits, you would be partially
                    reimbursed for each visit
                  </Text>
                </ListItem>
              </ul>
            </ListItem>
            <ListItem>
              <Text black small inline>
                Your insurance is the ultimate authority on whether a doctor or
                provider is "in-network". If you think there is any doubt the
                provider may be in-network, establish a paper trail
              </Text>
              <ul>
                <ListItem>
                  <Text black small inline>
                    If the insurance company's online provider directory shows
                    the doctor in-network, keep a record such as a PDF with a
                    timestamp (if rely only on the website, the insurance
                    company can update it at any time)
                  </Text>
                </ListItem>
                <ListItem>
                  <Text black small inline>
                    You can also call the phone number on the back of your
                    health insurance card and request a letter stating the
                    provider is in-network
                  </Text>
                </ListItem>
              </ul>
            </ListItem>
            <ListItem>
              <Text black small inline>
                Even if your insurance company states that a provider is
                "in-network", that doctor can make it hard to schedule an
                appointment with patients who have less desirable insurance
                plans
              </Text>
              <ul>
                <ListItem>
                  <Text black small inline>
                    If a doctor is in high demand, they may be able to pick and
                    choose and only see patients who have insurance plans with
                    higher reimbursement rates
                  </Text>
                </ListItem>
                <ListItem>
                  <Text black small inline>
                    Doctor's office may tell you upfront that they do not accept
                    you insurance, or they might tell you they don't have any
                    availability
                  </Text>
                </ListItem>
              </ul>
            </ListItem>
            <ListItem>
              <Text black small inline>
                Your care is more likely to be billed as "in-network" if you
                both schedule an appointment with your doctor who has confirmed
                to you that they accept your insurance, and then prior to the
                visit to document through your insurance company that this
                doctor is in their network
              </Text>
              <ul>
                <ListItem>
                  <Text black small inline>
                    Even if you take these steps, providers and insurance
                    companies are continually renegotiating their networks and
                    rates, so it is still possible that a doctor's "in-network"
                    status could change
                  </Text>
                </ListItem>
              </ul>
            </ListItem>
          </ul>
        </InnerWrapper>
      </FlexWrapper>
    </Wrapper>
  );
};

export default Education;
