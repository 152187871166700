import styled, { css } from "styled-components";

const Text = styled.p`
  font-size: 0.8em;
  font-family: circular;
  margin: 0;
  font-weight: 400;
  font-style: normal;
  line-height: 1.7em;
  text-transform: none;
  ${({ customLSpacing }) => customLSpacing && `letter-spacing: ${customLSpacing};`}

  ${({ select }) =>
    select
      ? `font-size: 13px; padding: 0;font-style: italic;     line-height: 1.5em;`
      : ` font-size: 0.8em;`}

  ${({ calculateFormHeader }) => calculateFormHeader && `font-size: 0.9em;`}
            ${({ calculateFormHeader }) =>
    calculateFormHeader && `font-size: 0.5em;`}
  ${({ arial }) =>
    arial &&
    css`
      line-height: 1.5em;
      font-size: 14px;
      color: #6b6b6b;
    `}
  
  ${({ color }) => color && `color: ${color};`}

  ${({ fontSize }) =>
    fontSize &&
    `
    font-size: ${fontSize}px;
    line-height: ${fontSize}px;
    `}

    ${({ italic }) => italic && `font-style: italic;`}
    ${({ bold }) => bold && `font-weight: bold`};
    ${({ bolder }) => bolder && `font-weight: bolder; font-size: 16px`};
    ${({ underline }) => underline && `text-decoration: underline`};
    ${({ white }) => white && `color: #e6e8eb;`}
    ${({ black }) => black && `color: #111;`}
    ${({ teal }) => teal && `color: #51a5a1;`}
    ${({ lightblack }) => lightblack && `color: #333;`}
    ${({ green }) => green && `color: rgb(0,161,155);  `}
    ${({ blue }) => blue && `color: #21355B;  `}
    ${({ grey }) => grey && `color: #54565a;  `}
    ${({ lightGrey }) => lightGrey && `color: rgba(33, 52, 91, 0.25);  `}
    ${({ lightGrey2 }) => lightGrey2 && `color: #667085;  `}
    ${({ white }) => white && `color: white;`}
    ${({ red }) => red && `color: #DC4C2D;  `}
    ${({ orange }) => orange && `color: rgb(198,88,28);  `}
    ${({ headerColor }) => headerColor && `color: #21355B;  `}
    ${({ orangeHighlight }) =>
    orangeHighlight && `background-color: rgb(198,88,28, 0.2);  `}
    ${({ tiny }) => tiny && `font-size: 12px; line-height: 16px;`}
    ${({ small }) => small && `font-size: 16px; line-height: 18px;`}
    ${({ big }) => big && ` font-size: 24px;`}
    ${({ lg }) => lg && ` font-size: 18px; line-height: 26px;`}
    ${({ inline }) => inline && `display: inline`}
    ${({ left }) => left && `text-align: left`}
    ${({ right }) => right && `text-align: right`}
    ${({ redHighlight }) =>
    redHighlight && `background-color:rgba(255, 0, 0, 0.2);`}
    ${({ shadow }) => shadow && `text-shadow: 1px 0 #888888;`}
    ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px;`}

    ${({ lineHeightUnits }) =>
    lineHeightUnits && `line-height: ${lineHeightUnits};`}

    ${({ wordBreak }) => wordBreak && `word-break: break-word`}

    ${({ modal, center }) =>
    modal &&
    css`
      text-align: justify;
      ${({ center }) => center && "text-align: center;"}
    `}

    ${({ subHeading }) =>
    subHeading &&
    css`font-family: circular;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.7em;
    text-transform: none;
    color: #fff;
    }`}
    ${({ medium }) => medium && `font-size: 24px;`}
    ${({ large }) => large && `font-size: 45px;`}
    ${({ margin }) => margin && `margin:${margin}`}
    ${({ padding }) => padding && `padding:${padding}`}
    ${({ transform }) => transform && `text-transform:${transform}`}
    ${({ uppercase }) => uppercase && `text-transform: uppercase;`}
    
    -webkit-print-color-adjust: exact;
  color-adjust: exact ${({ indent }) => indent && `padding-left: 20px`};
  ${({ indentMore }) => indentMore && `padding-left: 40px`};

  ${({ center }) => center && `text-align: center`};
  ${({ verticalCenter }) =>
    verticalCenter && `margin-top: auto; margin-bottom: auto`};
  ${({ end }) => end && `width: 100%; text-align: end;padding-right: 15px}`}
  ${({ noWrap }) => noWrap && `white-space: nowrap`};
  ${({ customWidth }) => customWidth && `width: ${customWidth}`};

  ${({ desktopMaxWidth }) => desktopMaxWidth && ` @media (min-width: 768px) { max-width: ${desktopMaxWidth}; }`};

  ${({ cursor }) =>
    cursor &&
    css`
      cursor: pointer;
    `}

  ${({ circular }) =>
    circular && `font-family: circular;`}
  
  ${({ newSelect }) =>
    newSelect &&
    `
    font-size: 14px;
    color: #5A7184; 
    font-weight: 400;
    font-family: circular;`}

      ${({ sectionTitle }) =>
    sectionTitle &&
    `
    font-size: 24px;
    color: #21355B; 
    font-weight: bold;
    font-family: circular;
    line-height: 1.3em;
    margin-bottom: 15px;`}

    ${({ sectionSubTitle }) =>
    sectionSubTitle &&
    `
      font-size: 18px;
      color: #667085; 
      font-weight: bold;
      font-family: circular;
      line-height: 1.3em;
    `}

          ${({ formLabel }) =>
    formLabel &&
    `
    font-size: 18px;
    color: #344054; 
    font-weight: bold;
    font-family: circular;`}
    ${({ formNote }) =>
    formNote &&
    `
    font-size: 14px;
    color: #333; 
    align-items: center;
    font-style: italic
    font-family: circular;
    margin-bottom: 20px`}

${({ coverageLabel }) =>
    coverageLabel &&
    `
    font-size: 14px;
    color: #333; 
    display: flex;
    align-items: center;
    height: 30px;
    font-family: circular;`}

${({ healthFundLabel }) =>
    healthFundLabel &&
    `
    font-size: 14px;
    color: #333; 
    display: flex;
    align-items: center;
    height: 30px;
    font-family: circular;
    width:108px;
    justify-content: center;
 `}
 ${({ formHSALabel }) =>
    formHSALabel &&
    `
    font-size: 14px;
    line-height: 20px;
    color: #333; 
    font-family: circular;`}
 
    ${({ hover }) =>
    hover &&
    css`
      :hover {
        color: #40a9ff;
      }
    `}

  ${({ justStart }) => justStart && `
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  `}
      
  ${({ comparisonMode }) =>
    comparisonMode &&
    `width: 100%; display: flex; align-items: center;     justify-content: center;
    margin-right: 10px;`}
  ${({ hide }) => hide && `display: none`}
`;

export default Text;
