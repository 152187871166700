import { PlusOutlined } from "@ant-design/icons";
import { Button, Space, Input, Tooltip } from "antd";
import Text from "components/atoms/Text";
import { useFormikContext } from "formik";
import React from "react";
import { formatterNumber, parserNumber } from "utils/currency";
import { formatterRate, parserRate } from "utils/rate";
import AutoCompleteField from "../AutoCompleteField";
import FormDetachField from "../FormDetachField";
import FormField from "../FormField";
import SelectField from "../SelectField";
import { WordEnum } from "enums/serviceWordMapping.enums";

const outOfNetworkFields = [
  "outOfNetworkIndividual",
  "outOfNetworkFamily",
  "outOfNetworkIndividualInFamily",
  "hasoutOfNetworkIndividualInFamily",
  "outOfNetworkIndividualInFamilyOOP",
  "outOfNetworkAndInNetworkCrossAccumulate",
  "hasoutOfNetworkIndividualInFamilyOOP",
  "outOfNetworkIndividualOOP",
  "outOfNetworkFamilyOOP",
];

const shouldShowElement = (table, index, dataIndex, serviceOption) => {
  if (!table || !table[index]) return true;
  else if (table[index]["outOfNetworkCoverage"] === "no" && outOfNetworkFields.includes(dataIndex)) return false;
  else if (table[index]["haveToPay"] === "copay" && dataIndex === "CoinsuranceRate") return false;
  else if (table[index].services?.[serviceOption]?.["haveToPay"] === "copay" && dataIndex === "CoinsuranceRate")
    return false;
  return true;
};

const EditableCell = ({
  editable,
  index,
  dataIndex,
  title,
  inputType,
  children,
  options,
  name,
  table,
  dropdownMatchSelectWidth,
  twice,
  page,
  disabled,
  disabledValue,
  min,
  className,
  emptyValue,
  disableCondition,
  style,
  filterOption,
  serviceOption = "",
  pageSize,
  inline,
  maxLength,
  allowValueNotInOptions,
  ...restProps
}) => {
  const { setFieldValue } = useFormikContext();

  pageSize = pageSize || 10;
  if (page) index = (page - 1) * pageSize + index;

  const isOutOfNetworkCoverageTwice = twice && table && table[index]?.outOfNetworkCoverage === "yes";

  const isHsaOrHraCompatible = table?.[index]?.isHsaOrHraCompatible;

  let twiceFields = ["inNetwork", "outOfNetwork"];

  let showElement = shouldShowElement(table, index, dataIndex, serviceOption);

  let serviceObject = "";
  if (serviceOption !== "") {
    serviceObject = `.services[${serviceOption}]`;
  }

  const forcedDisable =
    table[index] && (table[index][disableCondition] === "yes" || table[index][disableCondition] === true);

  if (!isOutOfNetworkCoverageTwice) twiceFields = twiceFields.slice(0, 1);

  const field = (options) => ({
    text: (
      <>
        {dataIndex === "label" && disabled ? (
          <Tooltip title={table[index][dataIndex]}>
            <>
              <FormField
                name={`${name}[${index}].${dataIndex}`}
                size="fullwidth"
                style={{
                  margin: 0,
                  visibility: showElement ? "visible" : "hidden",
                }}
                type="text"
                disabled={disabled}
              />
            </>
          </Tooltip>
        ) : (
          <FormField
            name={`${name}[${index}].${dataIndex}`}
            size="fullwidth"
            style={{
              margin: 0,
              visibility: showElement ? "visible" : "hidden",
            }}
            type="text"
            disabled={disabled}
            inline={inline}
            index={index}
            dataIndex={dataIndex}
            maxLength={maxLength}
          />
        )}
      </>
    ),
    number: (
      <FormField
        name={`${name}[${index}].${dataIndex}`}
        size="fullwidth"
        style={{
          margin: 0,
          visibility: showElement ? "visible" : "hidden",
        }}
        type="number"
        disabled={disabled}
      />
    ),
    currency: (
      <FormField
        name={`${name}[${index}].${dataIndex}`}
        size="fullwidth"
        style={{
          margin: 0,
          width: "100%",
          visibility: showElement ? "visible" : "hidden",
        }}
        min={min || 0}
        defaultValue="$"
        formatter={formatterNumber}
        parser={parserNumber}
        type="number"
        disabled={disabled}
      />
    ),
    currencyAddable: (
      <>
        <Input.Group
          compact
          style={{
            height: 40,
            width: "100%",
            display: table[index] && table[index][`has${dataIndex}`] ? "none" : "flex",
            visibility: showElement ? "visible" : "hidden",
          }}
        >
          <Input
            disabled
            value={emptyValue}
            style={{
              height: 40,
              margin: 0,
              width: "calc(100% - 32px)",
              fontSize: 18,
              flexGrow: 1,
            }}
          />

          <Button
            style={{
              width: 26,
              height: 40,
            }}
            onClick={() => {
              setFieldValue(`${name}[${index}].has${dataIndex}`, true);
            }}
            icon={<PlusOutlined />}
          />
        </Input.Group>

        <FormDetachField
          name={`${name}[${index}].${dataIndex}`}
          size="fullwidth"
          style={{
            fontSize: 18,
            margin: 0,
            width: "calc(100% - 32px)",
            display: table[index] && !table[index][`has${dataIndex}`] && "none",
            visibility: showElement ? "visible" : "hidden",
          }}
          defaultValue="$"
          formatter={formatterNumber}
          parser={parserNumber}
          type="number"
          onButtonClick={() => {
            setFieldValue(`${name}[${index}].has${dataIndex}`, false);
            setFieldValue(`${name}[${index}].${dataIndex}`, null);
          }}
          disabled={disabled}
        />
      </>
    ),
    rate: (
      <FormField
        name={`${name}[${index}].${dataIndex}`}
        size="fullwidth"
        style={{
          margin: 0,
          width: "100%",
          visibility: showElement ? "visible" : "hidden",
        }}
        defaultValue="%"
        max={100}
        min={0}
        formatter={formatterRate}
        parser={parserRate}
        type="number"
      />
    ),
    autocomplete: (
      <>
        {dataIndex === "insuranceNetwork" && disabled ? (
          <Tooltip title={table[index][dataIndex]?.replace(";", " ")}>
            <AutoCompleteField
              name={`${name}[${index}].${dataIndex}`}
              size="fullwidth"
              dropdownMatchSelectWidth={dropdownMatchSelectWidth}
              style={{
                margin: 0,
                visibility: showElement ? "visible" : "hidden",
              }}
              options={options}
              disabled={disabled}
            />
          </Tooltip>
        ) : (
          <AutoCompleteField
            name={`${name}[${index}].${dataIndex}`}
            size="fullwidth"
            dropdownMatchSelectWidth={dropdownMatchSelectWidth}
            style={{
              margin: 0,
              visibility: showElement ? "visible" : "hidden",
            }}
            options={options}
            disabled={disabled}
          />
        )}
      </>
    ),
    select: (
      <SelectField
        name={`${name}[${index}].${dataIndex}`}
        size="fullwidth"
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        style={{
          margin: 0,
          visibility: showElement ? "visible" : "hidden",
        }}
        options={filterOption ? options?.filter((item) => filterOption(item, table[index])) : options}
        disabled={
          disabled || forcedDisable || (dataIndex === "type" && table[index].billedAs === "Out-of-network Therapy")
        }
        forcedValue={forcedDisable && disabledValue}
        handleOnSelect={restProps.handleOnSelect || null}
        index={index}
        allowValueNotInOptions={allowValueNotInOptions}
      />
    ),
    coverage: (
      <Space direction="vertical" size="middle" style={{ display: !isOutOfNetworkCoverageTwice ? "none" : "flex" }}>
        {["In-Network", "Out-Of-Network"].map((item) => (
          <Text coverageLabel>{item}</Text>
        ))}
      </Space>
    ),
    hsaOrHra: (
      <span
        style={{
          display: isHsaOrHraCompatible === "no" ? "none" : "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {["HSA", "HRA"]
          .filter((item) => isHsaOrHraCompatible === "both" || isHsaOrHraCompatible === item)
          .map((item) => (
            <Text coverageLabel>{item}</Text>
          ))}
      </span>
    ),
    select2xReadOnly: (
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <SelectField
          name={`${name}[${index}]${serviceObject}.${dataIndex}`}
          size="fullwidth"
          dropdownMatchSelectWidth={dropdownMatchSelectWidth}
          style={{
            margin: 0,
            visibility: showElement ? "visible" : "hidden",
          }}
          options={options}
          disabled={
            (dataIndex === "inNetworkSubjectToDeductible" && table[index]?.inNetworkIndividual === 0) || disabled
          }
          defaultValue={dataIndex === "inNetworkSubjectToDeductible" ? "yes" : ""}
        />
        {isOutOfNetworkCoverageTwice && (
          <SelectField
            name="disabled"
            size="fullwidth"
            dropdownMatchSelectWidth={dropdownMatchSelectWidth}
            style={{
              margin: 0,
            }}
            defaultValue={serviceOption === "Fertility Services (IVF, Egg Freezing)" ? "not covered" : disabledValue}
            options={
              serviceOption === "Fertility Services (IVF, Egg Freezing)"
                ? [{ value: "not covered", name: "Not Covered" }]
                : options
            }
            disabled={true}
          />
        )}
      </Space>
    ),
    select2x: (
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        {twiceFields.map(
          (item, i) =>
            (i === 0 || isOutOfNetworkCoverageTwice) && (
              <SelectField
                name={`${name}[${index}].${item}${dataIndex}`}
                size="fullwidth"
                dropdownMatchSelectWidth={dropdownMatchSelectWidth}
                style={{
                  margin: 0,
                  visibility: i === 1 ? "visible" : showElement ? "visible" : "hidden",
                }}
                options={options}
              />
            ),
        )}
      </Space>
    ),
    currency2x: (
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        {twiceFields.map(
          (item, i) =>
            (i === 0 || isOutOfNetworkCoverageTwice) && (
              <FormField
                name={`${name}[${index}].${item}${dataIndex}`}
                size="fullwidth"
                style={{
                  margin: 0,
                  width: "100%",
                  visibility: i === 1 ? "visible" : showElement ? "visible" : "hidden",
                }}
                defaultValue="$"
                formatter={formatterNumber}
                parser={parserNumber}
                type="number"
                disabled={disabled}
              />
            ),
        )}
      </Space>
    ),
    rate2x: (
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        {twiceFields.map(
          (item, i) =>
            (i === 0 || isOutOfNetworkCoverageTwice) && (
              <FormField
                name={`${name}[${index}]${serviceObject}.${item}${dataIndex}`}
                size="fullwidth"
                style={{
                  margin: 0,
                  width: "100%",
                  visibility:
                    serviceOption === "Fertility Services (IVF, Egg Freezing)" && i === 1
                      ? "hidden"
                      : i === 1
                      ? "visible"
                      : showElement
                      ? "visible"
                      : "hidden",
                }}
                defaultValue="%"
                max={100}
                min={0}
                disabled={serviceOption !== "Inpatient Hospital Stay" && i === 1 && disabled}
                formatter={formatterRate}
                parser={parserRate}
                type="number"
              />
            ),
        )}
      </Space>
    ),
    copay: (
      <div
        style={{
          display:
            table[index]?.haveToPay != undefined && table[index]?.haveToPay !== "coinsurance"
              ? "flex"
              : table[index]?.services?.[serviceOption]?.haveToPay != undefined &&
                table[index]?.services?.[serviceOption]?.haveToPay !== "coinsurance"
              ? "flex"
              : "none",
          alignItems: "center",
        }}
      >
        <FormField
          name={`${name}[${index}]${serviceObject}.${dataIndex[0]}`}
          size="fullwidth"
          style={{
            margin: 0,
            visibility: showElement ? "visible" : "hidden",
          }}
          defaultValue="$"
          formatter={formatterNumber}
          parser={parserNumber}
          type="number"
          disabled={disabled}
        />
        <span>per</span>
        {serviceOption &&
        [
          "Generic (Tier 1)",
          "Preferred Brand (Tier 2)",
          "Non-Preferred Brand (Tier 3)",
          "Specialty (Tier 4)",
          "Other Drug",
        ].includes(serviceOption) ? (
          <>supply</>
        ) : serviceOption && serviceOption !== "Inpatient Hospital Stay" ? (
          <>{WordEnum[serviceOption]}</>
        ) : (
          <SelectField
            name={`${name}[${index}]${serviceObject}.${dataIndex[1]}`}
            size="fullwidth"
            width={table[index]?.unit !== "night" && 90}
            dropdownMatchSelectWidth={dropdownMatchSelectWidth}
            style={{
              margin: 0,
              visibility: showElement ? "visible" : "hidden",
            }}
            options={options}
          />
        )}
        {(table[index]?.unit === "night" || table[index]?.services?.[serviceOption]?.unit === "night") && (
          <>
            <span style={{ minWidth: 33 }}>up to</span>
            <FormField
              name={`${name}[${index}]${serviceObject}.${dataIndex[2]}`}
              style={{
                margin: 0,
                width: "65px",
                visibility: showElement ? "visible" : "hidden",
              }}
              type="number"
            />
            <span>nights</span>
          </>
        )}
      </div>
    ),
    healthFund: (
      <>
        {index === 0 && (
          <div
            size="middle"
            style={{
              display: "flex",
              position: "absolute",
              top: -36,
              gap: 16,
            }}
          >
            <Text healthFundLabel>Individual</Text>

            <Text healthFundLabel>Family of 2</Text>

            <Text healthFundLabel>Family of 3+</Text>
          </div>
        )}
        <Space direction="vertical" size="middle">
          {["HSA", "HRA"]
            .filter((item) => isHsaOrHraCompatible === "both" || isHsaOrHraCompatible === item)
            .map((item, i) => (
              <Space
                size="middle"
                style={{
                  display: "flex",
                }}
              >
                <FormField
                  name={`${name}[${index}].${dataIndex[0]}${item}`}
                  size="fullwidth"
                  defaultValue="$"
                  formatter={formatterNumber}
                  parser={parserNumber}
                  type="number"
                  disabled={disabled}
                />

                <FormField
                  name={`${name}[${index}].${dataIndex[1]}${item}`}
                  size="fullwidth"
                  defaultValue="$"
                  formatter={formatterNumber}
                  parser={parserNumber}
                  type="number"
                  disabled={disabled}
                />

                <FormField
                  name={`${name}[${index}].${dataIndex[2]}${item}`}
                  size="fullwidth"
                  defaultValue="$"
                  formatter={formatterNumber}
                  parser={parserNumber}
                  type="number"
                  disabled={disabled}
                />

                {item === "HSA" ? (
                  <>
                    <div style={{ textAlign: "center" }}>deposited</div>
                    <SelectField
                      name={`${name}[${index}].${dataIndex[3]}${item}`}
                      width={120}
                      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
                      options={options && options[0]}
                    />
                  </>
                ) : (
                  <>
                    <div style={{ textAlign: "center" }}>toward</div>
                    <SelectField
                      name={`${name}[${index}].${dataIndex[4]}${item}`}
                      width={70}
                      options={options && options[1]}
                    />
                    <span>part of deductible</span>
                  </>
                )}
              </Space>
            ))}
        </Space>
      </>
    ),
    pricePerVisit: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <FormField
          name={`${name}[${index}].${dataIndex[0]}`}
          size="fullwidth"
          style={{
            visibility: showElement ? "visible" : "hidden",
          }}
          defaultValue="$"
          formatter={formatterNumber}
          parser={parserNumber}
          type="number"
          disabled={disabled}
        />
        {table[index].billedAs === "Inpatient Hospital Stay" ? (
          <>
            <span>per</span>
            <SelectField
              name={`${name}[${index}].${dataIndex[2]}`}
              size="fullwidth"
              dropdownMatchSelectWidth={dropdownMatchSelectWidth}
              style={{
                visibility: showElement ? "visible" : "hidden",
              }}
              options={[
                { name: "stay", value: "stay" },
                { name: "night", value: "night" },
              ]}
            />
            <span style={{ whiteSpace: "nowrap" }}>for a</span>
            <FormField
              name={`${name}[${index}].${dataIndex[1]}`}
              size="fullwidth"
              type="number"
              disabled={disabled}
              style={{
                width: "40px",
              }}
            />
            <span style={{ whiteSpace: "nowrap" }}>-night stay</span>
          </>
        ) : [
            "Generic (Tier 1)",
            "Preferred Brand (Tier 2)",
            "Non-Preferred Brand (Tier 3)",
            "Specialty (Tier 4)",
            "Other Drug",
          ].includes(table[index].billedAs) ? (
          <>
            <span style={{ display: table[index][dataIndex[1]] === "once" ? "none" : "block" }}>per</span>
            <SelectField
              name={`${name}[${index}].${dataIndex[1]}`}
              size="fullwidth"
              dropdownMatchSelectWidth={dropdownMatchSelectWidth}
              style={{
                margin: 0,
                visibility: showElement ? "visible" : "hidden",
              }}
              options={options && options[1]}
            />
            <span style={{ display: table[index][dataIndex[1]] === "once" ? "block" : "none" }}>per</span>
            <FormField
              name={`${name}[${index}].${dataIndex[2]}`}
              size="fullwidth"
              placeholder="0.0 ML"
              type="text"
              disabled={disabled}
            />
          </>
        ) : table[index][dataIndex[1]] === "once" ? (
          <SelectField
            name={`${name}[${index}].${dataIndex[1]}`}
            size="fullwidth"
            dropdownMatchSelectWidth={dropdownMatchSelectWidth}
            style={{
              margin: 0,
              visibility: showElement ? "visible" : "hidden",
            }}
            options={options && options[0]}
          />
        ) : table[index][dataIndex[1]] === "other frequency" ? (
          <>
            <span>per {WordEnum[table[index].billedAs] || "service"}</span>
            <FormField
              name={`${name}[${index}].otherFrequencyValue`}
              style={{
                margin: 0,
                width: "100%",
              }}
              disabled={disabled}
              min={min || 0}
              defaultValue="1"
              formatter={(value) => (value < 0 ? 0 : value)}
              type="number"
            />
            <span style={{ whiteSpace: "nowrap" }}>times</span>
          </>
        ) : (
          <>
            <span>per</span>
            <SelectField
              name={`${name}[${index}].${dataIndex[1]}`}
              size="fullwidth"
              dropdownMatchSelectWidth={dropdownMatchSelectWidth}
              style={{
                margin: 0,
                visibility: showElement ? "visible" : "hidden",
              }}
              options={options && options[0]}
            />
            <span style={{ whiteSpace: "nowrap" }}>{WordEnum[table[index].billedAs] || "service"}</span>
          </>
        )}
      </div>
    ),
    reimbursement: (
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        <FormField
          name={`expectedTable[${dataIndex[0] - 1}].reimbursements[${table[index].id}]`}
          style={{
            margin: 0,
            width: "100%",
          }}
          disabled={disabled}
          min={min || 0}
          defaultValue="$"
          formatter={formatterNumber}
          parser={parserNumber}
          type="number"
        />
        <span>
          per {dataIndex[1]} counts towards the {table[index].extraDetailsPhrase}
        </span>
      </div>
    ),
    textarea: (
      <FormField
        name={`${name}[${index}].${dataIndex}`}
        size="fullwidth"
        style={{
          margin: 0,
          visibility: showElement ? "visible" : "hidden",
        }}
        type="textarea"
        disabled={disabled}
        inline={inline}
        index={index}
        dataIndex={dataIndex}
      />
    ),
  });
  return (
    <td
      {...restProps}
      key={`${name}[${index}`}
      className={`${className} form`}
      style={{
        ...style,
        verticalAlign: inputType !== "copay" ? "inherit" : "initial",
      }}
    >
      {editable ? field(options)[inputType] : children}
    </td>
  );
};

export default EditableCell;
