import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "@ant-design/compatible";
import { Card } from "antd";

const InsideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;
`

const DiffsFeedback = ({differences}) => {

  return (
    <Card title="Differences">
      {
        differences.map( diff => {
          if(diff.diffs.length === 0) return
          return (
            <>
            <p>{'ROW: ' + diff.line}</p>
            <InsideWrapper>
              { diff.diffs.map(diff => <p>{`- ${diff}`}</p>) }
            </InsideWrapper>
            </>
          )
        })
      }              
    </Card>
  );
};

export default DiffsFeedback;
