import { put, call, select } from "redux-saga/effects";
import {
  getGroupsInsurance,
  getPlansInsurance,
  getPublicGroupsInsurance,
  getNetworkInsurance,
  getPublicPlansInsurance,
  getPublicNetworkInsurance,
} from "services/api";
import Actions from "./actions";
import { getToken, getUserId } from "services/utils";
import { removeTokenSaga } from "../auth/sagas";
import history from "../history";

const { Creators } = Actions;
const getPlan = (state) => state.plan;

const names = [
  "groups",
  "insurancePlans",
  "plansNetwork",
  "firstBaby",
  "insurancePlanType",
  "isDoctorInNetwork",
  "tiers", // 6
  "surcharges", // 7
  "hsa",
  "premiumGroups", //9
];

export function* loadSelectOptionInsurance(name, value) {
  try {
    const token = yield getToken();
    const plan = yield select(getPlan);
    if (name === names[3] && value === false) {
      yield put(Creators.changeInsurancePlan("insurancePlanType", "family"));
    } else if (name === names[4]) {
      yield put(
        Creators.changeInsuranceIsNetwork(names[5], { isVisible: true })
      );
    } else if (name === names[0]) {
      yield call(loadName, value, token, plan);
    } else if (name === names[1]) {
      yield call(loadNetworkPlan, plan, value, token);
    }
  } catch (error) {
    yield removeTokenSaga(error);
  }
}

function* loadNetworkPlan(plan, value, token) {
  const { pathname } = history.location;

  let response;
  if (pathname === "/calculator") {
    const startDate = "2021-06-30";
    response = yield call(
      getPublicNetworkInsurance,
      plan.groups.value,
      value,
      startDate
    );
  } else {
    response = yield call(getNetworkInsurance, plan.groups.value, value, token);
  }
  const res = response.data.map(({ _id, broaderNetwork, network }) => ({
    _id,
    broaderNetwork,
    network,
  })); // replace all response.data to res

  if (response.data[0].premium) {
    const { tiers, surcharges } = response.data[0].premium;
    const { hsa } = response.data[0];
    const hsaTiers = hsa && hsa.tiers ? hsa.tiers : [];

    yield put(Creators.loadInsuranceSelect(names[6], tiers));
    yield put(Creators.loadInsuranceSelect(names[7], surcharges));
    yield put(Creators.loadInsuranceSelect(names[8], hsaTiers));
  }

  const prev = plan.plansNetwork.prevValue;
  const valueCurrent = plan.plansNetwork.value;
  yield put(Creators.loadInsuranceSelect(names[2], res));
  yield put(Creators.changePrevValue(names[2], valueCurrent));
  if (prev && (res.find(({ _id }) => _id === prev) || []).length !== 0)
    yield put(Creators.changeInsurancePlan(names[2], prev));
  else if (res.length === 1) {
    yield put(Creators.changeInsurancePlan(names[2], res[0]._id));
  }
}

export function* loadGroup(token) {
  const userId = yield getUserId();
  const response = yield call(getGroupsInsurance, userId, token);
  const data = response.data;
  // data.unshift("Mine isn’t one of these");
  yield put(Creators.loadInsuranceSelect(names[0], data));
}

function* loadName(value, token, plan) {
  const prevValueNetwork = plan[names[1]].prevValue;
  yield put(Creators.changePrevValue(names[1], plan[names[1]].value));
  let response;

  const { pathname } = history.location;
  if (pathname === "/calculator") {
    const startDate = "2021-06-30";
    response = yield call(getPublicPlansInsurance, value, startDate);
  } else {
    response = yield call(getPlansInsurance, value, token);
  }
  const data = response.data;
  // data.unshift("Mine isn’t one of these");
  yield put(Creators.loadInsuranceSelect(names[1], data));
  if (prevValueNetwork && response.data.indexOf(prevValueNetwork) !== -1) {
    yield put(Creators.changeInsurancePlan(names[1], prevValueNetwork));
    yield call(changeIsDoctorPrev, plan);
    return;
  }
  yield call(changeIsDoctor, plan);
  return;
}

function* changeIsDoctor(plan) {
  yield put(Creators.changePrevValue(names[5], plan[names[5]].value));
  const value = plan.isDoctorInNetwork.data.length === 1 ? false : true;
  yield put(
    Creators.changeInsuranceIsNetwork(names[5], {
      value,
      isVisible: false,
    })
  );
}

function* changeIsDoctorPrev(plan) {
  const prevValue = plan[names[5]].prevValue;
  yield put(Creators.changePrevValue(names[5], plan[names[5]].value));
  const data = {
    isVisible: true,
    value: prevValue,
  };
  yield put(Creators.changeInsurancePlan(names[5], data));
}

// Partner

const partnerNames = [
  "partnerGroups", // 0
  "partnerInsurancePlans", // 1
  "partnerPlansNetwork", // 2
  "firstBaby", // 3
  "partnerInsurancePlanType", // 4
  "partnerIsDoctorInNetwork", // 5
  "partnerTiers", // 6
  "partnerSurcharges",
  "partnerHsa", // 8
  "partnerPremiumGroups",
];

export function* loadSelectOptionPartnerInsurance(name, value) {
  try {
    const token = yield getToken();
    const plan = yield select(getPlan);
    if (name === partnerNames[4]) {
      yield put(
        Creators.changePartnerInsuranceIsNetwork(partnerNames[5], {
          isVisible: true,
        })
      );
    } else if (name === partnerNames[0]) {
      yield call(loadPartnerName, value, token, plan);
    } else if (name === partnerNames[1]) {
      yield call(loadPartnerNetworkPlan, plan, value, token);
    }
  } catch (error) {
    yield removeTokenSaga(error);
  }
}

function* loadPartnerName(value, token, plan) {
  const prevValueNetwork = plan[partnerNames[1]].prevValue;
  yield put(
    Creators.changePrevValue(partnerNames[1], plan[partnerNames[1]].value)
  );

  const response = yield call(getPlansInsurance, value, token);
  const data = response.data;
  // data.unshift("Mine isn’t one of these");
  yield put(Creators.loadInsuranceSelect(partnerNames[1], data));

  if (prevValueNetwork && response.data.indexOf(prevValueNetwork) !== -1) {
    yield put(Creators.changeInsurancePlan(partnerNames[1], prevValueNetwork));
  }
  return;
}

// grab premium and hsa info from this
function* loadPartnerNetworkPlan(plan, value, token) {
  const response = yield call(
    getNetworkInsurance,
    plan.partnerGroups.value,
    value,
    token
  );

  // future changes response.data = [{plans, tiers, surcharges, hsaTiers}]
  const res = response.data.map(({ _id, broaderNetwork, network }) => ({
    _id,
    broaderNetwork,
    network,
  }));

  if (response.data[0].premium) {
    const { tiers, surcharges } = response.data[0].premium;
    const { hsa } = response.data[0];
    const hsaTiers = hsa && hsa.tiers ? hsa.tiers : [];

    yield put(Creators.loadInsuranceSelect(partnerNames[6], tiers));
    yield put(Creators.loadInsuranceSelect(partnerNames[7], surcharges));
    yield put(Creators.loadInsuranceSelect(partnerNames[8], hsaTiers));
  }
  const valueCurrent = plan.partnerPlansNetwork.value;
  yield put(Creators.changePrevValue(partnerNames[2], valueCurrent));
  yield put(Creators.loadInsuranceSelect(partnerNames[2], res));
}

// load both groups
export function* loadGroups(token) {
  let response;
  const startDate = "2021-06-30";

  const { pathname } = history.location;
  if (pathname === "/calculator") {
    response = yield call(getPublicGroupsInsurance, startDate);
  } else {
    const userId = yield getUserId();
    response = yield call(getGroupsInsurance, userId, token);
  }
  const data = response.data;

  yield put(Creators.loadInsuranceSelect(names[0], data));
  yield put(Creators.loadInsuranceSelect(partnerNames[0], data));
}

export function* loadGroupsPremium(token) {
  const userId = yield getUserId();
  const premiumResponse = yield call(getGroupsInsurance, userId, token, true);
  const premiumData = premiumResponse.data;

  yield put(Creators.loadInsuranceSelect(names[9], premiumData));
  yield put(Creators.loadInsuranceSelect(partnerNames[9], premiumData));
}

export function* loadSelectOptionPremiumInsurance(name, value) {
  try {
    const token = yield getToken();
    const plan = yield select(getPlan);
    if (name === names[3] && value === false) {
      yield put(Creators.changeInsurancePlan("insurancePlanType", "family"));
    } else if (name === names[4]) {
      yield put(
        Creators.changeInsuranceIsNetwork(names[5], { isVisible: true })
      );
    } else if (name === names[0]) {
      yield call(loadName, value, token, plan);
    } else if (name === names[1]) {
      yield call(loadNetworkPlan, plan, value, token);
    } else if (name === partnerNames[4]) {
      yield put(
        Creators.changePartnerInsuranceIsNetwork(partnerNames[5], {
          isVisible: true,
        })
      );
    } else if (name === partnerNames[0]) {
      yield call(loadPartnerName, value, token, plan);
    } else if (name === partnerNames[1]) {
      yield call(loadPartnerNetworkPlan, plan, value, token);
    }
  } catch (error) {
    yield removeTokenSaga(error);
  }
}
