import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import Actions from "store/plan/actions";

import { getProfile, getGroupById, getReport, getNetworksGrid, getRole, isProfessional } from "services/api";
import { getToken, getUserId } from "services/utils";

import { PlusOutlined } from "@ant-design/icons";
import { PageTemplate, Header, Footer, MyPlansCardsList } from "components";

const StickyButton = styled.div`
  display: flex;
  position: fixed;
  z-index: 1;
  bottom: 40px;
  right: 40px;
  width: 276px;
  height: 90px;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #21355b;
  border-radius: 30px;
  transition: 0.2s;
  padding: 0 40px 0 40px;

  :hover {
    color: white;
    background-color: #283e67;
    cursor: pointer;
  }
`;

const AlertAdminView = styled.div`
  width: 100%;
  font-size: 18px;
  text-align: center;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
  color: white;
  background-color: rgba(255, 11, 0, 1);
`;

const MyPlansPage = ({ ...props }) => {
  const { profileId } = props.match.params;
  const [list, setList] = useState([]);
  const [finishedGetRoleCall, setFinishedGetRoleCall] = useState(false);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [isProfessionalUser, setIsProfessionalUser] = useState(false);
  const dispatch = useDispatch();

  const roleCheck = async () => {
    const token = await getToken();
    const isAdminResponse = await getRole(token);
    setIsAdminUser(isAdminResponse.status === 200);
    const isProfessionalResponse = await isProfessional(token);
    setIsProfessionalUser(isProfessionalResponse.status === 200);
    setFinishedGetRoleCall(true);
  };

  useEffect(() => {
    document.title = "Predictabill";
    roleCheck();
  }, []);

  useEffect(() => {
    const loadGroupsAndReports = async () => {
      const userId = profileId && isAdminUser ? profileId : await getUserId();
      const token = await getToken();
      const { status, data } = await getProfile(userId, token);
      if (status === 200) {
        const { user } = data;
        const groupsList = user.groupsWithProfessionalAccess || [];
        const groups = [];
        for (let i = 0; i < groupsList.length; i++) {
          const result = await getGroupById({ id: groupsList[i] });
          result.status === 200 && groups.push(result.data.group);
        }
        const groupsWithReports = (
          await Promise.all(
            groups.map(async (group) => {
              return {
                ...group,
                reports: (
                  await Promise.all(
                    group.masterReports
                      .filter((reportId) => reportId && reportId !== "")
                      .filter((reportIdList) => reportIdList.length > 0)
                      .map(async (reportId) => {
                        const { status, data } = await getReport(reportId, token);
                        return status === 200 && data.report;
                      }),
                  )
                ).filter((report) => report != null && report.showOnMyPlans),
                networkGrids: (
                  await Promise.all(
                    group.masterNetworkGrids
                      .filter((networkGridId) => networkGridId && networkGridId !== "")
                      .filter((networkGridId) => !group.masterReports.includes(networkGridId))
                      .filter((networkGridIdList) => networkGridIdList.length > 0)
                      .map(async (networkGridId) => {
                        const { status, data } = await getNetworksGrid({ id: networkGridId });
                        return status === 200 && data.grid;
                      }),
                  )
                ).filter((grid) => grid != null && grid.showOnMyPlans),
              };
            }),
          )
        ).filter((group) => group.reports.length > 0 || group.networkGrids.length > 0);
        setList(
          groupsWithReports.map((group) => {
            const reports = group.reports.map((report) => ({ ...report, group }));
            const networkGrids = group.networkGrids.map((networkGrid) => ({ ...networkGrid, group }));
            const result = [...reports, ...networkGrids];
            return result;
          }),
        );
      }
    };

    loadGroupsAndReports();
  }, [finishedGetRoleCall]);

  if (finishedGetRoleCall && !isAdminUser && !isProfessionalUser) {
    window.location.pathname = "/recommendations";
    return null;
  }

  return (
    <PageTemplate
      header={
        <>
          <Header invert V2 />
        </>
      }
      footer={<Footer />}
    >
      {isAdminUser && profileId && <AlertAdminView>Viewing as Admin</AlertAdminView>}
      {list && (
        <MyPlansCardsList
          list={[...list]
            .flat()
            .sort(
              (a, b) =>
                new Date(b.fulfilledDate || b.updated_at || b.updatedAt) -
                new Date(a.fulfilledDate || a.updated_at || a.updatedAt),
            )}
        />
      )}
      <a href="/add-networks">
        <StickyButton
          onClick={() => {
            localStorage.removeItem("networksForm");
            dispatch(Actions.Creators.changeCurrent(0));
          }}
        >
          <PlusOutlined />
          <p style={{ margin: "0 0 0 15px" }}>CREATE NEW GROUP</p>
        </StickyButton>
      </a>
    </PageTemplate>
  );
};

export default MyPlansPage;
