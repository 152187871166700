import React from "react";
import { useFormikContext } from "formik";
import FormField from "components/molecules/FormField";
import SelectField from "components/molecules/SelectField";
import { surchargeWaiverFrequencyOptions } from "../utils/options";
import CheckboxField from "components/molecules/CheckboxField";
import { formatterNumber, parserNumber } from "utils/currency";
import { SpaceDivider } from "./utils";

export const CheckboxWithWaiverPayment = () => {
  const { values } = useFormikContext();
  const { withWaiverPayment, waiverTiersNumber, selectedWaiverTier } = values;

  const getWaiverTiersOptions = (num) => {
    const result = [];
    for (let i = 1; i <= num; i++) {
      result.push({ name: i, value: i });
    }
    return result;
  };

  const waiverTierValue = `waivers["${selectedWaiverTier}"].value`;
  const waiverTierFrequency = `waivers["${selectedWaiverTier}"].frequency`;

  return (
    <>
      <CheckboxField
        name="withWaiverPayment"
        label="There is a waiver payment if you opt out of coverage under this plan"
      />

      {withWaiverPayment && (
        <>
          <FormField
            name="waiverTiersNumber"
            type="number"
            label="# of waiver tiers"
            defaultValue={1}
            min={1}
          />

          <SelectField
            name="selectedWaiverTier"
            options={
              waiverTiersNumber ? getWaiverTiersOptions(waiverTiersNumber) : []
            }
            label={`Waiver Tier`}
            defaultValue={1}
          />

          <FormField
            name={waiverTierValue}
            type="number"
            label="Waiver payment value"
            defaultValue="0"
            formatter={formatterNumber}
            parser={parserNumber}
            required
          />
          <SelectField
            name={waiverTierFrequency}
            options={surchargeWaiverFrequencyOptions}
            label="Frequency"
            defaultValue={1 / 12}
          />
          <SpaceDivider />
        </>
      )}
    </>
  );
};
