import { put, call, delay, takeLatest } from "redux-saga/effects";
import Actions from "./actions";
import { loadTopDoctors } from "services/api";
import { sortByColumn, sortByHeader } from "services/utils/sortByColumn";

const {
  Types: { LOAD_TOP_DOCTORS_REQUEST },
  Creators: { loadTopDoctorsSuccess, loadTopDoctorsFailure }
} = Actions;

export function* watchLoadTopDoctors() {
  yield takeLatest(LOAD_TOP_DOCTORS_REQUEST, load);
}

function* load() {
  try {
    const response = yield call(loadTopDoctors);
    yield delay(200);

    const { headers, data, columnsCount, ...other } = response.data;
    const sortHeader = sortByColumn(headers, columnsCount, "order");

    const sortData = sortByHeader(sortHeader, data);

    yield put(
      loadTopDoctorsSuccess({
        headers: sortHeader,
        data: sortData,
        columnsCount,
        ...other
      })
    );
  } catch (err) {
    yield put(loadTopDoctorsFailure());
  }
}
