import React from "react";
import styled from "styled-components";
import {
  FooterTwitter,
  FooterNavigation as Navigation,
  FooterQuestions as Questions,
} from "components";

const Wrapper = styled.div`
  padding: 48px 48px 0;
  margin-bottom: 56px;
`;

const Band = styled.div`
  border-bottom: 1px solid #1c355e;
  margin-top: 40px;
`;

const Footer = (props) => {
  return (
    <Wrapper {...props}>
      {/* <FooterTwitter /> */}
      <Questions />
      <Band />
      <Navigation />
    </Wrapper>
  );
};

export default Footer;
