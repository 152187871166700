import React, { useState } from "react";
import styled from "styled-components";

import {
  CalculateHeader as Header,
  CalculateFormTemplate,
  FormSelect as Select,
  Text,
  ButtonLink,
  FormInput as Input,
  FormUpload as Upload,
  AntModalView as ModalView,
  Spinner,
  FormText,
} from "components";
import { uploadPamphlets } from "services/api/report.api";
import { message } from "antd";
import useModal from "services/hooks/useModal.hook";

const Wrapper = styled.div`
  padding: 20px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FlexWrapper = styled.div`
  display: flex;
  ${({ row }) =>
    row
      ? `flex-direction: row;
        justify-content: space-around;
        padding: 5px 10px;
        flex-wrap: wrap;
        `
      : `flex-direction: column;
        justify-content: center;
        `}

  ${({ left }) => (left ? `align-items: flex-start;` : `align-items: center;`)}
`;

const UploadPamphlets = ({
  plan,
  report,
  id,
  parent,
  names,
  name,
  setName,
  otherName,
  submit,
  handleNext,
  handleRedirect,
}) => {
  const [company, setCompany] = useState(parent.company);
  const [type, setType] = useState(false);
  const [pamphlets, setPamphlets] = useState([]);
  const [loading, setLoading] = useState(false);

  const { handleClick, isShow } = useModal();

  const options = [
    "Mine isn't one of these",
    "Insurance is through a school or union",
  ];

  const handleChange = ({ target: { name, value } }) => {
    setPamphlets([]);
    if (name === "name") {
      setName(value);
    } else {
      switch (value) {
        case options[0]:
          setCompany(null);
          setType("please enter company name");
          break;
        case options[1]:
          setCompany(null);
          setType("please enter school or union name");
          break;
        default:
          setCompany(value);
          setType(false);
          break;
      }
    }
  };

  const handleChangeInput = ({ target: { name, value } }) => {
    setCompany(value);
  };

  const handleUpload = (name, fileList) => {
    setPamphlets(fileList);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const formData = new FormData();
      pamphlets.forEach((file) => {
        formData.append("file", file, file.name);
      });
      formData.append("company", company);
      formData.append("parent", parent.parent);
      formData.append("name", name);
      formData.append("reportId", id);

      const response = await uploadPamphlets(formData);
      if (response.status === 200) {
        message.success("Success!");
        setLoading(false);
        handleNext();
      } else throw Error;
    } catch (error) {}
  };

  return (
    <>
      <ModalView
        close={handleClick}
        isShow={isShow}
        isInfo
        title="Why do we need to know this?"
      >
        <Text modal arial>
          Different companies, unions, or schools can purchase very different
          insurance plans for their employees, members, and students. So even if
          you have a United plan, JP Morgan employees could have a different
          United plan from Goldman Sachs employees. We want to get you the most
          accurate calculations, which is why we are asking!
        </Text>
      </ModalView>
      <Wrapper>
        <Header
          bigTitle={true}
          title={name ? name : "Upload your pamphlet"}
          text={parent.company ? parent.company : ""}
        />

        <FlexWrapper>
          <CalculateFormTemplate
            title=""
            activeKey={1}
            collapsible={false}
            noBorder={true}
          >
            {!parent.company && (
              <>
                {report.isDue === false && (
                  <>
                    <Text select>Who are you uploading pamphlets for?</Text>
                    <Select
                      selection={names.filter((name) => name !== otherName)}
                      name="name"
                      isSearch
                      handleChange={handleChange}
                      value={name}
                    />
                  </>
                )}

                {report.isDue && names.length === 1 && (
                  <>
                    <Text select>Who are you uploading pamphlets for?</Text>
                    <Select
                      selection={["Birth Parent", "Non-Birth Parent"]}
                      name="name"
                      isSearch
                      handleChange={handleChange}
                      value={name}
                    />
                  </>
                )}

                <br></br>

                <Text select>Select Company Name</Text>

                <Select
                  selection={[...options, ...plan.groups.data]}
                  name="company"
                  isSearch
                  handleChange={handleChange}
                  value={
                    type === "please enter company name"
                      ? "Mine isn't one of these"
                      : type === "please enter school or union name"
                      ? "Insurance is through a school or union"
                      : company
                  }
                />
                {!company && !type && (
                  <FormText
                    small="true"
                    center
                    text='Select "Marketplace" if you do not have a company, school or union'
                  />
                )}

                <br></br>

                {type && (
                  <Input
                    placeholder={type}
                    name="company"
                    handleChange={handleChangeInput}
                  />
                )}

                <br></br>

                {!company && !type && (
                  <ButtonLink
                    button
                    textAlign
                    withBorder
                    fontSize="18"
                    onClick={handleClick}
                  >
                    Why do we need to know this?
                  </ButtonLink>
                )}
              </>
            )}

            {(parent.company || company) && (
              <>
                {process.env.REACT_APP_COMPLETE_COMPANIES.includes(company) ? (
                  <Text select>
                    Easy! We already have their insurance plans in our systems
                  </Text>
                ) : company === "There is only one parent with insurance" ? (
                  <Text select>Got it, that makes things a bit simpler!</Text>
                ) : process.env.REACT_APP_COMPLETE_COMPANIES.includes(
                    parent.company
                  ) ||
                  (parent.generalIsComplete &&
                    parent.premiumIsComplete &&
                    parent.hsaIsComplete) ? (
                  <Text select>No Uploads Necessary</Text>
                ) : (
                  <>
                    <FormText
                      text="Upload any pamphlets you've received for your enrollment period including where it shows what you have to pay for each option (you can take a photo with your phone if you only have a paper version)."
                      small="true"
                      center
                    />

                    <br></br>

                    <Upload width={300} handleChange={handleUpload} />
                  </>
                )}
                <br></br>
                <br></br>
                {loading ? (
                  <Spinner center />
                ) : (
                  <ButtonLink
                    textAlign
                    withBG
                    button
                    fontSize="18"
                    onClick={handleSubmit}
                  >
                    {submit}
                  </ButtonLink>
                )}

                <br></br>
                <br></br>
                <br></br>

                <ButtonLink
                  textAlign
                  withBG
                  button
                  fontSize="18"
                  onClick={handleRedirect}
                >
                  Do this later
                </ButtonLink>
              </>
            )}
          </CalculateFormTemplate>
        </FlexWrapper>
      </Wrapper>
    </>
  );
};

export default UploadPamphlets;
