import { put, call, take, cancel, takeLatest, fork, delay, takeLeading } from "redux-saga/effects";
import { loginUser, registerUser, resetPassword, getUserByToken, getGroupById } from "services/api";
import authActions from "./actions";
import planActions from "../plan/actions";
import customActions from "../custom/actions";
import profileActions from "../profile/actions";
import { loadProfile } from "../profile/sagas";
import { resetStoreForm } from "store/formData/sagas";
import { getToken, setToken, removeToken, isValidToken, isEmployerCustomerEmail } from "services/utils";
import { EventOne, handleGA } from "services/google";
import history from "../history";
const { Types, Creators } = authActions;
const { Creators: PlanCreators } = planActions;
const { Creators: ProfileCreators } = profileActions;
const { Creators: CustomCreators } = customActions;

export function* loginFlow() {
  yield takeLatest(Types.LOGIN_REQUEST, function* ({ email, password, destinationRoute }) {
    const task = yield fork(authorize, email, password, destinationRoute);
    const action = yield take([Types.LOGIN_SUCCESS, Types.LOGIN_FAILURE]);
    if (action.type === Types.LOGIN_FAILURE) {
      yield cancel(task);
    }
  });
}

export function* loadFlow() {
  yield takeLatest(Types.LOAD_REQUEST, load);
}

export function* navigate(email) {
  const domainName = isEmployerCustomerEmail(email);
  const formType = domainName ? "f2LaZfN6" : "lVuTDexM";
  const url = domainName
    ? `https://predictabill.typeform.com/${domainName}#work=${email}`
    : `https://predictabill.typeform.com/to/lVuTDexM#email=${email}`;
  yield call(history.push, "/typeform/signup", { formType, email, url });
}

function* handleLoginRedirect(role, currentReport, groupsWithProfessionalAccess, destinationRoute) {
  if (role === "professional") {
    if ((groupsWithProfessionalAccess && groupsWithProfessionalAccess.length === 0) || !groupsWithProfessionalAccess) {
      yield call(history.push, "/add-networks");
    } else if (groupsWithProfessionalAccess.length > 1) {
      yield call(history.push, "/my-plans");
    } else if (groupsWithProfessionalAccess.length === 1) {
      const { status, data } = yield call(getGroupById, { id: groupsWithProfessionalAccess[0] });
      if (status !== 200) {
        yield call(history.push, "/add-networks");
      } else if (data.group.masterReports && data.group.masterReports.length > 1) {
        yield call(history.push, "/my-plans");
      } else if (data.group.masterReports && data.group.masterReports.length === 1) {
        yield call(history.push, `/my-plans/${data.group.name}/${data.group.masterReports[0]}`);
      } else if (
        (!data.group.masterReports || data.group.masterReports.length === 0) &&
        data.group.masterNetworkGrids?.length > 0
      ) {
        yield call(history.push, `/networks/${data.group.masterNetworkGrids[0]}`);
      }
    }
  } else if (currentReport) {
    if (destinationRoute === "/onboarding" || destinationRoute === "/self-serve") {
      yield call(history.push, destinationRoute);
    } else {
      yield call(history.push, "/recommendations");
    }
  } else {
    const { location } = history;
    const { pathname } = location;
    if (pathname !== "/add-networks") {
      if (!role || role === "") {
        yield call(history.push, "/groups");
      }
    }
  }
}

function* authorize(email, password, destinationRoute) {
  try {
    yield delay(300);
    const response = yield call(loginUser, email, password);
    const { username, isBeta, token, currentReport, role, groupsWithProfessionalAccess } = response.data;

    handleGA(isBeta);
    EventOne("Onboarding", "Log In", "Log In");
    setToken(token);
    yield put(Creators.loginSuccess(username, token));
    const signinBackToTypeform = localStorage.getItem("signinBackToTypeform");
    if (signinBackToTypeform) {
      const { url, primary, group, addEmployer } = JSON.parse(signinBackToTypeform);
      localStorage.setItem(
        "signinBackToTypeform",
        JSON.stringify({
          url,
          primary,
          auth: true,
          group,
          addEmployer,
        }),
      );
      yield call(history.push, "/typeform/new-recommendation");
    } else {
      yield call(handleLoginRedirect, role, currentReport, groupsWithProfessionalAccess, destinationRoute);
    }
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    yield put(Creators.loginFailure(errorMessage));
    return error;
  }
}

export function* registerFlow() {
  yield takeLatest(Types.REGISTER_REQUEST, function* ({ form, destinationRoute }) {
    const task = yield fork(register, form, destinationRoute);
    const action = yield take([Types.REGISTER_SUCCESS, Types.REGISTER_FAILURE]);
    if (action.type === Types.REGISTER_FAILURE) {
      yield cancel(task);
    }
  });
}

function* register(form, destinationRoute) {
  try {
    yield delay(300);
    const { email } = form;
    const response = yield call(registerUser, form);
    const { username, isBeta, token } = response.data;
    handleGA(isBeta);
    setToken(token);
    yield call(history.push, "/recommendations");
  } catch (error) {
    const errorMessage = error.response ? error.response.data : error.message;
    yield put(Creators.registerFailure(errorMessage));
    return error;
  }
}

export function* resetPasswordFlow() {
  yield takeLatest(Types.RESET_PASSWORD_REQUEST, function* ({ password, resetToken }) {
    const task = yield fork(reset, password, resetToken);
    const action = yield take([Types.LOGIN_SUCCESS, Types.RESET_PASSWORD_FAILURE]);
    if (action.type === Types.RESET_PASSWORD_FAILURE) {
      yield cancel(task);
    }
  });
}

export function* getUserByTokenFlow() {
  yield takeLatest(Types.GET_USER_BY_TOKEN, getUserByTokenCall);
}

function* getUserByTokenCall(payload) {
  try {
    yield delay(300);
    const response = yield call(getUserByToken, payload.resetToken);
    if (!response.data) yield call(history.push, "/signin");
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    yield put(Creators.resetPasswordFailure(errorMessage));
    return error;
  }
}

function* reset(password, resetToken) {
  try {
    yield delay(300);
    const response = yield call(resetPassword, password, resetToken);
    const { username, token, currentReport, role, groupsWithProfessionalAccess } = response.data;
    setToken(token);
    yield put(Creators.loginSuccess(username, token));
    yield call(handleLoginRedirect, role, currentReport, groupsWithProfessionalAccess);
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error.message;
    yield put(Creators.resetPasswordFailure(errorMessage));
    return error;
  }
}

export function* load() {
  const token = yield getToken();
  const isValid = yield call(isValidToken, token);
  if (!isValid) {
    yield put(Creators.loadFailure());
  } else {
    yield put(Creators.loadSuccess());
    yield call(loadProfile, token);
  }
}

export function* logout() {
  yield takeLeading(Types.LOGOUT, function* () {
    yield removeToken();
    yield put(Creators.removeToken());
    yield put(PlanCreators.resetStorePlan());
    yield put(CustomCreators.resetStoreCustom());
    yield put(ProfileCreators.resetStoreProfile());
    yield call(resetStoreForm);
    yield call(history.push, "/signin");
  });
}

export function* removeTokenSaga(error) {
  if (error && error.response && error.response.status === 401) {
    yield call(removeToken);
    yield put(Creators.removeToken());
    yield put(PlanCreators.resetStorePlan());
    yield put(ProfileCreators.resetStoreProfile());
    yield call(resetStoreForm);
  }
}

export function* removeTokenWithoutToken() {
  yield put(Creators.removeToken());
}
