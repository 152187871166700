export const OutOfNetworkCoverageOptions = [
  {
    value: "yes",
    name: "Yes",
  },
  {
    value: "no",
    name: "No, it's in-network only",
  },
];

export const yesOrNoOptions = [
  {
    value: "yes",
    name: "Yes",
  },
  {
    value: "no",
    name: "No",
  },
];

export const yesOrNoHealthOptions = [
  {
    value: "no",
    name: "No, they don't contribute anything",
  },
  {
    value: "HSA",
    name: "Yes, an HSA (Health Savings Account)",
  },
  {
    value: "HRA",
    name: "Yes, an HRA (Health Reimbursement Acccount)",
  },
  {
    value: "both",
    name: "Yes, both an HSA and an HRA",
  },
];

export const familyTypeOptions = [
  {
    value: "Embedded",
    name: "Embedded",
  },
  {
    value: "Aggregated",
    name: "Aggregate",
  },
];

export const copayOptions = [
  {
    value: "stay",
    name: "stay",
  },
  {
    value: "night",
    name: "night",
  },
];

export const haveToPayOptions = [
  {
    value: "coinsurance",
    name: "Coinsurance",
  },
  {
    value: "copay",
    name: "Copay",
  },
  {
    value: "both",
    name: "Copay and Coinsurance",
  },
];

export const expectedUnexpectedOptions = [
  {
    value: "expected",
    name: "Expected",
  },
  {
    value: "unexpected",
    name: "Unexpected",
  },
];

export const forOptions = [
  {
    value: "self",
    name: "Self",
  },
  {
    value: "spouse",
    name: "Spouse",
  },
  {
    value: "child",
    name: "Child",
  },
];

export const networkOptions = [
  {
    value: "inNetwork",
    name: "In-Network",
  },
  {
    value: "outOfNetwork",
    name: "Out-of-Network",
  },
];

export const frequencyOptions = [
  {
    value: "once",
    name: "once",
  },
  {
    value: "weekly",
    name: "weekly",
  },
  {
    value: "bi-weekly",
    name: "bi-weekly",
  },
  {
    value: "monthly",
    name: "monthly",
  },
  {
    value: "quarterly",
    name: "quarterly",
  },
  {
    value: "semiannual",
    name: "semi-annual",
  },
  {
    value: "annual",
    name: "annual",
  },
  {
    value: "other frequency",
    name: "other frequency",
  },
];

export const drugsFrequencyOptions = [
  {
    value: "monthly",
    name: "Monthly (30-Day Supply)",
  },
  {
    value: "quarterly",
    name: "Quarterly (90-Day Supply)",
  },
];

export const serviceOptions = [
  {
    value: "Inpatient Hospital Stay",
    name: "Inpatient Hospital Stay",
  },
  {
    value: "Therapy",
    name: "Therapy",
  },
  {
    value: "Primary Care Sick Visit",
    name: "Primary Care Sick Visit",
  },
  {
    value: "Preventive Care",
    name: "Preventive Care",
  },
  {
    value: "Specialist Visit",
    name: "Specialist Visit",
  },
  {
    value: "X-Ray",
    name: "X-Ray",
  },
  {
    value: "Labwork",
    name: "Labwork",
  },
  {
    value: "CT Scan",
    name: "CT Scan",
  },
  {
    value: "PET Scan",
    name: "PET Scan",
  },
  {
    value: "MRI",
    name: "MRI",
  },
  {
    value: "Fertility Services (IVF, Egg Freezing)",
    name: "Fertility Services (IVF, Egg Freezing)",
  },
  {
    value: "Outpatient Surgery Facility Fee",
    name: "Outpatient Surgery Facility Fee",
  },
  {
    value: "Outpatient Surgery Physician Fees",
    name: "Outpatient Surgery Physician Fees",
  },
  {
    value: "ER Care",
    name: "ER Care",
  },
  {
    value: "Emergency Medical Transportation (Ambulance)",
    name: "Emergency Medical Transportation (Ambulance)",
  },
  {
    value: "Urgent Care",
    name: "Urgent Care",
  },
  {
    value: "Inpatient Surgery Physician Fees",
    name: "Inpatient Surgery Physician Fees",
  },
  {
    value: "Psychotherapy Office Visit",
    name: "Psychotherapy Office Visit",
  },
  {
    value: "Mental Health Inpatient Services",
    name: "Mental Health Inpatient Services",
  },
  {
    value: "Home Health Care",
    name: "Home Health Care",
  },
  {
    value: "Rehabilitation Services (PT, OT, Chiropractor)",
    name: "Rehabilitation Services (PT, OT, Chiropractor)",
  },
  {
    value: "Skilled Nursing Care",
    name: "Skilled Nursing Care",
  },
  {
    value: "Durable Medical Equipment",
    name: "Durable Medical Equipment",
  },
  {
    value: "Hospice Services",
    name: "Hospice Services",
  },
  {
    value: "Children's Eye Exam",
    name: "Children's Eye Exam",
  },
  {
    value: "Outpatient Ambulatory Surgery Center Facility Fee",
    name: "Outpatient Ambulatory Surgery Center Facility Fee",
  },
  {
    value: "Other Service",
    name: "Other Service",
  },
];
