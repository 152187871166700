import React from "react";
import { Text } from "components";
import styled, { css } from "styled-components";
import { Icon } from "@ant-design/compatible";
import { Link as ReactLink } from "react-router-dom";

const FlexWrapper = styled.div`
  display: flex;
  ${({ row }) =>
    row
      ? css`
          width: 100%;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          padding: 0 20px;
        `
      : css`
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 0 0 0 20px;
        `};
`;

const StyledReactLink = styled(ReactLink)`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ orange }) =>
    orange
      ? css`
          background: rgb(249, 227, 213);
          border: 3px solid rgb(198, 88, 28);
        `
      : css`
          background: #d9f3f1;
          border: 3px solid #00a19b;
        `}
  border-radius: 50px;
  width: 270px;
  height: 125px;
  margin: 20px;

  :hover {
    opacity: 0.5;
  }
`;

const NavigationButton = ({ to, icon, text, subText, orange, seen }) => {
  return (
    <StyledReactLink to={to} orange={orange}>
      <FlexWrapper row>
        <Icon
          type={icon}
          style={{
            color: orange ? "#c6581c" : "#00a19b",
            fontSize: "60px",
            padding: "0 10px",
          }}
        />
        <FlexWrapper>
          <Text
            select
            bolder
            shadow
            small
            green={orange || seen ? false : true}
            orange={orange}
            blue={seen}
          >
            {text}
          </Text>
          <Text select green={orange ? false : true} orange={orange}>
            {subText}
          </Text>
        </FlexWrapper>
      </FlexWrapper>
    </StyledReactLink>
  );
};

export default NavigationButton;
