import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  FormText,
  CalculateFormTemplate,
  FormSelect as Select,
  InNetworkModal,
} from "components";
import { strToBoolTrue, strToBoolFalse } from "services/utils";
import useModal from "services/hooks/useModal.hook";

const Dropdown = styled.div`
  display: none;

  @media (max-width: 480px) {
    display: block;
  }
`;

const CalculateBirthPlan = ({ birth, changePlan, changeOptions, ...props }) => {
  const { handleClick, isShow } = useModal();
  const [state, setState] = useState(true);

  const handleChange = ({ target: { name, value } }) => {
    if (strToBoolTrue(value)) changeOptions(name, true);
    else if (strToBoolFalse(value)) changeOptions(name, false);
    else changeOptions(name, value);
  };

  const handleChangePlan = ({ target: { name, value } }) => {
    if (name === "doctors") handleClick();
    changePlan(name, value);
  };

  useEffect(() => {
    const dropdown = document.getElementById("mobile");
    if (dropdown && getComputedStyle(dropdown).display === "block") {
      setState(false);
    } else {
      setState(true);
    }
  }, []);

  const renderFull = () => {
    return (
      <>
        {props.doctors.isVisible && (
          <Select
            text={`Select a Doctor (or Practice)`}
            selection={props.doctors.data}
            selectObj="_id"
            newStyle
            select="name"
            isSearch
            name="doctors"
            handleChange={handleChangePlan}
            value={props.doctors.value}
          />
        )}

        {props.hospitals.isVisible && (
          <Select
            newStyle
            text={`Select a Hospital`}
            selection={props.hospitals.data}
            selectObj="_id"
            select="name"
            isSearch
            name="hospitals"
            handleChange={handleChangePlan}
            value={props.hospitals.value}
          />
        )}

        {props.deliveryTypes.isVisible && (
          <>
            <Select
              text="Type of Delivery"
              newStyle
              selection={props.deliveryTypes.data}
              name="deliveryTypes"
              handleChange={handleChange}
              value={props.deliveryTypes.value}
            />
            {props.roomTypes.data.length > 0 && (
              <Select
                text="Shared or Private Room in Hospital?"
                newStyle
                selection={props.roomTypes.data}
                selectObj="value"
                select="name"
                name="roomTypes"
                handleChange={handleChange}
                value={props.roomTypes.value}
              />
            )}
          </>
        )}

        {props.roomTypes.value &&
          props.hospitals.current &&
          props.hospitals.current.deluxe && (
            <Select
              text="Deluxe Room?"
              newStyle
              selection={props.deluxe.data}
              isBool
              name="deluxe"
              handleChange={handleChange}
              value={props.deluxe.value}
            />
          )}

        {props.hospitals.current && props.roomTypes.value && (
          <FormText
            text="Private Room Cost (Not Covered by Insurance)"
            circular
            currency
            center
            value={costRoom(
              props.roomTypes.value,
              props.deluxe.value,
              props.hospitals.current
            )}
            prevText={props.hospitals.current.upTo && "Up to"}
            textValue="per night"
          />
        )}

        {props.doctors.practice && props.doctors.practice.name && (
          <FormText
            center
            circular
            text="Selected Practice"
            value={props.doctors.practice.name}
          />
        )}

        {props.hospitals.current &&
          props.hospitals.current.name &&
          props.hospitals.value !== "all" && (
            <FormText
              center
              circular
              text="Affiliated Hospital"
              value={props.hospitals.current.name}
            />
          )}
      </>
    );
  };

  return (
    <>
      <InNetworkModal isShow={isShow} handleClick={handleClick} />
      <CalculateFormTemplate
        title="Birth Plan Options"
        activeKey={state ? 1 : 2}
        borderShadow
        noBorder
        headerHeight={"50px"}
      >
      <>
        <Select
          newStyle
          text="Search by Doctor or by Hospital?"
          selection={props.planNames.data}
          name="planNames"
          handleChange={handleChangePlan}
          value={props.planNames.value}
          />
        {props.planNames.value && renderFull()}
      </>
      </CalculateFormTemplate>
      <Dropdown id="mobile"></Dropdown>
    </>
  );
};

const costRoom = (privateRoom, deluxe, hospitalCurrent) => {
  return privateRoom && !deluxe
    ? hospitalCurrent.privateRoomRate
    : hospitalCurrent.deluxe;
};

export default React.memo(CalculateBirthPlan);
