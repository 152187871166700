import { put, call, take, cancel, select, takeLeading, delay, takeLatest, fork } from "redux-saga/effects";
import Actions from "./actions";

import { getProfile, getReportsHeader, getDocumentById, uploadPamphlets, getRole } from "services/api";
import { getToken, isValidToken, getUserId } from "services/utils";
import { EventOne } from "services/google";

import { loadGroups } from "../plan/workflow.sagas";
import { navigate, removeTokenSaga, removeTokenWithoutToken } from "../auth/sagas";

import history from "../history";

const {
  Types: {
    LOAD_PROFILE_REQUEST,
    UPLOAD_PAMPHLETS_REQUEST,
    UPLOAD_PAMPHLETS_SUCCESS,
    UPLOAD_PAMPHLETS_FAILURE,
    LOAD_DOCUMENT_BY_ID_REQUEST,
  },
  Creators,
} = Actions;

const getReports = (state) =>
  state.profile.currentReport ? [...state.profile.reports, state.profile.currentReport._id] : state.profile.reports;

export function* watchLoadProfile() {
  yield takeLatest(LOAD_PROFILE_REQUEST, function* ({ profileId, navigateToProfile }) {
    try {
      const token = yield call(getToken);
      const isValid = yield call(isValidToken, token);
      if (!isValid) {
        yield call(removeTokenWithoutToken);
      } else {
        yield call(loadGroups, token);
        yield call(loadProfile, token, profileId, navigateToProfile);
        yield loadReportsSummary();
      }
    } catch (error) {
      yield call(removeTokenSaga, error);
      yield put(Creators.loadProfileFailure());
    }
  });
}

export function* loadProfile(token, profileId, navigateToProfile) {
  const userId = yield call(getUserId);
  let selectedId = userId;

  if (profileId) {
    const { status } = yield call(getRole, token);
    if (userId === profileId || status !== 200) navigateToProfile();
    else selectedId = profileId;
  }

  try {
    delay(300);
    let response;
    response = yield call(getProfile, selectedId, token);
    if (response.status !== 200 && selectedId !== userId) {
      response = yield call(getProfile, userId, token);
      navigateToProfile();
    }

    const { user, report } = response.data;
    const { currentReport, username: email, role, groupsWithProfessionalAccess } = user;
    yield put(Creators.loadProfileSuccess(user, report));
    /*<< TO REMOVE */
    const { location } = history;
    const { pathname } = location;
    if (pathname !== "/add-networks") {
      if ((!role || role === "") && !currentReport) {
        yield call(history.push, "/groups");
      } else if (role === "professional") {
        if (
          (groupsWithProfessionalAccess && groupsWithProfessionalAccess.length === 0) ||
          !groupsWithProfessionalAccess
        ) {
          yield call(history.push, "/add-networks");
        }
      }
    }
    /* TO REMOVE >>*/

    // yield fork(navigate, email);
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data : error.message;
    yield put(Creators.loadProfileFailure(errorMessage));
    return error;
  }
}

function* loadReportsSummary() {
  delay(100);
  const reportIds = yield select(getReports);
  if (reportIds.length === 0) return;
  yield put(Creators.loadReportsHeaderRequest(reportIds));
  try {
    const token = yield getToken();
    const response = yield call(getReportsHeader, reportIds, token);
    if (response.data) {
      yield put(Creators.loadReportsHeaderSuccess(response.data));
    } else yield put(Creators.loadReportsHeaderFailure());
  } catch (error) {
    yield put(Creators.loadReportsHeaderFailure(true));
    yield removeTokenSaga(error);
  }
}

export function* watchUpdatePamphlets() {
  yield takeLatest(UPLOAD_PAMPHLETS_REQUEST, function* ({ data }) {
    const task = yield fork(updatePamphlets, data);
    const action = yield take([UPLOAD_PAMPHLETS_SUCCESS, UPLOAD_PAMPHLETS_FAILURE]);
    if (action.type === UPLOAD_PAMPHLETS_FAILURE) {
      yield cancel(task);
    }
  });
}

function* updatePamphlets(data) {
  try {
    yield delay(300);
    const response = yield call(uploadPamphlets, data);
    yield put(Creators.updatePamphletsSuccess(response.data));
    return response;
  } catch (error) {
    yield put(Creators.updatePamphletsFailure());
    return error;
  }
}

export function* watchLoadDocumentById() {
  yield takeLeading(LOAD_DOCUMENT_BY_ID_REQUEST, function* ({ documentType, documentId }) {
    const token = yield getToken();
    yield fork(EventOne, "Documents", "View document details");
    try {
      const response = yield call(getDocumentById, documentType, documentId, token);
      if (response.data) {
        yield delay(500);
        yield put(Creators.loadDocumentByIdSuccess(response.data));
      } else yield put(Creators.loadDocumentByIdFailure());
    } catch (error) {
      yield removeTokenSaga(error);
    }
  });
}
