import React from "react";
import styled from "styled-components";
import { Text } from "components";
import { Icon } from "@ant-design/compatible";

const Wrapper = styled.div`
  color: #123;
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 85%;
  padding: 20px 50px;
  margin: 0 auto;
  margin-top: 50px;


  @media (max-width: 480px) {
    margin: 75px auto 0 auto;
    padding: 20px 0;
    max-width: 100%;
  }
`

const Title = styled.h1`
  font-family: "publico";
  font-weight: bold;
  text-align: center;
  font-size: 50px;
  line-height: 1.2em;
  color: #1c355e;
  
  @media (max-width: 480px) {
    font-size: 1.2em;
    padding: 1px 2px;
  }
`
const SubWrapper = styled.div`
  padding: 0 10px;
  margin-bottom: 20px;
`

const SubTitle = styled.h3`
  text-align: center;
  color: #1c355e;
  font-size: 16px;
  font-family: circular;
  
  @media (max-width: 480px) {
    font-size: .6em;
    padding: 1px 2px;
  }
`

const Link = styled.a`
  color: #377B78;
  font-weight: bold;
`


const SerfiHeader = ({ title, subTitle, linkText, linkRef }) =>
  <Wrapper>
    <Title>
      {title}
    </Title>
    <SubWrapper>
      <SubTitle> 
        {subTitle} 
        {
          linkRef &&
          <Link href={linkRef}>{" "+linkText}</Link>
        }
      </SubTitle>
    </SubWrapper>
  </Wrapper>

export default SerfiHeader;


