import { createReducer } from "reduxsauce";
import Actions from "./actions";
import { assign, merge } from "lodash";

const { Types } = Actions;
const INITIAL_STATE = {
  header: {
    date: "",
    procedures: "",
    insuranceRates: "",
    chargesWithInsurance: "",
    chargesPayCompletely: ""
  },
  body: [],
  isSelectAll: false,
  isDisabled: true,
  ids: [],
  currentBill: {},
  isRequesting: false,
  isRequestingBills: false
};

const success = (state = INITIAL_STATE, { records }) => {
  const bills = records.map(bill => {
    bill.isChecked = true;
    return bill;
  });
  const isSelectAll =
    state.body.length > 0 ? (state.isSelectAll ? true : false) : true;
  return {
    ...state,
    body: bills,
    isSelectAll,
    isDisabled: false,
    isRequestingBills: false
  };
};

const successHeader = (state = INITIAL_STATE, { payload }) => {
  const header = {
    ...payload
  };
  return {
    ...state,
    header,
    isDisabled: false
  };
};

const changeAllSelect = (state = INITIAL_STATE) => {
  const isSelectAll = !state.isSelectAll;
  let body;
  if (isSelectAll) {
    body = state.body.map(bill => {
      bill.isChecked = true;
      return bill;
    });
  }
  return merge({}, state, { isSelectAll, body });
};

const checkedBill = (state = INITIAL_STATE, { idBill }) => {
  const body = state.body.map(bill => {
    if (bill._id === idBill) {
      let isCheck = bill.isChecked;
      bill.isChecked = !isCheck;
    }
    return bill;
  });
  return assign({}, state, { body });
};

const requestLoadBill = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true
  };
};

const successLoadBill = (state = INITIAL_STATE, { bill }) => {
  return {
    ...state,
    currentBill: bill,
    isRequesting: false
  };
};

const reset = (state = INITIAL_STATE, { name, value }) => {
  const bills = state.body;
  if (name === "header" && state.body.length > 0) {
    return {
      ...state,
      isDisabled: bills.length > 0 ? false : true
    };
  }
  return {
    ...state,
    [name]: value,
    isDisabled: bills.length > 0 ? false : true
  };
};

const failureHeader = (state = INITIAL_STATE, { isAll }) => {
  if (isAll)
    return {
      ...state,
      header: {}
    };
  return merge({}, state, {
    header: {}
  });
};

const request = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequestingBills: true,
    header: {},
    body: []
  };
};

const failure = (state = INITIAL_STATE) => {
  return {
    ...state,
    header: {},
    body: []
  };
};

export const HANDLERS = {
  [Types.LOAD_BILLS_SUCCESS]: success,
  [Types.LOAD_BILLS_REQUEST]: request,
  [Types.LOAD_BILLS_FAILURE]: failure,
  [Types.LOAD_BILLS_HEADER_REQUEST]: failureHeader,
  [Types.LOAD_BILLS_HEADER_SUCCESS]: successHeader,
  [Types.LOAD_BILLS_HEADER_FAILURE]: failureHeader,
  [Types.CHANGE_ALL_SELECT]: changeAllSelect,
  [Types.CHECKED_BILL]: checkedBill,
  [Types.CHECKED_BILL]: checkedBill,
  [Types.LOAD_BILL_BY_ID_SUCCESS]: successLoadBill,
  [Types.LOAD_BILL_BY_ID_REQUEST]: requestLoadBill,
  [Types.RESET_STORE_BILL]: reset
};

const reducer = createReducer(INITIAL_STATE, HANDLERS);

export default reducer;
