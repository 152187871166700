import React, { useState } from "react";
import styled from "styled-components";
import { TableRow, TableCell, Link, Text } from "components";
import { Icon } from "@ant-design/compatible";
import { getDocumentById } from "services/api/report.api";
import { getToken } from "services/utils";

const Rotater = styled(Link)`
  float: left;
  margin-left: 0;
  transition: all 0.8s ease;
  transform: ${(state) => (state.show ? `rotate(-180deg)` : `rotate(0deg)`)};
`;

const FadeRow = styled(TableRow)`
  animation: ${(state) => state.show && `fade-in`} 0.5s ease alternate;

  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

const UploadTableBody = ({ report }, i) => {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    const isShow = !show;
    setShow(isShow);
  };

  const handleView = async (pamphlet) => {
    const token = await getToken();
    getDocumentById(pamphlet.id, token).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${pamphlet.filename}`);
      document.body.appendChild(link);
      link.click();
    });
  };

  const renderPamphlets = (pamphlet, i) => {
    return (
      <FadeRow
        key={pamphlet.id}
        show={show}
        onClick={() => handleView(pamphlet)}
      >
        <TableCell />
        <TableCell center>
          <Text arial italic>
            {pamphlet.type > 0 && pamphlet.type !== 4
              ? "complete"
              : pamphlet.type === 0
              ? "pending"
              : "not needed"}
          </Text>
        </TableCell>
        <TableCell short width="125px">
          <Text>
            {i + 1} <Link green>{pamphlet.filename}</Link>
          </Text>
        </TableCell>
        <TableCell center>
          {pamphlet.type.toString().includes("1") && (
            <Text arial lg>
              √
            </Text>
          )}
        </TableCell>
        <TableCell center>
          {pamphlet.type.toString().includes("2") && (
            <Text arial lg>
              √
            </Text>
          )}
        </TableCell>
        <TableCell center>
          {pamphlet.type.toString().includes("3") && (
            <Text arial lg>
              √
            </Text>
          )}
        </TableCell>
      </FadeRow>
    );
  };

  const uploadIsComplete =
    report.generalIsComplete &&
    report.premiumIsComplete &&
    report.hsaIsComplete;

  return (
    <React.Fragment key={i}>
      <TableRow>
        <TableCell
          ms
          orange={!uploadIsComplete}
          className="mobile-hide"
          title={report.company}
        >
          {uploadIsComplete ? (
            <Text arial bolder>
              {report.company}
            </Text>
          ) : (
            <Text bolder orange>
              {report.company}
            </Text>
          )}
        </TableCell>
        <TableCell center orange={!uploadIsComplete} title="status">
          <Text arial>
            {uploadIsComplete ? (
              <Text bolder>complete</Text>
            ) : (
              <Text bolder orange>
                incomplete
              </Text>
            )}
          </Text>
        </TableCell>
        <TableCell
          width="125px"
          short
          title="documents"
          className="mobile-hide"
        >
          {report.pamphlet.length ? (
            <Text arial>
              <Rotater green show={show} onClick={handleShow}>
                <Icon type="down" />
              </Rotater>
              {report.pamphlet.length} document(s)
            </Text>
          ) : uploadIsComplete && report.pamphlet.length === 0 ? (
            <Text arial italic>
              No user upload necessary
            </Text>
          ) : (
            <Text arial>
              <Icon style={{ visibility: "hidden" }} type="down" /> 0 documents
            </Text>
          )}
        </TableCell>
        <TableCell
          center
          orange={!report.generalIsComplete}
          title="planDesignStatus"
          className="mobile-hide"
        >
          {report.generalIsComplete ? (
            <Text arial lg>
              √
            </Text>
          ) : (
            <Text lg orange>
              <Icon type="close" />
            </Text>
          )}
        </TableCell>
        <TableCell
          center
          orange={!report.premiumIsComplete}
          title="premiumStatus"
          className="mobile-hide"
        >
          {report.premiumIsComplete ? (
            <Text arial lg>
              √
            </Text>
          ) : (
            <Text lg orange>
              <Icon type="close" />
            </Text>
          )}
        </TableCell>
        <TableCell
          center
          orange={!report.hsaIsComplete}
          title="hsaStatus"
          className="mobile-hide"
        >
          {report.hsaIsComplete ? (
            <Text arial lg>
              √
            </Text>
          ) : (
            <Text lg orange>
              <Icon type="close" />
            </Text>
          )}
        </TableCell>
      </TableRow>
      {show && report.pamphlet.map(renderPamphlets)}
    </React.Fragment>
  );
};

export default UploadTableBody;
