import React from "react";
import styled from "styled-components";
import Text from "components/atoms/Text";
import { Radio } from "antd";

const Wrapper = styled.div`
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${(props) => (props.step === 0 ? "none" : " translateX(-100%)")};
  opacity: ${(props) => (props.step === 0 ? 1 : 0)};
  visibility: ${(props) => (props.step === 0 ? "visible" : "hidden")};
  transition: 600ms;
`;

const Questions = ({ step, attachPersonalEmailOption, setAttachPersonalEmailOption }) => {
  return (
    <div style={{ position: "relative" }}>
      <Wrapper step={step}>
        <Text sectionTitle left styles={{ marginBottom: 0 }}>
          {
            "You already provided that work email address. Would you like to attach a personal email address to this account?"
          }
        </Text>
        <Radio.Group value={attachPersonalEmailOption} onChange={(e) => setAttachPersonalEmailOption(e.target.value)}>
          <Radio value="yes">Yes</Radio>
          <Radio value="no">No, I want to use my work email address</Radio>
        </Radio.Group>
      </Wrapper>
    </div>
  );
};

export default Questions;
