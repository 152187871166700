import React from "react";
import { connect } from "react-redux";
import Actions from "store/plan/actions";
import {
  PageTemplate,
  Header,
  ProgressTracker,
  OnboardingMainV2,
} from "components";
import { PageView, EventOne } from "services/google";
import { createReport, updateReportV2 } from "services/api/report.api";
import { message } from "antd";

const steps = ["Answer Questions", "Review Details", "Payment"];

class OnboardingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      report: null,
      currentStep: 0,
      backgroundColor: null,
    };

    this.handleCreateReport = this.handleCreateReport.bind(this);
    this.handleUpdateReport = this.handleUpdateReport.bind(this);
  }

  async componentDidMount() {
    document.title = "Onboarding - predictaBill";
    PageView();
    const { load, isLoaded, currentReport, isNew } = this.props;

    if (!isNew && currentReport && currentReport.status !== 3) {
      this.setState({
        report: currentReport,
        currentStep:
          currentReport.onboardingStep === "payment"
            ? 2
            : currentReport.onboardingStep === "complete"
            ? 2
            : currentReport.onboardingStep === "review"
            ? 1
            : 0,
      });
    }
    if (!isLoaded) {
      await load();
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.currentReport !== prevProps.currentReport) {
      const { currentReport, isNew } = this.props;
      if (currentReport.status === 3) {
        // current report is completed
        // generate a new onboarding flow for a new report

        this.setState({ currentStep: 0 });
      } else if (!isNew) {
        this.setState({
          report: currentReport,
          currentStep:
            currentReport.onboardingStep === "payment"
              ? 2
              : currentReport.onboardingStep === "complete"
              ? 2
              : currentReport.onboardingStep === "review"
              ? 1
              : 0,
        });
      }
    }
  }

  handleCreateReport(form) {
    EventOne("Onboarding", "Create Report");
    createReport(form).then((res) => {
      if (res.status === 200) {
        this.setState({ report: res.data.report });
      } else {
        message
          .error("There is currently an optimization already pending")
          .then(this.props.history.push("/recommendations"));
      }
    });
  }

  handleUpdateReport(form) {
    if (form.onboardingStep === "complete") {
      EventOne("Onboarding", "Create Report", "completed onboarding survey");
    }

    if (form.onboardingStep === "review") {
      this.setState({ currentStep: 1 });
    }

    updateReportV2(this.state.report._id, form).then((res) => {
      if (res.status === 200) {
        const { report } = res.data;
        this.setState({ report: report });
        this.setState({
          currentStep:
            report.onboardingStep === "payment"
              ? 2
              : report.onboardingStep === "complete"
              ? 2
              : report.onboardingStep === "review"
              ? 1
              : 0,
        });
      } else {
        message
          .error("There is currently an optimization already pending")
          .then(this.props.history.push("/recommendations"));
      }
    });
  }

  render() {
    const { report, currentStep } = this.state;
    return (
      <PageTemplate
        header={<Header invert secondaryBackground={currentStep === 2} V2 />}
        secondaryBackground={currentStep === 2}
      >
        <ProgressTracker steps={steps} currentStep={currentStep} />
        <OnboardingMainV2
          report={report}
          plan={this.props.plan}
          handleCreateReport={this.handleCreateReport}
          handleUpdateReport={this.handleUpdateReport}
        />
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { plan, profile } = state;
  const { params } = ownProps.match;
  return {
    plan,
    isLoaded: profile.isLoaded,
    currentReport: profile.currentReport,
    isNew: params.new,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: () => {
      dispatch(Actions.Creators.loadOptionsRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingPage);
