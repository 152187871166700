import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import FormField from "components/molecules/FormField";
import Text from "components/atoms/Text";
import { Space } from "antd";
import SelectField from "components/molecules/SelectField";
import { TableRows } from "../utils/table";
import { frequencyOptions, networkOptions } from "../utils/options";
import TableField from "components/molecules/TableField";
import TableMenu from "components/molecules/TableMenu";
import { AdvancedOptions } from "./AdvancedOptions";
import { isCreatingPlan, isEditingPlan } from "../utils/helpers";

const Step2 = () => {
  const { values, setFieldValue, setErrors, setTouched } = useFormikContext();
  const {
    planOptions,
    table,
    waiverTiersNumber,
    partnerJustForComparison,
    selectedPremiumTier,
    waivers,
    allowedInsuranceNetworks,
  } = values;
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  const columns = [
    {
      title: "",
      dataIndex: "idx",
      width: 20,
      editable: false,
      fixed: "left",
    },
    {
      title: "Label",
      dataIndex: "label",
      width: 133,
      editable: true,
      inputType: "text",
      shouldCellUpdate: () => false,
      dropdownMatchSelectWidth: 350,
      fixed: "left",
    },
    {
      title: "Insurance Network",
      dataIndex: "insuranceNetwork",
      className: "insuranceNetwork-col",
      width: 200,
      editable: true,
      inputType: allowedInsuranceNetworks?.length > 0 ? "select" : "autocomplete",
      dropdownMatchSelectWidth: 350,
      options: allowedInsuranceNetworks?.length > 0 ? allowedInsuranceNetworks : networkOptions,
      shouldCellUpdate: () => false,
    },
    {
      title: "me only",
      dataIndex: `tiers[${selectedPremiumTier}].mo`,
      width: 110,
      editable: true,
      inputType: "currency",
      min: Number.MIN_SAFE_INTEGER,
      shouldCellUpdate: () => false,
    },
    {
      title: "me and spouse",
      dataIndex: `tiers[${selectedPremiumTier}].ms`,
      width: 110,
      editable: true,
      inputType: "currency",
      min: Number.MIN_SAFE_INTEGER,
      shouldCellUpdate: () => false,
    },
    {
      title: "me and child(ren)",
      dataIndex: `tiers[${selectedPremiumTier}].mc`,
      width: 110,
      editable: true,
      inputType: "currency",
      min: Number.MIN_SAFE_INTEGER,
      shouldCellUpdate: () => false,
    },
    {
      title: "me, spouse and child(ren)",
      dataIndex: `tiers[${selectedPremiumTier}].mf`,
      width: 110,
      editable: true,
      inputType: "currency",
      min: Number.MIN_SAFE_INTEGER,
      shouldCellUpdate: () => false,
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 48,
      render: (_, record) => <TableMenu step={2} record={record} />,
    },
  ].map((col) => {
    return {
      ...col,
      onCell: (_, idx) => ({
        index: idx,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: col.inputType,
        options: col.options,
        dropdownMatchSelectWidth: col.dropdownMatchSelectWidth,
        min: col.min,
      }),
    };
  });

  useEffect(() => {
    for (let waiverKey = 1; waiverKey <= waiverTiersNumber; waiverKey++) {
      if (waivers[waiverKey] == null) {
        waivers[waiverKey] = {
          value: 0,
          frequency: 1 / 12,
        };
      }
      if (waivers[waiverKey] && (waivers[waiverKey].value == null || waivers[waiverKey].frequency == null)) {
        waivers[waiverKey].value = waivers[waiverKey].value != null ? waivers[waiverKey].value : 0;
        waivers[waiverKey].frequency = waivers[waiverKey].frequency != null ? waivers[waiverKey].frequency : 1 / 12;
      }
    }
    if (Object.keys(waivers).length > waiverTiersNumber) {
      for (let waiverKey = waiverTiersNumber + 1; waiverKey <= Object.keys(waivers).length; waiverKey++) {
        delete waivers[waiverKey];
      }
    }
    setFieldValue("waivers", waivers);
  }, [waiverTiersNumber]);

  useEffect(() => {
    const tableData = TableRows(table, planOptions, values);
    setFieldValue("table", tableData);
  }, [planOptions]);

  const data = table.slice(0, planOptions);

  useEffect(() => {
    if (Object.keys(waivers).every((key) => waivers[key].value === 0)) setFieldValue("withWaiverPayment", false);
    if (values.spousalSurcharge === 0) setFieldValue("withSpouseSurcharge", false);

    document.getElementById("form-content").scrollTo({ top: 0, behavior: "smooth" });
    setErrors({});
    setTouched({}, false);
  }, []);

  const isMarketplacePlans = partnerJustForComparison === "marketplace";

  return (
    <>
      <Text sectionTitle>Cost of Options</Text>

      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Space direction="horizontal" size="middle">
          <FormField
            name="planOptions"
            type="number"
            label={`How many plan options do you have ${isMarketplacePlans ? "" : "with this job?"}`}
            hyperlink={
              isMarketplacePlans && {
                label: "through the Marketplace?",
                url: "https://www.healthcare.gov/see-plans",
              }
            }
            inline
            min={1}
            required
          />
          {isEditingPlan() && (
            <AdvancedOptions.Toggle isOpen={showAdvancedOptions} setIsOpen={setShowAdvancedOptions} />
          )}
        </Space>

        <AdvancedOptions.Content isEnabled={isCreatingPlan() || showAdvancedOptions} />

        <SelectField name="frequency" options={frequencyOptions} label={`Cost shown`} />
        <TableField name="table" columns={columns} dataSource={data} tableLayout="fixed" />
      </Space>
    </>
  );
};

export default Step2;
