import React from "react";
import { Input } from "antd";
import styled, { css } from "styled-components";

const { TextArea } = Input;

const Wrapper = styled.div`
  margin-bottom: ${({ isNotMargin }) => !isNotMargin && `5px`};
  width: 100%;
  ${({ onboardingWrapper }) =>
    onboardingWrapper &&
    css`
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px;
      align-items: center;
      justify-items: center;
    `};
`;

const StyledTextArea = styled(TextArea)`
  > textarea > .ant-input {
    color: rgb(0, 161, 155);
    border: none;
    resize: none;
    outline: none;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
`;

const textAreaStyles = {
  color: "rgb(0,161,155)",
  border: "none",
  borderColor: "none",
  resize: "none",
  outline: "none",
  width: "350px",
  height: "150px"
};

const StyledInput = styled(Input)`
  > .ant-input-wrapper > .ant-input {
    color: rgb(0, 161, 155);
    text-align: end;
    border: 2px solid red;
  }
`;

const InputWrapper = styled.div`
  ${({ onboardingWrapper }) =>
    onboardingWrapper &&
    css`
      background-color: rgba(81, 165, 162, 0.15);
      width: 310px;
      height: 60px;
      outline: none;
      border: none;
      padding: 5px 25px;
      border-radius: 15px;
      color: rgba(81, 165, 162);
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;

const InputCustom = ({
  placeholder,
  addonBefore,
  handleChange,
  noMargin,
  name,
  value,
  textArea,
  disabled,
  type,
  max,
  min,
  onboardingWrapper,
  autoFocus
}) => {
  const onChange = e => {
    const { value } = e.target;
    const target = { name, value };
    handleChange({ target });
  };

  return (
    <Wrapper isNotMargin={noMargin}>
      <InputWrapper onboardingWrapper={onboardingWrapper}>
        {textArea ? (
          <StyledTextArea
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            name={name}
            style={textAreaStyles}
            autoFocus
            disabled={disabled}
          />
        ) : (
          <StyledInput
            addonBefore={addonBefore && addonBefore + ":"}
            placeholder={disabled ? "" : placeholder}
            onChange={onChange}
            value={disabled ? "" : value}
            name={name}
            disabled={disabled}
            type={type ? type : "text"}
            max={max}
            min={min}
            autoFocus={autoFocus}
          />
        )}
      </InputWrapper>
    </Wrapper>
  );
};

export default React.memo(InputCustom);
