import { createReducer } from "reduxsauce";
import Actions from "./actions";
import { assign } from "lodash";

const { Types } = Actions;
const INITIAL_STATE = {
  charges: [],
  basedOnBillCharges: "",
  grandTotal: 0,
  isRequesting: false,
  error: null
};

const success = (state = INITIAL_STATE, { payload }) => {
  return assign({}, state, { isRequesting: false, ...payload });
};

const reset = (state = INITIAL_STATE, { error }) => {
  if (error)
    return {
      ...INITIAL_STATE,
      error
    };
  return INITIAL_STATE;
};

const request = (state = INITIAL_STATE) => {
  return assign({}, state, {
    ...INITIAL_STATE,
    isRequesting: true,
    error: null
  });
};

export const HANDLERS = {
  [Types.CALCULATE_SUCCESS]: success,
  [Types.CALCULATE_REQUEST]: request,
  [Types.CALCULATE_FAILURE]: reset
};

const reducer = createReducer(INITIAL_STATE, HANDLERS);

export default reducer;
