import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "components";

const Wrapper = styled.div`
  display: inline-flex;
  text-decoration: none;
  border-radius: 70px;
  border: 2px transparent;
  min-height: 39px;
  ${({ minWidth }) =>
    minWidth ? `min-width: ${minWidth}px;` : `min-width: 84px;`}
  text-align: center;

  ${({ margin }) => margin && `margin: ${margin};`}
  &:hover {
    background: #fff;
    color: #111;
  }
  cursor: pointer;
`;

const Button = (props) => {
  return (
    <Wrapper margin={props.navmargin}>
      <Link circular {...props} button />
    </Wrapper>
  );
};

Button.propTypes = {
  href: PropTypes.string,
};

export default Button;
