export const formatHSA = (item) => {
  const {
    isHSACompatible,
    enrollHealthCareFSA,
    isHsaOrHraCompatible,
    individualHSA,
    familyHSA,
    largeFamilyHSA,
    depositedHSA,
    individualHRA,
    familyHRA,
    largeFamilyHRA,
    towardHRA,
  } = item;
  let formattedTiers = {};
  const individualHSAByFrequency = individualHSA / depositedHSA;
  const familyHSAByFrequency = familyHSA / depositedHSA;
  const largeFamilyHSAByFrequency = largeFamilyHSA / depositedHSA;
  if (isHsaOrHraCompatible === "HSA") {
    formattedTiers = {
      1: {
        range: "default",
        individual: individualHSAByFrequency,
        family: familyHSAByFrequency,
        largeFamily: largeFamilyHSAByFrequency,
      },
    };
  } else if (isHsaOrHraCompatible === "HRA") {
    if (isHSACompatible === "yes") {
      formattedTiers = {
        1: {
          range: "default",
          individual: 0,
          family: 0,
          largeFamily: 0,
        },
        2: {
          range: "default",
          individual: individualHRA,
          family: familyHRA,
          largeFamily: largeFamilyHRA,
        },
      };
    } else {
      formattedTiers = {
        1: {
          range: "default",
          individual: individualHRA,
          family: familyHRA,
          largeFamily: largeFamilyHRA,
        },
      };
    }
  } else if (isHsaOrHraCompatible === "both") {
    formattedTiers = {
      1: {
        range: "default",
        individual: individualHSAByFrequency,
        family: familyHSAByFrequency,
        largeFamily: largeFamilyHSAByFrequency,
      },
      2: {
        range: "default",
        individual: individualHRA,
        family: familyHRA,
        largeFamily: largeFamilyHRA,
      },
    };
  } else if (isHsaOrHraCompatible === "no" && isHSACompatible === "yes") {
    formattedTiers = {
      1: {
        range: "default",
        individual: 0,
        family: 0,
        largeFamily: 0,
      },
    };
  }

  const formattedHSA = {
    isHra:
      isHsaOrHraCompatible === "no"
        ? null
        : isHsaOrHraCompatible === "HRA" && isHSACompatible === "no"
          ? true
          : false,
    isFsa: isHSACompatible === "yes" ? false : enrollHealthCareFSA === "yes",
    enrollHealthCareFSA,
    isHSACompatible,
    isHsaOrHraCompatible,
    hasBoth:
      isHsaOrHraCompatible === "both" ||
      (isHsaOrHraCompatible === "HRA" && isHSACompatible === "yes"),
    frequency: isHsaOrHraCompatible === "HRA" ? 1 : depositedHSA || null,
    toward: towardHRA || null,
    tiers: formattedTiers,
  };

  return formattedHSA;
};
