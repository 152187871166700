import React from "react";
import {
  CalculateFormTemplate,
  FormSelect as Select,
  InNetworkModal,
} from "components";
import { strToBoolTrue, strToBoolFalse } from "services/utils";
import useModal from "services/hooks/useModal.hook";
import { useDispatch, useSelector } from "react-redux";
import Action from "store/plan/actions";

const CalculateInsurancePlan = ({
}) => {
  const { handleClick, isShow } = useModal();

  const {broaderNetworksMySelf, isDoctorInNetwork} = useSelector(state => state.plan )
  const dispatch = useDispatch()
  
  
  const handleChange = ({ target: { name, value } }) => {
    dispatch(Action.Creators.changeInsurancePlan(name, value))
  };

  const handleChangeBool = ({ target: { value: boolStr } }) => {
    handleClick();
    let value
    if (strToBoolTrue(boolStr)) value = true
    else if (strToBoolFalse(boolStr)) value = false
    else return;
    dispatch(Action.Creators.changeInsurancePlan("isDoctorInNetwork", {value }))
  };

  return (
      <>
        <InNetworkModal isShow={isShow} handleClick={handleClick} />
        {isDoctorInNetwork.isVisible && (
          <Select
            text="Doctor In-Network?"
            isBool
            newStyle
            name="isDoctorInNetwork"
            selection={isDoctorInNetwork.data}
            value={isDoctorInNetwork.value} 
            handleChange={handleChangeBool}
          />
        )}
        {broaderNetworksMySelf.isVisible && (
          <Select
            newStyle
            text="Insurance Rates Used to Predict Your Cost"
            selectObj="broaderNetwork"
            select="broaderNetwork"
            title=" (My Network)"
            name="broaderNetworksMySelf"
            isBig
            selection={broaderNetworksMySelf.data}
            value={broaderNetworksMySelf.value}
            handleChange={handleChange}
          />
        )}
      </>
  );
};

export default React.memo(CalculateInsurancePlan);
