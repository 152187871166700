import React from 'react';
import styled from 'styled-components';

const StyledAnchor = styled.a`
  display: inline-flex;
  width: 125px;
  height: 38px;
  padding: 8px 0;
  border:  1px solid white;
  background: transparent;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  line-height: 1em;
  white-space: nowrap;
  font-family: circular;
    justify-content: center;
  align-content: center;
  :hover {
    background: white;
    color: #479F9B
  }
`;

export default ({ children, link, ...props }) => <StyledAnchor href={link} {...props}>{children}</StyledAnchor>;

