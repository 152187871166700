import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MyPlansMiddleWrapper, MyPlansBox } from "components";
import { updateReport, cloneReportForUsers, getPlansArr } from "services/api";
import { updateGroup, deleteFromGroup } from "services/api/groups.api";
import { getToken } from "services/utils";

const Wrapper = styled.div`
  font-family: 15px;
  color: #123;
  padding: 20px;
  padding-top: 0;
  background-color: ${({ withBG }) => (withBG ? "#d9f3f1" : "none")};
`;

const HeaderWrapper = styled.div`
  color: #123;
  width: 50vw;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 480px) {
    width: 100%;
    margin: 25px auto 0 auto;
  }
`;

const Title = styled.h1`
  color: black;
  text-align: center;
  font-size: ${({ bigTitle }) => (bigTitle ? "2.5em" : "1.2em")};
  ${({ bigTitle }) => bigTitle && `font-size: 2.5em;`};
  line-height: 1.4em;
  font-weight: 900;
  margin: 5px 35px;

  @media (max-width: 1300px) {
    line-height: 1.5em;
  }

  @media (max-width: 480px) {
    font-size: 1.2em;
    padding: 1px 2px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  width: 100%;
  flex-wrap: ${(props) => (props.wrap ? "wrap" : "nowrap")};
`;

const MyPlansCardsList = ({ list }) => {
  const formatDate = (date) =>
    `${("0" + (date.getMonth() + 1)).slice(-2)}/${("0" + date.getDate()).slice(-2)}/${date.getFullYear()}`;

  const [listData, setListData] = useState([]);
  const [componentPropsList, setComponentPropsList] = useState([]);

  const countServices = async (planIds) => {
    if (!planIds) {
      return;
    }
    const token = await getToken();
    const { data } = await getPlansArr(planIds, token, false);
    const plans = data.plansArr;
    return plans[0].services.length;
  };

  const handleCopyItem = async (item, isPublished, year) => {
    const token = await getToken();
    const group = item.group;

    const currentYear = new Date().getFullYear();
    const cloneYear = isPublished && year === currentYear ? currentYear + 1 : year;
    const { status, data } = await cloneReportForUsers(item._id, [], token, true, {
      year: cloneYear,
      isPublishedForGroup: false,
    });

    if (status === 200) {
      const newReport = data.report;
      const newGroup = {
        ...group,
        masterNetworkGrids: [...group.masterNetworkGrids, newReport._id],
        masterReports: [...group.masterReports, newReport._id],
      };
      await updateGroup({
        originalName: group.name,
        form: newGroup,
      });
      setListData((prev) =>
        [...prev, { group, ...newReport }].sort(
          (a, b) =>
            new Date(b.fulfilledDate || b.updated_at || b.updatedAt) -
            new Date(a.fulfilledDate || a.updated_at || a.updatedAt),
        ),
      );
    }
  };

  const handleDeleteItem = async (item) => {
    const token = await getToken();
    const { status } = await deleteFromGroup({ name: item.group.name, id: item._id, token });
    if (status === 200) {
      setListData((prev) =>
        prev
          .filter((entry) => entry._id !== item._id)
          .sort(
            (a, b) =>
              new Date(b.fulfilledDate || b.updated_at || b.updatedAt) -
              new Date(a.fulfilledDate || a.updated_at || a.updatedAt),
          ),
      );
    }
  };

  const loadList = async () => {
    if (listData.length === 0) return;
    Promise.all(
      listData.map(async (item, index) => {
        const isReport = item.status === 3;
        const isPublished = isReport && item.isPublishedForGroup !== false;
        const numberOfServicesAvailable = await countServices(item?.birthParent?.planIds);
        const headerTitle = `${
          item.status === 3 && numberOfServicesAvailable >= 9 ? (isPublished ? "PUBLISHED" : "COMPLETE") : "IN PROGRESS"
        }`;
        const headerDate = item.fulfilledDate || formatDate(new Date(item.updatedAt));
        const title = item.group.displayName;
        const imageSrc = item.group.logoUrl;
        const year = item.year ? item.year : item.dates ? item.dates[0].split("/")[2] : "";
        const monthsRange = item.dates
          ? `${new Date(item.dates[0]).toLocaleString("default", {
              month: "long",
            })} - ${new Date(item.dates[1]).toLocaleString("default", { month: "long" })}`
          : "January - December";
        const showPublishButton = isReport && numberOfServicesAvailable >= 9;
        const otherItemSameYearIsAlreadyPublished =
          listData
            .filter((filterItem) => filterItem._id !== item._id)
            .filter(
              (filterItem) =>
                filterItem.group.name === item.group.name &&
                filterItem.year === item.year &&
                filterItem.isPublishedForGroup,
            ).length > 0;
        const isPublishButtonEnabled =
          !otherItemSameYearIsAlreadyPublished &&
          item.group.displayName &&
          item.group.otherDomains.filter((item) => item && item !== "").length > 0 &&
          (item.group.linkedinUrl || item.group.ein) &&
          item.year;

        const errorTooltip = [
          !item.group.displayName && "Display Name",
          item.group.otherDomains.filter((item) => item && item !== "").length === 0 && "Valid Email Domain(s)",
          (!item.group.linkedinUrl || item.group.linkedinUrl === "") && item.group.hasLinkedin && "LinkedIn URL",
          (!item.group.ein || item.group.ein === "") && !item.group.hasLinkedin && "Federal EIN",
          !item.year && "Plan Year",
          item.group.otherSearchTerms.length === 0 &&
            `Optional: Other Search Terms (for example, "Facebook" or "FB" instead of "Meta")`,
          !item.group.logoUrl && "Optional: Upload Logo",
        ].filter((item) => item);

        const otherItemSameYearIsAlreadyPublishedTooltip =
          "Another plan for this group is already published for this year. You can only publish one plan per group per year.";
        const errorTooltipText = `Some information is missing. Fill out these fields in order to publish your plans!
        ${errorTooltip.map((item) => `- ${item}`).join("\n")}`;

        const buttons = [
          {
            text: "VIEW",
            href: `/my-plans/${item.group.name}/${item._id}`,
          },
        ];
        if (showPublishButton) {
          buttons.push({
            text: isPublished ? "UNPUBLISH" : "PUBLISH",
            onClick: () => isReport && handleUpdateReport(item, index),
            disabled: !isPublishButtonEnabled,
            tooltip: otherItemSameYearIsAlreadyPublished
              ? otherItemSameYearIsAlreadyPublishedTooltip
              : !isPublishButtonEnabled && errorTooltipText,
            onConfirm:
              !otherItemSameYearIsAlreadyPublished &&
              (() => (window.location.href = `/my-plans/${item.group.name}/${item._id}`)),
          });
        }

        return {
          headerTitle,
          headerDate,
          title,
          imageSrc,
          year,
          copyItem: isReport && (() => handleCopyItem(item, isPublished, year)),
          deleteItem: () => handleDeleteItem(item),
          monthsRange,
          buttons,
        };
      }),
    ).then((data) => {
      setComponentPropsList(data);
    });
  };

  useEffect(() => {
    setListData(list);
  }, [list]);

  useEffect(() => {
    loadList();
  }, [listData]);

  const handleUpdateReport = async (item, index) => {
    const token = await getToken();
    const { status, data } = await updateReport(item._id, { isPublishedForGroup: !item.isPublishedForGroup }, token);
    if (status === 200) {
      const newListData = [...listData];
      const report = data.report;
      newListData[index] = { group: item.group, ...report };
      setListData(newListData);
    }
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <Title bigTitle={true}>My Plans</Title>
      </HeaderWrapper>
      <FlexWrapper wrap>
        {componentPropsList &&
          componentPropsList.map((item, index) => {
            const { headerTitle, headerDate, title, imageSrc, year, copyItem, deleteItem, monthsRange, buttons } = item;
            return (
              <MyPlansBox
                key={index}
                headerTitle={headerTitle}
                headerDate={headerDate}
                title={title}
                handleCopyItem={copyItem}
                handleDeleteItem={deleteItem}
                middleWrapper={<MyPlansMiddleWrapper imageSrc={imageSrc} year={year} monthsRange={monthsRange} />}
                buttons={buttons}
              />
            );
          })}
      </FlexWrapper>
    </Wrapper>
  );
};

export default MyPlansCardsList;
