import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  CalculateHeader as Header,
  FormFooter,
  Text,
  UploadPamphletsV2,
} from "components";
import { merge } from "lodash";
import { Icon } from "@ant-design/compatible";
import { uploadPamphlets } from "services/api/report.api";

const Wrapper = styled.section`
  display: grid;
  height: 100%;
  place-items: center;
`;

const GridWrapper = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 15px;
`;

const Button = styled.button`
  background-color: rgba(81, 165, 162, 0.15);
  width: 408px;
  height: 84px
  outline: none;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;

  &:hover {
    background: rgba(81, 165, 162, 0.3);
    border: 2px solid rgba(81, 165, 162);
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const iconRightStyles = {
  fontSize: "20px",
  fontWeight: "bold",
  color: "rgba(81, 165, 162)",
};

const OnboardingPamphletSection = ({
  _form,
  handleNext,
  nameSelections,
  plan,
}) => {
  const [steps, setSteps] = useState(["pamphlet"]);
  const step = steps[steps.length - 1];

  const [birthParent, setBirthParent] = useState({
    parentName: _form.birthParentName || "Me",
    nameSelections,
    company: _form.birthParentCompany,
    pamphlets: [],
  });

  const [partner, setPartner] = useState({
    parentName: _form.partnerName || "Spouse",
    nameSelections,
    company: _form.partnerCompany,
    pamphlets: [],
  });

  const [companySelections, setCompanySelections] = useState(null);

  useEffect(() => {
    if (plan.groups.data.length) {
      setCompanySelections([
        "Mine isn't one of these",
        "Insurance is through a school or union",
        ...plan.groups.data,
      ]);
    }
  }, [plan.groups.data]);

  const handleFinish = () => {
    const updatedForm = merge({}, _form, {
      onboardingStep: "review",
    });
    handleNext(updatedForm);
  };

  const redirectToHelp = () => {
    window.open("https://calendly.com/predictabill/help", "_blank");
  };

  const handleStep = async () => {
    const updatedForm = merge({}, _form, {
      onboardingStep: "review",
    });
    switch (step) {
      case "pamphlet":
        setSteps([...steps, "birthParent"]);
        break;
      case "birthParent":
        try {
          const formData = new FormData();
          birthParent.pamphlets.forEach((file) => {
            formData.append("file", file, file.name);
          });
          formData.append("company", birthParent.company);
          formData.append("parent", "birthParent");
          formData.append("name", birthParent.parentName);
          formData.append("reportId", _form.id);

          const response = await uploadPamphlets(formData);
          if (response.status === 200) {
            nameSelections.length > 1
              ? setSteps([...steps, "partner"])
              : handleNext(updatedForm);
          } else throw Error;
        } catch (error) {}
        break;
      case "partner":
        try {
          const formData = new FormData();
          partner.pamphlets.forEach((file) => {
            formData.append("file", file, file.name);
          });
          formData.append("company", partner.company);
          formData.append("parent", "partner");
          formData.append("name", partner.parentName);
          formData.append("reportId", _form.id);

          const response = await uploadPamphlets(formData);
          if (response.status === 200) {
            handleNext(updatedForm);
          } else throw Error;
        } catch (error) {}
        break;
      default:
        handleNext(updatedForm);
        break;
    }
  };

  const handleBack = () => {
    const updatedSteps = steps.slice(0, -1);
    const updatedForm = merge({}, _form, {
      onboardingStep: "location",
    });

    switch (steps.length) {
      case 1:
        handleNext(updatedForm);
        break;
      default:
        setSteps(updatedSteps);
        break;
    }
  };

  return (
    <Wrapper>
      <Header bigTitle={true} title="Let's get your benefit information" />
      {step === "pamphlet" ? (
        <GridWrapper>
          <Button onClick={handleStep}>
            <TextWrapper>
              <Text select bolder green left>
                Upload Your Pamphlet
              </Text>
              <Text select left green style={{ maxWidth: "350px" }}>
                If you have it handy, this is an easy option.
              </Text>
            </TextWrapper>
            <Icon type="right" style={iconRightStyles} />
          </Button>

          <Button onClick={handleFinish}>
            <TextWrapper>
              <Text select bolder green left>
                Send Your Pamphlet To Us Later
              </Text>
              <Text select left green style={{ maxWidth: "350px" }}>
                We’ll email you to get your pamphlet after checkout.
              </Text>
            </TextWrapper>
            <Icon type="right" style={iconRightStyles} />
          </Button>

          <Button onClick={redirectToHelp}>
            <TextWrapper>
              <Text select bolder green left>
                Ask For Help
              </Text>
              <Text select left green style={{ maxWidth: "350px" }}>
                Sign up for a call where we can assist you.
              </Text>
            </TextWrapper>
            <Icon type="right" style={iconRightStyles} />
          </Button>
        </GridWrapper>
      ) : step === "birthParent" ? (
        <GridWrapper>
          <UploadPamphletsV2
            parent={birthParent}
            setParent={setBirthParent}
            companySelections={companySelections}
          />
        </GridWrapper>
      ) : step === "partner" ? (
        <UploadPamphletsV2
          parent={partner}
          setParent={setPartner}
          companySelections={companySelections}
        />
      ) : null}
      <FormFooter
        leftAction={handleBack}
        mainAction={step !== "pamphlet" ? handleStep : null}
        mainActionActive={true}
      />
    </Wrapper>
  );
};

export default OnboardingPamphletSection;
