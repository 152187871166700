import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/report/actions";
import { CheckboxGroup } from "../components/CheckboxGroup";

export const DoctorOptionsFilter = ({ doctorOptions }) => {
  const dispatch = useDispatch();
  const doctor = useSelector((state) => state.report.doctor);

  if (!doctorOptions?.length) return null;

  return (
    <CheckboxGroup
      label="In-Network Doctors"
      options={doctorOptions.map((option) => ({ value: option, label: option }))}
      onChange={(value) => {
        dispatch(
          Actions.Creators.switchState({
            doctor: value,
          }),
        );
      }}
      value={doctor}
    />
  );
};
