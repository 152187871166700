import model from "./model";
const {
  formField: {
    expectedTable,
    prescriptionsTable,
    pregnancyTable,
    fertilityTable,
    unexpectedTable,
    table,
    partnerTable,
    subjectToDeductibleG1,
    subjectToDeductibleG2,
    FSTypeG1,
    FSMaxAmountG1,
    FSMaxUnitsG1,
    FSTypeG2,
    FSMaxAmountG2,
    FSMaxUnitsG2,
  },
} = model;

export default {
  [expectedTable.name]: [
    {
      idx: 1,
      tableName: "expectedTable",
      expectedOrUnexpected: "expected",
      type: "inNetwork",
    },
  ],
  [prescriptionsTable.name]: [
    {
      idx: 1,
      tableName: "prescriptionsTable",
    },
  ],
  [pregnancyTable.name]: [
    {
      idx: 1,
      tableName: "pregnancyTable",
    },
  ],
  [fertilityTable.name]: [
    {
      idx: 1,
      tableName: "fertilityTable",
    },
  ],
  [unexpectedTable.name]: [
    {
      idx: 1,
      tableName: "unexpectedTable",
    },
  ],
  [table.name]: [],
  [partnerTable.name]: [],
  [subjectToDeductibleG1.name]: true,
  [subjectToDeductibleG2.name]: true,
  [FSTypeG1.name]: "amount",
  [FSMaxAmountG1.name]: null,
  [FSMaxUnitsG1.name]: null,
  [FSTypeG2.name]: "amount",
  [FSMaxAmountG2.name]: null,
  [FSMaxUnitsG2.name]: null,
};
