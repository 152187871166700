import { roomTypes } from "enums";
import { strToBoolFalse, strToBoolTrue } from "./checkBool";

export const checkFormData = ({
  birth,
  insurance,
  isDoctorInNetwork,
  bills,
  hospitals,
  deliveryTypes,
  doctors,
  plansNetwork,
  roomTypes
}) => {
  const formDataSend = {
    roomType: "all",
    deliveryType: deliveryTypes.value,
    insurancePlanType: insurance.insurancePlanType,
    billIds: getBillsId(bills),
    isDoctorInNetwork: isDoctorInNetwork.value,
    insurancePlanId: plansNetwork.value,
    hospitalId:
      hospitals.value || hospitalFind(doctors.value, hospitals.current)
  };

  if (strToBoolFalse(roomTypes.value) || strToBoolTrue(roomTypes.value)) {
    formDataSend.roomType = getRoomType(roomTypes.value, birth.deluxe);
  }

  return formDataSend;
};

export const removeField = formDataSend => {
  const data = formDataSend;
  Object.keys(data).forEach(key =>
    data[key] === undefined || data[key] === "all"
      ? delete data[key]
      : data[key]
  );

  return data;
};

export const getDataForSummary = ({ plan, idBills }) => {
  const {
    hospitals,
    doctors,
    deliveryTypes,
    isDoctorInNetwork: { value: network }
  } = plan;

  let formDataSend = {
    ids: idBills,
    network: network === null ? true : network,
    proceduresTitle:
      deliveryTypes.value === "All Deliveries" ? "" : deliveryTypes.value
  };

  if (plan.broaderNetworksMySelf.value.toLowerCase() !== "all networks") {
    formDataSend.broaderNetwork = plan.broaderNetworksMySelf.value;
  }
  if (hospitals.value && hospitals.value !== "all") {
    formDataSend.hospital = hospitals.current.name;
  } else if (doctors.value && doctors.value !== "all") {
    formDataSend.practice = doctors.practice.name;
  }
  return formDataSend;
};

export const checkFormTableBills = ({
  isDoctorInNetwork,
  doctors,
  hospitals,
  deliveryTypes,
  broaderNetworksMySelf
}) => {
  const formDataSend = {
    practice: doctors.practice._id,
    hospital: hospitals.value,
    network: isDoctorInNetwork.value !== false ? true : false,
    proceduresTitle:
      deliveryTypes.value === "All Deliveries" ? "" : deliveryTypes.value,
    insuranceRates:
      broaderNetworksMySelf.value !== "All Networks"
        ? broaderNetworksMySelf.value
        : ""
  };

  return formDataSend;
};

const getRoomType = (privateRoom, deluxe) =>
  privateRoom === false
    ? roomTypes.Shared
    : privateRoom === true && deluxe === true
    ? roomTypes.Deluxe
    : privateRoom === true && roomTypes.Private;

const hospitalFind = (doctor, hospital) => {
  if (doctor === "all") return doctor;
  return hospital ? hospital._id : null;
};

export const getBillsId = (bills = []) => {
  const data = bills.filter(bill => bill.isChecked).map(data => data._id);
  return data;
};
