import React from "react";
import { Checkbox } from "antd";
import styled from "styled-components";
import { Text } from "components";
import "antd/dist/antd.css";

const styles = {
  fontSize: "14px",
  display: "flex",
  flexDirection: "column",
  padding: "0 5px",
};

const Wrapper = styled.div`
  margin-bottom: ${({ isNotMargin }) => !isNotMargin && `5px`};
  width: 100%;
  > *
    > .ant-checkbox-wrapper-checked
    > .ant-checkbox-checked
    .ant-checkbox-inner {
    background-color: #00a19b;
    border-color: #00a19b;
  }
`;

const CheckboxCustom = ({
  options,
  value,
  select,
  text,
  name,
  handleChange,
  isBool,
}) => {
  const onChange = (value) => {
    if (isBool) {
      const boolVal = value.length > 0 ? true : false;
      const target = { name, value: boolVal };
      handleChange({ target });
    } else {
      const target = { name, value };
      handleChange({ target });
    }
  };

  const renderOptionWithSelect = (option, i) => {
    if (select) {
      const fields = select.split(" ");

      const values = fields.map((field) => option[field]);
      return values.join(" ");
    } else {
      return option;
    }

    // const valueSelect = select ? option[select] : option;

    // return valueSelect;
  };

  return (
    <Wrapper>
      <Text select>{text}</Text>
      <Checkbox.Group
        options={options.map(renderOptionWithSelect)}
        value={
          isBool && value
            ? options
            : isBool && !value
            ? []
            : value
            ? value.map(renderOptionWithSelect)
            : value
        }
        onChange={onChange}
        style={styles}
      />
    </Wrapper>
  );
};

export default React.memo(CheckboxCustom);
