import { broaderAllNetworks } from "store/formData";
import { getDoctorAndHospital, getRooms } from ".";
import {
  getPremiumUnitToFrequency,
  getHsaUnitToFrequency
} from "services/utils/getFrequencyFormat";

import { floatToPercent } from "services/utils/getPercentFormat";

export const hospitalFields = (id, data) => {
  const obj = data.find(({ _id }) => _id === id);
  return obj;
};

export const findPractice = (planName, state) => {
  let hospital = null;
  let doctor = null;
  if (planName.toLowerCase() === "doctor") {
    doctor = state.doctors[0]._id;
  } else if (planName.toLowerCase() === "hospital") {
    hospital = state.hospitals[0]._id;
  }
  return {
    doctor,
    hospital
  };
};

// Insurance Plan Options - Your Company (or type Marketplace): will populate the rest of form
export const changeGroupInsuranceGroup = (value, state) => {
  return {
    insurance: {
      ...state.insurance,
      broaderNetwork: null,
      practice: {
        _id: null,
        name: null
      }
    },
    isDoctorInNetwork: {
      ...state.isDoctorInNetwork,
      isVisible: false
    },
    insurancePlans: {
      ...state.insurancePlans,
      isRequesting: true
    },
    groups: {
      ...state.groups,
      value
    },
    plansNetwork: {
      ...state.plansNetwork,
      isVisible: false
    },
    broaderNetworksMySelf: {
      ...state.broaderNetworksMySelf,
      isVisible: false,
      value: "All Networks"
    }
  };
};

export const changeGroupInsurancePlan = (plan, state) => {
  return {
    insurance: {
      ...state.insurance,
      broaderNetwork: null
    },
    insurancePlans: {
      ...state.insurancePlans,
      value: plan
    },
    plansNetwork: {
      ...state.plansNetwork,
      isRequesting: true
    }
  };
};

export const changeNetworkPlan = (value, state) => {
  const insurance = changeAllFieldNetworkPlan(value, state);
  return insurance;
};

const changeAllFieldNetworkPlan = (value, state) => {
  let network = state.plansNetwork.data.find(({ _id }) => _id === value);
  let broaderNetwork = null;
  if (
    network &&
    network.broaderNetwork.toLowerCase() !== network.network.toLowerCase()
  ) {
    broaderNetwork = network.broaderNetwork;
  }
  const broaderNetworksMySelf = [broaderAllNetworks, network];

  const insurance = {
    ...state.insurance,
    broaderNetwork
  };
  return {
    insurance,
    plansNetwork: {
      ...state.plansNetwork,
      value
    },
    broaderNetworksMySelf: {
      ...state.broaderAllNetworks,
      data: broaderNetworksMySelf,
      value: broaderNetworksMySelf[0]._id,
      isVisible: true
    },
    isDoctorInNetwork: {
      ...state.isDoctorInNetwork,
      isVisible: Boolean(state.insurance.insurancePlanType)
    }
  };
};

export const changeMyNetwork = (value, state) => {
  return {
    broaderNetworksMySelf: {
      ...state.broaderNetworksMySelf,
      value
    }
  };
};

export const changeDoctorHospital = ({ isPrivate, name, value, ...props }) => {
  let data = {
    hospitals: {},
    roomTypes: { data: [], value: null },
    doctors: {
      value: null
    }
  };
  data[name] = {
    value,
    prevValue: props[name].value
  };

  const isDoc = name === "doctors";

  if (isDoc) {
    const { hospital, doctor } = getDoctorAndHospital(
      value,
      props.doctors.data
    );
    data.hospitals.current = hospital;
    data.doctors.current = doctor;
    data.hospitals.value = null;
    data.roomTypes.value = hospital ? isPrivate : null;
    data.roomTypes.data = getRooms(hospital);
  } else {
    data.hospitals.current = hospitalFields(value, props.hospitals.data);
    data.roomTypes.value = data.hospitals.current ? isPrivate : null;
    data.roomTypes.data = getRooms(data.hospitals.current);
    data.doctors.value = null;
  }

  return {
    ...data,
    deliveryTypes: { isVisible: true },
    deluxe: { value: null, isVisible: false }
  };
};

export const customPlanFields = (OPTION_INITIAL_STATE, plans) => {
  const data = plans.map(plan => {
    return {
      ...OPTION_INITIAL_STATE,
      id: plan._id,
      name: plan.name,
      maternityCopayDetails: {
        subjToCopay: plan.maternityCopayDetails.subjToCopay,
        hospital: plan.maternityCopayDetails.hospital,
        unit:
          plan.maternityCopayDetails.unit === "stay"
            ? "Per stay"
            : plan.maternityCopayDetails.unit === "night"
            ? "Per night"
            : null,
        maxUnits: plan.maternityCopayDetails.maxUnits,
        whoPays:
          plan.maternityCopayDetails.whoPays === "Parent"
            ? "Just the birth parent"
            : plan.maternityCopayDetails.whoPays === "Both"
            ? "Both the birth parent and the baby"
            : null,
        subjToPercentCoins: plan.maternityCopayDetails.subjToPercentCoins
      },
      deductibles: {
        subjToDeductible: plan.deductibles.isApply,
        baby: {
          ...OPTION_INITIAL_STATE.deductibles.baby,
          isUsed: plan.baby.deductible.isUsed
        },
        familyType:
          plan.family.deductible.type === "Combined"
            ? true
            : plan.family.deductible.type === "Individual"
            ? false
            : null,
        crossAccumulatable: plan.deductibles.cross.isAccumulatable
      },
      inNetwork: {
        individual: {
          deductible: plan.inNetwork.individual.deductible,
          oopMax: plan.inNetwork.individual.oopMax
        },
        family: {
          deductible: plan.inNetwork.family.deductible,
          individualDeductible: plan.inNetwork.family.individualDeductible,
          individualOopMax: plan.inNetwork.family.individualOopMax,
          oopMax: plan.inNetwork.family.oopMax
        },
        coinsurance: {
          rate: floatToPercent(plan.inNetwork.coinsurance.rate)
        }
      },
      outOfNetwork: {
        individual: {
          deductible: plan.outOfNetwork.individual.deductible,
          oopMax: plan.outOfNetwork.individual.oopMax
        },
        family: {
          deductible: plan.outOfNetwork.family.deductible,
          individualDeductible: plan.outOfNetwork.family.individualDeductible,
          individualOopMax: plan.outOfNetwork.family.individualOopMax,
          oopMax: plan.outOfNetwork.family.oopMax
        },
        coinsurance: {
          rate: floatToPercent(plan.outOfNetwork.coinsurance.rate)
        }
      },
      charge: {
        frequency: {
          ...OPTION_INITIAL_STATE.charge.frequency,
          value: getPremiumUnitToFrequency(plan.premium.frequency)
        },
        mo: plan.premium.tiers[1].mo,
        ms: plan.premium.tiers[1].ms,
        mc: plan.premium.tiers[1].mc,
        mf: plan.premium.tiers[1].mf
      },
      hsa: {
        isApply: plan.hsa.isApply,
        amountIndividual: plan.hsa.isApply
          ? plan.hsa.tiers[1].individual
          : null,
        amountFamily: plan.hsa.isApply ? plan.hsa.tiers[1].family : null,
        frequency: {
          ...OPTION_INITIAL_STATE.hsa.frequency,
          value: getHsaUnitToFrequency(plan.hsa.frequency)
        }
      }
    };
  });
  return data;
};
