import React from "react";
import styled, { css } from "styled-components";
import { Text, Currency } from "components";

const Inner = styled.div`
  display: grid;

  align-items: center;
  width: 100%;
  height: 25px;
  ${({ bottom }) => bottom && `margin-bottom: 16px;`}
  ${({ bottomBig }) => bottomBig && `margin-bottom: 28px;`}
  padding: 0 36px 0 25px;
  grid-gap: 1px;
  grid-template-columns: 65% 12% 12% 11%;

  ${({ background }) =>
    background
      ? css`
          background: ${background};
        `
      : css`
          background: #fff;
        `};
  ${({ white }) => white && `color: white;`}
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
`;

const StyledText = styled(Text)`
  font-size: 14px;
  ${({center, right}) => `text-align: ${center?'center': `${right ? 'right': 'left' }`}`}
  ${({bold}) => bold && `font-weight: bold`}
`;

const BillText = (props) => <StyledText circular {...props}>{props.children}</StyledText>

const Footer = styled.footer`
  display: grid;
  align-self: start;
  grid-gap: 1px;
`;

const BillContentFooter = ({
  feesAtChildbirth: { doctorCharges, insuranceRates },
  preventiveCareCharges,
  chargesWithInsurance,
  chargesPayCompletely,
  babysCharges,
  parentsCharges,
}) => {
  return (
    <Footer>
      <Inner background="#479F9B">
        <BillText white bold lg>
          Fees at childbirth
        </BillText>
        <BillText right white bold>
          <Currency price={doctorCharges} />
        </BillText>
        <BillText right white bold>
          <Currency price={insuranceRates} />
        </BillText>
        <div/>
      </Inner>

      <Inner>
        <BillText blue >
          Minus: preventive care charges (covered 100% by insurance)
        </BillText>
        <div/>
        <BillText right blue>
          <Currency price={preventiveCareCharges} />
        </BillText>
        <div/>
      </Inner>

      <Inner background="#186A67" white bottom>
        <BillText bold white lg>
          Charges you split with insurance
        </BillText>
        <div/>
        <BillText right bold>
          <Currency price={chargesWithInsurance} />
        </BillText>
        <div/>
      </Inner>

      <Inner background="#21355B" white>
        <BillText bold white lg>
          Charges you pay completely (out-of-network or denied coverage)
        </BillText>
        <div/>
        <div />
        <BillText right>
          <Currency price={chargesPayCompletely} />
        </BillText>
      </Inner>

      <Inner>
        <BillText blue>
          Parent's charges
        </BillText>
        <div/>
        <BillText right blue>
          <Currency price={parentsCharges} />
        </BillText>
        <div/>
      </Inner>

      <Inner >
        <BillText blue>
          Baby's charges
        </BillText>
        <div/>
        <BillText right blue>
          <Currency price={babysCharges} />
        </BillText>
        <div/>
      </Inner>
    </Footer>
  );
};

export default BillContentFooter;
