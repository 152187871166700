import React from "react";
import { Text } from "components";
import "./index.css";

const CheckboxCustom = ({ Icon, text, handleChange, name, value }) => {
  const onChange = (e) => {
    e.preventDefault();
    handleChange({ name, value: e.target.checked, text });
  };

  return (
    <form className="form">
      <div className="inputGroup">
        <input
          id={name + text}
          type="checkbox"
          name={name}
          checked={value}
          onClick={onChange}
        />
        <label for={name + text}>
          {Icon ? Icon : <div />}
          <Text center>{text}</Text>
        </label>
      </div>
    </form>
  );
};

export default React.memo(CheckboxCustom);
