import React from "react";
import styled from "styled-components";
import {
  CalculateHeader as Header,
  NavigationButton as Button,
} from "components";

const Wrapper = styled.div`
  padding: 20px;
  margin: 0 auto;
`;

const FlexWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;

  ${({ row }) => (row ? `flex-direction: row` : `flex-direction: column`)}
`;

const AccountNavigation = ({ profile }) => {
  const renderStatusBtn = () => {
    const status = profile.currentReport ? profile.currentReport.status : null;
    return (
      <>
        {!profile.currentReport ? (
          <Button
            to="/onboarding"
            icon="select"
            text="Ask for Advice"
            subText={null}
          />
        ) : profile.currentReport && !profile.currentReport.isPaid ? (
          <Button
            to={{
              pathname: "/checkout",
              subscriptionTier:
                profile.currentReport.forBusiness || profile.currentReport.type,
            }}
            icon="shopping-cart"
            text="Return To Checkout"
            subText={null}
          />
        ) : status === 0 ? (
          <Button
            to="/account/upload_pamphlets"
            icon="select"
            text="Waiting for Your Uploads to Give You Advice"
            subText="Click to complete"
            orange="true"
          />
        ) : status === 1 ? (
          <Button
            to="/account/your_optimizations"
            icon="select"
            text="Your Advice Is Pending"
            subText={null}
          />
        ) : status === 2 ? (
          <Button
            to="/account/upload_pamphlets"
            icon="select"
            text="More Information is Required"
            subText="Click to complete"
          />
        ) : status === 3 ? (
          <Button
            to="/onboarding"
            icon="select"
            text="Ask for Advice"
            subText={null}
          />
        ) : status === 4 ? (
          <Button
            to="/account/additional_details"
            icon="select"
            text="More Information is Required"
            subText="Click to complete"
          />
        ) : (
          <Button
            to="/onboarding"
            icon="select"
            text="Ask for Advice"
            subText={null}
          />
        )}
      </>
    );
  };

  const renderOptimizationsBtn = () => {
    if (!profile.currentReport) return null;
    const status = profile.currentReport ? profile.currentReport.status : null;
    const isSeen = profile.currentReport ? profile.currentReport.isSeen : null;

    return (
      <>
        {!isSeen && status === 3 ? (
          <Button
            to="/account/your_optimizations"
            icon="switcher"
            text="You have a new optimization ready to view!"
            seen="true"
            subText={null}
          />
        ) : (
          <Button
            to="/account/your_optimizations"
            icon="switcher"
            text="Your Optimizations"
            subText="See full history"
          />
        )}
      </>
    );
  };
  return (
    <Wrapper>
      <Header title="Your Profile" bigTitle={true} />
      <FlexWrapper row>
        {renderStatusBtn()}

        {renderOptimizationsBtn()}
        {profile.currentReport && (
          <Button
            to="/account/your_uploads"
            icon="file-text"
            text="Your Uploads"
            subText="See your pamphlets"
          />
        )}
        <Button
          to="/calculator"
          icon="calculator"
          text="Calculator"
          subText='Try out "what-if" scenarios for the best cost prediction'
        />
        {/* <Button
          to="/self-serve"
          icon="control"
          text="Customize"
          subText="Manually input your plan details yourself!"
        /> */}
      </FlexWrapper>
    </Wrapper>
  );
};

export default AccountNavigation;
