import React from "react";
import styled from "styled-components";
import {
  TableRow,
  TableCell,
  Link,
  Currency,
  Icon
} from "components";


const StyledTableCell = styled(TableCell)`
  ${({ padding }) => padding && `padding: ${padding}`};
  font-family: circular;
  color: #21355B;
`;

const TableBody = ({ bills: body, handleShow, handleCheck, isDisabled }) => {
  const bills = body || [];

  const renderBill = (
    {
      _id,
      isDoctorInNetwork,
      year,
      isChecked,
      practice: doctor,
      hospital,
      proceduresTitle,
      broaderNetwork,
      chargesWithInsurance,
      chargesPayCompletely,
      totalCharges,
    },
    index
  ) => (
    <TableRow border height={80} fontSize={14} color={"white"} key={_id}>
      <StyledTableCell style={{ position: "relative" }} >
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => handleCheck(_id)}
          disabled={isDisabled}
        />
      </StyledTableCell>
      <StyledTableCell className="mobile-hide">
        {year}
      </StyledTableCell>
      <StyledTableCell ms short title={doctor.name}>
        {doctor.name}
      </StyledTableCell>

      <StyledTableCell
        ms
        short
        title={hospital.name}
        className="mobile-hide"
      >
        {hospital.name}
      </StyledTableCell>
      <StyledTableCell
        short
        className="mobile-hide">
        {proceduresTitle}
      </StyledTableCell>

      <StyledTableCell
        ms
        short
        left
        title={broaderNetwork}
        className="mobile-hide"
      >
        {broaderNetwork}
      </StyledTableCell>
      <StyledTableCell center
        className="mobile-hide">
        {isDoctorInNetwork ? "Yes" : "No"}
      </StyledTableCell>

      <StyledTableCell
        right={chargesPayCompletely}
        center={!chargesPayCompletely}
        className="mobile-hide">
        {chargesPayCompletely ? <Currency price={chargesPayCompletely} /> : "-"}
      </StyledTableCell>

      <StyledTableCell
        right
        className="mobile-hide">
        <Currency price={chargesWithInsurance} />
      </StyledTableCell>

      <StyledTableCell
        right={totalCharges}
        center={!totalCharges} >
        {totalCharges ? <Currency price={totalCharges} /> : "-"}
      </StyledTableCell>
      <StyledTableCell padding={"6px 0 0"}>
        <Link onClick={() => handleShow(_id)} green>
          <Icon magnifyingGlass backgroundSize={14} />
        </Link>
      </StyledTableCell>
    </TableRow>
  );
  return <tbody>{bills.map(renderBill)}</tbody>;
};

export default TableBody;
