import React, { useEffect, useState } from "react";

import { FieldArray, useField } from "formik";
import Text from "components/atoms/Text";
import EditableCell from "../EditableCell";
import { Table } from "antd";
import "./styles.css";

export function processColumns(columns) {
  let newColumns = [...columns];

  // Forcing all Index columns to have the same size to maintain style
  const isID = newColumns?.[0]?.dataIndex?.startsWith?.("id");
  if (isID) {
    newColumns[0].width = 30;
  }

  // Don't make any columns fixed other than the ID for mobile view size
  if (window.innerWidth <= 1024) {
    newColumns = newColumns.map((column, index) => {
      if (index === 0) return column;
      delete column.fixed;
      return column;
    });
  }

  return newColumns;
}

const TableField = ({ name, dataSource, columns, pageSize, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const [page, setPage] = useState(1);

  return (
    <>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <Table
            {...props}
            tableLayout="auto"
            components={{
              body: {
                cell: (props) =>
                  EditableCell({
                    ...props,
                    name: name,
                    table: dataSource,
                    page: page,
                    pageSize: pageSize ? pageSize : 10,
                  }),
              },
            }}
            dataSource={dataSource}
            rowKey={(item) => item.idx}
            columns={processColumns(columns)}
            className="customTable"
            pagination={{
              hideOnSinglePage: true,
              defaultPageSize: pageSize ? pageSize : 10,
              onChange: (pageIndex) => {
                setPage(pageIndex || 1);
              },
            }}
            page={page}
          />
        )}
      />
      {meta?.touched && meta?.error && <Text red>All table fields are required</Text>}
    </>
  );
};

export default TableField;
