import styled from "styled-components";
import { Text } from "components";

const Title = styled(Text)`
  ${({ modal }) =>
    modal
      ? `font-size: 20px; font-weight: bold; margin-right: 20px;`
      : ` margin: 10px 0; font-size:15px;`}
  
  ${({ card }) =>
    card
    && `font-size: 20px;
       font-weight: 
      bold; margin: 20px 0px;
       color: #1c355e; 
      line-height: 1.2em;
            font-family: 'circular'` }
`;

export default Title;
