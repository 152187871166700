import React from "react";
import { ModalView, Text } from "components";
import styled from "styled-components";

const TextWrapper = styled.div`
  padding: 20px 45px;
  width: 700px;
`

const InNetworkModal = ({ isShow, handleClick }) => (
  <ModalView
    close={handleClick}
    isShow={isShow}
    isInfo
    isCancel
    textButton="Close"
    title="Make sure to double-check this with your insurance plan!"
  >
    <TextWrapper>
    <Text circular modal arial>
    Every plan has a different network, and the organizations that negotiate
    on behalf of your doctor are frequently joining or leaving networks for
    different employers and insurance companies. Some doctors don’t take
    insurance at all. The safest way to verify is to call the phone number on
    the back of your health insurance card, and ask if your preferred doctor
    is in your network. If so, you should request this information in writing.
    </Text>
    </TextWrapper>
  </ModalView>
);

export default InNetworkModal;
