import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  loadRecommendedByOptmization: ["payload"],
  changeHighlightText: ["payload"],
  changeNoteText: ["payload"],
  switchState: ["payload"],
  loadRecommendedByReport: ["payload"],
  resetState: ["payload"],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
