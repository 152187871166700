import styled from "styled-components";

export const SpaceDivider = styled.div`
  margin: 4px;
`;
export const CustomVerticalSpace = styled.div`
  display: flex;
  ${({ direction = "row" }) => `flex-direction: ${direction}`}
  ${({ gap = 8 }) => `gap: ${gap}px`}
`;
