import React, { useEffect, useState } from "react";
import { Field, ErrorMessage, useFormikContext, FieldArray } from "formik";
import Text from "components/atoms/Text";
import FormField from "components/molecules/FormField";
import SelectField from "components/molecules/SelectField";
import CheckboxField from "components/molecules/CheckboxField";
import { Space, Table } from "antd";
import { formatterNumber, parserNumber } from "utils/currency";
import useCollapse from "react-collapsed";
import EditableCell from "components/molecules/EditableCell";
import {
  costForOptions,
  familyTypeOptions,
  networkOptions,
  OutOfNetworkCoverageOptions,
  yesOrNoOptions,
} from "../utils/options";
import TableField from "components/molecules/TableField";
import DateField from "components/molecules/DateField";
import TableMenu from "components/molecules/TableMenu";
import { useLocation } from "react-router-dom";

const columns = [
  {
    title: "",
    dataIndex: "idx",
    width: 21,
    editable: false,
    fixed: "left",
  },
  {
    title: "Label",
    dataIndex: "label",
    width: 100,
    editable: true,
    inputType: "text",
    shouldCellUpdate: () => false,
    fixed: "left",
    disabled: true,
  },
  {
    title: "Insurance Network",
    dataIndex: "insuranceNetwork",
    className: "insuranceNetwork-col",
    width: 200,
    editable: true,
    inputType: "autocomplete",
    dropdownMatchSelectWidth: 350,
    options: networkOptions,
    shouldCellUpdate: () => false,
    disabled: true,
  },
  {
    title: "Out-of-Network Coverage",
    dataIndex: "outOfNetworkCoverage",
    width: 105,
    editable: true,
    inputType: "select",
    dropdownMatchSelectWidth: 200,
    options: OutOfNetworkCoverageOptions,
    shouldCellUpdate: () => false,
    disabled: true,
  },
  {
    title: "In-Network Individual OOP Limit",
    dataIndex: "inNetworkIndividualOOP",
    width: 110,
    editable: true,
    inputType: "currency",
    shouldCellUpdate: () => false,
  },
  {
    title: "In-Network Individual-in-Family OOP Limit",
    dataIndex: "inNetworkIndividualInFamilyOOP",
    width: 142,
    editable: true,
    inputType: "currencyAddable",
    emptyValue: "None",
    shouldCellUpdate: (record, prevRecord) =>
      record.hasinNetworkIndividualInFamilyOOP !== prevRecord.hasinNetworkIndividualInFamilyOOP,
  },
  {
    title: (
      <>
        <p style={{ margin: 0 }}>In-Network Family OOP Limit</p>
        <CheckboxField name="showIndividualInFamilyOOP" label="more" />
      </>
    ),
    dataIndex: "inNetworkFamilyOOP",
    width: 110,
    editable: true,
    inputType: "currency",
    shouldCellUpdate: () => false,
  },
  {
    title: "Out-of-Network Individual OOP Limit",
    dataIndex: "outOfNetworkIndividualOOP",
    width: 142,
    editable: true,
    inputType: "currencyAddable",
    emptyValue: "Unlimited",
    shouldCellUpdate: (record, prevRecord) =>
      record.hasoutOfNetworkIndividualOOP !== prevRecord.hasoutOfNetworkIndividualOOP,
  },
  {
    title: "Out-Of-Network Individual-in-Family OOP Limit",
    dataIndex: "outOfNetworkIndividualInFamilyOOP",
    width: 142,
    editable: true,
    inputType: "currencyAddable",
    emptyValue: "None",
    shouldCellUpdate: (record, prevRecord) =>
      record.hasoutOfNetworkIndividualInFamilyOOP !== prevRecord.hasoutOfNetworkIndividualInFamilyOOP,
  },
  {
    title: (
      <>
        <p style={{ margin: 0 }}>Out-of-Network Family OOP Limit</p>
        <CheckboxField name="showIndividualInFamilyOOP" label="more" />
      </>
    ),
    dataIndex: "outOfNetworkFamilyOOP",
    width: 142,
    editable: true,
    inputType: "currencyAddable",
    emptyValue: "Unlimited",
    shouldCellUpdate: (record, prevRecord) => record.hasoutOfNetworkFamilyOOP !== prevRecord.hasoutOfNetworkFamilyOOP,
  },
  {
    title: "",
    key: "operation",
    fixed: "right",
    width: 62,
    render: (_, record) => <TableMenu step={4} record={record} />,
  },
].map((col) => {
  return {
    ...col,
    onCell: (_, idx) => ({
      index: idx,
      editable: col.editable,
      dataIndex: col.dataIndex,
      title: col.title,
      inputType: col.inputType,
      options: col.options,
      dropdownMatchSelectWidth: col.dropdownMatchSelectWidth,
      disabled: col.disabled || false,
      emptyValue: col.emptyValue,
    }),
  };
});

const filteredColumns = columns.filter((column) => !column?.dataIndex?.includes("IndividualInFamily"));

const Step = () => {
  const { values, setErrors, setTouched } = useFormikContext();
  const { state } = useLocation();
  const { table, showIndividualInFamilyOOP, planOptions } = values;

  useEffect(() => {
    document.getElementById("form-content").scrollTo({ top: 0, behavior: "smooth" });
    setErrors({});
    setTouched({}, false);
  }, []);

  const data = table.slice(0, planOptions);

  return (
    <>
      <Text sectionTitle>Annual Maximum Out-of-Pocket (OOP) Limits</Text>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <DateField name="startDate" label="My OOP limit starts at $0 (zero) in:" inline size="small" />

        {window.location.pathname.includes("create-plan") && (
          <CheckboxField
            name="enterAdvancedDetails"
            label="I want to enter Advanced details about my deductibles and limits"
          />
        )}

        <TableField
          name="table"
          columns={showIndividualInFamilyOOP ? columns : filteredColumns}
          dataSource={data}
          scroll={{ x: showIndividualInFamilyOOP ? "80vw" : false }}
          tableLayout="fixed"
        />
      </Space>
    </>
  );
};

export default Step;
