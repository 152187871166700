import React from "react";
import styled from "styled-components";
import Text from "components/atoms/Text";
import { Radio } from "antd";

const Wrapper = styled.div`
  padding: 0;
`

const Questions = ({ option, setOption }) => {
  return (
    <div style={{ position: "relative" }}>
      <Wrapper>
        <Text sectionTitle styles={{ marginBottom: 0 }}>{
          `How would you like to add a new employer?`
        }</Text>
        <Radio.Group value={option} onChange={(e) => setOption(e.target.value)}>
          <Radio value="upload">Upload Pamphlet</Radio>
          <Radio value="create">Enter plan info myself</Radio>
        </Radio.Group>
      </Wrapper >
    </div>
  )
}

export default Questions;