import React from "react";
import styled from "styled-components";
import { Text, BlockTotalCost } from "components";

const Wrapper = styled.div`
  margin-top: 10px;
  padding: 0;
  align-self: flex-start;
  width: 100%;
`;
const Header = styled.div`
  text-align: right;
`;

const InnerWrapper = styled.div`
  min-height: 80px;
  margin-top: 10px;

  @media (max-width: 480px) {
    padding: 0px;
  }
`;

const renderRow = (data) => {
  return (
    <InnerWrapper key={data.total.value}>
      <BlockTotalCost
        data={data.details}
        title={data.total.title}
        value={data.total.value}
        newStyle={data.newStyle}
      />
    </InnerWrapper>
  );
};

const CalculatePriceForm = ({ charges, noText, newStyle}) => {
  return (
    <Wrapper>
      <Header>{!noText && <Text circular arial>You'd Pay</Text>}</Header>
      {charges.map(el => renderRow({...el, newStyle}))}
    </Wrapper>
  );
};

export default React.memo(CalculatePriceForm);
