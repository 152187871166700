export const AlignmentEnums = {
  Center: "center",
  Left: "left",
  Right: "right"
};

export const ContentFormat = {
  Text: "string",
  Currency: "currency",
  Some: "boolean",
  Yes: "boolean",
  "$#,##0": "currency",
  Plural: "plural"
};
