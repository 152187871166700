import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import Text from "components/atoms/Text";
import { Space } from "antd";

import {
  networkOptions,
  yesOrNoOptions,
  yesOrNoHealthOptions,
  healthFrequencyOptions,
  healthTowardOptions,
} from "../utils/options";
import TableField from "components/molecules/TableField";
import TableMenu from "components/molecules/TableMenu";
import { autoFillHSAMarketplace, autoFillIsHSACompatible } from "../utils/table";

const columns = [
  {
    title: "",
    dataIndex: "idx",
    width: 25,
    fixed: "left",
    editable: false,
  },
  {
    title: "Label",
    dataIndex: "label",
    width: 100,
    editable: true,
    inputType: "text",
    fixed: "left",
    shouldCellUpdate: () => false,
    disabled: true,
  },
  {
    title: "Insurance Network",
    dataIndex: "insuranceNetwork",
    className: "insuranceNetwork-col",
    width: 200,
    editable: true,
    inputType: "autocomplete",
    dropdownMatchSelectWidth: 350,
    options: networkOptions,
    shouldCellUpdate: () => false,
    disabled: true,
  },
  {
    title: "Is this an HSA-Compatible HDHP?",
    dataIndex: "isHSACompatible",
    width: 100,
    editable: true,
    inputType: "select",
    twice: true,
    options: yesOrNoOptions,
    disableCondition: "isHSACompatibleDisabled",
    shouldCellUpdate: false,
  },
  {
    title: "Can you enroll in a Health Care FSA?",
    dataIndex: "enrollHealthCareFSA",
    width: 100,
    editable: true,
    inputType: "select",
    twice: true,
    options: yesOrNoOptions,
    shouldCellUpdate: false,
    disableCondition: "isHSACompatible",
    disabledValue: "no",
  },
  {
    title: "Does this employer fund an HSA or HRA for this plan on your behalf?",
    dataIndex: "isHsaOrHraCompatible",
    width: 225,
    editable: true,
    inputType: "select",
    dropdownMatchSelectWidth: 335,
    twice: true,
    options: yesOrNoHealthOptions,
    shouldCellUpdate: false,
    filterOption: (item, row) => row.isHSACompatible !== "no" || (item.value !== "both" && item.value !== "HSA"),
  },
  {
    title: "",
    dataIndex: "hsaOrHra",
    width: 50,
    inputType: "hsaOrHra",
    twice: true,
    editable: true,
  },
  {
    title: "How much do they fund for the year?",
    dataIndex: ["individual", "family", "largeFamily", "deposited", "toward"],
    width: 615,
    editable: true,
    dropdownMatchSelectWidth: 375,
    inputType: "healthFund",
    options: [healthFrequencyOptions, healthTowardOptions],
    shouldCellUpdate: (record, prevRecord) => record.isHsaOrHraCompatible !== prevRecord.isHsaOrHraCompatible,
  },
  {
    title: "",
    key: "operation",
    fixed: "right",
    width: 64,
    render: (_, record) => <TableMenu step={5} record={record} />,
  },
].map((col) => {
  return {
    ...col,
    onCell: (_, idx) => ({
      index: idx,
      editable: col.editable,
      dataIndex: col.dataIndex,
      title: col.title,
      inputType: col.inputType,
      options: col.options,
      dropdownMatchSelectWidth: col.dropdownMatchSelectWidth,
      twice: col.twice,
      disabled: col.disabled || false,
      disableCondition: col.disableCondition,
      disabledValue: col.disabledValue,
      filterOption: col.filterOption,
    }),
  };
});

const Step = () => {
  const { values, setErrors, setTouched, setFieldValue } = useFormikContext();
  const { table, planOptions, partnerJustForComparison } = values;

  useEffect(() => {
    document.getElementById("form-content").scrollTo({ top: 0, behavior: "smooth" });
    setErrors({});
    setTouched({}, false);
  }, []);

  useEffect(() => {
    let data = !table.some((element) => element.id) ? autoFillIsHSACompatible(table) : table;

    if (partnerJustForComparison === "marketplace") {
      columns.splice(4, 3);
      data = autoFillHSAMarketplace(data);
    }

    setFieldValue("table", data);
  }, [partnerJustForComparison]);

  const data = table.slice(0, planOptions);

  const hasSomeHsaOrHra = data.some((element) => element.isHsaOrHraCompatible && element.isHsaOrHraCompatible !== "no");

  return (
    <>
      <Text sectionTitle>{partnerJustForComparison === "marketplace" ? "HSAs" : "FSAs, HSAs, and HRAs"}</Text>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Text formHSALabel>
          {partnerJustForComparison === "marketplace"
            ? `Are any of these plans a High-Deductible Health Plan ("HDHP") that makes you eligible to contribute to a Health Savings Account ("HSA")? If you think one of these plans should be HSA-eligible but it's not showing up that way, double-check the plan deductibles and limits to make sure it meets the IRS eligibility rules (you can edit your plans if you made a mistake).`
            : `Does your employer allow you to contribute to a Flexible Spending
          Account ("FSA"), or is your plan a High-Deductible Health Plan
          ("HDHP") that makes you eligible to contribute to a Health Savings
          Account ("HSA")? Maybe your employer also funds a Health Reimbursement
          Account ("HRA") for you. Tell us about these accounts here. If you
          think one of these plans should have an HSA but it's not showing up
          that way, double-check the plan deductibles and limits to make sure it
          meets the IRS eligibility rules (you can edit your plans if you made a
          mistake).`}
        </Text>

        <TableField
          name="table"
          columns={
            hasSomeHsaOrHra
              ? columns
              : columns.filter(
                  (element) =>
                    element.title !== "How much do they fund for the year?" && element.dataIndex !== "hsaOrHra",
                )
          }
          dataSource={data}
          scroll={{ x: hasSomeHsaOrHra ? "80vw" : false }}
          tableLayout="fixed"
        />
      </Space>
    </>
  );
};

export default Step;
