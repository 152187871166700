import React from "react";
import styled from "styled-components";

import {
  FormCheckbox as Checkbox,
  FormInput as Input,
  FormInputNumber as InputNumber,
  FormSelect as Select,
  Text,
  Table,
  TableRow,
  TableCell,
} from "components";

import { strToBoolTrue, strToBoolFalse } from "services/utils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 10px;
  padding: 20px 5px;
  align-items: center;
  border: 1px solid black;
  border-radius: 40px;
  width: 100%;
  overflow-x: auto;
  position: relative;
`;

const FlexWrapper = styled.div`
  display: flex;
  padding: 5px 20px;
  ${({ col }) => col && `flex-direction: column; padding: 0;`}
  justify-content: space-evenly;
  margin: auto;
  ${({ align }) => align && `align-items: ${align}`}
  ${({ subTitle }) => subTitle && `display: block; width: 100%;`}
  ${({ noPadding }) => noPadding && `padding: 0;`}
`;

const CustomPlans = ({
  changePlan,
  parent,
  options,
  useOutOfNetwork,
  ...props
}) => {
  const handleChange = ({ target: { name, value } }) => {
    if (strToBoolTrue(value)) changePlan(name, true);
    else if (strToBoolFalse(value)) changePlan(name, false);
    else changePlan(name, value);
  };

  const optionsAmount = options.map((option, i) => {
    return (
      <TableCell key={i} center slim>
        <Text arial bolder>
          {"Option #" + (i + 1)}
        </Text>
      </TableCell>
    );
  });

  const placeHolderText = [
    "ex) Standard Plan",
    "ex) High Deductible Plan",
    "ex) Mid Tier Plan",
    "ex) Cheapest Plan",
  ];

  const planNames = options.map((option, i) => {
    return (
      <TableCell key={i} slim>
        <Input
          handleChange={handleChange}
          name={parent + " " + i + " name"}
          value={option.name}
          placeholder={placeHolderText[i % 4]}
        />
      </TableCell>
    );
  });

  const coverageTypes = options.map((option, i) => {
    return (
      <TableCell key={i} slim>
        <FlexWrapper col="true">
          <Checkbox
            handleChange={handleChange}
            name={parent + " " + i + " maternityCopayDetails subjToCopay"}
            value={option.maternityCopayDetails.subjToCopay}
            options={["Copay"]}
            isBool
          />
          <Checkbox
            handleChange={handleChange}
            name={parent + " " + i + " deductibles subjToDeductible"}
            value={option.deductibles.subjToDeductible}
            options={["Deductible"]}
            isBool
          />
          <Checkbox
            handleChange={handleChange}
            name={
              parent + " " + i + " maternityCopayDetails subjToPercentCoins"
            }
            value={option.maternityCopayDetails.subjToPercentCoins}
            options={["Coinsurance"]}
            isBool
          />
        </FlexWrapper>
      </TableCell>
    );
  });

  const copayType = options.map((option, i) =>
    option.maternityCopayDetails.subjToCopay ? (
      <TableCell key={i} slim>
        <Select
          handleChange={handleChange}
          name={parent + " " + i + " maternityCopayDetails unit"}
          selection={["Per stay", "Per night"]}
          value={option.maternityCopayDetails.unit}
        />
      </TableCell>
    ) : (
      <TableCell key={i} slim></TableCell>
    )
  );

  const copayAmount = options.map((option, i) =>
    option.maternityCopayDetails.subjToCopay ? (
      <TableCell key={i} slim>
        <InputNumber
          format="currency"
          handleChange={handleChange}
          name={parent + " " + i + " maternityCopayDetails hospital"}
          value={option.maternityCopayDetails.hospital}
          min={0}
        />
      </TableCell>
    ) : (
      <TableCell key={i} slim></TableCell>
    )
  );

  const copayMaxNights = options.map((option, i) =>
    option.maternityCopayDetails.unit === "Per night" ? (
      <TableCell key={i} slim>
        <InputNumber
          handleChange={handleChange}
          name={parent + " " + i + " maternityCopayDetails maxUnits"}
          value={option.maternityCopayDetails.maxUnits}
          min={0}
        />
      </TableCell>
    ) : (
      <TableCell key={i} slim></TableCell>
    )
  );

  const copayWhoPays = options.map((option, i) =>
    option.maternityCopayDetails.subjToCopay ? (
      <TableCell key={i} slim>
        <Select
          handleChange={handleChange}
          name={parent + " " + i + " maternityCopayDetails whoPays"}
          selection={[
            "Just the birth parent",
            "Both the birth parent and the baby",
          ]}
          value={option.maternityCopayDetails.whoPays}
        />
      </TableCell>
    ) : (
      <TableCell key={i} slim></TableCell>
    )
  );

  const babyDeductible = options.map((option, i) =>
    option.deductibles.subjToDeductible ? (
      <TableCell key={i} slim>
        <Select
          handleChange={handleChange}
          name={parent + " " + i + " deductibles baby isUsed"}
          selection={option.deductibles.baby.data}
          value={option.deductibles.baby.isUsed}
          isBool
        />
      </TableCell>
    ) : (
      <TableCell key={i} slim></TableCell>
    )
  );

  const IndivInAFamily = options.map((option, i) =>
    option.deductibles.subjToDeductible ? (
      <TableCell key={i} slim>
        <Select
          handleChange={handleChange}
          name={parent + " " + i + " deductibles familyType"}
          selection={[
            "No, I can hit lower numbers if it's just me (this is uncommon)",
            "Yes, I have to hit them both",
          ]}
          value={option.deductibles.familyType}
          isBool
        />
      </TableCell>
    ) : (
      <TableCell key={i} slim></TableCell>
    )
  );

  const planLimitsHeader = options.map((option, i) => {
    return (
      <TableCell key={i} slim padding="2px 5px">
        <FlexWrapper noPadding="true" align="flex-end">
          <TableCell ms tall position="center" center padding="2px">
            <Text lineHeight="normal">Deductible</Text>
          </TableCell>
          <TableCell ms tall position="center" center padding="2px">
            <Text lineHeight="normal">Maximum Out-of-Pocket Limit</Text>
          </TableCell>
        </FlexWrapper>
      </TableCell>
    );
  });

  const inNetworkIndividualLimits = options.map((option, i) => {
    return (
      <TableCell key={i} slim padding="2px 5px">
        <FlexWrapper noPadding="true">
          <TableCell tall padding="2px">
            <InputNumber
              format="currency"
              handleChange={handleChange}
              name={parent + " " + i + " inNetwork individual deductible"}
              value={option.inNetwork.individual.deductible}
              min={0}
              disabled={!option.deductibles.subjToDeductible}
            />
          </TableCell>
          <TableCell tall padding="2px">
            <InputNumber
              format="currency"
              handleChange={handleChange}
              name={parent + " " + i + " inNetwork individual oopMax"}
              value={option.inNetwork.individual.oopMax}
              min={option.inNetwork.individual.deductible}
            />
          </TableCell>
        </FlexWrapper>
      </TableCell>
    );
  });

  const inNetworkIndivInAFamilyLimits = options.map((option, i) => {
    return (
      <TableCell key={i} slim padding="2px 5px">
        <FlexWrapper noPadding="true">
          {option.deductibles.familyType === false && (
            <>
              <TableCell tall padding="2px">
                <InputNumber
                  format="currency"
                  handleChange={handleChange}
                  name={
                    parent + " " + i + " inNetwork family individualDeductible"
                  }
                  value={option.inNetwork.family.individualDeductible}
                  min={0}
                  disabled={!option.deductibles.subjToDeductible}
                />
              </TableCell>
              <TableCell tall padding="2px">
                <InputNumber
                  format="currency"
                  handleChange={handleChange}
                  name={parent + " " + i + " inNetwork family individualOopMax"}
                  value={option.inNetwork.family.individualOopMax}
                  min={option.inNetwork.family.individualDeductible}
                />
              </TableCell>
            </>
          )}
        </FlexWrapper>
      </TableCell>
    );
  });

  const inNetworkFamilyLimits = options.map((option, i) => {
    return (
      <TableCell key={i} slim padding="2px 5px">
        <FlexWrapper noPadding="true">
          <TableCell tall padding="2px">
            <InputNumber
              format="currency"
              handleChange={handleChange}
              name={parent + " " + i + " inNetwork family deductible"}
              value={option.inNetwork.family.deductible}
              min={0}
              disabled={!option.deductibles.subjToDeductible}
            />
          </TableCell>
          <TableCell tall padding="2px">
            <InputNumber
              format="currency"
              handleChange={handleChange}
              name={parent + " " + i + " inNetwork family oopMax"}
              value={option.inNetwork.family.oopMax}
              min={option.inNetwork.family.deductible}
            />
          </TableCell>
        </FlexWrapper>
      </TableCell>
    );
  });

  const inNetworkCoinsurance = options.map((option, i) =>
    option.maternityCopayDetails.subjToPercentCoins ? (
      <TableCell key={i} slim tall>
        <InputNumber
          format="percent"
          handleChange={handleChange}
          name={parent + " " + i + " inNetwork coinsurance rate"}
          value={option.inNetwork.coinsurance.rate}
          min={0}
          max={50}
        />
      </TableCell>
    ) : (
      <TableCell key={i} slim tall></TableCell>
    )
  );

  const outOfNetworkIndividualLimits = options.map((option, i) => {
    return (
      <TableCell key={i} slim padding="2px 5px">
        <FlexWrapper noPadding="true">
          <TableCell tall padding="2px">
            <InputNumber
              format="currency"
              handleChange={handleChange}
              name={parent + " " + i + " outOfNetwork individual deductible"}
              value={option.outOfNetwork.individual.deductible}
              min={0}
              disabled={!option.deductibles.subjToDeductible}
            />
          </TableCell>
          <TableCell tall padding="2px">
            <InputNumber
              format="currency"
              handleChange={handleChange}
              name={parent + " " + i + " outOfNetwork individual oopMax"}
              value={option.outOfNetwork.individual.oopMax}
              min={option.outOfNetwork.individual.deductible}
            />
          </TableCell>
        </FlexWrapper>
      </TableCell>
    );
  });

  const outOfNetworkIndivInAFamilyLimits = options.map((option, i) => {
    return (
      <TableCell key={i} slim padding="2px 5px">
        <FlexWrapper noPadding="true">
          {option.deductibles.familyType === false && (
            <>
              <TableCell tall padding="2px">
                <InputNumber
                  format="currency"
                  handleChange={handleChange}
                  name={
                    parent +
                    " " +
                    i +
                    " outOfNetwork family individualDeductible"
                  }
                  value={option.outOfNetwork.family.individualDeductible}
                  min={0}
                  disabled={!option.deductibles.subjToDeductible}
                />
              </TableCell>
              <TableCell tall padding="2px">
                <InputNumber
                  format="currency"
                  handleChange={handleChange}
                  name={
                    parent + " " + i + " outOfNetwork family individualOopMax"
                  }
                  value={option.outOfNetwork.family.individualOopMax}
                  min={option.outOfNetwork.family.individualDeductible}
                />
              </TableCell>
            </>
          )}
        </FlexWrapper>
      </TableCell>
    );
  });

  const outOfNetworkFamilyLimits = options.map((option, i) => {
    return (
      <TableCell key={i} slim padding="2px 5px">
        <FlexWrapper noPadding="true">
          <TableCell tall padding="2px">
            <InputNumber
              format="currency"
              handleChange={handleChange}
              name={parent + " " + i + " outOfNetwork family deductible"}
              value={option.outOfNetwork.family.deductible}
              min={0}
              disabled={!option.deductibles.subjToDeductible}
            />
          </TableCell>
          <TableCell tall padding="2px">
            <InputNumber
              format="currency"
              handleChange={handleChange}
              name={parent + " " + i + " outOfNetwork family oopMax"}
              value={option.outOfNetwork.family.oopMax}
              min={option.outOfNetwork.family.deductible}
            />
          </TableCell>
        </FlexWrapper>
      </TableCell>
    );
  });

  const outOfNetworkCoinsurance = options.map((option, i) =>
    option.maternityCopayDetails.subjToPercentCoins ? (
      <TableCell key={i} slim tall>
        <InputNumber
          format="percent"
          handleChange={handleChange}
          name={parent + " " + i + " outOfNetwork coinsurance rate"}
          value={option.outOfNetwork.coinsurance.rate}
          min={0}
          max={50}
        />
      </TableCell>
    ) : (
      <TableCell key={i} slim tall></TableCell>
    )
  );

  const deductibleCross = options.map((option, i) => {
    return (
      <TableCell key={i} slim tall>
        <Select
          handleChange={handleChange}
          name={parent + " " + i + " deductibles crossAccumulatable"}
          selection={["No", "Yes"]}
          value={option.deductibles.crossAccumulatable}
          isBool
        />
      </TableCell>
    );
  });

  const chargeFrequency = options.map((option, i) => {
    return (
      <TableCell key={i} slim>
        <Select
          handleChange={handleChange}
          name={parent + " " + i + " charge frequency value"}
          selection={option.charge.frequency.data}
          value={option.charge.frequency.value}
        />
      </TableCell>
    );
  });

  const chargeMO = options.map((option, i) => {
    return (
      <TableCell key={i} slim>
        <InputNumber
          format="currency"
          handleChange={handleChange}
          name={parent + " " + i + " charge mo"}
          value={option.charge.mo}
          min={0}
        />
      </TableCell>
    );
  });

  const chargeMS = options.map((option, i) => {
    return (
      <TableCell key={i} slim>
        <InputNumber
          format="currency"
          handleChange={handleChange}
          name={parent + " " + i + " charge ms"}
          value={option.charge.ms}
          min={0}
        />
      </TableCell>
    );
  });

  const chargeMC = options.map((option, i) => {
    return (
      <TableCell key={i} slim>
        <InputNumber
          format="currency"
          handleChange={handleChange}
          name={parent + " " + i + " charge mc"}
          value={option.charge.mc}
          min={0}
        />
      </TableCell>
    );
  });

  const chargeMF = options.map((option, i) => {
    return (
      <TableCell key={i} slim>
        <InputNumber
          format="currency"
          handleChange={handleChange}
          name={parent + " " + i + " charge mf"}
          value={option.charge.mf}
          min={0}
        />
      </TableCell>
    );
  });

  const hsaIsApply = options.map((option, i) => {
    return (
      <TableCell key={i} slim>
        <Select
          handleChange={handleChange}
          name={parent + " " + i + " hsa isApply"}
          selection={["No", "Yes"]}
          value={option.hsa.isApply}
          isBool
        />
      </TableCell>
    );
  });

  const hsaIndividual = options.map((option, i) =>
    option.hsa.isApply ? (
      <TableCell key={i} slim>
        <InputNumber
          format="currency"
          handleChange={handleChange}
          name={parent + " " + i + " hsa amountIndividual"}
          value={option.hsa.amountIndividual}
          min={0}
        />
      </TableCell>
    ) : (
      <TableCell key={i} slim></TableCell>
    )
  );

  const hsaFamily = options.map((option, i) =>
    option.hsa.isApply ? (
      <TableCell key={i} slim>
        <InputNumber
          format="currency"
          handleChange={handleChange}
          name={parent + " " + i + " hsa amountFamily"}
          value={option.hsa.amountFamily}
          min={0}
        />
      </TableCell>
    ) : (
      <TableCell key={i} slim></TableCell>
    )
  );

  const hsaFrequency = options.map((option, i) =>
    option.hsa.isApply ? (
      <TableCell key={i} slim>
        <Select
          handleChange={handleChange}
          name={parent + " " + i + " hsa frequency value"}
          selection={option.hsa.frequency.data}
          value={option.hsa.frequency.value}
        />
      </TableCell>
    ) : (
      <TableCell key={i} slim></TableCell>
    )
  );

  if (props[parent].options.length === 0)
    return (
      <Wrapper>
        <Text arial bolder>
          No information required in this section.
        </Text>
      </Wrapper>
    );

  return (
    <Wrapper>
      <Table>
        <tbody style={{ overflow: "scroll" }}>
          <TableRow>
            <TableCell wide>
              <Text arial bolder>
                {parent === "birthParent"
                  ? "Birth Parent Plan"
                  : "Non-Birth Parent Plan"}
              </Text>
            </TableCell>
            {optionsAmount}
          </TableRow>

          <TableRow color="grey">
            <TableCell wide>
              <Text arial bold>
                Plan Overview
              </Text>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell wide>
              <Text arial indent>
                How should we refer to this plan?
              </Text>
            </TableCell>
            {planNames}
          </TableRow>

          <TableRow color="grey">
            <TableCell wide>
              <Text arial bold>
                Coverage
              </Text>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell wide tall>
              <Text arial indent>
                Which of the following are you responsible for under this plan
                for Labor & Delivery? (Check all that apply.)
              </Text>
            </TableCell>
            {coverageTypes}
          </TableRow>

          {options.some(
            (option) => option.maternityCopayDetails.subjToCopay
          ) && (
            <>
              <TableRow>
                <TableCell wide tall>
                  <Text arial indent>
                    What kind of copay is it?
                  </Text>
                </TableCell>
                {copayType}
              </TableRow>

              <TableRow>
                <TableCell wide tall>
                  <Text arial indent>
                    How much is the copay per stay / per night?
                  </Text>
                </TableCell>
                {copayAmount}
              </TableRow>

              {options.some(
                (option) => option.maternityCopayDetails.unit === "Per night"
              ) && (
                <TableRow>
                  <TableCell wide tall>
                    <Text arial indent>
                      What is the maximum number of nights you'd pay a copay
                      for?
                    </Text>
                  </TableCell>
                  {copayMaxNights}
                </TableRow>
              )}

              <TableRow>
                <TableCell wide tall>
                  <Text arial indent>
                    Who pays a copay?
                  </Text>
                </TableCell>
                {copayWhoPays}
              </TableRow>
            </>
          )}

          {options.some((option) => option.deductibles.subjToDeductible) && (
            <>
              <TableRow>
                <TableCell wide>
                  <Text arial indent>
                    Does the baby get charged a deductible too?
                  </Text>
                </TableCell>
                {babyDeductible}
              </TableRow>

              <TableRow>
                <TableCell wide>
                  <Text arial indent>
                    Do you have to hit the whole family deductible and
                    coinsurance maximum by yourself?
                  </Text>
                </TableCell>
                {IndivInAFamily}
              </TableRow>
            </>
          )}

          <TableRow>
            <TableCell wide>
              <Text arial indent>
                Fill in your in-network plan limits.
              </Text>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell wide></TableCell>
            {planLimitsHeader}
          </TableRow>

          <TableRow>
            <TableCell wide>
              <Text arial indentMore>
                Individual
              </Text>
            </TableCell>
            {inNetworkIndividualLimits}
          </TableRow>

          {options.some(
            (option) => option.deductibles.familyType === false
          ) && (
            <TableRow>
              <TableCell wide>
                <Text arial indentMore>
                  Individual-in-a-Family
                </Text>
              </TableCell>
              {inNetworkIndivInAFamilyLimits}
            </TableRow>
          )}

          <TableRow>
            <TableCell wide>
              <Text arial indentMore>
                Family
              </Text>
            </TableCell>
            {inNetworkFamilyLimits}
          </TableRow>

          {options.some(
            (option) => option.maternityCopayDetails.subjToPercentCoins
          ) && (
            <TableRow>
              <TableCell wide>
                <Text arial indentMore>
                  Coinsurance (enter the percentage YOU pay, not what your
                  insurance pays)
                </Text>
              </TableCell>
              {inNetworkCoinsurance}
            </TableRow>
          )}

          {useOutOfNetwork && (
            <>
              <TableRow></TableRow>
              <TableRow>
                <TableCell wide>
                  <Text arial indent>
                    Fill in your out-of-network plan limits.
                  </Text>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell wide></TableCell>
                {planLimitsHeader}
              </TableRow>

              <TableRow>
                <TableCell wide>
                  <Text arial indentMore>
                    Individual
                  </Text>
                </TableCell>
                {outOfNetworkIndividualLimits}
              </TableRow>

              {options.some(
                (option) => option.deductibles.familyType === false
              ) && (
                <TableRow>
                  <TableCell wide>
                    <Text arial indentMore>
                      Individual-in-a-Family
                    </Text>
                  </TableCell>
                  {outOfNetworkIndivInAFamilyLimits}
                </TableRow>
              )}

              <TableRow>
                <TableCell wide>
                  <Text arial indentMore>
                    Family
                  </Text>
                </TableCell>
                {outOfNetworkFamilyLimits}
              </TableRow>

              {options.some(
                (option) => option.maternityCopayDetails.subjToPercentCoins
              ) && (
                <TableRow>
                  <TableCell wide>
                    <Text arial indentMore>
                      Coinsurance (enter the percentage YOU pay, not what your
                      insurance pays)
                    </Text>
                  </TableCell>
                  {outOfNetworkCoinsurance}
                </TableRow>
              )}

              <TableRow>
                <TableCell wide>
                  <Text arial indentMore>
                    Is your deductible cross-Accumulatable? (costs are counted
                    towards both in-network and out-of-network deductibles)
                  </Text>
                </TableCell>
                {deductibleCross}
              </TableRow>
            </>
          )}

          <TableRow></TableRow>

          <TableRow color="grey">
            <TableCell wide>
              <Text arial bold>
                Premiums (Paycheck Deductions)
              </Text>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell wide>
              <Text arial indent>
                How often do you pay premiums? (this is often deducted from your
                paycheck)
              </Text>
            </TableCell>
            {chargeFrequency}
          </TableRow>

          <TableRow>
            <TableCell wide>
              <Text arial indent>
                How much does this plan cost per pay period?
              </Text>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell wide>
              <Text arial indentMore>
                …for you only?
              </Text>
            </TableCell>
            {chargeMO}
          </TableRow>

          <TableRow>
            <TableCell wide>
              <Text arial indentMore>
                …for you and your partner to join?
              </Text>
            </TableCell>
            {chargeMS}
          </TableRow>

          <TableRow>
            <TableCell wide>
              <Text arial indentMore>
                …for you and your child(ren) to join?
              </Text>
            </TableCell>
            {chargeMC}
          </TableRow>

          <TableRow>
            <TableCell wide>
              <Text arial indentMore>
                …for you, your partner, and your child(ren) to join?
              </Text>
            </TableCell>
            {chargeMF}
          </TableRow>

          <TableRow color="grey">
            <TableCell wide>
              <Text arial bold>
                HSA
              </Text>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell wide>
              <Text arial indent>
                Does this plan have an option that puts free money into an HSA?
              </Text>
            </TableCell>
            {hsaIsApply}
          </TableRow>

          {options.some((option) => option.hsa.isApply) && (
            <>
              <TableRow>
                <TableCell wide>
                  <Text arial indent>
                    What is your company's HSA contribution for an individual?
                  </Text>
                </TableCell>
                {hsaIndividual}
              </TableRow>

              <TableRow>
                <TableCell wide>
                  <Text arial indent>
                    What is your company's HSA contribution for a family?
                  </Text>
                </TableCell>
                {hsaFamily}
              </TableRow>

              <TableRow>
                <TableCell wide>
                  <Text arial indent>
                    How often does your company contribute to your HSA?
                  </Text>
                </TableCell>
                {hsaFrequency}
              </TableRow>
            </>
          )}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default React.memo(CustomPlans);
