import React, { useEffect } from "react";

import styled from "styled-components";

import { Widget } from "@typeform/embed-react";
import { useLocation } from "react-router-dom";
import { typeformUpdate } from "services/api";
import PrimaryNavigation from "components/molecules/PrimaryNavigation";

const Wrapper = styled(Widget)`
  position: relative;
  height: 100vh;
  overflow-y: hidden;
`;
const SignupTypeFormPage = () => {
  const location = useLocation();

  const { state } = location;
  const { formType, email, url } = state;

  return (
    <>
      <PrimaryNavigation isTypeform />
      <Wrapper
        id={url}
        onSubmit={(e) => {
          if (formType === "lVuTDexM") {
            const { responseId } = e;
            typeformUpdate({ username: email, formType, responseId });
          }
        }}
      />
    </>
  );
};

export default SignupTypeFormPage;
