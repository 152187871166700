import React from "react";
import { Text, IconLink, Heading, Link, Icon } from "components";
import styled from "styled-components";

const Block = styled.div`
  text-align: center;
  margin-top: 26vh;

  @media (max-width: 480px) {
    margin-top: 13vh;
    width: 100vw;
  }
`;

const BlockText = ({ icon, heading, sub, link, href, destinationRoute }) => {
  return (
    <Block>
      <div>{icon && <Icon lock />}</div>
      <IconLink href="https://predictabill.com/" logo />
      <Heading>{heading}</Heading>
      <Text subHeading margin="0px 25px">
        {sub && (
          <>
            {sub}
            {link && (
              <Link
                underline="true"
                to={{
                  pathname: link,
                  destinationRoute: destinationRoute,
                }}
              >
                here
              </Link>
            )}
            {href && (
              <Link underline="true" href={href}>
                here
              </Link>
            )}
            .
          </>
        )}
      </Text>
    </Block>
  );
};
export default BlockText;
