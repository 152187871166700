import { connect } from "react-redux";
import { CalculateBirthPlan } from "components";
import Action from "store/plan/actions";

const mapStateToProps = (state) => {
  const plan = state.plan;
  return {
    ...plan,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changePlan: (name, value) => {
    dispatch(Action.Creators.changeBirthPlan(name, value));
  },
  changeOptions: (name, value) => {
    dispatch(Action.Creators.changeBirthPlanOptions(name, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CalculateBirthPlan);
