import React from "react";
import styled from "styled-components";
import { CalculateHeader as Header, ButtonLink } from "components";

const Wrapper = styled.section``;

const FlexWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InnerWrapper = styled.div`
  display: flex;
  width: 200px;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 45px 0;
`;

const OnboardingBoolSection = ({
  onChange,
  option,
  title,
  value,
  yes,
  no,
  action,
  actionText,
}) => {
  const handleChange = (value) => {
    onChange(option, value);
  };

  return (
    <Wrapper>
      <Header bigTitle={true} title={title} />
      <FlexWrapper>
        <InnerWrapper>
          <ButtonLink
            withBG
            button
            fontSize="18"
            margin="5px"
            onClick={() => handleChange(true)}
          >
            {yes ? yes : "Yes"}
          </ButtonLink>
          <ButtonLink
            withBG
            button
            fontSize="18"
            margin="5px"
            onClick={() => handleChange(false)}
          >
            {no ? no : "No"}
          </ButtonLink>
        </InnerWrapper>

        <br></br>
        {action && (
          <ButtonLink withBG button fontSize="18" onClick={action}>
            {actionText}
          </ButtonLink>
        )}
      </FlexWrapper>
    </Wrapper>
  );
};

export default OnboardingBoolSection;
