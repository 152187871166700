import React from "react";
import { connect } from "react-redux";
import planActions from "store/plan/actions";
import styled from "styled-components";
import {
  getRole,
  optimizeKnown,
  getKnownOpt,
  getKnownOpts,
} from "services/api/admin.api";
import { getToken } from "services/utils";
import { message } from "antd";

import {
  PageTemplate,
  Header,
  Footer,
  CalculateFormTemplate,
  CalculatePriceForm,
  GrandTotal,
  Text,
  Link,
  Spinner,
  Currency,
  ButtonLink,
  FormSelect as Select,
  FormInput as Input,
  FormDate as Date,
} from "components";

const Wrapper = styled.section`
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FlexWrapper = styled.div`
  display: flex;
  ${({ col }) => col && `flex-direction: column`}
  ${({ left }) => left && `float: left; margin-left: 20px; margin-top: 20px;`}
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

// const InnerWrapper = styled.div`
//   ${({ left }) => left && `float: left;`}
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 10px 10px;
// `;

class KnownOptPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loading: "",
      externalId: "",
      group: "Marketplace New York",
      network: "",
      scenario: "MO",
      startDate: "04/15/2020",
      endDate: "12/31/2020",
      charges: 31652,
      knownOpts: [],
      optimization: undefined,
      error: null,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getOpt = this.getOpt.bind(this);
  }

  async componentDidMount() {
    document.title = "Known Opt - predictaBill";

    const token = await getToken();
    const response = await getRole(token);

    const knownList = await getKnownOpts();

    if (response.status === 200 && knownList.status === 200) {
      this.setState({
        loaded: true,
        knownOpts: knownList.data,
      });
    } else {
      this.props.history.push("/calculator");
    }
  }

  async handleSearch(e) {
    e.preventDefault();
    const {
      externalId,
      group,
      network,
      scenario,
      startDate,
      endDate,
      charges,
    } = this.state;

    this.setState({ error: null, loading: true });
    const token = await getToken();
    const form = {
      externalId,
      group,
      network,
      scenario,
      startDate,
      endDate,
      charges,
    };

    const response = await optimizeKnown(form, token);

    if (response.data !== null) {
      this.setState({
        optimization: response.data,
        loading: false,
        error: null,
      });
    } else {
      this.setState({
        optimization: null,
        loading: false,
        error: "Report not found",
      });
      message.error("Report not found");
    }
  }

  handleChange({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  async getOpt(id) {
    this.setState({ loading: true });

    const response = await getKnownOpt(id);

    if (response.status === 200) {
      this.setState({
        optimization: response.data,
        loading: false,
        error: null,
      });
    } else {
      this.setState({
        optimization: null,
        loading: false,
        error: "Report not found",
      });
      message.error("Report not found");
    }
  }

  render() {
    const {
      loaded,
      knownOpts,
      optimization,
      loading,
      externalId,
      group,
      network,
      scenario,
      startDate,
      endDate,
      charges,
    } = this.state;

    if (!loaded) return <></>;

    return (
      <PageTemplate header={<Header />} footer={<Footer />}>
        <FlexWrapper left>
          <FlexWrapper col left>
            <Text arial inline>
              ID:
            </Text>
            <Input
              placeholder="Optimization ID"
              name="externalId"
              handleChange={this.handleChange}
              value={externalId}
            />
            <Text arial inline>
              Group/Company:
            </Text>
            <Input
              placeholder="Group/Company"
              name="group"
              handleChange={this.handleChange}
              value={group}
            />
            <Text arial inline>
              Network
            </Text>
            <Input
              placeholder="Network"
              name="network"
              handleChange={this.handleChange}
              value={network}
            />
            <Select
              text="Scenario"
              selection={["MO", "MS", "MC", "MF"]}
              name="scenario"
              handleChange={this.handleChange}
              value={scenario}
            />
            <Text arial>Start: </Text>
            <Date
              placeholder="MM-DD-YYYY"
              name="startDate"
              handleChange={this.handleChange}
              value={startDate}
            />
            <Text arial>End: </Text>
            <Date
              placeholder="MM-DD-YYYY"
              name="endDate"
              handleChange={this.handleChange}
              value={endDate}
            />
            <Text arial inline>
              Expected Bill Charges:
            </Text>
            <Input
              placeholder="Expected Bill Charges"
              name="charges"
              handleChange={this.handleChange}
              value={charges}
            />
            <ButtonLink
              textAlign
              withBG
              button
              fontSize="18"
              onClick={this.handleSearch}
            >
              Optimize
            </ButtonLink>
          </FlexWrapper>
          <FlexWrapper col>
            {knownOpts.map((opt) => {
              return (
                <React.Fragment key={opt._id}>
                  <Link
                    green
                    fontSize={"16"}
                    onClick={() => this.getOpt(opt._id)}
                  >
                    Group: {opt.group} | ID: {opt.externalId}
                  </Link>
                  <Text arial>
                    Charges: <Currency price={opt.charges} /> | Scenario:{" "}
                    {opt.scenario}
                  </Text>
                  <Text arial>
                    {opt.dates[0]} - {opt.dates[1]}
                  </Text>
                  <Text arial>
                    Best: {opt.optimal.network}-{opt.optimal.name}
                  </Text>
                  <hr />
                </React.Fragment>
              );
            })}
          </FlexWrapper>
        </FlexWrapper>
        {loading ? (
          <Wrapper>
            Calculating & Saving Optimization...
            <Spinner />
          </Wrapper>
        ) : (
          optimization && (
            <Wrapper key={optimization._id}>
              <Text>
                {optimization.group} - {optimization.externalId}
              </Text>
              <Text>{optimization.scenario}</Text>
              <FlexWrapper>
                <CalculateFormTemplate>
                  <Text>Best of networks:</Text>
                  {optimization.best.map((best, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Text arial>
                          {best.network} - {best.name}:
                          <Currency price={best.grandTotal} />
                        </Text>
                      </React.Fragment>
                    );
                  })}
                  <hr />
                  <Text>Optimal Choice: </Text>
                  <Text arial>
                    {optimization.optimal.network} - {optimization.optimal.name}
                  </Text>
                  <CalculatePriceForm charges={optimization.optimal.charges} />
                  <GrandTotal
                    total={optimization.optimal.grandTotal}
                    title="Grand Total"
                    blue
                  />
                </CalculateFormTemplate>
              </FlexWrapper>
            </Wrapper>
          )
        )}
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bills: state.bills.body,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: () => {
      dispatch(planActions.Creators.loadOptionsRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KnownOptPage);
