import React, { useState } from "react";
import styled from "styled-components";
import Questions from "./questions";
import AttachPersonalEmail from "./attachPersonalEmail";
import ReVerifyWorkEmail from "./reVerifyWorkEmail";
import VerifyCode from "./verifyCode";
import CompleteOnboardingFooter from "./footer";
import ConfirmWorkEmailValidation from "./confirmWorkEmailValidation";

const Wrapper = styled.div`
  padding: 5% 25% 0 25%;
  width: 100%;
  overflow: hidden;
  @media (max-width: 1800px) {
    padding: 5% 20% 0 20%;
  }
  @media (max-width: 1600px) {
    padding: 5% 17.5% 0 17.5%;
  }
  @media (max-width: 1280px) {
    padding: 5% 10% 0 10%;
  }
  @media (max-width: 900px) {
    padding: 5% 5% 0 5%;
  }
`;

const CompleteGroupOnboardingPage = ({ emailFromTypeform, workEmail, group, url }) => {
  const [step, setStep] = useState(0);
  const [attachPersonalEmailOption, setAttachPersonalEmailOption] = useState("yes");
  const [previousStep, setPreviousStep] = useState(0);
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [canSendEmail, setCanSendEmail] = useState(false);
  return (
    <Wrapper>
      {emailFromTypeform === workEmail ? (
        <div className={`step-${step}`}>
          <Questions
            step={step}
            attachPersonalEmailOption={attachPersonalEmailOption}
            setAttachPersonalEmailOption={setAttachPersonalEmailOption}
          />
          {attachPersonalEmailOption === "yes" ? (
            <AttachPersonalEmail
              step={step}
              previousStep={previousStep}
              setEmail={setEmail}
              setCanSendEmail={setCanSendEmail}
            />
          ) : (
            <ConfirmWorkEmailValidation
              step={step}
              previousStep={previousStep}
              title={
                "Got it. That is possible, but we need you to verify that it's really you. We'll email you now with a verification code."
              }
            />
          )}
          {attachPersonalEmailOption === "no" && (
            <VerifyCode
              title={"Please enter your verification code below to proceed."}
              step={step}
              previousStep={previousStep}
              setCode={setCode}
              stepToShow={2}
            />
          )}
        </div>
      ) : (
        <div className={`step-${step}`}>
          <ReVerifyWorkEmail
            step={step}
            previousStep={previousStep}
            title={`Someone has already attached a different personal email address to this account. In order to add ${emailFromTypeform} to this account, you'll need to re-verify ${workEmail}.`}
          />
          <VerifyCode
            title={"Please enter your verification code below to proceed."}
            step={step}
            previousStep={previousStep}
            setCode={setCode}
            stepToShow={1}
          />
        </div>
      )}
      <CompleteOnboardingFooter
        type={emailFromTypeform === workEmail ? "attachPersonalEmail" : "reVerifyWorkEmail"}
        attachPersonalEmailOption={attachPersonalEmailOption}
        emailFromTypeform={emailFromTypeform}
        workEmail={workEmail}
        email={email}
        canSendEmail={canSendEmail}
        code={code}
        step={step}
        setStep={setStep}
        setPreviousStep={setPreviousStep}
        group={group}
        url={url}
      />
    </Wrapper>
  );
};

export default CompleteGroupOnboardingPage;
