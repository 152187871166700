import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Actions from "store/profile/actions";
import { StickyContainer, Sticky } from "react-sticky";

import {
  PageTemplate,
  Header,
  Footer,
  Text,
  Spinner,
  Profile,
  ManageSubscription,
  Billing,
} from "components";
import { PageView } from "services/google";
import { Tabs } from "antd";
const { TabPane } = Tabs;

const HeadingWrapper = styled.div`
  height: 75px;
  width: 100%;
  background-color: #1c355e;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const StyledPane = styled(TabPane)`
  min-height: 300px;
  width: 100vw;
  display: grid;
  place-items: center;
`;

// Route '/account'
class AccountPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }
  componentDidMount() {
    document.title = "Account - predictaBill";
    const {
      profile: { isLoaded },
      loadProfile,
    } = this.props;
    PageView();

    if (!isLoaded) {
      loadProfile();
    }
  }

  render() {
    const { profile } = this.props;

    const renderTabBar = (props, DefaultTabBar) => {
      return (
        <Sticky bottomOffset={80}>
          {({ style }) => (
            <DefaultTabBar
              {...props}
              className="site-custom-tab-bar"
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                backgroundColor: "#1c355e",
                color: "#fff",
                zIndex: 1,
                ...style,
              }}
            />
          )}
        </Sticky>
      );
    };

    if (!profile.isLoaded) return <Spinner center top />;
    return (
      <PageTemplate header={<Header V2 />} footer={<Footer />}>
        <HeadingWrapper>
          <FlexWrapper>
            <Text white fontSize="24" bold>
              My Account
            </Text>
          </FlexWrapper>
        </HeadingWrapper>
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar}>
            <StyledPane tab="My Profile" key="1">
              <Profile profile={profile} />
            </StyledPane>
            {/* <StyledPane tab="Manage My Subscription" key="2">
              <ManageSubscription
                subscriptionTier={profile.subscriptionTier}
                subscriptionValidUntil={profile.subscriptionValidUntil}
                subscriptionAutoRenew={profile.subscriptionAutoRenew}
                subscriptionNextTier={profile.subscriptionNextTier}
              />
            </StyledPane>
            <StyledPane tab="Billing" key="3">
              <Billing subscriptionHistory={profile.subscriptionHistory} />
            </StyledPane> */}
          </Tabs>
        </StickyContainer>
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state) => {
  const { profile, plan } = state;
  return {
    profile,
    plan,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadProfile: () => {
      dispatch(Actions.Creators.loadProfileRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
