import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { strToBoolTrue, strToBoolFalse } from "services/utils";
import {
  CalculateHeader as Header,
  CalculateFormTemplate,
  Text,
  ButtonLink,
  FormSelect as Select,
  FormCheckbox as Checkbox,
} from "components";
import { getToken } from "services/utils/tokens";
import { getTiers } from "services/api/form.api";
import { updateReport } from "services/api/report.api";
import { message } from "antd";

const Wrapper = styled.section`
  margin: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FlexWrapper = styled.div`
  display: flex;
  ${({ col }) => col && `flex-direction: column`}
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const InnerWrapper = styled.div`
  padding: 0 16px;
`;

const Divider = styled.div`
  margin: 10px 0;
  ${({ line }) => line && `border-bottom: 1px solid grey;`};
`;

const UploadAdditionalDetails = ({ profile, handleRedirect }) => {
  const [firstBaby, setFirstBaby] = useState(null);
  const [premiumTierBPData, setPremiumTierBPData] = useState(null);
  const [premiumTierBPValue, setPremiumTierBPValue] = useState(null);
  const [hsaTierBPData, setHsaTierBPData] = useState(null);
  const [hsaTierBPValue, setHsaTierBPValue] = useState(null);
  const [surchargesBPData, setSurchargesBPData] = useState(null);
  const [surchargesBPValue, setSurchargesBPValue] = useState(null);

  const [premiumTierPartnerData, setPremiumTierPartnerData] = useState(null);
  const [premiumTierPartnerValue, setPremiumTierPartnerValue] = useState(null);
  const [hsaTierPartnerData, setHsaTierPartnerData] = useState(null);
  const [hsaTierPartnerValue, setHsaTierPartnerValue] = useState(null);
  const [surchargesPartnerData, setSurchargesPartnerData] = useState(null);
  const [surchargesPartnerValue, setSurchargesPartnerValue] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const token = await getToken();

      if (profile.currentReport.birthParent.company) {
        const birthParentResponse = await getTiers(
          profile.currentReport.birthParent.company,
          token
        );

        if (birthParentResponse.status === 200) {
          const {
            premiumTiers,
            hsaTiers,
            surcharges,
          } = birthParentResponse.data;
          premiumTiers.length > 0 && setPremiumTierBPData(premiumTiers);
          hsaTiers.length > 0 && setHsaTierBPData(hsaTiers);
          surcharges.length > 0 && setSurchargesBPData(surcharges);
        }
      }

      if (profile.currentReport.partner.company) {
        const partnerResponse = await getTiers(
          profile.currentReport.partner.company,
          token
        );
        if (partnerResponse.status === 200) {
          const { premiumTiers, hsaTiers, surcharges } = partnerResponse.data;
          premiumTiers.length > 0 && setPremiumTierPartnerData(premiumTiers);
          hsaTiers.length > 0 && setHsaTierPartnerData(hsaTiers);
          surcharges.length > 0 && setSurchargesPartnerData(surcharges);
        }
      }
    }

    if (profile.currentReport.status !== 4) {
      message.warning("Additional Details not required at this time");
      handleRedirect();
    } else fetchData();
  }, [
    profile.currentReport.birthParent.company,
    profile.currentReport.partner.company,
    profile.currentReport.status,
    handleRedirect,
  ]);

  const handleChange = ({ target: { name, value } }) => {
    if (name === "birthParent premiumTier") {
      setPremiumTierBPValue(value);
    } else if (name === "birthParent hsaTier") {
      setHsaTierBPValue(value);
    } else if (name === "birthParent surcharges") {
      setSurchargesBPValue(value);
    } else if (name === "partner premiumTier") {
      setPremiumTierPartnerValue(value);
    } else if (name === "partner hsaTier") {
      setHsaTierPartnerValue(value);
    } else if (name === "partner surcharges") {
      setSurchargesPartnerValue(value);
    }
  };

  const handleChangeBool = ({ target: { name, value } }) => {
    if (strToBoolTrue(value)) setFirstBaby(true);
    else if (strToBoolFalse(value)) setFirstBaby(false);
  };

  const handleSubmit = async () => {
    const token = await getToken();

    const form = {
      ...profile.currentReport,
      isFirst: firstBaby,
      birthParent: {
        ...profile.currentReport.birthParent,
        premiumTier: premiumTierBPValue,
        hsaTier: hsaTierBPValue,
        surcharges: surchargesBPValue,
      },
      partner: {
        ...profile.currentReport.partner,
        premiumTier: premiumTierPartnerValue,
        hsaTier: hsaTierPartnerValue,
        surcharges: surchargesPartnerValue,
      },
    };

    const response = await updateReport(profile.currentReport._id, form, token);
    if (response.status === 200) {
      message.success("Report updated");
      handleRedirect();
    } else {
      message.error("Update Failed");
      handleRedirect();
    }
  };

  return (
    <Wrapper>
      <Header
        bigTitle={true}
        title="Additional details are required for your optimization"
      />
      <FlexWrapper>
        <CalculateFormTemplate title="Birth Parent">
          <InnerWrapper>
            <Divider />
            {profile.currentReport.birthParent.company && (
              <>
                <Text select>
                  {"Company: " + profile.currentReport.birthParent.company}
                </Text>
                {premiumTierBPData && (
                  <Select
                    text="Select the option that applies"
                    selection={premiumTierBPData}
                    selectObj="tier"
                    select="value"
                    name="birthParent premiumTier"
                    handleChange={handleChange}
                    value={premiumTierBPValue}
                  />
                )}

                {hsaTierBPData && (
                  <Select
                    text="Select the option that applies"
                    selection={hsaTierBPData}
                    selectObj="tier"
                    select="value"
                    name="birthParent hsaTier"
                    handleChange={handleChange}
                    value={hsaTierBPValue}
                  />
                )}

                {surchargesBPData && (
                  <Checkbox
                    text="Select all that applies:"
                    options={surchargesBPData}
                    select="surcharge"
                    handleChange={handleChange}
                    value={surchargesBPValue}
                    name="birthParent surcharges"
                  />
                )}
                <Divider line />
              </>
            )}

            <Select
              text="Is this your first baby?"
              isBool
              name="firstBaby"
              handleChange={handleChangeBool}
              value={firstBaby}
              selection={["No", "Yes"]}
            />
          </InnerWrapper>
        </CalculateFormTemplate>

        <CalculateFormTemplate title="Non-Birth Parent">
          <InnerWrapper>
            <Divider />
            {profile.currentReport.partner.company && (
              <>
                <Text select>
                  {"Company: " + profile.currentReport.partner.company}
                </Text>
                {premiumTierPartnerData && (
                  <Select
                    text="Select the option that applies"
                    selection={premiumTierPartnerData}
                    selectObj="tier"
                    select="value"
                    name="partner premiumTier"
                    handleChange={handleChange}
                    value={premiumTierPartnerValue}
                  />
                )}

                {hsaTierPartnerData && (
                  <Select
                    text="Select the option that applies"
                    selection={hsaTierPartnerData}
                    selectObj="tier"
                    select="value"
                    name="partner hsaTier"
                    handleChange={handleChange}
                    value={hsaTierPartnerValue}
                  />
                )}

                {surchargesPartnerData && (
                  <Checkbox
                    text="Select all that applies:"
                    options={surchargesPartnerData}
                    select="surcharge"
                    handleChange={handleChange}
                    value={surchargesPartnerValue}
                    name="partner surcharges"
                  />
                )}
              </>
            )}
          </InnerWrapper>
        </CalculateFormTemplate>
      </FlexWrapper>
      <ButtonLink textAlign withBG button fontSize="18" onClick={handleSubmit}>
        Submit
      </ButtonLink>
    </Wrapper>
  );
};

export default UploadAdditionalDetails;
