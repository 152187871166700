import React from "react";

const ListGroupEmployeesJobOffers = ({ group, year }) => {
  return (
    <div>
      {group?.currentEmployeeMap && Object.keys(group.currentEmployeeMap).length ? (
        <>
          <h3>Current Employees ({Object.keys(group.currentEmployeeMap).length.toLocaleString()})</h3>
          {Object.keys(group.currentEmployeeMap).map((email, idx) => (
            <div key={idx}>
              {email} - {group.currentEmployeeMap[email].roles?.[year]} - {group.currentEmployeeMap[email].date}
            </div>
          ))}
        </>
      ) : (
        ""
      )}
      {group?.benefitIneligibleEmployeesMap && Object.keys(group.benefitIneligibleEmployeesMap).length && (
        <>
          <h3 style={{ marginTop: "10px" }}>
            Benefit Ineligible Employees ({Object.keys(group.benefitIneligibleEmployeesMap).length.toLocaleString()})
          </h3>
          {Object.keys(group.benefitIneligibleEmployeesMap).map((email, idx) => (
            <div key={idx}>
              {email} - {group.benefitIneligibleEmployeesMap[email].roles?.[year]} -{" "}
              {group.benefitIneligibleEmployeesMap[email].date}
            </div>
          ))}
        </>
      )}
      {group?.formerEmployeeMap && Object.keys(group.formerEmployeeMap).length ? (
        <>
          <h3 style={{ marginTop: "10px" }}>
            Former Employees ({Object.keys(group.formerEmployeeMap).length.toLocaleString()})
          </h3>
          {Object.keys(group.formerEmployeeMap).map((email, idx) => (
            <div key={idx}>
              {email} - {group.formerEmployeeMap[email].roles?.[year]} - {group.formerEmployeeMap[email].date}
            </div>
          ))}
        </>
      ) : (
        ""
      )}
      {group?.jobOfferMap && Object.keys(group.jobOfferMap).length ? (
        <>
          <h3 style={{ marginTop: "10px" }}>Job Offers ({Object.keys(group.jobOfferMap).length.toLocaleString()})</h3>
          {Object.keys(group.jobOfferMap).map((key, idx) => (
            <div key={idx}>
              {group.jobOfferMap[key].email} - {group.jobOfferMap[key].roles?.[year]}
            </div>
          ))}
        </>
      ) : (
        ""
      )}
      {group && !group?.currentEmployeeMap && !group?.formerEmployeeMap && !group?.jobOfferMap ? (
        <div>No emails found</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ListGroupEmployeesJobOffers;
