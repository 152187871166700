import { WordEnum } from "enums/serviceWordMapping.enums";

const formatPlans = (plans, serviceList, FSType, FSMaxAmount, FSMaxUnits) => {
  return plans.map((item) => {
    const {
      id,
      outOfNetworkCoverage,
      services
    } = item;

    const {
      haveToPay: IHSHaveToPay,
      inNetworkCoinsuranceRate: IHSInNetworkCoinsuranceRate,
      outOfNetworkCoinsuranceRate: IHSOutOfNetworkCoinsuranceRate,
    } = services?.["Inpatient Hospital Stay"] || {}

    return {
      externalId: id,
      inNetwork: {
        coinsurance: {
          rate:
            IHSHaveToPay === "coinsurance" || IHSHaveToPay === "both"
              ? IHSInNetworkCoinsuranceRate > 50
                ? (100 - IHSInNetworkCoinsuranceRate) / 100
                : IHSInNetworkCoinsuranceRate / 100
              : 0,
        },
      },
      outOfNetwork: {
        coinsurance: {
          rate:
            outOfNetworkCoverage === "yes"
              ? IHSOutOfNetworkCoinsuranceRate > 50
                ? (100 - IHSOutOfNetworkCoinsuranceRate) / 100
                : IHSOutOfNetworkCoinsuranceRate / 100
              : null,
        },
      },
      services: serviceList.map(serviceName => {
        const {
          haveToPay,
          inNetworkCoinsuranceRate,
          amount,
          unit,
          maxUnits,
          inNetworkSubjectToDeductible
        } = services?.[serviceName] || {}
        return {
          serviceName: serviceName,
          maternityCopayDetails: {
            subjToCopay: serviceName === "Fertility Services (IVF, Egg Freezing)" ? true : haveToPay === "copay" || haveToPay === "both",
            hospital:
              haveToPay === "copay" || haveToPay === "both" ? amount : null,
            unit: haveToPay === "copay" || haveToPay === "both" ? unit : "n/a",
            maxUnits:
              (haveToPay === "copay" || haveToPay === "both") &&
                unit === "night"
                ? maxUnits
                : null,
            maxUnitsCovered: serviceName === "Fertility Services (IVF, Egg Freezing)" ? FSType === "amount" ? FSMaxAmount : FSMaxUnits : null,
            maxUnitsCoveredUnit: serviceName === "Fertility Services (IVF, Egg Freezing)" ? FSType : null,
            whoPays:
              haveToPay === "copay" || haveToPay === "both" ? "Parent" : "n/a",
            subjToDeductible: inNetworkSubjectToDeductible !== "no",
            subjToPercentCoins:
              haveToPay === "coinsurance" || haveToPay === "both",
          },
          inNetwork: {
            coinsurance: {
              rate:
                haveToPay === "coinsurance" || haveToPay === "both"
                  ? inNetworkCoinsuranceRate > 50
                    ? (100 - inNetworkCoinsuranceRate) / 100
                    : inNetworkCoinsuranceRate / 100
                  : 0,
            },
          },
        }
      })
    }
  })
}

const formatMember = (member) => {
  if (member === "Self")
    return "birthParent"
  else if (member === "Spouse")
    return "partner"
  else
    return member.toLowerCase().replace(" ", "_")
}

export const formatServices = (values) => {
  const {
    expectedTable,
    table,
    partnerTable,
    serviceList,
    FSTypeG1,
    FSMaxAmountG1,
    FSMaxUnitsG1,
    FSTypeG2,
    FSMaxAmountG2,
    FSMaxUnitsG2,
  } = values;

  const formattedPlans = {
    birthParentPlans: formatPlans(table, serviceList, FSTypeG1, FSMaxAmountG1, FSMaxUnitsG1),
    partnerPlans: formatPlans(partnerTable, serviceList, FSTypeG2, FSMaxAmountG2, FSMaxUnitsG2)
  }

  const formattedServices = expectedTable.map(service => {
    service.unit = service.unit ? service.unit : WordEnum[service.billedAs]
    service.for = formatMember(service.for)
    return service
  })

  return { formattedPlans, formattedServices }
};
