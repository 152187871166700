import styled, { css } from "styled-components";

const Form = styled.form`
  padding: 5px 5px 20px 5px;
  height: auto;
  width: ${({ width }) => width && width};
  height: ${({ height }) => height && height};
  ${({ show }) => show &&
    `visibility: ${show? 'visible': 'hidden'}`};
  ${({ login, invalid }) =>
    login &&
    css`
      width: auto;
      display: flex;
      height: auto;
      justify-content: space-between;
      width: 300px;
      height: 42px;
      flex-direction: row;
      padding: 0px;
      font-size: 16px;
      border-bottom: 1px solid ${invalid ? `#f0523d` : `white`};
    `}

  ${({ register, invalid }) =>
    register &&
    css`
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 300px;
      height: auto;
      padding: 0px;
      font-size: 16px;
      border-bottom: 1px solid ${invalid ? `#f0523d` : `white`};
    `}
`;

export default Form;
