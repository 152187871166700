import { createReducer } from "reduxsauce";
import Actions from "./actions";
import { assign } from "lodash";

const { Types } = Actions;

const INITIAL_STATE = {
  headers: [],
  data: [],
  isRequesting: false
};

const success = (state = INITIAL_STATE, { payload }) => {
  return assign({}, state, { isRequesting: false, ...payload });
};

const failure = (state = INITIAL_STATE) => {
  return INITIAL_STATE;
};

const request = (state = INITIAL_STATE) => {
  return assign({}, state, {
    headers: [],
    data: [],
    isRequesting: true
  });
};

export const HANDLERS = {
  [Types.LOAD_TOP_DOCTORS_SUCCESS]: success,
  [Types.LOAD_TOP_DOCTORS_REQUEST]: request,
  [Types.LOAD_TOP_DOCTORS_FAILURE]: failure
};

const reducer = createReducer(INITIAL_STATE, HANDLERS);

export default reducer;
