import React, { createRef } from "react";

import { getProfile } from "services/api/account.api";
import { getToken } from "services/utils";

import { Form, Input, Button, Select, message } from "antd";
import { getPlansTemplateForEdit } from "services/api";

const { Item } = Form;
const { Option } = Select;

const SearchPlans = ({ disable, handleChange, onSubmit }) => {
  const [form] = Form.useForm();

  const onClear = () => {
    form.resetFields();
    handleChange({});
  };

  const onFail = () => {
    console.log("Invalid Submission");
  };

  return (
    <Form
      form={form}
      layout="inline"
      onFinish={onSubmit}
      onFinishFailed={onFail}
      initialValues={{ searchBy: "allPrivate" }}
    >
      <Item
        label="Search By"
        name="searchBy"
        rules={[{ required: true, message: "Item required!" }]}
      >
        <Select style={{ width: "100px" }}>
          <Option value="externalId">External Id</Option>
          <Option value="allPrivate">All Private</Option>
          <Option value="allPublic">All Public</Option>
          <Option value="group">Group</Option>
          <Option value="network">Network</Option>
          <Option value="reportId">Report Id</Option>
          <Option value="userId">User Id</Option>
        </Select>
      </Item>

      <Item
        name="searchTerm"
        rules={[{ required: false, message: "Field required!" }]}
      >
        <Input />
      </Item>

      <Item>
        <Button disabled={disable} type="primary" htmlType="submit">
          Search
        </Button>
      </Item>

      {disable && (
        <Item>
          <Button onClick={onClear}>Reset</Button>
        </Item>
      )}
    </Form>
  );
};

export default SearchPlans;
