import React, { useState } from "react";
import styled from "styled-components";
import {
  CalculateHeader as Header,
  FormFooter,
  FormCheckboxAltV2 as Checkbox,
  FormDatepickerV2 as Datepicker,
  Icon,
} from "components";
import { merge } from "lodash";

const Wrapper = styled.section`
  display: grid;
  height: 100%;
  place-items: center;
`;

const GridWrapper = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 15px;
`;

const questions = {
  isSpouse: "Are you married?",
  marriageDate: "When is your wedding?",
  considerSpousePlan: "Would you consider a spouse or partner's plan?",
  spouseRequireIns:
    "Would you like the insurance to cover you and your spouse?",
};

const valToBool = (_val) => {
  return _val.toLowerCase() === "yes" ? true : false;
};

const OnboardingSpouseEventSection = ({ _form, handleNext }) => {
  const [steps, setSteps] = useState([
    _form.gettingMarried ? "marriageDate" : "isSpouse",
  ]);

  const step = steps[steps.length - 1];

  const [form, setForm] = useState({
    isSpouse: _form.isSpouse,
    marriageDate: _form.marriageDate,
    considerSpousePlan: _form.considerSpousePlan,
    spouseRequireIns: _form.spouseRequireIns,
    // isDue: _form.isDue,
    onboardingStep: _form.onboardingStep.includes("review")
      ? "review"
      : "residence",
  });

  const handleChange = ({ name, value, text }) => {
    switch (name) {
      case "isSpouse":
        setForm({
          ...form,
          [name]: valToBool(text),
        });
        break;
      case "marriageDate":
        setForm({
          ...form,
          [name]: value,
        });
        break;
      case "considerSpousePlan":
        setForm({
          ...form,
          [name]: valToBool(text),
        });
        break;
      case "spouseRequireIns":
        setForm({
          ...form,
          [name]: valToBool(text),
        });
        break;
      default:
        setForm({
          ...form,
          onboardingStep: _form.onboardingStep.includes("review")
            ? "review"
            : "residence",
        });
        break;
    }
  };

  const handleStep = () => {
    const updatedForm = merge({}, _form, form);
    switch (step) {
      case "isSpouse":
        form.isSpouse
          ? setSteps([...steps, "considerSpousePlan"])
          // : form.isDue
          // ? setSteps([...steps, "dueDate"])
          : handleNext(updatedForm);
        break;
      case "marriageDate":
        setSteps([...steps, "considerSpousePlan"]);
        break;
      case "considerSpousePlan":
        !form.considerSpousePlan
          ? setSteps([...steps, "spouseRequireIns"])
          : handleNext(updatedForm);
        break;
      default:
        handleNext(updatedForm);
        break;
    }
  };

  const handleBack = () => {
    const updatedSteps = steps.slice(0, -1);
    const updatedForm = merge({}, _form, {
      ...form,
      onboardingStep: _form.onboardingStep.includes("review")
        ? "review"
        : "dob",
    });
    switch (steps.length) {
      case 1:
        handleNext(updatedForm);
        break;
      default:
        setSteps(updatedSteps);
        break;
    }
  };

  const active = form[step] !== null && form[step] !== undefined;
  return (
    <Wrapper>
      <Header bigTitle={true} title={questions[step]} />
      <GridWrapper>
        {step.includes("Date") ? (
          <GridWrapper>
            <Datepicker
              name={step}
              value={form[step]}
              handleChange={handleChange}
              allowNA
            />
          </GridWrapper>
        ) : (
          <GridWrapper>
            <Checkbox
              name={step}
              value={form[step]}
              Icon={<Icon yesIcon small />}
              text="Yes"
              handleChange={handleChange}
            />
            <Checkbox
              name={step}
              value={form[step] === false}
              Icon={<Icon noIcon small />}
              text="No"
              handleChange={handleChange}
            />
          </GridWrapper>
        )}
      </GridWrapper>
      <FormFooter
        leftAction={handleBack}
        mainAction={handleStep}
        mainActionActive={active}
      />
    </Wrapper>
  );
};

export default OnboardingSpouseEventSection;
