import * as Yup from "yup";
import model from "./model";

const {
  formField: { expectedTable, table, partnerTable },
} = model;

const servicesValidationObject = (values) => {
  let { serviceList } = values || {};

  if (!serviceList) {
    serviceList = [];
  }

  const finalObject = {};
  for (const service of serviceList) {
    if (service !== "Out-of-network Therapy") {
      if (service !== "Inpatient Hospital Stay") {
        finalObject[service] = Yup.object().shape({
          haveToPay: Yup.string().required(""),
          inNetworkCoinsuranceRate: Yup.string().when("haveToPay", {
            is: (haveToPay) => haveToPay !== "copay",
            then: Yup.string().required(""),
          }),
          amount: Yup.string().when("haveToPay", {
            is: (haveToPay) => haveToPay === "copay" || haveToPay === "both",
            then: Yup.string().required(""),
          }),
        });
      } else {
        finalObject[service] = Yup.object().shape({
          haveToPay: Yup.string().required(""),
          inNetworkCoinsuranceRate: Yup.string().when("haveToPay", {
            is: (haveToPay) => haveToPay !== "copay",
            then: Yup.string().required(""),
          }),
          amount: Yup.string().when("haveToPay", {
            is: (haveToPay) => haveToPay === "copay" || haveToPay === "both",
            then: Yup.string().required(""),
          }),
          unit: Yup.string().when("haveToPay", {
            is: (haveToPay) => haveToPay === "copay" || haveToPay === "both",
            then: Yup.string().required(""),
          }),
          maxUnits: Yup.string().when(["haveToPay", "unit"], {
            is: (haveToPay, unit) => (haveToPay === "copay" || haveToPay === "both") && unit === "night",
            then: Yup.string().required(""),
          }),
        });
      }
    }
  }

  return Yup.object(finalObject);
};

const reimbursementsValidationObject = (values) => {
  let { concatenatedTable } = values || {};

  if (!concatenatedTable) {
    concatenatedTable = [];
  }

  const yupPlanIdsObject = {};
  for (const plan of concatenatedTable) {
    yupPlanIdsObject[plan.id] = Yup.number().required("");
  }

  return yupPlanIdsObject;
};

export const createValidationSchema = (values) => [
  // step 1
  Yup.object().shape({
    [expectedTable.name]: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string().required(""),
          expectedOrUnexpected: Yup.string().required(""),
          billedAs: Yup.string().required(""),
          for: Yup.string().required(""),
          type: Yup.string().required(""),
          price: Yup.number().required(""),
          frequency: Yup.mixed().required(""),
          unit: Yup.mixed().when("billedAs", {
            is: (value) =>
              [
                "Generic (Tier 1)",
                "Preferred Brand (Tier 2)",
                "Non-Preferred Brand (Tier 3)",
                "Specialty (Tier 4)",
                "Other Drug",
                "Inpatient Hospital Stay",
              ].includes(value),
            then: Yup.mixed().required(""),
          }),
        }),
      )
      .required("Must have table"),
  }),
  Yup.object()
    .shape({
      [table.name]: Yup.array().of(
        Yup.object().shape({
          services: servicesValidationObject(values),
        }),
      ),
      FSTypeG1: Yup.string().required(""),
      FSMaxAmountG1: Yup.number().when(["FSTypeG1", "serviceList"], {
        is: (FSTypeG1, serviceList) =>
          FSTypeG1 === "amount" && serviceList && serviceList.includes("Fertility Services (IVF, Egg Freezing)"),
        then: Yup.number().required("").typeError("This field is required"),
        otherwise: Yup.number().notRequired().nullable(),
      }),
      FSMaxUnitsG1: Yup.number().when(["FSTypeG1", "serviceList"], {
        is: (FSTypeG1, serviceList) =>
          FSTypeG1 === "units" && serviceList && serviceList.includes("Fertility Services (IVF, Egg Freezing)"),
        then: Yup.number().required("").typeError("This field is required"),
        otherwise: Yup.number().notRequired().nullable(),
      }),
    })
    .required("Must have table"),
  Yup.object()
    .shape({
      [partnerTable.name]: Yup.array().of(
        Yup.object().shape({
          services: servicesValidationObject(values),
        }),
      ),
      FSTypeG2: Yup.string().required(""),
      FSMaxAmountG2: Yup.number().when(["FSTypeG2", "serviceList"], {
        is: (FSTypeG2, serviceList) =>
          FSTypeG2 === "amount" && serviceList && serviceList.includes("Fertility Services (IVF, Egg Freezing)"),
        then: Yup.number().required("").typeError("This field is required"),
        otherwise: Yup.number().notRequired().nullable(),
      }),
      FSMaxUnitsG2: Yup.number().when(["FSTypeG2", "serviceList"], {
        is: (FSTypeG2, serviceList) =>
          FSTypeG2 === "units" && serviceList && serviceList.includes("Fertility Services (IVF, Egg Freezing)"),
        then: Yup.number().required("").typeError("This field is required"),
        otherwise: Yup.number().notRequired().nullable(),
      }),
    })
    .required("Must have table"),
  Yup.object()
    .shape({
      [expectedTable.name]: Yup.array().of(
        Yup.object().shape({
          reimbursements: Yup.object().when("type", {
            is: (type) => {
              return type === "outOfNetwork";
            },
            then: Yup.object(reimbursementsValidationObject(values)).required(),
            otherwise: Yup.object().nullable(),
          }),
        }),
      ),
    })
    .required("Must have table"),
];
