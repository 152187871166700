import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Button, Input } from "antd";
const { Item } = Form;

const DynamicField = ({ name, label, placeholder }) => {
  return (
    <Form.List
      name={name}
    >
      {(fields, { add, remove }) => (
        <>
          <Item
            label={label}
            labelCol={{ span: 24 }}
            style={{ marginBottom: "0px" }}
          />
          {fields.map((field, index) => (
            <Item
              key={field.key}
            >
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                <Item
                  {...field}
                  noStyle
                >
                  <Input
                    placeholder={placeholder}
                    style={{ minWidth: "150px" }}
                  />
                </Item>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    style={{ margin: "5px 8px" }}
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </div>
            </Item>
          ))}
          <Item>
            <Button
              type="dashed"
              onClick={() => add()}
              style={{
                width: '100%',
                minWidth: "150px"
              }}
            >
              Add <PlusOutlined />
            </Button>
          </Item>
        </>
      )}
    </Form.List>
  );
}

export default DynamicField;
