import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  color: #00a19b;
  width: 100%;
`;

const SelectContainer = styled.div`
  width: 100%;
  max-width: 310px;
  background: #d9f3f1;
  border-radius: 15px;
  height: 45px;
  padding: 5px 15px;

  > select {
    width: 100%;
    background: transparent;
    outline: none;
    border: none;
    font-family: circular;
    font-size: 18px;
    line-height: 22.63px;
  }
`;

const SelectCustom = ({ name, value, selection, handleChange }) => {
  const onChange = (e) => {
    const target = { name, value: e.target.value };
    handleChange({ target });
  };
  return (
    <Wrapper>
      <SelectContainer>
        <select value={value} onChange={onChange}>
          <option value="" disabled selected>
            Select option
          </option>
          {selection.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      </SelectContainer>
    </Wrapper>
  );
};

export default React.memo(SelectCustom);
