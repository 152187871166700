import React, { useState } from "react";
import styled from "styled-components";
import {
  ButtonLink,
  CalculateHeader as Header,
  FormCheckbox as Checkbox,
} from "components";

const Wrapper = styled.section``;

const FlexWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  border: 3px solid #00a19b;
  border-radius: 25px;
  margin: 15px 12px;
  padding: 30px;
  width: 350px;
  outline: none;
  color: #00a19b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  background: #fff;
`;

const OnboardingGeneralSection = ({ generateForm, options }) => {
  const [choices, setChoices] = useState([]);

  const handleChange = ({ target: { name, value } }) => {
    setChoices(value);
  };

  const handleSubmit = () => {
    generateForm(choices);
  };

  return (
    <Wrapper>
      <Header
        bigTitle={true}
        title="What do you need health insurance advice on?"
        greenText="Select all that apply."
      />
      <FlexWrapper>
        <ButtonWrapper>
          <Checkbox
            options={options}
            handleChange={handleChange}
            value={choices}
            name="choices"
          />

          <Checkbox
            options={["None of these, but I still want advice!"]}
            handleChange={handleChange}
            value={choices}
            name="choices"
          />
        </ButtonWrapper>
        {choices.length > 0 && (
          <ButtonLink withBG button fontSize="18" onClick={handleSubmit}>
            Continue
          </ButtonLink>
        )}
      </FlexWrapper>
    </Wrapper>
  );
};

export default OnboardingGeneralSection;
