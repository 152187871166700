import { getPlansArr } from "services/api";

const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

const getAllowedServices = ({ group1, group2 }) => {
  const services = [
    {
      value: "Inpatient Hospital Stay",
      name: "Inpatient Hospital Stay",
    },
    {
      value: "Therapy",
      name: "Therapy",
    },
    {
      value: "Primary Care Sick Visit",
      name: "Primary Care Sick Visit",
    },
    {
      value: "Preventive Care",
      name: "Preventive Care",
    },
    {
      value: "Specialist Visit",
      name: "Specialist Visit",
    },
    {
      value: "Labwork",
      name: "Labwork",
    },
    {
      value: "Urgent Care",
      name: "Urgent Care",
    },
    {
      value: "Rehabilitation Services (PT, OT, Chiropractor)",
      name: "Rehabilitation Services (PT, OT, Chiropractor)",
    },
    {
      value: "Outpatient Surgery Physician Fees",
      name: "Outpatient Surgery Physician Fees",
    },
    {
      value: "Fertility Services (IVF, Egg Freezing)",
      name: "Fertility Services (IVF, Egg Freezing)",
    },
  ];
  if (group1 === "Quinnipiac University" || group2 === "Quinnipiac University") {
    services.splice(8, 0, {
      value: "Outpatient Ambulatory Surgery Center Facility Fee",
      name: "Outpatient Ambulatory Surgery Center Facility Fee",
    });
  }
  return services;
};

const initializePlansServices = (report, plans) => {
  return plans.map((plan, index) => {
    const services = {};
    for (const service of plan.services) {
      if (service.serviceName.toLowerCase().includes("network therapy")) {
        service.serviceName = "Therapy";
      }
      services[service.serviceName] = {
        haveToPay: service.maternityCopayDetails.hospital
          ? service.inNetwork.coinsurance.rate
            ? "both"
            : "copay"
          : "coinsurance",
        inNetworkCoinsuranceRate: (service.inNetwork.coinsurance.rate * 100).toFixed(2) || 0,
        outOfNetworkCoinsuranceRate: (plan.outOfNetwork.coinsurance.rate * 100).toFixed(2) || 0,
        amount: service.maternityCopayDetails.hospital || 0,
        unit: service.maternityCopayDetails.unit !== "n/a" ? service.maternityCopayDetails.unit : "",
        maxUnits: service.maternityCopayDetails.maxUnits || "",
        inNetworkSubjectToDeductible: service.maternityCopayDetails.subjToDeductible ? "yes" : "no",
      };
    }

    const deductible = {
      individual: plan.outOfNetwork.individual.deductible,
      family: plan.outOfNetwork.family.deductible,
    };

    const oop = {
      individual: plan.outOfNetwork.individual.oopMax,
      family: plan.outOfNetwork.family.oopMax,
    };

    let extraDetailsPhrase = "";
    if (deductible.individual !== 0) {
      extraDetailsPhrase = `${USDollar.format(deductible.individual)} individual deductible`;
    } else {
      extraDetailsPhrase = `${USDollar.format(oop.individual)} individual out-of-pocket maximum`;
    }

    if (report.type !== "individual") {
      if (deductible.family !== 0) {
        extraDetailsPhrase += ` or ${USDollar.format(deductible.family)} family deductible`;
      } else {
        extraDetailsPhrase += ` or ${USDollar.format(oop.family)} family out-of-pocket maximum`;
      }
    }

    return {
      id: plan.externalId,
      idx: index + 1,
      label: report.version === "v1" ? plan.name : plan.network,
      insuranceNetwork:
        plan.broaderNetwork === "Blue Cross/Blue Shield"
          ? "Blue Cross and/or Blue Shield (including Anthem)"
          : plan.broaderNetwork,
      outOfNetworkCoverage: plan.outOfNetwork.coverage ? "yes" : "no",
      extraDetailsPhrase: extraDetailsPhrase,
      services: services,
    };
  });
};

const getServiceMember = (member) => {
  if (member === "birthParent") return "Self";
  else if (member === "partner") return "Spouse";
  else if (member === "child") return "Child 1";
  else if (member.match(/child_\d+/)) return "Child " + member.split("_")[1];
  else if (member === "baby") return "Baby";

  return "Self";
};

export const loadServices = async (initialValues, report, token) => {
  report.expected.services = report.expected.services.map((element) => {
    return { ...element, expected: true };
  });
  report.unexpected.services = report.unexpected.services.map((element) => {
    return { ...element, expected: false };
  });
  const services = report.unexpected.services.concat(report.expected.services);
  initialValues.expectedTable = [];

  services.forEach((service, index) => {
    const obj = {
      idx: index + 1,
      label: service.label || "",
      expectedOrUnexpected: service.expected ? "expected" : "unexpected",
      billedAs: service.billedAs || service.name.replaceAll("_", " "),
      for: getServiceMember(service.member),
      type: service.inNetwork ? "inNetwork" : "outOfNetwork",
      price: service.charge,
      frequency: service.frequency ? service.frequency : service.qty,
      unit: service.unit,
      hasCreatedLabworkRow: true,
      tableName: "expectedTable",
      otherFrequencyValue: service.frequency === "other frequency" ? service.qty : 1,
      reimbursements: service.reimbursements,
    };

    if (
      service.billedAs?.toLowerCase() === "inpatient hospital stay" ||
      service.name.replaceAll("_", " ") === "inpatient hospital stay"
    ) {
      if (!obj.label) {
        obj.label = "Unplanned Hospital Stay";
        obj.expectedOrUnexpected = "unexpected";
      }
      obj.frequency = service.qty;
      obj.unit = obj.unit ? (obj.unit === "visit" ? "night" : obj.unit) : "stay";
    }

    if (service.billedAs?.toLowerCase().includes("network therapy")) {
      obj.billedAs = "Therapy";
    }

    initialValues.expectedTable[index] = obj;
  });

  initialValues.group1 = report.birthParent.company;
  initialValues.group2 = report.partner.company;

  const { data: birthParentPlansInfo } = await getPlansArr(report.birthParent.planIds, token, false);
  const birthParentPlans = birthParentPlansInfo.plansArr;
  const { data: partnerPlansInfo } = await getPlansArr(report.partner.planIds, token, false);
  const partnerPlans = partnerPlansInfo?.plansArr || [];

  const FSObjG1 =
    birthParentPlans.length &&
    birthParentPlans[0].services.find((service) => service.serviceName === "Fertility Services (IVF, Egg Freezing)");
  if (FSObjG1) {
    initialValues.FSTypeG1 = FSObjG1.maternityCopayDetails.maxUnitsCoveredUnit
      ? FSObjG1.maternityCopayDetails.maxUnitsCoveredUnit
      : "units";
    if (initialValues.FSTypeG1 === "amount") {
      initialValues.FSMaxAmountG1 = FSObjG1.maternityCopayDetails.maxUnitsCovered;
    } else {
      initialValues.FSMaxUnitsG1 = FSObjG1.maternityCopayDetails.maxUnitsCovered;
    }
  }

  const FSObjG2 =
    partnerPlans.length &&
    partnerPlans[0].services.find((service) => service.serviceName === "Fertility Services (IVF, Egg Freezing)");
  if (FSObjG2) {
    initialValues.FSTypeG2 = FSObjG2.maternityCopayDetails.maxUnitsCoveredUnit
      ? FSObjG2.maternityCopayDetails.maxUnitsCoveredUnit
      : "units";
    if (initialValues.FSTypeG2 === "amount") {
      initialValues.FSMaxAmountG2 = FSObjG2.maternityCopayDetails.maxUnitsCovered;
    } else {
      initialValues.FSMaxUnitsG2 = FSObjG2.maternityCopayDetails.maxUnitsCovered;
    }
  }

  initialValues.table = initializePlansServices(report, birthParentPlans, "G1");
  initialValues.partnerTable = initializePlansServices(report, partnerPlans, "G2");
  initialValues.subjectToDeductibleG1 = initialValues.table.every((item) =>
    Object.keys(item.services).every((key) => item.services[key].inNetworkSubjectToDeductible === "yes"),
  );
  initialValues.subjectToDeductibleG2 = initialValues.partnerTable.every((item) =>
    Object.keys(item.services).every((key) => item.services[key].inNetworkSubjectToDeductible === "yes"),
  );

  initialValues.allowedServices =
    report.version === "v2" ? getAllowedServices({ group1: initialValues.group1, group2: initialValues.group2 }) : null;

  return initialValues;
};
