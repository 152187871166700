import React, { useEffect } from "react";

import { useField } from "formik";
import Text from "components/atoms/Text";
import { Select } from "formik-antd";
import RequiredField from "components/atoms/RequiredField";
import styled, { css } from "styled-components";
import { useInputChangeContext } from "components/organisms/AddServicesSteps/InputChangeProvider";

const { Option } = Select;

const StyledSelect = styled(Select)`
  min-height: 32px !important;

  ${({ size }) => size === "small" && `width: 15% !important`};
  ${({ size }) => size === "fullwidth" && `width: 100% !important`};
  ${({ width }) => width && `width: ${width}px !important`};
  display: flex !important;
  align-items: center !important;
  font-size: 18px !important;
  color: #344054 !important;

  .ant-select-selector {
    border: 1px solid #d0d5dd !important;
    border-radius: 8px !important;
    padding: 5px 10px !important;
    height: max-content !important;
  }

  width: 100% !important;
  @media (min-width: 768px) {
    width: 50% !important;
  }
`;

const Wrapper = styled.div`
  ${({ inline }) =>
    inline &&
    css`
      display: flex;
      > * {
        margin-right: 7px;
        display: flex;
        align-items: center;
      }
    `}
`;

const FormField = ({ label, type, name, options, required, setExpanded, condition, forcedValue, inline, ...props }) => {
  const [field, meta, helpers] = useField(name);

  const { onSelectInputChanged } = useInputChangeContext();

  useEffect(() => {
    const valueIsNotAOption = meta.value && !options.find((item) => item.value === meta.value);
    !props.allowValueNotInOptions && valueIsNotAOption && helpers.setValue(null);
  }, [options]);

  if (inline) {
    return (
      <Wrapper inline={inline}>
        {label && (
          <Text formLabel>
            {label}
            {required && <RequiredField error={meta.touched && meta.error} />}
          </Text>
        )}
        <StyledSelect
          {...field}
          {...props}
          onChange={(value) => {
            helpers.setValue(value);
            if (onSelectInputChanged) {
              onSelectInputChanged(field.name, value);
            }
            condition && setExpanded(value === "yes");
          }}
          status={meta.touched && meta.error && "error"}
          value={forcedValue || meta.value}
        >
          {options.map(({ value, name }) => (
            <Option key={value} value={value}>
              {name}
            </Option>
          ))}
        </StyledSelect>

        {meta?.touched && meta?.error && <Text red>{meta.error}</Text>}
      </Wrapper>
    );
  }

  return (
    <>
      {label && (
        <Text formLabel>
          {label}
          {required && <RequiredField error={meta.touched && meta.error} />}
        </Text>
      )}
      <StyledSelect
        {...field}
        {...props}
        onChange={(value) => {
          helpers.setValue(value);
          if (onSelectInputChanged) {
            onSelectInputChanged(field.name, value);
          }
          condition && setExpanded(value === "yes");
        }}
        status={meta.touched && meta.error && "error"}
        value={forcedValue || meta.value}
        onSelect={(value) => {
          props.handleOnSelect && props.handleOnSelect(value, props.index);
        }}
      >
        {options.map(({ value, name }) => (
          <Option key={value} value={value}>
            {name}
          </Option>
        ))}
      </StyledSelect>

      {meta?.touched && meta?.error && <Text red>{meta.error}</Text>}
    </>
  );
};

export default FormField;
