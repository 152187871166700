import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  calculateRequest: [null],
  calculateSuccess: ["payload"],
  calculateFailure: ["error"]
});

const Actions = {
  Types,
  Creators
};

export default Actions;
