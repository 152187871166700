import { getPlansArr, getReport } from "services/api";
import { getGroup } from "services/api/groups.api";
import initialValues from "./initialValues";

import model from "./model";
const {
  formField: {
    planOptions,
    costFor,
    table,
    frequency,
  },
} = model;

const forceObjectFromList = (list) => {
  try {
    const newObj = {}
    for (let key of Object.keys(list)) {
      if (list[key] !== null) {
        newObj[key] = list[key]
      }
    }
    return newObj
  } catch (e) {
    return list
  }
}

export const loadValues = async ({ group, token, reportToVerify, premiumTier, currentReportId, isEditing, option }) => {
  let reportId = isEditing ? currentReportId : ""

  if (!isEditing && reportToVerify) {
    reportId = reportToVerify
  }

  if (!reportId) return null

  const { data: reportInfo } = await getReport(reportId, token);
  const report = reportInfo.report;

  let currentReport = null
  if (currentReportId) {
    const { data: currentReportInfo } = await getReport(currentReportId, token);
    currentReport = currentReportInfo.report;
  }

  const {
    event: {
      general: { children },
    },
    type,
    isDue
  } = currentReport ? currentReport : report;

  let groupType =
    type === "individual" && (!children && !isDue)
      ? "mo"
      : type === "individual" && (children > 0 || isDue)
        ? "mc"
        : type === "household (with no spouse)" && (children > 0 || isDue)
          ? "mc"
          : type === "household" && (!children && !isDue)
            ? "ms"
            : type === "household" && (children > 0 || isDue)
              ? "mf"
              : "mo";
  if (!isEditing && option === "partner" && groupType === "mo") {
    groupType = "ms"
  }

  if (report.birthParent.planIds.length === 0) {
    return { ...initialValues, [costFor.name]: groupType };
  }
  const { data: planInfo } = await getPlansArr(isEditing && option === "partner" ? report.partner.planIds : report.birthParent.planIds, token, false);
  const plans = planInfo.plansArr;

  const tableMap = plans.map((plan, index) => {
    return {
      id: plan.externalId,
      idx: index + 1,
      planName: plan.name,
      label: plan.network,
      insuranceNetwork: plan.broaderNetwork === "Blue Cross/Blue Shield" ? "Blue Cross and/or Blue Shield (including Anthem)" : plan.broaderNetwork,
      tiers: forceObjectFromList(plan.premium.tiers),
      frequency: plan.premium.frequency,
    }
  })

  return {
    //step 1
    [planOptions.name]: tableMap.length,
    [costFor.name]: groupType,
    [table.name]: tableMap,
    //step 2
    [frequency.name]: tableMap[0].frequency,
    selectedPremiumTier: premiumTier || 1
  }
}