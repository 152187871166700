import React, { useState } from "react";
import styled from "styled-components";
import { DownOutlined, HeartOutlined } from "@ant-design/icons";
import { Icon } from "components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #142444;
  color: #fff;
`;

const Title = styled.div`
  margin-top: 80px;
  width: 725px;
  text-align: center;
  font-size: 41px;
  line-height: 60px;
  font-family: circular;
  font-weight: bold;
  @media (max-width: 750px) {
    width: 100%;
    font-size: 30px;
  }
`;

const GetStarted = styled.a`
  padding: 18px 40px 19px 40px;
  border: 1px solid #377b78;
  background: #377b78;
  border-radius: 7px;
  color: white;
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  line-height: 1em;
  white-space: nowrap;
  margin-bottom: 20px;
  font-family: circular;

  :hover {
    background: white;
    color: #377b78;
  }
`;

const LineDivider = styled.div`
  width: 80%;
  height: 1px;
  background-color: #fff;
  opacity: 10%;
`;

const HealthInsurance = styled.div`
  display: flex;
  margin-top: 80px;
  color: #21355b;
  background-color: #fff;
  font-size: 18px;
  font-family: circular;
  border-radius: 4px;
  @media (max-width: 750px) {
    width: 90%;
    display: block;
  }
  margin-bottom: 62px;
`;

const TextLeft = styled.div`
  padding: 20px;
  border-right: 1px solid rgb(33 53 91 / 20%);
`;

const DivRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 20px;
  &:hover {
    cursor: pointer;
  }
`;

const Drop = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  flex-direction: column;
  display: flex;
  width: 100%;
  background-color: #fff;
  padding: 20px 20px 0 20px;
  a {
    margin-bottom: 10px;
    color: #21355b;
  }
`;

const TextPredictaBill = styled.p`
  margin-top: 62px;
  margin-bottom: 10px;
  font-family: circular;
  font-size: 32px;

  span {
    color: #479f9b;
  }
`;

const SubText = styled.p`
  text-align: center;
`;

const TextLink = styled.a`
  color: #b3bac5;
  font-size: 18px;
  text-align: center;
  text-decoration: underline;
  margin-bottom: 7px;
  &:hover {
    text-decoration: underline;
    color: #479f9b;
  }
`;

const Heart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background-color: #21355b;
  border-radius: 50%;
  margin-top: 55px;
  margin-bottom: 20px;
`;

const Copyright = styled.span`
  font-family: circular;
  font-size: 16px;
`;

const insuranceList = [
  { text: "Through My Employer", slug: "employer" },
  { text: "Through My Spose/Partner", slug: "employer" },
  { text: "Independently", slug: "independent" },
  { text: "Through My Parents", slug: "parent" },
  { text: "Through COBRA", slug: "cobra" },
  { text: "I'm in Transition", slug: "transition" },
  { text: "More Choices...", slug: "more" },
];

const Footer = (props) => {
  const [openList, setOpenList] = useState(false);
  return (
    <Wrapper {...props}>
      <Title>Ready to get your personalized health insurance advice?</Title>

      <HealthInsurance>
        <TextLeft>
          <Icon magnifyingGlass backgroundSize={18} /> I Get My Health Insurance
        </TextLeft>
        <DivRight onClick={() => setOpenList((state) => !state)}>
          {insuranceList[0].text} <DownOutlined style={{ marginLeft: 20 }} />
          {openList && (
            <Drop>
              {insuranceList.map((insurance) => {
                return (
                  <a
                    href={`https://predictabill.typeform.com/to/BtEZdk5l#answer=${insurance.slug}`}
                    target="_blank"
                    key={insurance.slug}
                  >
                    {insurance.text}
                  </a>
                );
              })}
            </Drop>
          )}
        </DivRight>
      </HealthInsurance>
      <GetStarted
        href={"https://predictabill.typeform.com/to/BtEZdk5l#answer=employer"}
      >
        Get Started for Free
      </GetStarted>

      <LineDivider />

      <TextPredictaBill>
        predicta<span>bill</span>
      </TextPredictaBill>

      <SubText>One Platform, All Your Health Insurance Options</SubText>

      <TextLink
        href="https://predictabill.com/privacy-policy-2/"
        target="_blank"
      >
        Privacy Policy
      </TextLink>
      <TextLink
        href="https://predictabill.com/terms-and-conditions/"
        target="_blank"
      >
        Terms and Conditions
      </TextLink>
      <TextLink
        href="https://predictabill.com/electronic-transactions/"
        target="_blank"
      >
        Electronic Transactions and Disclosures Agreement
      </TextLink>

      <Heart>
        <HeartOutlined />
      </Heart>

      <Copyright>© 2018-{new Date().getFullYear()}</Copyright>
    </Wrapper>
  );
};

export default Footer;
