import React from "react";
import styled from "styled-components";
import {SerifHeader } from "components";

import {
  BirthPlan,
  InsurancePlan,
  TableBills,
  PredictedTotal,
  InsuranceBroader,
} from "containers";

const Wrapper = styled.div`
  color: #123;
  padding: 20px 0;
  padding-top: 0;
`;

const FlexWrapper = styled.section`
  display: flex;

  justify-content: center;
  ${({ row }) =>
    row
      ? `
    flex-direction: column;
  `
      : " padding: 0;"}

  flex-wrap: wrap;
`;

const InnerWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px ;
`;

function CalculateMain() {
  return (
    <Wrapper>
      <SerifHeader
        title="How much will it cost you to have a baby in Manhattan?"
        subTitle="We overlaid your health insurance plan on real pregnancy bills to calculate your predicted out-of-pocket costs."
        linkRef={"https://predictabill.com/why/"}
        linkText={"Why?"}
      />
      <FlexWrapper>
        <BirthPlan />
        <InsurancePlan />
        <PredictedTotal />
      </FlexWrapper>
      <InnerWrapper>
        <TableBills />
      </InnerWrapper>
    </Wrapper>
  );
}

export default CalculateMain;
