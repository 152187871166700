import {
  put,
  call,
  select,
  takeLeading,
  delay,
  takeLatest,
  fork
} from "redux-saga/effects";
import Actions from "./actions";
import { getBills, getBillsHeader, getBillById } from "services/api";
import {
  getBillsId,
  getDataForSummary,
  getToken,
  checkFormTableBills
} from "services/utils";
import { EventOne } from "services/google";
import { sendForm, sendPremiumForm } from "../formData/sagas";
import { validation, summarySchema } from "services/validation";
import { removeTokenSaga } from "../auth/sagas";

const { Creators, Types } = Actions;
const getPlan = state => state.plan;
const getBillsState = state => state.bills.body;

// loads bills into tableBill
export function* loadBills() {
  const plan = yield select(getPlan);
  const formBillsSend = yield checkFormTableBills(plan);
  yield put(Creators.loadBillsRequest(formBillsSend));

  try {
    const token = yield getToken();
    const { data } = yield call(getBills, formBillsSend, token);
    yield delay(500);
    if (data) {
      yield put(Creators.loadBillsSuccess(data.records, data.summary));
    } else yield put(Creators.loadBillsFailure());
  } catch (error) {
    yield put(Creators.loadBillsFailure());
    yield removeTokenSaga(error);
  }
}

// loads summary in tableBills (ie. 8 practices, 5 hospitals)
export function* loadSummary() {
  const bills = yield select(getBillsState);
  const plan = yield select(getPlan);
  const idBills = yield call(getBillsId, bills);
  const data = yield call(getDataForSummary, { plan, idBills });

  const valid = yield call(validation, summarySchema, data);
  if (valid) {
    try {
      yield put(Creators.loadBillsHeaderRequest());
      const token = yield getToken();
      const response = yield call(getBillsHeader, data, token);
      yield put(Creators.loadBillsHeaderSuccess(response.data));
    } catch (error) {
      yield put(Creators.loadBillsHeaderFailure());
      yield removeTokenSaga(error);
    }
  } else yield put(Creators.loadBillsHeaderFailure(true));
}

// rendering formData for checking bills / other changes
export function* watchLoadSummary() {
  yield takeLatest([Types.CHECKED_BILL, Types.CHANGE_ALL_SELECT], function*({
    type
  }) {
    if (type === Types.CHANGE_ALL_SELECT) {
      const selectAll = state => state.bills.isSelectAll;
      const isSelectAll = yield select(selectAll);
      const value = isSelectAll ? "checked" : "unchecked";
      yield fork(EventOne, "Bills", "Use average of all bills", value);
    }
    yield loadSummary();

    // allows bills to affect totals
    const route = state => state.router.location.pathname;
    const isRoute = yield select(route);
    if (isRoute === "/premium") {
      yield sendPremiumForm();
    } else {
      yield sendForm();
    }
  });
}

export function* watchLoadBillById() {
  yield takeLeading(Types.LOAD_BILL_BY_ID_REQUEST, function*({ id }) {
    const token = yield getToken();
    yield fork(EventOne, "Bills", "View bill details");
    try {
      const response = yield call(getBillById, id, token);
      if (response.data) {
        yield delay(500);
        yield put(Creators.loadBillByIdSuccess(response.data));
      } else yield put(Creators.loadBillByIdFailure());
    } catch (error) {
      yield removeTokenSaga(error);
    }
  });
}
