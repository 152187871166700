import React, { useEffect } from "react";

import styled from "styled-components";

import { Widget } from "@typeform/embed-react";
import { useLocation, useHistory } from "react-router-dom";
import PrimaryNavigation from "components/molecules/PrimaryNavigation";
import { useDispatch } from "react-redux";
import Actions from "store/plan/actions";

const Wrapper = styled(Widget)`
  position: relative;
  height: 100vh;
  overflow-y: hidden;
`;
const PlanInfoTypeformPage = () => {
  const history = useHistory();
  const { search, state } = useLocation();
  const reportId = state?.reportId;
  const option = state?.option;
  const dispatch = useDispatch();
  const formType = new URLSearchParams(search).get("formType");
  let url = "/";
  let isPartnerPlans = false;
  if (formType === "BtEZdk5l") {
    url = "https://predictabill.typeform.com/to/BtEZdk5l?e=1";
  } else if (formType === "dzQzpUd8") {
    url = "https://predictabill.typeform.com/to/dzQzpUd8";
    isPartnerPlans = true;
  } else {
    history.push(url);
  }

  return (
    <>
      <PrimaryNavigation isTypeform />
      <Wrapper
        id={url}
        onSubmit={(e) => {
          const { responseId } = e;
          localStorage.removeItem("form");
          dispatch(Actions.Creators.changeCurrent(0));
          history.push("/create-plan", {
            formType,
            responseId,
            isPartnerPlans,
            reportId,
            option
          });
        }}
      />
    </>
  );
};

export default PlanInfoTypeformPage;
