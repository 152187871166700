import React from "react";
import styled, { css } from "styled-components";
import { Text, Currency } from "components";
import { isNumber } from "lodash";

const Block = styled.div`
  ${({ border }) =>
    border &&
    css`
      border-top: 2px solid #7d7d7d;
    `}
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 0px;
`;

const StyleText = styled(Text)`
  font-size: 15px;
  font-family: 'circular';
  ${({ right }) =>
    right
      ? css`
          text-align: right;
        `
      : css`
          text-align: left;
        `}

  ${({ bold }) =>
    bold && `color: rgb(0, 32, 96); font-weight: bolder;`}

  ${({ price }) => price && `font-size: 18px; font-weight: bold`}


  ${({ color }) =>
    color && `color: ${color};`}

`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: center;
`;

const StyleTextWrapper = styled.div`
  box-sizing: border-box;
  padding: 0 1rem;
`;

const NewWrapper = styled.div`
  max-width: 70%;
`

const cost = (price) => <Currency price={price} />;

const renderCost = ({ title, value, extra = null, newStyle}) => {

  const [newTitle, subTitle] = title.split('(')

  return (
    <Block key={title} tree={extra}>
      <NewWrapper>
        <StyleText bold arial>{newTitle}</StyleText>
        <StyleText arial>{extra ? `(${extra})` : subTitle ? `(${subTitle}` : ''}</StyleText>
      </NewWrapper>
      {extra && !newStyle ? (
        <FlexWrapper>
          <StyleTextWrapper>
            <StyleText green right arial>
              {extra}
            </StyleText>
          </StyleTextWrapper>
          <StyleText price arial right>
            {isNumber(value) ? cost(value) : value}
          </StyleText>
        </FlexWrapper>
      ) : (
        <StyleText price color={'#1c355e'} bold arial right>
          {isNumber(value) ? cost(value) : value}
        </StyleText>
      )} 
    </Block>
  );
};

const renderTotal = (title, price, newStyle) => {
  return (
    newStyle ? (
    <Block border key={title}>
      <NewWrapper>
        <StyleText color={"#DC4C2D"} bold>{title}</StyleText>
      </NewWrapper>
      <StyleText price color={"#DC4C2D"} right bold>
        {price > 0 ? cost(price) : "Nothing"}
      </StyleText>
    </Block>
    ):
    (
      <Block border key={title}>
        <StyleText bold>{title}</StyleText>
        <StyleText right bold>
          {price > 0 ? cost(price) : "Nothing"}
        </StyleText>
      </Block>
    )
  );
};

const BlockTotalCost = ({ data = [], title, value, newStyle }) => {
  return (
    <>
      {data.map(el => renderCost({...el, newStyle}))}
      {renderTotal(title, value, newStyle)}
    </>
  );
};

export default React.memo(BlockTotalCost);
