import { createReducer } from "reduxsauce";
import Actions from "./actions";
import { merge } from "lodash";

const { Types } = Actions;
const INITIAL_STATE = {
  isRequesting: false,
  isAuth: false,
  error: false,

  isFirstVisit: false,
};

const loginRequest = (state = INITIAL_STATE) => {
  return merge({}, state, {
    isRequesting: true,
    error: false,
  });
};

const loginSuccess = (state = INITIAL_STATE) => {
  return merge({}, state, {
    isRequesting: false,
    isAuth: true,
  });
};

const loginFailure = (state = INITIAL_STATE, { error }) => {
  return merge({}, state, {
    isRequesting: false,
    error,
  });
};

const registerRequest = (state = INITIAL_STATE) => {
  return merge({}, state, {
    isRequesting: true,
    error: false,
  });
};

const registerSuccess = (state = INITIAL_STATE) => {
  return merge({}, state, {
    isRequesting: false,
    isAuth: true,
    isFirstVisit: true,
  });
};

const registerFailure = (state = INITIAL_STATE, { error }) => {
  return merge({}, state, {
    isRequesting: false,
    error,
  });
};

const resetPasswordRequest = (state = INITIAL_STATE) => {
  return merge({}, state, {
    isRequesting: true,
    error: false,
  });
};

const getUserByToken = (state = INITIAL_STATE) => {
  return merge({}, state, {
    isRequesting: true,
    error: false,
  });
};

const resetPasswordFailure = (state = INITIAL_STATE, { error }) => {
  return merge({}, state, {
    isRequesting: false,
    error,
  });
};

const loadRequest = (state = INITIAL_STATE) => {
  return merge({}, state, {
    isRequesting: true,
    error: false,
  });
};

const loadFailure = (state = INITIAL_STATE, { error }) => {
  return merge({}, state, {
    isRequesting: false,
    error,
  });
};

const removeToken = (state = INITIAL_STATE) => {
  return merge({}, state, {
    isAuth: false,
    isFirstVisit: false,
  });
};

const clearError = (state = INITIAL_STATE) => {
  return merge({}, state, {
    error: false,
  });
};

export const HANDLERS = {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,

  [Types.REGISTER_REQUEST]: registerRequest,
  [Types.REGISTER_SUCCESS]: registerSuccess,
  [Types.REGISTER_FAILURE]: registerFailure,

  [Types.RESET_PASSWORD_REQUEST]: resetPasswordRequest,
  [Types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,

  [Types.GET_USER_BY_TOKEN]: getUserByToken,

  [Types.LOAD_REQUEST]: loadRequest,
  [Types.LOAD_SUCCESS]: loginSuccess,
  [Types.LOAD_FAILURE]: loadFailure,

  [Types.REMOVE_TOKEN]: removeToken,
  [Types.CLEAR_AUTH_ERROR]: clearError,
};

const reducer = createReducer(INITIAL_STATE, HANDLERS);

export default reducer;
