import React, { useEffect } from "react";
import { Card } from "antd";
import { Link } from "components";
import { useHistory, useLocation } from "react-router-dom";
import "./styles.css";
import styled from "styled-components";
import { getToken } from "services/utils";
import { useSelector } from "react-redux";

const { Meta } = Card;

const GroupCard = ({ title, icon, group, isAddNew }) => {
  const history = useHistory();
  const { state } = useLocation();
  const { reportId, partner } = state || {};
  const username = useSelector((state) => state.profile.username);

  const otherHref = username
    ? `https://predictabill.typeform.com/to/E56Aa77w?typeform-welcome=0&email=${username || ""}`
    : "https://predictabill.typeform.com/to/MlPebEpM";

  return (
    <>
      {isAddNew ? (
        <a href={otherHref}>
          <Card hoverable style={{}} className="first-card">
            {title}
          </Card>
        </a>
      ) : (
        <Card
          hoverable
          style={{}}
          onClick={async () => {
            if (title !== "Add An Employer") {
              localStorage.removeItem("codeId");
              history.push("/groups/verification", {
                group: group,
                title: title,
                reportId,
                partner,
              });
            }
          }}
          cover={<img alt="example" style={{ padding: "10%", borderRadius: "20%" }} src={icon} />}
        >
          <Meta title={title} />
        </Card>
      )}
    </>
  );
};

export default GroupCard;
