import React from "react";
import styled from "styled-components";
import { Popconfirm, Button, Menu, Dropdown, Modal } from "antd";
import { CopyOutlined, DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import "./styles.css";

const ReportWrapper = styled.div`
  width: 450px;
  height: 400px;
  border: 2px solid #d9d9d9;
  border-radius: 1.5%;
  margin: 40px;

  @media (max-width: 480px) {
    width: 100%;
    margin: 10px auto;
  }
`;

const ReportHeader = styled.div`
  height: 18%;
  border-bottom: 2px solid #d9d9d9;
  padding: 0 3%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.p`
  display: flex;
  font-size: 22px;
  font-weight: 400;
  margin: 0;
`;

const Title = styled.p`
  display: flex;
  font-size: 22px;
  font-weight: 700;
  min-height: 33px;
`;

const StyledButtonAnchor = styled.a`
  display: flex;
  background-color: #fff;
  color: #666666;
  border: 2px solid #d9d9d9;
  width: 65%;
  height: 20%;
  max-height: 47px;
  font-size: 22px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};

  ${({ turnOff }) =>
    turnOff
      ? `color: #d9d9d9;
    cursor: not-allowed`
      : `cursor: pointer;`}

  :hover {
    color: #666666;
    background-color: #f5f5f5;
    ${({ turnOff }) =>
      turnOff
        ? `color: #d9d9d9;
    background-color: #fff;`
        : `cursor: pointer;`}
  }
`;

const StyledButton = styled.button`
  display: flex;
  background-color: #fff;
  color: #666666;
  border: 2px solid #d9d9d9;
  width: 65%;
  height: 20%;
  max-height: 47px;
  font-size: 22px;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
  ${({ turnOff }) =>
    turnOff
      ? `color: #d9d9d9;
  cursor: not-allowed`
      : `cursor: pointer;`}

  :hover {
    color: #666666;
    background-color: #f5f5f5;
    ${({ turnOff }) =>
      turnOff
        ? `color: #d9d9d9;
    background-color: #fff;`
        : `cursor: pointer;`}
  }
`;

const ReportBody = styled.div`
  display: flex;
  padding: 5% 5%;
  height: 82%;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const MyPlansBox = ({
  headerTitle = "COMPLETE",
  headerDate,
  title,
  handleCopyItem,
  handleDeleteItem,
  middleWrapper,
  buttons,
}) => {
  const copyMenuItem = {
    label: "Duplicate",
    key: "1",
    icon: <CopyOutlined />,
    onClick: handleCopyItem,
  };

  const deleteMenuItem = {
    label: "Delete",
    key: "2",
    icon: <DeleteOutlined />,
    onClick: () =>
      Modal.confirm({
        title: "Would you like to permanently delete these plans?",
        content: "Once deleted, these plans will no longer be accessible.",
        okText: "Permanently Delete",
        okType: "primary",
        okButtonProps: { danger: true },
        onOk: handleDeleteItem,
        cancelText: "Cancel",
        wrapClassName: "delete-warning-modal",
        width: 500,
      }),
    danger: true,
  };

  const options = <Menu items={handleCopyItem ? [copyMenuItem, deleteMenuItem] : [deleteMenuItem]} />;

  return (
    <ReportWrapper>
      <ReportHeader>
        <Text>{headerTitle}</Text>
        <Text>{headerDate}</Text>
      </ReportHeader>
      <ReportBody>
        {(handleCopyItem || handleDeleteItem) && (
          <div style={{ position: "absolute", left: "calc(98% - 46px)", top: "2%" }}>
            <Dropdown
              overlay={options}
              trigger={["hover"]}
              placement="bottom"
              arrow={{
                pointAtCenter: true,
              }}
            >
              <Button>
                <EllipsisOutlined style={{ display: "flex" }} />
              </Button>
            </Dropdown>
          </div>
        )}
        <Title>{title}</Title>
        {middleWrapper}
        {buttons &&
          buttons.map((button, index) => (
            // added "turnOff" property instead of "disabled" bc antd Popconfirm doesn't work if child component is disabled
            <Popconfirm
              key={index}
              disabled={!button.disabled || !button.tooltip}
              title={button.tooltip}
              trigger={"hover"}
              okText={"Edit"}
              onConfirm={button.onConfirm}
              okButtonProps={{ style: { display: button.onConfirm ? "initial" : "none" } }}
              cancelButtonProps={{ style: { display: button.onConfirm ? "initial" : "none" } }}
            >
              {button.href ? (
                <StyledButtonAnchor
                  marginTop={index === 0 ? (buttons.length === 1 ? "6%" : "auto") : "2%"}
                  href={button.href}
                  turnOff={button.disabled}
                >
                  {button.text}
                </StyledButtonAnchor>
              ) : (
                <StyledButton
                  key={index}
                  marginTop={index === 0 ? (buttons.length === 1 ? "6%" : "auto") : "2%"}
                  onClick={!button.disabled && (() => button.onClick())}
                  turnOff={button.disabled}
                >
                  {button.text}
                </StyledButton>
              )}
            </Popconfirm>
          ))}
      </ReportBody>
    </ReportWrapper>
  );
};

export default MyPlansBox;
