import React from "react";
import { InputNumber } from "antd";
import styled from "styled-components";
import "antd/dist/antd.css";

const Wrapper = styled.div`
  width: 100%;

  > .ant-input-number {
    width: 100%;
  }
`;

const InputCustom = ({
  format,
  handleChange,
  max,
  min,
  name,
  value,
  disabled
}) => {
  const onChange = value => {
    const target = { name, value };
    handleChange({ target });
  };

  return (
    <Wrapper>
      {format === "currency" ? (
        <InputNumber
          onChange={onChange}
          value={value}
          formatter={value =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={value => value.replace(/\$\s?|(,*)/g, "")}
          max={max}
          min={min}
          disabled={disabled}
        />
      ) : format === "percent" ? (
        <InputNumber
          onChange={onChange}
          value={value}
          formatter={value => `${value}%`}
          parser={value => value.replace("%", "")}
          max={max}
          min={min}
          disabled={disabled}
        />
      ) : (
        <InputNumber
          onChange={onChange}
          value={value}
          max={max}
          min={min}
          type="number"
          disabled={disabled}
        />
      )}
    </Wrapper>
  );
};

export default React.memo(InputCustom);
