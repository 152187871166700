import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/report/actions";
import SegmentedControl from "../SegmentedControl";
import styled from "styled-components";
import { getRecommendedPlansInformation } from "services/utils/getRecommendations";

const AddChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  color: #21355b;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const useHasMultipleCompanies = ({ reportType, isJobOffer }) => {
  const hasNotSeparate = useSelector((state) => state.report.hasNotSeparate);

  return !(reportType === "individual" || hasNotSeparate || isJobOffer);
};

const StarIcon = () => {
  return (
    <svg
      style={{ width: "20px", height: "20px" }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const PlansModeControl = ({ reportType, isJobOffer, optimization, preference, loading }) => {
  const plansMode = useSelector((state) => state.report.plansMode);
  const dispatch = useDispatch();
  const bestPlan = getRecommendedPlansInformation(optimization);
  const plansToJoinPreference = preference?.join;
  const controlRef = useRef();
  const segments = [
    {
      label: "Together",
      value: "samePlan",
      leftAddornment: bestPlan?.planType === "samePlan" ? <StarIcon /> : "",
      id: "same-plan-btn",
      ref: useRef(),
    },
    {
      label: "Separate",
      value: "separatePlans",
      leftAddornment: bestPlan?.planType === "separatePlans" ? <StarIcon /> : "",
      id: "separate-plans-btn",
      ref: useRef(),
    },
  ];

  const hasMultipleCompanies = useHasMultipleCompanies({ reportType, isJobOffer });
  if (loading) return null;
  if (!hasMultipleCompanies) return null;
  if (plansToJoinPreference && plansToJoinPreference !== "all") return null;

  return (
    <SegmentedControl
      name="plansMode"
      controlRef={controlRef}
      segments={segments}
      callback={(planMode) => {
        dispatch(Actions.Creators.switchState({ plansMode: planMode }));
        if (planMode === "samePlan") {
          dispatch(Actions.Creators.switchState({ comparedPlanSpouse: "" }));
          dispatch(Actions.Creators.switchState({ planSelectedFrom: "birthParent" }));
        }
      }}
      initialValue={plansMode}
    />
  );
};

export const PlansModeAddChildren = ({ reportType, isJobOffer, optimization, report, preference, loading }) => {
  const plansMode = useSelector((state) => state.report.plansMode);
  const hasKid = useSelector((state) => state.report.hasKid);
  const children = useSelector((state) => state.report.children);
  const dispatch = useDispatch();
  const bestPlan = getRecommendedPlansInformation(optimization);
  const plansToJoinPreference = preference?.join;
  const controlRef = useRef();
  const segments = [
    {
      label: report?.birthParent?.company,
      value: "birthParent",
      leftAddornment: bestPlan?.childrenPlanType === "birthParent" ? <StarIcon /> : "",
      id: "birth-parent-btn",
      ref: useRef(),
    },
    {
      label: report?.partner?.company,
      value: "partner",
      leftAddornment: bestPlan?.childrenPlanType === "partner" ? <StarIcon /> : "",
      id: "partner-btn",
      ref: useRef(),
    },
  ];

  const hasMultipleCompanies = useHasMultipleCompanies({ reportType, isJobOffer });
  if (loading) return null;
  if (!hasMultipleCompanies) return null;
  if (plansToJoinPreference && plansToJoinPreference !== "all") return null;
  if (plansMode === "samePlan" || !hasKid) {
    return null;
  }

  return (
    <AddChildrenContainer>
      <div style={{ fontSize: 14, margin: 0 }}>Add children to</div>
      <SegmentedControl
        name="planSelectedFrom"
        segments={segments}
        controlRef={controlRef}
        callback={(planSelectedFrom) => {
          dispatch(
            Actions.Creators.switchState({
              children: planSelectedFrom,
            }),
          );
        }}
        initialValue={children}
      />
    </AddChildrenContainer>
  );
};
