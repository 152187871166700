export const getDateFormat = date => {
  const dateObj = new Date(date);
  const month = dateObj.getMonth() < 10
    ? "0" + (dateObj.getMonth() + 1)
    : dateObj.getMonth() + 1;
  const day = dateObj.getDate() < 10
    ? "0" + dateObj.getDate()
    : dateObj.getDate();
  const year = dateObj.getFullYear();
  
  return `${month}/${day}/${year}`;
}
