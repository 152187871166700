import React from "react";
import styled from "styled-components";
import {
  CalculateHeader as Header,
  Table,
  ReportTableBody,
  TableRow,
  TableCell,
  Spinner,
  ButtonLink,
} from "components";

const FlexWrapper = styled.section`
  display: flex;

  justify-content: center;
  ${({ row }) =>
    row
      ? `
    flex-direction: column;
  `
      : " padding: 0;"}

  flex-wrap: wrap;

  padding: 20px 0;
`;

const Wrapper = styled.div`
  font-family: 15px;
  color: #123;
  padding: 20px;
  padding-top: 0;
`;

const ReportUploads = ({ header, body, isRequestingReports }) => {
  const renderHeaderName = () => (
    <TableRow header>
      <TableCell small>#</TableCell>
      <TableCell width="180px" big className="mobile-hide">
        Your Customized Analysis
      </TableCell>
      <TableCell width="85px" center>
        Interactive
      </TableCell>
      <TableCell width="85px" center>
        Order Fulfilled
      </TableCell>
      <TableCell width="85px" center className="mobile-hide">
        Order Placed
      </TableCell>
      <TableCell big center className="mobile-hide">
        Your Top Question
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <Wrapper>
        <Header bigTitle={true} title="Your Optimizations" icon="search" />
        <hr />
        <Header text="Here are all of your current and past scenarios." />
        <FlexWrapper>
          {isRequestingReports ? (
            <Spinner center top />
          ) : (
            <Table id="report-table">
              <thead>{renderHeaderName()}</thead>
              <ReportTableBody reports={body} />
            </Table>
          )}
        </FlexWrapper>
        <FlexWrapper>
          <ButtonLink textAlign withBG to="/account/your_uploads">
            See My Uploads
          </ButtonLink>
        </FlexWrapper>
      </Wrapper>
    </>
  );
};

export default ReportUploads;
