import React from "react";
import { Link, Text } from "components";
import { updateReport } from "services/api/admin.api";
import { getToken } from "services/utils";

import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  message,
} from "antd";
import {
  MinusOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const { Item, List } = Form;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const innerLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 12 },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: { span: 14, offset: 6 },
};

const cardLayout = {
  style: { width: "1400px" },
  headStyle: { background: "#d9f3f1" },
};

const ReportForm = ({ report, plans }) => {
  const [form] = Form.useForm();
  const { birthParent, event, partner, status, type } = report;

  const birthParentPlans = plans.filter((plan) =>
    birthParent.planIds.includes(plan.externalId)
  );
  const partnerPlans = plans.filter((plan) =>
    partner.planIds.includes(plan.externalId)
  );

  const onSubmit = async (values) => {
    const token = await getToken();
    const response = await updateReport(report._id, values, token);

    if (response.status === 200) {
      message.success("Report Updated Successfully");
    } else {
      message.error("Error Updating Report");
    }
  };

  const renderServices = (serviceType) => (
    <Card type="inner" title={`${serviceType} services`} style={{ width: 660 }}>
      <Item label="Title" name={[serviceType, "title"]}>
        <Input placeholder={`${serviceType} Charges`} />
      </Item>
      <List name={[serviceType, "services"]}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((field, i) => (
                <Item required={false} key={field.key}>
                  <Card type="inner" style={{ width: 500 }}>
                    <Item
                      {...field}
                      label="Name"
                      name={[field.name, "name"]}
                      fieldKey={[field.fieldKey]}
                      {...layout}
                    >
                      <Select placeholder="Select an Option">
                        <Option value="therapy">Therapy</Option>
                        <Option value="hospital">Hospital</Option>
                      </Select>
                    </Item>

                    <Item
                      {...field}
                      label="Member"
                      name={[field.name, "member"]}
                      fieldKey={[field.fieldKey]}
                      {...layout}
                    >
                      <Select placeholder="Select an Option">
                        <Option value="birthParent">{birthParent.name}</Option>
                        {type !== "individual" && partner.name ? (
                          <Option value="partner">{partner.name}</Option>
                        ) : null}
                        <Option value="baby">Baby</Option>
                        {event.general.children ? (
                          <Option value="child">Child</Option>
                        ) : null}
                      </Select>
                    </Item>

                    <Item
                      {...field}
                      label="In network"
                      name={[field.name, "inNetwork"]}
                      fieldKey={[field.fieldKey]}
                      {...layout}
                    >
                      <Select placeholder="Select an Option">
                        <Option value={true}>Yes</Option>
                        <Option value={false}>No</Option>
                      </Select>
                    </Item>

                    <Item
                      {...field}
                      label="Charge"
                      name={[field.name, "charge"]}
                      fieldKey={[field.fieldKey]}
                      {...layout}
                      extra="For pregnancy, input 25000 for the birth parent or 15403 for the baby"
                    >
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Item>

                    <Item
                      {...field}
                      label="Quantity"
                      name={[field.name, "qty"]}
                      fieldKey={[field.fieldKey]}
                      {...layout}
                      extra="For hospital - input # of nights"
                    >
                      <InputNumber />
                    </Item>

                    <Button
                      danger
                      onClick={() => {
                        remove(field.name);
                      }}
                      block
                    >
                      <MinusOutlined /> Remove service
                    </Button>
                  </Card>
                </Item>
              ))}
              <Item {...formItemLayoutWithOutLabel}>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  block
                >
                  <PlusOutlined /> Add service
                </Button>
              </Item>
            </>
          );
        }}
      </List>
    </Card>
  );

  const renderInsuranceForm = (parent) => {
    const { company, name, pamphlet, parent: parentType } = parent;
    const parentPlans =
      parentType === "birthParent" ? birthParentPlans : partnerPlans;
    const parentPlansWithOON = parentPlans.filter(
      (plan) => plan.outOfNetwork.coverage
    );

    return (
      <Card
        title={`Insurance Option - ${name ? name : "Not Provided"}`}
        extra={company}
        {...cardLayout}
      >
        <Item label="Person's Name" name={[`${parentType}`, "name"]}>
          <Input placeholder="Name" />
        </Item>

        <Item label="Company" name={[`${parentType}`, "company"]}>
          <Input placeholder="company" />
        </Item>

        <Item label="Premium Tier" name={[`${parentType}`, "premiumTier"]}>
          <InputNumber />
        </Item>

        <Item label="HSA Tier" name={[`${parentType}`, "hsaTier"]}>
          <InputNumber />
        </Item>

        <Space type="horizontal" align="start">
          <Card type="inner" title="Plans" style={{ width: 445 }}>
            <List name={[`${parentType}`, "planIds"]}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, i) => (
                    <Item
                      {...layout}
                      key={field.key}
                      style={{ marginBottom: "2px" }}
                    >
                      <div style={{ display: "flex" }}>
                        <Item
                          {...field}
                          label={i + 1}
                          name={field.name}
                          fieldKey={[field.fieldKey]}
                        >
                          <Input />
                        </Item>

                        <MinusCircleOutlined
                          style={{ margin: "5px 8px" }}
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </div>
                    </Item>
                  ))}
                  <Item {...formItemLayoutWithOutLabel}>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add Plan
                    </Button>
                  </Item>
                </>
              )}
            </List>
          </Card>

          <Card type="inner" title="Pamphlet Details" style={{ width: 445 }}>
            <List name={[`${parentType}`, "pamphlet"]}>
              {(fields) => (
                <>
                  {fields.map((field, i) => (
                    <Item {...field} label={i + 1}>
                      <Link
                        href={`https://drive.google.com/open?id=${pamphlet[i].id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        black
                        fontSize="16"
                      >
                        <Text>{pamphlet[i].filename.slice(0, 40) + "..."}</Text>
                      </Link>
                    </Item>
                  ))}
                </>
              )}
            </List>

            <Item
              {...innerLayout}
              label="General Complete"
              name={[`${parentType}`, "generalIsComplete"]}
            >
              <Select placeholder="Select an Option" style={{ width: "50%" }}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Item>

            <Item
              {...innerLayout}
              label="Premium Complete"
              name={[`${parentType}`, "premiumIsComplete"]}
            >
              <Select placeholder="Select an Option" style={{ width: "50%" }}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Item>

            <Item
              {...innerLayout}
              label="HSA Complete"
              name={[`${parentType}`, "hsaIsComplete"]}
            >
              <Select placeholder="Select an Option" style={{ width: "50%" }}>
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Item>
          </Card>

          <Card
            type="inner"
            title="Out-Of-Network Details"
            style={{ width: 445 }}
          >
            <Item label="Reimbursement Rates" {...innerLayout} />
            <Item
              {...innerLayout}
              label="Default"
              name={[
                `${parentType}`,
                "outOfNetworkServices",
                "reimbursementRates",
                "default",
              ]}
            >
              <InputNumber
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                placeholder="default"
              />
            </Item>

            {parentPlansWithOON.map((plan) => {
              const { name, network, externalId } = plan;
              return (
                <Item
                  {...innerLayout}
                  label={`${network} ${name}`}
                  name={[
                    `${parentType}`,
                    "outOfNetworkServices",
                    "reimbursementRates",
                    `${externalId}`,
                  ]}
                  key={externalId}
                >
                  <InputNumber
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Item>
              );
            })}
          </Card>
        </Space>
      </Card>
    );
  };

  const renderPreferences = (preference) => (
    <Card type="inner" title={`Preference ${preference}`} {...cardLayout}>
      <Item
        label="Apply Preference"
        name={["preferences", preference, "apply"]}
      >
        <Select placeholder="Select an Option" style={{ width: "50%" }}>
          <Option value={true}>Yes</Option>
          <Option value={false}>No</Option>
        </Select>
      </Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.preferences[preference].apply !==
          currentValues.preferences[preference].apply
        }
      >
        {({ getFieldValue }) => {
          if (getFieldValue("preferences")[preference].apply === true)
            return (
              <>
                <Item
                  label="Column Heading"
                  name={["preferences", preference, "title"]}
                >
                  <Input placeholder="Lowest Cost" />
                </Item>

                <Item
                  label="Column Subheading"
                  name={["preferences", preference, "subTitle"]}
                >
                  <Input />
                </Item>

                <Item
                  label="Plans to Join"
                  name={["preferences", preference, "join"]}
                >
                  <Select
                    placeholder="Select an Option"
                    style={{ width: "50%" }}
                  >
                    {type !== "individual" && (
                      <Option value="same">Same Plan</Option>
                    )}
                    {type !== "individual" && (
                      <Option value="separate">Separate Plans</Option>
                    )}
                    {birthParent.company && (
                      <Option value="birthParent">{birthParent.company}</Option>
                    )}
                    {partner.company && (
                      <Option value="partner">{partner.company}</Option>
                    )}
                  </Select>
                </Item>

                <Item
                  label="Optimize for"
                  name={["preferences", preference, "cost"]}
                >
                  <Select
                    placeholder="Select an Option"
                    style={{ width: "50%" }}
                  >
                    <Option value="lowest">Lowest Cost</Option>
                    <Option value="lowest 1">2nd Lowest Cost</Option>
                    <Option value="lowest 2">3rd Lowest Cost</Option>
                    <Option value="highest">Highest Cost</Option>
                    <Option value="highest 1">2nd Highest Cost</Option>
                    <Option value="highest 2">3rd Highest Cost</Option>
                  </Select>
                </Item>

                <Card
                  type="inner"
                  title="Select Plans to include in optimization"
                >
                  <Space direction="horizontal">
                    {birthParent.company && (
                      <Item
                        label={birthParent.company}
                        name={[
                          "preferences",
                          preference,
                          "include",
                          "birthParentPlans",
                        ]}
                      >
                        <Checkbox.Group>
                          <Row>
                            {birthParentPlans.map((plan) => {
                              const { externalId, network, name } = plan;
                              return (
                                <Col span={20} key={externalId}>
                                  <Checkbox
                                    value={externalId}
                                    style={{ lineHeight: "32px" }}
                                  >
                                    {`${network} ${name}`}
                                  </Checkbox>
                                </Col>
                              );
                            })}
                          </Row>
                        </Checkbox.Group>
                      </Item>
                    )}
                    {partner.company && (
                      <Item
                        label={partner.company}
                        name={[
                          "preferences",
                          preference,
                          "include",
                          "partnerPlans",
                        ]}
                      >
                        <Checkbox.Group>
                          <Row>
                            {partnerPlans.map((plan) => {
                              const { externalId, network, name } = plan;
                              return (
                                <Col span={20} key={externalId}>
                                  <Checkbox
                                    value={externalId}
                                    style={{ lineHeight: "32px" }}
                                  >
                                    {`${network} ${name}`}
                                  </Checkbox>
                                </Col>
                              );
                            })}
                          </Row>
                        </Checkbox.Group>
                      </Item>
                    )}
                  </Space>
                </Card>
              </>
            );
        }}
      </Form.Item>
    </Card>
  );

  return (
    <Form
      {...layout}
      form={form}
      initialValues={report}
      layout="horizontal"
      onFinish={onSubmit}
    >
      <Space direction="vertical">
        <Card title="Report Details" {...cardLayout}>
          <Item label="Is Paid" name="isPaid">
            <Select placeholder="Select an Option" style={{ width: "50%" }}>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Item>

          <Item label="Report Type" name="type">
            <Select placeholder="Select an Option" style={{ width: "50%" }}>
              <Option value="individual">Individual</Option>
              <Option value="household (with no spouse)">
                Household (with no spouse)
              </Option>
              <Option value="household">Household (with spouse)</Option>
              {/* <Option value="baby">baby</Option> */}
            </Select>
          </Item>

          <Item label="Zip Code" name={["event", "general", "zipCode"]}>
            <Input />
          </Item>

          <Item label="Date of Birth" name={["event", "general", "dob"]}>
            <Input placeholder="MM/DD/YYYY" />
          </Item>

          <Item label="Is spouse?" name={["event", "marriage", "isApply"]}>
            <Select placeholder="Select an Option" style={{ width: "50%" }}>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Item>

          <Item label="Children" name={["event", "general", "children"]}>
            <InputNumber />
          </Item>

          <List name="dates">
            {(fields) => (
              <>
                {fields.map((field, i) => (
                  <Item {...field} label={i === 0 ? "Start Date" : "End Date"}>
                    <Input placeholder="MM/DD/YYYY" />
                  </Item>
                ))}
              </>
            )}
          </List>

          <Item label="Expecting a Baby?" name="isDue">
            <Select placeholder="Select an Option" style={{ width: "50%" }}>
              <Option value={true}>Yes</Option>
              <Option value={false}>No</Option>
            </Select>
          </Item>

          <Item
            label="Event Date"
            name="dueDate"
            extra="For Baby - input due date"
          >
            <Input placeholder="MM/DD/YYYY" />
          </Item>

          <Item label="Completion Status" name="status">
            <Select placeholder="Select an Option" style={{ width: "50%" }}>
              <Option value={0}>0 - Incomplete</Option>
              <Option value={1}>1 - Pending Review</Option>
              <Option value={2}>2 - Require More Info</Option>
              <Option value={3}>3 - Complete</Option>
            </Select>
          </Item>

          {status === 3 ? (
            <Item label="Completed Date" name="fulfilledDate">
              <Input placeholder="MM/DD/YYYY" />
            </Item>
          ) : null}

          <Space direction="horizontal" align="start">
            {renderServices("expected")}
            {renderServices("unexpected")}
          </Space>
        </Card>
        <Card title="Optimization Results" {...cardLayout}>
          <Item label="Recommended Option" name="title">
            <Input.TextArea />
          </Item>

          <Item
            label="Plan Comparison Page Title"
            name={["results", "comparisonTitle"]}
          >
            <Input.TextArea />
          </Item>

          <Item label="Overview - Rationale" name={["results", "downside"]}>
            <Input.TextArea />
          </Item>

          <List name={["results", "whatNow"]}>
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field, i) => (
                    <Item
                      {...(i === 0 ? layout : formItemLayoutWithOutLabel)}
                      label={i === 0 ? "Overview - What Now?" : ""}
                      required={false}
                      key={field.key}
                    >
                      <Item
                        {...field}
                        name={field.name}
                        fieldKey={[field.fieldKey]}
                        style={{ marginBottom: 0 }}
                      >
                        <Input.TextArea style={{ display: "inline" }} />
                      </Item>

                      {fields.length > 1 ? (
                        <Button
                          danger
                          onClick={() => {
                            remove(field.name);
                          }}
                          block
                        >
                          <MinusOutlined /> Remove step
                        </Button>
                      ) : null}
                    </Item>
                  ))}
                  <Item {...formItemLayoutWithOutLabel}>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      <PlusOutlined /> Add step
                    </Button>
                  </Item>
                </>
              );
            }}
          </List>
        </Card>
        {renderInsuranceForm(birthParent)}
        {renderInsuranceForm(partner)}
        {renderPreferences("1")}
        {renderPreferences("2")}
        {renderPreferences("3")}

        <Item name={["preferences", "custom"]} hidden />
        <Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Item>
      </Space>
    </Form>
  );
};

export default ReportForm;
