import React from "react";
import styled from "styled-components";
import { Text } from "components";
import { SPACE_BEFORE_TABLES, SPACE_BETWEEN_TABLES, StyledCell } from "components/organisms/AllMyOptions";
import { useSelector } from "react-redux";
import { checkColumnIsFiltered } from "../Body";
import { getNonFilteredColumnLength } from "../Title";

const TableTag = ({ columnHeadersBP, columnHeadersPartner, isJobOffer, preference }) => {
  const recommendedPlan = useSelector((state) => state.report.recommendedPlan);
  const join = useSelector((state) => state.report.join);
  const detailsJoin = useSelector((state) => state.report.detailsJoin);
  const plansMode = useSelector((state) => state.report.plansMode);
  const children = useSelector((state) => state.report.children);
  const childrenRecommended = useSelector((state) => state.report.childrenRecommended);

  const sameRecommendation = join === plansMode && children === childrenRecommended;

  const birthParentHeadersLength = getNonFilteredColumnLength(columnHeadersBP, preference, "birthParent");
  const partnerHeadersLength = getNonFilteredColumnLength(columnHeadersPartner, preference, "partner");

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row",
        flexWrap: "nowrap",
        transition: "all 0.3s ease",
        width: "max-content",
        justify: `${birthParentHeadersLength + partnerHeadersLength > 7 ? "start" : ""}`,
      }}
    >
      <StyledCell customWidth={`${SPACE_BEFORE_TABLES}px`} customHeight="0" />
      {columnHeadersBP.map((column, _i) => {
        const isColumnFiltered = checkColumnIsFiltered({ name: column.name }, preference, "birthParent");
        if (isColumnFiltered) return null;
        const recommended =
          (plansMode === "samePlan"
            ? recommendedPlan[0] === column.name && detailsJoin === "birthParent"
            : recommendedPlan[0] === column.name) && sameRecommendation;
        return recommended ? (
          <StyledCell recommended span={3} key={_i} background="blue" hFit bordered>
            <Text bold fontSize="12" white customLSpacing="0">
              Recommended
            </Text>
          </StyledCell>
        ) : (
          <StyledCell span={3} customHeight="0" />
        );
      })}

      <StyledCell hide={!partnerHeadersLength} customWidth={`${SPACE_BETWEEN_TABLES}px`} customHeight="0" />
      {columnHeadersPartner.map((column, _i) => {
        const isColumnFiltered = checkColumnIsFiltered({ name: column.name }, preference, "partner");
        if (isColumnFiltered) return null;
        const recommended =
          (plansMode === "samePlan"
            ? recommendedPlan[0] === column.name && detailsJoin === "partner"
            : recommendedPlan[1] === column.name) && sameRecommendation;
        return !isJobOffer && recommended ? (
          <StyledCell recommended span={3} key={_i} background="blue" hFit bordered>
            <Text bold fontSize="12" white customLSpacing="0">
              Recommended
            </Text>
          </StyledCell>
        ) : (
          <StyledCell span={3} customHeight="0" />
        );
      })}
    </div>
  );
};

export default TableTag;
