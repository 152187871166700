import React from "react";
import styled from "styled-components";
import { Icon } from "@ant-design/compatible";
import Linkify from "react-linkify";

import {
  CalculateHeader as Header,
  Currency,
  Text,
  Timeline,
} from "components";
import OverviewCard from "components/molecules/OverviewCard";
import { formatCurrency } from "utils/currency";
import { useSelector } from "react-redux";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px auto;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: ${(props) => props.justify};
  width: 85%;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 10px;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

const HtmlWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const Overview = ({ allOptimizations, report, changeSideBar, customOptimizations }) => {
  const { results, dates, isDue, unexpected: unexpectedFromReport } = report;

  const customOverviewTile = report.customOverviewTile || {};

  const serviceOption = useSelector((state) => state.report.serviceOption);

  const {
    showCustomOverviewTile,
    body,
    buttonText,
    heading,
    miniTitle,
    subheading,
    showButton,
    buttonLink,
  } = customOverviewTile;

  const startDate = dates[0];
  const endDate = dates[1];

  const getSelectedOptimization = () => {
    const customSelected = customOptimizations[0]?.selected || null;

    if (!customSelected) {
      return allOptimizations[0]?.selected;
    }

    const customSubHeading = customSelected.subHeading[0];

    for (let allOpt of allOptimizations) {
      let allOptSubHeading = allOpt.selected.subHeading[0]
      if (allOptSubHeading === customSubHeading) {
        return allOpt.selected;
      }
    }

    return customOptimizations[0]?.selected;
  }

  const { expected, unexpected } = getSelectedOptimization() || {
    subHeading: "",
    expected: {
      total: null,
      charges: {
        before: [{ id: "premium", details: [] }],
        after: [{ id: "premium", details: [] }],
      },
    },
    unexpected: {
      total: null,
      charges: {
        before: [{ id: "premium", details: [] }],
        after: [{ id: "premium", details: [] }],
      },
    },
  };

  const join = useSelector((state) => state.report.join);

  const reducer = (accumulator, currentValue) =>
    accumulator + currentValue.premium;

  const premiumDetailsBefore = expected.charges.before.find(
    (charge) => charge.id === "premium"
  );
  const costBefore = premiumDetailsBefore?.details.reduce(reducer, 0) || 0;
  let premiumBefore = premiumDetailsBefore
    ? formatCurrency(costBefore)
    : formatCurrency(0);

  if (costBefore < 0) premiumBefore = premiumBefore.substring(1) + " cash back";
  const premiumDetailsAfter = expected.charges.after.find(
    (charge) => charge.id === "premium"
  );
  const costAfter = premiumDetailsAfter?.details.reduce(reducer, 0) || 0;
  let premiumAfter = premiumDetailsAfter ? formatCurrency(costAfter) : null;
  if (costAfter < 0) premiumAfter = premiumAfter.substring(1) + " cash back";

  let expectedSpending =
    expected.total === 0
      ? "nothing"
      : expected.total
        ? formatCurrency(expected.total)
        : "...";
  if (expected.total < 0)
    expectedSpending = expectedSpending.substring(1) + " cash back";

  let unexpectedSpending =
    unexpected.total === 0
      ? "nothing"
      : unexpected.total
        ? formatCurrency(unexpected.total)
        : "...";
  if (unexpected.total < 0)
    unexpectedSpending = unexpectedSpending.substring(1) + " cash back";

  const unexpectedPremiumDetails = expected.charges.after.find(
    (charge) => charge.id === "premium"
  );

  const hasPositivePremium = unexpectedPremiumDetails?.details?.some(
    (item) => item.premium > 0
  );

  const howManyDrugs = useSelector((state) => state.report.howManyDrugs);
  const howManyDoctors = useSelector((state) => state.report.howManyDoctors);
  const howManyHospitals = useSelector(
    (state) => state.report.howManyHospitals
  );

  const getComparisonPeriod = () => {
    // TODO: Get difference in months
    let diff = 0;
    const startDateArray = startDate.split("/");
    const startMonth = startDateArray[0];
    const startDay = startDateArray[1];
    const startYear = startDateArray[2];

    const endDateArray = endDate.split("/");
    const endMonth = endDateArray[0];
    const endDay = endDateArray[1];
    const endYear = endDateArray[2];

    const getMonth = {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      7: "July",
      8: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December",
    };

    const getDay = (str) => {
      const day = parseInt(str);

      if (day === 1 || str === "21" || str === "31") {
        return `${day}st`;
      } else if (day === 2 || str === "22") {
        return `${day}nd`;
      } else if (day === 3 || str === "23") {
        return `${day}rd`;
      } else {
        return `${day}th`;
      }
    };

    if (parseInt(endYear - startYear) === 1) {
      diff += 12;
    }

    diff += parseInt(endMonth - startMonth);

    if (parseInt(endDay - startDay) >= 28) {
      diff += 1;
    }

    if (diff >= 12) {
      return "during the year";
    } else {
      return `over ${diff} months beginning ${getMonth[parseInt(startMonth)]
        } ${getDay(startDay)}`;
    }
  };

  const renderCoverageCost = () => {
    if (costBefore === 0) {
      return (
        <>
          <Text fontSize="20" lg inline>
            {"This coverage will "}
          </Text>
          <Text fontSize="20" lg inline green bold>
            not cost{" "}
          </Text>
          <Text fontSize="20" lg inline>
            anything
          </Text>
        </>
      );
    } else if (costBefore < 0) {
      return (
        <>
          <Text fontSize="20" lg inline>
            {"You'll get "}
          </Text>
          <Text fontSize="20" lg inline green bold>
            {premiumBefore}
          </Text>
          <Text fontSize="20" lg inline>
            {" per month"}
          </Text>
        </>
      );
    } else {
      return (
        <>
          <Text fontSize="20" lg inline>
            {"This coverage will cost "}
          </Text>
          <Text fontSize="20" lg inline green bold>
            {premiumBefore}
          </Text>
          <Text fontSize="20" lg inline>
            {" per month"}
          </Text>
        </>
      );
    }
  };

  const renderEstimatedSpend = () => {
    if (costBefore === 0) {
      return " on out-of-pocket costs ";
    } else if (costBefore < 0) {
      return `${hasPositivePremium ? " on premiums and" : "on"
        } out-of-pocket costs, net of cash back `;
    } else {
      return " on premiums and out-of-pocket costs ";
    }
  };

  const getEstimateSpend = (bodyTitle) => {
    const comparisonPeriod = getComparisonPeriod();
    const upperCaseWords = unexpectedFromReport?.title?.match(
      /(\b[A-Z][A-Z]+|\b[A-Z]\b)/g
    );

    const unexpectedTitle =
      serviceOption ? 'Expected Services only' : 
        'Expected and Unexpected Services';

    return (
      <InnerWrapper>
        <Text blue fontSize="28" bold style={{ marginBottom: 10 }}>
          {bodyTitle}
        </Text>
        <div>
          {renderCoverageCost()}

          {isDue && premiumAfter && premiumAfter !== premiumBefore ? (
            <>
              <Text fontSize="20" lg inline>
                {" at first, then "}
              </Text>
              <Text fontSize="20" lg inline green bold>
                {premiumAfter}
              </Text>
              <Text fontSize="20" lg inline>
                {" per month with your new baby. "}
              </Text>
            </>
          ) : (
            <Text fontSize="20" lg inline>
              {". "}
            </Text>
          )}

          <Text fontSize="20" lg inline>
            {"We estimate you would "}{" "}
            {unexpected.total < 0 ? "net " : "spend a grand total of "}
          </Text>
          <Text fontSize="20" lg inline green bold>
            {unexpectedSpending}
          </Text>
          <Text fontSize="20" lg inline>
            {` ${comparisonPeriod} ${renderEstimatedSpend()} (assuming ${unexpectedTitle}). Assuming only expected services, We estimate you would `}{" "}
            {expected.total < 0 ? "net " : "spend "}
          </Text>
          <Text fontSize="20" lg inline green bold>
            {expectedSpending}
          </Text>
          <Text fontSize="20" lg inline>
            .
          </Text>
        </div>
      </InnerWrapper>
    );
  };

  const getCostOfCare = (bodyTitle) => {
    let recommendation = "...";

    if (customOptimizations[0] && customOptimizations[0]?.selected) {
      const { heading, subHeading } = customOptimizations[0]?.selected;
      if (heading.includes("Same")) {
        recommendation = `for everyone to join ${subHeading[0]}. `;
      } else if (heading.includes("Separate")) {
        const birthParentFields = subHeading[0].split(":");
        const partnerFields = subHeading[1].split(":");

        recommendation = `for ${birthParentFields[0]} to join ${birthParentFields[1]} and ${partnerFields[0]} to join ${partnerFields[1]} separately. `;
      } else {
        const fields = subHeading[0].split(":");
        recommendation = `to join ${fields[1]}. `;
      }
    }
    return (
      <InnerWrapper>
        <Text blue fontSize="28" bold style={{ marginBottom: 10 }}>
          {bodyTitle}
        </Text>
        <div>
          <Text fontSize="20" lg inline>
            {`Based on the information you provided, the optimal choice is `}
          </Text>
          <Text fontSize="20" lg inline green bold>
            {recommendation}
          </Text>
          {/* <Text fontSize="20" lg inline>
            With low premiums and cost of care,{" "}
          </Text> */}
          <Text fontSize="20" lg inline green bold>
            This plan is the most affordable option{" "}
          </Text>
          <Text fontSize="20" lg inline>
            whether or not you experience an unplanned hospital stay.
          </Text>
        </div>
      </InnerWrapper>
    );
  };

  const getCustom = () => {
    return (
      <InnerWrapper>
        <Text blue fontSize="28" bold style={{ marginBottom: 10 }}>
          {miniTitle}
        </Text>
        <HtmlWrapper dangerouslySetInnerHTML={{ __html: body }} />
      </InnerWrapper>
    );
  };

  const renderSpendingCardTitle = () => {
    const spending = expectedSpending === unexpectedSpending ?
      expectedSpending : `${expectedSpending} - ${unexpectedSpending}`
    if (expectedSpending === "nothing") {
      return `Up to ${unexpectedSpending}`;
    } else if (expected.total < 0 && unexpected.total > 0) {
      return `${spending} in costs`;
    } else {
      return `${spending}`;
    }
  };

  const providersEmpty =
    howManyDoctors === "hide" &&
    howManyDrugs === "hide" &&
    howManyHospitals === "hide";

  const inNetworkCareTitleSuffix =
    join === "separatePlans" ? "for some of you" : "on this plan";

  return (
    <Wrapper>
      <Header title="Why this plan?" bigTitle />
      {howManyDoctors && (
        <FlexWrapper
          justify={providersEmpty ? "center" : "flex-start"}
          margin="20px"
        >
          <OverviewCard
            title={
              isDue && premiumAfter && premiumAfter !== premiumBefore
                ? premiumAfter
                : premiumBefore
            }
            subheading={"per month"}
            bodyTitle={"Estimated Spending"}
            buttonText={"See monthly spending options"}
            onButtonPress={() => changeSideBar("2")}
            getBody={getEstimateSpend}
          />
          <OverviewCard
            title={renderSpendingCardTitle()}
            isSmallTitle={expected.total < 0}
            subheading={"per year"}
            bodyTitle={"Cost of Care"}
            buttonText={"See annual cost details"}
            onButtonPress={() => changeSideBar("4")}
            getBody={getCostOfCare}
          />
          {howManyDoctors !== "hide" && (
            <OverviewCard
              title={howManyDoctors + " doctors"}
              provider="doctors"
              subheading={"are in-network*"}
              bodyTitle={"In-Network Care"}
              description={`are in-network ${inNetworkCareTitleSuffix}`}
              buttonText={"See in-network doctors"}
              onButtonPress={() => changeSideBar("5")}
            />
          )}
          {howManyDrugs !== "hide" && (
            <OverviewCard
              title={howManyDrugs + " drugs"}
              provider="drugs"
              subheading={"are covered*"}
              bodyTitle={"In-Network Care"}
              description={`are covered in-network ${inNetworkCareTitleSuffix}`}
              buttonText={"See in-network drugs"}
              onButtonPress={() => changeSideBar("5")}
            />
          )}
          {howManyHospitals !== "hide" && (
            <OverviewCard
              title={howManyHospitals + " hospitals"}
              provider="hospitals"
              subheading={"are in-network*"}
              bodyTitle={"In-Network Care"}
              description={`are in-network ${inNetworkCareTitleSuffix}`}
              buttonText={"See in-network hospitals"}
              onButtonPress={() => changeSideBar("5")}
            />
          )}
          {showCustomOverviewTile && (
            <OverviewCard
              type="custom"
              title={heading}
              subheading={subheading}
              bodyTitle={miniTitle}
              description={body}
              showButton={showButton}
              buttonText={buttonText}
              getBody={getCustom}
              onButtonPress={() => (window.location = buttonLink)}
            />
          )}
        </FlexWrapper>
      )}
    </Wrapper>
  );
};

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

export default Overview;
