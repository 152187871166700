import React from "react";
import { Text } from "components";
import { Switch } from "antd";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  ${({ float }) => float && `float: left;`}
  align-items: center;
  margin-top: auto;
  margin-bottom: 20px;
  .ant-switch:focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgb(0, 165, 155);
    box-shadow: 0 0 0 2px rgb(0, 165, 155);
  }

  .ant-switch-checked.ant-switch-loading .ant-switch-loading-icon {
    color: #00a19b;
  }

  .ant-switch-checked {
    background-color: #00a19b;
  }
`;

const TextWrapper = styled.div`
  ${({ right }) =>
    right
      ? `text-align: right; padding-right: 10px;`
      : `text-align: left; padding-left: 10px;`}
  width: 100px;
  margin: auto;
`;

const Switcher = ({
  float,
  optionA,
  optionB,
  onChange,
  onClick,
  checked,
  loading,
  disabled,
}) => {
  return (
    <Wrapper float={float}>
      <TextWrapper right>
        <Text green={!checked} fontSize="16">
          {optionA}
        </Text>
      </TextWrapper>
      <Switch
        onChange={onChange}
        onClick={onClick}
        checked={checked}
        disabled={disabled}
        loading={loading}
      />
      <TextWrapper>
        <Text green={checked} fontSize="16">
          {optionB}
        </Text>
      </TextWrapper>
    </Wrapper>
  );
};

export default Switcher;
