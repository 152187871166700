import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import {
  TableRow,
  TableCell,
  Table,
  Text,
  Currency,
  Icon,
  Link,
  Tooltip,
} from "components";
import { ContentFormat } from "enums/alignments.enums";
import { isMobile } from "react-device-detect";

const Float = styled.span`
  margin-right: 28px;
`;

const Container = styled.div`
  position: relative;
  min-height: 150px;
  // width: 86%;
  align-self: center;
  display: flex;
  justify-content: center;
  border-spacing: 0;

  @media (max-width: 1740px) {
    max-width: 95%;
  }

  @media (min-width: 2000px) {
    max-width: 80%;
  }

  overflow-x: scroll;

  > * {
    text-overflow: ellipsis;
    display: block;
  }
`;

const GlobalStyle = createGlobalStyle`
 #tooltip-top {
  background-color: rgb(0, 161, 155);
    text-align: justify;
    padding: 12px;
  }

  .ant-tooltip-arrow {
    border-color:  rgb(0, 161, 155) !important;
  }
`;

const Header = ({ items }) => {
  const styles = (cursor, width) => {
    let style = { cursor, lineHeight: "26px" };
    if (width) style.width = `${width * 100}%`;
    return style;
  };

  const renderText = ({
    title,
    styles: { headerAlignment, width },
    description,
    cursor,
  }) => (
    <TableCell
      position={headerAlignment}
      key={title}
      style={styles(cursor, width)}
    >
      {title}
      {description && (
        <Text italic style={{ fontWeight: 400 }}>
          {description}
        </Text>
      )}
    </TableCell>
  );

  const renderItemHeader = (
    { order, title, legalPopupText, ...data },
    index
  ) => {
    if (legalPopupText)
      return (
        <Tooltip
          blue
          key={title}
          trigger="click"
          id="tooltip-top"
          overlayStyle={{
            minWidth: index > 1 ? 420 : 200,
            fontSize: 16,
          }}
          title={legalPopupText}
        >
          {renderText({ ...data, title, cursor: "pointer" })}
        </Tooltip>
      );
    return renderText({ ...data, title, cursor: "initial" });
  };

  return <TableRow header>{items.map(renderItemHeader)}</TableRow>;
};

const renderValue = (value, formatText, handleClick) => {
  const format = ContentFormat[formatText];

  if (isMobile && value && value.length > 15) {
    return (
      <Tooltip placement="topLeft" title={<Text>{value}</Text>}>
        {value}
      </Tooltip>
    );
  }

  if (format === ContentFormat.Currency && value)
    return (
      <Link
        to="/calculator"
        onClick={handleClick}
        green
        bold
        style={{ margin: "5px" }}
      >
        <Float>
          <Currency price={value} />
        </Float>
        <Icon calculator />
      </Link>
    );
  else if (format === ContentFormat.Currency && !value)
    return (
      <Link to="/calculator" withBG fontSize={14}>
        share your data
      </Link>
    );
  return value;
};

const DoctorsTable = ({ headers, data, columnsCount, handleClick }) => {
  const renderItem = ({ value, contentAlignment, contentFormat }, index) => {
    return (
      <TableCell short position={contentAlignment} key={index}>
        {renderValue(value, contentFormat, handleClick)}
      </TableCell>
    );
  };

  if (data.length === 0) {
    return (
      <Container>
        <Text>Data not available</Text>
      </Container>
    );
  }

  return (
    <Container>
      <GlobalStyle />
      <Table>
        <tbody>
          <Header items={headers} columnsCount={columnsCount} />
          {data.map((doctor, index) => (
            <TableRow
              color={index % 2 === 1 ? "white" : " #F2F2F2 "}
              key={index}
            >
              {doctor.map(renderItem)}
            </TableRow>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default DoctorsTable;
