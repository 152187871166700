import { createReducer } from "reduxsauce";
import Actions from "./actions";
import { merge } from "lodash";

const { Types } = Actions;
const INITIAL_STATE = {
  username: null,
  firstName: null,
  lastName: null,
  birthDate: null,
  zipCode: null,
  isActive: null,
  isBeta: false,
  isPremium: null,
  subscriptionTier: null,
  subscriptionValidUntil: null,
  subscriptionAutoRenew: null,
  subscriptionNextTier: null,
  subscriptionHistory: [],
  alternativeEmails: [],
  reports: [],
  icons: [],
  header: [],
  customs: [],
  currentReport: null,
  currentCustom: null,
  isRequesting: false,
  isLoaded: false,
  isError: false,
  currentDocument: {},
  groupsWithProfessionalAccess: [],
};

const success = (state = INITIAL_STATE, { user, report }) => {
  const currentReport = report ? report : state.currentReport;
  const currentCustom = user.currentCustom ? user.currentCustom : state.currentCustom;
  return {
    ...state,
    _id: user._id,
    username: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    birthDate: user.birthDate,
    zipCode: user.zipCode,
    isActive: user.isActive,
    isBeta: user.isBeta,
    isPremium: user.isPremium,
    alternativeEmails: user.alternativeEmails,
    subscriptionTier: user.subscription.tier,
    subscriptionValidUntil: user.subscription.validUntil,
    subscriptionAutoRenew: user.subscription.autoRenew,
    subscriptionNextTier: user.subscription.nextTier,
    subscriptionHistory: user.subscription.history,
    reports: user.reports,
    icons: user.icons,
    currentReport: currentReport,
    customs: user.customs,
    currentCustom: currentCustom,
    isRequesting: false,
    isLoaded: true,
    isError: false,
    groupsWithProfessionalAccess: user.groupsWithProfessionalAccess,
  };
};

const updateProfile = (state = INITIAL_STATE, { name, value }) => {
  return merge({}, state, {
    [name]: value,
  });
};

const request = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    isLoaded: false,
    isError: false,
  };
};

const failure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    username: null,
    isActive: null,
    reports: [],
    icons: [],
    currentReport: {},
    isRequesting: false,
    isLoaded: false,
    isError: true,
  };
};

const successHeader = (state = INITIAL_STATE, { reports }) => {
  const header = {
    ...reports,
  };
  return {
    ...state,
    header,
    isRequesting: false,
  };
};

const failureHeader = (state = INITIAL_STATE, { isAll }) => {
  if (isAll)
    return {
      ...state,
      header: {},
    };
  return merge({}, state, {
    header: {},
  });
};

const uploadPamphletsRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    isError: false,
  };
};

const uploadPamphletsSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,

    isRequesting: false,
    isError: false,
  };
};

const uploadPamphletsFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: false,
    isError: true,
  };
};

const requestLoadDocument = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
  };
};

const successLoadDocument = (state = INITIAL_STATE, { document }) => {
  return {
    ...state,
    currentDocument: document,
    isRequesting: false,
  };
};

const reset = (state = INITIAL_STATE) => {
  return INITIAL_STATE;
};

export const HANDLERS = {
  [Types.LOAD_PROFILE_SUCCESS]: success,
  [Types.LOAD_PROFILE_REQUEST]: request,
  [Types.LOAD_PROFILE_FAILURE]: failure,

  [Types.UPDATE_PROFILE]: updateProfile,

  [Types.LOAD_REPORTS_HEADER_REQUEST]: failureHeader,
  [Types.LOAD_REPORTS_HEADER_SUCCESS]: successHeader,
  [Types.LOAD_REPORTS_HEADER_FAILURE]: failureHeader,

  [Types.UPLOAD_PAMPHLETS_REQUEST]: uploadPamphletsRequest,
  [Types.UPLOAD_PAMPHLETS_SUCCESS]: uploadPamphletsSuccess,
  [Types.UPLOAD_PAMPHLETS_FAILURE]: uploadPamphletsFailure,

  [Types.LOAD_DOCUMENT_BY_ID_SUCCESS]: successLoadDocument,
  [Types.LOAD_DOCUMENT_BY_ID_REQUEST]: requestLoadDocument,

  [Types.RESET_STORE_PROFILE]: reset,
};

const reducer = createReducer(INITIAL_STATE, HANDLERS);

export default reducer;
