import React, { useState } from "react";
import { getFieldKey } from "./utils";
import { CompanyOptions, FieldOptions, PeriodOptions } from "./constants";

const getCompanyAlternativeName = (plans) => plans?.[0]?.group || null;

const getInitialContext = ({
  birthParent = {
    company: null,
    alternativeName: null,
  },
  partner = {
    company: null,
    alternativeName: null,
  },
}) => ({
  expand: {
    [getFieldKey({ field: FieldOptions.total })]: false,
    [getFieldKey({ field: FieldOptions.subtotal })]: false,
    [getFieldKey({ field: FieldOptions.Premiums })]: false,
    [getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })]: false,
    [getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })]: false,
    [getFieldKey({ field: FieldOptions.Surcharges })]: false,
    [getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.Before })]: false,
    [getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })]: false,
    [getFieldKey({ field: FieldOptions.Insurance })]: false,
    [getFieldKey({ field: FieldOptions.NotCovered })]: false,
    [getFieldKey({ field: FieldOptions.Insurance, companyType: CompanyOptions.companyA })]: false,
    [getFieldKey({ field: FieldOptions.Insurance, companyType: CompanyOptions.companyB })]: false,
    [getFieldKey({ field: FieldOptions.OutOfNetwork })]: false,
    [getFieldKey({ field: FieldOptions.ReimbursementFromInsurance })]: false,
    [getFieldKey({ field: FieldOptions.OutOfPocketAccrual })]: false,
    [getFieldKey({ field: FieldOptions.OptOutPayment })]: false,
    [getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before })]: false,
    [getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.After })]: false,
    [getFieldKey({ field: FieldOptions.HSA, period: PeriodOptions.Before })]: false,
    [getFieldKey({ field: FieldOptions.HSA, period: PeriodOptions.After })]: false,
    [getFieldKey({ field: FieldOptions.HRA, period: PeriodOptions.After })]: false,
    [getFieldKey({ field: FieldOptions.HRA, period: PeriodOptions.Before })]: false,
    [getFieldKey({ field: FieldOptions.leftover })]: false,
    [getFieldKey({ field: FieldOptions.HSAHRAImpact })]: false,
    [getFieldKey({ field: FieldOptions.EmployerHSAContribution })]: false,
    [getFieldKey({ field: FieldOptions.ReducedTaxBillFromHSAContribution })]: false,
    [getFieldKey({ field: FieldOptions.ReducedTaxBillOnHSAInvestmentGains })]: false,
    [getFieldKey({ field: FieldOptions.InvestmentGainsOnEmployerContribution })]: false,
    [getFieldKey({ field: FieldOptions.GainLossOnInvestmentNetOfTaxes })]: false,
  },
  showRows: {
    [getFieldKey({ field: FieldOptions.Premiums })]: false,
    [getFieldKey({ field: FieldOptions.Surcharges })]: false,
    [getFieldKey({ field: FieldOptions.Insurance })]: false,
    [getFieldKey({ field: FieldOptions.NotCovered })]: false,
    [getFieldKey({ field: FieldOptions.OutOfNetwork })]: false,
    [getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before })]: false,
    [getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.After })]: false,
    [getFieldKey({ field: FieldOptions.HSA, period: PeriodOptions.Before })]: false,
    [getFieldKey({ field: FieldOptions.HSA, period: PeriodOptions.After })]: false,
    [getFieldKey({ field: FieldOptions.HRA, period: PeriodOptions.Before })]: false,
    [getFieldKey({ field: FieldOptions.HRA, period: PeriodOptions.After })]: false,
    [getFieldKey({ field: FieldOptions.HSAHRAImpact })]: false,
  },
  showSubRows: {
    companyA: {
      name: birthParent.company,
      altName: birthParent.alternativeName,
      insuranceFields: {}, // Total, Copay, Coinsurance, Deductibles
      "Company Details A": null,
      "Company Details B": null,
      [getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })]: null,
      [getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })]: null,
      [getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.Before })]: null,
      [getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })]: null,
      [getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before })]: null,
      [getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.After })]: null,
      [getFieldKey({ field: FieldOptions.HSA, period: PeriodOptions.Before })]: null,
      [getFieldKey({ field: FieldOptions.HSA, period: PeriodOptions.After })]: null,
      [getFieldKey({ field: FieldOptions.HRA, period: PeriodOptions.Before })]: null,
      [getFieldKey({ field: FieldOptions.HRA, period: PeriodOptions.After })]: null,
    },
    companyB: {
      name: partner.company,
      altName: partner.alternativeName,
      insuranceFields: {},
      [getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })]: null,
      [getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })]: null,
      [getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.Before })]: null,
      [getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })]: null,
      [getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before })]: null,
      [getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.After })]: null,
      [getFieldKey({ field: FieldOptions.HSA, period: PeriodOptions.Before })]: null,
      [getFieldKey({ field: FieldOptions.HSA, period: PeriodOptions.After })]: null,
      [getFieldKey({ field: FieldOptions.HRA, period: PeriodOptions.Before })]: null,
      [getFieldKey({ field: FieldOptions.HRA, period: PeriodOptions.After })]: null,
    },
    "Out-Of-Network": {
      "Cost of Visits": null,
      "Reimbursement from Insurance": null,
      "Negotiated Rate": null,
      "Out-of-Pocket Accrual": null,
      Deductibles: null,
      Coinsurance: null,
    },
    "HSA/HRA Impact": {
      "Employer HSA Contribution": null,
      "Company A Employer HSA Contribution": null,
      "Company B Employer HSA Contribution": null,
      "Reduced Tax Bill From HSA Contribution": null,
      "Company A Reduced Tax Bill From HSA Contribution": null,
      "Company B Reduced Tax Bill From HSA Contribution": null,
      "Reduced Tax Bill on HSA Investment Gains": null,
      "Company A Reduced Tax Bill on HSA Investment Gains": null,
      "Company B Reduced Tax Bill on HSA Investment Gains": null,
      "Investment Gains on Employer Contribution": null,
      "Company A Investment Gains on Employer Contribution": null,
      "Company B Investment Gains on Employer Contribution": null,
    },
  },
});

const WhatIfStateContext = React.createContext(getInitialContext);

export const WhatIfStateProvider = ({ children, report, birthParentPlans, partnerPlans }) => {
  const { birthParent, partner } = report;

  const initialValues = getInitialContext({
    birthParent: {
      company: birthParent.company,
      alternativeName: getCompanyAlternativeName(birthParentPlans),
    },
    partner: {
      company: partner.company,
      alternativeName: getCompanyAlternativeName(partnerPlans),
    },
  });

  const [expand, setExpand] = useState(initialValues.expand);

  const [showRows, setShowRows] = useState(initialValues.showRows);

  const [showSubRows, setShowSubRows] = useState(initialValues.showSubRows);

  const { companyA, companyB } = showSubRows;

  const isSingleCompany = !companyA.name || !companyB.name;

  const handleExpand = (field) => {
    setExpand({ ...expand, [field]: !expand[field] });
  };

  const isExpanded = ({ field, companyType, period }) => {
    const expandKey = getFieldKey({ field, companyType, period });

    if (field === FieldOptions.Insurance && isSingleCompany) {
      return expand[getFieldKey({ field })];
    }

    return expand[expandKey];
  };

  const handleShow = (show, value) => {
    if (!showRows[show]) {
      setShowRows({ ...showRows, [show]: value ? value : !showRows[show] });
    }
  };

  const handleSubShow = (show, value, newFieldsA, newFieldsB) => {
    // show = companyA, companyB, or Out-Of-Network
    // value = showSubRows clone

    if (show === "companies") {
      const newCompanyA = newFieldsA ? Object.assign({}, showSubRows.companyA, newFieldsA) : showSubRows.companyA;
      const newCompanyB = newFieldsB ? Object.assign({}, showSubRows.companyB, newFieldsB) : showSubRows.companyB;
      setShowSubRows({
        ...showSubRows,
        companyA: newCompanyA,
        companyB: newCompanyB,
      });
    } else {
      setShowSubRows({ ...showSubRows, [show]: value });
    }
  };

  return (
    <WhatIfStateContext.Provider
      value={{
        expand,
        setExpand,
        handleExpand,
        isExpanded,
        showRows,
        handleShow,
        showSubRows,
        handleSubShow,
        isSingleCompany,
      }}
    >
      {children}
    </WhatIfStateContext.Provider>
  );
};
export const useWhatIfContext = () => {
  const context = React.useContext(WhatIfStateContext);
  if (context === undefined) {
    throw new Error("useWhatIfContext must be used within a WhatIfStateProvider");
  }
  return context;
};
