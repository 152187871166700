import { FieldOptions, PeriodOptions } from "./constants";
import { getFieldKey } from "./utils";

const initializeItem = (items, itemKey) => {
  items[itemKey] = items[itemKey] || {};
};

const initializeCost = (item, valueKey) => {
  item[valueKey] = item[valueKey] || [0, 0];
};

const incrementValueOfCost = (item, index, value) => {
  item[index] += value;
};

const initializeAndIncrementCost = (items, itemKey, valueKey, index, value) => {
  initializeItem(items, itemKey);
  initializeCost(items[itemKey], valueKey);
  incrementValueOfCost(items[itemKey][valueKey], index, value);
};

export const accumulateItems = ({
  items,
  source,
  index,
  field,
  period = PeriodOptions.Before,
  unExpectedCost,
  expectedCost,
}) => {
  const maxCost = [unExpectedCost, expectedCost]; // use for when hsa/hra exceeds the amount used

  source.forEach((item) => {
    if (field === FieldOptions["Premiums"]) {
      const { title, value } = item;
      const itemKey = getFieldKey({ field, period });
      const company = title.replace(" Plan", "");

      initializeAndIncrementCost(items, itemKey, "Total", index, value);
      initializeAndIncrementCost(items, itemKey, company, index, value);
      initializeItem(items[itemKey], "PremiumSubRows");
      initializeItem(items[itemKey].PremiumSubRows, company);
      items[itemKey].PremiumSubRows[company].SpousalSurcharges = item.spousalSurcharge;
      items[itemKey].PremiumSubRows[company].Premium = items[itemKey][company]
        ? items[itemKey][company][index] - item.spousalSurcharge
        : 0;
    } else if (field === FieldOptions["Surcharges"]) {
      const { title, value } = item;
      const itemKey = getFieldKey({ field, period });
      const company = title.replace(" Plan", "");
      initializeAndIncrementCost(items, itemKey, "Total", index, value);
      initializeAndIncrementCost(items, itemKey, company, index, value);
    } else if (field === FieldOptions["Insurance"]) {
      const { company, value, deductible, coinsurance, copay } = item || {};
      initializeAndIncrementCost(items, company, "Total", index, value);
      initializeAndIncrementCost(items, company, "Deductible", index, deductible || 0);
      initializeAndIncrementCost(items, company, "Copay", index, copay || 0);
      initializeAndIncrementCost(items, company, "Coinsurance", index, coinsurance || 0);
    } else if (field === FieldOptions["OutOfNetwork"]) {
      const {
        costOfVisits,
        totalReimbursementFromInsurance,
        negotiatedRate,
        totalOOPAccrual,
        deductible,
        coinsurance,
      } = item || {};

      initializeCost(items, "Cost of Visits");
      initializeCost(items, "Reimbursement from Insurance");
      initializeCost(items, "Negotiated Rate");
      initializeCost(items, "Out-of-Pocket Accrual");
      initializeCost(items, "Deductibles");
      initializeCost(items, "Coinsurance");

      incrementValueOfCost(items["Cost of Visits"], index, costOfVisits || 0);
      incrementValueOfCost(items["Reimbursement from Insurance"], index, totalReimbursementFromInsurance || 0);
      incrementValueOfCost(items["Negotiated Rate"], index, negotiatedRate || 0);
      incrementValueOfCost(items["Out-of-Pocket Accrual"], index, totalOOPAccrual || 0);
      incrementValueOfCost(items["Deductibles"], index, deductible || 0);
      incrementValueOfCost(items["Coinsurance"], index, coinsurance || 0);
    } else if (field === FieldOptions["OptOutPayment"]) {
      const { title, value } = item;
      const itemKey = getFieldKey({ field, period });
      const company = title.replace(" Plan", "");

      initializeAndIncrementCost(items, itemKey, "Total", index, value);
      initializeAndIncrementCost(items, itemKey, company, index, value);
    } else if (field === FieldOptions["NotCovered"]) {
      const { company, notCovered } = item || {};
      const itemKey = getFieldKey({ field });

      initializeAndIncrementCost(items, itemKey, company, index, notCovered);
    } else if (field === FieldOptions["HSA"] || field === FieldOptions["HRA"]) {
      const { title, value } = item;

      const company = title.replace(" Contributions", "");
      items[company] = items[company] || [];
      items[company][index] = (items[company][index] || 0) + value;

      items[company][index] = Math.min(maxCost[index], items[company][index]);
    } else if (field === FieldOptions["HSAHRAImpact"]) {
      let { title, value } = item || {};
      const generalTitle = title.replace(/Company (A|B) /gm, "");

      initializeCost(items, generalTitle);
      incrementValueOfCost(items[generalTitle], index, value);

      initializeCost(items, title);
      incrementValueOfCost(items[title], index, value);
    }

    return;
  });
};
