import configure from "./configure";
import stringify from "./query";

const { setHeadersWithAuth, axios, standardHeaders } = configure;

export const createCustom = async form => {
  const body = { form };
  return axios.post("reports/create-custom", body, standardHeaders).then(
    res => {
      return res;
    },
    error => {
      return error;
    }
  );
};

export const getCustom = async (customId, token, userId) => {
  const headers = setHeadersWithAuth(token);
  const query = stringify({ customId, userId });
  return axios.get(`reports/get-custom${query}`, headers).then(
    res => {
      return res;
    },
    error => {
      return error;
    }
  );
};

export const updateCustom = async (customId, form) => {
  const body = { customId, form };
  return axios.post("reports/update-custom", body, standardHeaders).then(
    res => {
      return res;
    },
    error => {
      return error;
    }
  );
};

export const updateCustomPlan = async (plan, token) => {
  const headers = setHeadersWithAuth(token);
  const body = { plan };
  return axios.post("custom-plans/update-plan", body, headers).then(
    res => {
      return res;
    },
    error => {
      return error;
    }
  );
};
