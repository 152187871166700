import React from "react";
import styled from "styled-components";
import { Text } from "components";

const InnerSecond = styled.div`
  display: grid;
  align-items: end;
  width: 100%;
  padding: 0 25px;
  grid-template-columns: 20% 12% 23% 10% 12% 12% 11%;
  border-bottom: solid #e4e4e46b 2px;
`;

const StyledText = styled(Text)`
  font-size: 14px;
  color: #5A7184;
  font-weight: bold;
  ${({ center }) =>  `text-align: ${center ? 'center': 'left'};`}
`;

const BillText = ({ width, grid, children, ...props }) => (
  <div>
    <StyledText circular padding={'0 8px 0px 0px'} {...props}>{children}</StyledText>
  </div>
);

const BillContentHeader = () => {
  return (
    <InnerSecond>
      <BillText >{"Provider"}</BillText>
      <BillText center>{`In\u2011Network?`}</BillText>
      <BillText>{"Service Description"}</BillText>
      <BillText center>{"Service Code"}</BillText>
      <BillText center>{"Doctor Charges"}</BillText>
      <BillText center>{"Insurance Rates"}</BillText>
      <BillText center>{"Not Covered"}</BillText>
    </InnerSecond>
 );
};

export default BillContentHeader;
