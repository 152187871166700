import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PrivateRoute from "./PrivateRoute";

import {
  CalculatePage,
  PremiumPage,
  AuthPage,
  RegisterPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  CheckoutPage,
  OnboardingPageV2,
  AccountPage,
  AdminPage,
  AdminUsersPage,
  KnownOptPage,
  OptimizeUserPage,
  OptimizeUserPageV2,
  RecommendationsPage,
  CreatePlanPage,
  SignupTypeFormPage,
  AddServicesPage,
  GroupsListPage,
  GroupVerificationPage,
  AddEmployerPage,
  AddPremiumsPage,
  CompleteGroupOnboardingPage,
  AddNetworksPage,
  NetworksGridPage,
  MyPlansPage,
  MyPlansDetailsPage,
  PricingPage,
} from "components";
import TopDoctorsPage from "containers/TopDoctorsPage.container";
import AuthRoute from "./AuthRoute";
import authActions from "../store/auth/actions";
import { initializeReactGA } from "services/google";
import { initializeReactGTM } from "services/google-tags";
import PlanInfoTypeformPage from "components/pages/PlanInfoTypeformPage";
import ReportInfoTypeformPage from "components/pages/ReportInfoTypeformPage";
import NewRecommendationTypeformPage from "components/pages/NewRecommendationTypeformPage";

const { Creators } = authActions;
class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    const { isAuth, load } = this.props;
    if (!isAuth) {
      load();
      setTimeout(() => {
        this.setState({ isLoading: false });
      }, 0);
    } else {
      this.setState({ isLoading: false });
    }
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      initializeReactGA();
      initializeReactGTM();
    }
  }

  render() {
    const { isLoading } = this.state;
    if (isLoading) return <div></div>;
    return (
      <>
        <Switch>
          <Route exact path="/have-a-baby" component={TopDoctorsPage} />
          <PrivateRoute path="/admin" component={AdminPage} isAuth={this.props.isAuth} />
          <PrivateRoute path="/admin-users" component={AdminUsersPage} isAuth={this.props.isAuth} />
          <PrivateRoute exact path="/known" component={KnownOptPage} isAuth={this.props.isAuth} />
          <PrivateRoute path="/optimize/:reportId" component={OptimizeUserPage} isAuth={this.props.isAuth} />
          <PrivateRoute path="/optimizeV2/:reportId" component={OptimizeUserPageV2} isAuth={this.props.isAuth} />
          <PrivateRoute path="/networks/:id" component={NetworksGridPage} isAuth={true} />
          <PrivateRoute path="/my-plans/:name/:id" component={MyPlansDetailsPage} isAuth={this.props.isAuth} />
          <PrivateRoute path="/my-plans/:profileId" component={MyPlansPage} isAuth={this.props.isAuth} />
          <PrivateRoute path="/my-plans" component={MyPlansPage} isAuth={this.props.isAuth} />
          <PrivateRoute
            path="/recommendations/:profileId?"
            component={RecommendationsPage}
            isAuth={this.props.isAuth}
          />
          <PrivateRoute path="/account/:navigation?" component={AccountPage} isAuth={this.props.isAuth} />
          <PrivateRoute exact path="/checkout" component={CheckoutPage} isAuth={this.props.isAuth} />
          <PrivateRoute exact path="/onboarding/:new?" component={OnboardingPageV2} isAuth={this.props.isAuth} />
          <PrivateRoute exact path="/onboarding" component={OnboardingPageV2} isAuth={this.props.isAuth} />
          <PrivateRoute exact path="/premium" component={PremiumPage} isAuth={this.props.isAuth} />
          <Route exact path="/calculator" component={CalculatePage} />
          <AuthRoute exact path="/signin" component={(props) => <AuthPage {...props} />} isAuth={this.props.isAuth} />
          <AuthRoute
            exact
            path="/signup/:email"
            component={(props) => <RegisterPage {...props} />}
            isAuth={this.props.isAuth}
          />
          <AuthRoute exact path="/forgot-password" component={ForgotPasswordPage} isAuth={this.props.isAuth} />
          <AuthRoute exact path="/reset-password/:token" component={ResetPasswordPage} isAuth={this.props.isAuth} />
          <AuthRoute exact path="/create-password/:token" component={ResetPasswordPage} isAuth={this.props.isAuth} />
          <PrivateRoute exact path="/create-plan" component={CreatePlanPage} isAuth={true} />
          <PrivateRoute exact path="/edit-plan" component={CreatePlanPage} isAuth={true} />
          <PrivateRoute exact path="/add-services" component={AddServicesPage} isAuth={this.props.isAuth} />
          <PrivateRoute exact path="/add-networks" component={AddNetworksPage} isAuth={true} />
          <PrivateRoute exact path="/edit-networks" component={AddNetworksPage} isAuth={true} />
          <PrivateRoute exact path="/typeform/signup" component={SignupTypeFormPage} isAuth={true} />
          <PrivateRoute exact path="/typeform/plan-info" component={PlanInfoTypeformPage} isAuth={true} />
          <PrivateRoute exact path="/typeform/report-info" component={ReportInfoTypeformPage} isAuth={true} />
          <PrivateRoute
            exact
            path="/typeform/new-recommendation"
            component={NewRecommendationTypeformPage}
            isAuth={true}
          />
          <PrivateRoute exact path="/groups" component={GroupsListPage} isAuth={true} />
          <PrivateRoute exact path="/groups/verification" component={GroupVerificationPage} isAuth={true} />
          <PrivateRoute
            exact
            path="/groups/verification&group=:group"
            component={GroupVerificationPage}
            isAuth={true}
          />
          <PrivateRoute
            exact
            path="/groups/complete-onboarding"
            component={CompleteGroupOnboardingPage}
            isAuth={true}
          />
          <PrivateRoute exact path="/groups/add-employer" component={AddEmployerPage} isAuth={true} />
          <PrivateRoute exact path="/add-premiums" component={AddPremiumsPage} isAuth={true} />
          <PrivateRoute exact path="/edit-premiums" component={AddPremiumsPage} isAuth={true} />
          <Route path="/quinnipiac" component={() => <Redirect to="/groups/verification?group=quinnipiac" />} />
          <Route path="/pricing" component={PricingPage} />
          <Redirect to="/recommendations" />
        </Switch>
      </>
    );
  }
}

Routes.propTypes = {
  load: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.isAuth,
    isFirstVisit: state.auth.isFirstVisit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: () => {
      dispatch(Creators.loadRequest());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
