import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";

import {
  FormText,
  CalculateFormTemplate,
  FormSelect as Select,
  FormDate as Date,
  FormInput as Input,
  FormCheckbox as Checkbox,
  Text,
} from "components";
import { strToBoolTrue, strToBoolFalse } from "services/utils";

const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 5px;
`;

// const FlexStartWrapper = styled.section`
//   display: flex;
//   justify-content: space-between;
//   align-items: flex-start;
//   padding: 10px 0;

//   @media (max-width: 480px) {
//     display: block;
//   }
// `;

// const Divider = styled.div`
//   border-top: 1px solid grey;
//   margin: 10px 0;
// `;

const InnerWrapper = styled.section`
  width: 100%;
  padding: 0 5px;
`;

const CalculatePremiumPlan = ({ change, changePremium, ...props }) => {
  // const [state, setState] = useState(true);
  const [birthParent, setBirthParent] = useState("Birth Parent");
  const [partner, setPartner] = useState("Non-Birth Parent");
  // const [additionalChildrenPre, setAdditionalChildrenPre] = useState("");
  // const [additionalChildrenPost, setAdditionalChildrenPost] = useState("");

  // const handleChangePremium = ({ target: { name, value } }) => {
  //   if (name === "isDue" && value === "No") {
  //     changePremium(
  //       "dueDate",
  //       moment()
  //         .add(9, "month")
  //         .format("MM-DD-YYYY")
  //     );
  //   }

  //   if (name === "preBirthPlan") {
  //     if (value === birthParent) {
  //       changePremium(name, {
  //         value: "birthParent",
  //         birthParent: "MS",
  //         partner: null
  //       });
  //     } else if (value === partner) {
  //       changePremium(name, {
  //         value: "partner",
  //         birthParent: null,
  //         partner: "MS"
  //       });
  //     } else {
  //       changePremium(name, {
  //         value: "Separate plans",
  //         birthParent: "MO",
  //         partner: "MO"
  //       });
  //     }
  //   } else if (name === "postBirthPlan") {
  //     if (value === birthParent) {
  //       changePremium(name, {
  //         value: "birthParent",
  //         birthParent: "MF",
  //         partner: null
  //       });
  //     } else if (value === partner) {
  //       changePremium(name, {
  //         value: "partner",
  //         birthParent: null,
  //         partner: "MF"
  //       });
  //     } else {
  //       changePremium(name, {
  //         value: "Separate plans",
  //         birthParent: "MO",
  //         partner: "MO"
  //       });
  //     }
  //   } else {
  //     changePremium(name, value);
  //   }
  // };

  // const handleChangeAdditionalChildrenPre = ({ target: { name, value } }) => {
  //   if (value === birthParent) {
  //     setAdditionalChildrenPre("birthParent");
  //     changePremium(name, {
  //       value: "Separate plans",
  //       birthParent: "MC",
  //       partner: "MO"
  //     });
  //   } else if (value === partner) {
  //     setAdditionalChildrenPre("partner");
  //     changePremium(name, {
  //       value: "Separate plans",
  //       birthParent: "MO",
  //       partner: "MC"
  //     });
  //   }
  // };

  // const handleChangeAdditionalChildrenPost = ({ target: { name, value } }) => {
  //   if (value === birthParent) {
  //     setAdditionalChildrenPost("birthParent");
  //     changePremium(name, {
  //       value: "Separate plans",
  //       birthParent: "MC",
  //       partner: "MO"
  //     });
  //   } else if (value === partner) {
  //     setAdditionalChildrenPost("partner");
  //     changePremium(name, {
  //       value: "Separate plans",
  //       birthParent: "MO",
  //       partner: "MC"
  //     });
  //   }
  // };

  const handleChangePremium = ({ target: { name, value } }) => {
    if (name === "isDue" && value === "No") {
      changePremium("dueDate", moment().add(9, "month").format("MM/DD/YYYY"));
    }

    if (name === "parentsPlan" || name === "familyPlan") {
      if (value === birthParent) {
        changePremium(name, "birthParent");
      } else if (value === partner) {
        changePremium(name, "partner");
      } else {
        changePremium(name, "Separate plans");
      }
    } else changePremium(name, value);
  };

  const handleChangeNames = ({ target: { name, value } }) => {
    name === "birthParent" ? setBirthParent(value) : setPartner(value);
    changePremium(name, value);
  };

  const handleChangeBool = ({ target: { name, value } }) => {
    if (strToBoolTrue(value)) changePremium(name, true);
    else if (strToBoolFalse(value)) changePremium(name, false);
  };

  // const RenderIsFirst = () => (
  //   <>
  //     {props.dueDate.value && (
  //       <Select
  //         text="First Baby?"
  //         isBool
  //         name="firstBaby"
  //         handleChange={handleChangeBool}
  //         value={props.insurance.firstBaby}
  //         selection={["No", "Yes"]}
  //       />
  //     )}
  //     {(props.insurance.firstBaby === true ||
  //       props.insurance.firstBaby === false) && (
  //       <Select
  //         text={"Plan(s) " + birthParent + " and " + partner + " are on"}
  //         selection={[...props.preBirthPlan.data, birthParent, partner]}
  //         title="'s plan"
  //         name="preBirthPlan"
  //         handleChange={handleChangePremium}
  //         value={
  //           props.preBirthPlan.value.value === "birthParent"
  //             ? birthParent
  //             : props.preBirthPlan.value.value === "partner"
  //             ? partner
  //             : props.preBirthPlan.value.value
  //         }
  //       />
  //     )}
  //     {!props.insurance.firstBaby &&
  //       props.preBirthPlan.value.value === "Separate plans" && (
  //         <Select
  //           text="Whose plan are the kid(s) on?"
  //           selection={[birthParent, partner]}
  //           name="preBirthPlan"
  //           handleChange={handleChangeAdditionalChildrenPre}
  //           value={
  //             additionalChildrenPre === "birthParent"
  //               ? birthParent
  //               : additionalChildrenPre === "partner"
  //               ? partner
  //               : ""
  //           }
  //         />
  //       )}
  //     {(props.insurance.firstBaby === true ||
  //       props.insurance.firstBaby === false) &&
  //       props.preBirthPlan.value.value && (
  //         <Select
  //           text={
  //             "Plan(s) " +
  //             birthParent +
  //             " and " +
  //             partner +
  //             " Will Join After Baby is Born"
  //           }
  //           selection={[...props.postBirthPlan.data, birthParent, partner]}
  //           title="'s plan"
  //           name="postBirthPlan"
  //           handleChange={handleChangePremium}
  //           value={
  //             props.postBirthPlan.value.value === "birthParent"
  //               ? birthParent
  //               : props.postBirthPlan.value.value === "partner"
  //               ? partner
  //               : props.postBirthPlan.value.value
  //           }
  //         />
  //       )}
  //     {!props.insurance.firstBaby &&
  //       props.postBirthPlan.value.value === "Separate plans" && (
  //         <Select
  //           text="Whose plan are the kid(s) on?"
  //           selection={[birthParent, partner]}
  //           name="postBirthPlan"
  //           handleChange={handleChangeAdditionalChildrenPost}
  //           value={
  //             additionalChildrenPost === "birthParent"
  //               ? birthParent
  //               : additionalChildrenPost === "partner"
  //               ? partner
  //               : ""
  //           }
  //         />
  //       )}
  //   </>
  // );

  const RenderIsFirst = () => (
    <>
      {props.dueDate.value && (
        <Select
          text="First Baby?"
          isBool
          name="firstBaby"
          handleChange={handleChangeBool}
          value={props.insurance.firstBaby}
          selection={["No", "Yes"]}
        />
      )}
      {typeof props.insurance.firstBaby === "boolean" && (
        <Select
          text={"Plan(s) " + birthParent + " and " + partner + " will join"}
          selection={[...props.parentsPlan.data, birthParent, partner]}
          title="'s plan"
          name="parentsPlan"
          handleChange={handleChangePremium}
          value={
            props.parentsPlan.value === "birthParent"
              ? birthParent
              : props.parentsPlan.value === "partner"
              ? partner
              : props.parentsPlan.value
          }
        />
      )}
      {props.parentsPlan.value === "Separate plans" && (
        <Select
          text={
            props.insurance.firstBaby
              ? "Plan baby will join"
              : "Plan children will join"
          }
          selection={[birthParent, partner]}
          name="familyPlan"
          handleChange={handleChangePremium}
          value={
            props.familyPlan.value === "birthParent"
              ? birthParent
              : props.familyPlan.value === "partner"
              ? partner
              : ""
          }
        />
      )}
    </>
  );

  const RenderInsurances = () => {
    return (
      <>
        {(props.parentsPlan.value === "Separate plans" ||
          props.parentsPlan.value === "birthParent") && (
          <InnerWrapper>
            <FormText noMargin={true} text={birthParent + "'s Plan:"} />
            <Select
              text="Company"
              // selection={["No company insurance", ...props.premiumGroups.data]}
              selection={[
                "No company insurance",
                props.currentReport.birthParent.company,
                "Marketplace New York",
              ]}
              name="groups"
              isSearch
              handleChange={handleChangePremium}
              value={props.groups.value}
            />
            {props.plans.isVisible &&
              props.groups.value !== "No company insurance" && (
                <Select
                  text="Plan"
                  isSearch
                  selection={props.plans.data}
                  name="insurancePlans"
                  handleChange={handleChangePremium}
                  value={props.plans.value}
                />
              )}
            {props.plansNetwork.isVisible &&
              props.plans.value &&
              props.groups.value !== "No company insurance" && (
                <>
                  <Select
                    text="Network"
                    selection={props.plansNetwork.data}
                    selectObj="_id"
                    select="network"
                    name="plansNetwork"
                    handleChange={handleChangePremium}
                    value={props.plansNetwork.value}
                  />
                  {props.tiers.isVisible && (
                    <Select
                      text="Select the option that applies"
                      selection={props.tiers.data}
                      selectObj="tier"
                      select="value"
                      name="tiers"
                      handleChange={handleChangePremium}
                      value={props.tiers.value}
                    />
                  )}
                  {props.hsa.isVisible && (
                    <Select
                      text="Select the option that applies"
                      selection={props.hsa.data}
                      selectObj="tier"
                      select="value"
                      name="hsa"
                      handleChange={handleChangePremium}
                      value={props.hsa.value}
                    />
                  )}
                  {props.surcharges.isVisible && (
                    <Checkbox
                      text="Select all that applies:"
                      options={props.surcharges.data}
                      select="surcharge"
                      handleChange={handleChangePremium}
                      value={props.surcharges.value}
                      name="surcharges"
                    />
                  )}
                </>
              )}
          </InnerWrapper>
        )}

        {(props.parentsPlan.value === "Separate plans" ||
          props.parentsPlan.value === "partner") && (
          <InnerWrapper>
            <FormText noMargin={true} text={partner + "'s Plan:"} />
            <Select
              text="Company"
              // selection={[
              //   "No company insurance",
              //   ...props.partnerPremiumGroups.data
              // ]}
              selection={[
                "No company insurance",
                props.currentReport.partner.company,
                "Marketplace New York",
              ]}
              name="partnerGroups"
              handleChange={handleChangePremium}
              value={props.partnerGroups.value}
            />
            {props.partnerInsurancePlans.isVisible &&
              props.partnerGroups.value !== "No company insurance" && (
                <Select
                  text="Plan"
                  selection={props.partnerInsurancePlans.data}
                  name="partnerInsurancePlans"
                  handleChange={handleChangePremium}
                  value={props.partnerInsurancePlans.value}
                />
              )}
            {props.partnerPlansNetwork.isVisible &&
              props.partnerInsurancePlans.value &&
              props.partnerGroups.value !== "No company insurance" && (
                <>
                  <Select
                    text="Network"
                    selection={props.partnerPlansNetwork.data}
                    selectObj="_id"
                    select="network"
                    name="partnerPlansNetwork"
                    handleChange={handleChangePremium}
                    value={props.partnerPlansNetwork.value}
                  />
                  {props.partnerTiers.isVisible && (
                    <Select
                      text="Select the option that applies"
                      handleChange={handleChangePremium}
                      selection={props.partnerTiers.data}
                      selectObj="tier"
                      select="value"
                      name="partnerTiers"
                      value={props.partnerTiers.value}
                    />
                  )}
                  {props.partnerHsa.isVisible && (
                    <Select
                      text="Select the option that applies"
                      selection={props.partnerHsa.data}
                      selectObj="tier"
                      select="value"
                      name="partnerHsa"
                      handleChange={handleChangePremium}
                      value={props.partnerHsa.value}
                    />
                  )}
                  {props.partnerSurcharges.isVisible && (
                    <Checkbox
                      text="Check all options that applies"
                      options={props.partnerSurcharges.data}
                      select="surcharge"
                      handleChange={handleChangePremium}
                      value={props.partnerSurcharges.value}
                      name="partnerSurcharges"
                    />
                  )}
                </>
              )}
          </InnerWrapper>
        )}
      </>
    );
  };

  return (
    <>
      <CalculateFormTemplate
        title="Insurance Plan Options"
        activeKey={1}
        collapsible={true}
        size="big"
      >
        <>
        <Wrapper>
          <InnerWrapper>
            <Select
              text="Do you have a due date yet?"
              name="isDue"
              handleChange={handleChangePremium}
              value={props.isDue.value}
              selection={props.isDue.data}
            />
          </InnerWrapper>
          <InnerWrapper>
            {props.isDue.value &&
              (props.isDue.value === "Yes" ? (
                <Date
                  text="When is your due date?"
                  placeholder="MM-DD-YYYY"
                  name="dueDate"
                  handleChange={handleChangePremium}
                  value={props.dueDate.value}
                />
              ) : (
                <Text green={true} select>
                  Let's assume that it will be in 9 months
                </Text>
              ))}
          </InnerWrapper>
        </Wrapper>

        <FormText noMargin={true} text="What should we call everyone?" />
        <Input
          placeholder="Birth Parent"
          handleChange={handleChangeNames}
          name="birthParent"
          value={birthParent}
          addonBefore="Birth Parent"
        />
        <Input
          placeholder="Birth Parent's Partner"
          handleChange={handleChangeNames}
          name="partner"
          value={partner}
          addonBefore="Non-Birth Parent"
        />

        <RenderIsFirst />
        <RenderInsurances />
        </>
      </CalculateFormTemplate>
    </>
  );
};

// export default React.memo(CalculatePremiumPlan);
export default CalculatePremiumPlan;
