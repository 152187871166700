import React from "react";
import { getUnconfirmedReports } from "services/api/admin.api";
import { getToken } from "services/utils";

import { Form, Space, Button, message, Card } from "antd";

const { Item } = Form;

const UnconfirmedReportSearch = ({ disable, handleChange }) => {
  const [form] = Form.useForm();

  const onSubmit = async ({ id }) => {
    const token = await getToken();
    const response = await getUnconfirmedReports(token);

    const { status, data } = response;
    if (status === 200) {
      handleChange({ reports: data["reports"] });
    } else {
      message.error("Reports not found");
    }
  };

  return (
    <Card>
      <Form form={form} layout="inline" onFinish={onSubmit}>
        <Space>
          <Item>
            <Button disabled={disable} type="primary" htmlType="submit">
              Search for Unconfirmed Report Ids
            </Button>
          </Item>
        </Space>
      </Form>
    </Card>
  );
};

export default UnconfirmedReportSearch;
