import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Icon } from "@ant-design/compatible";
import "antd/dist/antd.css";
import { Carousel } from "antd";
import "antd/dist/antd.css";

import { CalculateHeader as Header, Text, UploadPamphlets } from "components";

const Wrapper = styled.section`
  .ant-carousel .slick-dots {
    width: 150px;
    justify-content: space-evenly;
    margin: 0 auto;
    z-index: -1;
  }

  .ant-carousel .slick-dots li button {
    width: 50px;
    height: 10px;
    background: #00a19b;
    cursor: pointer;
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: #00a19b;
  }

  .ant-carousel .slick-slide {
    padding: 50px 10px;
    text-align: center;
    height: 100%;
  }

  width: 100vw;
  padding: 10px;
`;

const InnerWrapper = styled.div``;

const FlexWrapper = styled.div`
  display: flex;
  ${({ row }) =>
    row
      ? `flex-direction: row; 
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 5px 10px;
        `
      : `flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        `}
  ${({ center }) => center && `align-items: center`}
`;

const ButtonWrapper = styled.button`
  border: 3px solid #00a19b;
  border-radius: 25px;
  margin: 15px 12px;
  width: 450px;
  height: 100px;
  outline: none;
  cursor: pointer;
  color: #00a19b;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  :hover {
    opacity: 0.5;
  }
  :focus {
    color: orange;
    border: 5px solid orange;
  }

  @media (max-width: 1200px) {
    width: 100%;
    margin: 10px auto;
  }
`;

const iconRightStyles = {
  fontSize: "20px",
  fontWeight: "bold",
};

const PamphletsMain = ({ plan, handleRedirect, report, id }) => {
  const ref = useRef();
  const [birthParentName, setBirthParentName] = useState(null);
  const [partnerName, setPartnerName] = useState(null);
  const [names, setNames] = useState([]);
  const [active, setActive] = useState(false);
  const [slide, setSlide] = useState(0);
  useEffect(() => {
    const {
      considerEmploymentPlan,
      considerCobraPlan,
    } = report.event.employment;
    const { considerSpousePlan } = report.event.marriage;
    const { considerCommercialPlan } = report.event.general;
    const { considerParentPlan } = report.event.age;

    if (
      !considerEmploymentPlan &&
      !considerCobraPlan &&
      !considerCommercialPlan &&
      !considerSpousePlan &&
      !considerParentPlan
    ) {
      setActive(2);
    }
    if (report.birthParent.name || report.partner.name) {
      setBirthParentName(report.birthParent.name);
      setPartnerName(report.partner.name);
    } else if (
      report.isDue &&
      (considerEmploymentPlan ||
        considerCobraPlan ||
        considerCommercialPlan ||
        considerParentPlan) &&
      considerSpousePlan
    ) {
      setBirthParentName("Birth Parent");
      setPartnerName("Non-Birth Parent");
    }
    const array = [];
    (considerEmploymentPlan || considerCommercialPlan) && array.push("Me");
    considerSpousePlan && array.push("Spouse/Partner");
    considerCobraPlan && array.push("COBRA Plan");
    considerParentPlan && array.push("Parent's Plan");
    setNames(array);
  }, [
    report.partner.name,
    report.birthParent.name,
    report.isDue,
    report.event.employment,
    report.event.marriage,
    report.event.age,
    report.event.general,
  ]);

  const afterChange = (current, next) => {
    setSlide(current);
  };

  const handleActive = () => {
    setActive(1);
  };

  const handleHelp = () => {
    window.open("https://calendly.com/predictabill/help", "_blank");
  };

  const handleNext = () => {
    if (!partnerName && names.length <= 1) {
      handleRedirect();
    } else ref.current.next();
  };

  const RenderDefault = () => {
    return (
      <FlexWrapper center={true}>
        <Header bigTitle={true} title="Let's get your benefit information" />
        <InnerWrapper>
          <ButtonWrapper>
            <FlexWrapper row onClick={handleActive}>
              <FlexWrapper>
                <Text select bolder left>
                  Upload Your Pamphlet
                </Text>
                <Text select left style={{ maxWidth: "350px" }}>
                  If you have it handy, this is an easy option.
                </Text>
              </FlexWrapper>
              <Icon type="right" style={iconRightStyles} />
            </FlexWrapper>
          </ButtonWrapper>
          <ButtonWrapper>
            <FlexWrapper row onClick={handleRedirect}>
              <FlexWrapper>
                <Text select bolder left>
                  Send Your Pamphlet To Us Later
                </Text>
                <Text select left style={{ maxWidth: "350px" }}>
                  We’ll email you to get your pamphlet after checkout.
                </Text>
              </FlexWrapper>
              <Icon type="right" style={iconRightStyles} />
            </FlexWrapper>
          </ButtonWrapper>
          <ButtonWrapper>
            <FlexWrapper row onClick={handleHelp}>
              <FlexWrapper>
                <Text select bolder left>
                  Ask For Help
                </Text>
                <Text select left style={{ maxWidth: "350px" }}>
                  Sign up for a call where we can assist you.
                </Text>
              </FlexWrapper>
              <Icon type="right" style={iconRightStyles} />
            </FlexWrapper>
          </ButtonWrapper>
        </InnerWrapper>
      </FlexWrapper>
    );
  };

  const RenderAlternative = () => {
    return (
      <FlexWrapper center={true}>
        <Header bigTitle={true} title="Let's get your benefit information" />
        <InnerWrapper>
          <ButtonWrapper>
            <FlexWrapper row onClick={handleRedirect}>
              <FlexWrapper>
                <Text select bolder left>
                  We Already Have All The Information To Give You Advice
                </Text>
                <Text select left style={{ maxWidth: "350px" }}>
                  Return to your profile.
                </Text>
              </FlexWrapper>
              <Icon type="right" style={iconRightStyles} />
            </FlexWrapper>
          </ButtonWrapper>
        </InnerWrapper>
      </FlexWrapper>
    );
  };

  return (
    <Wrapper slide={slide}>
      {active === 1 ? (
        <Carousel afterChange={afterChange} dotPosition="top" ref={ref}>
          <InnerWrapper>
            <UploadPamphlets
              plan={plan}
              report={report}
              id={id}
              names={names}
              parent={report.birthParent}
              otherParent={report.partner}
              name={birthParentName}
              setName={setBirthParentName}
              otherName={partnerName}
              submit="Continue"
              handleNext={handleNext}
              handleRedirect={handleRedirect}
            />
          </InnerWrapper>

          {(partnerName || names.length > 1) && (
            <InnerWrapper>
              <UploadPamphlets
                plan={plan}
                report={report}
                id={id}
                parent={report.partner}
                otherParent={report.birthParent}
                names={names}
                name={partnerName}
                setName={setPartnerName}
                otherName={birthParentName}
                submit="Continue"
                handleNext={handleRedirect}
                handleRedirect={handleRedirect}
              />
            </InnerWrapper>
          )}
        </Carousel>
      ) : active === 2 ? (
        <InnerWrapper>
          <RenderAlternative />
        </InnerWrapper>
      ) : (
        <InnerWrapper>
          <RenderDefault />
        </InnerWrapper>
      )}
    </Wrapper>
  );
};

export default PamphletsMain;
