import React from "react";
import styled from "styled-components";
import {
  BillContentHeader as Header,
  BillContentFooter as Footer,
  BillContentMain as Main,
  Spinner,
} from "components";

const InnerWrapper = styled.div`
  max-height: calc(100% - 190px);
  width: 1000px;
  overflow-y: scroll;

  @media print {
    max-height: fit-content;
    overflow: none;
  }
`;
const SpinnerWraper = styled.div`
  height: 200px;
  width: 500px;
`

const Wrapper = styled.div`
  height: 100%;
  width: fit-content;

  @media print {
    max-height: fit-content;
    overflow: none;
  }
`

const BillContent = ({ bill, isRequesting }) => {
  return (
    <>
      {isRequesting ? (
        <SpinnerWraper>
          <Spinner center top />
        </SpinnerWraper>
      ) : (
        <Wrapper id="printBillContent">
          <Header year={bill.year} broaderNetwork={bill.broaderNetwork} />
          <InnerWrapper>
              <Main tree={bill.tree || []} />  
          </InnerWrapper>
          <Footer {...bill}/>  
        </Wrapper>
      )}
    </>
  );
};

export default BillContent;


