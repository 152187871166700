import React from "react";
import Networks from "components/organisms/AddNetworksSteps/Networks";
import ProvidersList from "components/organisms/AddNetworksSteps/ProvidersList";
import ProvidersGrid from "components/organisms/AddNetworksSteps/ProvidersGrid";

export const networkSteps = [
  {
    title: "Networks",
    content: <Networks />,
  },
  {
    title: "Providers",
    content: <ProvidersList />,
  },
  {
    title: "Provider Grid",
    content: <ProvidersGrid />,
  },
];
