import React from "react";
import { message } from "antd";
import { Icon as AntDIcon } from "@ant-design/compatible";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/plan/actions";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 20px 0;
  width: 100%;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    overflow-x: scroll;
  }

  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
    width: 0 !important;
    height: 0 !important;
  }
`;

const CustomStepper = styled.ul`
  display: flex;
  flex-direction: row;
  width: max-content;
  gap: 8px;
  list-style: none;
  margin: 0 auto;
  padding: 0;

  @media (min-width: 768px) {
    justify-content: center;
    width: 90%;
    flex-wrap: wrap;
  }
`;

const CustomStep = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  color: #21355b;

  button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;
    align-items: center;

    span {
      width: 30px;
      height: 30px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid #21355b;

      ${({ isActive }) =>
        isActive
          ? `
        background-color: #21355B;
        color: #fff;
      `
          : `
        background-color: #fff;
        color: #21355B;
      `}
    }
  }
`;

const IconWrapper = styled(AntDIcon)`
  display: flex !important;
  align-items: center;
`;

const AddServicesStepper = ({ report, isMasterReport }) => {
  const dispatch = useDispatch();
  const hasPartner = report && report.partner?.planIds?.length;
  const { values, validateForm, setFieldTouched, setErrors } = useFormikContext();
  let { expectedTable, table, partnerTable, currentServiceExtraDetails } = values;

  let steps = JSON.parse(JSON.stringify(useSelector((state) => state.plan.serviceSteps)));
  if (isMasterReport) {
    steps = [steps[1]];
  } else if (!report.birthParent.canEditPlans && !report.partner.canEditPlans) {
    steps.splice(1, 2);
  } else if (!report.partner.canEditPlans || !report.partner.planIds?.length) {
    steps.splice(2, 1);
  } else if (!report.birthParent.canEditPlans) {
    steps.splice(1, 1);
  }
  if (
    expectedTable.every((element) => element.type === "inNetwork") ||
    table.concat(partnerTable).every((element) => element.outOfNetworkCoverage === "no")
  ) {
    steps = steps.filter((item) => item.title !== "Extra Details");
  }

  const current = useSelector((state) => state.plan.current);

  const group1Name = values.group1;
  const group2Name = values.group2;
  steps.forEach((item) => {
    if (group1Name) {
      item.title = item.title.replace("[Group 1]", group1Name);
    }
    if (group2Name) {
      item.title = item.title.replace("[Group 2]", group2Name);
    }
  });

  const onValidate = (res, value) => {
    const fields = Object.keys(res);
    if (fields.length) {
      fields.map((field) => setFieldTouched(field));
      message.error("You must complete all required fields");
    } else {
      dispatch(Actions.Creators.changeCurrent(value));
      setErrors({});
    }
  };

  return (
    <Wrapper style={{ zIndex: 3 }}>
      <CustomStepper>
        {steps.map((item, i) => (
          <CustomStep key={item.title} isActive={current === i}>
            <button
              type="button"
              title={item.title}
              disabled={i > current + 1}
              onClick={() => {
                current < i
                  ? validateForm().then((res) => {
                      onValidate(res, i);
                    })
                  : dispatch(Actions.Creators.changeCurrent(i));
              }}
            >
              <span>{i + 1}</span>
              {item.title}
            </button>
            {i + 1 < steps.length ? <IconWrapper type="right" /> : <></>}
          </CustomStep>
        ))}
      </CustomStepper>
    </Wrapper>
  );
};

export default AddServicesStepper;
