import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Button, Select, DatePicker } from "antd";
const { Item } = Form;

const DynamicFieldRemoveEmployee = ({ name, label, emailList }) => {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          <Item label={label} labelCol={{ span: 24 }} style={{ marginBottom: "0px" }} />
          {fields.map((field, index) => (
            <Item key={field.key}>
              <div style={{ display: "inline-flex", alignItems: "center" }}>
                <Item name={[index, "email"]} noStyle>
                  <Select
                    style={{ width: "250px" }}
                    showSearch
                    placeholder="Type to search an existing email"
                    options={emailList ? emailList.map((email) => ({ value: email })) : []}
                    filterOption={(input, option) => (option?.value ?? "").toLowerCase().includes(input.toLowerCase())}
                  />
                </Item>
                <Item name={[index, "option"]} noStyle>
                  <Select
                    placeholder="Select an option"
                    style={{ width: "200px", marginLeft: "5px", marginRight: "5px" }}
                    options={[
                      { value: "terminated", label: "Terminated" },
                      { value: "retired", label: "Retired" },
                      { value: "deceased", label: "Deceased" },
                    ]}
                  />
                </Item>
                <Item name={[index, "date"]} noStyle>
                  <DatePicker format={["MM/DD/YYYY", "MM/DD/YY", "M/D/YYYY", "M/D/YY"]} />
                </Item>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    style={{ margin: "5px 8px" }}
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </div>
            </Item>
          ))}
          <Button
            type="dashed"
            onClick={() => add()}
            style={{
              width: "100%",
              minWidth: "150px",
            }}
          >
            Add Row <PlusOutlined />
          </Button>
        </>
      )}
    </Form.List>
  );
};

export default DynamicFieldRemoveEmployee;
