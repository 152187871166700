import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CustomPlans from "../components/organisms/CustomPlans";
import customActions from "store/custom/actions";

const mapStateToProps = (state, ownProps) => {
  const { custom, profile } = state;
  const { parent } = ownProps;
  const options = custom[parent].options;
  return {
    username: profile.username,
    parent,
    options,
    ...custom
  };
};

const mapDispatchToProps = dispatch => ({
  changePlan: (name, value) => {
    dispatch(customActions.Creators.changeCustomPlan(name, value));
  }
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomPlans)
);
