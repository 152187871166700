import React from "react";
import { BlockText } from "components";

import styled from "styled-components";
import { Icon, Text, Link } from "components";

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  margin: -60px auto 50px;


  @media (max-width: 1200px) {
    width: 100vw;
    padding-bottom: 50px;
  }
`;

const InnerWrapper = styled.div`
  display: block;
  width: 420px;
  text-align: center;

  @media (max-width: 480px) {
    width: 100vw;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  width: 100%;
  margin: 10vw auto 25px;
`;

const AuthenticationFormSection = (props) => {
  const {
    title,
    destinationRoute,
    showLink = true,
    linkText,
    linkContent,
    linkDestiny
  } = props

  return (
    <Wrapper>
      <InnerWrapper>
        <FlexWrapper col align="center" justify="space-between">
          <Text blue circular large bold lineHeightUnits={'1.1em'}>
            {title}
          </Text>
        </FlexWrapper>
      </InnerWrapper>

      {props.children}

      {
        linkContent && showLink &&
        <InnerWrapper>
          <Text fontSize="16" color="#657BA5" style={{ display: "inline" }}>
            {linkText + " "}
            <Link
              noMargin
              color="#3F598A"
              underline
              fontSize="16"
              to={{
                pathname: linkDestiny,
                destinationRoute,
              }}
            >
              {linkContent}
            </Link>
            .
          </Text>
        </InnerWrapper>
      }
    </Wrapper>
  );
};

export default AuthenticationFormSection
