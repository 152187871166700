import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import { cn } from "utils/cn";
import styled from "styled-components";

export const CustomRadioCheckGroup = styled.ul`
  li {
    display: flex;
    width: 100%;
    padding: 6px;
    border: 2px solid #d0d5dd;
    color: #21355b;
    border-radius: 8px;
    margin: 8px 0;
    font-weight: 500;

    label {
      display: flex;
      width: 100%;
      cursor: pointer;
      flex-direction: row;
      ${({ rightAligned }) => rightAligned && "flex-direction: row-reverse"};

      .label-wrapper {
        display: flex;
        flex-direction: column;
        line-height: initial;
        gap: 4px;
        margin: 6px;
        text-align: left;
        width: 100%;

        .radio-plans-title {
          font-weight: 900;
          font-size: 16px;
        }
        .radio-plans-description {
          margin-left: 0;
          margin-top: 2px;
          font-size: 13px;
        }

        .radio-plans-label {
          margin-left: 0;
          font-size: 14px;
        }
      }
    }

    .check-input-radio-wrapper,
    .check-input-checkbox-wrapper {
      position: relative;
      background-color: transparent;
      border-radius: 100%;
      border: 2px solid #21355b;
      height: 20px;
      width: 20px;
      min-width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 6px;

      &.checked {
        background-color: #21355b;

        .check-icon-radio,
        .check-icon-checkbox {
          display: inline;
          filter: invert(1);
        }
      }

      input {
        display: none;
      }

      .check-icon-radio,
      .check-icon-checkbox {
        display: none;
        top: 0;
        width: max-content;
        height: max-content;
      }
    }

    .check-input-checkbox-wrapper {
      border-radius: 4px;
    }

    &.group-no-border {
      border: none;
      padding: 0;

      .check-input-radio-wrapper {
        border-radius: 4px;
      }
    }
  }
`;

export const ItensWrapper = styled.div``;

export const ItensWrapperHeading = styled.h3`
  font-weight: bold;
  color: #21355b;
  margin-bottom: 10px;
  line-height: initial;
`;

/**
 * RadioGroup component.
 *
 * @param {Object} props - Component props.
 * @param {string} props.label - The label for the radio group.
 * @param {Array<{label: string, value: string}>} props.options - The options for the radio buttons.
 * @param {function} props.onChange - The function to call when the radio selection changes.
 * @param {string} props.value - The currently selected value.
 *
 * @returns {JSX.Element} The RadioGroup component.
 */
export const RadioGroup = ({ name, label, options, onChange, value, rightAligned }) => {
  const handleChange = (item) => {
    onChange(item);
  };

  const isSelected = (item) => value === item;

  return (
    <ItensWrapper>
      <ItensWrapperHeading>{label}</ItensWrapperHeading>
      <CustomRadioCheckGroup rightAligned={rightAligned}>
        {options?.map?.((option) => (
          <li key={option.label}>
            <label>
              <div className={cn("check-input-radio-wrapper", [isSelected(option.value) ? "checked" : ""])}>
                <input
                  type="radio"
                  name={name}
                  value={option.value}
                  checked={isSelected(option.value)}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange(value);
                  }}
                />
                <div className="check-icon-radio">
                  <CheckOutlined />
                </div>
              </div>
              {option.description ? (
                <div className="label-wrapper">
                  <span className="radio-plans-title">{option.label}</span>
                  <span className="radio-plans-description">{option.description}</span>
                </div>
              ) : (
                <div className="label-wrapper">
                  <span className="radio-plans-label">{option.label}</span>
                </div>
              )}
            </label>
          </li>
        ))}
      </CustomRadioCheckGroup>
    </ItensWrapper>
  );
};

export const booleanToTextValue = (value) => {
  if (value === null) {
    return undefined;
  }

  return value ? "true" : "false";
};

export const textValueToBoolean = (value) => {
  if (value === undefined) {
    return null;
  }

  return value === "true";
};
