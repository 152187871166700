import Title from 'components/atoms/Title'
import React from 'react'
import ReactDom from 'react-dom'
import styled from 'styled-components'
import {
  Button
} from 'components'

const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000;
  height: 100%;
`

const ModalWrapper = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 1000;
  background-color: #FFF;
  border-radius: 12px;
  flex-direction: column;
  max-width: 1200px;
  width: max-content;
  outline: 0;
  height: max-content;
  max-height: 95%;

  @media (max-width: 580px){
    max-width: 100%;
  }
`

const Header = styled.div`
  height: 55px;
  padding: 10px;
  margin: 15px auto 0;
  font-size: 22px;
  align-items: center;
  font-weight: bold;
  color: #21355b;
  font-family: circular;
  justify-content: center;

  @media (max-width: 580px){
  }
`

const Content = styled.div`
  max-height: calc(100% - 140px);
  height: fit-content;
  overflow: hidden;

  @media (max-width: 580px){
    width: 100%;
    overflow: scroll;
  }
`

const Footer = styled.div`
  min-height: 70px;
  align-self: flex-end;
  display: flex;
  align-items: center;
  margin: 0 50px ;
  justify-content: flex-end;

  @media (max-width: 580px){
    justify-content: center;
    width: 100%;
    margin: unset;
  }
`

const ModalView = props => {

  const {
    title,
    children,
    isShow,
    handlePrint,
    handleRegister,
    handleAction,
    textButton,
    disableButton,
    close
  } = props;

  if(!isShow) return null;

  return ReactDom.createPortal(
    <>
      <Overlay />
      <ModalWrapper>
        <Header>
          {title}
        </Header>
        <Content>{children}</Content>
        <Footer>
        {
        handlePrint && (
          <Button circular modal color="#186A67"  key={1} full onClick={handlePrint}>
            Print
          </Button>
        )}
        {
        handleRegister && (
          <Button circular modal key={3} full onClick={handleRegister}>
            Make an Account
          </Button>
        )}
        {
        handleAction ? (
          <Button circular modal key={4} full onClick={handleAction}>
            {textButton}
          </Button>
        ) : !disableButton ? (
          <Button circular modal border key={2} full onClick={close}>
            {textButton || "Close"}
          </Button>
        ) : (
          <Button circular modal key={2.5} full disabled>
            {textButton || "Close"}
          </Button>
        )}
        </Footer>
      </ModalWrapper>
    </>
    ,
    document.getElementById('root')
  )
}

export default ModalView