import React from "react";
import Start from "components/organisms/AddServicesSteps/Start"
import CoverageG1 from "components/organisms/AddServicesSteps/CoverageG1"
import CoverageG2 from "components/organisms/AddServicesSteps/CoverageG2"
import ExtraDetails from "components/organisms/AddServicesSteps/ExtraDetails"

export const serviceSteps = [
  {
    title: "Start",
    content: <Start />,
  },
  {
    title: "[Group 1] Coverage",
    content: <CoverageG1 />,
  },
  {
    title: "[Group 2] Coverage",
    content: <CoverageG2 />,
  },
  {
    title: "Extra Details",
    content: <ExtraDetails />,
  }
];
