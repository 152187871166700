import React from "react";
import styled from "styled-components";
import Text from "components/atoms/Text";
import { Radio } from "antd";
import styles from "./styles.css"

const Wrapper = styled.div`
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${(props) => props.step === 1 ? "none" : (props.previousStep > props.step) || props.previousStep === props.step ? "translateX(100%)" : "translateX(-100%)"};
  opacity: ${(props) => props.step === 1 ? 1 : 0};
  visibility: ${(props) => props.step === 1 ? "visible" : "hidden"};
  width: 100%;
  transition: 600ms;
`

const JobOfferQuestions = ({ step, previousStep, jobOfferOption, setJobOfferOption, groupObj }) => {
  return (
    <div style={{ position: "relative" }}>
      <Wrapper step={step} previousStep={previousStep}>
        <Text sectionTitle left styles={{ marginBottom: 0 }}>How would you like to verify your access?</Text>
        <Radio.Group value={jobOfferOption} onChange={(e) => setJobOfferOption(e.target.value)}>
          {groupObj.verifyJobOfferAgainstDatabase ?
            (<Radio value="verificationEmail">By email</Radio>)
            : (<Radio value="verificationCode">By verification code</Radio>)}
          <Radio value="upload">By uploading the benefit guide</Radio>
        </Radio.Group>
      </Wrapper>
    </div>
  )
}

export default JobOfferQuestions;