import joi from "joi";

const formSendSchema = joi
  .object({
    ids: joi
      .array()
      .items(joi.string())
      .min(1)
      .required(),
    network: joi.bool(),
    insurance: joi.string(),
    hospital: joi.string(),
    practice: joi.string()
  })
  .unknown()
  .required();

export default formSendSchema;
