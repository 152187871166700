import React, { useState } from "react";
import styled from "styled-components";
import {
  CalculateHeader as Header,
  FormFooter,
  FormCheckboxAltV2 as Checkbox,
  FormDatepickerV2 as Datepicker,
  FormInputV2 as Input,
  Icon,
} from "components";
import { merge } from "lodash";

const Wrapper = styled.section`
  display: grid;
  height: 100%;
  place-items: center;
`;

const GridWrapper = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 15px;
`;

const questions = {
  haveChildren: "Do you have children?",
  children: "How many child(ren) do you have?",
  childrenRequireIns:
    "Do you want to put your child(ren) on your insurance plan?",
  dueDate: "When is your baby's due date?",
};

const valToBool = (_val) => {
  return _val.toLowerCase() === "yes" ? true : false;
};

const OnboardingChildrenEventSection = ({ _form, handleNext }) => {
  const [steps, setSteps] = useState(["dob"]);

  const step = steps[steps.length - 1];

  const [form, setForm] = useState({
    haveChildren: _form.haveChildren,
    children: _form.children,
    childrenRequireIns: _form.childrenRequireIns,
    onboardingStep: _form.onboardingStep.includes("review")
      ? "review"
      : "residence",
    dueDate: _form.dueDate,
  });

  const handleChange = ({ name, value, text }) => {
    switch (name) {
      case "haveChildren":
        setForm({
          ...form,
          [name]: valToBool(text),
        });
        break;
      case "children":
        setForm({
          ...form,
          [name]: value,
        });
        break;
      case "childrenRequireIns":
        setForm({
          ...form,
          [name]: valToBool(text),
        });
        break;
      case "dueDate":
        setForm({
          ...form,
          [name]: value,
        });
        break;
      default:
        setForm({
          ...form,
          onboardingStep: _form.onboardingStep.includes("review")
            ? "review"
            : "residence",
        });
        break;
    }
  };

  const handleStep = () => {
    const updatedForm = merge({}, _form, form);
    switch (step) {
      case "haveChildren":
        form.haveChildren
          ? setSteps([...steps, "children"])
          : form.isDue
          ? setSteps([...steps, "dueDate"])
          : handleNext(updatedForm);
        break;
      case "children":
        form.children
          ? setSteps([...steps, "childrenRequireIns"])
          : form.isDue
          ? setSteps([...steps, "dueDate"])
          : handleNext(updatedForm);
        break;
      case "dueDate":
        handleNext(updatedForm);
        break;
      default:
        handleNext(updatedForm);
        break;
    }
  };

  const handleBack = () => {
    const updatedSteps = steps.slice(0, -1);
    const updatedForm = merge({}, _form, {
      ...form,
      onboardingStep: _form.onboardingStep.includes("review")
        ? "review"
        : "spouse",
    });
    switch (steps.length) {
      case 1:
        handleNext(updatedForm);
        break;
      default:
        setSteps(updatedSteps);
        break;
    }
  };

  const active = form[step] !== null && form[step] !== undefined;

  return (
    <Wrapper>
      <Header bigTitle={true} title={questions[step]} />
      <GridWrapper>
        {step.includes("Date") ? (
          <GridWrapper>
            <Datepicker
              name={step}
              value={form[step]}
              handleChange={handleChange}
              allowNA
            />
          </GridWrapper>
        ) : step.includes("children") ? (
          <GridWrapper>
            <Input
              name={step}
              value={form[step]}
              handleChange={handleChange}
              type="number"
            />
          </GridWrapper>
        ) : (
          <GridWrapper>
            <Checkbox
              name={step}
              value={form[step]}
              Icon={<Icon yesIcon small />}
              text="Yes"
              handleChange={handleChange}
            />
            <Checkbox
              name={step}
              value={form[step] === false}
              Icon={<Icon noIcon small />}
              text="No"
              handleChange={handleChange}
            />
          </GridWrapper>
        )}
      </GridWrapper>
      <FormFooter
        leftAction={handleBack}
        mainAction={handleStep}
        mainActionActive={active}
      />
    </Wrapper>
  );
};

export default OnboardingChildrenEventSection;
