import React from "react";
import styled from "styled-components";
import { PageTemplate, Header } from "components";
import checkWhite from "./check-white.svg";
import checkTeal from "./check-teal.svg";
import repeatWhite from "./repeat-white.svg";
import repeatTeal from "./repeat-teal.svg";
import mobileCheckWhite from "./mobile-check-white.svg";
import mobileCheckTeal from "./mobile-check-teal.svg";
import mobileRepeatWhite from "./mobile-repeat-white.svg";
import mobileRepeatTeal from "./mobile-repeat-teal.svg";
import { useWindowDimensions } from "utils/window";

const colors = {
  blue: "#21355b",
  teal: "#479f9b",
  gray: "#5a7184",
  lightGray: "#b3bac5",
  gold: "#eed786",
};

const PricingPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3% 0;
`;

const PageHeader = styled.h1`
  font-weight: 700;
  font-size: 56px;
  line-height: 60px;
  color: #21355b;

  @media (max-width: 500px) {
    font-size: 40px;
  }
`;

const PageSubHeader = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #21355b;
  text-align: center;
  width: 40%;

  @media (max-width: 1400px) {
    width: 50%;
  }

  @media (max-width: 1100px) {
    width: 60%;
  }

  @media (max-width: 950px) {
    width: 70%;
  }

  @media (max-width: 500px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

const PricingCard = styled.div`
  display: flex;
  width: 50%;
  height: 80vh;
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.125);
  border-radius: 5px;

  @media (max-width: 1400px) {
    width: 70%;
  }

  @media (max-width: 1100px) {
    width: 80%;
    height: 90vh;
  }

  @media (max-width: 950px) {
    width: 90%;
  }

  @media (max-width: 767px) {
    height: 65vh;
    width: 70%;
    margin-bottom: 25px;
    min-height: 550px;
  }

  @media (max-width: 500px) {
    width: 90%;
  }

  @media (max-width: 375px) {
    min-height: 475px;
  }

  @media (max-width: 320px) {
    min-height: 450px;
  }
`;

const MostPopularWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  height: 35px;
  @media (max-width: 1400px) {
    width: 70%;
  }

  @media (max-width: 1100px) {
    width: 80%;
  }

  @media (max-width: 950px) {
    width: 90%;
  }

  @media (max-width: 767px) {
    width: 70%;
  }

  @media (max-width: 500px) {
    width: 90%;
  }
`;

const MostPopular = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.teal};
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  width: ${(props) => (props.mobile ? "100%" : "33.33%")};
  height: 35px;
  z-index: 1;
  border-radius: 5px 5px 0 0;
`;

const Side = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: ${(props) => (props.customer ? "66.66%" : "33.33%")};
  background-color: ${(props) => (props.customer ? "white" : colors.blue)};
  border-radius: 5px;
`;

const PricingCardHeader = styled.p`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.employer ? "left" : "center")};
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: ${(props) => (props.employer ? "white" : colors.blue)};
  width: 100%;
  height: 80px;
  background-color: ${(props) => (props.employer ? colors.blue : colors.gold)};
  padding-left: ${(props) => (props.employer ? "30px" : 0)};
  border-radius: ${(props) => (props.mobile ? "5px 5px 0 0" : props.customer ? "5px 0 0 0" : 0)};
  margin: 0;
`;

const MiddleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 70%;
  width: 100%;
  margin-top: 20px;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
`;

const PriceTitle = styled.p`
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: ${(props) => (props.employer ? "white" : colors.blue)};
  margin: 0;
`;

const PriceSubtitle = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  color: ${(props) => (props.employer ? "white" : colors.gray)};
`;

const Compare = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  color: ${(props) => (props.employer ? colors.lightGray : colors.teal)};
  margin-bottom: 2px;

  @media (max-width: 375px) {
    font-size: 13px;
    line-height: 18px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const List = styled.ul`
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  color: ${(props) => (props.employer ? "white" : colors.teal)};
  padding-inline-start: 20px;
  list-style-type: none;
  padding: 0;
  margin: 0;

  @media (max-width: 500px) {
    line-height: 22px;
  }

  @media (max-width: 375px) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
    line-height: 16px;
  }

  li {
    background-image: url(${(props) => (props.employer ? checkWhite : checkTeal)});
    background-repeat: no-repeat;
    background-position: 0% 0.1em;
    padding-left: 30px;
    margin-bottom: 0.5em;

    @media (max-width: 500px) {
      background-image: url(${(props) => (props.employer ? mobileCheckWhite : mobileCheckTeal)});
    }
  }

  li:last-child {
    background-image: url(${(props) => (props.employer ? repeatWhite : repeatTeal)});

    @media (max-width: 500px) {
      background-image: url(${(props) => (props.employer ? mobileRepeatWhite : mobileRepeatTeal)});
    }
  }
`;

const Button = styled.a`
  width: 80%;
  height: 45px;
  color: ${(props) => (props.employer ? "white" : colors.teal)};
  background-color: ${(props) => (props.employer ? colors.teal : "white")};
  border: 2px solid ${colors.teal};
  border-radius: 4px;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${(props) => (props.mobile ? "20px" : "0")};

  :hover {
    color: ${(props) => (props.employer ? "white" : colors.teal)};
    background-color: ${(props) => (props.employer ? colors.teal : "white")};
    cursor: pointer;
  }
`;

const SideMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: ${(props) => (props.customer ? "white" : colors.blue)};
  border-radius: 5px;
`;

const freeCustomerOption = (
  <OptionWrapper>
    <PriceTitle>Free for you</PriceTitle>
    <PriceSubtitle>employer paid</PriceSubtitle>
    <Compare>Compare</Compare>
    <List>
      <li>Employer plan(s) (including job offers)</li>
      <li>COBRA plan</li>
      <li>Marketplace plans</li>
      <li>Spouse / partner&apos;s plan(s)</li>
      <li>Child&apos;s plan(s)</li>
      <li>How often: unlimited while your employer pays</li>
    </List>
  </OptionWrapper>
);

const paidCustomerOption = (
  <OptionWrapper>
    <PriceTitle>$21.99</PriceTitle>
    <PriceSubtitle>one time payment</PriceSubtitle>
    <Compare>Compare</Compare>
    <List>
      <li>Employer plan(s) (including job offers)</li>
      <li>COBRA plan</li>
      <li>Marketplace plans</li>
      <li>Spouse / partner&apos;s plan(s)</li>
      <li>Child&apos;s plan(s)</li>
      <li>How often: whenever you make a payment and upload new plan documents</li>
    </List>
  </OptionWrapper>
);

const paidEmployerOption = (
  <OptionWrapper>
    <PriceTitle employer>$0.79</PriceTitle>
    <PriceSubtitle employer>per employee per month</PriceSubtitle>
    <Compare employer>Compare</Compare>
    <List employer>
      <li>Employer plan(s) (including job offers)</li>
      <li>COBRA plan</li>
      <li>Marketplace plans</li>
      <li>Spouse / partner&apos;s plan(s)</li>
      <li>Child&apos;s plan(s)</li>
      <li>How often: unlimited for employees</li>
    </List>
  </OptionWrapper>
);

const PricingPage = () => {
  const { width } = useWindowDimensions();
  const isUserLoggedIn = Boolean(localStorage.getItem("token"));

  return (
    <PageTemplate
      padding="0 12.5%"
      header={<Header V2 href={isUserLoggedIn ? "/recommendations" : "https://www.predictabill.com"} />}
    >
      <PricingPageWrapper>
        <PageHeader>Pricing</PageHeader>
        <PageSubHeader>
          All plans offer the same comparison options, but it&apos;s easier and more affordable for you if your employer
          joins Predictabill
        </PageSubHeader>
        {width >= 768 ? (
          <>
            <MostPopularWrapper>
              <MostPopular>Most Popular!</MostPopular>
            </MostPopularWrapper>
            <PricingCard>
              <Side customer>
                <PricingCardHeader customer>Consumer</PricingCardHeader>
                <MiddleWrapper>
                  {freeCustomerOption}
                  {paidCustomerOption}
                </MiddleWrapper>
                <Button href={"/groups"}>Get Started For Free</Button>
              </Side>
              <Side employer>
                <PricingCardHeader employer>Employer</PricingCardHeader>
                <MiddleWrapper>{paidEmployerOption}</MiddleWrapper>
                <Button href={"https://calendly.com/predictabill/30min"} employer>
                  Sign Up for a Demo
                </Button>
              </Side>
            </PricingCard>
          </>
        ) : (
          <>
            <PricingCard>
              <SideMobile customer>
                <PricingCardHeader customer mobile>
                  Consumer
                </PricingCardHeader>
                <MiddleWrapper>{freeCustomerOption}</MiddleWrapper>
                <Button href={"/groups"} mobile>
                  Get Started For Free
                </Button>
              </SideMobile>
            </PricingCard>
            <PricingCard>
              <SideMobile customer>
                <PricingCardHeader customer mobile>
                  Consumer
                </PricingCardHeader>
                <MiddleWrapper>{paidCustomerOption}</MiddleWrapper>
                <Button href={"/groups"} mobile>
                  Get Started For Free
                </Button>
              </SideMobile>
            </PricingCard>
            <MostPopularWrapper>
              <MostPopular mobile>Most Popular!</MostPopular>
            </MostPopularWrapper>
            <PricingCard>
              <SideMobile employer>
                <PricingCardHeader employer>Employer</PricingCardHeader>
                <MiddleWrapper>{paidEmployerOption}</MiddleWrapper>
                <Button href={"https://calendly.com/predictabill/30min"} employer mobile>
                  Sign Up for a Demo
                </Button>
              </SideMobile>
            </PricingCard>
          </>
        )}
      </PricingPageWrapper>
    </PageTemplate>
  );
};

export default PricingPage;
