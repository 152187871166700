import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { Icon } from "@ant-design/compatible";
import { EditOutlined } from "@ant-design/icons"
import { Link } from "components";
import {
  CalculateHeader as Header,
  Spinner,
  ButtonLink,
  Text,
  IncompleteOnboarding,
} from "components";

import {
  Form, Select, Divider, Modal, Radio, Space, message, Input
} from "antd";

import { useDispatch } from "react-redux";
import Actions from "store/plan/actions";
import profileActions from "store/profile/actions";
import { getToken, getUserId } from "services/utils";
import { cloneReportForUsers, updateReport } from "services/api/admin.api";

import "./styles.css"

const { Item } = Form;
const { Option } = Select;

const modalWidth = "20vw";

const Wrapper = styled.div`
  font-family: 15px;
  color: #123;
  padding: 20px;
  padding-top: 0;
  background-color: ${({ withBG }) => (withBG ? "#d9f3f1" : "none")};
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  width: 100%;
  flex-wrap: ${(props) => (props.wrap ? "wrap" : "nowrap")};
`;

const StickyButton = styled.div`
  display: ${(props) => (props.hide ? "none" : "flex")};
  position: fixed;
  z-index: 1;
  bottom: 40px;
  right: 40px;
  width: 276px;
  height: 90px;
  align-items: center;
  justify-content: center;
`;

const ReportWrapper = styled.div`
  width: 446px;
  height: 400px;
  background-color: ${(props) => (props.first ? "#d9f3f1" : "lightgrey")};
  border-radius: 20px;
  overflow: hidden;
  margin: 30px;
  display: block;
`;

const ReportHeader = styled.div`
  height: 67px;
  background-color: ${(props) => (props.first ? "#86dbd5" : "grey")};
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AlertAdminView = styled.div`
  width: 100%;
  font-size: 18px;
  text-align: center;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
  color: white;
  background-color: rgba(255, 11, 0, 1);
`;

const ReportBody = styled.div`
  padding: 35px 10px;
  display: flex;
  height: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const RadioContainer = styled(Space)`
  .ant-radio-button-wrapper {
    width: 20vw;
    padding-left: 24px;
    padding-right: 24px;
    border: 0px;
  }
  .ant-radio-button-wrapper:first-child {
    border-bottom: 1px;
  }
  .ant-radio-button-wrapper-checked {
    background: #21355c !important;
    border-color: #21355c !important;
  }
`;

const RecommendationsList = ({
  header = {},
  isRequesting,
  history,
  currentReport,
  stickyWarning,
  toggleBackgroundColor,
  profileId,
  forceUpdate,
  username,
  isAdmin
}) => {

  const [reportModalNewPlans, setReportModalNewPlans] = useState();
  const [modalNewPlans, setModalNewPlans] = useState(false);
  const [newPlans, setNewPlans] = useState("");
  const [householdModal, setHouseholdModal] = useState(false);
  const [reportModalDuplicate, setReportModalDuplicate] = useState(null);
  const [form] = Form.useForm();
  const title = Form.useWatch('title', form);
  const type = Form.useWatch('type', form);
  const plans = Form.useWatch('plans', form);
  const pregnancy = Form.useWatch('pregnancy', form);


  const dispatch = useDispatch();

  const showModalNewPlans = (report) => {
    setReportModalNewPlans(report);
    setModalNewPlans(true);
  };

  const duplicateReport = async (changes) => {
    try {
      const reportId = reportModalDuplicate._id
      const users = new Array(await getUserId())
      const token = await getToken();
      const { data, status } = await cloneReportForUsers(reportId, users, token, true, changes)
      if (status === 200) {
        dispatch(profileActions.Creators.loadProfileRequest(profileId))
        forceUpdate()
      }
    } catch (e) {
      message.error('Error cloning recommendation')
    }
  }

  const handleHouseholdModalCancel = () => {
    setHouseholdModal(false)
  }

  const getHousehold = (report) => {
    const {
      type,
      event: {
        general: { children },
      },
    } = report || {};

    return type === "individual" && !children
      ? "mo"
      : type === "household (with no spouse)" && children > 0
        ? "mc"
        : type === "household" && !children
          ? "ms"
          : type === "household" && children > 0
            ? "mf"
            : "mo";
  }

  const getChildrenNumber = (report) => {
    const {
      event: {
        general: { children },
      },
    } = report || {};

    return children
  }

  const loadOptionsStateDropdown = (report, plans) => {
    if (!report) return []
    const options = new Set()
    const BPCompany = plans !== "clear self" && report?.birthParent?.company.toLowerCase()
    const partnerCompany = plans !== "clear spouse" && report?.partner?.company && report.partner.company.toLowerCase()
    if (BPCompany && BPCompany.includes("microsoft")) {
      options.add("California")
      options.add("Washington")
    } else if (BPCompany && BPCompany.includes("morgan stanley")) {
      options.add("Northern California")
      options.add("Southern California")
      options.add("Hawaii")
    } else if (BPCompany && BPCompany.includes("tesla")) {
      options.add("California")
      options.add("Hawaii")
    }

    if (partnerCompany && partnerCompany.includes("microsoft")) {
      options.add("California")
      options.add("Washington")
    } else if (partnerCompany && partnerCompany.includes("morgan stanley")) {
      options.add("Northern California")
      options.add("Southern California")
      options.add("Hawaii")
    } else if (partnerCompany && partnerCompany.includes("tesla")) {
      options.add("California")
      options.add("Hawaii")
    }

    let optionsArray = Array.from(options)
    if (optionsArray.length > 0) {
      if (optionsArray.includes("California") && optionsArray.includes("Northern California")) {
        optionsArray = optionsArray.filter(option => option !== "California")
      }
      optionsArray.sort((a, b) => a.localeCompare(b))
      optionsArray = optionsArray.map(option => {
        return {
          label: option,
          value: option
        }
      })
      optionsArray.push({
        label: "Other US State",
        value: "other"
      })

      return optionsArray
    } else {
      return []
    }
  }

  const handleAddNewPlans = (report) => {
    if (!report) {
      history.push("/groups")
    } else if (newPlans !== "marketplace") {
      history.push("/groups", {
        reportId: report._id,
        partner: newPlans
      })
    } else if (report) {
      localStorage.removeItem("form");
      dispatch(Actions.Creators.changeCurrent(0));
      history.push(`/create-plan`, {
        reportId: report._id,
        partnerJustForComparison: newPlans,
      });
    }
  };

  const handleCancelNewPlans = () => {
    setReportModalNewPlans(null);
    setModalNewPlans(false);
  };

  let reports = header.reports || [];
  reports = reports.filter((element) => element.showOnRecommendations === true);

  const [isCurrentOnboarding, setIsCurrentOnboarding] = useState(null);

  useEffect(() => {
    if (currentReport) {
      // if current report is not paid, provide link to redirect back to onboarding
      setIsCurrentOnboarding(!currentReport.isPaid);
      toggleBackgroundColor(!currentReport.isPaid);
    } else {
      setIsCurrentOnboarding(true);
      toggleBackgroundColor(true);
    }
  }, [currentReport, toggleBackgroundColor]);

  const handleClick = ({ route, report }) => {
    const { _id, status, onboardingStep } = report || {};

    if (route) {
      history.push(route);
    } else if (status === 3) {
      history.push(`/optimizeV2/${_id}`);
    } else if (onboardingStep !== "finish") {
      history.push("/onboarding");
    } else {
      history.push("/account/upload_pamphlets");
    }
  };

  const handleDetailsLink = ({ report }) => {
    const { _id } = report || {};

    return `/optimizeV2/${_id}`
  };

  const [editing, setEditing] = useState(false);
  const [reportBeingEdited, setReportBeingEdited] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = async (report, newTitle) => {
    try {
      toggleEdit()
      newTitle = newTitle.trim()
      if (report.title !== newTitle) {
        const token = await getToken();
        const { status } = await updateReport(report._id, {
          ...report,
          title: newTitle,
        }, token)
        if (status === 200 || status === 206) {
          dispatch(profileActions.Creators.loadProfileRequest(profileId))
          forceUpdate()
        } else {
          message.error("Error updating recommendation's title")
        }
      }
    } catch (err) {
      message.error("Error updating recommendation's title")
    }
  };

  const renderReports = (report, i) => {
    const {
      birthParent,
      isPaid,
      dates,
      fulfilledDate,
      status,
      partner,
      title,
      event,
    } = report;
    const { marriage } = event;
    const { needToAddPartnerPlans } = marriage;

    const startYear = dates[0].split("/")[2]; // start year
    const endYear = dates[1].split("/")[2]; // start year
    const year =
      startYear === endYear ? startYear : `${startYear}/${endYear.slice(2)}`;

    const companyTitle =
      birthParent.company && partner.company
        ? birthParent.company + " vs. " + partner.company + " " + year
        : birthParent.company
          ? birthParent.company + " " + year
          : partner.company
            ? partner.company + " " + year
            : "New Request (2020)";

    const documents = birthParent.pamphlet.length + partner.pamphlet.length;

    return (
      <ReportWrapper key={i} first={i === 0}>
        <ReportHeader first={i === 0}>
          {!isPaid ? (
            <>
              <Text black fontSize="22" bold="true">
                INCOMPLETE
              </Text>
              <Text />
            </>
          ) : (
            <>
              <Text black fontSize="22" bold="true">
                {status === 3 ? "COMPLETE" : "PENDING"}
              </Text>
              <Text black fontSize="18">
                {fulfilledDate}
              </Text>
            </>
          )}
        </ReportHeader>
        <ReportBody>
          <FlexWrapper col>
            <Text black small>
              {companyTitle}
            </Text>
            <Text bold lg center style={{
              width: "85%",
              marginTop: "5px"
            }}>
              {status === 3 && editing && report._id === reportBeingEdited ? (
                <div
                  style={{
                    maxHeight: "26px",
                  }}
                >
                  <Input
                    ref={inputRef}
                    onPressEnter={(e) => save(report, e.target.value)}
                    onBlur={(e) => save(report, e.target.value)}
                    defaultValue={title}
                  />
                </div>
              ) : status === 3 ? (
                <>
                  <div
                    className="editable-cell-value-wrap"
                    onClick={() => {
                      setReportBeingEdited(report._id)
                      toggleEdit()
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {title}
                  </div>
                </>
              )
                : status === 2
                  ? "The information you provided is under review"
                  : "We need more information from you"}
            </Text>
          </FlexWrapper>

          {i === 0 && !isPaid && (
            <ButtonLink
              withBG
              button
              fontSize="18"
              to={{
                pathname: "/checkout",
                subscriptionTier: report.forBusiness || report.type,
              }}
            >
              PROCEED TO PAYMENT
            </ButtonLink>
          )}

          {status === 3 ? (
            <Link to={handleDetailsLink({ report })}>
              <ButtonLink
                withBG
                button
                fontSize="18"
              >
                VIEW DETAILS
              </ButtonLink>
            </Link>
          ) : (
            <ButtonLink
              withBG
              button
              fontSize="18"
              onClick={() => handleClick({ report })}
            >
              {status === 1
                ? "ADD MORE DETAILS"
                : "ADD DETAILS"}
            </ButtonLink>
          )}

          {!report.partner?.planIds?.length && (
            <ButtonLink
              withBG
              button
              fontSize="18"
              onClick={() => {
                showModalNewPlans(report);
              }}
            >
              ADD PLANS
            </ButtonLink>
          )}
          <ButtonLink
            withBG
            button
            fontSize="18"
            onClick={() => {
              setReportModalDuplicate(report);
              setHouseholdModal(true);
            }}
          >
            MAKE A COPY
          </ButtonLink>
          <FlexWrapper col>
            {documents > 0 && (
              <Text black italic fontSize="13">
                {`Documents received: ${documents}`}
              </Text>
            )}
          </FlexWrapper>
        </ReportBody>
      </ReportWrapper >
    );
  };

  return (
    <>
      {stickyWarning && <AlertAdminView>Viewing as Admin</AlertAdminView>}
      <Wrapper withBG={!isCurrentOnboarding ? false : true}>
        <Header
          bigTitle={true}
          title={!isCurrentOnboarding ? "My Recommendations" : "Welcome back!"}
        />
        {!isCurrentOnboarding ? (
          <>
            <FlexWrapper>
              {isRequesting ? (
                <Spinner center top />
              ) : (
                <FlexWrapper wrap>{reports.map(renderReports)}</FlexWrapper>
              )}
            </FlexWrapper>
            <StickyButton hide={currentReport && currentReport.status < 3}>
              <ButtonLink
                withBG
                button
                fontSize="20px"
                onClick={() => {
                  handleAddNewPlans()
                }}
              >
                <FlexWrapper justify="space-between">
                  <Icon
                    type="plus"
                    style={{
                      color: "#fff",
                      fontSize: "65px",
                      padding: "0 10px",
                    }}
                  />
                  <FlexWrapper col align="flex-start">
                    <Text white>{`GET ${currentReport || reports.length ? "ANOTHER" : "A"
                      }`}</Text>
                    <Text white>RECOMMENDATION</Text>
                  </FlexWrapper>
                </FlexWrapper>
              </ButtonLink>
            </StickyButton>
          </>
        ) : (
          <IncompleteOnboarding
            setIsCurrentOnboarding={setIsCurrentOnboarding}
            toggleBackgroundColor={toggleBackgroundColor}
          />
        )}
      </Wrapper>
      <Modal
        title="What new plans would you like to compare?"
        open={modalNewPlans}
        okButtonProps={{ disabled: !newPlans }}
        onOk={() => handleAddNewPlans(reportModalNewPlans)}
        onCancel={handleCancelNewPlans}
        bodyStyle={{ padding: 0 }}
        width={modalWidth}
      >
        <Radio.Group
          buttonStyle="solid"
          size="large"
          onChange={(e) => {
            setNewPlans(e.target.value);
          }}
        >
          <RadioContainer direction="vertical" size={0}>
            {reportModalNewPlans?.event?.marriage?.needToAddPartnerPlans && (
              <>
                <Radio.Button value="partner">Spouse Plans</Radio.Button>
                <Divider style={{ margin: 0 }} />
              </>
            )}

            <Radio.Button value="jobOffer">Job Offer</Radio.Button>
          </RadioContainer>
        </Radio.Group>
      </Modal>
      <Modal
        title="What information would you like to modify before copying?"
        open={householdModal}
        onOk={form.submit}
        onCancel={handleHouseholdModalCancel}
        width={"35vw"}
        destroyOnClose={true}
      >
        <Form
          form={form}
          onFinish={duplicateReport}
          preserve={false}
          layout="horizontal"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          initialValues={{
            title: reportModalDuplicate?.title,
            type: reportModalDuplicate && getHousehold(reportModalDuplicate),
            plans: "keep all",
            state: loadOptionsStateDropdown(reportModalDuplicate, plans)[0]?.value,
            pregnancy: "no",
            children: 1
          }}
          className="clone-report-modal"
        >
          <Item label="Title:" name="title" labelCol={{ span: 24 }}>
            <Input defaultValue={reportModalDuplicate?.title} placeholder="Report's new title" />
          </Item>
          <Item label="Household Size:" name="type" labelCol={{ span: 24 }}>
            <Select defaultValue={() => getHousehold(reportModalDuplicate)} placeholder="Select an Option">
              <Option value="mo">You</Option>
              <Option value="ms">You and your spouse</Option>
              <Option value="mc">You and your child(ren)</Option>
              <Option value="mf">You, your spouse and your child(ren)</Option>
            </Select>
          </Item>
          {reportModalDuplicate?.partner?.planIds?.length > 0 && <Item label="Plans:" name="plans" labelCol={{ span: 24 }}>
            <Select defaultValue={"keep all"} placeholder="Select an Option">
              <Option value="keep all">Keep All Plans</Option>
              <Option value="clear spouse">{`Keep Only ${reportModalDuplicate?.birthParent?.company} Plans`}</Option>
              <Option value="clear self">{`Keep Only ${reportModalDuplicate?.partner?.company} Plans`}</Option>
            </Select>
          </Item>}
          {loadOptionsStateDropdown(reportModalDuplicate, plans).length > 0 && (
            <Item label="Which state do you reside?" name="state" labelCol={{ span: 24 }}>
              <Select defaultValue={loadOptionsStateDropdown(reportModalDuplicate, plans)[0]?.value} placeholder="Select an Option">
                {loadOptionsStateDropdown(reportModalDuplicate, plans).map((option) => (
                  <Option value={option.value}>{option.label}</Option>
                ))}
              </Select>
            </Item>
          )}
          {(type === "mc" || type === "mf") && (
            <Item label="Pregnancy:" name="pregnancy" labelCol={{ span: 24 }}>
              <Select defaultValue={"yes"} placeholder="Select an Option">
                <Option value="yes">Yes</Option>
                <Option value="no">No</Option>
              </Select>
            </Item>
          )}
          {(type === "mc" || type === "mf") && (
            <Item label={`Number of Children${pregnancy === "yes" ? " (without newly expected baby):" : ":"}`} name="children"
              labelCol={{ span: 24 }}
            >
              <Input type="number" defaultValue={() => getChildrenNumber(reportModalDuplicate)} />
            </Item>
          )}
        </Form>
      </Modal >
    </>
  );
};

export default RecommendationsList;
