import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "antd";
import Text from "components/atoms/Text";

const Wrapper = styled.div`
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${(props) =>
    props.step === 1
      ? "none"
      : props.previousStep > props.step || props.previousStep === props.step
      ? "translateX(100%)"
      : "translateX(-100%)"};
  opacity: ${(props) => (props.step === 1 ? 1 : 0)};
  visibility: ${(props) => (props.step === 1 ? "visible" : "hidden")};
  transition: 600ms;
  // position: static;
`;

const AttachPersonalEmail = ({ step, previousStep, setEmail, setCanSendEmail }) => {
  const [validEmail, setValidEmail] = useState(false);

  if (validEmail) {
    setCanSendEmail(true);
  } else {
    setCanSendEmail(false);
  }

  const validateEmail = (email) => {
    const emailRegex =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    setValidEmail(emailRegex.test(email));
  };

  return (
    <div style={{ position: "relative" }}>
      <Wrapper step={step} previousStep={previousStep}>
        <Text sectionTitle left style={{ marginBottom: 0 }}>{`Please enter your personal email address.`}</Text>
        <Input
          type="email"
          style={{ width: "100%", maxWidth: "500px" }}
          placeholder="Enter work email"
          onChange={(e) => {
            const email = e.target.value?.toLowerCase();
            setEmail(email);
            validateEmail(email);
          }}
        />
      </Wrapper>
    </div>
  );
};

export default AttachPersonalEmail;
