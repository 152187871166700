import React, { useState, createRef, useMemo } from "react";
import JSONTree from "react-json-tree";
import {
  downloadPlanTemplate,
  uploadNewPlansCSV,
  createPlans,
} from "services/api/admin.api";
import { getToken } from "services/utils";
import { Card, message } from "antd";

import { planTypes } from "enums";
import styled from "styled-components";
import { UploadCSVPlans, Spinner, PreviewResultsPlansCSV } from "components";

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
`;

const AddPlansPanel = () => {
  const templateLink = createRef();

  const [errorsList, setErrorsList] = useState();
  const [plansValues, setPlansValues] = useState();
  const [plansPreview, setPlansPreview] = useState();
  const [hasErrors, setHasErrors] = useState(false);
  const [type, setType] = useState(planTypes.private);

  const onSubmit = () => {
    setPlansPreview();
    setPlansValues();
    setErrorsList();
    setHasErrors(false);
  };

  const onUploadSuccess = (response) => {
    const { errors, numberOfErrors, plans } = response.data;
    if (errors.length === 0 && plans.length === 0) {
      message.warning("No new plans");
    }
    setErrorsList(errors);
    const preview = {};
    const values = plans.map((val) => {
      preview[`Row: ${val.line} - ID: ${val.plan.externalId}`] = val.plan;
      return val.plan;
    });
    setPlansPreview(preview);
    setPlansValues(values);
    setHasErrors(numberOfErrors > 0);
  };

  const sendValues = async () => {
    try {
      const token = await getToken();

      const response = await createPlans(
        type === planTypes.public,
        token,
        plansValues,
      );
      if (response.status === 200) {
        message.success("Plans Created Successfully");
      } else message.error(response.message);
    } catch (e) { }
  };

  const onChangeType = (e) => {
    e.preventDefault();
    setType(e.target.value);
    setPlansPreview();
    setPlansValues();
    setHasErrors(false);
    setErrorsList();
  };

  const downloadTemplate = async () => {
    const token = await getToken();
    try {
      let link = templateLink;
      if (link.current.href) {
        return;
      }
      const { data } = await downloadPlanTemplate(type, token);
      var myblob = new Blob([data], {
        type: "text/plain",
      });
      const href = window.URL.createObjectURL(myblob);

      link.current.download = `template.csv`;
      link.current.href = href;
      link.current.click();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Card title="Add new plans">
      <>
        <Nav>
          <a ref={templateLink} onClick={() => downloadTemplate()}>
            Download Plans Template
          </a>
        </Nav>
        <UploadCSVPlans
          onSubmit={onSubmit}
          uploadFunction={uploadNewPlansCSV}
          onChangeType={onChangeType}
          onSuccess={onUploadSuccess}
          type={type}
        />
      </>
      <PreviewResultsPlansCSV
        errorsList={errorsList}
        hasErrors={hasErrors}
        plansPreview={plansPreview}
        submitDisabled={hasErrors || !plansValues || !plansValues.length}
        onSubmit={sendValues}
      />
    </Card>
  );
};

export default AddPlansPanel;
