import React from "react";
import styled, { css } from "styled-components";
import crystalBallImage from "./crystal_ball_blue.png";
import { Text, Currency, Icon } from "components";
// import { Icon } from "antd";

const Container = styled.div`
  ${({ crystalBall }) =>
    crystalBall &&
    css`
      background-image: url(${crystalBallImage});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    `}
  display: flex;
  min-width: 300px;
  min-height: 437px;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  vertical-align: top;
`;

const FlexWrapper = styled.div`
  display: flex;
  ${({ col }) => col && `flex-direction: column;`}
  vertical-align: middle;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  margin-bottom: 140px;
  width: 250px;
  height: 285px;
`;

const InnerWrapper = styled.div`
  vertical-align: middle;
  align-items: center;
  text-align: center;
  margin: auto;
  height: 80px;
  ${({ top }) => top && `height: 30px;`}
  ${({ middle }) => middle && `min-height: 140px;`}
  ${({ bottom }) => bottom && `bottom: 50px;`}
`;

const IconWrapper = styled.div`
  display: inline-flex;
  height: 95px;
  width: 100%;
`;
const IconInner = styled.div`
  width: 50%;
  ${({ bottom }) =>
    bottom &&
    `height: 100%; width: 75%; bottom: 0; margin-right: auto; margin-left: auto;`}
`;

const CrystalBall = ({ data, icons, reportType, isBaby }) => {
  const { heading, subHeading, total } = data;

  let hsa = data.charges.after.filter((charge) => {
    return charge.id === "hsa";
  });
  hsa = hsa.length > 0 ? hsa[0].total.value : 0;

  let hra = data.charges.after.filter((charge) => {
    return charge.id === "hra";
  });
  hra = hra.length > 0 ? hra[0].total.value : 0;

  return (
    <Container crystalBall>
      <FlexWrapper col>
        <InnerWrapper top>
          <Text big bolder>
            {heading}
          </Text>
        </InnerWrapper>
        <InnerWrapper middle>
          <IconWrapper>
            {subHeading.length > 1 ? (
              icons.map((icon, i) => {
                return (
                  <IconInner key={`${i}-icon`}>
                    <Text fontSize="10">{subHeading[i]}</Text>
                    <Icon
                      bald={icon === "bald"}
                      flip={icon === "flip"}
                      curly={icon === "curly"}
                      parted={icon === "parted"}
                    />
                  </IconInner>
                );
              })
            ) : (
              <IconInner bottom>
                <Text fontSize="12">
                  {subHeading[0]} {"\n"}
                </Text>
                {reportType === "individual"
                  ? icons.map((icon, i) => {
                      return (
                        <Icon
                          key={`${i}-icon`}
                          style={{ width: "50%" }}
                          bald={icon === "bald"}
                          flip={icon === "flip"}
                          curly={icon === "curly"}
                          parted={icon === "parted"}
                        />
                      );
                    })[0]
                  : icons.map((icon, i) => {
                      return (
                        <Icon
                          key={`${i}-icon`}
                          style={{ width: "50%" }}
                          bald={icon === "bald"}
                          flip={icon === "flip"}
                          curly={icon === "curly"}
                          parted={icon === "parted"}
                        />
                      );
                    })}
              </IconInner>
            )}
          </IconWrapper>
          {isBaby && (
            <Text big>
              <Icon baby />
            </Text>
          )}
        </InnerWrapper>
        <InnerWrapper>
          <Text lg bolder>
            Estimated Grand Total
          </Text>
          <Text lg bolder>
            <Currency price={total} />
          </Text>
          {hsa && hra ? (
            <Text>
              (+
              <Currency price={hsa + hra} /> Unused HA)
            </Text>
          ) : hsa ? (
            <Text>
              (+
              <Currency price={hsa} /> Unused HSA)
            </Text>
          ) : (
            <Text>
              (+
              <Currency price={hra} /> Unused HRA)
            </Text>
          )}
        </InnerWrapper>
      </FlexWrapper>
    </Container>
  );
};

export default CrystalBall;
