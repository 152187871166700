import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  changeBirthPlanOptions: ["name", "value"],
  changeBirthPlan: ["name", "value"],
  changeInsurancePlan: ["name", "value"],
  changeInsuranceIsNetwork: ["name", "value"],
  changePremiumPlan: ["name", "value"],
  loadOptionsRequest: [null],
  loadOptionsSuccess: ["payload"],
  loadOptionsFailure: ["error"],
  loadBillsRequest: ["data"],
  resetStorePlan: [null],
  loadInsuranceSelect: ["name", "data"],
  changePrevValue: ["name", "prevValue"],
  changePrevValueBirth: ["name", "prevValue"],
  changeCurrent: ["value"],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
