import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
  validation,
  customOptionsFormSendSchema,
  customPlanFormSendSchema
} from "services/validation";
import {
  checkCustomOptionsFormData,
  checkCustomPlanFormData
} from "services/utils";

import {
  createCustom,
  getCustom,
  updateCustom,
  updateCustomPlan
} from "services/api";
import { getUserId, getToken } from "services/utils/tokens";
import { removeTokenSaga } from "../auth/sagas";
import Actions from "./actions";
import profileActions from "../profile/actions";

const {
  Types: { CHANGE_CUSTOM_OPTIONS, CHANGE_CUSTOM_PLAN, LOAD_CUSTOM_REQUEST },
  Creators
} = Actions;

export function* watchChangeCustomOptions() {
  yield takeLatest(CHANGE_CUSTOM_OPTIONS, function*({ name, value }) {
    if (name.includes("options") && value === "") {
      return;
    }

    const customId = yield select(state => state.profile.currentCustom);
    const custom = yield select(state => state.custom);

    if (!customId) {
      yield call(sendCreateCustom, custom);
    } else if (customId) {
      yield call(sendUpdateCustom, customId, custom);
    }
  });
}

export function* watchChangeCustomPlan() {
  yield takeLatest(CHANGE_CUSTOM_PLAN, function*({ name, value }) {
    const customId = yield select(state => state.profile.currentCustom);
    const custom = yield select(state => state.custom);

    if (!customId) {
      yield call(sendCreateCustom, custom);
    } else if (customId) {
      const fields = name.split(" ");
      const parent = fields[0];
      const idx = fields[1];
      const plan = custom[parent].options[idx];
      yield call(
        sendUpdateCustomPlan,
        plan,
        custom[parent].group,
        custom.useOutOfNetwork,
        custom[parent].startDate
      );
    }
  });
}

function* sendCreateCustom(custom) {
  const formDataSend = yield checkCustomOptionsFormData({ ...custom });
  const valid = yield call(
    validation,
    customOptionsFormSendSchema,
    formDataSend
  );

  if (valid) {
    try {
      const response = yield call(createCustom, formDataSend);
      if (response.data) {
        const { data } = response;
        yield put(
          profileActions.Creators.updateProfile(
            "currentCustom",
            data.custom._id
          )
        );
        if (data.custom.birthParent.plans.length > 0)
          yield put(
            Creators.updateCustomPlanIds(
              "birthParent",
              data.custom.birthParent.plans
            )
          );
        if (data.custom.partner.plans.length > 0)
          yield put(
            Creators.updateCustomPlanIds("partner", data.custom.partner.plans)
          );
      } else {
        yield put(Creators.loadCustomFailure(response.error));
      }
    } catch (error) {
      yield put(Creators.resetStoreCustom());
      yield removeTokenSaga(error);
    }
  }
}

function* sendUpdateCustom(customId, custom) {
  const formDataSend = yield checkCustomOptionsFormData({ ...custom });
  const valid = yield call(
    validation,
    customOptionsFormSendSchema,
    formDataSend
  );

  if (valid) {
    try {
      const response = yield call(updateCustom, customId, formDataSend);
      if (response.data) {
        const { data } = response;
        if (data.custom.birthParent.plans.length > 0)
          yield put(
            Creators.updateCustomPlanIds(
              "birthParent",
              data.custom.birthParent.plans
            )
          );
        if (data.custom.partner.plans.length > 0)
          yield put(
            Creators.updateCustomPlanIds("partner", data.custom.partner.plans)
          );
      } else {
        yield put(Creators.loadCustomFailure(response.error));
      }
    } catch (error) {
      yield put(Creators.resetStoreCustom());
      yield removeTokenSaga(error);
    }
  }
}

function* sendUpdateCustomPlan(plan, group, useOutOfNetwork, startDate) {
  const formDataSend = yield checkCustomPlanFormData({
    ...plan,
    group,
    useOutOfNetwork,
    startDate
  });
  const valid = yield call(validation, customPlanFormSendSchema, formDataSend);

  if (valid) {
    try {
      const token = yield getToken();
      const response = yield call(updateCustomPlan, formDataSend, token);
      if (response.status !== 200) {
        yield put(Creators.loadCustomFailure(response.error));
      }
    } catch (error) {
      yield put(Creators.resetStoreCustom());
      yield removeTokenSaga(error);
    }
  }
}

export function* watchLoadCustomOptions() {
  yield takeEvery(LOAD_CUSTOM_REQUEST, function*({ customId }) {
    try {
      const isAuth = yield select(state => state.auth.isAuth);
      const userId = isAuth ? yield getUserId() : null;

      const token = yield getToken();
      const response = yield call(getCustom, customId, token, userId);
      if (response.data) {
        const { custom, birthParentPlans, partnerPlans } = response.data;
        yield put(
          Creators.loadCustomSuccess(custom, birthParentPlans, partnerPlans)
        );
      } else {
        yield put(Creators.loadCustomFailure(response.error));
      }
    } catch (error) {
      yield put(Creators.resetStoreCustom());
      yield removeTokenSaga(error);
    }
  });
}
