import React from "react";
import Step1 from "components/organisms/CreatePlanSteps/Step1";
import Step2 from "components/organisms/CreatePlanSteps/Step2";
import Step3 from "components/organisms/CreatePlanSteps/Step3";
import Step4 from "components/organisms/CreatePlanSteps/Step4";
import Step5 from "components/organisms/CreatePlanSteps/Step5";
import Step6 from "components/organisms/CreatePlanSteps/Step6";
import Step7 from "components/organisms/CreatePlanSteps/Step7";
import StepAdvanced from "components/organisms/CreatePlanSteps/StepAdvanced";

export const steps = [
  {
    title: "Start",
    content: <Step1 />,
  },
  {
    title: "Premiums",
    content: <Step2 />,
  },
  {
    title: "Deductibles",
    content: <Step3 />,
  },
  {
    title: "Limits",
    content: <Step4 />,
  },
  {
    title: "Advanced",
    content: <StepAdvanced />,
  },
  {
    title: "FSA/HSA",
    content: <Step5 />,
  },
  {
    title: "Coverage",
    content: <Step6 />,
  },
  {
    title: "Group",
    content: <Step7 />,
  },
];
