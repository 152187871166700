import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  getRole,
  getTracking,
  getDatedTracking,
  getFriends,
  addFriend,
  removeFriend,
} from "services/api/admin.api";
import { getToken, getDateFormat } from "services/utils";
import { message } from "antd";

import {
  CalculateHeader,
  PageTemplate,
  Header,
  Footer,
  Text,
  TableTracking,
  Button,
  FormInput as Input,
} from "components";

const Wrapper = styled.section`
  margin: 20px auto;
  display: flex;

  min-height: 477px;
`;

const FlexWrapper = styled.div`
  display: flex;
  ${({ block }) => block && `display: block`}
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 0;
  ${({ fixed }) => fixed && `margin: 0 auto 0 auto; height: 500px;`}
  ${({ fixedTop }) => fixedTop && `float: right; margin: 0 auto 0 auto;`}
  ${({ leftNoMargin }) => leftNoMargin && `margin-left: 5px;`}
  ${({ col }) => col && `flex-direction: column;`}
  ${({ maxWidth }) =>
    maxWidth
      ? `max-width: ${maxWidth}; min-width: 300px;`
      : `min-width: 500px;`}
  ${({ left }) =>
    left &&
    `
    float: left;
    margin-left: 30px;
    margin-right: 20px;
    min-width: 250px;
  `}
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  ${({ header }) =>
    header &&
    `
    margin: auto;
    margin-top: 0;
    padding: 0;
    flex-direction: column;
  `}
`;

class AdminUsersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      tracking: null,
      startDate: "",
      endDate: "",
      defaultStart: "",
      defaultEnd: "",
      datedTracking: null,
      friend: "",
      friends: [],
      error: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.addFriend = this.addFriend.bind(this);
    this.removeFriend = this.removeFriend.bind(this);
  }

  async componentDidMount() {
    document.title = "Admin - Users";
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    const endDate = new Date();

    const token = await getToken();
    const response = await getRole(token);

    const tracking = await getTracking(token);
    const datedTracking = await getDatedTracking(startDate, endDate, token);
    const friends = await getFriends(token);

    if (
      response.status === 200 &&
      tracking.status === 200 &&
      friends.status === 200
    ) {
      this.setState({
        loaded: true,
        tracking: tracking.data,
        datedTracking: datedTracking.data,
        friends: friends.data,
        startDate: startDate,
        endDate: endDate,
        defaultStart: startDate,
        defaultEnd: endDate,
      });
    } else {
      this.props.history.push("/calculator");
    }
  }

  handleChange({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  async handleSearch(e) {
    e.preventDefault();
    const { startDate, endDate, defaultStart, defaultEnd } = this.state;

    const token = await getToken();
    const response = await getDatedTracking(startDate, endDate, token);

    if (response.status === 200) {
      this.setState({
        datedTracking: response.data,
      });
    } else {
      const defaultRes = await getDatedTracking(
        defaultStart,
        defaultEnd,
        token
      );

      this.setState({
        datedTracking: defaultRes.data,
        startDate: defaultStart,
        endDate: defaultEnd,
      });
      message.error("Problem with date input, reset to default dates.");
    }
  }

  async addFriend(e) {
    e.preventDefault();
    const { friend, friends, defaultStart, defaultEnd } = this.state;
    const token = await getToken();
    const friendObj = { _id: Math.random(), username: friend };
    let currentFriends = friends;

    const response = await addFriend(friend);
    if (response.status === 200) {
      currentFriends.push(friendObj);
      const updateTracking = await getTracking(token);
      const updateDated = await getDatedTracking(
        defaultStart,
        defaultEnd,
        token
      );
      this.setState({
        friend: "",
        friends: currentFriends,
        tracking: updateTracking.data,
        datedTracking: updateDated.data,
        error: null,
      });
    } else {
      this.setState({ error: "Friend not added." });
      message.error("Friend not added.");
    }
  }

  async removeFriend(username) {
    const { friends, defaultStart, defaultEnd } = this.state;
    const token = await getToken();

    const response = await removeFriend(username);
    if (response.status === 200) {
      const currentFriends = friends.filter(
        (friend) => friend.username !== username
      );
      const updateTracking = await getTracking(token);
      const updateDated = await getDatedTracking(
        defaultStart,
        defaultEnd,
        token
      );

      this.setState({
        friends: currentFriends,
        tracking: updateTracking.data,
        datedTracking: updateDated.data,
        error: null,
      });
    } else {
      this.setState({ error: "Friend not added." });
      message.error("Friend not added.");
    }
  }

  render() {
    const {
      tracking,
      startDate,
      endDate,
      datedTracking,
      friend,
      friends,
      loaded,
    } = this.state;

    if (!loaded) return <></>;

    return (
      <PageTemplate header={<Header />} footer={<Footer />}>
        <CalculateHeader title="Admin Panel - User Tracking" />
        <Wrapper>
          <FlexWrapper left col>
            <Text>FRIENDS LIST</Text>
            <ul style={{ listStyle: "none" }}>
              {friends.map((friend) => {
                return (
                  <li key={friend._id}>
                    <Text>
                      {friend.username} {"    "}
                      {!friend.username.includes("admin") && (
                        <Button
                          close
                          onClick={() => this.removeFriend(friend.username)}
                        />
                      )}
                    </Text>
                  </li>
                );
              })}
            </ul>
            <Input
              placeholder="Friend Username"
              value={friend}
              name="friend"
              handleChange={this.handleChange}
            />
            <Button green fontSize="18" onClick={this.addFriend}>
              +
            </Button>
          </FlexWrapper>
          <FlexWrapper col>
            <h4>All Time Data</h4>
            <TableTracking tracking={tracking} />
            <h4>
              Data for {getDateFormat(startDate)} - {getDateFormat(endDate)}
            </h4>
            <InnerWrapper>
              <Input
                placeholder="Start Date"
                value={getDateFormat(startDate)}
                name="startDate"
                handleChange={this.handleChange}
              />
              <Text fontSize="16">&nbsp;&nbsp;thru&nbsp;&nbsp;</Text>
              <Input
                placeholder="End Date"
                value={getDateFormat(endDate)}
                name="endDate"
                handleChange={this.handleChange}
              />
              <Button green fontSize="14" onClick={this.handleSearch}>
                Search
              </Button>
            </InnerWrapper>
            <TableTracking tracking={datedTracking} />
          </FlexWrapper>
        </Wrapper>
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersPage);
