import configure from "./configure";
import stringify from "./query";

const { axios } = configure;

export const verifyEmail = async (email) => {
  const body = { email };
  return axios.post(`/verification-code/send-code`, body).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    }
  );
}

export const verifyCode = async (codeId, code) => {
  const query = stringify({ codeId, code });
  return axios.get(`/verification-code/verify-code${query}`).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    }
  );
}
