import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/report/actions";
import { CheckboxGroup } from "../components/CheckboxGroup";

export const PlansFilter = ({ plansOptions }) => {
  const dispatch = useDispatch();
  const selectedPlan = useSelector((state) => state.report.selectedPlan);

  if (!plansOptions?.length) return null;

  return (
    <CheckboxGroup
      label="Plans"
      options={plansOptions.map((option) => ({ value: option.value, label: option.label }))}
      onChange={(value) => {
        dispatch(
          Actions.Creators.switchState({
            selectedPlan: value,
          }),
        );
      }}
      value={selectedPlan}
      hasSelectionHelpers
    />
  );
};
