import React from "react";
import styled from "styled-components";
import Text from "components/atoms/Text";

const Wrapper = styled.div`
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${(props) => (props.step === 0 ? "none" : " translateX(-100%)")};
  opacity: ${(props) => (props.step === 0 ? 1 : 0)};
  visibility: ${(props) => (props.step === 0 ? "visible" : "hidden")};
  transition: 600ms;
`;

const ReVerifyWorkEmail = ({ step, title }) => {
  return (
    <div style={{ position: "relative" }}>
      <Wrapper step={step}>
        <Text sectionTitle left styles={{ marginBottom: 0 }}>
          {title}
        </Text>
      </Wrapper>
    </div>
  );
};

export default ReVerifyWorkEmail;
