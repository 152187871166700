import React from "react";
import { connect } from "react-redux";
import profileActions from "store/profile/actions";

import { getToken } from "services/utils";
import { getRole } from "services/api/admin.api";

import { PageTemplate, Header, Footer, RecommendationsList } from "components";

class RecommendationsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      report: {
        id: null,
        loaded: false,
      },
      stickyWarning: false,
      backgroundColor: null,
    };

    this.toggleBackgroundColor = this.toggleBackgroundColor.bind(this);
  }

  updateProfile() {
    const navigateToProfile = (id) => {
      this.props.history.push(`/recommendations/${id || ""}`);
    };
    this.props.loadProfile(this.props.profileId, navigateToProfile);
  }

  componentDidMount() {
    document.title = "Your Recommendations - predictaBill";
    (async () => {
      const token = await getToken();
      const response = await getRole(token);
      const isAdmin = response.status === 200;
      this.setState({ isAdmin });
    })();
    this.updateProfile()
  }

  toggleBackgroundColor = (value) => {
    // value - boolean
    this.setState({ backgroundColor: value });
  };

  render() {
    const { profile, history } = this.props;
    const { header, isRequesting, currentReport, isLoaded } = profile;

    return (
      <PageTemplate
        header={
          <>
            <Header
              invert
              secondaryBackground={this.state.backgroundColor}
              V2
            />
          </>
        }
        footer={<Footer />}
        secondaryBackground={this.state.backgroundColor}
      >
        {isLoaded && (
          <RecommendationsList
            header={header}
            stickyWarning={this.state.isAdmin && this.props.profileId}
            isRequesting={isRequesting}
            history={history}
            currentReport={currentReport}
            toggleBackgroundColor={this.toggleBackgroundColor}
            profileId={this.props.profileId}
            forceUpdate={() => this.forceUpdate()}
            username={profile.username}
            isAdmin={this.state.isAdmin}
          />
        )}
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { profile, bills } = state;
  const { profileId } = ownProps.match.params;
  return {
    bills: bills.body,
    user: profile.username,
    icons: profile.icons,
    report: profile.currentReport,
    profile: profile,
    profileId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadProfile: (profileId, navigateToProfile) => {
      dispatch(
        profileActions.Creators.loadProfileRequest(profileId, navigateToProfile)
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecommendationsPage);
