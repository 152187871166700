import React from "react";
import styled from "styled-components";
import { Text } from "components";

const Content = styled.div`
  text-align: center;
  outline: none;
`;

const Link = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: rgb(28, 53, 94);
`;

const FooterQuestion = () => {
  return (
    <Content>
      <Text>
        QUESTIONS? COMMENTS? CONTACT US:{" "}
        <Link href="mailto:HI@PREDICTABILL.COM">HI@PREDICTABILL.COM</Link>
      </Text>
    </Content>
  );
};

export default FooterQuestion;
