import { createReducer } from "reduxsauce";
import Actions from "./actions";
import { merge } from "lodash";
import { customPlanFields, getPremiumUnitToFrequency } from "services/utils";

const { Types } = Actions;

const INITIAL_STATE = {
  birthParent: {
    group: null,
    optionsAmount: null,
    startDate: null,
    extraChildren: {
      data: [
        "No, it's the same cost no matter how many children I have",
        "Yes"
      ],
      value: null
    },
    optOut: {
      isApply: null,
      amount: null,
      frequency: {
        data: [
          "Weekly",
          "Bi-Weekly (26 times per year)",
          "Bi-Monthly (24 times per year)",
          "Monthly",
          "Quarterly",
          "Bi-Annually (2 times per year)",
          "Annually"
        ],
        value: null
      }
    },
    options: []
  },
  partner: {
    group: null,
    optionsAmount: null,
    startDate: null,
    extraChildren: {
      data: [
        "No, it's the same cost no matter how many children I have",
        "Yes"
      ],
      value: null
    },
    optOut: {
      isApply: null,
      amount: null,
      frequency: {
        data: [
          "Weekly",
          "Bi-Weekly (26 times per year)",
          "Bi-Monthly (24 times per year)",
          "Monthly",
          "Quarterly",
          "Bi-Annually (2 times per year)",
          "Annually"
        ],
        value: null
      }
    },
    options: []
  },
  isDue: null,
  dueDate: null,
  firstBaby: null,
  useOutOfNetwork: null
};

const OPTION_INITIAL_STATE = {
  id: null,
  name: null,
  maternityCopayDetails: {
    subjToCopay: false,
    hospital: null,
    unit: null,
    maxUnits: null,
    whoPays: null,
    subjToPercentCoins: false
  },
  deductibles: {
    subjToDeductible: false,
    baby: {
      data: [
        "Only if the baby's hospital stay is longer than the birth parent's",
        "Yes"
      ],
      isUsed: null
    },
    familyType: null,
    crossAccumulatable: null
  },
  inNetwork: {
    individual: {
      deductible: null,
      oopMax: null
    },
    family: {
      deductible: null,
      individualDeductible: null,
      individualOopMax: null,
      oopMax: null
    },
    coinsurance: {
      rate: null
    }
  },
  outOfNetwork: {
    individual: {
      deductible: null,
      oopMax: null
    },
    family: {
      deductible: null,
      individualDeductible: null,
      individualOopMax: null,
      oopMax: null
    },
    coinsurance: {
      rate: null
    }
  },
  charge: {
    frequency: {
      data: [
        "Weekly",
        "Bi-Weekly (26 times per year)",
        "Bi-Monthly (24 times per year)",
        "Monthly",
        "Quarterly",
        "Bi-Annually (2 times per year)",
        "Annually"
      ],
      value: null
    },
    mo: null,
    ms: null,
    mc: null,
    mf: null
  },
  hsa: {
    isApply: null,
    amountIndividual: null,
    amountFamily: null,
    frequency: {
      data: [
        "Bi-Monthly (24 times per year)",
        "Monthly",
        "Bi-Annually (2 times per year)",
        "Annually"
      ],
      value: null
    }
  }
};

const loadSuccess = (
  state = INITIAL_STATE,
  { custom, birthParentPlans, partnerPlans }
) => {
  const {
    birthParent,
    partner,
    useOutOfNetwork,
    isDue,
    dueDate,
    isFirst
  } = custom;

  const birthParentOptions = customPlanFields(
    OPTION_INITIAL_STATE,
    birthParentPlans
  );

  const partnerOptions = customPlanFields(OPTION_INITIAL_STATE, partnerPlans);
  return merge({}, state, {
    birthParent: {
      group: birthParent.company,
      optionsAmount: birthParent.optionsAmount,
      startDate: birthParent.startDate,
      extraChildren: {
        ...state.extraChildren,
        value: false
      },
      optOut: {
        ...state.birthParent.optOut,
        isApply: birthParent.optOut.isApply,
        amount: birthParent.optOut.amount,
        frequency: {
          ...state.birthParent.optOut.frequency,
          value: getPremiumUnitToFrequency(birthParent.optOut.frequency)
        }
      },
      options: birthParentOptions
    },
    partner: {
      group: partner.company,
      optionsAmount: partner.optionsAmount,
      startDate: partner.startDate,
      extraChildren: {
        ...state.extraChildren,
        value: false
      },
      optOut: {
        ...state.partner.optOut,
        isApply: partner.optOut.isApply,
        amount: partner.optOut.amount,
        frequency: {
          ...state.birthParent.optOut.frequency,
          value: getPremiumUnitToFrequency(partner.optOut.frequency)
        }
      },
      options: partnerOptions
    },
    useOutOfNetwork,
    firstBaby: isFirst,
    isDue,
    dueDate
  });
};

const changeCustomOptions = (state = INITIAL_STATE, { name, value }) => {
  const fields = name.split(" ");

  if (fields[fields.length - 1] === "options") {
    if (value === "" || value === state[fields[0]].optionsAmount) return state;
    const newOptions =
      state[fields[0]].optionsAmount > value
        ? state[fields[0]].options.slice(0, value)
        : [
            ...state[fields[0]].options,
            ...new Array(value - state[fields[0]].optionsAmount).fill(
              OPTION_INITIAL_STATE
            )
          ];

    const newState = {
      ...state,
      [fields[0]]: {
        ...state[fields[0]],
        optionsAmount: value,
        options: newOptions
      }
    };

    return newState;
  }

  if (fields[fields.length - 1] === "extraChildren") {
    return merge({}, state, {
      [fields[0]]: {
        ...[fields[0]],
        extraChildren: {
          ...[fields[0]].extraChildren,
          value: value
        }
      }
    });
  }

  if (fields.length === 2) {
    return merge({}, state, {
      [fields[0]]: {
        ...[fields[0]],
        [fields[1]]: value
      }
    });
  }

  if (fields[fields.length - 1] === "frequency") {
    return merge({}, state, {
      [fields[0]]: {
        ...[fields[0]],
        optOut: {
          ...[fields[0]].optOut,
          [fields[2]]: {
            ...[fields[1]].frequency,
            value: value
          }
        }
      }
    });
  }

  if (fields.length === 3) {
    return merge({}, state, {
      [fields[0]]: {
        ...[fields[0]],
        optOut: {
          ...[fields[0]].optOut,
          [fields[2]]: value
        }
      }
    });
  }

  if (name === "isDue" && value === false) {
    return merge({}, state, {
      [name]: value,
      dueDate: null
    });
  }

  return merge({}, state, {
    [name]: value
  });
};

const changeCustomPlan = (state = INITIAL_STATE, { name, value }) => {
  const fields = name.split(" ");
  const parent = fields[0];
  const idx = parseInt(fields[1]);
  const field = fields[fields.length - 1];

  if (fields.length === 4) {
    return merge({}, state, {
      [parent]: {
        ...[parent],
        options: state[parent].options.map((option, i) => {
          if (i === idx) {
            return {
              ...option,
              [fields[2]]: {
                ...option[fields[2]],
                [field]: value
              }
            };
          } else {
            return { ...option };
          }
        })
      }
    });
  }

  if (fields.length === 5) {
    return merge({}, state, {
      [parent]: {
        ...[parent],
        options: state[parent].options.map((option, i) => {
          if (i === idx) {
            return {
              ...option,
              [fields[2]]: {
                ...option[fields[2]],
                [fields[3]]: {
                  ...option[fields[3]],
                  [field]: value
                }
              }
            };
          } else {
            return { ...option };
          }
        })
      }
    });
  }

  return merge({}, state, {
    [parent]: {
      ...[parent],
      options: state[parent].options.map((option, i) =>
        i === idx ? { ...option, [field]: value } : { ...option }
      )
    }
  });
};

const updateCustomPlanIds = (state = INITIAL_STATE, { parent, ids }) => {
  return merge({}, state, {
    [parent]: {
      ...[parent],
      options: state[parent].options.map((option, i) => {
        return { ...option, id: ids[i] };
      })
    }
  });
};

const reset = (state = INITIAL_STATE) => {
  return INITIAL_STATE;
};

export const HANDLERS = {
  [Types.CHANGE_CUSTOM_OPTIONS]: changeCustomOptions,
  [Types.CHANGE_CUSTOM_PLAN]: changeCustomPlan,
  [Types.UPDATE_CUSTOM_PLAN_IDS]: updateCustomPlanIds,
  [Types.LOAD_CUSTOM_SUCCESS]: loadSuccess,
  [Types.RESET_STORE_CUSTOM]: reset
};

const reducer = createReducer(INITIAL_STATE, HANDLERS);

export default reducer;
