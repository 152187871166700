import { connect } from "react-redux";
import CalculatePremiumPlan from "../components/organisms/CalculatePremiumPlan/index";
import Action from "store/plan/actions";
import { plansBaby } from "store/formData";

const find = (value, plans, name) => {
  const data = plans.filter(plan => plan[name] === value);
  return data || [];
};

const mapStateToProps = state => {
  const plan = state.plan;
  const { currentReport } = state.profile;
  return {
    groups: plan.groups,
    plans: plan.insurancePlans,
    plansNetwork: plan.plansNetwork,
    insurance: plan.insurance,
    dueDate: plan.dueDate,
    plansBaby: find(plan.insurance.firstBaby, plansBaby, "isFirst"),
    broaderNetwork: plan.insurance.broaderNetwork,
    tiers: plan.tiers,
    partnerTiers: plan.partnerTiers,
    surcharges: plan.surcharges,
    partnerSurcharges: plan.partnerSurcharges,
    currentReport,
    ...plan
  };
};

const mapDispatchToProps = dispatch => ({
  change: (name, value) => {
    dispatch(Action.Creators.changeInsurancePlan(name, value));
  },
  changePremium: (name, value) => {
    dispatch(Action.Creators.changePremiumPlan(name, value));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculatePremiumPlan);
