import React from "react";
import { useFormikContext } from "formik";
import FormField from "components/molecules/FormField";
import SelectField from "components/molecules/SelectField";
import { surchargeWaiverFrequencyOptions } from "../utils/options";
import CheckboxField from "components/molecules/CheckboxField";
import { formatterNumber, parserNumber } from "utils/currency";
import { SpaceDivider } from "./utils";

export const CheckboxWithSpouseSurcharge = () => {
  const { values } = useFormikContext();
  const { withSpouseSurcharge } = values;

  return (
    <>
      <CheckboxField
        name="withSpouseSurcharge"
        label="There is a surcharge for a spouse to join these plans if they have their own options"
      />

      {withSpouseSurcharge && (
        <>
          <FormField
            name="spousalSurcharge"
            type="number"
            label="Spouse surcharge value"
            defaultValue="$"
            formatter={formatterNumber}
            parser={parserNumber}
            required
          />
          <SelectField
            name="spousalSurchargeFrequency"
            options={surchargeWaiverFrequencyOptions}
            label="Frequency"
            defaultValue={1}
          />
          <SpaceDivider />
        </>
      )}
    </>
  );
};
