import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  loginRequest: ["email", "password", "destinationRoute"],
  loginSuccess: ["user", "token"],
  loginFailure: ["error"],

  registerRequest: ["form", "destinationRoute"],
  registerSuccess: ["user", "token"],
  registerFailure: ["error"],

  resetPasswordRequest: ["password", "resetToken"],
  resetPasswordFailure: ["error"],

  loadRequest: null,
  loadSuccess: ["token"],
  loadFailure: ["error"],

  getUserByToken: ["resetToken"],

  removeToken: null,
  logout: [null],
  clearAuthError: [],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
