import React from "react";
import styled from "styled-components";
import { Title, Form } from "components";
import { Collapse } from "antd";

const { Panel } = Collapse;

const width = ({ size }) => (size && size === "big" ? `500px` : size === "x-big" ? "1000px": "310px");
const height = ({ height }) =>
  height && height === "x-small"
    ? "150px"
    : height === "small"
    ? "160px"
    : height === "sm-md"
    ? "280px"
    : height === "md"
    ? "370px"
    : "550px";
const borderRadius = ({ size }) => (size && size === "big" ? `40px` : "25px");

const Wrapper = styled.section`
  ${({ blue }) => blue && `background: rgb(217,243,241);`}
  border: 1px solid #9a9a9a;
  ${({borderShadow}) => borderShadow && `box-shadow: 0px 3px 20px -1px rgba(0,0,0,0.21);`};
  
  ${({ noBorder }) =>
    noBorder ? `border: none;` : `border: 1px solid #9a9a9a;`}
  
  border-radius: ${borderRadius};
  margin: 10px 12px;
  // height: ${height}
  width: ${width};
  min-width: ${width};

  min-height: ${height};

  @media (max-width: 580px) {
    width: 310px;
    min-width: 310px;
    margin: 10px auto;
    min-height: auto;
  }
`;

const collapseStyle = {
  borderRadius: borderRadius,
  background: "transparent",
};

const panelStyle = {
  borderBottom: "none",
};

const InnerWrapper = styled.div`
`

const Header = styled.header`
  padding: 20px 25px;
  height: ${({ height }) => (height ? height : "20px")};
  text-align: center;
  vertical-align: middle;
  font-weight: bolder;
  margin-top: -15px;
  margin-bottom: 10px;
`;

const Main = styled.div`
  padding: 25px;
  ${({padding}) => padding && `padding: ${padding}`}
  justify-content: space-between;
  ${({ blue }) => (!blue ? `margin-top: 10px;` : `margin-top: 8px;`)}
`;

const CalculateFormTemplate = ({
  title,
  children,
  size,
  blue,
  height,
  headerHeight,
  collapsible,
  activeKey,
  noBorder,
  borderShadow,
  hasTwoChildren,
  footer,
  padding
}) => {

  const header = (
    <Header height={headerHeight}>
      <Title blue={blue}>{title}</Title>
    </Header>
  );

  return (
    <Wrapper size={size} blue={blue} height={height} borderShadow={borderShadow} noBorder={noBorder}>
      {collapsible ? (
        <Collapse
          bordered={false}
          expandIconPosition="right"
          defaultActiveKey={["1"]}
          style={collapseStyle}
        >
          <Panel style={panelStyle} header={header} key={activeKey}>
            <Form>
              <Main blue={blue}>{children}</Main>
            </Form>
          </Panel>
        </Collapse>
      ) : 
      hasTwoChildren ? (
        <InnerWrapper>
          <Header height={headerHeight}>
            <Title card blue={blue}>{title[0]}</Title>
          </Header>
          <Form>
            <Main padding={padding} blue={blue}>{children[0]}</Main>
          </Form>
          <Header height={headerHeight}>
            <Title card blue={blue}>{title[1]}</Title>
          </Header>
          <Form>
            <Main padding={padding} blue={blue}>{children[1]}</Main>
          </Form>
        </InnerWrapper>
      ):(
        <InnerWrapper>
          <Header height={headerHeight}>
            <Title card blue={blue}>{title}</Title>
          </Header>
          <Form>
            <Main padding={padding} blue={blue}>{children}</Main>
          </Form>
          {
            footer && 
            footer()
          }
        </InnerWrapper>
      )}
    </Wrapper>
  );
};

export default CalculateFormTemplate;
