import React, { useState, useEffect } from "react";
import { PageTemplate, Header } from "components";
import { AddNetworksStepper as Stepper, AddNetworksSteps as Steps, AddNetworksFooter as Footer } from "components";
import { Formik } from "formik";
import { Form } from "formik-antd";
import initialValues from "./utils/initialValues";
import { withFormikDevtools } from "formik-devtools-extension";
import { message } from "antd";
import { getToken, getUserId } from "services/utils";
import FormikPersist from "./utils/formikPersist";
import { useHistory, useLocation } from "react-router-dom";
import { createNetworksGrid, updateNetworksGrid, addGroupToUser } from "services/api/";
import { createUntitledGroup, updateGroup } from "services/api/groups.api";
import { formatValues } from "./utils/format";
import { loadValues } from "./utils/loadValues";

const AddNetworksPage = () => {
  const history = useHistory();
  const { state } = useLocation();
  const [formikInitialValues, setFormikInitialValues] = useState(initialValues);

  const gridId = state?.gridId;
  const reportId = state?.reportId;
  const isEditing = window.location.pathname.includes("edit");

  let token = {};

  const getValuesFromDatabase = async () => {
    token = await getToken();
    setFormikInitialValues(
      await loadValues({
        gridId,
        reportId,
        token,
      }),
    );
  };

  useEffect(() => {
    (gridId || reportId) && !localStorage.getItem("networksForm") && getValuesFromDatabase();
  }, []);

  const handleNetworksGridResult = ({ status, data }) => {
    if (status === 200) {
      message.success("Success!");
      const { grid } = data;
      localStorage.removeItem("networksForm");
      history.push(`/networks/${grid._id}`);
    } else {
      message.error("Something happened, please try again later.");
    }
  };

  return (
    <PageTemplate isContentHover padding="0 12.5%" header={<Header V2 />}>
      <Formik
        initialValues={formikInitialValues}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setSubmitting(false);
            if (gridId && isEditing) {
              const { status, data } = await updateNetworksGrid({
                id: gridId,
                form: formatValues(values),
              });
              handleNetworksGridResult({ status, data });
            } else if (reportId && isEditing) {
              // todo: update report
            } else {
              const { status, data } = await createNetworksGrid({
                form: formatValues(values, gridId),
              });
              if (!gridId) {
                const { status: untitledGroupStatus, data: untitledGroupData } = await createUntitledGroup();
                if (status === 200 && untitledGroupStatus === 200) {
                  const untitledGroup = untitledGroupData.group;
                  await updateGroup({
                    originalName: untitledGroup.name,
                    form: { ...untitledGroup, masterNetworkGrids: [data.grid._id] },
                  });
                  localStorage.removeItem("networksForm");
                  const userId = await getUserId();
                  if (userId) {
                    await addGroupToUser({
                      userId: userId,
                      group: untitledGroup.name,
                    });
                  }
                  history.push(`networks/${data.grid._id}`);
                }
              } else {
                history.push(`/my-plans`);
              }
            }
          } catch (e) {
            console.error(e);
          }
        }}
      >
        {(formikProps) => {
          withFormikDevtools(formikProps);
          return (
            <Form>
              <Stepper />
              <Steps />
              <Footer isSubmitting={formikProps.isSubmitting} />
              <FormikPersist name="networksForm" />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
};

export default AddNetworksPage;
