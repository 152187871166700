import React, { useMemo } from "react";
import styled from "styled-components";
import { Link, PrimaryNavigation, LinkButton } from "components";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/auth/actions";
import history from "../../../store/history";

const Wrapper = styled.div`
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  z-index: 2px;
  margin: 5px;

  @media (max-width: 1100px) {
    justify-content: flex-end;
  }
`;

const StyledNav = styled.div`
  flex-direction: row;

  &.big {
    @media (max-width: 580px) {
      display: none;
    }
  }

  &.small {
    @media (min-width: 581px) {
      display: none;
    }
  }
`;

const List = styled.ul`
  align-self: flex-start;
  display: inline-flex;
  flex-direction: row;
  list-style: none;
  margin: auto;
`;

const StyledLink = styled(Link)`
  line-height: 1em;
  width: fit-content;
  white-space: nowrap;
`;

const SecondaryNavigator = styled.nav`
  @media (max-width: 1100px) {
    display: none;
  }
`;

const NavigationOptions = [
  // {name: 'Pricing', path: 'http://predictabill.com/pricing'},
];

const NewNavigation = (props) => {
  const isAuth = useSelector((state) => state.auth.isAuth);

  const { pathname } = history.location;

  const isAuthenticationForm = useMemo(() => {
    return (
      pathname === "/signin" ||
      pathname === "/signup" ||
      pathname === "/forgot-password" ||
      pathname.split("/")[1] === "reset-password"
    );
  }, [pathname]);

  return (
    <Wrapper>
      <SecondaryNavigator>
        <List>
          {NavigationOptions.map((nav) => (
            <li>
              <StyledLink circular fontSize={16} bold href={nav.path}>
                {nav.name}
              </StyledLink>
            </li>
          ))}
        </List>
      </SecondaryNavigator>
      <StyledNav className="big">
        {isAuth ? (
          <>
            <PrimaryNavigation />
          </>
        ) : (
          <>
            {!isAuthenticationForm && (
              <StyledLink circular fontSize={16} bold to={"/signin"}>
                {"Sign In"}
              </StyledLink>
            )}
            {isAuthenticationForm ? (
              pathname === "/signup" ? (
                <LinkButton link={"/signin"}>Sign In</LinkButton>
              ) : (
                <LinkButton link={"/groups"}>Sign Up</LinkButton>
              )
            ) : (
              <LinkButton
                link={"https://predictabill.typeform.com/to/BtEZdk5l"}
              >
                Get Started
              </LinkButton>
            )}
          </>
        )}
      </StyledNav>
      <StyledNav className="small">
        <PrimaryNavigation />
      </StyledNav>
    </Wrapper>
  );
};

export default NewNavigation;
