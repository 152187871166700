import React from "react";
import styled from "styled-components";

import { IconLink, Block, PrimaryNavigation } from "components";

const Wrapper = styled(Block)`
  display: flex;
  padding: 0 90px 0 38px;

  @media (max-width: 1800px) {
    padding: 0 50px 0 20px;
  }

  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  ${({ invert }) =>
    invert &&
    `
    background-color: #fff;
  `}

  ${({ secondaryBackground }) => secondaryBackground && `background-color: #d9f3f1;`}
`;

const InnerWrapper = styled.div`
  padding-top: 13px;
  padding-bottom: 6px;
  padding-right: 90px;
  padding-left: 3%;

  @media (max-width: 950px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 480px) {
    display: flex;
    padding-top: 7px;
  }
`;

const Header = (props) => {
  return (
    <Wrapper opaque reverse {...props}>
      <InnerWrapper>
        <IconLink href={props.href} to={!props.href && "/recommendations"} noMargin logo height={31} {...props} />
      </InnerWrapper>
      <PrimaryNavigation tryIt={props.tryIt} destinationRoute={props.destinationRoute} {...props} />
    </Wrapper>
  );
};

export default Header;
