
import React, { useState, createRef, useMemo } from "react";
import JSONTree from 'react-json-tree';
import { uploadEditPlansCSV, getPlansTemplateForEdit, updatePlans } from "services/api/admin.api";
import { getToken } from "services/utils";
import {
  Button,
  Card,
  Col,
  Row,
  Tabs,
  message,
} from "antd";

import PreviewResultsPlansCSV from "../PreviewResultsPlansCSV";
import styled from "styled-components";
import { ErrorsFeedback, Spinner, SearchPlans } from 'components'
import UploadCSVPlans from "components/molecules/UploadCSVPlans";
import { planTypes } from "enums";

const UpdatePlansPanel = () => {
  const downloadLink = createRef()

  const [type, setType] = useState(planTypes.private);
  const [errorsList, setErrorsList] = useState();
  const [plansValues, setPlansValues] = useState();
  const [plansPreview, setPlansPreview] = useState();
  const [hasErrors, setHasErrors] = useState(false);
  const [differences, setDifferences] = useState();

  const onSubmitSearch = async ({ searchBy, searchTerm }) => {
    try {
      const token = await getToken();
      const response = await getPlansTemplateForEdit({searchBy, searchTerm}, false, token)

      const { status, data } = response;
      if (status === 200) {
        console.log("data", data);
        var myblob = new Blob([data], {
          type: 'text/plain'
        });
        const href = window.URL.createObjectURL(myblob)

        downloadLink.current.download = `file.csv`
        downloadLink.current.href = href
        downloadLink.current.click()
      } else {
        message.error("Plans not found");
      }
    } catch(e){

    }
  };

  const onSubmit =  (event) => {
    setPlansPreview()
    setPlansValues()
    setDifferences()
    setErrorsList()    
    setHasErrors(false)
  }

  const onUploadSuccess = (response) => {
    const {errors, plans,numberOfErrors, differences} = response.data
    if(errors.length === 0 && plans.length === 0) {message.warning("Plans not found or with no differences")}
    setErrorsList(errors)
    const preview = {}
    const values = plans.map((val) => {
      preview[`Row: ${val.line} - ID: ${val.plan.externalId}`] = val.plan
      return val.plan
    })
    console.log(preview)
    setPlansPreview(preview)
    setPlansValues(values)
    setHasErrors(numberOfErrors>0)
    setDifferences(differences)
  }

  const sendValues = async () => {
    try{  
      const token = await getToken()
      const response = await updatePlans(type===planTypes.public, plansValues, token)
      if(response.status === 200){
        message.success("Plans Updated Successfully")
      }
      else message.error(response.message)
    } catch(e){
    }
  }

  const onChangeType = (e) => {
    e.preventDefault()
    setType(e.target.value)
    setDifferences()
    setPlansPreview()
    setPlansValues()
    setHasErrors(false)
    setErrorsList() 
  }

  return (
    <Card title="Edit plans">
      <a ref={downloadLink}></a>
      <SearchPlans onSubmit={onSubmitSearch}/>
      <UploadCSVPlans  
        onSubmit={onSubmit}
        uploadFunction={uploadEditPlansCSV}
        onSuccess={onUploadSuccess} 
        onChangeType={onChangeType}
        type={type}
        />
      <PreviewResultsPlansCSV
        errorsList={errorsList}
        hasErrors={hasErrors}
        plansPreview={plansPreview}
        submitDisabled={hasErrors || !plansValues || !plansValues.length}
        differences={differences}
        onSubmit={sendValues}  
      />
    </Card>
  );
};

export default UpdatePlansPanel;


