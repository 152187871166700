import React, { useState, createRef, useMemo, useRef } from "react";

import { getToken } from "services/utils";
import styled from "styled-components";
import { Spinner } from "components";
import { Button, message } from "antd";
import { planTypes } from "enums";

const SelectorDiv = styled.div`
  max-width: 150px;
  margin: 10px;
`;

const Form = styled.form`
  margin: 50px 10px;
  display: flex;
  flex-direction: column;
`;

const SubmitButton = styled(Button)`
  max-width: 150px;
  margin: 10px;
`;

const UploadCSVPlans = ({ onSuccess, onChangeType, onSubmit, uploadFunction, type }) => {
  const [uploadFile, setUploadFile] = useState();
  const [loading, setLoading] = useState(false);

  const inputFile = useRef();

  const onInsertFile = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setUploadFile(null);
      return;
    }
    if (file.type !== "text/csv") {
      message.error("Not CSV file format");
      inputFile.current.value = "";
      setUploadFile(null);
    } else {
      setUploadFile(file);
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();
    setLoading(true);
    onSubmit();
    const formData = new FormData();
    const token = await getToken();
    formData.append("file", uploadFile);
    formData.set("type", type);
    try {
      const response = await uploadFunction(formData, token);
      if (response.status === 200) {
        onSuccess(response);
      } else {
        message.error(response.message);
      }
    } catch (e) {
      let errorMessage = e.response ? e.response.data : e.message;
      message.error(errorMessage);
    }
    setLoading(false);
  };

  return (
    <Form onSubmit={submitForm}>
      <SelectorDiv>
        <label htmlFor="typeOfPlan">Select Type of Plan</label>
        <select defaultValue={type} onChange={onChangeType} name="typeOfPlan" id="typeOfPlan">
          <option value={planTypes.private}>Private</option>
          <option value={planTypes.public}>Public</option>
        </select>
      </SelectorDiv>
      <label htmlFor="file">Upload File</label>
      <input ref={inputFile} type="file" onChange={onInsertFile} />
      <SubmitButton type="primary" htmlType="submit" disabled={!uploadFile}>
        Upload
      </SubmitButton>
      {loading && <Spinner />}
    </Form>
  );
};

export default UploadCSVPlans;
