import joi from "joi";

const premiumFormSendSchema = joi
  .object({
    billIds: joi
      .array()
      .items(joi.string())
      .min(1)
      .required(),
    dueDate: joi.string().required(),
    name: joi.string().required(),
    partnerName: joi.string().required(),
    isFirst: joi.bool().required(),
    parentsPlan: joi.string().required(),
    familyPlan: joi
      .string()
      .allow(null)
      .required(),

    insurancePlanId: joi
      .string()
      .allow(null)
      .required(),
    premiumTier: joi.number().required(),
    surcharges: joi.array().required(),
    hsaTier: joi.number().required(),

    partnerInsurancePlanId: joi
      .string()
      .allow(null)
      .required(),
    partnerPremiumTier: joi.number().required(),
    partnerSurcharges: joi.array().required(),
    partnerHsaTier: joi.number().required()
  })
  .unknown()
  .required();

export default premiumFormSendSchema;
