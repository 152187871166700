import React from "react";
import ExpectedTable from "./expectedTable"

const Start = () => {
  return (
    <>
      <ExpectedTable />
    </>
  );
}

export default Start