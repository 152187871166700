import styled from "styled-components";

const background = ({ color }) =>
  color === "grey"
    ? "background: #e4e4e46b;"
    : color === "green"
    ? "background:rgb(0,161,155);  color: #fff;"
    : color === "white"
    ? "background:#fff"
    : `background : ${color};`;

const TableRow = styled.tr`
  ${background};
  ${({lineHeight}) => lineHeight && `line-height: ${lineHeight}`};
  ${({fontSize}) => `font-size: ${fontSize ?? 16}px`};
  ${({height}) => `height: ${height ?? 20}px`};
  ${({padding}) => padding && `padding: ${padding}`};
  ${({border}) => border && `border-top: 1px solid #e4e4e46b`}
  ${({borderBottom}) => borderBottom && `border-bottom: 2px solid #e4e4e46b`}
  ${({ header }) => header && `font-weight: 800; color: #111`}
  ${({ bottom }) => bottom && `border-bottom: 1.5pt solid #54565a;`}
  ${({ bold }) => bold && `font-weight: bold`}
`;
export default TableRow;
