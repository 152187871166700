import React from "react";
import styled from "styled-components";
import { Text } from "components";
import { Icon } from "@ant-design/compatible";

const Wrapper = styled.div`
  color: #123;
  width: 50vw;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 480px) {
    width: 100%;
    margin: 25px auto 0 auto;
  }
`;

const InnerWrapper = styled.div`
  // width: ${({ big }) => (big ? "825px" : "520px")};
  text-align: center;
  margin-top: 2px;
  margin-bottom: 8px;
  padding: 0 12px;
  width: 100vw;

  @media (max-width: 480px) {
    padding: 0;
    width: 100%;
  }
`;

const Title = styled.h1`
  color: #21355B;
  text-align: center;
  font-size: ${({ bigTitle }) => (bigTitle ? "2.5em" : "1.2em")};
  ${({ bigTitle }) => bigTitle && `font-size: 2.5em;`};
  line-height: 1.4em;
  font-weight: 900;
  margin: 5px 35px;

  @media (max-width: 1300px) {
    line-height: 1.5em;
  }

  @media (max-width: 480px) {
    font-size: 1.2em;
    padding: 1px 2px;
  }
`;

const InnerText = styled.span`
  color: rgb(15, 162, 157);
`;
const StyledText = styled(Text)`
  font-size: 15px;
  line-height: 20px;
`;
const HeaderCalculate = ({
  title,
  greenText,
  text,
  children,
  big,
  bigTitle,
  icon,
}) => {
  return (
    <Wrapper>
      <Title bigTitle={bigTitle}>
        {icon && <Icon type={icon} />} {title}
      </Title>
      <InnerWrapper big={big}>
        <StyledText lg={big}>
          <InnerText green md>
            {greenText}
          </InnerText>{" "}
          {text}
          {children || ""}
        </StyledText>
      </InnerWrapper>
    </Wrapper>
  );
};

export default HeaderCalculate;
