import { orderBy, remove, slice, findIndex } from "lodash";

export const sortByColumn = (items, count, column) => {
  const newData = remove(items, ({ order }) => typeof order == "number");
  const data = slice(newData, 0, count);
  const sortItems = orderBy(data, [column], ["asc"]);
  return sortItems;
};

export const sortByHeader = (headers, data) => {
  const arrayIndex = [];
  data[0].forEach((item, index) => {
    headers.forEach(({ title, order }) => {
      if (title === item.header) {
        arrayIndex.push({ index, order });
      }
    });
  });

  const sortItems = data.map(items => {
    const newItemsRemove = remove(
      items,
      (item, index) => findIndex(arrayIndex, { index }) > -1
    );
    const newItems = newItemsRemove.map((item, index) => {
      const indexHeader = arrayIndex[index].order - 1;
      item.contentFormat = headers[indexHeader].styles.contentFormat;
      item.contentAlignment = headers[indexHeader].styles.contentAlignment;
      item.order = headers[indexHeader].order;
      return item;
    });
    return orderBy(newItems, ["order"], ["asc"]);
  });

  return sortItems;
};
