import React from "react";
import { Text } from "components";
import styled from "styled-components";

const ErrorText = styled(Text)`
  color: #fff;
  font-size: 14px;
`;

const Wrapper = styled.div`
  background: #f0523d;
  min-height: 3px;
  padding: 7px 10px;
`;

const ErrorBar = ({ message, isShow }) => (
  <>
    {isShow && (
      <Wrapper>
        <ErrorText>{message}</ErrorText>
      </Wrapper>
    )}
  </>
);

export default ErrorBar;
