import { stringify } from "query-string";

export default function getFullQuery(params) {
  const data = removeWithValue(params, "all");
  const query = stringify(data, { arrayFormat: "comma" });
  return "?" + query;
}

const removeWithValue = (data, value) => {
  Object.keys(data).forEach(key =>
    data[key] === value || data[key] === "" || data[key] === null
      ? delete data[key]
      : data[key]
  );
  return data;
};
