import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import FormField from "components/molecules/FormField";
import Text from "components/atoms/Text";
import { Space } from "antd";
import SelectField from "components/molecules/SelectField";
import { TableRows } from "../utils/table";
import { frequencyOptions, networkOptions } from "../utils/options";
import TableField from "components/molecules/TableField";
import styled from "styled-components";

const PremiumsTitlesWrapper = styled.div`
  @media (max-width: 768px) {
    padding: 28px;
  }
`;

const filterColumns = (insuranceFor, pregnancyResponse, costFor) => {
  if (insuranceFor === "Just me!") {
    if (pregnancyResponse?.pregnancyAndFertilityScenarios?.includes("Pregnancy")) {
      return ["me only", "me and child(ren)"];
    }
    return ["me only"];
  }
  if (insuranceFor === "Myself and my spouse/partner") {
    if (pregnancyResponse?.pregnancyAndFertilityScenarios?.includes("Pregnancy")) {
      if (
        pregnancyResponse?.partnerHasSameEmployer === "Yes" ||
        pregnancyResponse?.partnerEmployerOffersInsurance === "Yes"
      ) {
        return ["me only", "me and spouse", "me and child(ren)", "me, spouse and child(ren)"];
      }
      return ["me and spouse", "me, spouse and child(ren)"];
    }
    if (
      pregnancyResponse?.partnerHasSameEmployer === "Yes" ||
      pregnancyResponse?.partnerEmployerOffersInsurance === "Yes"
    ) {
      return ["me only", "me and spouse"];
    }
    return ["me and spouse"];
  }
  if (insuranceFor === "Myself and my family") {
    if (
      pregnancyResponse?.insuranceCoversPartner === "Yes" &&
      pregnancyResponse?.haveChildrenUnderTwentySix === "Yes"
    ) {
      if (
        pregnancyResponse?.partnerHasSameEmployer === "Yes" ||
        pregnancyResponse?.partnerEmployerOffersInsurance === "Yes"
      ) {
        return ["me only", "me and child(ren)", "me, spouse and child(ren)"];
      }
      return ["me, spouse and child(ren)"];
    }
    if (pregnancyResponse?.insuranceCoversPartner === "Yes") return ["me only", "me and spouse"];
    if (pregnancyResponse?.haveChildrenUnderTwentySix === "Yes") return ["me and child(ren)"];
  }
  if (costFor === "mo") {
    if (pregnancyResponse?.pregnancyAndFertilityScenarios?.includes("Pregnancy")) {
      return ["me only", "me and child(ren)"];
    }
    return ["me only"];
  }
  if (costFor === "ms") {
    return ["me only", "me and spouse"];
  }
  if (costFor === "mc") {
    return ["me only", "me and child(ren)"];
  }
  if (costFor === "mf") {
    return ["me only", "me and spouse", "me and child(ren)", "me, spouse and child(ren)"];
  }
  return ["me only", "me and spouse", "me and child(ren)", "me, spouse and child(ren)"];
};

const Premiums = ({}) => {
  const { values, setFieldValue, setErrors, setTouched } = useFormikContext();
  const { planOptions, table, costFor, selectedPremiumTier } = values || {};
  const { insuranceFor } =
    (localStorage.getItem("userTypeformResponse") && JSON.parse(localStorage.getItem("userTypeformResponse"))) || {};
  const pregnancyResponse =
    (localStorage.getItem("pregnancyTypeformResponse") &&
      JSON.parse(localStorage.getItem("pregnancyTypeformResponse"))) ||
    {};

  const columns = [
    {
      title: "",
      dataIndex: "idx",
      width: 20,
      editable: false,
      fixed: "left",
    },
    {
      title: "Label",
      dataIndex: "label",
      width: 133,
      editable: true,
      inputType: "text",
      shouldCellUpdate: () => false,
      fixed: "left",
      disabled: true,
    },
    {
      title: "Insurance Network",
      dataIndex: "insuranceNetwork",
      className: "insuranceNetwork-col",
      width: 200,
      editable: true,
      inputType: "autocomplete",
      dropdownMatchSelectWidth: 350,
      options: networkOptions,
      shouldCellUpdate: () => false,
      disabled: true,
    },
    {
      title: "me only",
      dataIndex: `tiers[${selectedPremiumTier}].mo`,
      width: 110,
      editable: true,
      inputType: "currency",
      min: Number.MIN_SAFE_INTEGER,
      shouldCellUpdate: () => false,
    },
    {
      title: "me and spouse",
      dataIndex: `tiers[${selectedPremiumTier}].ms`,
      width: 110,
      editable: true,
      inputType: "currency",
      min: Number.MIN_SAFE_INTEGER,
      shouldCellUpdate: () => false,
    },
    {
      title: "me and child(ren)",
      dataIndex: `tiers[${selectedPremiumTier}].mc`,
      width: 110,
      editable: true,
      inputType: "currency",
      min: Number.MIN_SAFE_INTEGER,
      shouldCellUpdate: () => false,
    },
    {
      title: "me, spouse and child(ren)",
      dataIndex: `tiers[${selectedPremiumTier}].mf`,
      width: 110,
      editable: true,
      inputType: "currency",
      min: Number.MIN_SAFE_INTEGER,
      shouldCellUpdate: () => false,
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 1,
    },
  ].map((col) => {
    return {
      ...col,
      onCell: (_, idx) => ({
        index: idx,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: col.inputType,
        options: col.options,
        dropdownMatchSelectWidth: col.dropdownMatchSelectWidth,
        min: col.min,
        disabled: col.disabled || false,
      }),
    };
  });

  const data = table.slice(0, planOptions);

  useEffect(() => {
    document.getElementById("form-content").scrollTo({ top: 0, behavior: "smooth" });
    setErrors({});
    setTouched({}, false);
  }, []);

  return (
    <>
      <PremiumsTitlesWrapper>
        <Text sectionTitle>How much do each of these plans cost for you to join?</Text>
        <Text sectionSubTitle>
          Please enter the amount that would be deducted from your paycheck if you joined the plan option.
        </Text>
        <br />
        <SelectField name="frequency" options={frequencyOptions} label={`Cost shown`} />
        <TableField
          name="table"
          columns={columns.filter((col) =>
            (["", "Label", "Insurance Network"] + filterColumns(insuranceFor, pregnancyResponse, costFor)).includes(
              col.title,
            ),
          )}
          dataSource={data}
          pageSize={10}
          tableLayout="fixed"
        />
      </PremiumsTitlesWrapper>

      {/* <Space direction="vertical" size="middle" style={{ display: "flex" }}>
      </Space> */}
    </>
  );
};

export default Premiums;
