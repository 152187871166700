import React from "react";
import { Popconfirm } from "antd";
import { Text } from "components";
import {
  SPACE_BEFORE_TABLES,
  SPACE_BETWEEN_TABLES,
  SPACE_CELL_TABLES,
  StyledCell,
} from "components/organisms/AllMyOptions";
import { useDispatch } from "react-redux";
import Actions from "store/plan/actions";
import { useHistory } from "react-router-dom";
import { checkColumnIsFiltered } from "../Body";

const handleEditPlans = (report, plansOwner, history, activeTab, groupObj) => {
  window.localStorage.removeItem("form");
  history.push(`/edit-plan`, {
    reportId: report._id,
    returnToDetailsPage: true,
    activeTab: activeTab,
    option: plansOwner,
    groupObj: groupObj || null,
    year: report.year || null,
  });
};

const handleEditPremiums = (report, plansOwner, history) => {
  window.localStorage.removeItem("form");
  history.push("/add-premiums", {
    reportId: report._id,
    option: plansOwner,
    isEditing: true,
  });
};

export const getNonFilteredColumnLength = (columnHeaders, preference, joinType) => {
  return columnHeaders.filter((column) => {
    return !checkColumnIsFiltered({ name: column.name }, preference, joinType);
  }).length;
};

const TableTitle = ({
  columnHeadersBP,
  columnHeadersPartner,
  birthParent,
  partner,
  report,
  activeTab,
  preference,
  groupObj,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const birthParentHeadersLength = getNonFilteredColumnLength(columnHeadersBP, preference, "birthParent");
  const partnerHeadersLength = getNonFilteredColumnLength(columnHeadersPartner, preference, "partner");

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row",
        flexWrap: "nowrap",
        transition: "all 0.3s ease",
        width: "max-content",
        justify: `${birthParentHeadersLength + partnerHeadersLength > 7 ? "start" : ""}`,
      }}
    >
      <StyledCell customWidth={`${SPACE_BEFORE_TABLES}px`} />
      <StyledCell
        hide={!birthParentHeadersLength}
        recommended
        customWidth={`${birthParentHeadersLength * SPACE_CELL_TABLES}px`}
        customBg="#F2F4F7"
      >
        {report?.birthParent.canEditPlans ? (
          <Popconfirm
            title="Would you like to edit these plans?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              dispatch(Actions.Creators.changeCurrent(0));
              handleEditPlans(report, "birthParent", history, activeTab, groupObj);
            }}
          >
            <Text
              title={birthParent.company}
              uppercase
              blue
              bold
              fontSize="20"
              hover
              cursor="pointer"
              customLSpacing="0"
            >
              {birthParent.company && birthParent.company.length > 25
                ? `${String(birthParent.company).substring(0, 22)}...`
                : birthParent.company}
            </Text>
          </Popconfirm>
        ) : report?.birthParent.canEditPremiums ? (
          <Popconfirm
            title="Would you like to edit these premiums?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              dispatch(Actions.Creators.changeCurrent(0));
              handleEditPremiums(report, "birthParent", history);
            }}
          >
            <Text
              title={birthParent.company}
              uppercase
              blue
              bold
              fontSize="20"
              hover
              cursor="pointer"
              customLSpacing="0"
            >
              {birthParent.company && birthParent.company.length > 25
                ? `${String(birthParent.company).substring(0, 22)}...`
                : birthParent.company}
            </Text>
          </Popconfirm>
        ) : (
          <Text title={birthParent.company} uppercase blue bold fontSize="20" customLSpacing="0">
            {birthParent.company && birthParent.company.length > 25
              ? `${String(birthParent.company).substring(0, 22)}...`
              : birthParent.company}
          </Text>
        )}
      </StyledCell>
      <StyledCell hide={!partnerHeadersLength} customWidth={`${SPACE_BETWEEN_TABLES}px`} />
      <StyledCell
        hide={!partnerHeadersLength}
        recommended
        customWidth={`${partnerHeadersLength * SPACE_CELL_TABLES}px`}
        customBg="#F2F4F7"
      >
        {report?.partner.planIds && report.partner.planIds.length && report?.partner.canEditPlans ? (
          <Popconfirm
            title="Would you like to edit these plans?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              dispatch(Actions.Creators.changeCurrent(0));
              handleEditPlans(report, "partner", history, activeTab);
            }}
          >
            <Text title={partner.company} uppercase blue bold fontSize="20" hover cursor="pointer" customLSpacing="0">
              {partner.company && partner.company.length > 25
                ? `${String(partner.company).substring(0, 22)}...`
                : partner.company}
            </Text>
          </Popconfirm>
        ) : report?.partner.canEditPremiums ? (
          <Popconfirm
            title="Would you like to edit these premiums?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              dispatch(Actions.Creators.changeCurrent(0));
              handleEditPremiums(report, "partner", history);
            }}
          >
            <Text title={partner.company} uppercase blue bold fontSize="20" hover cursor="pointer" customLSpacing="0">
              {partner.company && partner.company.length > 25
                ? `${String(partner.company).substring(0, 22)}...`
                : partner.company}
            </Text>
          </Popconfirm>
        ) : (
          <Text title={partner.company} uppercase blue bold fontSize="20" customLSpacing="0">
            {partner.company && partner.company.length > 25
              ? `${String(partner.company).substring(0, 22)}...`
              : partner.company}
          </Text>
        )}
      </StyledCell>
    </div>
  );
};

export default TableTitle;
