import React from "react";
import { Checkbox } from "antd";
import { useField } from "formik";
import "./styles.css";

const CheckboxField = ({ label, type, name, ...props }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <div className="form" style={{ padding: 4 }}>
      <Checkbox
        {...field}
        {...props}
        defaultChecked={field.value}
        checked={field.value}
      >
        {label}
      </Checkbox>

      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </div>
  );
};

export default CheckboxField;
