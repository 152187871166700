import React from "react";
import {
  Tabs,
} from "antd";
import { AddPlansPanel, UpdatePlansPanel, UpdateServicesPanel} from "components";

const AdminPlansPage = () => {
  const {TabPane} = Tabs

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Add Plan" key="1">
        <AddPlansPanel/>
      </TabPane>
      <TabPane tab="Edit Plan" key="2">
        <UpdatePlansPanel/>
      </TabPane>
      <TabPane tab="Add/Edit Non-Hospital Coverage" key="3">
        <UpdateServicesPanel />
      </TabPane>
    </Tabs>
  );
};

export default AdminPlansPage;