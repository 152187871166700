import React from "react";
import styled from "styled-components";
import { Modal } from "antd";
import { Button, Title } from "components";

const StylesModal = styled(Modal)`
font-family: circular;
  @media (min-width: 768px) {
    min-width: ${widthSize};
  }
  @media print {
    min-height: calc(100% + 150px);
    .ant-modal-header,
    .ant-modal-close,
    .ant-modal-footer {
      display: none;
    }

    .ant-modal-content {
      box-shadow: none;
    }
  }
`;

function widthSize({ size }) {
  if (size === "big") {
    return "1025px";
  }
  return "550px";
}

const ModalCustom = ({
  close,
  noClose,
  children,
  title,
  size,
  isShow,
  textButton,
  handlePrint,
  handleRegister,
  handleAction,
  disableButton,
  isCancel,
}) => {
  return (
    <StylesModal
      title={[
        <Title key="title" modal>
          {title}
        </Title>,
      ]}
      centered
      size={size}
      bodyStyle={{
        paddingTop: 8,
        paddingBottom: 8,
        minHeight: 150,
      }}
      width={widthSize({ size })}
      visible={isShow}
      closable={!noClose}
      maskClosable={!isCancel}
      onCancel={close}
      footer={[
        handlePrint && (
          <Button key={1} full onClick={handlePrint}>
            Print
          </Button>
        ),
        handleRegister && (
          <Button key={3} full onClick={handleRegister}>
            Make an Account
          </Button>
        ),
        handleAction ? (
          <Button key={4} full onClick={handleAction}>
            {textButton}
          </Button>
        ) : !disableButton ? (
          <Button key={2} full onClick={close}>
            {textButton || "Close"}
          </Button>
        ) : (
          <Button key={2.5} full disabled>
            {textButton || "Close"}
          </Button>
        ),
      ]}
    >
      {children}
    </StylesModal>
  );
};

export default ModalCustom;
