export const getPremiumFrequencyToUnit = frequency => {
  if (!frequency) return null;
  const format = {
    Weekly: 4.3571,
    "Bi-Weekly (26 times per year)": 2.178571429,
    "Bi-Monthly (24 times per year)": 2,
    Monthly: 1,
    Quarterly: 0.33333333,
    "Bi-Annually (2 times per year)": 0.16666667,
    Annually: 0.83333333
  };

  return format[frequency];
};

export const getPremiumUnitToFrequency = unit => {
  if (!unit) return null;
  const format = {
    Weekly: 4.3571,
    "Bi-Weekly (26 times per year)": 2.178571429,
    "Bi-Monthly (24 times per year)": 2,
    Monthly: 1,
    Quarterly: 0.33333333,
    "Bi-Annually (2 times per year)": 0.16666667,
    Annually: 0.83333333
  };

  const key = Object.keys(format).find(k => format[k] === unit);
  return key;
};

export const getHsaFrequencyToUnit = frequency => {
  if (!frequency) return null;
  const format = {
    Annually: 1,
    "Bi-Annually (2 times per year)": 2,
    Monthly: 12,
    "Bi-Monthly (24 times per year)": 24
  };

  return format[frequency];
};

export const getHsaUnitToFrequency = unit => {
  if (!unit) return null;
  const format = {
    Annually: 1,
    "Bi-Annually (2 times per year)": 2,
    Monthly: 12,
    "Bi-Monthly (24 times per year)": 24
  };

  const key = Object.keys(format).find(k => format[k] === unit);
  return key;
};
