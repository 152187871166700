import React, { useState } from "react";
import styled from "styled-components";
import {
  CalculateHeader as Header,
  FormFooter,
  FormCheckboxAltV2 as Checkbox,
  FormDatepickerV2 as Datepicker,
  Icon,
} from "components";
import { merge } from "lodash";

const Wrapper = styled.section`
  display: grid;
  height: 100%;
  place-items: center;
`;

const GridWrapper = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 15px;
`;

const questions = {
  considerEmploymentPlan: "Does your new job offer health insurance?",
  jobDate:
    "When's the earliest date you can start with your new job's coverage?",
  considerCobraPlan: "Are you eligible for COBRA insurance?",
  cobraDate: "When did you leave your job?",
  considerCommercialPlan:
    "Do you have access to insurance through a job, school, or union?",
  // forBusiness:
  //   "Are you considering setting up health insurance for a business you work for?",
};

const valToBool = (_val) => {
  return _val.toLowerCase() === "yes" ? true : false;
};

const OnboardingJobEventSection = ({ _form, handleNext }) => {
  const [steps, setSteps] = useState([
    _form.newJob
      ? "considerEmploymentPlan"
      : _form.leftJob
      ? "considerCobraPlan"
      : "considerCommercialPlan",
  ]);

  const step = steps[steps.length - 1];

  const [form, setForm] = useState({
    newJob: _form.newJob,
    considerEmploymentPlan: _form.considerEmploymentPlan,
    jobDate: _form.jobDate,

    leftJob: _form.leftJob,
    considerCobraPlan: _form.considerCobraPlan,
    cobraDate: _form.cobraDate,

    considerCommercialPlan: _form.considerCommercialPlan,
    
    //default for false as asked by Sarah.
    //This question confuses users and it's broken
    // forBusiness: _form.forBusiness,
    forBusiness: false,

    onboardingStep: _form.onboardingStep.includes("review") ? "review" : "dob",
  });

  const handleChange = ({ name, value, text }) => {
    switch (name) {
      case "considerEmploymentPlan":
        setForm({
          ...form,
          [name]: valToBool(text),
        });
        break;
      case "jobDate":
        setForm({
          ...form,
          [name]: value,
        });
        break;
      case "considerCobraPlan":
        setForm({
          ...form,
          [name]: valToBool(text),
        });
        break;
      case "cobraDate":
        setForm({
          ...form,
          [name]: value,
        });
        break;
      case "considerCommercialPlan":
        setForm({
          ...form,
          [name]: valToBool(text),
          forBusiness: valToBool(text) ? false : form.forBusiness,
        });
        break;
      case "forBusiness":
        setForm({
          ...form,
          [name]: false
          // [name]: valToBool(text),
        });
        break;
      default:
        setForm({
          ...form,
          onboardingStep: _form.onboardingStep.includes("review")
            ? "review"
            : "dob",
        });
        break;
    }
  };

  const handleStep = () => {
    const updatedForm = merge({}, _form, form);
    switch (step) {
      case "considerEmploymentPlan":
        setSteps([
          ...steps,
          //Removed forBusiness step
          // form.considerEmploymentPlan ?
           "jobDate" 
          //  : "forBusiness",
        ]);
        break;
      case "jobDate":
        form.leftJob
          ? setSteps([...steps, "considerCobraPlan"])
          : handleNext(updatedForm);
        break;
      case "considerCobraPlan":
        form.considerCobraPlan
          ? setSteps([...steps, "cobraDate"])
          : !form.newJob
          ? setSteps([...steps, "considerCommercialPlan"])
          : handleNext(updatedForm);
        break;
      case "cobraDate":
        !form.newJob
          ? setSteps([...steps, "considerCommercialPlan"])
          : handleNext(updatedForm);
        break;
      case "considerCommercialPlan":
        // !form.considerCommercialPlan
        //   ? setSteps([...steps, "forBusiness"]):
           handleNext(updatedForm);
        break;
      case "forBusiness":
        form.leftJob && typeof form.considerCobraPlan !== "boolean"
          ? setSteps([...steps, "considerCobraPlan"])
          : handleNext(updatedForm);
        break;
      default:
        handleNext(updatedForm);
        break;
    }
  };

  const handleBack = () => {
    const updatedSteps = steps.slice(0, -1);
    const updatedForm = merge({}, _form, {
      ...form,
      onboardingStep: _form.onboardingStep === "review" ? "review" : "general",
    });
    switch (steps.length) {
      case 1:
        handleNext(updatedForm);
        break;
      default:
        setSteps(updatedSteps);
        break;
    }
  };

  const active = form[step] !== null && form[step] !== undefined;

  return (
    <Wrapper>
      <Header bigTitle={true} title={questions[step]} />
      {step.includes("Date") ? (
        <GridWrapper>
          <Datepicker
            name={step}
            value={form[step]}
            handleChange={handleChange}
            allowNA
          />
        </GridWrapper>
      ) : (
        <GridWrapper>
          <Checkbox
            name={step}
            value={form[step]}
            Icon={<Icon yesIcon small />}
            text="Yes"
            handleChange={handleChange}
          />
          <Checkbox
            name={step}
            value={form[step] === false}
            Icon={<Icon noIcon small />}
            text="No"
            handleChange={handleChange}
          />
        </GridWrapper>
      )}
      <FormFooter
        leftAction={handleBack}
        mainAction={handleStep}
        mainActionActive={active}
      />
    </Wrapper>
  );
};

export default OnboardingJobEventSection;
