import { useRefreshProvider } from "contexts/RefreshContext";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useFilterContext } from "../FilterV2/FilterContext";
import Actions from "store/report/actions";
import { ConfirmModal, ConfirmWrapper } from "components/organisms/SideBar/SideBarOverlay";

const FilterSortButtonStyled = styled.div`
  width: max-content;
  display: flex;
  justify-content: center;
  margin: 10px 5px;
  gap: 12px;

  button {
    display: flex;
    gap: 8px;
    align-items: center;
    color: #344054;
    border: 1px solid #344054;
    border-radius: 8px;
    background-color: #fff;
    padding: 5px 15px;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;

    svg {
      width: 15px;
      aspect-ratio: 1/1;
    }
  }

  .clear-button {
    --clear-background-color: #f2f4f7;
    --clear-color: #344054;
    display: flex;
    gap: 8px;
    align-items: center;
    border: 1px solid var(--clear-color);
    color: var(--clear-color);
    border-radius: 8px;
    background-color: #fff;
    padding: 5px 15px;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
    background-color: var(--clear-background-color);

    span {
      color: var(--clear-color);
    }
    svg {
      width: 15px;
      aspect-ratio: 1/1;
      fill: var(--clear-color);

      circle {
        stroke: var(--clear-color);
        stroke-width: 1.66667;
      }
      text {
        font-size: 12px;
        fill: white;
        color: var(--clear-color);
        stroke-width: 0.1;
      }
    }
  }
`;

const HelpIcon = styled.svg`
  width: 18px;
  height: 18px;
  fill: none;
  stroke-width: 1.66667;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const QuestionMarkCircle = styled.circle`
  stroke: #344054;
  stroke-width: 1.66667;
`;

const QuestionMark = styled.text`
  font-size: 12px;
  fill: #344054;
  stroke-width: 0.1;
`;

export default function FilterSortButton({ toggleSideBar, toggleAssumptions }) {
  const { refreshState } = useRefreshProvider();
  const restartTour = () => {
    localStorage.removeItem("#recommendation-title");
    refreshState();
  };
  const { isMenuOpen } = useFilterContext();
  const { areFiltersActive, resetFilters, loading } = useActiveFilters();

  const clearIconPlacement = (size = 12) => {
    const viewBoxSize = size * 2 + size / 12;
    const radius = size - size / 6;
    return {
      svg: { viewBox: `0 0 ${viewBoxSize} ${viewBoxSize}` },
      circle: { cx: "50%", cy: "50%", r: radius },
      text: { x: "50%", y: "66%", "text-anchor": "middle" },
    };
  };

  return (
    <FilterSortButtonStyled>
      {!isMenuOpen && loading && (
        <ConfirmWrapper>
          <ConfirmModal>
            <p>Updating...</p>
          </ConfirmModal>
        </ConfirmWrapper>
      )}

      <button onClick={toggleSideBar} id="all-my-options-filter-btn">
        <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="filter-lines">
            <path
              id="Icon"
              d="M5.75 10H15.75M3.25 5H18.25M8.25 15H13.25"
              stroke="#344054"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
        Filter & sort
      </button>

      {areFiltersActive && (
        <button onClick={resetFilters} id="all-my-options-filter-btn" className="clear-button">
          <span>Filters applied</span>

          <svg viewBox={clearIconPlacement(12).svg.viewBox} xmlns="http://www.w3.org/2000/svg">
            <circle {...clearIconPlacement(12).circle} />
            <text {...clearIconPlacement(12).text}>✕</text>
          </svg>
        </button>
      )}

      <button onClick={toggleAssumptions} id="all-my-options-assumptions-btn">
        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.08333 6.0852C7.23016 5.6678 7.51998 5.31584 7.90144 5.09165C8.28291 4.86745 8.73141 4.7855 9.16751 4.86031C9.60361 4.93511 9.99917 5.16184 10.2841 5.50034C10.5691 5.83884 10.725 6.26726 10.7244 6.70973C10.7244 7.9588 8.85077 8.58333 8.85077 8.58333M8.87492 11.0833H8.88325M7 15L8.21667 16.6222C8.3976 16.8635 8.48807 16.9841 8.59897 17.0272C8.69611 17.065 8.80389 17.065 8.90103 17.0272C9.01193 16.9841 9.1024 16.8635 9.28333 16.6222L10.5 15C10.7443 14.6743 10.8664 14.5114 11.0154 14.3871C11.2141 14.2213 11.4486 14.104 11.7004 14.0446C11.8893 14 12.0929 14 12.5 14C13.6649 14 14.2473 14 14.7067 13.8097C15.3193 13.556 15.806 13.0693 16.0597 12.4567C16.25 11.9973 16.25 11.4149 16.25 10.25V5.5C16.25 4.09987 16.25 3.3998 15.9775 2.86502C15.7378 2.39462 15.3554 2.01217 14.885 1.77248C14.3502 1.5 13.6501 1.5 12.25 1.5H5.25C3.84987 1.5 3.1498 1.5 2.61502 1.77248C2.14462 2.01217 1.76217 2.39462 1.52248 2.86502C1.25 3.3998 1.25 4.09987 1.25 5.5V10.25C1.25 11.4149 1.25 11.9973 1.4403 12.4567C1.69404 13.0693 2.18072 13.556 2.79329 13.8097C3.25272 14 3.83515 14 5 14C5.40715 14 5.61072 14 5.79959 14.0446C6.05141 14.104 6.28593 14.2213 6.48458 14.3871C6.63357 14.5114 6.75571 14.6743 7 15Z"
            stroke="#344054"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Assumptions
      </button>

      <button onClick={restartTour} id="all-my-options-help-btn">
        <HelpIcon viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
          <QuestionMarkCircle cx="9" cy="9" r="8" />
          <QuestionMark x="6" y="13">
            ?
          </QuestionMark>
        </HelpIcon>
        Help
      </button>
    </FilterSortButtonStyled>
  );
}

const useActiveFilters = () => {
  const {
    doctorOptions,
    drugOptions,
    hospitalOptions,
    broaderNetworksOptions,
    broaderNetworkIncludesOnLoad,
    selectedCategoriesOptions,
    selectedPlansOptions,
    currentPreference,
    applyLoading,
    setApplyLoading,
    handleApply,
  } = useFilterContext();
  const { refreshState } = useRefreshProvider();
  const [isResetting, setIsResetting] = React.useState(false);
  const dispatch = useDispatch();

  const selectedCategories = currentPreference?.selectedCategories;
  const selectedPlans = currentPreference?.selectedPlans;
  const join = currentPreference?.join;

  const allSelected = (options = [], selected = []) => {
    if (options?.length === 0) return true;
    if (options?.length !== selected?.length) return false;

    return options?.every((option) => selected?.includes(option));
  };

  const noneSelected = (options = [], selected = []) => {
    if (options?.length === 0) return true;
    return options?.every((option) => !selected?.includes(option));
  };

  const resetFilters = async () => {
    const updatedState = {
      doctor: [],
      drug: [],
      hospital: [],
      broaderNetworkIncludes: broaderNetworksOptions,
      selectedCategory: selectedCategoriesOptions,
      selectedPlan: selectedPlansOptions?.map(({ value }) => value),
      plansToJoin: "all",
    };

    dispatch(
      Actions.Creators.switchState({
        ...updatedState,
      }),
    );

    setApplyLoading(true);
    setIsResetting(true);
  };

  const filterActiveStates = useMemo(() => {
    return {
      doctor: !noneSelected(doctorOptions, selectedCategoriesOptions),
      hospital: !noneSelected(hospitalOptions, selectedCategoriesOptions),
      drug: !noneSelected(drugOptions, selectedCategoriesOptions),
      broaderNetworkIncludes:
        broaderNetworkIncludesOnLoad?.length > 0 && !allSelected(broaderNetworksOptions, broaderNetworkIncludesOnLoad),
      selectedPlan:
        selectedPlans?.length > 0 &&
        !allSelected(
          selectedPlansOptions?.map((plan) => plan.value),
          selectedPlans,
        ),
      selectedCategory: selectedCategories?.length > 0 && !allSelected(selectedCategoriesOptions, selectedCategories),
      plansToJoin: join && join !== "all",
    };
  }, [
    doctorOptions,
    drugOptions,
    hospitalOptions,
    broaderNetworksOptions,
    broaderNetworkIncludesOnLoad,
    selectedCategoriesOptions,
    selectedPlansOptions,
    selectedCategories,
    selectedPlans,
    join,
  ]);

  const areFiltersActive = useMemo(() => {
    return Object.values(filterActiveStates).some((state) => state);
  }, [filterActiveStates]);

  useEffect(() => {
    if (isResetting && areFiltersActive) {
      setIsResetting(false);
      handleApply();
    }
  }, [isResetting, areFiltersActive]);

  useEffect(() => {
    if (!applyLoading) {
      refreshState();
    }
  }, [applyLoading, refreshState]);

  return {
    areFiltersActive,
    resetFilters,
    loading: applyLoading || isResetting,
  };
};
