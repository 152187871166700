import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Icon } from "@ant-design/compatible";

import {
  CalculateHeader as Header,
  Spinner,
  ButtonLink,
  Text,
  IncompleteOnboarding,
} from "components";

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

const ReportWrapper = styled.div`
  width: 25vw;
  background-color: ${(props) => (props.complete ? "#d9f3f1" : "lightgrey")};
  border-radius: 20px;
  margin: 1.5vw;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ReportHeader = styled.div`
  height: 120px;
  background-color: #51a5a1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const Button = styled.div`
  width: 100%;
  border-radius: 20px;
  font-size: 20px;
  text-align: center;
  color: white;
  background-color: #21345b;
  cursor: pointer;
  margin-top: 20px;
`;

const ReportBody = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #e5f2f1;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  min-height: 32.5vh;
`;

const renderReportBody = (
  type,
  title,
  provider,
  bodyTitle,
  description,
  onButtonPress,
  buttonText,
  showButton,
  getBody
) => {
  return (
    <ReportBody>
      <FlexWrapper col>
        {getBody ? (
          getBody(bodyTitle)
        ) : (
          <>
            <Text blue fontSize="28" bold style={{ marginBottom: 10 }}>
              {bodyTitle}
            </Text>
            <div>
              <Text fontSize="20" lg inline green bold>
                {title}
              </Text>
              <Text fontSize="20" lg inline>
                {" "}
                {description}
              </Text>
              <br />
              <br />
              <Text fontSize="20" lg inline>
                {"*Includes all of your "}
                {provider}
                {" you gave to Predictabill for analysis"}
              </Text>
            </div>
          </>
        )}
      </FlexWrapper>

      {(type !== "custom" || showButton) && (
        <Button onClick={onButtonPress}>{buttonText}</Button>
      )}
    </ReportBody>
  );
};

const OverviewCard = ({
  type,
  title,
  isSmallTitle,
  provider,
  subheading,
  bodyTitle,
  description,
  onButtonPress,
  buttonText,
  showButton,
  getBody,
}) => {
  return (
    <ReportWrapper>
      <ReportHeader>
        <Text
          white
          fontSize={isSmallTitle ? "22" : "34"}
          bold
          style={{ marginBottom: 10, letterSpacing: 0, textAlign: "center" }}
        >
          {title}
        </Text>
        <Text white fontSize="22">
          {subheading}
        </Text>
      </ReportHeader>
      {renderReportBody(
        type,
        title,
        provider,
        bodyTitle,
        description,
        onButtonPress,
        buttonText,
        showButton,
        getBody
      )}
    </ReportWrapper>
  );
};

export default OverviewCard;
