import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "@ant-design/compatible";
import { Card } from "antd";

const CardWrapper = styled(Card)`
`

const Tag = styled.div`
 height: 25px;
 width: 5px;
 margin-right: 5px;
 ${({color}) => `background-color: ${color}`}
`

const InsideWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 50px;
`

const ColumnName = styled.p`
  width: 100px;
`

const RenderErrorsDiv = styled.div`
  margin-left: 15px;
`


const ErrorsFeedback = ({errorsList, plansServicesList = ['No Plan']}) => {

  const renderErrors = arr => {
    return arr.map( line =>
      <RenderErrorsDiv>
        <p>{'ROW: ' + line.line}</p>
        <div>
        {
          line.errors.map(err => {
            return (
              <InsideWrapper>
                <ColumnName>
                {`Column ${err.column}:`} 
                </ColumnName>
                {
                  err.err ? <><Tag color='red'/><p>{err.err}</p></> :
                  <><Tag color='orange'/><p>{err.warn}</p></>
                }
              </InsideWrapper>
            )
          })
        }
        </div>
      </RenderErrorsDiv>
    )
  }

  return (
    <Card title="Errors and warnings">
      {
        plansServicesList.map( service => 
          <>
          { service.externalId && <p style={{fontWeight: 900}}>{service.externalId}</p> }
          {
            renderErrors(errorsList || service.errors)
          }
          </>
        )
      }
    </Card>
  );
};

export default ErrorsFeedback;
