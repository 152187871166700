import styled, { css } from "styled-components";
import { AlignmentEnums } from "enums/alignments.enums";

const position = ({ position }) =>
  position &&
  css`
    text-align: ${AlignmentEnums[position]};
  `;

const TableHead = styled.th`
  ${({ middle }) =>
    middle && ` width:150px; max-width: 150px; min-width: 120px;`};
  ${({ small }) => small && ` width:40px; max-width: 40px;`};
  ${({ big }) => big && ` width:180px; max-width: 180px;`};
  ${({ ms }) => ms && ` width:100px; max-width: 100px; min-width: 80px;`};
  ${({ width }) =>
    width &&
    ` width: ${width}; max-width:${width}; min-width:calc(${width} - 20px)`};
  margin: 0;
  padding: 2px 10px;
  height: 50px;
  text-align: ${({ right, center }) =>
    right ? "end" : center ? "center" : "start"};
  ${({ white }) => white && "background: #fff;"};
  ${({ header }) => header && `font-weight: 800; color: #111`}
  ${({ short }) =>
    short &&
    `white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;`}
  ${({ orange }) => orange && "background-color: rgb(198,88,28, 0.2);"}
  ${position};
  ${({ top }) => top && `border-top: 0.75pt solid #54565a;`}
  ${({ grandTotal }) => grandTotal && `
    border-top-${grandTotal}-radius: 14px;
    border-bottom-${grandTotal}-radius: 14px;
  `}

  @media(max-width: 480px) {
    max-width: 150px;
  }
`;
export default TableHead;
