import React from "react";
import styled from "styled-components";
import { Text, Currency } from "components";

const Wrapper = styled.div`
  ${({ blue }) => blue && `background: rgb(0, 161, 155);`}
  ${({ green }) => green && `background: rgb(24, 46, 82);`}
  height: 40px;
  border-radius: 14px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
`;

const StyleText = styled(Text)`
  color: #fff;
  font-weight: bold;
  font-size: 16px;
`;

const NewStyledText = styled(Text)`
  color: #DC4C2D;
  font-weight: 900;
  text-align: left;
  align-self: center;
  font-size: 22px;
  line-height: 1.1em;
  margin: 15px;
`

const InnerWrapper = styled.div`
  max-width: 70%;
`

const NewWrapper = styled.div`
  display: flex;
  margin: 0 0 20px;
`

const GrandTotal = ({ total, title, ...props }) => {
  return (
    props.newStyle ?
    <NewWrapper>
      <InnerWrapper>
        <NewStyledText circular bolder>{title}</NewStyledText>
      </InnerWrapper>
      <NewStyledText circular>
         <Currency price={total} />
      </NewStyledText>   
    </NewWrapper>
    :
    <Wrapper {...props}>
      <StyleText circular>{title}</StyleText>
      <StyleText circular>
        <Currency price={total} />
      </StyleText>
    </Wrapper>
  );
};

export default GrandTotal;
