import React, { useState } from "react";
import styled from "styled-components";
import {
  CalculateHeader as Header,
  Text,
  ButtonLink,
  Spinner,
} from "components";
import { Icon } from "@ant-design/compatible";

const Wrapper = styled.section``;

const FlexWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  ${({ row }) => (row ? `flex-direction: row` : `flex-direction: column`)}
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const FlexInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InnerWrapper = styled.div`
  border: 3px solid #00a19b;
  border-radius: 25px;
  margin: 15px 12px;

  ${({ small }) =>
    small
      ? `
  height: 75px; width: 250px;`
      : `max-width: 80%;`}
  outline: none;
  color: #00a19b;
  display: flex;
  ${({ row }) =>
    row
      ? `flex-direction: row; justify-content: space-evenly;`
      : `flex-direction: column; justify-content: space-evenly;`}
  justify-content: space-evenly;
  align-items: center;
  background: #fff;
  padding: 15px 20px;
`;

const OnboardingReviewSection = ({
  handleGoTo,
  onSubmit,
  choices,
  form: {
    ageDate,
    dueDate,
    jobDate,
    cobraDate,
    marriageDate,
    graduationDate,
    moveDate,
    under26,
    parentPlan,
    isSpouse,
    spousePlan,
    generalInsurance,
    jobInsurance,
    cobraInsurance,
    haveChildren,
    children,
    question,
  },
}) => {
  const options = [
    "New job", // 0
    "Left job", // 1
    "Having a baby", // 2
    "Getting married", // 3
    "Turning 26", // 4
    "Graduating from school", // 5
    "Moving", // 6
  ];

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    onSubmit();
  };

  return (
    <Wrapper>
      <Header bigTitle={true} title="Review Your Information" />
      <FlexWrapper row={true}>
        {choices.includes(options[0]) && jobInsurance && (
          <InnerWrapper small={true} row>
            <Icon
              type="idcard"
              style={{ fontSize: "40px", padding: "0 5px" }}
            />
            <FlexInnerWrapper>
              <Text select bolder>
                New Job's Coverage
              </Text>
              <Text select>{jobDate}</Text>
            </FlexInnerWrapper>
          </InnerWrapper>
        )}

        {choices.includes(options[1]) && cobraInsurance && (
          <InnerWrapper small={true} row>
            <Icon
              type="pushpin"
              style={{ fontSize: "40px", padding: "0 5px" }}
            />
            <FlexInnerWrapper>
              <Text select bolder>
                Left Job
              </Text>
              <Text select>{cobraDate}</Text>
            </FlexInnerWrapper>
          </InnerWrapper>
        )}

        {choices.includes(options[2]) && (
          <InnerWrapper small={true} row>
            <Icon
              type="calendar"
              style={{ fontSize: "40px", padding: "0 5px" }}
            />
            <FlexInnerWrapper>
              <Text select bolder>
                Due Date
              </Text>
              <Text select>{dueDate}</Text>
            </FlexInnerWrapper>
          </InnerWrapper>
        )}

        {choices.includes(options[3]) && (
          <InnerWrapper small={true} row>
            <Icon
              type="schedule"
              style={{ fontSize: "40px", padding: "0 5px" }}
            />
            <FlexInnerWrapper>
              <Text select bolder>
                Wedding Date
              </Text>
              <Text select>{marriageDate}</Text>
            </FlexInnerWrapper>
          </InnerWrapper>
        )}

        {(choices.includes(options[4]) || under26) && (
          <InnerWrapper small={true} row>
            <Icon type="gift" style={{ fontSize: "40px", padding: "0 5px" }} />
            <FlexInnerWrapper>
              <Text select bolder>
                26th Birthday
              </Text>
              <Text select>{ageDate}</Text>
            </FlexInnerWrapper>
          </InnerWrapper>
        )}

        {choices.includes(options[5]) && (
          <InnerWrapper small={true} row>
            <Icon
              type="notification"
              style={{ fontSize: "40px", padding: "0 5px" }}
            />
            <FlexInnerWrapper>
              <Text select bolder>
                Graduation Date
              </Text>
              <Text select>{graduationDate}</Text>
            </FlexInnerWrapper>
          </InnerWrapper>
        )}

        {choices.includes(options[6]) && (
          <InnerWrapper small={true} row>
            <Icon type="home" style={{ fontSize: "40px", padding: "0 5px" }} />
            <FlexInnerWrapper>
              <Text select bolder>
                Move Date
              </Text>
              <Text select>{moveDate}</Text>
            </FlexInnerWrapper>
          </InnerWrapper>
        )}

        {!choices.includes(options[0]) && (
          <InnerWrapper small={true} row>
            <Icon
              type="appstore"
              style={{ fontSize: "40px", padding: "0 5px" }}
            />
            <FlexInnerWrapper>
              <Text select bolder>
                Access to Job, School, or Union Insurance
              </Text>
              <Text select>{generalInsurance ? "Yes" : "No"}</Text>
            </FlexInnerWrapper>
          </InnerWrapper>
        )}

        {!choices.includes(options[4]) && (
          <InnerWrapper small={true} row>
            <Icon type="star" style={{ fontSize: "40px", padding: "0 5px" }} />
            <FlexInnerWrapper>
              <Text select bolder>
                Consider Parent's Plan
              </Text>
              <Text select>{parentPlan ? "Yes" : "No"}</Text>
            </FlexInnerWrapper>
          </InnerWrapper>
        )}

        <InnerWrapper small={true} row>
          <Icon type="smile" style={{ fontSize: "40px", padding: "0 5px" }} />
          <FlexInnerWrapper>
            <Text select bolder>
              Consider Spouse or Partner's Plan
            </Text>
            <Text select>{spousePlan ? "Yes" : "No"}</Text>
          </FlexInnerWrapper>
        </InnerWrapper>

        {haveChildren && (
          <InnerWrapper small={true} row>
            <Icon
              type="number"
              style={{ fontSize: "40px", padding: "0 5px" }}
            />
            <FlexInnerWrapper>
              <Text select bolder>
                Children
              </Text>
              <Text select>{children}</Text>
            </FlexInnerWrapper>
          </InnerWrapper>
        )}
      </FlexWrapper>

      {/* <FlexWrapper>
        <InnerWrapper row>
          <Icon
            type="question"
            style={{ fontSize: "40px", padding: "0 5px" }}
          />
          <FlexInnerWrapper>
            <Text select bolder>
              Your Question
            </Text>
            <Text select wordBreak>
              {question}
            </Text>
          </FlexInnerWrapper>
        </InnerWrapper>
      </FlexWrapper> */}

      <FlexWrapper>
        {loading ? (
          <Spinner small />
        ) : (
          <ButtonLink withBG button fontSize="18" onClick={handleSubmit}>
            Continue
          </ButtonLink>
        )}
        <br></br>
        <ButtonLink withBG button fontSize="18" onClick={handleGoTo}>
          Edit my information
        </ButtonLink>
      </FlexWrapper>
    </Wrapper>
  );
};

export default OnboardingReviewSection;
