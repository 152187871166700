import React from "react";
import styled from "styled-components";
import { Input } from "antd";
import Text from "components/atoms/Text";

const WrapperStep1 = styled.div`
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${(props) => (props.step === 1 ? "none" : "translateX(100%)")};
  opacity: ${(props) => (props.step === 1 ? 1 : 0)};
  visibility: ${(props) => (props.step === 1 ? "visible" : "hidden")};
  transition: 600ms;
`;

const WrapperStep2 = styled.div`
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${(props) => (props.step === 2 ? "none" : "translateX(100%)")};
  opacity: ${(props) => (props.step === 2 ? 1 : 0)};
  visibility: ${(props) => (props.step === 2 ? "visible" : "hidden")};
  transition: 600ms;
`;

const VerifyCode = ({ title, subtitle, step, setCode, stepToShow }) => {
  const Wrapper = stepToShow === 1 ? WrapperStep1 : WrapperStep2;
  return (
    <div style={{ position: "relative" }}>
      <Wrapper step={step}>
        <Text sectionTitle left style={{ marginBottom: 0 }}>
          {title}
        </Text>
        <Text>{subtitle}</Text>
        <Input
          type="text"
          style={{ width: "100%" }}
          maxLength={12}
          placeholder="123456"
          onChange={(e) => {
            setCode(e.target.value);
          }}
        />
      </Wrapper>
    </div>
  );
};

export default VerifyCode;
