import React, { useState, useEffect } from "react";

import styled from "styled-components";

import { Widget } from "@typeform/embed-react";
import { useLocation, useHistory } from "react-router-dom";
import PrimaryNavigation from "components/molecules/PrimaryNavigation";
import { useDispatch } from "react-redux";
import Actions from "store/plan/actions";
import {
  typeform,
  getProfile,
  groupNewAccount,
  groupNewReport,
  groupAddPartner,
  getGroupReportInfo,
  addEmail,
  logLogin,
} from "services/api";
import { getAllGroups } from "services/api/groups.api";
import { getToken, setToken, getUserId } from "services/utils";
import authActions from "store/auth/actions";

const Wrapper = styled(Widget)`
  position: relative;
  height: 100vh;
  overflow-y: hidden;
`;

const insuranceForMap = {
  "Just me!": "me",
  "Myself and my spouse/partner": "partner",
  "Myself and my family": "family",
};

const deleteLocalStorage = () => {
  localStorage.removeItem("userTypeformResponse");
  localStorage.removeItem("pregnancyTypeformResponse");
  localStorage.removeItem("form");
};

const NewRecommendationTypeformPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [groups, setGroups] = useState([]);

  const updateGroupsList = async () => {
    const { data } = await getAllGroups();
    setGroups(data.groups);
  };

  useEffect(() => {
    updateGroupsList();
  }, []);

  const signinBackToTypeform = localStorage.getItem("signinBackToTypeform");
  const {
    url: localUrl,
    primary: localPrimary,
    auth,
    group: localGroup,
    addEmployer: localAddEmployer,
  } = signinBackToTypeform ? JSON.parse(signinBackToTypeform) : {};
  if (signinBackToTypeform) {
    localStorage.removeItem("signinBackToTypeform");
  }
  const stateUrl = auth && localUrl ? localUrl : state?.url;
  const primary = auth && localPrimary ? localPrimary : state?.primary;
  const group = auth && localGroup ? localGroup : state?.group ? state?.group : "";
  const addEmployer = auth && localAddEmployer ? localAddEmployer : state?.addEmployer ? state?.addEmployer : false;
  const newReport = auth ? true : state?.newReport ? state?.newReport : false;
  const reportId = state?.reportId;
  const partner = state?.partner;
  const formType = "jI2oRWzX";
  let url =
    (stateUrl && primary) || group !== "" || addEmployer
      ? stateUrl
      : "https://predictabill.typeform.com/to/jI2oRWzX?typeform-welcome=0";
  const workEmail = localStorage.getItem("workEmail") || "";
  const jobOfferEmail = localStorage.getItem("jobOfferEmail") || "";

  return (
    <>
      <PrimaryNavigation isTypeform />
      <Wrapper
        id={url}
        onSubmit={async (e) => {
          const userId = await getUserId();
          const { data } = await typeform({
            formType,
            responseId: e.responseId,
          });
          const email = data?.email?.replace("qu.edu", "quinnipiac.edu") || "";
          const insuranceFor = data.insuranceFor || "";
          const specificDoctors = data.specificDoctors || "";
          const doctorsInNetwork = data.doctorsInNetwork || "";
          const pregnancyAndFertilityScenarios = data.pregnancyAndFertilityScenarios || [];
          const upennGroup = data.upennGroup || "";
          const state = data.state || "";
          const county = data.county || "";
          const partOfCalifornia = data.partOfCalifornia || "";
          const morganStanleyBEE = data.morganStanleyBEE || "";
          const quinnipiacGroup = data.quinnipiacGroup || "";
          const bankOfAmericaGroup = data.bankOfAmericaGroup || "";
          const canRedirectToReport = !data.otherServices;
          data.primary = primary || "";
          const groupObj = group && groups.find((element) => element.name === group);
          if (email) {
            deleteLocalStorage();
            localStorage.setItem("userTypeformResponse", JSON.stringify(data));
            const emailDomain = email.split("@")[1].toLowerCase();
            const workEmailDomain = workEmail.split("@")[1].toLowerCase();
            if (emailDomain === workEmailDomain) {
              history.push("/groups/complete-onboarding", {
                emailFromTypeform: email.toLowerCase(),
                workEmail: workEmail.toLowerCase(),
                group,
                url: `https://predictabill.typeform.com/to/${formType}?typeform-welcome=0&who=${
                  insuranceForMap[insuranceFor]
                }${
                  specificDoctors === "Yes" &&
                  doctorsInNetwork !== "No, all of my doctors are out-of-network with insurance."
                    ? "&askdoctorsnames=1"
                    : ""
                }`,
              });
              return;
            }
            const token = await getToken();
            const { status: emailFromTypeformStatus, data: emailFromTypeformData } = await getProfile(
              null,
              token,
              email.toLowerCase(),
            );
            const userFromPersonalEmail = emailFromTypeformData?.user;

            const { status: workEmailStatus, data: workEmailData } = await getProfile(
              null,
              token,
              workEmail.toLowerCase(),
            );

            const userFromWorkEmail = workEmailData?.user;

            if (emailFromTypeformStatus === 200 && workEmailStatus === 200) {
              // base case: they are from the same account and the email exists
              if (
                userFromPersonalEmail &&
                userFromWorkEmail &&
                userFromPersonalEmail.username === userFromWorkEmail.username
              ) {
                // dangerous!
                const { username, token } = userFromPersonalEmail;
                setToken(token);
                dispatch(authActions.Creators.loginSuccess(username, token));
                await logLogin({ username });
                history.push("/typeform/new-recommendation", {
                  url: `https://predictabill.typeform.com/to/${formType}?typeform-welcome=0&who=${
                    insuranceForMap[insuranceFor]
                  }${addEmployer ? "&dontredirect=1" : ""}${
                    specificDoctors === "Yes" &&
                    doctorsInNetwork !== "No, all of my doctors are out-of-network with insurance."
                      ? "&askdoctorsnames=1"
                      : ""
                  }&primary=${email}`,
                  group,
                  addEmployer,
                  primary: email,
                  newReport: true,
                });
              } else {
                history.push(`/signin/${email}`);
              }
            } else if (emailFromTypeformStatus === 200) {
              if (userFromPersonalEmail) {
                // dangerous!
                const { username, token } = userFromPersonalEmail;
                setToken(token);
                dispatch(authActions.Creators.loginSuccess(username, token));
                await logLogin({ username });
                await addEmail(userId, workEmail, "workEmail", token);
                // might be good to verify current username b4 continuing!!
                history.push("/typeform/new-recommendation", {
                  url: `https://predictabill.typeform.com/to/${formType}?typeform-welcome=0&who=${
                    insuranceForMap[insuranceFor]
                  }${addEmployer ? "&dontredirect=1" : ""}${
                    specificDoctors === "Yes" &&
                    doctorsInNetwork !== "No, all of my doctors are out-of-network with insurance."
                      ? "&askdoctorsnames=1"
                      : ""
                  }&primary=${email}`,
                  group,
                  addEmployer,
                  primary: email,
                  newReport: true,
                });
              } else {
                history.push(`/signin/${email}`);
              }
            } else if (workEmailStatus === 200) {
              if (userFromWorkEmail.alternativeEmails?.length === 0) {
                // dangerous!
                const { username, token } = userFromPersonalEmail;
                setToken(token);
                dispatch(authActions.Creators.loginSuccess(username, token));
                await logLogin({ username });
                await addEmail(userId, email, "extraNonWorkEmail", token);
                history.push("/typeform/new-recommendation", {
                  url: `https://predictabill.typeform.com/to/${formType}?typeform-welcome=0&who=${
                    insuranceForMap[insuranceFor]
                  }${addEmployer ? "&dontredirect=1" : ""}${
                    specificDoctors === "Yes" &&
                    doctorsInNetwork !== "No, all of my doctors are out-of-network with insurance."
                      ? "&askdoctorsnames=1"
                      : ""
                  }&primary=${email}`,
                  group,
                  addEmployer,
                  primary: email,
                  newReport: true,
                });
              } else {
                history.push("/groups/complete-onboarding", {
                  emailFromTypeform: email.toLowerCase(),
                  workEmail: workEmail.toLowerCase(),
                  group,
                  url: `https://predictabill.typeform.com/to/${formType}?typeform-welcome=0&who=${
                    insuranceForMap[insuranceFor]
                  }${
                    specificDoctors === "Yes" &&
                    doctorsInNetwork !== "No, all of my doctors are out-of-network with insurance."
                      ? "&askdoctorsnames=1"
                      : ""
                  }`,
                });
              }
            } else {
              history.push("/typeform/new-recommendation", {
                url: `https://predictabill.typeform.com/to/${formType}?typeform-welcome=0&who=${
                  insuranceForMap[insuranceFor]
                }${addEmployer ? "&dontredirect=1" : ""}${
                  specificDoctors === "Yes" &&
                  doctorsInNetwork !== "No, all of my doctors are out-of-network with insurance."
                    ? "&askdoctorsnames=1"
                    : ""
                }&primary=${email}`,
                group,
                addEmployer,
                primary: email,
              });
            }
          } else if (insuranceFor) {
            deleteLocalStorage();
            localStorage.setItem("userTypeformResponse", JSON.stringify(data));
            history.push("/typeform/new-recommendation", {
              url: `https://predictabill.typeform.com/to/${formType}?typeform-welcome=0&who=${
                insuranceForMap[insuranceFor]
              }${addEmployer ? "&dontredirect=1" : ""}${
                specificDoctors === "Yes" &&
                doctorsInNetwork !== "No, all of my doctors are out-of-network with insurance."
                  ? "&askdoctorsnames=1"
                  : ""
              }&primary=${primary}`,
              group,
              addEmployer,
              newReport,
              primary,
            });
          } else if (pregnancyAndFertilityScenarios.length) {
            localStorage.setItem("pregnancyTypeformResponse", JSON.stringify(data));
            const { groupReportId, displayName, premiumTier, waiverTier } =
              (
                await getGroupReportInfo({
                  group,
                  additionalInformation: { workEmail, jobOfferEmail },
                })
              )?.data?.response || {};
            if (
              (groupObj.skipFollowUpQuestions ||
                (groupObj.verifyWorkEmailAgainstDatabase && workEmail) ||
                (groupObj.verifyJobOfferAgainstDatabase && jobOfferEmail)) &&
              groupObj.editable &&
              canRedirectToReport
            ) {
              localStorage.removeItem("form");
              dispatch(Actions.Creators.changeCurrent(0));
              history.push("/edit-plan", {
                reportId,
                option: partner ? partner : "birthParent",
                reportToVerify: groupReportId,
                workEmail,
                jobOfferEmail,
                group: group,
                premiumTier: premiumTier,
                waiverTier: waiverTier,
                displayName,
                canRedirectToReport,
              });
            } else if (
              (groupObj.skipFollowUpQuestions ||
                (groupObj.verifyWorkEmailAgainstDatabase && workEmail) ||
                (groupObj.verifyJobOfferAgainstDatabase && jobOfferEmail)) &&
              !groupObj.requiresUserPremiums
            ) {
              const userTypeformResponse =
                (localStorage.getItem("userTypeformResponse") &&
                  JSON.parse(localStorage.getItem("userTypeformResponse"))) ||
                {};
              const pregnancyTypeformResponse =
                (localStorage.getItem("pregnancyTypeformResponse") &&
                  JSON.parse(localStorage.getItem("pregnancyTypeformResponse"))) ||
                {};
              if (newReport) {
                const { status, data } = await groupNewReport({
                  userId,
                  userTypeformResponse,
                  pregnancyTypeformResponse,
                  group,
                  additionalInformation: { workEmail, jobOfferEmail },
                });
                if (status === 200) {
                  deleteLocalStorage();
                  if (canRedirectToReport) {
                    history.push(`/optimizeV2/${data.reportId}`, {
                      activeTab: 2,
                    });
                  }
                }
              } else {
                const { status, data } = await groupNewAccount({
                  userTypeformResponse,
                  pregnancyTypeformResponse,
                  group,
                  additionalInformation: { workEmail, jobOfferEmail },
                });
                if (status === 200 && data.authenticatedUser) {
                  deleteLocalStorage();
                  if (canRedirectToReport) {
                    const { username, token } = data.authenticatedUser;
                    setToken(token);
                    dispatch(authActions.Creators.loginSuccess(username, token));
                    history.push(`/optimizeV2/${data.reportId}`, {
                      activeTab: 2,
                    });
                  }
                }
              }
            } else if (
              (groupObj.skipFollowUpQuestions ||
                (groupObj.verifyWorkEmailAgainstDatabase && workEmail) ||
                (groupObj.verifyJobOfferAgainstDatabase && jobOfferEmail)) &&
              groupObj.requiresUserPremiums
            ) {
              history.push("/add-premiums", {
                reportId,
                group,
                option: partner ? partner : "birthParent",
                reportToVerify: groupReportId,
                workEmail,
                jobOfferEmail,
                premiumTier: premiumTier,
                canRedirectToReport,
              });
            } else if (!addEmployer) {
              history.push("/typeform/new-recommendation", {
                url: `https://predictabill.typeform.com/to/${formType}?typeform-welcome=0&${
                  group !== "" ? "&group=" + group : "&account=1"
                }&primary=${primary}`,
                group,
                newReport,
                primary,
              });
            }
          } else if (state !== "" || upennGroup !== "" || quinnipiacGroup !== "" || bankOfAmericaGroup !== "") {
            const additionalInformation = {
              state,
              county,
              partOfCalifornia,
              morganStanleyBEE,
              upennGroup,
              quinnipiacGroup,
              bankOfAmericaGroup,
            };
            const { groupReportId, displayName, premiumTier, waiverTier } =
              (await getGroupReportInfo({ group, additionalInformation }))?.data?.response || {};
            if (groupObj.editable && canRedirectToReport) {
              localStorage.removeItem("form");
              dispatch(Actions.Creators.changeCurrent(0));
              history.push("/edit-plan", {
                reportId,
                option: partner ? partner : "birthParent",
                reportToVerify: groupReportId,
                group: group,
                waiverTier: waiverTier,
                displayName,
                canRedirectToReport,
              });
            } else if (groupObj.requiresUserPremiums) {
              history.push("/add-premiums", {
                reportId,
                group,
                option: partner ? partner : "birthParent",
                reportToVerify: groupReportId,
                workEmail,
                jobOfferEmail,
                premiumTier: premiumTier,
                canRedirectToReport,
              });
            } else {
              const userTypeformResponse =
                (localStorage.getItem("userTypeformResponse") &&
                  JSON.parse(localStorage.getItem("userTypeformResponse"))) ||
                {};
              const pregnancyTypeformResponse =
                (localStorage.getItem("pregnancyTypeformResponse") &&
                  JSON.parse(localStorage.getItem("pregnancyTypeformResponse"))) ||
                {};
              if (newReport) {
                const { status, data } = await groupNewReport({
                  userId,
                  userTypeformResponse,
                  pregnancyTypeformResponse,
                  group,
                  additionalInformation,
                });
                if (status === 200) {
                  deleteLocalStorage();
                  if (canRedirectToReport) {
                    history.push(`/optimizeV2/${data.reportId}`, {
                      activeTab: 2,
                    });
                  }
                }
              } else if (reportId && partner) {
                const { status } = await groupAddPartner({
                  reportId,
                  partnerType: partner,
                  group,
                  additionalInformation,
                  userId,
                  primary,
                });
                if (status === 200) {
                  deleteLocalStorage();
                  history.push(`/optimizeV2/${reportId}`, {
                    activeTab: 2,
                  });
                }
              } else {
                const { status, data } = await groupNewAccount({
                  userTypeformResponse,
                  pregnancyTypeformResponse,
                  group,
                  additionalInformation,
                });
                if (status === 200 && data.authenticatedUser) {
                  deleteLocalStorage();
                  if (canRedirectToReport) {
                    const { username, token } = data.authenticatedUser;
                    setToken(token);
                    dispatch(authActions.Creators.loginSuccess(username, token));
                    history.push(`/optimizeV2/${data.reportId}`, {
                      activeTab: 2,
                    });
                  }
                }
              }
            }
          }
        }}
      />
    </>
  );
};

export default NewRecommendationTypeformPage;
