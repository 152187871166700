import React, { useLayoutEffect, useRef, useState } from "react";
import { GroupCard } from "components";
import { List } from 'antd';
import styled from "styled-components";

const Wrapper = styled.div`
  border-top: 1px solid #e8e8e8;
  padding: 1% 25%;
  @media (max-width: 1800px){
    padding: 1% 20%;
  }
  @media (max-width: 1600px){
    padding: 1% 17.5%;
  }
  @media (max-width: 1280px){
    padding: 1% 10%;
  }
  @media (max-width: 900px){
    padding: 3% 5%;
  }
`;

const GroupsList = ({ options }) => {
  if (!options || !options.length) return null;
  return (
    <Wrapper>
      <List
        grid={{
          gutter: 10,
          column: 5,
          lg: 5,
          md: 5,
          sm: 2,
          xs: 2,
        }}
        dataSource={options}
        renderItem={(item) => (
          <List.Item>
            <GroupCard title={item.title} icon={item.icon} group={item.group} isAddNew={item.isAddNew}></GroupCard>
          </List.Item>
        )}
      />
    </Wrapper>
  )
}

export default GroupsList;
