import joi from "joi";

const customOptionFormSendSchema = joi
  .object({
    isDue: joi.bool().required(),
    dueDate: joi.string().when("isDue", {
      is: true,
      then: joi.required(),
      otherwise: joi.allow(null)
    }),
    isFirst: joi.bool().required(),
    useOutOfNetwork: joi.bool().required(),
    birthParent: joi.object().keys({
      optionsAmount: joi.number().required(),
      group: joi.string().when("optionsAmount", {
        is: joi.number().greater(0),
        then: joi.required(),
        otherwise: joi.allow(null)
      }),
      startDate: joi.string().when("optionsAmount", {
        is: joi.number().greater(0),
        then: joi.required(),
        otherwise: joi.allow(null)
      }),
      optOut: joi
        .object()
        .keys({
          isApply: joi.bool().allow(null),
          amount: joi.number().when("isApply", {
            is: true,
            then: joi.required(),
            otherwise: joi.allow(null)
          }),
          frequency: joi.number().when("isApply", {
            is: true,
            then: joi.required(),
            otherwise: joi.allow(null)
          })
        })
        .when(joi.object({ optionsAmount: joi.number().greater(0) }), {
          then: joi.object(
            { optOut: joi.required() },
            { otherwise: joi.allow(null) }
          )
        }),
      premiumTier: joi.number().required(),
      hsaTier: joi.number().required(),
      plans: joi.array().items(joi.object())
    }),
    partner: joi.object().keys({
      optionsAmount: joi.number().required(),
      group: joi.string().when("optionsAmount", {
        is: joi.number().greater(0),
        then: joi.required(),
        otherwise: joi.allow(null)
      }),
      startDate: joi.string().when("optionsAmount", {
        is: joi.number().greater(0),
        then: joi.required(),
        otherwise: joi.allow(null)
      }),
      optOut: joi
        .object()
        .keys({
          isApply: joi.bool().allow(null),
          amount: joi.number().when("isApply", {
            is: true,
            then: joi.required(),
            otherwise: joi.allow(null)
          }),
          frequency: joi.number().when("isApply", {
            is: true,
            then: joi.required(),
            otherwise: joi.allow(null)
          })
        })
        .when(joi.object({ optionsAmount: joi.number().greater(0) }), {
          then: joi.object(
            { optOut: joi.required() },
            { otherwise: joi.allow(null) }
          )
        }),
      premiumTier: joi.number().required(),
      hsaTier: joi.number().required(),
      plans: joi.array().items(joi.object())
    })
  })
  .unknown()
  .required();

export default customOptionFormSendSchema;
