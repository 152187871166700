import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import Text from "components/atoms/Text";
import { Space } from "antd";
import SelectField from "components/molecules/SelectField";
import TableMenu from "components/molecules/TableMenu";
import TableField from "components/molecules/TableField";
import { WordEnum } from "enums/serviceWordMapping.enums";
import { CommentsDollarDimensions } from "styled-icons/fa-solid/CommentsDollar";
import styled from "styled-components";

const PremiumsTitlesWrapper = styled.div`
  @media (max-width: 768px) {
    padding: 28px;
  }
`;

const columns = (selectedServiceIndex, selectedServiceBilledAs) =>
  [
    {
      title: "",
      dataIndex: "idx",
      width: 10,
      editable: false,
      fixed: "left",
    },
    {
      title: "Label",
      dataIndex: "label",
      width: 80,
      editable: true,
      inputType: "text",
      shouldCellUpdate: () => false,
      disabled: true,
      fixed: "left",
    },
    {
      title: "Insurance Network",
      dataIndex: "insuranceNetwork",
      className: "insuranceNetwork-col",
      width: 200,
      editable: true,
      inputType: "autocomplete",
      shouldCellUpdate: () => false,
      disabled: true,
    },
    {
      title: "Reimbursement",
      dataIndex: [selectedServiceIndex, selectedServiceBilledAs],
      width: 200,
      editable: true,
      inputType: "reimbursement",
      shouldCellUpdate: () => false,
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 1,
    },
  ].map((col) => {
    return {
      ...col,
      onCell: (_, idx) => {
        return {
          index: idx,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          inputType: col.inputType,
          options: col.options,
          dropdownMatchSelectWidth: col.dropdownMatchSelectWidth,
          min: col.min,
          disabled: col.disabled || false,
        };
      },
    };
  });

const ExtraDetails = () => {
  let { values, setFieldValue } = useFormikContext();
  let { expectedTable, table, partnerTable, currentServiceExtraDetails } = values;

  const serviceExtraDetailsList = expectedTable
    .filter((element) => element.type === "outOfNetwork")
    .map((element) => {
      return {
        value: `$${element.price} ${
          element.frequency !== "once" &&
          element.frequency !== "other frequency" &&
          element.billedAs !== "Inpatient Hospital Stay"
            ? element.frequency
            : ""
        } ${element.label} for ${element.for}`,
        label: `$${element.price} ${
          element.frequency !== "once" &&
          element.frequency !== "other frequency" &&
          element.billedAs !== "Inpatient Hospital Stay"
            ? element.frequency
            : ""
        } ${element.label} for ${element.for}`,
        billedAs: element.billedAs,
        idx: element.idx,
      };
    });

  const concatenatedTable = table
    .concat(partnerTable)
    .filter((item) => item.outOfNetworkCoverage === "yes")
    .map((item, i) => {
      return { ...item, idx: i + 1 };
    });
  useEffect(() => {
    document.getElementById("form-content").scrollTo({ top: 0, behavior: "smooth" });
    setFieldValue("concatenatedTable", concatenatedTable);
    setFieldValue("serviceExtraDetailsList", serviceExtraDetailsList);
    if (!currentServiceExtraDetails && serviceExtraDetailsList.length) {
      currentServiceExtraDetails = serviceExtraDetailsList[0].value;
      setFieldValue("currentServiceExtraDetails", serviceExtraDetailsList[0].value);
    }
  }, []);

  const selectedService = serviceExtraDetailsList.filter((element) => element.value === currentServiceExtraDetails);
  const selectedServiceIndex = selectedService.length && selectedService[0].idx;
  let selectedServiceBilledAs = "";
  if (selectedService.length && selectedService[0].billedAs) {
    if (selectedService[0].billedAs === "Inpatient Hospital Stay") {
      selectedServiceBilledAs = "stay";
    } else {
      selectedServiceBilledAs = WordEnum[selectedService[0].billedAs];
    }
  }

  return (
    <PremiumsTitlesWrapper>
      <Text sectionTitle>What are your Out-of-Network Coverage Reimbursement Rates?</Text>
      <SelectField
        name="currentServiceExtraDetails"
        options={serviceExtraDetailsList}
        defaultValue={currentServiceExtraDetails}
        label="Select the Out-of-Network service"
      />
      <TableField
        name="concatenatedTable"
        columns={columns(selectedServiceIndex, selectedServiceBilledAs)}
        dataSource={concatenatedTable}
        scroll={false}
        tableLayout="fixed"
      />
    </PremiumsTitlesWrapper>
  );
};

export default ExtraDetails;
