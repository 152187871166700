import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { searchReport, getPlansArr, optimizeV2, getRole, isProfessional } from "services/api/admin.api";
import { createNewPreference, getAllMyOptions, updatePreferenceResults, updateReport } from "services/api/report.api";
import { getToken } from "services/utils";
import { StickyContainer, Sticky } from "react-sticky";

import {
  Header,
  Footer,
  PageTemplate,
  Text,
  Overview,
  WhatIf,
  WhatIfV2,
  YearlyCost,
  YearlyCostV2,
  ButtonLink,
  InNetworkCare,
  Education,
} from "components";
import { Icon } from "@ant-design/compatible";

import { ADD_SERVICES_START, CREATE_PLAN_START, ADD_PREMIUMS_START } from "utils/constants";

import { Tabs, Divider, Modal, Radio, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import AllMyOptions from "components/organisms/AllMyOptions";
import SideBar from "components/organisms/SideBar";
import Actions from "store/report/actions";
import PlanActions from "store/plan/actions";
import FilterSortButton from "components/molecules/FilterSortButton";
import Tour from "components/atoms/Tour";
import { RefreshContext } from "contexts/RefreshContext";
import { FilterContextProvider } from "components/molecules/FilterV2/FilterContext";
import { getNetworksGrid, getGroupById, getGroupByReportId } from "services/api";
const { TabPane } = Tabs;

const CustomModal = styled(Modal)`
  @media (min-width: 769px) {
    width: 35vw;
  }

  label {
    width: 100% !important;
  }
`;

const HeadingWrapper = styled.div`
  /* height: 220px; */
  width: 100%;
  background-color: #1c355e;
  display: flex;
  align-items: flex-start;
  justify-content: ${(props) => (props.justify ? props.justify : "space-evenly")};
  padding: ${(props) => (props.justify ? "0 10%" : "0 100px")};

  @media (max-width: 768px) {
    height: auto;
    padding: 0px 20px 20px 20px;
    flex-direction: column;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  justify-content: ${(props) => (props.justify ? props.justify : "space-evenly")};
  margin-top: 25px;
  width: ${(props) => (props.width ? props.width : "100%")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  width: 100%;
  flex-wrap: ${(props) => (props.wrap ? "wrap" : "nowrap")};
`;

const StyledPane = styled(TabPane)`
  height: 100%;
`;

const StyledPaneAlt = styled(TabPane)`
  height: 100%;
  width: 100% !important;
`;

const StickyButton = styled.div`
  display: ${(props) => (props.hide ? "none" : "flex")};
  position: fixed;
  right: 40px;
  bottom: 10px;
  z-index: 1;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #21355b;
  border-radius: 25px;
  transition: 0.2s;
  padding: 18px;
  margin-bottom: 10px;
  max-width: 180px;

  :hover {
    color: white;
    background-color: #283e67;
    cursor: pointer;
  }
`;

const Button = styled.div`
  width: 100%;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: white;
  background-color: rgb(14, 35, 68);
  cursor: pointer;
  margin: 10px 0;
  padding: 2px 0;
  border: 2px solid #dedede;
  &:hover {
    background-color: rgba(14, 35, 68, 0.7);
  }
`;

const CustomTabs = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #1c355e;
  padding-top: 10px;

  @media (max-width: 768px) {
    justify-content: left;
    background-color: transparent;
  }

  ul {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    white-space: nowrap;
    padding: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin: 0;

    @media (max-width: 768px) {
      padding: 6px;
    }

    li {
      list-style: none;
      float: left;
      padding: 2px;

      button {
        padding: 4px 12px;
        border: none;
        background-color: transparent;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
        color: white;

        @media (max-width: 768px) {
          color: #1c355e;
          font-size: 1rem;
        }

        &:hover {
          color: #40a9ff;

          @media (max-width: 768px) {
            background-color: #e8eefa;
            opacity: 50%;
          }
        }
      }

      &.active {
        border-bottom: medium solid #1890ff;

        @media (max-width: 768px) {
          border: none;
        }

        button {
          color: #1890ff;
          font-weight: bold;

          @media (max-width: 768px) {
            color: #1c355e;
            background-color: #e8eefa;
            font-size: 1.1rem;
          }
        }
      }
    }

    ::-webkit-scrollbar {
      display: none;
      width: 0 !important;
      height: 0 !important;
    }
  }
`;

const RadioContainer = styled(Space)`
  .ant-radio-button-wrapper {
    width: 35vw;
    padding-left: 24px;
    padding-right: 24px;
    border: 0px;
  }
  .ant-radio-button-wrapper:first-child {
    border-bottom: 1px;
  }
  .ant-radio-button-wrapper-checked {
    background: #21355c !important;
    border-color: #21355c !important;
  }
`;

const StyledTitle = styled(Text)`
  @media (max-width: 768px) {
    font-size: 0.6em;
  }
`;

const StyledText = styled(Text)`
  @media (max-width: 768px) {
    margin-top: 0.3em;
    line-height: 1.1em;
    font-size: 0.9em;
  }
`;

const modalWidth = "35vw";

class OptimizeUserPageV2 extends React.Component {
  static contextType = RefreshContext;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loaded: false,
      report: null,
      reportType: "",
      plans: [],
      birthParentPlans: [],
      partnerPlans: [],
      optimizations: {},
      otherOptimizations: {},
      activeTab: "1",
      loadedOptimization: false,
      loadingRecommendedByReport: true,
      sidebarOpen: false,
      columnHeadersBP: [],
      columnHeadersPartner: [],
      broaderNetworks: [],
      inNetworkLimits: [],
      allOptimizations: [],
      whatIfOptimizations: [],
      modal: false,
      modalOption: "services",
      isAdmin: null,
      defaultTab: "1",
      forceUpdateState: 0,
      networksGrid: null,
      group: null,
    };

    this.updatePreference = this.updatePreference.bind(this);
    this.selectAsCustom = this.selectAsCustom.bind(this);
  }

  async componentDidMount() {
    document.title = "Optimization - Predictabill";
    const { reportId, history, switchState, resetState } = this.props;
    resetState();

    (async () => {
      const token = await getToken();
      const response = await getRole(token);
      const isAdmin = response.status === 200;
      this.setState({ isAdmin });
    })();

    if (history?.location?.state?.activeTab) {
      this.setState({
        activeTab: String(history.location.state.activeTab),
      });
      history.push({
        activeTab: null,
      });
    }

    const token = await getToken();
    const search = await searchReport(reportId, token);

    const resetCustomPreferences = (optimizations) => {
      optimizations.data.results[1] &&
        this.selectAsCustom(optimizations.data.results[1].selected.optimizationDetails, "custom");
      optimizations.data.results[2] &&
        this.selectAsCustom(optimizations.data.results[2].selected.optimizationDetails, "custom2");
    };

    if (search.status === 200) {
      const { report, plansArr } = search.data;

      const reportOnlyExpected =
        (report && report.preferences && report.preferences["1"] && report.preferences["1"].onlyExpected) || false;

      await getAllMyOptions(reportId, token, reportOnlyExpected).then((res) => {
        const { birthParentPlansHeaders, broaderNetworks, partnerPlansHeaders, inNetworkLimits } = res;

        this.setState({
          columnHeadersBP: birthParentPlansHeaders,
          columnHeadersPartner: partnerPlansHeaders,
          broaderNetworks: broaderNetworks,
          inNetworkLimits: inNetworkLimits,
        });
      });

      if (report.birthParent.planIds.length) {
        const plans = await getPlansArr(report.birthParent.planIds, token);
        if (plans.status === 200) {
          await this.setState({
            birthParentPlans: plans.data.plansArr,
          });
        }
      }

      if (report.partner.planIds.length) {
        const plans = await getPlansArr(report.partner.planIds, token);

        if (plans.status === 200) {
          await this.setState({
            partnerPlans: plans.data.plansArr,
          });
        }
      }

      const defaultSelectedTab = report?.version === "v2" ? "2" : "1";
      const { status: networksGridStatus, data: networksGridData } = await getNetworksGrid({ id: reportId });
      const { status: groupStatus, data: groupData } = await getGroupByReportId({ reportId });

      this.setState({
        loaded: true,
        report,
        reportType: report?.type,
        plans: plansArr,
        error: null,
        defaultTab: defaultSelectedTab,
        activeTab: defaultSelectedTab,
        networksGrid: networksGridStatus === 200 ? networksGridData.grid : null,
        group: groupStatus === 200 ? groupData.group : null,
      });

      // const { optimizations, otherOptimizations } = this.state;
      const { preferences, serviceOptions } = report;
      const preferenceKeys = Object.keys(preferences);

      const opt = await optimizeV2({ reportId, preferenceKey: 1, onlyExpected: reportOnlyExpected });

      if (opt.status === 200) {
        this.setState({
          allOptimizations: opt.data.filteredResults,
          whatIfOptimizations: opt.data.results,
        });
      }

      if (preferences.custom?.apply) {
        const optCustom = await optimizeV2({
          reportId,
          preferenceKey: "custom",
          onlyExpected: false,
          forceCalculate: false,
          shouldFilter: false,
        });

        if (optCustom?.status === 200) {
          optCustom.data.results[0].selected._id === opt.data.filteredResults[0].selected._id
            ? resetCustomPreferences(opt)
            : switchState({
                customPreferenceOne: optCustom.data.results[0].selected,
              });
        } else {
          opt.data.filteredResults[1] &&
            this.selectAsCustom(opt.data.filteredResults[1].selected.optimizationDetails, "custom");
        }
      }
      if (preferences.custom2?.apply) {
        const optCustom2 = await optimizeV2({
          reportId,
          preferenceKey: "custom2",
          onlyExpected: false,
          forceCalculate: false,
          shouldFilter: false,
        });
        if (optCustom2?.status === 200) {
          optCustom2.data.results[0].selected._id === opt.data.filteredResults[0].selected._id
            ? resetCustomPreferences(opt)
            : switchState({
                customPreferenceTwo: optCustom2.data.results[0].selected,
              });
        } else {
          opt.data.filteredResults[2] &&
            this.selectAsCustom(opt.data.filteredResults[2].selected.optimizationDetails, "custom2");
        }
      }
      if (!preferences.custom2?.apply && !preferences.custom?.apply) {
        resetCustomPreferences(opt);
      }
    } else {
      this.setState({ loaded: false, error: true });
      history.push("/recommendations");
    }
  }
  async componentDidUpdate(prevProps, prevState) {
    const { profile, reportId, history, loadRecommendedByOptmization, loadRecommendedByReport } = this.props;

    const {
      allOptimizations,
      loadedOptimization,
      report,
      broaderNetworks: broaderNetworksFromAllMyOptions,
      columnHeadersBP,
    } = this.state;

    if (profile && profile.isLoaded !== prevProps.profile.isLoaded) {
      const { reports, currentReport, groupsWithProfessionalAccess } = profile;
      let isProfessionalUser = false;
      const masterReportsFromProfessionalUser = [];

      if (this.state.isAdmin === null) {
        await (async () => {
          const token = await getToken();
          const response = await getRole(token);
          const professionalResponse = await isProfessional(token);
          if (professionalResponse.status === 200) {
            isProfessionalUser = true;
            const groups = [];
            for (let i = 0; i < groupsWithProfessionalAccess.length; i++) {
              const result = await getGroupById({ id: groupsWithProfessionalAccess[i] });
              result.status === 200 && groups.push(result.data.group);
            }
            for (let i = 0; i < groups.length; i++) {
              if (groups[i].masterReports && groups[i].masterReports.length > 0) {
                masterReportsFromProfessionalUser.push(...groups[i].masterReports);
              }
            }
          }

          const isAdmin = response.status === 200;
          this.setState({ isAdmin });
        })();
      }

      if (
        !this.state.isAdmin &&
        !(isProfessionalUser && masterReportsFromProfessionalUser.includes(reportId)) &&
        ![...reports, currentReport ? currentReport._id : ""].includes(reportId)
      ) {
        history.push("/recommendations");
      }
    }

    if (allOptimizations[0]?.selected && !loadedOptimization) {
      this.setState({
        loadedOptimization: true,
      });
      loadRecommendedByOptmization({ ...allOptimizations[0]?.selected, version: report.version });

      //GET RECOMMEND INDEX
      const { birthParent, partner } = allOptimizations[0]?.selected.optimizationDetails;
      const {
        broaderNetworks,
        providersGrid,
        event,
        isDue,
        partner: partnerFromReport,
        birthParent: birthParentFromReport,
        preferences,
      } = report;
      const { birthParentPlans, partnerPlans } = preferences[1].include;
      const plansIds = [birthParent.externalId, partner.externalId].filter((el) => Boolean(el));

      this.setState({
        loadingRecommendedByReport: true,
      });
      const token = await getToken();
      const data = await getPlansArr(plansIds, token, false);
      loadRecommendedByReport({
        data,
        broaderNetworks,
        providersGrid,
        optimization: allOptimizations[0]?.selected,
        event,
        isDue,
        broaderNetworksFromAllMyOptions,
        plansFromPartner: partnerFromReport.planIds,
        plansFromBirthParent: birthParentFromReport.planIds,
        preferenceBirthParentPlans: birthParentPlans,
        preferencePartnerPlans: partnerPlans,
        indexToSplit: columnHeadersBP.length,
        join: report.preferences["1"].join,
      });
      this.setState({
        loadingRecommendedByReport: false,
      });
    }
  }

  async selectAsCustom(optimizationDetails, custom) {
    const { report } = this.state;
    const { switchState } = this.props;
    const {
      birthParent: { externalId: birthParentExternalId },
      partner: { externalId: partnerExternalId },
      childrenJoin,
      join,
    } = optimizationDetails;
    const newCoverage = {
      title: `Custom Coverage #${custom === "custom" ? "1" : "2"}`,
      subTitle: "",
      apply: true,
      join: join,
      cost: "lowest",
      childrenJoin: childrenJoin,
      include: {
        birthParentPlans: [birthParentExternalId],
        partnerPlans: [partnerExternalId],
      },
      exclude: {
        birthParentPlans: [],
        partnerPlans: [],
      },
      lifeEvent: {
        name: null,
        date: null,
        allowSwitch: false,
      },
      bills: {
        inNetworkParent: 25285,
        hospitalVisits: 1,
      },
    };
    const token = await getToken();
    const res = await createNewPreference(report._id, custom, newCoverage, token);

    const opt = res.status === 200 && (await optimizeV2({ reportId: report._id, preferenceKey: custom }));

    if (opt.status === 200) {
      let customPreference = opt.data.filteredResults[0].selected;
      const customState =
        custom === "custom"
          ? {
              customPreferenceOne: customPreference,
            }
          : {
              customPreferenceTwo: customPreference,
            };
      switchState(customState);
    }
  }

  async updatePreference({ preferences, preferenceKey, results }) {
    const token = await getToken();
    const { report, optimizations, otherOptimizations } = this.state;
    const { switchState } = this.props;

    let update;
    let newReport = report;
    if (preferences) {
      newReport.preferences[preferenceKey] = preferences;
      update = await createNewPreference(report._id, preferenceKey, preferences, token);
    } else if (results) {
      update = await updatePreferenceResults({
        reportId: report._id,
        preferenceKey,
        token,
        results,
      });
      if (update.status === 200) {
        newReport = update.data.report;
      }
    }
    if (update.status === 200) {
      const opt = await optimizeV2({ reportId: report._id, preferenceKey, shouldFilter: false });

      if (opt.status === 200) {
        let newOptimizations = optimizations;
        newOptimizations[preferenceKey] = opt.data.results[0].selected;
        otherOptimizations[preferenceKey] = opt.data.results[0].otherOptimizations;

        preferenceKey === "custom" &&
          switchState({
            customPreferenceOne: opt.data.results[0]?.selected,
          });
        preferenceKey === "custom2" &&
          switchState({
            customPreferenceTwo: opt.data.results[0]?.selected,
          });

        this.setState({
          report: newReport,
          optimizations: newOptimizations,
          otherOptimizations,
        });
      }
    }
  }

  render() {
    const {
      loaded,
      report,
      reportType,
      optimizations,
      otherOptimizations,
      birthParentPlans,
      partnerPlans,
      activeTab,
      sidebarOpen,
      columnHeadersBP,
      columnHeadersPartner,
      broaderNetworks,
      inNetworkLimits,
      modal,
      modalOption,
      isAdmin,
      defaultTab,
      forceUpdateState,
      networksGrid,
    } = this.state;

    let { allOptimizations, whatIfOptimizations } = this.state;

    const { history, resetFormCurrent, switchToCostPeriod } = this.props;

    const isJobOffer = report?.partner?.justForComparison === "jobOffer" || false;

    const filterOptimizationsWithoutSeparate = (optimizations) => {
      return optimizations.filter((opt) => {
        return (
          opt &&
          opt.selected &&
          opt.selected.optimizationDetails &&
          opt.selected.optimizationDetails.join !== "separate"
        );
      });
    };

    allOptimizations = isJobOffer ? filterOptimizationsWithoutSeparate(allOptimizations) : allOptimizations;

    whatIfOptimizations = isJobOffer ? filterOptimizationsWithoutSeparate(whatIfOptimizations) : whatIfOptimizations;

    const needToAddPartnerPlans = report?.event?.marriage?.needToAddPartnerPlans;

    const { birthParent, partner, dates } = report || {};
    let companyTitle = "";
    if (dates) {
      const startYear = dates[0].split("/")[2];
      const endYear = dates[1].split("/")[2];
      const year = startYear === endYear ? startYear : `${startYear}/${endYear.slice(2)}`;
      companyTitle =
        birthParent.company && partner.company
          ? birthParent.company + " vs. " + partner.company + " " + year
          : birthParent.company
          ? birthParent.company + " " + year
          : partner.company + " " + year;
    }

    let recommendation = "...";

    if (allOptimizations[0] && allOptimizations[0]?.selected) {
      const { heading, subHeading } = allOptimizations[0]?.selected;
      if (heading.includes("Same")) {
        recommendation = `for everyone to join ${subHeading[0]}.`;
      } else if (heading.includes("Separate")) {
        const birthParentFields = subHeading[0].split(":");
        const partnerFields = subHeading[1].split(":");

        recommendation = `for ${birthParentFields[0]} to join ${birthParentFields[1]} and ${partnerFields[0]} to join ${partnerFields[1]} separately.`;
      } else {
        const fields = subHeading[0].split(":");
        recommendation = `to join ${fields[1]}.`;
      }
    }

    const changeSideBar = (key) => {
      this.setState({
        activeTab: key,
      });
    };

    const toggleSideBar = () => {
      this.setState({
        sidebarOpen: !sidebarOpen,
      });
    };

    const toggleAssumptions = async () => {
      handleAssumptionsClick(report, resetFormCurrent);
    };

    const updateState = (opt, newPreference) => {
      if (opt.status === 200) {
        this.setState({
          allOptimizations: opt.data.filteredResults,
          whatIfOptimizations: opt.data.results,
          report: {
            ...report,
            preferences: {
              ...report.preferences,
              1: newPreference,
            },
          },
        });
      }
    };

    const updateAllMyOptions = ({ birthParentPlansHeaders, broaderNetworks, partnerPlansHeaders, inNetworkLimits }) => {
      this.setState({
        columnHeadersBP: birthParentPlansHeaders,
        columnHeadersPartner: partnerPlansHeaders,
        broaderNetworks: broaderNetworks,
        inNetworkLimits: inNetworkLimits,
      });
    };

    const handleCancel = () => {
      this.setState({ modal: false });
    };

    const handleModalOption = (option) => {
      this.setState({ modalOption: option });
    };

    const handleAssumptionsClick = (report, resetFormCurrent) => {
      resetFormCurrent(ADD_SERVICES_START);
      localStorage.removeItem("servicesForm");
      history.push(`/add-services`, {
        reportId: report._id,
        report: report,
        returnToDetailsPage: true,
        activeTab: activeTab,
      });
    };

    const handleModalOk = (option, report, resetFormCurrent) => {
      if (!option) return;
      if (option === "services") {
        resetFormCurrent(ADD_SERVICES_START);
        localStorage.removeItem("servicesForm");
        history.push(`/add-services`, {
          reportId: report._id,
          report: report,
          returnToDetailsPage: true,
          activeTab: activeTab,
        });
      } else if (option === "birthParent-premiums" || option === "partner-premiums") {
        option = option.split("-")[0];
        resetFormCurrent(ADD_PREMIUMS_START);
        localStorage.removeItem("userTypeformResponse");
        localStorage.removeItem("pregnancyTypeformResponse");
        localStorage.removeItem("premiumsForm");
        history.push(`/edit-premiums`, {
          isEditing: true,
          reportId: report._id,
          option: option,
        });
      } else {
        option = option.split("-")[0];
        window.localStorage.removeItem("form");
        resetFormCurrent(CREATE_PLAN_START);
        history.push(`/edit-plan`, {
          reportId: report._id,
          returnToDetailsPage: true,
          activeTab: activeTab,
          option: option,
          groupObj: this.state.group,
        });
      }
    };

    const renderTabBar = (props, DefaultTabBar) => {
      return (
        <Sticky bottomOffset={80}>
          {({ style }) => (
            <DefaultTabBar
              {...props}
              className="site-custom-tab-bar"
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                backgroundColor: "#1c355e",
                color: "#fff",
                marginBottom: 0,
                zIndex: 10,
                height: 50,
                ...style,
              }}
            />
          )}
        </Sticky>
      );
    };

    const throwForceUpdateState = () => {
      this.setState({ forceUpdateState: forceUpdateState + 1 });
    };

    const birthParentOptions = report?.birthParent?.planIds?.length;
    const partnerOptions = report?.partner?.planIds?.length;
    const children = report?.event?.general?.children || report?.isDue;

    const spouseFormula =
      birthParentOptions + partnerOptions + (1 + (children ? 1 : 0)) * (birthParentOptions * partnerOptions);

    const jobOfferFormula = birthParentOptions + partnerOptions;

    const preFilterOptimizationsCount = isJobOffer ? jobOfferFormula : spouseFormula;

    const gridViewTabBtn = "#all-my-options-tab-btn";
    const rankingViewTab = "#yearly-cost-tab-btn";
    const whatIfViewTab = "#what-if-tab-btn";
    const yearlySpendBtn = "#yearly-spend-btn";
    const monthlyPremiumsBtn = "#monthly-premiums-btn";

    const clickOnElement = (selector, { onChildren } = { onChildren: false }) => {
      const element = onChildren ? document.querySelector(selector)?.children?.[0] : document.querySelector(selector);
      if (element) {
        element.click();
      }
    };

    const FilterProvider = report ? FilterContextProvider : React.Fragment;

    return (
      <PageTemplate header={<Header V2 />} footer={<Footer />}>
        {report && !localStorage.getItem("#recommendation-title") && (
          <Tour
            steps={[
              {
                target: "#recommendation-title",
                content: "This is your recommendation. Click next to see how we came up with it.",
                disableBeacon: true,
                showSkipButton: true,
              },
              {
                target: yearlySpendBtn,
                content: `Your recommendation is based on the lowest cost across the entire year, based on the visits you told us you expect to have${
                  !report?.preferences?.["1"]?.onlyExpected || false ? " as well as unexpected visits" : ""
                }. Click next to see how to edit your visit assumptions.`,
                disableBeacon: true,
                showSkipButton: true,
              },
              {
                target: "#all-my-options-assumptions-btn",
                content: "Click here if you want to add, delete, or edit cost assumptions for your visit.",
                disableBeacon: true,
                showSkipButton: true,
              },
              {
                target: "#all-my-options-filter-btn",
                content: `Click here if you'd like to exclude certain plans or change the assumptions around your potential HSA contributions.`,
                disableBeacon: true,
                showSkipButton: true,
              },
              {
                target: monthlyPremiumsBtn,
                content: `Maybe you want to see how much these options cost on a monthly basis.`,
                disableBeacon: true,
                showSkipButton: true,
              },
              {
                target: rankingViewTab,
                content:
                  "If you find it confusing to see these plans in a grid, maybe you want to see them all lined up ranked from lowest to highest.",
                disableBeacon: true,
                showSkipButton: true,
              },
              {
                target: whatIfViewTab,
                content:
                  "If you want to see a detailed breakdown of deductibles, copays, coinsurance, and how your visits factor into the numbers, head to the What If tab.",
                disableBeacon: true,
                showSkipButton: true,
              },
            ]}
            onStepChange={(step) => {
              if (step.target === rankingViewTab) {
                clickOnElement(rankingViewTab, { onChildren: true });
              } else if (step.target === whatIfViewTab) {
                clickOnElement(whatIfViewTab, { onChildren: true });
              } else {
                clickOnElement(gridViewTabBtn, { onChildren: true });
              }

              if (step.target === yearlySpendBtn) {
                switchToCostPeriod("yearly");
                clickOnElement(yearlySpendBtn);
              }
              if (step.target === monthlyPremiumsBtn) {
                switchToCostPeriod("monthly");
                clickOnElement(monthlyPremiumsBtn);
              }
            }}
            onFinish={() => {
              clickOnElement(gridViewTabBtn, { onChildren: true });
              switchToCostPeriod("yearly");
              setTimeout(() => {
                clickOnElement(yearlySpendBtn);
              }, 1000);
            }}
          />
        )}
        <HeadingWrapper justify={report?.form?.birthParent || report?.form?.partner ? "start" : "space-evenly"}>
          <FlexWrapper col align="flex-start" maxWidth={"70%"} id={"recommendation-title"}>
            {report && (
              <>
                <StyledTitle white transform="uppercase" small>
                  {companyTitle}
                </StyledTitle>
                <StyledText white bold big>
                  {"Based on the information you provided, the optimal choice is " + recommendation || ""}
                </StyledText>
              </>
            )}
          </FlexWrapper>
          {/* <FlexWrapper col align="flex-start" width="30%" maxWidth="50%">
            <>
              <Text white small modal>
                We made this recommendation based on assumptions we made about your planned procedures,
                qualifying life events, and plan coverage details.
              </Text>
              <Button onClick={() => this.setState({ modal: true })}>
                See & Modify Assumptions
              </Button>
              <Text white tiny italic modal>Please note: our recommendation could change if your assumptions change.</Text>
            </>
          </FlexWrapper> */}
        </HeadingWrapper>

        <StickyContainer>
          <FilterProvider
            providersList={report?.providersList}
            plans={this.state.plans}
            report={report}
            // optimizations={optimizations}
            // otherOptimizations={otherOptimizations}
            updateState={updateState}
            updateAllMyOptions={updateAllMyOptions}
            throwForceUpdateState={throwForceUpdateState}
            broaderNetworksFromAllMyOptions={broaderNetworks}
            categories={[...columnHeadersBP, ...columnHeadersPartner]}
            indexToSplit={columnHeadersBP.length}
            isMenuOpen={sidebarOpen}
            closeMenu={() =>
              this.setState({
                sidebarOpen: false,
              })
            }
          >
            <>
              {report && (
                <SideBar
                  activeTab={activeTab}
                  report={report}
                  plans={this.state.plans}
                  reportType={reportType}
                  optimization={allOptimizations[0]?.selected}
                  optimizations={optimizations}
                  otherOptimizations={otherOptimizations}
                  columnHeadersBP={columnHeadersBP}
                  columnHeadersPartner={columnHeadersPartner}
                  sidebarOpen={sidebarOpen}
                  changeSideBar={changeSideBar}
                  updateState={updateState}
                  updateAllMyOptions={updateAllMyOptions}
                  broaderNetworksFromAllMyOptions={broaderNetworks}
                  throwForceUpdateState={throwForceUpdateState}
                  setSideBarOpen={() =>
                    this.setState({
                      sidebarOpen: !sidebarOpen,
                    })
                  }
                />
              )}
              <CustomTabs>
                <ul>
                  <li id="overview-tab-btn" className={`${this.state.activeTab === "1" ? "active" : ""}`}>
                    <button
                      onClick={() => {
                        this.setState({ activeTab: "1" });
                      }}
                    >
                      Overview
                    </button>
                  </li>
                  {broaderNetworks?.length > 0 && allOptimizations[0]?.selected && (
                    <li id="all-my-options-tab-btn" className={`${this.state.activeTab === "2" ? "active" : ""}`}>
                      <button
                        onClick={() => {
                          this.setState({ activeTab: "2" });
                        }}
                      >
                        Grid View
                      </button>
                    </li>
                  )}
                  {preFilterOptimizationsCount && preFilterOptimizationsCount > 3 && (
                    <li id="yearly-cost-tab-btn" className={`${this.state.activeTab === "3" ? "active" : ""}`}>
                      <button
                        onClick={() => {
                          this.setState({ activeTab: "3" });
                        }}
                      >
                        Ranking View
                      </button>
                    </li>
                  )}
                  <li id="what-if-tab-btn" className={`${this.state.activeTab === "4" ? "active" : ""}`}>
                    <button
                      onClick={() => {
                        this.setState({ activeTab: "4" });
                      }}
                    >
                      What If?
                    </button>
                  </li>
                  {report && report?.isProvidersAndNetworksDisplay && (
                    <li className={`${this.state.activeTab === "5" ? "active" : ""}`}>
                      <button
                        onClick={() => {
                          this.setState({ activeTab: "5" });
                        }}
                      >
                        In-Network Care
                      </button>
                    </li>
                  )}
                  {/* {report && (
                  <li className={`${this.state.activeTab === '6' ? 'active' : ''}`}>
                    <button onClick={() => { this.setState({ activeTab: '6' }); }}>Healthcare Education</button>
                  </li>
                )} */}
                </ul>
              </CustomTabs>
              {this.state.activeTab === "1" && (
                <>
                  {report && (
                    <Overview
                      allOptimizations={whatIfOptimizations}
                      customOptimizations={allOptimizations}
                      report={report}
                      changeSideBar={changeSideBar}
                    />
                  )}
                </>
              )}
              {this.state.activeTab === "2" && (
                <>
                  {report && loaded && (
                    <AllMyOptions
                      allPlanOptions={this.state.plans}
                      report={report}
                      birthParentPlans={birthParentPlans}
                      partnerPlans={partnerPlans}
                      columnHeadersBP={columnHeadersBP}
                      columnHeadersPartner={columnHeadersPartner}
                      broaderNetworks={broaderNetworks}
                      inNetworkLimits={inNetworkLimits}
                      activeTab={activeTab}
                      allOptimizations={allOptimizations}
                      toggleSideBar={toggleSideBar}
                      toggleAssumptions={toggleAssumptions}
                      loadingRecommendedByReport={this.state.loadingRecommendedByReport}
                      groupObj={this.state.group}
                    />
                  )}
                </>
              )}
              {this.state.activeTab === "3" && (
                <>
                  {report && report?.version === "v1" ? (
                    <YearlyCost
                      optimizations={optimizations}
                      otherOptimizations={otherOptimizations}
                      report={report}
                      birthParentPlans={birthParentPlans}
                      partnerPlans={partnerPlans}
                      updatePreference={this.updatePreference}
                      allOptimizations={allOptimizations}
                      updateState={updateState}
                      broaderNetworksFromAllMyOptions={broaderNetworks}
                      toggleSideBar={toggleSideBar}
                      toggleAssumptions={toggleAssumptions}
                    />
                  ) : (
                    report && (
                      <YearlyCostV2
                        optimizations={optimizations}
                        otherOptimizations={otherOptimizations}
                        report={report}
                        birthParentPlans={birthParentPlans}
                        partnerPlans={partnerPlans}
                        updatePreference={this.updatePreference}
                        allOptimizations={allOptimizations}
                        updateState={updateState}
                        broaderNetworksFromAllMyOptions={broaderNetworks}
                        toggleSideBar={toggleSideBar}
                        toggleAssumptions={toggleAssumptions}
                      />
                    )
                  )}
                </>
              )}
              {this.state.activeTab === "4" && (
                <>
                  {report && report?.version === "v1" ? (
                    <WhatIf
                      optimizations={optimizations}
                      report={report}
                      birthParentPlans={birthParentPlans}
                      partnerPlans={partnerPlans}
                      updatePreference={this.updatePreference}
                      allOptimizations={whatIfOptimizations}
                      customOptimizations={allOptimizations}
                      activeTab={activeTab}
                      toggleSideBar={toggleSideBar}
                      toggleAssumptions={toggleAssumptions}
                    />
                  ) : (
                    report && (
                      <WhatIfV2
                        optimizations={optimizations}
                        report={report}
                        birthParentPlans={birthParentPlans}
                        partnerPlans={partnerPlans}
                        updatePreference={this.updatePreference}
                        allOptimizations={whatIfOptimizations}
                        customOptimizations={allOptimizations}
                        activeTab={activeTab}
                        forceUpdateState={forceUpdateState}
                        toggleSideBar={toggleSideBar}
                        toggleAssumptions={toggleAssumptions}
                      />
                    )
                  )}
                </>
              )}
              {this.state.activeTab === "5" && (
                <>
                  {report && (
                    <InNetworkCare
                      optimization={allOptimizations[0]?.selected}
                      report={report}
                      broaderNetworks={networksGrid?.networks}
                      providersGrid={networksGrid?.providersGrid}
                    />
                  )}
                </>
              )}
              {/* {this.state.activeTab === '6' && (
              <>
                {report && (
                  <Education report={report} />
                )}
              </>
            )} */}
            </>
          </FilterProvider>
        </StickyContainer>
        <StickyButton
          hide={partnerOptions > 0 || this.state.group !== null}
          onClick={() => {
            history.push("/groups", {
              reportId: report._id,
              partner: "partner",
            });
          }}
        >
          <PlusOutlined style={{ padding: "0 15px 0 0" }} />
          <Text fontSize={16}>ADD SPOUSE PLANS</Text>
        </StickyButton>
        <StickyButton
          hide={this.state.group === null}
          onClick={() => {
            resetFormCurrent(0);
            localStorage.removeItem("servicesForm");
            history.push(`/add-services`, {
              reportId: report._id,
              report: report,
              returnToDetailsPage: true,
              activeTab: 2,
              isMasterReport: true,
            });
          }}
        >
          <PlusOutlined style={{ padding: "0 15px 0 0" }} />
          <Text fontSize={16}>ADD COVERAGES</Text>
        </StickyButton>
        <CustomModal
          title="What assumptions would you like to view and modify?"
          open={modal}
          onOk={() => handleModalOk(modalOption, report, resetFormCurrent)}
          onCancel={handleCancel}
          bodyStyle={{ padding: 0 }}
        >
          <Radio.Group
            buttonStyle="solid"
            size="large"
            defaultValue={"services"}
            onChange={(e) => {
              handleModalOption(e.target.value);
            }}
          >
            <RadioContainer direction="vertical" size={0}>
              {report?.birthParent.canEditPlans && (
                <>
                  <Radio.Button value="birthParent-plans">{report?.birthParent.company} Plans</Radio.Button>
                  <Divider style={{ margin: 0 }} />
                </>
              )}
              {report?.partner.planIds && report.partner.planIds.length && report?.partner.canEditPlans && (
                <>
                  <Radio.Button value="partner-plans">{report?.partner.company} Plans</Radio.Button>
                  <Divider style={{ margin: 0 }} />
                </>
              )}
              {!report?.birthParent.canEditPlans && report?.birthParent.canEditPremiums && (
                <>
                  <Radio.Button value="birthParent-premiums">{report?.birthParent.company} Premiums</Radio.Button>
                  <Divider style={{ margin: 0 }} />
                </>
              )}
              {!report?.partner.canEditPlans &&
                report?.partner.planIds &&
                report.partner.planIds.length &&
                report?.partner.canEditPremiums && (
                  <>
                    <Radio.Button value="partner-premiums">{report?.partner.company} Premiums</Radio.Button>
                    <Divider style={{ margin: 0 }} />
                  </>
                )}
              <>
                <Radio.Button value="services">Visits, Drugs and Services</Radio.Button>
              </>
            </RadioContainer>
          </Radio.Group>
        </CustomModal>
      </PageTemplate>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { params } = ownProps.match;
  return {
    reportId: params.reportId,
    profile: state.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadRecommendedByOptmization: (recommendedText) => {
      dispatch(Actions.Creators.loadRecommendedByOptmization(recommendedText));
    },
    loadRecommendedByReport: (payload) => {
      dispatch(Actions.Creators.loadRecommendedByReport(payload));
    },
    changeHighlightText: (highlightText) => {
      dispatch(Actions.Creators.changeHighlightText(highlightText));
    },
    changeNoteText: (noteText) => {
      dispatch(Actions.Creators.changeNoteText(noteText));
    },
    switchState: (state) => {
      dispatch(Actions.Creators.switchState(state));
    },
    resetState: () => {
      dispatch(Actions.Creators.resetState());
    },
    resetFormCurrent: (val) => {
      dispatch(PlanActions.Creators.changeCurrent(val));
    },
    switchToCostPeriod: (period) => {
      dispatch(Actions.Creators.switchState({ costPeriod: period }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OptimizeUserPageV2);
