import React from "react";
import styled from "styled-components";
import { Text, Currency } from "components";

const Wrapper = styled.div`
  margin-bottom: ${({ isNotMargin }) => !isNotMargin && `8px`};
  ${({ center }) =>
    center &&
    `
    text-align: center;
    margin-top: 5px;
  `}
`;

const TextLabel = styled(Text)`
  color: #5A7184;
  ${({ size }) => size && `font-size: 12px; padding-left: 5px;`}
`;

const FormText = ({
  text,
  prevText,
  small,
  value,
  currency,
  textValue,
  noMargin,
  center,
}) => {

  return (
    <Wrapper isNotMargin={noMargin} center={center}>
      <TextLabel
        circular
        select size={small}>
        {`${text} ${currency ? ' - ' + prevText : ''} `}
        {currency ? <Currency price={value} /> : value}
        {`${" "}${currency ? textValue : ''}`}
      </TextLabel>
    </Wrapper>
  );
};
export default FormText;
