import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Text from "components/atoms/Text";
const PremiumsTitlesWrapper = styled.div`
  justify-content: center;
  display: flex;
  margin-top: 7%;
  @media (max-width: 768px) {
    padding: 28px;
  }
`;
const Wrapper = styled.div`
  margin-bottom: 100px;
  min-height: calc(100vh);

  @media (min-width: 768px) {
    width: 90%;
    margin: 50px auto 100px auto;
  }
`;

const AddPremiumsSteps = ({ showEndMessage, email }) => {
  const steps = useSelector((state) => state.plan.premiumSteps);

  const current = useSelector((state) => state.plan.current);
  if (showEndMessage) {
    return (
      <Wrapper>
        <>
          <PremiumsTitlesWrapper>
            <Text sectionTitle>
              {`Thanks for sharing that! We'll email you within 48 hours at ${email} with your recommendation or any
              follow-up questions.`}
            </Text>
          </PremiumsTitlesWrapper>
        </>
      </Wrapper>
    );
  }

  return <Wrapper>{steps[current].content}</Wrapper>;
};

export default AddPremiumsSteps;
