import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  loadTopDoctorsRequest: [],
  loadTopDoctorsSuccess: ["payload"],
  loadTopDoctorsFailure: ["error"]
});

const Actions = {
  Types,
  Creators
};

export default Actions;
