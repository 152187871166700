import React, { useState } from "react";
import { useField } from "formik";
import Text from "components/atoms/Text";
import RequiredField from "components/atoms/RequiredField";
import styled, { css } from "styled-components";
import { DatePicker } from "formik-antd";
import moment from "moment";
import "./style.css";

const MONTH_FORMAT = "MMMM";

const StyledDatePicker = styled(DatePicker)`
  height: 32px !important;
  width: 50% !important;
  ${({ size }) => size === "fullwidth" && `width: 100% !important`};
  border-radius: 8px !important;

  .ant-picker-input{
    height: 100% !important;
    
    input {
      color: #344054 !important;
      height: 100% !important;
      padding: 5px 10px !important;
    }
  }

  @media (min-width: 768px) {
    ${({ size }) => size === "small" && `width: 15% !important`};
  }
`;

const Wrapper = styled.div`
  ${({ inline }) =>
    inline &&
    css`
      display: flex;
      > * {
        margin-right: 10px;
      }
    `}

  @media (max-width: 768px) {
    flex-direction: column !important;
  }
`;

const DateField = ({ label, name, required, inline, picker, ...props }) => {
  const [field, meta, helpers] = useField(name);

  return (
    <Wrapper inline={inline}>
      {label && (
        <Text formLabel>
          {label}
          {required && <RequiredField error={meta.touched && meta.error} />}
        </Text>
      )}
      <StyledDatePicker
        {...field}
        {...props}
        onChange={(value) => {
          helpers.setValue(value);
        }}
        format={MONTH_FORMAT}
        placeholder={MONTH_FORMAT}
        status={meta.touched && meta.error && "error"}
        picker="month"
        dropdownClassName="hideYear"
        value={field.value && moment(field.value)}
      />

      {meta?.touched && meta?.error && (
        <Text
          red
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
          }}
        >
          {/* {meta.error} */}
          This field is required
        </Text>
      )}
    </Wrapper>
  );
};

export default DateField;
