import React, { useState, useEffect } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "firebase/init";
import { createGroup } from "services/api/groups.api";
import { Card, message, Form, Button, Popconfirm } from "antd";
import { GroupForm } from "components";
const { Item } = Form;

const AddPlansPanel = ({ setActiveKey, updateGroupsList }) => {
  const [form] = Form.useForm();
  const groupNameWatch = Form.useWatch("name", form);
  const requiresFollowUpQuestionsWatch = Form.useWatch("requiresFollowUpQuestions", form);
  const requiresEmailVerificationWatch = Form.useWatch("requiresEmailVerification", form);
  const otherDomainsWatch = Form.useWatch("otherDomains", form);
  const onlyAllowListedDomainsWatch = Form.useWatch("onlyAllowListedDomains", form);
  const excludePersonalDomainsWatch = Form.useWatch("excludePersonalDomains", form);
  const skipEmailVerificationWatch = Form.useWatch("skipEmailVerification", form);
  const verifyWorkEmailAgainstDatabaseWatch = Form.useWatch("verifyWorkEmailAgainstDatabase", form);
  const doesntHaveLinkedinWatch = Form.useWatch("doesntHaveLinkedin", form);
  const [disableOnlyAllowListedDomainsSwitch, setDisableOnlyAllowListedDomainsSwitch] = useState(false);
  const [logo, setLogo] = useState(null);
  const [disableMasterReportsInput, setDisableMasterReportsInput] = useState(false);
  const [disableRequiresFollowUpQuestions, setDisableRequiresFollowUpQuestions] = useState(false);
  const [employeesAdded, setEmployeesAdded] = useState([]);
  const [employeesRemoved, setEmployeesRemoved] = useState([]);
  const [employeesWithWrongRole, setEmployeesWithWrongRole] = useState([]);

  const handleAddGroup = async (changes) => {
    if (
      !Object.keys(changes).every(
        (key) =>
          key === "linkedinUrl" ||
          key === "prestigeRanking" ||
          (disableMasterReportsInput && key === "masterReports") ||
          changes[key] !== "",
      )
    ) {
      message.error("Please fill out all fields");
      return;
    }

    if (
      changes.jobOfferMap &&
      !changes.jobOfferMap.every(
        (item) => item.email && ((item.role && groupNameWatch === "quinnipiac") || groupNameWatch !== "quinnipiac"),
      )
    ) {
      message.error("Please make sure all job offers are filled out correctly");
      return;
    }

    if (
      changes.jobOfferMap &&
      changes.jobOfferMap.map((item) => item.email).length !==
        [...new Set(changes.jobOfferMap.map((item) => item.email))].length
    ) {
      message.error("The same email cannot appear in job offer list multiple times");
      return;
    }

    changes.hasLinkedin = !changes.doesntHaveLinkedin;
    if (!changes.hasLinkedin && changes.ein?.length < 10) {
      message.error("Please enter a valid EIN");
      return;
    }

    if (changes.hasLinkedin) {
      changes.ein = "";
    } else {
      changes.linkedinUrl = "";
    }

    if (logo !== null) {
      try {
        // eslint-disable-next-line no-undef
        const storageRef = ref(storage, `${process.env.REACT_APP_FB_CUSTOM_FOLDER_NAME}/logos/${changes.name}`);
        await uploadBytes(storageRef, logo);
        const url = await getDownloadURL(storageRef);
        changes.logoUrl = url;
      } catch (error) {
        message.error("Error uploading logo");
        return;
      }
    }

    changes.editable = !changes.lockEditing;
    changes.skipFollowUpQuestions = !changes.requiresFollowUpQuestions;
    delete changes.lockEditing;
    delete changes.requiresFollowUpQuestions;

    if (disableOnlyAllowListedDomainsSwitch) {
      changes.onlyAllowListedDomains = false;
    }

    if (disableRequiresFollowUpQuestions) {
      changes.requiresFollowUpQuestions = false;
    }

    delete changes.employeeCSV;
    const employeesWithWrongRoleEmails = employeesWithWrongRole.map((e) => e.email);
    changes.employeesAdded = employeesAdded.filter(
      (employee) => !employeesWithWrongRoleEmails.includes(employee.email),
    );
    changes.employeesRemoved = employeesRemoved.filter(
      (employee) => !employeesWithWrongRoleEmails.includes(employee.email),
    );

    changes.employeesAdded = employeesAdded;
    changes.employeesRemoved = employeesRemoved;

    changes.displayOnGroupsPage = !changes.hideOnSearchPage;
    delete changes.hideOnSearchPage;

    const response = await createGroup({ form: changes });
    if (response.status === 200) {
      message.success("Group created successfully");
      setEmployeesAdded([]);
      setEmployeesRemoved([]);
      setEmployeesWithWrongRole([]);
      updateGroupsList();
      form.resetFields();
      setActiveKey("1");
    } else {
      message.error(response.response.data.message);
    }
  };

  useEffect(() => {
    groupNameWatch?.length > 0 &&
    ["microsoft", "morganstanley", "tesla", "upenn", "quinnipiac", "bankofamerica", "opm"].includes(groupNameWatch)
      ? setDisableRequiresFollowUpQuestions(false)
      : setDisableRequiresFollowUpQuestions(true);
  }, [groupNameWatch]);

  useEffect(() => {
    requiresFollowUpQuestionsWatch ? setDisableMasterReportsInput(true) : setDisableMasterReportsInput(false);
  }, [requiresFollowUpQuestionsWatch]);

  return (
    <Card title="Add Group">
      <Form
        form={form}
        onFinish={handleAddGroup}
        preserve={false}
        layout="horizontal"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        initialValues={{
          displayName: "",
          name: "",
          masterReports: "",
          otherSearchTerms: [""],
          otherDomains: [""],
          lockEditing: true,
          requiresUserPremiums: false,
          requiresFollowUpQuestions: false,
          requiresEmailVerification: true,
          onlyAllowListedDomains: true,
          excludePersonalDomains: true,
          skipEmailVerification: false,
          verifyWorkEmailAgainstDatabase: false,
          verifyJobOfferAgainstDatabase: false,
          startOnHealthcareSpendingOnly: false,
          hideOnSearchPage: false,
        }}
      >
        <GroupForm
          setLogo={setLogo}
          disableRequiresFollowUpQuestions={disableRequiresFollowUpQuestions}
          disableMasterReportsInput={disableMasterReportsInput}
          employeesAdded={employeesAdded}
          setEmployeesAdded={setEmployeesAdded}
          employeesRemoved={employeesRemoved}
          setEmployeesRemoved={setEmployeesRemoved}
          employeesWithWrongRole={employeesWithWrongRole}
          setEmployeesWithWrongRole={setEmployeesWithWrongRole}
          skipEmailVerificationWatch={skipEmailVerificationWatch}
          requiresEmailVerificationWatch={requiresEmailVerificationWatch}
          verifyWorkEmailAgainstDatabaseWatch={verifyWorkEmailAgainstDatabaseWatch}
          doesntHaveLinkedinWatch={doesntHaveLinkedinWatch}
          form={form}
          group={""}
        />
        <Item>
          {(!onlyAllowListedDomainsWatch && !excludePersonalDomainsWatch) ||
          otherDomainsWatch?.every((domain) => domain === "") ||
          employeesWithWrongRole.length > 0 ? (
            <Popconfirm
              title={
                employeesWithWrongRole.length > 0
                  ? "Some employees have the wrong category. They won't be added unless they have the correct category. Are you sure you want to continue?"
                  : otherDomainsWatch?.every((domain) => domain === "")
                  ? "The domain list is empty. Are you sure you want to continue?"
                  : "This configuration allows any email as valid. Are you sure you want to continue?"
              }
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                setDisableOnlyAllowListedDomainsSwitch(true);
                form.submit();
              }}
            >
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            </Popconfirm>
          ) : (
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          )}
        </Item>
      </Form>
    </Card>
  );
};

export default AddPlansPanel;
