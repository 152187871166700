import React from "react";
import Routes from "../routes";
import { RefreshProvider } from "contexts/RefreshContext";

const App = () => {
  return (
    <RefreshProvider>
      <Routes />
    </RefreshProvider>
  );
};

export default App;
