import { message } from "antd";

const displayErrorMessage = (rows, errorMessage) => {
  message.error(`Error: ${errorMessage} in row${rows.length > 1 ? "s" : ""} ${rows.join(", ")}.`);
};

const verifyEmptyProviderName = (providersList) => {
  const invalidIdx = [];
  providersList.forEach((provider) => {
    if (!provider.name || provider.name === "") {
      invalidIdx.push(provider.idx);
    }
  });

  return invalidIdx;
};

export const validateProvidersList = (providersList) => {
  let invalidIdx = [];

  invalidIdx = verifyEmptyProviderName(providersList);
  if (invalidIdx.length > 0) {
    displayErrorMessage(invalidIdx, "empty Provider's Name");
    return false;
  }

  return true;
};
