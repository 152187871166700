import React, { useEffect } from "react";
import { notifyUsers } from "services/api/admin.api";
import { typeformUpdateReport, typeform } from "services/api";
import { useSelector } from "react-redux";

import styled from "styled-components";

import { Widget } from "@typeform/embed-react";
import { useLocation, useHistory } from "react-router-dom";
import PrimaryNavigation from "components/molecules/PrimaryNavigation";
import { getToken } from "services/utils";

const Wrapper = styled(Widget)`
  position: relative;
  height: 100vh;
  overflow-y: hidden;
`;
const ReportInfoTypeformPage = () => {
  const history = useHistory();
  const { search, state } = useLocation();
  const formType = new URLSearchParams(search).get("formType");
  const reportId = state?.reportId;
  const loggedUsername = useSelector((state) => state.profile.username);
  const username = loggedUsername || state.username;

  let url = "/";
  if (formType === "nvcStPeU") {
    url = `https://predictabill.typeform.com/to/nvcStPeU#pbaccount=${username}`;
  } else {
    history.push(url);
  }

  return (
    <>
      <PrimaryNavigation isTypeform />
      <Wrapper
        id={url}
        onSubmit={async (e) => {
          const { responseId } = e;
          typeform({ formType, responseId })
            .then((res) => res.data)
            .then(async (typeformResponse) => {
              const res = await typeformUpdateReport({
                reportId,
                username,
                typeformResponse,
                isNotLogged: !loggedUsername,
              });

              const { url } = res?.data;
              history.push(url);
            });
        }}
      />
    </>
  );
};

export default ReportInfoTypeformPage;
