
import React, { useState, createRef } from "react";
import { uploadEditPlansServicesCSV, getPlansTemplateForEdit, updateServices } from "services/api/admin.api";
import { getToken } from "services/utils";
import {
  Card,
  message,
} from "antd";

import { SearchPlans } from 'components'
import UploadCSVPlans from "components/molecules/UploadCSVPlans";
import PreviewResultsServicesCSV from "../PreviewResultsServicesCSV";
import { planTypes } from "enums";

const UpdateServicesPanel = () => {
  const downloadLink = createRef()

  const [type, setType] = useState("private-plans");
  const [servicesValues, setServicesValues] = useState();
  const [hasErrors, setHasErrors] = useState(false);

  const onSubmitSearch = async ({ searchBy, searchTerm }) => {
    try{
      const token = await getToken();
      const response = await getPlansTemplateForEdit({searchBy, searchTerm}, true ,token)

      const { status, data } = response;
      if (status === 200) {
        var myblob = new Blob([data], {
          type: 'text/plain'
        });
        const href = window.URL.createObjectURL(myblob)

        downloadLink.current.download = `file.csv`
        downloadLink.current.href = href
        downloadLink.current.click()
      } else {
        message.error("Plans not found");
      }
    } catch(e){

    }
  };
  
  const onSubmit =  (event) => {
    setHasErrors(false)
    setServicesValues()
  }

  const onUploadSuccess = (response) => {
    const {numberOfErrors, numberOfWarns, plansList} = response.data
    if(plansList.length === 0) {message.warning("Plans not found or with no differences")}
    setHasErrors(numberOfErrors>0)
    setServicesValues(plansList)
  }

  const sendValues = async () => {
    try{  
      const token = await getToken()
      const response = await updateServices(type===planTypes.public, servicesValues, token)
      if(response.status === 200){
        message.success("Plans Updated Successfully")
      }
      else message.error(response.message)
    } catch(e){
    }
  }

  const onChangeType = (e) => {
    e.preventDefault()
    setType(e.target.value)
    setServicesValues()
    setHasErrors(false)
  }

  return (
    <Card title="Update Services">
      <a ref={downloadLink}></a>
      <SearchPlans onSubmit={onSubmitSearch}/>
      <UploadCSVPlans  
        onSubmit={onSubmit}
        uploadFunction={uploadEditPlansServicesCSV}
        onSuccess={onUploadSuccess} 
        onChangeType={onChangeType}
        type={type}
        />
      <PreviewResultsServicesCSV
        plansServicesList={servicesValues}
        submitDisabled={hasErrors || !servicesValues || !servicesValues.length}
        onSubmit={sendValues}  
      />
    </Card>
  );
};

export default UpdateServicesPanel;
