import model from "./model";
const {
  formField: {
    planOptions,
    withMultiplePremiumTiers,
    premiumTiersNumber,
    selectedPremiumTier,
    premiumTier,
    costFor,
    outOfNetworkCoverage,
    label,
    table,
    deductibles,
    frequency,
  },
} = model;

export default {
  //step 1
  [planOptions.name]: 1,
  [withMultiplePremiumTiers.name]: false,
  [premiumTiersNumber.name]: 1,
  [selectedPremiumTier.name]: 1,
  [premiumTier.name]: "",
  [costFor.name]: "",
  [outOfNetworkCoverage.name]: "yes",
  [table.name]: [
    {
      idx: "1",
      outOfNetworkCoverage: "yes",
      hasoutOfNetworkIndividualOOP: true,
      tiers: {
        1: {
          range: "Default",
          mo: 0,
          ms: 0,
          mc: 0,
          mf: 0,
        }
      }
    },
  ],
  [label.name]: "",
  [deductibles.name]: [],
  //step 2
  [frequency.name]: 1,
};
