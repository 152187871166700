import React from "react";
import styled from "styled-components";

import { Link, Icon } from "components";

const Content = styled.div`
  vertical-align: center;
  text-align: center;
  margin: 0;
  padding: 20px 0;
  color: #111;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const FooterTwitter = () => {
  return (
    <Content>
      <Link
        href={process.env.REACT_APP_LINKEDIN_LINK}
        target="_blank"
        rel="noopener noreferrer"
        black
        icon
      >
        <Icon inIcon />
      </Link>
      <Link
        href={process.env.REACT_APP_INSTAGRAM_LINK}
        target="_blank"
        rel="noopener noreferrer"
        black
        icon
      >
        <Icon instagram />
      </Link>
      <Link
        href={process.env.REACT_APP_FACEBOOK_LINK}
        target="_blank"
        rel="noopener noreferrer"
        black
        icon
      >
        <Icon facebook />
      </Link>
    </Content>
  );
};

export default FooterTwitter;
