import { useState } from "react";

const useModal = () => {
  const [isShow, setIsShow] = useState(false);

  const handleClick = event => {
    if (event) event.preventDefault();

    const show = !isShow;
    setIsShow(show);
  };

  return {
    handleClick,
    isShow
  };
};

export default useModal;
