import React from "react";
import styled from "styled-components";
import { Table, TableRow, TableCell, Text } from "components";

const BorderCell = styled(TableCell)`
  border: 1px solid black;
  text-align: center;
`;

const TableTracking = ({ tracking }) => (
  <Table>
    <tbody>
      <TableRow header color="green">
        <BorderCell colSpan="5" width="100px">
          <Text fontSize="14">Users</Text>
        </BorderCell>
        <BorderCell colSpan="3" width="100px">
          <Text fontSize="14">Reports</Text>
        </BorderCell>
      </TableRow>
      <TableRow header color="green">
        <BorderCell width="100px">
          <Text fontSize="14">Total Repeat Logins</Text>
        </BorderCell>
        <BorderCell width="100px">
          <Text fontSize="14">Average Logins</Text>
        </BorderCell>
        <BorderCell width="100px">
          <Text fontSize="14">Total Users</Text>
        </BorderCell>
        <BorderCell width="100px">
          <Text fontSize="14">Total Survey Completed</Text>
        </BorderCell>
        <BorderCell width="100px">
          <Text fontSize="14">% Complete</Text>
        </BorderCell>
        <BorderCell width="100px">
          <Text fontSize="14">Total Paid Reports</Text>
        </BorderCell>
        <BorderCell width="100px">
          <Text fontSize="14">% Paid</Text>
        </BorderCell>
        <BorderCell width="100px">
          <Text fontSize="14">% Completed</Text>
        </BorderCell>
      </TableRow>
      <TableRow bottom>
        <BorderCell>{tracking.totalLogIns}</BorderCell>
        <BorderCell>{tracking.avgLogIns}</BorderCell>
        <BorderCell>{tracking.totalUsers}</BorderCell>
        <BorderCell>{tracking.surveyCompleted}</BorderCell>
        <BorderCell>{tracking.percentageCompleted}%</BorderCell>
        <BorderCell>{tracking.paidReports}</BorderCell>
        <BorderCell>{tracking.percentagePaid}%</BorderCell>
        <BorderCell>{tracking.percentageReportsCompleted}</BorderCell>
      </TableRow>
    </tbody>
  </Table>
);

export default TableTracking;
