import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import Text from "components/atoms/Text";
import TableFieldExternalPagination from "components/molecules/TableFieldExternalPagination";
import { TableTitleTooltip } from "components/organisms/CreatePlanSteps/StepNetworks/tableTitleTooltip";
import TableMenu from "components/molecules/TableMenu";
import { networkOptions } from "components/organisms/AddNetworksSteps/utils/options";
import { Select, Modal } from "antd";
import AutoCompleteField from "components/molecules/AutoCompleteField";

const StyledSelect = styled(Select)`
  min-height: 32px !important;

  display: flex !important;
  align-items: center !important;
  color: #344054 !important;

  .ant-select-selector {
    border: 1px solid #d0d5dd !important;
    border-radius: 8px !important;
    padding: 5px 10px !important;
    height: max-content !important;
  }

  width: 100% !important;
  @media (min-width: 768px) {
    width: 50% !important;
  }
`;

const CustomModal = styled(Modal)`
  min-width: 40vw;
  .ant-modal-body {
    padding-bottom: 24px;
  }

  @media (min-width: 769px) {
    width: 90vw;
  }

  label {
    width: 100% !important;
  }
`;

const columns = (handleOnSelect) =>
  [
    {
      title: "",
      dataIndex: "idx",
      width: 20,
      editable: false,
      fixed: "left",
    },
    {
      title: "Insurance Company",
      dataIndex: "name",
      width: 200,
      editable: true,
      inputType: "select",
      shouldCellUpdate: () => false,
      fixed: "left",
      dropdownMatchSelectWidth: 350,
      options: networkOptions,
      handleOnSelect: (value, index) => handleOnSelect(value, index),
      allowValueNotInOptions: true,
    },
    {
      title: (
        <TableTitleTooltip
          title={"Provider Directory URL"}
          tooltip={
            "This is the website where the insurance company allows you to search for doctors, hospitals, and other providers."
          }
        />
      ),
      dataIndex: "url",
      width: 300,
      editable: true,
      inputType: "text",
      inline: true,
      shouldCellUpdate: () => false,
    },
    {
      title: "Network Name",
      dataIndex: "row2",
      width: 200,
      editable: true,
      inputType: "text",
      inline: true,
      shouldCellUpdate: () => false,
      maxLength: 36,
    },
    {
      title: (
        <TableTitleTooltip
          title={"Extra Instructions"}
          tooltip={
            "If there are multiple steps on the insurance company website in order to search the correct provider network, describe them here."
          }
        />
      ),
      dataIndex: "extraInstructions",
      width: 300,
      editable: true,
      inputType: "textarea",
      inline: true,
      shouldCellUpdate: () => false,
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 48,
      render: (_, record) => <TableMenu record={record} />,
    },
  ].map((col) => {
    return {
      ...col,
      onCell: (_, idx) => ({
        index: idx,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: col.inputType,
        options: col.options,
        dropdownMatchSelectWidth: col.dropdownMatchSelectWidth,
        min: col.min,
        disabled: col.disabled || false,
        inline: col.inline || false,
        maxLength: col.maxLength || null,
        handleOnSelect: col.handleOnSelect || null,
        allowValueNotInOptions: col.allowValueNotInOptions || false,
      }),
    };
  });

const handleAddButton = (table, setFieldValue, tableName) => {
  const nextIndex = table.length !== 0 ? Math.max(...table.map((element) => element.idx)) + 1 : 1;
  const obj = {
    idx: Math.floor(nextIndex),
    tableName: tableName,
  };
  table.push(obj);
  setFieldValue(tableName, [...table]);
};

const Networks = () => {
  const { values, setFieldValue, setErrors, setTouched } = useFormikContext();
  const { networksTable, modalAutocomplete } = values;

  const [modal, setModal] = useState(false);
  const [modalOptions, setModalOptions] = useState([]);
  const [modalCurrentCompany, setModalCurrentCompany] = useState(null);
  const [modalSelectedIndex, setModalSelectedIndex] = useState(null);
  const [modalSelectedOption, setModalSelectedOption] = useState(null);
  const [modalUseAutocomplete, setModalUseAutocomplete] = useState(false);

  useEffect(() => {
    document.getElementById("form-content").scrollTo({ top: 0, behavior: "smooth" });
    setErrors({});
    setTouched({}, false);
  }, []);

  const autocompleteRow = ({ index, name, url, row2, extraInstructions }) => {
    if (name) setFieldValue(`networksTable[${index}].name`, name);
    setFieldValue(`networksTable[${index}].isSuggested`, {
      url: Boolean(url),
      row2: Boolean(row2),
      extraInstructions: Boolean(extraInstructions),
    });
    setFieldValue(`networksTable[${index}].url`, url);
    setFieldValue(`networksTable[${index}].row2`, row2);
    setFieldValue(`networksTable[${index}].extraInstructions`, extraInstructions);
  };

  const handleOnSelect = (value, index) => {
    const network = networkOptions.find((option) => option.value === value);
    if (network?.autocomplete?.length === 1) {
      const { url, row2, extraInstructions } = network.autocomplete[0];
      autocompleteRow({ index, url, row2, extraInstructions });
    } else if (network?.autocomplete?.length > 1) {
      setModalSelectedIndex(index);
      setModalOptions(network.autocomplete);
      if (network.value === "Other") {
        setModalSelectedOption({ name: networksTable[index].name, url: "", row2: "", extraInstructions: "" });
        setFieldValue(`networksTable[${index}].name`, networksTable[index].name);
      } else {
        setModalSelectedOption(network.autocomplete[0]);
      }
      setModalUseAutocomplete(
        network.value === "Blue Cross and/or Blue Shield (including Anthem)" || network.value === "Other",
      );
      setModalCurrentCompany(network.value);
      setModal(true);
    } else {
      autocompleteRow({ index, url: "", row2: "", extraInstructions: "" });
    }
  };

  return (
    <>
      <Text sectionTitle big>
        Add Insurance Network Options
      </Text>
      <Text fontSize={16} lineHeightUnits={"1.7em"}>
        Add your insurance network options. Note that you can add more than one network from the same insurance company.
      </Text>
      <>
        <TableFieldExternalPagination
          name="networksTable"
          columns={columns(handleOnSelect)}
          dataSource={networksTable}
          pageSize={10}
          tableLayout="fixed"
          handleAddButton={() => handleAddButton(networksTable, setFieldValue, "networksTable")}
        />
      </>
      <CustomModal
        title="Which One?"
        open={modal}
        destroyOnClose={true}
        onCancel={() => {
          setModalOptions([]);
          setModal(false);
          setModalCurrentCompany(null);
        }}
        onOk={() => {
          autocompleteRow({
            index: modalSelectedIndex,
            name:
              modalAutocomplete ||
              (modalCurrentCompany === "Blue Cross and/or Blue Shield (including Anthem)" && modalSelectedOption.name),
            url: modalSelectedOption.url,
            row2: modalSelectedOption.row2,
            extraInstructions: modalSelectedOption.extraInstructions,
          });
          setFieldValue("modalAutocomplete", "");
          setModalSelectedOption(null);
          setModalOptions([]);
          setModal(false);
        }}
        className={"networks-modal"}
      >
        {modalUseAutocomplete ? (
          <AutoCompleteField
            name="modalAutocomplete"
            size="fullwidth"
            options={modalOptions.map((option) => ({
              value: option.name,
              label: option.name,
            }))}
            minCharacters={modalCurrentCompany === "Other" ? 3 : 0}
            onChange={(value) => {
              const option = modalOptions.find((option) => option.name === value);
              setModalSelectedOption(option || { name: value, url: "", row2: "", extraInstructions: "" });
            }}
          />
        ) : (
          <StyledSelect
            onChange={(value) => {
              const option = modalOptions.find((option) => option.name === value);
              setModalSelectedOption(option);
            }}
            options={modalOptions.map((option) => ({
              value: option.name,
              label: option.name,
            }))}
            defaultValue={modalOptions[0]?.name}
          />
        )}
      </CustomModal>
    </>
  );
};

export default Networks;
