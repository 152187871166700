import { message } from "antd";
import { networkOptions, blueCrossBlueShieldOptions } from "components/organisms/AddNetworksSteps/utils/options";

const big5Networks = networkOptions
  .filter((network) => network.value !== "Other")
  .map((network) => network.value)
  .concat(blueCrossBlueShieldOptions);
const big5NetworksWithoutKaiser = big5Networks.filter((network) => network !== "Kaiser");

const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};

const displayErrorMessage = (rows, errorMessage) => {
  message.error(`Error: ${errorMessage} in row${rows.length > 1 ? "s" : ""} ${rows.join(", ")}.`);
};

const verifyEmptyInsuranceCompany = (networkTable) => {
  const invalidIdx = [];
  networkTable.forEach((network) => {
    if (!network.name || network.name === "") {
      invalidIdx.push(network.idx);
    }
  });

  return invalidIdx;
};

const verifyEmptyUrl = (networkTable) => {
  const invalidIdx = [];
  networkTable.forEach((network) => {
    if (big5Networks.includes(network.name) && (!network.url || network.url === "")) {
      invalidIdx.push(network.idx);
    }
  });

  return invalidIdx;
};

const verifyInvalidUrl = (networkTable) => {
  const invalidIdx = [];
  networkTable.forEach((network) => {
    if (!isValidUrl(network.url) && network.url && network.url !== "") {
      invalidIdx.push(network.idx);
    }
  });

  return invalidIdx;
};

const verifyEmptyNetworkName = (networkTable) => {
  const invalidIdx = [];
  networkTable.forEach((network) => {
    if (big5Networks.includes(network.name) && (!network.row2 || network.row2 === "")) {
      invalidIdx.push(network.idx);
    }
  });

  return invalidIdx;
};

const verifyRepeatedNetworkName = (networkTable) => {
  const invalidIdx = [];

  networkTable = networkTable.map((network) => {
    return {
      row2: network.row2 || "",
      ...network,
    };
  });

  for (let i = 0; i < networkTable.length; i++) {
    const repeatedNetworkNames = [];
    for (let j = i + 1; j < networkTable.length; j++) {
      if (networkTable[i].name === networkTable[j].name && networkTable[i].row2 === networkTable[j].row2) {
        if (repeatedNetworkNames.length === 0) repeatedNetworkNames.push(networkTable[i]);
        repeatedNetworkNames.push(networkTable[j]);
      }
    }

    if (repeatedNetworkNames.length > 0) {
      invalidIdx.push(repeatedNetworkNames.map((item) => item.idx));
      break;
    }
  }

  return invalidIdx;
};

const verifyEmptyExtraInstructions = (networkTable) => {
  const invalidIdx = [];
  networkTable.forEach((network) => {
    if (
      big5NetworksWithoutKaiser.includes(network.name) &&
      (!network.extraInstructions || network.extraInstructions === "")
    ) {
      invalidIdx.push(network.idx);
    }
  });

  return invalidIdx;
};

const verifyMinorInsuranceCompaniesNetworkNames = (networkTable) => {
  const networkNames = networkTable.map((network) => network.name);
  const repeatedNetworkNames = networkNames.filter((name, idx) => networkNames.indexOf(name) !== idx);
  let invalidCompanyName = "";
  networkTable.forEach((network) => {
    if (repeatedNetworkNames.includes(network.name) && (!network.row2 || network.row2 === "")) {
      invalidCompanyName = network.name;
    }
  });

  return invalidCompanyName;
};

export const validateNetworksTable = (networkTable) => {
  let invalidIdx = [];

  invalidIdx = verifyEmptyInsuranceCompany(networkTable);
  if (invalidIdx.length > 0) {
    displayErrorMessage(invalidIdx, "empty Insurance Company");
    return false;
  }
  invalidIdx = [];

  invalidIdx = verifyEmptyUrl(networkTable);
  if (invalidIdx.length > 0) {
    displayErrorMessage(invalidIdx, "empty URL");
    return false;
  }
  invalidIdx = [];

  invalidIdx = verifyInvalidUrl(networkTable);
  if (invalidIdx.length > 0) {
    displayErrorMessage(invalidIdx, "invalid URL");
    return false;
  }
  invalidIdx = [];

  invalidIdx = verifyEmptyNetworkName(networkTable);
  if (invalidIdx.length > 0) {
    displayErrorMessage(invalidIdx, "empty Network Name");
    return false;
  }
  invalidIdx = [];

  invalidIdx = verifyRepeatedNetworkName(networkTable);
  if (invalidIdx.length > 0) {
    displayErrorMessage(invalidIdx, "repeated Network Name");
    return false;
  }
  invalidIdx = [];

  invalidIdx = verifyEmptyExtraInstructions(networkTable);
  if (invalidIdx.length > 0) {
    displayErrorMessage(invalidIdx, "empty Extra Instructions");
    return false;
  }

  const invalidCompanyName = verifyMinorInsuranceCompaniesNetworkNames(networkTable);
  if (invalidCompanyName !== "") {
    message.error(
      `Error: You've added 2 ${invalidCompanyName} networks. If you have 2 different ${invalidCompanyName} networks, please add different Network Names so that you can tell them apart. If they are duplicates, please delete one.`,
    );
    return false;
  }

  return true;
};

export const warnEmptyUrl = (networkTable) => {
  const invalidIdx = [];
  networkTable.forEach((network) => {
    if (!network.url || network.url === "") {
      invalidIdx.push(network.idx);
    }
  });

  return invalidIdx;
};
