import React from "react";
import styled from "styled-components";
import "./index.css";

const StyledButton = styled.button`
  background-color: transparent;
  padding: 20px;
  border: 2px solid #D0D5DD;
  border-radius: 8px;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  width: 100%;

  font-family: circular;
  font-style: normal;
  font-weight: bold;
  color: #21355B;
  font-size: 16px;
  line-height: 40px;
`;

const CustomSelect = ({ onClick }) => (
  <>
    <StyledButton
      onClick={onClick}
    >
      Select a plan
    </StyledButton>
  </>
);

export default CustomSelect;
