import React from "react";
import { isEqual } from "lodash";
import styled from "styled-components";
import { optimize } from "services/api/admin.api";
import { updateReport } from "services/api/report.api";
import { getToken } from "services/utils";
import { message } from "antd";

import {
  CalculateHeader as Header2,
  OptimizeTable,
  CrystalBall,
  Text,
  Spinner,
  Switcher,
  Link,
  AntModalView as ModalView,
  OptimizeModal,
} from "components";

const Wrapper = styled.section`
  margin: 20px auto;
  display: flex;

  min-height: 477px;
`;

const FlexWrapper = styled.div`
  display: flex;
  ${({ block }) => block && `display: block`}
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 0;
  ${({ fixed }) => fixed && `margin: 0 auto 0 auto; height: 500px;`}
  ${({ fixedTop }) => fixedTop && `float: right; margin: 0 auto 0 auto;`}
  ${({ leftNoMargin }) => leftNoMargin && `margin-left: 5px;`}
  ${({ col }) => col && `flex-direction: column;`}
  ${({ maxWidth }) =>
    maxWidth
      ? `max-width: ${maxWidth}; min-width: 300px;`
      : `min-width: 500px;`}
  ${({ left }) =>
    left &&
    `
    float: left;
    margin-left: 30px;
    margin-right: 20px;
    min-width: 250px;
  `}
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  ${({ header }) =>
    header &&
    `
    margin: auto;
    margin-top: 0;
    padding: 0;
    flex-direction: column;
  `}
`;

class OptimizeReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      billIds: [],
      apiCount: 0,
      isInsuranceTerms: false,
      isSwitching: false,
      isNoLabor: false,
      showOptions: ["LCL", "HC"],
      formShow: false,
      modalUse: 0,
      modalShow: false,
      modalLoading: false,
      modalDisabled: false,
      icons: ["user", "user"],
      optimization: [],
      option: [],
      options: [],
      error: null,
    };

    this.toggleShow = this.toggleShow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.canSwitch = this.canSwitch.bind(this);
    this.handleChangeShow = this.handleChangeShow.bind(this);
    this.handleUpdateReport = this.handleUpdateReport.bind(this);
  }

  async componentDidMount() {
    this.setState({
      billIds: this.props.billIds,
      report: this.props.custom,
      apiCount: 1,
      error: null,
    });

    const form = { reportId: this.props.customId, billIds: this.props.billIds };
    const data = await optimize(form);

    if (data.data && data.status === 200) {
      const showOptions = ["HC", "LCL"];
      const options = data.data.filter((optimization) => {
        return showOptions.includes(optimization.id);
      });
      const option = data.data.find(
        (optimization) => optimization.id === "LCL"
      );

      this.setState({
        optimization: data.data,
        option: option,
        options: options.reverse(),
        loading: false,
        error: null,
      });
    } else {
      this.setState({
        optimization: null,
        loading: false,
        error: "Report not found",
      });
      message.error("Report not found");
    }
  }

  toggleShow({ name, value }) {
    if (name === "modalShow" && this.state.modalDisabled) {
      this.setState({ isSwitching: false });
    }

    this.setState({ [name]: !value });
  }

  handleChange({ target: { name, value } }) {
    this.setState({ [name]: value });
  }

  canSwitch() {
    const { optimization } = this.state;
    const showOptions = ["LCS", "LCBS"];

    const options = optimization.filter((optimization) => {
      return showOptions.includes(optimization.id);
    });

    if (options.length === 0) {
      this.setState({ modalDisabled: true });
      this.handleChangeShow("switching");
      return false;
    } else {
      this.handleChangeShow("switching");
    }
  }

  //Lowest Cost = LC, Highest Cost = HC Lowest Cost w/o Ignored = LCL,
  //Lowest Cost Switching = LCS, Lowest Cost Botched Switching = LCBS,
  //Lowest Cost w/o Labor = LCNL, Lowest Cost No Planned Labor = LCNP,
  //Lowest Cost Unplanned Labor = LCUP
  handleChangeShow(key, newOption) {
    const { optimization, isSwitching, isNoLabor, modalUse } = this.state;
    let showOptions, option;
    let options = [];

    if (key === "switching" && !isSwitching) {
      showOptions = ["LCS", "LCBS"];
      options = optimization.filter((optimization) => {
        return showOptions.includes(optimization.id);
      });
      option = optimization.find((optimization) => optimization.id === "LCS");

      if (options.length > 0) {
        if (modalUse > 0) {
          this.setState({
            isSwitching: true,
            isNoLabor: false,
          });
        } else {
          this.setState(
            {
              isSwitching: true,
              isNoLabor: false,
              modalUse: modalUse + 1,
              modalShow: true,
              modalLoading: true,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  modalLoading: false,
                  modalShow: false,
                });
              }, 7000);
            }
          );
        }
      } else {
        this.setState(
          {
            modalShow: true,
            modalLoading: true,
          },
          () => {
            setTimeout(() => {
              this.setState({ modalLoading: false });
            }, 7000);
          }
        );
      }
    } else if (key === "noLabor" && !isNoLabor) {
      showOptions = ["LCNP", "LCUP"];
      // showOptions = [
      //   "LC",
      //   "LCNL",
      //   "LCNP",
      //   "LCUP"
      // ]
      options = optimization.filter((optimization) => {
        return showOptions.includes(optimization.id);
      });
      option = optimization.find((optimization) => optimization.id === "LCNP");
      this.setState({
        isSwitching: false,
        isNoLabor: true,
      });
    } else if (key === "option") {
      option = option = optimization.find(
        (optimization) => optimization.id === newOption
      );
    } else {
      showOptions = ["LCL", "HC"];
      options = optimization
        .filter((optimization) => {
          return showOptions.includes(optimization.id);
        })
        .reverse();
      option = optimization.find((optimization) => optimization.id === "LCL");

      this.setState({
        isSwitching: false,
        isNoLabor: false,
      });
    }

    this.setState({
      option: option ? option : this.state.option,
      options: options.length > 0 ? options : this.state.options,
    });
  }

  async handleUpdateReport(newReport) {
    const { report, billIds } = this.state;
    const { customId } = this.props;
    const token = await getToken();

    if (!isEqual(newReport, report)) {
      this.setState({ loading: true, error: null });

      await updateReport(customId, newReport, token);

      const form = { reportId: newReport._id, billIds };
      const data = await optimize(form, token);

      if (data.data && data.status === 200) {
        const showOptions = ["HC", "LCL"];
        const options = data.data.filter((optimization) => {
          return showOptions.includes(optimization.id);
        });
        const option = data.data.find(
          (optimization) => optimization.id === "LCL"
        );

        this.setState({
          report: newReport,
          optimization: data.data,
          option: option,
          options: options.reverse(),
          loading: false,
          error: null,
        });
      } else {
        this.setState({
          optimization: null,
          loading: false,
          error: "Report not found",
        });
        message.error("Report not found");
      }
    }
  }

  renderHeading() {
    const {
      optimization,
      billIds,
      report,
      isInsuranceTerms,
      isSwitching,
      isNoLabor,
      option,
      formShow,
    } = this.state;
    let lineOne, newOption, lineTwo;

    if (isInsuranceTerms) {
      lineOne = ["", "Cost of Coverage"];
    } else {
      if (isSwitching) {
        lineOne = ["", " System Gamed"];
      } else if (isNoLabor) {
        lineOne =
          option.id === "LCNP"
            ? [" No Labor", " Cost Coverage"]
            : [" Unplanned Labor", " Cost Coverage"];
        newOption = option.id === "LCNP" ? "LCUP" : "LCNP";
      } else {
        lineOne =
          option.id === "LC" || option.id === "LCL"
            ? [" Lowest", " Cost Coverage"]
            : [" Highest", " Cost Coverage"];
        newOption = option.id === "LCL" ? "HC" : "LCL";
      }
    }

    lineTwo = "Among All Plans";

    const planOptions = optimization.find(
      (optimization) => optimization.id === "OPT"
    );

    return (
      <InnerWrapper header>
        <Text arial fontSize="16">
          <Link
            gray
            underline
            fontSize="16"
            onClick={() => this.handleChangeShow("option", newOption)}
          >
            {lineOne[0]}
          </Link>
          {lineOne[1]}
        </Text>
        <Text arial fontSize="16">
          {lineTwo}
        </Text>
        {/* <Link
          green
          noMargin
          fontSize="12"
          onClick={() => this.toggleShow({ name: "formShow", value: formShow })}
        >
          change my preferences
        </Link> */}
        <OptimizeModal
          report={report}
          billIds={billIds}
          options={planOptions}
          show={formShow}
          close={() => this.toggleShow({ name: "formShow", value: formShow })}
          handleUpdate={this.handleUpdateReport}
        />
      </InnerWrapper>
    );
  }

  render() {
    const {
      error,
      option,
      icons,
      options,
      isInsuranceTerms,
      isSwitching,
      isNoLabor,
      modalShow,
      modalLoading,
      modalDisabled,
      loading,
    } = this.state;

    return (
      <>
        <Header2 bigTitle={true} title="Your Recommendations" icon="search" />
        <hr />
        <Wrapper>
          <FlexWrapper col left fixed maxWidth="315px">
            <Text arial center margin="35px auto" fontSize="24">
              CONTROL PANEL
            </Text>
            <InnerWrapper>
              <Switcher
                checked={isInsuranceTerms}
                optionA="Fortune Teller Mode"
                optionB="Spreadsheet Mode"
                onChange={() =>
                  this.toggleShow({
                    name: "isInsuranceTerms",
                    value: isInsuranceTerms,
                  })
                }
              />
            </InnerWrapper>
            <InnerWrapper>
              <Switcher
                checked={isSwitching}
                loading={modalLoading}
                disabled={loading || modalDisabled}
                optionA="Open Enrollment"
                optionB="Qualifying Life Event"
                onClick={() => this.canSwitch()}
              />
            </InnerWrapper>
            <InnerWrapper>
              <Switcher
                checked={isNoLabor}
                disabled={loading}
                optionA="Due Date"
                optionB="No Due Date"
                onChange={() => this.handleChangeShow("noLabor")}
              />
            </InnerWrapper>
            <ModalView
              close={() =>
                this.toggleShow({ name: "modalShow", value: modalShow })
              }
              isShow={modalShow}
              title="Game the System!"
            >
              {modalLoading ? (
                <React.Fragment>
                  <Text modal bold center fontSize="18" lineHeight="25px">
                    We're checking if you can save any money by using your
                    baby's birth as a Qualifying Life Event to change your
                    insurance
                  </Text>
                  <FlexWrapper>
                    <Spinner />
                  </FlexWrapper>
                  <Text modal center fontSize="14">
                    CAUTION: This requires extra paperwork, and not all plans
                    let you do it
                  </Text>
                </React.Fragment>
              ) : (
                <Text modal bold center fontSize="18" lineHeight="25px">
                  Good news! You're already getting the best deal without trying
                  to switch plans.
                </Text>
              )}
            </ModalView>
          </FlexWrapper>
          {loading ? (
            <FlexWrapper col fixed>
              Optimizing Your Options...
              <Spinner />
            </FlexWrapper>
          ) : error ? (
            <FlexWrapper>
              <Text fontSize="24">
                We're sorry. We're having trouble processing your report at this
                time.
              </Text>
            </FlexWrapper>
          ) : (
            <FlexWrapper leftNoMargin={isInsuranceTerms} block>
              <FlexWrapper col maxWidth="60%">
                {this.renderHeading()}
              </FlexWrapper>
              {!isInsuranceTerms ? (
                <FlexWrapper col maxWidth="60%">
                  <CrystalBall data={option} icons={icons} />
                </FlexWrapper>
              ) : (
                <FlexWrapper col leftNoMargin maxWidth="100%">
                  <OptimizeTable data={options} />
                </FlexWrapper>
              )}
            </FlexWrapper>
          )}
        </Wrapper>
      </>
    );
  }
}

export default OptimizeReport;
