import React from "react";
import styled from "styled-components";
import aetna from "./aetna.svg";
import bcbs from "./bcbs.svg";
import cigna from "./cigna.svg";
import kaiser from "./kaiser.svg";
import united from "./united.svg";

const MiddleWrapper = styled.div`
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  max-height: 100px;
`;

const Image = styled.img`
  width: ${({ width }) => width || "60px"};
`;

const MyPlansMiddleWrapperInsuranceCompanies = () => {
  return (
    <MiddleWrapper>
      <Image width={"64px"} src={aetna} />
      <Image width={"70px"} src={bcbs} />
      <Image width={"56px"} src={cigna} />
      <Image width={"48px"} src={kaiser} />
      <Image src={united} />
    </MiddleWrapper>
  );
};

export default MyPlansMiddleWrapperInsuranceCompanies;
