import React, { useEffect, useState } from "react";
import { getToken } from "services/utils";
import { addReport, updateRole, addGroupToUser } from "services/api/account.api";
import { updateReport } from "services/api/admin.api";
import { getGroupById } from "services/api/groups.api";
import { getNetworksGridsList, updateNetworksGrid } from "services/api/networks-grids.api";
import moment from "moment";
import { Button, Divider, Input, Form, message, Space, DatePicker, Switch, Select, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { getReportsHeader } from "services/api";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import styled from "styled-components";

const { Item } = Form;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const EllipsisWrapper = styled.div`
  max-width: 200px;
  min-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const UserForm = ({ user, handleChange }) => {
  const {
    _id,
    username,
    alternativeEmails,
    subscription,
    marketplaceGroups,
    groups,
    groupsWithProfessionalAccess,
    role,
    professionalLicenseExpiresAt,
  } = user;

  const [reports, setReports] = useState([]);
  const [currentReport, setCurrentReport] = useState(user.currentReport);
  const [newReport, setNewReport] = useState("");
  const [newGroup, setNewGroup] = useState("");
  const [token, setToken] = useState();
  const [professionalGroupList, setProfessionalGroupList] = useState([]);

  const [form] = Form.useForm();
  const [roleForm] = Form.useForm();
  const roleValueFromForm = Form.useWatch("role", roleForm);

  const loadReports = async () => {
    const token = await getToken();
    setToken(token);
    const response = await getReportsHeader([...user.reports, currentReport], token);
    if (response.data) {
      const reports = response.data.reports;
      const changed = reports.map((item) => {
        return {
          ...item,
          newFulfilledDate: false,
          newShow: item.showOnRecommendations,
        };
      });
      setReports(changed);
    }
  };

  const loadGroups = async () => {
    if (!groupsWithProfessionalAccess) return;
    const groupsList = [];
    for (let i = 0; i < groupsWithProfessionalAccess.length; i++) {
      const result = await getGroupById({ id: groupsWithProfessionalAccess[i] });
      if (result.status !== 200) continue;
      const groupObj = result.data.group || {};
      const networkGridsFilteredIdList = groupObj.masterNetworkGrids?.filter(
        (item) => !groupObj.masterReports.includes(item),
      );
      const networkGridsResult = networkGridsFilteredIdList.length
        ? await getNetworksGridsList({ idList: groupObj.masterNetworkGrids })
        : {};
      if (networkGridsResult.status === 200) {
        const networkGrids = networkGridsResult.data.grids;
        for (let j = 0; j < networkGrids.length; j++) {
          groupsList.push({ group: groupObj, networkGrid: networkGrids[j] });
        }
      }

      const reportsResult = await getReportsHeader(groupObj.masterReports, token);
      if (reportsResult.status === 200) {
        const reports = reportsResult.data.reports;
        for (let j = 0; j < reports.length; j++) {
          groupsList.push({ group: groupObj, report: reports[j] });
        }
      }
    }
    setProfessionalGroupList(
      groupsList
        .filter((item) => item.report || item.networkGrid)
        .map((item) => {
          return {
            ...item,
            newShow: item.networkGrid ? item.networkGrid.showOnMyPlans : item.report.showOnMyPlans,
            newFulfilledDate: false,
          };
        })
        .sort((a, b) => {
          const dateA = a.networkGrid ? a.networkGrid.fulfilledDate || a.networkGrid.updatedAt : a.report.fulfilledDate;
          const dateB = b.networkGrid ? b.networkGrid.fulfilledDate || b.networkGrid.updatedAt : b.report.fulfilledDate;
          return new Date(dateB) - new Date(dateA);
        }),
    );
  };

  const handleAddReport = async () => {
    const response = await addReport(_id, newReport, token);

    if (response.status === 200) {
      handleChange({
        userId: response.data.user._id,
        user: response.data.user,
      });

      message.success(`Recommendation ${newReport} Added Successfully`);
    } else {
      message.error("Recommendation not found");
    }
  };

  const handleUpdateReport = async (report, i) => {
    const formattedDate = report.newFulfilledDate
      ? moment(report.newFulfilledDate).format("MM/DD/YYYY")
      : report.fulfilledDate;

    const newReport = {
      ...report,
      fulfilledDate: formattedDate,
      showOnRecommendations: report.newShow,
    };
    delete newReport.newFulfilledDate;
    delete newReport.newShow;

    const response = await updateReport(report._id, newReport, token);
    response.status === 200
      ? message.success("Recommendation Updated Successfully")
      : message.error("Error Updating Recommendation");

    const reset = reports.map((item, index) => {
      return {
        ...item,
        newFulfilledDate: i === index ? false : item.newFulfilledDate,
        showOnRecommendations: i === index ? newReport.showOnRecommendations : item.showOnRecommendations,
        newShow: i === index ? newReport.showOnRecommendations : item.newShow,
      };
    });
    setReports(reset);
  };

  const handleAddGroup = async () => {
    const response = await addGroupToUser({ userId: _id, group: newGroup });

    if (response.status === 200) {
      handleChange({
        userId: response.data.user._id,
        user: response.data.user,
      });
      setNewGroup("");
      message.success(response.data.message);
    } else {
      message.error("Group not found");
    }
  };

  const handleUpdateGroupsMasterItem = async ({ i }) => {
    const item = professionalGroupList[i];
    const newShow = item.newShow;
    const networkGrid = item.networkGrid;
    const report = item.report;
    const newFullfilledDate = item.newFulfilledDate
      ? moment(item.newFulfilledDate).format("MM/DD/YYYY")
      : networkGrid
      ? networkGrid.fulfilledDate
      : report.fulfilledDate;
    if (networkGrid) {
      const newNetworkGrid = { ...networkGrid, showOnMyPlans: newShow, fulfilledDate: newFullfilledDate };
      await updateNetworksGrid({ id: networkGrid._id, form: newNetworkGrid });
      const updatedNetworkGrids = professionalGroupList.map((item, index) => {
        return {
          ...item,
          networkGrid: i === index ? newNetworkGrid : item.networkGrid,
          newShow: i === index ? newShow : item.newShow,
          newFulfilledDate: i === index ? false : item.newFulfilledDate,
        };
      });
      setProfessionalGroupList(updatedNetworkGrids);
    } else if (report) {
      const newReport = { ...report, showOnMyPlans: newShow, fulfilledDate: newFullfilledDate };
      await updateReport(report._id, newReport, token);
      const updatedReports = professionalGroupList.map((item, index) => {
        return {
          ...item,
          report: i === index ? newReport : item.report,
          newShow: i === index ? newShow : item.newShow,
          newFulfilledDate: i === index ? false : item.newFulfilledDate,
        };
      });
      setProfessionalGroupList(updatedReports);
    }
  };

  useEffect(() => {
    setCurrentReport(user.currentReport);
    loadReports();
    loadGroups();
  }, [user]);

  const workEmail = alternativeEmails?.filter((item) => item.emailType === "workEmail");
  const extraNonWorkEmail = alternativeEmails?.filter((item) => item.emailType === "extraNonWorkEmail");

  return (
    <Form {...layout} form={form} initialValues={user} layout="horizontal">
      <Divider orientation="left">Account Details</Divider>
      <Item label="User ID">{_id}</Item>
      <Item label="Email">{username}</Item>
      {!!workEmail?.length && (
        <Item label="Work Email">
          <Space direction="vertical" size="small" style={{ display: "flex", marginTop: 5 }}>
            {workEmail.map((item) => (
              <>{item.email}</>
            ))}
          </Space>
        </Item>
      )}
      {!!extraNonWorkEmail?.length && (
        <Item label="Extra Non-Work Email">
          <Space direction="vertical" size="small" style={{ display: "flex", marginTop: 5 }}>
            {extraNonWorkEmail.map((item) => (
              <>{item.email}</>
            ))}
          </Space>
        </Item>
      )}
      {groups && Object.keys(groups).length > 0 && (
        <Item label="Groups">
          <Space direction="vertical" size="small" style={{ display: "flex", marginTop: 5 }}>
            {Object.keys(groups).map((item) => (
              <>{`${item} - ${groups[item].current}`}</>
            ))}
          </Space>
        </Item>
      )}
      <Item label="Subscription Status">{subscription ? subscription.tier : "free"}</Item>
      <Item label="Subscription Expiration">{subscription ? subscription.validUntil : "n/a"}</Item>
      <Item label="Role">{role ? role : "customer"}</Item>
      {role === "professional" && <Item label="Professional License Expires at">{professionalLicenseExpiresAt}</Item>}
      <Item label="Change Role">
        <Form
          form={roleForm}
          onFinish={async (changes) => {
            const token = await getToken();
            const response = await updateRole({ username, role: changes.role, token });
            if (response.status === 200) {
              message.success("Role Updated Successfully");
              const user = response.data.user;
              handleChange({
                userId: user._id,
                user,
              });
            } else {
              message.error("Error Updating Role");
            }
          }}
          preserve={false}
          layout="horizontal"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          initialValues={{
            role: role,
          }}
        >
          <Item name="role">
            <Select style={{ maxWidth: "50%" }}>
              <Select.Option value="">Customer</Select.Option>
              <Select.Option value="professional">Professional</Select.Option>
              <Select.Option value="admin">Admin</Select.Option>
            </Select>
          </Item>
          {roleValueFromForm === "admin" ? (
            <Popconfirm
              title={`Are you sure you want to make ${username} an admin?`}
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                roleForm.submit();
              }}
            >
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            </Popconfirm>
          ) : (
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          )}
        </Form>
      </Item>
      {marketplaceGroups && marketplaceGroups.length ? (
        <>
          <Divider orientation="left">Marketplace Options Generated for This User</Divider>
          {marketplaceGroups
            ? marketplaceGroups.map((group, i) => (
                <Item label={i + 1} key={`marketplace` + group} style={{ marginBottom: "5px" }}>
                  {group}
                </Item>
              ))
            : "No group found"}
        </>
      ) : null}
      {role === "professional" || role === "admin" ? (
        <>
          <Divider orientation="left">My Plans</Divider>
          <Link to={`my-plans/${_id}`} target="_blank" rel="noopener noreferrer" style={{ marginLeft: "200px" }}>
            View Groups
          </Link>
          {professionalGroupList.map((item, i) => {
            const { _id, name, displayName } = item.group;
            const showOnMyPlans = item.networkGrid ? item.networkGrid.showOnMyPlans : item.report.showOnMyPlans;
            const date = moment(
              item.networkGrid
                ? item.networkGrid.fulfilledDate || new Date(item.networkGrid.updatedAt)
                : item.report.fulfilledDate,
              "MM/DD/YYYY",
            );
            return (
              <Item label={i + 1} key={i} style={{ marginBottom: "5px" }}>
                <Space size="small">
                  <div style={{ minWidth: 120 }}>{_id}</div>
                  <EllipsisWrapper>{name}</EllipsisWrapper>
                  <EllipsisWrapper>{displayName}</EllipsisWrapper>
                  <EllipsisWrapper>
                    {item.networkGrid ? `Networks ID: ${item.networkGrid._id}` : `Report ID: ${item.report._id}`}
                  </EllipsisWrapper>
                  <DatePicker
                    bordered={false}
                    allowClear={false}
                    format={["MM/DD/YYYY", "MM/DD/YY", "M/D/YYYY", "M/D/YY"]}
                    defaultValue={date}
                    onChange={(e) => {
                      const changed = professionalGroupList.map((item, index) => {
                        return {
                          ...item,
                          newFulfilledDate: (item.newFulfilledDate || i === index) && e._d,
                        };
                      });
                      setProfessionalGroupList(changed);
                    }}
                  />
                  <Switch
                    style={{ marginRight: 10 }}
                    checkedChildren={<EyeOutlined />}
                    unCheckedChildren={<EyeInvisibleOutlined />}
                    size="small"
                    defaultChecked={showOnMyPlans}
                    onChange={() => {
                      const changed = professionalGroupList.map((item, index) => {
                        return {
                          ...item,
                          newShow: i === index ? !item.newShow : item.newShow,
                        };
                      });
                      setProfessionalGroupList(changed);
                    }}
                  />
                  {(showOnMyPlans !== item.newShow || item.newFulfilledDate) && (
                    <Button
                      type="primary"
                      onClick={() =>
                        handleUpdateGroupsMasterItem({
                          i: i,
                        })
                      }
                      size="small"
                    >
                      Save
                    </Button>
                  )}
                </Space>
              </Item>
            );
          })}
          <Item label="Add New Group">
            <Input value={newGroup} onChange={(e) => setNewGroup(e.target.value)} />
          </Item>
          <Button type="primary" onClick={handleAddGroup} style={{ marginLeft: "200px" }}>
            Add Group
          </Button>
        </>
      ) : null}

      <Divider orientation="left">Reports</Divider>
      <Link to={`recommendations/${_id}`} target="_blank" rel="noopener noreferrer" style={{ marginLeft: "200px" }}>
        View Recommendations
      </Link>
      <div style={{ marginBottom: 24 }}>
        {reports.map((report, i) => {
          const { birthParent, dates, partner, title } = report;
          const startYear = dates[0].split("/")[2];
          const endYear = dates[1].split("/")[2];
          const year = startYear === endYear ? startYear : `${startYear}/${endYear.slice(2)}`;
          const companyTitle =
            birthParent.company && partner.company
              ? birthParent.company + " vs. " + partner.company + " " + year
              : birthParent.company
              ? birthParent.company + " " + year
              : partner.company
              ? partner.company + " " + year
              : "New Request (2020)";
          return (
            <Item label={i + 1} key={i} style={{ marginBottom: "5px" }}>
              <Space size="small">
                <div style={{ minWidth: 120 }}>{report._id}</div>
                <EllipsisWrapper>{companyTitle}</EllipsisWrapper>
                <EllipsisWrapper>{title}</EllipsisWrapper>
                <DatePicker
                  bordered={false}
                  allowClear={false}
                  format={["MM/DD/YYYY", "MM/DD/YY", "M/D/YYYY", "M/D/YY"]}
                  defaultValue={moment(report.fulfilledDate, "MM/DD/YYYY")}
                  onChange={(e) => {
                    const changed = reports.map((item, index) => {
                      return {
                        ...item,
                        newFulfilledDate: (item.newFulfilledDate || i === index) && e._d,
                      };
                    });
                    setReports(changed);
                  }}
                />
                <Switch
                  style={{ marginRight: 10 }}
                  checkedChildren={<EyeOutlined />}
                  unCheckedChildren={<EyeInvisibleOutlined />}
                  size="small"
                  defaultChecked={report.showOnRecommendations}
                  onChange={() => {
                    const changed = reports.map((item, index) => {
                      return {
                        ...item,
                        newShow: i === index ? !item.newShow : item.newShow,
                      };
                    });
                    setReports(changed);
                  }}
                />
                {(report.newFulfilledDate || report.showOnRecommendations !== report.newShow) && (
                  <Button type="primary" onClick={() => handleUpdateReport(report, i)} size="small">
                    Save
                  </Button>
                )}
              </Space>
            </Item>
          );
        })}
      </div>

      <Item label="Current Recommendation">{currentReport ? currentReport : "None"}</Item>

      <Item label="Add A New Recommendation">
        <Input value={newReport} onChange={(e) => setNewReport(e.target.value)} />
      </Item>
      <Button type="primary" onClick={handleAddReport} style={{ marginLeft: "200px" }}>
        Add Recommendation
      </Button>
    </Form>
  );
};

export default UserForm;
