import React, { useState } from "react";
import styled from "styled-components";
import { CalculateHeader as Header, Text, FormDate as Date } from "components";
import { Icon } from "@ant-design/compatible";

const Wrapper = styled.section``;

const FlexWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const InnerWrapper = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.button`
  border: 3px solid #00a19b;
  border-radius: 25px;
  margin: 15px 12px;
  width: 250px;
  height: 100px;
  outline: none;
  cursor: pointer;
  color: #00a19b;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  ${({ active }) =>
    active
      ? `color: orange; border: 5px solid orange;`
      : `color: #00a19b; border: 3px solid #00a19b;`}
  :hover {
    opacity: 0.5;
  }
  :focus {
    color: orange;
    border: 5px solid orange;
  }
`;

const DeadlineSection = ({ handleNext, onChange, deadline }) => {
  const [active, setActive] = useState(null);
  const [date, setDate] = useState(null);

  const handleChange = ({ target: { name, value } }) => {
    setDate(value);
    onChange(name, value);
    setActive("opt1");
    handleNext();
  };

  const handleNoDeadline = () => {
    setDate(null);
    setActive("opt2");
    onChange("deadline", "I don't know");
    handleNext();
  };

  const handleActive1 = (e) => {
    if (e.target.name === "opt1") setActive("opt3");
  };

  const handleActive2 = (e) => {
    setActive("opt3");
  };

  return (
    <Wrapper>
      <Header
        bigTitle={true}
        title="When is your insurance deadline?"
        greenText="If one partner’s deadline is earlier than the other partner’s deadline, tell us the earlier one."
      />
      <FlexWrapper onClick={handleActive1}>
        <ButtonWrapper name="opt1" active={active === "opt1" ? true : false}>
          {active === "opt3" ? (
            <Date
              placeholder="MM-DD-YYYY"
              name="deadline"
              handleChange={handleChange}
              value={date}
              open={true}
            />
          ) : (
            <InnerWrapper onClick={handleActive2}>
              <Icon type="info-circle" style={{ padding: "0 5px" }} />

              <Text select>{date ? date : "mm/dd/yyyy"}</Text>
            </InnerWrapper>
          )}
        </ButtonWrapper>
        <ButtonWrapper
          name="opt2"
          active={active === "opt2" ? true : false}
          onClick={handleNoDeadline}
        >
          <Icon type="bulb" style={{ padding: "0 5px" }} />
          <Text select>I don't know</Text>
        </ButtonWrapper>
      </FlexWrapper>
    </Wrapper>
  );
};

export default DeadlineSection;
