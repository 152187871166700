import React from "react";
import { useLocation } from "react-router-dom";
import { PageTemplate, Header, CompleteGroupOnboardingSteps } from "components";

const CompleteGroupOnboardingPage = () => {
  const { state } = useLocation();

  const { emailFromTypeform, group, url } = state || {};
  const workEmail = localStorage.getItem("workEmail");

  if (!emailFromTypeform && !workEmail) {
    window.location.href = "/groups";
  }

  return (
    <PageTemplate padding="0 12.5%" header={<Header V2 />}>
      <CompleteGroupOnboardingSteps
        emailFromTypeform={emailFromTypeform}
        workEmail={workEmail}
        group={group}
        url={url}
      />
    </PageTemplate>
  );
};

export default CompleteGroupOnboardingPage;
