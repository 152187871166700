import React from "react";
import { Text } from "components";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/plan/actions";
import styled from "styled-components";
import { useFormikContext } from "formik";
import { Popconfirm, Space, message } from "antd";
import { useLocation } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: white;
  padding: 4px;
  gap: 4px;
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.2);
  z-index: 2;
`;

const FlexWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 4px;
  align-items: center;
  padding: 0 20px;
`;

const Button = styled.div`
  background-color: ${({ active }) => (active ? "#1c355e" : "rgba(146, 146, 146, 0.37)")};
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 22px !important;
  padding: 26px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  cursor: ${({ active, type }) => (active || type === "back") && "pointer"};
  transition: 0.3s;
  &:hover {
    background-color: ${({ active }) => (active ? "#132541" : "rgba(146, 146, 146, 0.37)")};
  }
  ${({ type }) => type === "back" && "background-color: white; border: 1px solid #1c355e"};

  @media (min-width: 768px) {
    width: 200px;
  }
  @media (max-width: 768px) {
    ${({ grow }) => grow && `flex-grow: 1`}
  }
`;

const ButtonClear = styled.div`
  background-color: transparent;
  border: none;
  color: #667085;
  cursor: pointer;
  text-decoration: underline;
  font-size: 18px;
`;

window.sessionStorage.setItem("costData", "null");

const AddPremiumsFooter = ({ isSubmitting }) => {
  const dispatch = useDispatch();

  const { validateForm, submitForm, isValid, setFieldValue, resetForm, setFieldTouched, setErrors, values } =
    useFormikContext();

  const current = useSelector((state) => state.plan.current);

  const steps = useSelector((state) => state.plan.premiumSteps);

  const location = useLocation();
  const { state } = location;

  const handleSubmit = () => {
    submitForm();
  };

  const onValidate = (res) => {
    const fields = Object.keys(res);
    if (fields.length) {
      fields.map((field) => setFieldTouched(field));
      message.error("You must complete all required fields");
    } else {
      dispatch(Actions.Creators.changeCurrent(current + 1));
      setErrors({});
    }
  };

  return (
    <Wrapper>
      <FlexWrapper>
        {current > 0 && (
          <Button type="back" onClick={() => dispatch(Actions.Creators.changeCurrent(current - 1))}>
            <Text blue center fontSize="24">
              {`< Back`}
            </Text>
          </Button>
        )}

        {current < steps.length - 1 && (
          <Button
            type="next"
            grow
            active={true}
            onClick={() => {
              validateForm().then((res) => {
                onValidate(res);
              });
            }}
          >
            <Text white center fontSize="24">
              {`Next >`}
            </Text>
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            active={true}
            disabled={isSubmitting}
            type="submit"
            grow
            onClick={() => {
              handleSubmit();
            }}
          >
            <Text white center fontSize="24">
              {`Done`}
            </Text>
          </Button>
        )}
      </FlexWrapper>
      <FlexWrapper>
        <Popconfirm
          title="Are you sure？"
          okText="Yes"
          cancelText="No"
          onConfirm={() => {
            localStorage.removeItem("form");
            resetForm();
            setFieldValue("partnerJustForComparison", state?.partnerJustForComparison);
            dispatch(Actions.Creators.changeCurrent(0));
          }}
        >
          <ButtonClear>{`Clear Fields`}</ButtonClear>
        </Popconfirm>
      </FlexWrapper>
    </Wrapper>
  );
};

export default AddPremiumsFooter;
