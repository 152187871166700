import React from "react";

export const InputChangeContext = React.createContext({
  onSelectInputChanged: () => {},
});

export const InputChangeProvider = ({ children, onSelectInputChanged }) => {
  const handleSelectInputChanged = (fieldName, value) => {
    onSelectInputChanged(fieldName, value);
  };

  return (
    <InputChangeContext.Provider value={{ onSelectInputChanged: handleSelectInputChanged }}>
      {children}
    </InputChangeContext.Provider>
  );
};

export const useInputChangeContext = () => {
  const context = React.useContext(InputChangeContext);
  if (context === undefined) {
    throw new Error("useInputChangeContext must be used within a InputChangeContext");
  }
  return context;
};
