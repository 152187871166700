import joi from "joi";

const customPlanFormSendSchema = joi
  .object({
    id: joi.string().allow(null),
    group: joi.string().allow(null),
    name: joi.string().allow(null),
    maternityCopayDetails: joi.object().keys({
      subjToCopay: joi.bool().allow(null),
      hospital: joi.number().allow(null),
      unit: joi.string().allow(null),
      maxUnits: joi.number().allow(null),
      whoPays: joi.string().allow(null),
      subjToPercentCoins: joi.bool().allow(null)
    }),
    inNetwork: joi.object().keys({
      individual: joi.object().keys({
        deductible: joi.number().allow(null),
        oopMax: joi.number().allow(null)
      }),
      family: joi.object().keys({
        deductible: joi.number().allow(null),
        individualDeductible: joi.number().allow(null),
        individualOopMax: joi.number().allow(null),
        oopMax: joi.number().allow(null)
      }),
      coinsurance: joi.object().keys({
        rate: joi.number().allow(null)
      })
    }),
    outOfNetwork: joi.object().keys({
      coverage: joi.bool().allow(null),
      individual: joi.object().keys({
        deductible: joi.number().allow(null),
        oopMax: joi.number().allow(null)
      }),
      family: joi.object().keys({
        deductible: joi.number().allow(null),
        individualDeductible: joi.number().allow(null),
        individualOopMax: joi.number().allow(null),
        oopMax: joi.number().allow(null)
      }),
      coinsurance: joi.object().keys({
        rate: joi.number().allow(null)
      })
    }),
    family: joi.object().keys({
      deductible: joi.object().keys({
        type: joi
          .string()
          .valid("Individual", "Combined")
          .allow(null)
      })
    }),
    baby: joi.object().keys({
      deductible: joi.object().keys({
        isUsed: joi.bool().allow(null)
      })
    }),
    deductibles: joi.object().keys({
      isApply: joi.boolean().allow(null),
      cross: joi.object().keys({
        isAccumulatable: joi.bool().allow(null)
      })
    }),
    premium: joi.object().keys({
      isUnique: joi.bool().allow(null),
      frequency: joi.number().allow(null),
      tiers: joi.object().keys({
        1: joi.object().keys({
          mo: joi.number().allow(null),
          ms: joi.number().allow(null),
          mc: joi.number().allow(null),
          mf: joi.number().allow(null)
        })
      })
    }),
    hsa: joi.object().keys({
      isApply: joi.bool().allow(null),
      frequency: joi.number().allow(null),
      tiers: joi.object().when("isApply", {
        is: true,
        then: joi.object().keys({
          1: joi.object().keys({
            individual: joi.number().allow(null),
            family: joi.number().allow(null)
          })
        }),
        otherwise: joi.object().keys({
          empty: joi
            .string()
            .valid("")
            .allow(null)
        })
      })
    })
  })
  .unknown()
  .required();

export default customPlanFormSendSchema;
