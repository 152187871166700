export const FieldOptions = {
  total: "total",
  subtotal: "subtotal",
  Premiums: "Premiums",
  Surcharges: "Surcharges",
  Insurance: "Insurance",
  NotCovered: "Not Covered",
  OutOfNetwork: "Out-Of-Network",
  ReimbursementFromInsurance: "Reimbursement from Insurance",
  OutOfPocketAccrual: "Out-of-Pocket Accrual",
  OptOutPayment: "Opt-Out Payment",
  leftover: "leftover",
  HSA: "HSA",
  HRA: "HRA",
  HSAHRAImpact: "HSA/HRA Impact",
  EmployerHSAContribution: "Employer HSA Contribution",
  ReducedTaxBillFromHSAContribution: "Reduced Tax Bill From HSA Contribution",
  ReducedTaxBillOnHSAInvestmentGains: "Reduced Tax Bill on HSA Investment Gains",
  InvestmentGainsOnEmployerContribution: "Investment Gains on Employer Contribution",
  GainLossOnInvestmentNetOfTaxes: "Gain/Loss on Investment Net of Taxes",
};

export const PeriodOptions = {
  Before: "Before",
  After: "After",
};

export const CompanyOptions = {
  companyA: "companyA",
  companyB: "companyB",
};
