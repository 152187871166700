import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import { cn } from "utils/cn";
import { Button, Divider } from "antd";
import styled from "styled-components";

export const CheckboxItems = styled.ul`
  li {
    display: flex;
    width: 100%;
    padding: 6px;
    border: 2px solid #d0d5dd;
    color: #21355b;
    border-radius: 8px;
    margin: 8px 0;
    font-weight: 500;

    label {
      display: flex;
      width: 100%;
      cursor: pointer;
      align-items: center;

      .radio-plans-label,
      .checkbox-plans-label {
        line-height: initial;
        margin: 6px;
      }
    }

    .check-input-radio-wrapper,
    .check-input-checkbox-wrapper {
      position: relative;
      background-color: transparent;
      border-radius: 100%;
      border: 2px solid #21355b;
      height: 20px;
      width: 20px;
      min-width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 6px;

      &.checked {
        background-color: #21355b;

        .check-icon-radio,
        .check-icon-checkbox {
          display: inline;
          filter: invert(1);
        }
      }

      input {
        display: none;
      }

      .check-icon-radio,
      .check-icon-checkbox {
        display: none;
        top: 0;
        width: max-content;
        height: max-content;
      }
    }

    .check-input-checkbox-wrapper {
      border-radius: 4px;
    }

    &.group-no-border {
      border: none;
      padding: 0;

      .check-input-radio-wrapper {
        border-radius: 4px;
      }
    }
  }
`;

export const CheckboxWrapper = styled.div``;

export const CheckboxWrapperHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 10px;
  line-height: normal;

  .label {
    display: inline-flex;
    align-items: center;
    font-size: 1.17em;
    font-weight: bold;
    color: #21355b;
    margin-bottom: 0px;
    min-height: 32px;
  }

  .selection-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1px;

    & > button {
      padding: 0;
      &.ant-btn.ant-btn-link {
        color: #21355b !important;
      }
    }
  }
`;

/**
 * CheckboxGroup component.
 *
 * @param {Object} props - Component props.
 * @param {string} props.label - The label for the checkbox group.
 * @param {Array<{label: string, value: string}>} props.options - The options for the checkboxes.
 * @param {function} props.onChange - The function to call when the checkbox selection changes.
 * @param {Array<string>} props.value - The currently selected values.
 * @param {boolean} [props.hasSelectionHelpers = true] - Whether to show the "Select All" and "Select None" buttons.
 * @param {boolean} [props.noBorder = false] - Whether to show the border around the checkboxes.
 *
 * @returns {JSX.Element} The CheckboxGroup component.
 */
export const CheckboxGroup = ({ label, options, onChange, value, hasSelectionHelpers = true, noBorder = false }) => {
  const handleSelectAll = () => {
    const newSelected = options.map((option) => option.value);
    onChange(newSelected);
  };

  const handleSelectNone = () => {
    const newSelected = [];
    onChange(newSelected);
  };

  const handleChange = (item) => {
    const newSelected = value.includes(item) ? value.filter((i) => i !== item) : [...value, item];
    onChange(newSelected);
  };

  const isSelected = (item) => value.includes(item);

  return (
    <CheckboxWrapper>
      <CheckboxWrapperHeading>
        <div className="label">{label}</div>
        {hasSelectionHelpers && (
          <div className="selection-buttons">
            <Button type="link" onClick={handleSelectNone}>
              Select None
            </Button>
            <Divider type="vertical" />
            <Button type="link" onClick={handleSelectAll}>
              Select All
            </Button>
          </div>
        )}
      </CheckboxWrapperHeading>

      <CheckboxItems>
        {options?.map?.((option) => (
          <li key={option.label} className={cn(noBorder && "group-no-border")}>
            <label>
              <div className={cn("check-input-checkbox-wrapper", [isSelected(option.value) ? "checked" : ""])}>
                <input
                  type="checkbox"
                  value={option.value}
                  checked={isSelected(option.value)}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange(value);
                  }}
                />
                <div className="check-icon-checkbox">
                  <CheckOutlined />
                </div>
              </div>
              <span className="checkbox-plans-label">{option.label}</span>
            </label>
          </li>
        ))}
      </CheckboxItems>
    </CheckboxWrapper>
  );
};
/**
 * SingleCheckbox component.
 *
 * @param {Object} props - Component props.
 * @param {string} props.label - The label for the checkbox section
 * @param {{value: string, label: string}} props.option - The label and value for the checkbox.
 * @param {function} props.onChange - The function to call when the checkbox state changes.
 * @param {boolean} props.checked - The current state of the checkbox.
 *
 * @returns {JSX.Element} The SingleCheckbox component.
 */
export const SingleCheckbox = ({ label, option, onChange, checked }) => {
  const handleChange = () => {
    onChange(!checked);
  };

  return (
    <CheckboxGroup
      label={label}
      options={[{ label: option.label, value: option.value }]}
      onChange={handleChange}
      value={checked ? [option.value] : []}
      hasSelectionHelpers={false}
      noBorder
    />
  );
};
