import getFullQuery from "./query";
import configure from "./configure";
import queryString from "./query";

const { setHeadersWithAuth, standardHeaders, axios } = configure;

export const getBillsHeader = (data, token) => {
  const headers = setHeadersWithAuth(token);
  const query = getFullQuery(data);

  return axios.get("bills/header" + query, headers);
};

export const getBillById = (id, token) => {
  const headers = setHeadersWithAuth(token);
  return axios.get("bills/" + id, headers);
};

export const getBills = (data, token) => {
  const headers = setHeadersWithAuth(token);
  const query = queryString(data);
  return axios.get(`bills${query}`, headers);
};

export const getBillIds = data => {
  const query = queryString(data);
  return axios.get("bills/ids" + query, standardHeaders).then(
    res => {
      return res;
    },
    error => {
      return error;
    }
  );
};
