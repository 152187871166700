import { Icon as AntDIcon } from "@ant-design/compatible";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  margin: 10px auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 82%;
  transition: left 0.4s ease-in-out;
  position: relative;
  left: 0;

  @media (max-width: 768px) {
    margin: 10px 0;
    width: 100%;
  }
`;
export const FlexWrapper = styled.div`
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  justify-content: ${(props) => (props.justify ? props.justify : "space-evenly")};
  width: 100%;
  overflow-x: scroll;
`;
export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding-bottom: 20px !important;
`;
export const InnerWrapper = styled.div`
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: column;
  align-items: ${(props) => (props.align ? `flex-${props.align}` : "center")};
  justify-content: start;
  ${(props) => props.width && `width: ${props.width};`};

  .ReactCollapse--collapse {
    width: 100%;
  }

  > .ReactCollapse--collapse {
    transition: height 500ms;

    > * {
      transition: height 500ms;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
`;

export const Row = styled.tr`
  height: ${(props) => {
    if (props.selectHeader) {
      return "150px";
    } else if (props.labelsHeader) {
      return "100px";
    } else if (props.header) {
      return "81px";
    } else if (props.category) {
      return "35px";
    } else if (props.subCategory) {
      return "35px";
    } else {
      return "50px";
    }
  }};

  ${(props) => props.customHeight && `height: ${props.customHeight};`}
  ${(props) => props.customWidth && `width: ${props.customWidth};`}
  background-color: transparent;
  padding: ${(props) => (props.padding ? props.padding : "4px 5px;")};
  margin: ${(props) => (props.margin ? props.margin : props.header ? "2px;" : "0;")};
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  cursor: ${(props) => (props.cursor ? props.cursor : "auto")};
  ${({ hover }) =>
    hover &&
    css`
      :hover {
        background-color: #04b8b1;
      }
    `}

  ${(props) => (props.bgColor ? `background-color: ${props.bgColor};` : "background-color: transparent;")}
`;

export function colorFromLevel(level) {
  if (level === -1) return "#21355B"; // Default color

  const colors = ["#D74328", "#EC8916", "#F9A314", "#589B40", "#30706D", "#374F7F", "#4A377F"];
  const colorsAmount = colors.length;
  try {
    const levelInt = level ? parseInt(level) - 1 : 0;
    const colorToPick = levelInt % colorsAmount;

    return colors[colorToPick];
  } catch (error) {
    return colors[0];
  }
}
export const CollapseSeparator = styled.div`
  border-left: 2px solid #000;
  margin-left: 6px;

  ${(props) => props.bColor && `border-color: ${props.bColor};`}
`;

export const OptWrapper = styled.div`
  border: ${(props) => (props.custom ? "1px solid lightgrey" : "0px white")};
  border-radius: none;
  width: inherit;
  flex-flow: column;
  opacity: ${(props) => (props.opacity ? 0.4 : 1)};

  > .ReactCollapse--collapse {
    transition: height 500ms;
  }
`;

export const IconWrapper = styled(AntDIcon)`
  display: flex !important;
  align-items: center;
  margin-left: 5px;
`;
export const HideMobile = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
