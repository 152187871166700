import React, { useState } from "react";
import styled from "styled-components";
import { FormSelect as Select, FormInput as Input } from "components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 44% 19% 25%;
  grid-template-rows: auto;
  column-gap: 6%;
`;

const monthSelections = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DateCustom = ({ handleChange, name, value, allowSkip }) => {
  const dateArray = value && value !== "n/a" ? value.split("/") : null;
  const [isDate, setIsDate] = useState(value === "n/a" ? false : true);
  const [monthNumber, setMonthNumber] = useState(
    dateArray ? dateArray[0] : null
  );
  const [month, setMonth] = useState(
    dateArray ? monthSelections[parseInt(dateArray[0]) - 1] : null
  );
  const [day, setDay] = useState(dateArray ? dateArray[1] : null);
  const [year, setYear] = useState(dateArray ? dateArray[2] : null);

  const onChange = async ({ target: { name, value } }) => {
    switch (name) {
      case "month":
        const _monthNumber = monthSelections.indexOf(value) + 1;
        await setMonth(value);
        await setMonthNumber(_monthNumber);

        if (day && year) {
          const date = `${_monthNumber}/${day}/${year}`;
          const target = { name, value: date };
          await handleChange({ target });
        }
        break;
      case "day":
        if (value.length <= 2 && !value.includes(".") && !isNaN(value)) {
          await setDay(value);

          if (month && year) {
            const date = `${monthNumber}/${value}/${year}`;
            const target = { name, value: date };
            await handleChange({ target });
          }
        }
        break;
      case "year":
        if (value.length <= 4 && !value.includes(".") && !isNaN(value)) {
          await setYear(value);

          if (day && month) {
            const date = `${monthNumber}/${day}/${value}`;
            const target = { name, value: date };
            await handleChange({ target });
          }
        }
        break;
      case "isDate":
        await setIsDate(value === "true" ? true : false);
        break;
      default:
        return;
    }
  };

  return (
    <Wrapper>
      {allowSkip && (
        <Select
          defaultOptionText="Month"
          name="isDate"
          isBool
          value={isDate}
          handleChange={onChange}
          selection={["Not Sure", "Input Date Below"]}
        />
      )}
      {isDate && (
        <GridWrapper>
          <Select
            defaultOptionText="Month"
            name="month"
            value={month}
            isSearch
            handleChange={onChange}
            selection={monthSelections}
          />
          <Input
            name="day"
            value={day}
            min={1}
            max={31}
            placeholder="DD"
            handleChange={onChange}
          />
          <Input
            name="year"
            value={year}
            min={1900}
            max={3000}
            placeholder="YYYY"
            handleChange={onChange}
          />
        </GridWrapper>
      )}
    </Wrapper>
  );
};

export default React.memo(DateCustom);
