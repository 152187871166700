import React, { useState } from "react";
import {
  CopyOutlined,
  DeleteOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
  UndoOutlined
} from "@ant-design/icons";
import { Button, Dropdown, Menu, Popconfirm } from "antd";
import styled from "styled-components";
import { useFormikContext } from "formik";
import {
  duplicateRow,
  deleteRow,
  copyFromRowAbove,
} from "components/organisms/CreatePlanSteps/utils/table";

import {
  copyFromRowAbove as servicesCopyFromRowAbove,
  resetFrequency
} from "components/organisms/AddServicesSteps/utils/table";

const Wrapper = styled(Button)`
  border: 1px solid #d9d9d9;
  color: #000;
  width: 32px !important;
  height: 32px !important;
`;

const TableMenu = ({ step, record }) => {
  const [open, setOpen] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  let { tableName } = record;
  if (!tableName) tableName = "table"

  const table = values[tableName]
  const { planOptions } = values;

  const { idx } = record;

  const addARow = () => {
    const data = duplicateRow(idx, table);
    setFieldValue("table", data);
    setFieldValue("planOptions", planOptions + 1);
  };

  const deleteARow = () => {
    const data = deleteRow(idx, table);
    setFieldValue(tableName, data);
    if (tableName === "table")
      setFieldValue("planOptions", planOptions - 1);
  };

  const copyRowAbove = () => {
    const data = copyFromRowAbove(idx, table, step);
    setFieldValue("table", data);
  };

  const duplicateRowElement = {
    label: "Duplicate Row",
    key: "1",
    onClick: () => addARow(),
    icon: <CopyOutlined />,
  };

  const copyFromRowAboveElement = {
    label: "Copy from row above",
    key: "1",
    onClick: () => copyRowAbove(),
    icon: <CopyOutlined />,
  };

  const deleteRowElement = {
    label: "Delete Row",
    key: step > 2 && planOptions === 1 ? "1" : "2",
    onClick: () => {
      setOpen(!open);
    },
    disabled: table && table.length === 1,
    icon: (
      <Popconfirm
        overlayClassName="tableTooltip"
        title="Are you sure?"
        open={open}
        placement="topRight"
        arrow={{
          pointAtCenter: true,
        }}
        onConfirm={() => {
          deleteARow();
        }}
        icon={
          <QuestionCircleOutlined
            style={{
              color: "red",
            }}
          />
        }
      >
        <DeleteOutlined />
      </Popconfirm>
    ),
    danger: true,
  };

  const servicesCopyFromRowAboveElement = {
    label: "Copy from row above",
    key: "1",
    onClick: () => {
      setFieldValue(tableName, servicesCopyFromRowAbove(idx, table))
    },
    icon: <CopyOutlined />,
  };

  const resetFrequencyElement = {
    label: "Reset Frequency Options",
    key: 0,
    onClick: () => {
      setFieldValue(tableName, resetFrequency(idx, table))
    },
    icon: <UndoOutlined />
  }

  const optionsMenuButtons = () => {
    if (step === 2) return [duplicateRowElement, deleteRowElement];
    if (planOptions > 1 && idx > 1)
      return [copyFromRowAboveElement, deleteRowElement];
    if (tableName === "expectedTable") {
      const options = [deleteRowElement];
      if (idx > 1) {
        options.unshift(servicesCopyFromRowAboveElement)
      }
      if (table[idx - 1]?.frequency === "other frequency") {
        options.unshift(resetFrequencyElement)
      }
      return options
    }
    return [deleteRowElement];
  };

  const options = <Menu onClick={() => { }} items={optionsMenuButtons()} />;

  return (
    <Dropdown
      overlay={options}
      trigger={["click"]}
      placement="bottom"
      arrow={{
        pointAtCenter: true,
      }}
    >
      <Wrapper
        onClick={(e) => e.preventDefault()}
        icon={<MoreOutlined style={{ fontSize: "22px", color: "#000" }} />}
        size="large"
      />
    </Dropdown>
  );
};

export default TableMenu;
