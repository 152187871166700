//TABS KEYS
export const TAB_ALL_MY_OPTIONS = "2";
export const TAB_YEARLY_OPTIONS = "3";
export const TAB_WHAT_IF = "4";
export const TAB_IN_NETWORK_CARE = "5";

export const ADD_SERVICES_START = 0;

export const CREATE_PLAN_START = 0;
export const CREATE_PLAN_PREMIUMS = 1;

export const ADD_PREMIUMS_START = 0;

//LABELS TO GUESS
export const LABELS = ["EPO", "HMO", "EXCLUSIVE", "KAISER"];

//NETWORKS TO GUESS
export const NETWORKS = ["Kaiser"];

//AUTOMATIC DETECTION OF HASA INELIGIBILITY
export const HSA_RULES = {
  inNetworkIndividual: 1650,
  inNetworkFamily: 3300,
  inNetworkIndividualOOP: 8300,
  inNetworkFamilyOOP: 16600,
};
