import * as Yup from "yup";

import model from "./model";
const {
  formField: {
    planOptions,
    premiumTier,
    group,
    costFor,
    outOfNetworkCoverage,
    label,
    deductibles,
    table,
    frequency,
    spousalSurcharge,
    spousalSurchargeFrequency,
    waivers,
    selectedWaiverTier,
    startDate,
    dontAddMoreCategories,
  },
} = model;

const servicesValidationObject = (values) => {
  let { serviceList } = values || {};

  if (!serviceList) {
    serviceList = [];
  }

  serviceList = serviceList.map((service) => service.name);

  const finalObject = {};
  for (const service of serviceList) {
    if (service !== "Inpatient Hospital Stay") {
      finalObject[service] = Yup.object().shape({
        haveToPay: Yup.string().required(""),
        inNetworkCoinsuranceRate: Yup.string().when("haveToPay", {
          is: (haveToPay) => haveToPay !== "copay",
          then: Yup.string().required(""),
        }),
        amount: Yup.string().when("haveToPay", {
          is: (haveToPay) => haveToPay === "copay" || haveToPay === "both",
          then: Yup.string().required(""),
        }),
      });
    } else {
      finalObject[service] = Yup.object().shape({
        haveToPay: Yup.string().required(""),
        inNetworkCoinsuranceRate: Yup.string().when("haveToPay", {
          is: (haveToPay) => haveToPay !== "copay",
          then: Yup.string().required(""),
        }),
        amount: Yup.string().when("haveToPay", {
          is: (haveToPay) => haveToPay === "copay" || haveToPay === "both",
          then: Yup.string().required(""),
        }),
        unit: Yup.string().when("haveToPay", {
          is: (haveToPay) => haveToPay === "copay" || haveToPay === "both",
          then: Yup.string().required(""),
        }),
        maxUnits: Yup.string().when(["haveToPay", "unit"], {
          is: (haveToPay, unit) => (haveToPay === "copay" || haveToPay === "both") && unit === "night",
          then: Yup.string().required(""),
        }),
      });
    }
  }

  return Yup.object(finalObject);
};

const getWaiversObject = (values) => {
  console.log(values);
  console.log(values.waiverTiersNumber);
  return Yup.object()
    .shape(
      (() => {
        const finalObject = {};
        for (let i = 1; i <= values.waiverTiersNumber; i++) {
          finalObject[i] = Yup.object().shape({
            value: Yup.number().required(""),
            frequency: Yup.number().required(""),
          });
        }
        return finalObject;
      })(),
    )
    .required("");
};

let realTableSize = 0;

export const validationSchema = (values) => [
  // step 1
  Yup.object().shape({
    [planOptions.name]: Yup.string()
      .required(`${planOptions.requiredErrorMsg}`)
      .test("is-index-required", "errorr", function (item) {
        realTableSize = Number(item);
        return true;
      }),
    [premiumTier.name]: Yup.string().required(`${premiumTier.requiredErrorMsg}`),
    [frequency.name]: Yup.string().when("premiumTier", {
      is: (value) => value > 0,
      then: Yup.string().required(`${frequency.requiredErrorMsg}`),
    }),
    [costFor.name]: Yup.string().required(`${costFor.requiredErrorMsg}`),
    [outOfNetworkCoverage.name]: Yup.string().required(`${outOfNetworkCoverage.requiredErrorMsg}`),
    [table.name]: Yup.array()
      .transform((fields) => fields.slice(0, realTableSize))
      .of(
        Yup.object()
          .shape({
            label: Yup.string(),
          })
          .test("first-element", label.requiredErrorMsg, function (item) {
            const index = parseInt(this.path.split("[")[1].split("]")[0], 10);
            return index > 0 || !!item.label
              ? true
              : this.createError({
                  path: `${this.path}.label`,
                  message: label.requiredErrorMsg,
                });
          }),
      ),
    [deductibles.name]: Yup.array()
      .of(
        Yup.object().shape({
          inNetwork: Yup.number().required(""),
          outOfNetwork: Yup.string().when("outOfNetworkCoverage", {
            is: "yes",
            then: Yup.string().required(""),
          }),
        }),
      )
      .min(2)
      .required("Must have deductibles"),
  }),
  // step 2
  Yup.object().shape({
    [planOptions.name]: Yup.string()
      .required(`${planOptions.requiredErrorMsg}`)
      .test("is-index-required", "errorr", function (item) {
        realTableSize = Number(item);
        return true;
      }),
    [frequency.name]: Yup.string().required(`${frequency.requiredErrorMsg}`),
    [spousalSurcharge.name]: Yup.number().when("withSpousalSurcharge", {
      is: true,
      then: Yup.number().required(""),
    }),
    [spousalSurchargeFrequency.name]: Yup.number().when("withSpousalSurcharge", {
      is: true,
      then: Yup.number().required(""),
    }),
    [selectedWaiverTier.name]: Yup.number().when("withWaiverPayment", {
      is: true,
      then: Yup.number().required(""),
    }),
    [table.name]: Yup.array()
      .transform((fields) => fields.slice(0, realTableSize))
      .when(
        ["withMultiplePremiumTiers", "premiumTiersNumber"],
        (withMultiplePremiumTiers, premiumTiersNumber, schema) =>
          schema
            .of(
              Yup.object().shape({
                label: Yup.string().required("").nullable(),
                insuranceNetwork: Yup.string().required(""),
                tiers: Yup.object().when([], {
                  is: () => withMultiplePremiumTiers,
                  then: Yup.object()
                    .shape(
                      (() => {
                        const finalObject = {};
                        for (let i = 1; i <= premiumTiersNumber; i++) {
                          finalObject[i] = Yup.object().shape({
                            mo: Yup.number().required(""),
                            ms: Yup.number().required(""),
                            mc: Yup.number().required(""),
                            mf: Yup.number().required(""),
                          });
                        }
                        return finalObject;
                      })(),
                    )
                    .required(),
                  otherwise: Yup.object()
                    .shape(
                      (() => {
                        return {
                          1: Yup.object().shape({
                            mo: Yup.number().required(""),
                            ms: Yup.number().required(""),
                            mc: Yup.number().required(""),
                            mf: Yup.number().required(""),
                          }),
                        };
                      })(),
                    )
                    .required(),
                }),
              }),
            )
            .required("Must have table"),
      ),
  }),
  // step 3
  Yup.object().shape({
    [startDate.name]: Yup.string().required(`${startDate.requiredErrorMsg}`),
    [table.name]: Yup.array()
      .transform((fields) => fields.slice(0, realTableSize))
      .when("showIndividualInFamilyDeductibles", (showIndividualInFamilyDeductibles, schema) =>
        schema.of(
          Yup.object().shape({
            label: Yup.string().required(""),
            insuranceNetwork: Yup.string().required(""),
            outOfNetworkCoverage: Yup.string().required(""),
            inNetworkIndividual: Yup.number().required(""),
            inNetworkIndividualInFamily: Yup.mixed().when(["hasinNetworkIndividualInFamily"], {
              is: (hasinNetworkIndividualInFamily) =>
                hasinNetworkIndividualInFamily && showIndividualInFamilyDeductibles,
              then: Yup.mixed().required(""),
            }),
            inNetworkFamily: Yup.number().required(""),
            outOfNetworkIndividual: Yup.number().when("outOfNetworkCoverage", {
              is: "yes",
              then: Yup.number().required(""),
            }),
            outOfNetworkIndividualInFamily: Yup.mixed().when(
              ["hasoutOfNetworkIndividualInFamily", "outOfNetworkCoverage"],
              {
                is: (hasoutOfNetworkIndividualInFamily, outOfNetworkCoverage) =>
                  hasoutOfNetworkIndividualInFamily &&
                  showIndividualInFamilyDeductibles &&
                  outOfNetworkCoverage === "yes",
                then: Yup.mixed().required(""),
              },
            ),
            outOfNetworkFamily: Yup.number().when("outOfNetworkCoverage", {
              is: "yes",
              then: Yup.number().required(""),
            }),
          }),
        ),
      )
      .required("Must have table"),
  }),
  // step 4
  Yup.object().shape({
    [startDate.name]: Yup.string().required(`${startDate.requiredErrorMsg}`),
    [table.name]: Yup.array()
      .transform((fields) => fields.slice(0, realTableSize))
      .when("showIndividualInFamilyOOP", (showIndividualInFamilyOOP, schema) =>
        schema.of(
          Yup.object().shape({
            label: Yup.string().required(""),
            insuranceNetwork: Yup.string().required(""),
            outOfNetworkCoverage: Yup.string().required(""),
            inNetworkIndividualOOP: Yup.number().required(""),
            inNetworkIndividualInFamilyOOP: Yup.mixed().when(["hasinNetworkIndividualInFamilyOOP"], {
              is: (hasinNetworkIndividualInFamilyOOP) => hasinNetworkIndividualInFamilyOOP && showIndividualInFamilyOOP,
              then: Yup.mixed().required(""),
            }),
            inNetworkFamilyOOP: Yup.number().required(""),
            outOfNetworkIndividualOOP: Yup.mixed().when(["hasoutOfNetworkIndividualOOP", "outOfNetworkCoverage"], {
              is: (hasoutOfNetworkIndividualOOP, outOfNetworkCoverage) =>
                hasoutOfNetworkIndividualOOP && outOfNetworkCoverage === "yes",
              then: Yup.mixed().required(""),
            }),
            outOfNetworkIndividualInFamilyOOP: Yup.mixed().when(
              ["hasoutOfNetworkIndividualInFamilyOOP", "outOfNetworkCoverage"],
              {
                is: (hasoutOfNetworkIndividualInFamilyOOP, outOfNetworkCoverage) =>
                  hasoutOfNetworkIndividualInFamilyOOP && showIndividualInFamilyOOP && outOfNetworkCoverage === "yes",
                then: Yup.mixed().required(""),
              },
            ),
            outOfNetworkFamilyOOP: Yup.mixed().when(["hasoutOfNetworkFamilyOOP", "outOfNetworkCoverage"], {
              is: (hasoutOfNetworkFamilyOOP, outOfNetworkCoverage) =>
                hasoutOfNetworkFamilyOOP && outOfNetworkCoverage === "yes",
              then: Yup.mixed().required(""),
            }),
          }),
        ),
      )
      .required("Must have table"),
  }),
  //step Advanced
  Yup.object().shape({
    [table.name]: Yup.array()
      .transform((fields) => fields.slice(0, realTableSize))
      .when(["notSureAboutThese", "enterAdvancedDetails"], {
        is: (notSureAboutThese, enterAdvancedDetails) => !notSureAboutThese && enterAdvancedDetails,
        then: (schema) =>
          schema.of(
            Yup.object().shape({
              label: Yup.string().required(""),
              insuranceNetwork: Yup.string().required(""),
              outOfNetworkCoverage: Yup.string().required(""),
              outOfNetworkAndInNetworkCrossAccumulate: Yup.mixed().when(["outOfNetworkCoverage"], {
                is: (outOfNetworkCoverage) => outOfNetworkCoverage === "yes",
                then: Yup.mixed().required(""),
              }),
              familyType: Yup.string().required(""),
              familyOOPType: Yup.string().required(""),
            }),
          ),
      }),
  }),
  // step 5
  Yup.object().shape({
    [table.name]: Yup.array()
      .transform((fields) => fields.slice(0, realTableSize))
      .of(
        Yup.object().shape({
          label: Yup.string().required(""),
          insuranceNetwork: Yup.string().required(""),
          isHSACompatible: Yup.string().required(""),
          enrollHealthCareFSA: Yup.string().when("isHSACompatible", {
            is: "no",
            then: Yup.string().required(""),
          }),
          isHsaOrHraCompatible: Yup.string().required(""),
          individualHSA: Yup.string().when(["isHsaOrHraCompatible"], {
            is: (isHsaOrHraCompatible) => isHsaOrHraCompatible === "HSA" || isHsaOrHraCompatible === "both",
            then: Yup.string().required(""),
          }),
          familyHSA: Yup.string().when(["isHsaOrHraCompatible"], {
            is: (isHsaOrHraCompatible) => isHsaOrHraCompatible === "HSA" || isHsaOrHraCompatible === "both",
            then: Yup.string().required(""),
          }),
          largeFamilyHSA: Yup.string().when(["isHsaOrHraCompatible"], {
            is: (isHsaOrHraCompatible) => isHsaOrHraCompatible === "HSA" || isHsaOrHraCompatible === "both",
            then: Yup.string().required(""),
          }),
          depositedHSA: Yup.string().when(["isHsaOrHraCompatible"], {
            is: (isHsaOrHraCompatible) => isHsaOrHraCompatible === "HSA" || isHsaOrHraCompatible === "both",
            then: Yup.string().required(""),
          }),
          individualHRA: Yup.string().when(["isHsaOrHraCompatible"], {
            is: (isHsaOrHraCompatible) => isHsaOrHraCompatible === "HRA" || isHsaOrHraCompatible === "both",
            then: Yup.string().required(""),
          }),
          familyHRA: Yup.string().when(["isHsaOrHraCompatible"], {
            is: (isHsaOrHraCompatible) => isHsaOrHraCompatible === "HRA" || isHsaOrHraCompatible === "both",
            then: Yup.string().required(""),
          }),
          largeFamilyHRA: Yup.string().when(["isHsaOrHraCompatible"], {
            is: (isHsaOrHraCompatible) => isHsaOrHraCompatible === "HRA" || isHsaOrHraCompatible === "both",
            then: Yup.string().required(""),
          }),
          towardHRA: Yup.string().when(["isHsaOrHraCompatible"], {
            is: (isHsaOrHraCompatible) => isHsaOrHraCompatible === "HRA" || isHsaOrHraCompatible === "both",
            then: Yup.string().required(""),
          }),
        }),
      )
      .required("Must have table"),
  }),
  // step 6
  Yup.object()
    .shape({
      [table.name]: Yup.array()
        .transform((fields) => fields.slice(0, realTableSize))
        .of(
          Yup.object().shape({
            services: servicesValidationObject(values),
          }),
        ),
      FSType: Yup.string().when(["serviceList"], {
        is: (serviceList) =>
          serviceList && serviceList.map((service) => service.name).includes("Fertility Services (IVF, Egg Freezing)"),
        then: Yup.string().required(""),
      }),
      FSMaxAmount: Yup.number().when(["FSType", "serviceList"], {
        is: (FSType, serviceList) =>
          FSType === "amount" &&
          serviceList &&
          serviceList.map((service) => service.name).includes("Fertility Services (IVF, Egg Freezing)"),
        then: Yup.number().required("").typeError("This field is required"),
        otherwise: Yup.number().notRequired().nullable(),
      }),
      FSMaxUnits: Yup.number().when(["FSType", "serviceList"], {
        is: (FSType, serviceList) =>
          FSType === "units" &&
          serviceList &&
          serviceList.map((service) => service.name).includes("Fertility Services (IVF, Egg Freezing)"),
        then: Yup.number().required("").typeError("This field is required"),
        otherwise: Yup.number().notRequired().nullable(),
      }),
    })
    .required("Must have table"),
  // step 7
  Yup.object().shape({
    [group.name]: Yup.string().required(`${group.requiredErrorMsg}`),
    [table.name]: Yup.array()
      .transform((fields) => fields.slice(0, realTableSize))
      .when(["planOptions", "dontAddMoreCategories"], (planOptions, dontAddMoreCategories, schema) =>
        schema
          .of(
            Yup.object().shape({
              label: Yup.string().required(""),
              insuranceNetwork: Yup.string().required(""),
              planName: Yup.string().when(["planOptions", "dontAddMoreCategories"], {
                is: () => planOptions >= 4 || !dontAddMoreCategories,
                then: Yup.string().required(""),
              }),
            }),
          )
          .required("Must have table"),
      ),
    categoryOptionsLength:
      realTableSize >= 4
        ? Yup.number().max(4, "You can have at most four categories + Catastrophic")
        : !dontAddMoreCategories
        ? Yup.number().max(4, "You can have at most four categories + Catastrophic")
        : Yup.number(),
  }),
];
