import React, { useState, useEffect } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "firebase/init";
import { getReportsHeader } from "services/api";
import { getToken } from "services/utils";
import { getGroup, getPremiumTiers, updateGroup, deleteGroup } from "services/api/groups.api";
import { GroupForm } from "components";
import { Card, message, Form, Input, Button, Popconfirm } from "antd";
const { Item } = Form;

const UpdatePlansPanel = ({ setActiveKey, updateGroupsList }) => {
  const [form] = Form.useForm();
  const groupNameWatch = Form.useWatch("name", form);
  const requiresFollowUpQuestionsWatch = Form.useWatch("requiresFollowUpQuestions", form);
  const requiresEmailVerificationWatch = Form.useWatch("requiresEmailVerification", form);
  const otherDomainsWatch = Form.useWatch("otherDomains", form);
  const onlyAllowListedDomainsWatch = Form.useWatch("onlyAllowListedDomains", form);
  const excludePersonalDomainsWatch = Form.useWatch("excludePersonalDomains", form);
  const skipEmailVerificationWatch = Form.useWatch("skipEmailVerification", form);
  const verifyWorkEmailAgainstDatabaseWatch = Form.useWatch("verifyWorkEmailAgainstDatabase", form);
  const doesntHaveLinkedinWatch = Form.useWatch("doesntHaveLinkedin", form);
  const [disableOnlyAllowListedDomainsSwitch, setDisableOnlyAllowListedDomainsSwitch] = useState(false);
  const [disableMasterReportsInput, setDisableMasterReportsInput] = useState(false);
  const [disableRequiresFollowUpQuestions, setDisableRequiresFollowUpQuestions] = useState(false);
  const [searchForm] = Form.useForm();
  const [logo, setLogo] = useState(null);
  const [employeesAdded, setEmployeesAdded] = useState([]);
  const [currentEmployees, setCurrentEmployees] = useState([]);
  const [newHires, setNewHires] = useState([]);
  const [employeesRemoved, setEmployeesRemoved] = useState([]);
  const [employeesWithWrongRole, setEmployeesWithWrongRole] = useState([]);
  const [originalName, setOriginalName] = useState("");
  const [reports, setReports] = useState([]);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [year, setYear] = useState(null);
  const [premiumTiers, setPremiumTiers] = useState([]);

  const [group, setGroup] = useState({
    name: "",
    displayName: "",
    masterReports: "",
    otherSearchTerms: [],
    otherDomains: [],
    editable: false,
  });

  const handleUpdateGroup = async (changes) => {
    if (
      !Object.keys(changes).every(
        (key) =>
          key === "linkedinUrl" ||
          key === "prestigeRanking" ||
          (disableMasterReportsInput && key === "masterReports") ||
          changes[key] !== "",
      )
    ) {
      message.error("Please fill out all fields");
      return;
    }

    if (
      !changes.otherSearchTerms.every((searchTerm, idx) => {
        if (idx === 0) {
          return true;
        }
        return searchTerm !== "" && searchTerm !== undefined;
      })
    ) {
      message.error("Please fill out all search terms");
      return;
    }

    if (premiumTiers.length === 1) {
      (() =>
        changes.jobOfferMap?.forEach((item) => {
          item.role = premiumTiers[0];
        }))();
    }

    if (changes.jobOfferMap && !changes.jobOfferMap.every((item) => item.email && item.role)) {
      message.error("Please make sure all job offers are filled out correctly");
      return;
    }

    if (
      changes.jobOfferMap &&
      changes.jobOfferMap.map((item) => item.email).length !==
        [...new Set(changes.jobOfferMap.map((item) => item.email))].length
    ) {
      message.error("The same email cannot appear in job offer list multiple times");
      return;
    }

    if (!group.jobOfferMap) {
      group.jobOfferMap = {};
    }
    (() =>
      changes.jobOfferMap?.forEach((item) => {
        if (group.jobOfferMap[item.email]) {
          item.roles = { ...group.jobOfferMap[item.email].roles, [year]: item.role };
        } else {
          item.roles = { [year]: item.role };
        }
        delete item.role;
      }))();

    changes.hasLinkedin = !changes.doesntHaveLinkedin;
    if (!changes.hasLinkedin && changes.ein?.length < 10) {
      message.error("Please enter a valid EIN");
      return;
    }

    if (changes.hasLinkedin) {
      changes.ein = "";
    } else {
      changes.linkedinUrl = "";
    }

    if (logo !== null) {
      try {
        const storageRef = ref(
          storage,
          // eslint-disable-next-line no-undef
          `${process.env.REACT_APP_FB_CUSTOM_FOLDER_NAME}/${changes.name} - ${logo.name}`,
        );
        await uploadBytes(storageRef, logo);
        const url = await getDownloadURL(storageRef);
        changes.logoUrl = url;
        setLogo(null);
      } catch (error) {
        message.error("Error uploading logo");
        return;
      }
    }

    changes.editable = !changes.lockEditing;
    changes.skipFollowUpQuestions = !changes.requiresFollowUpQuestions;
    delete changes.lockEditing;
    delete changes.requiresFollowUpQuestions;

    if (disableOnlyAllowListedDomainsSwitch) {
      changes.onlyAllowListedDomains = false;
    }

    if (disableRequiresFollowUpQuestions) {
      changes.requiresFollowUpQuestions = false;
    }

    delete changes.employeeCSV;
    const employeesWithWrongRoleEmails = employeesWithWrongRole.map((e) => e.email);
    changes.employeesAdded = employeesAdded.filter(
      (employee) => !employeesWithWrongRoleEmails.includes(employee.email),
    );
    changes.employeesRemoved = employeesRemoved.filter(
      (employee) => !employeesWithWrongRoleEmails.includes(employee.email),
    );

    changes.displayOnGroupsPage = !changes.hideOnSearchPage;
    delete changes.hideOnSearchPage;

    const response = await updateGroup({ originalName, form: changes });
    if (response.status === 200) {
      message.success("Group updated successfully");
      setGroup({ name: "", displayName: "", masterReports: "" });
      setEmployeesAdded([]);
      setEmployeesRemoved([]);
      setEmployeesWithWrongRole([]);
      searchForm.resetFields();
      form.resetFields();
      updateGroupsList();
      setActiveKey("1");
    } else {
      message.error(response.response.data.message);
    }
  };

  const handleSearchGroup = async (changes) => {
    const response = await getGroup({ name: changes.search });
    if (response.status === 200) {
      const group = response.data.group;
      if (!group?.otherSearchTerms || group?.otherSearchTerms?.length === 0) {
        group.otherSearchTerms = [""];
      }
      if (!group?.otherDomains || group?.otherDomains?.length === 0) {
        group.otherDomains = [""];
      }
      setGroup(group);
      setEmployeesAdded([]);
      setEmployeesRemoved([]);
      setEmployeesWithWrongRole([]);
      setReports([]);
      setSelectedReportId(null);
      setYear(null);
      setOriginalName(group.name);
      form.resetFields();
    } else {
      message.error(response.response.data.message);
    }
  };

  const getDaysDifference = (date1, date2) => {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  useEffect(() => {
    const employees = employeesAdded.map((employee) => ({
      ...employee,
      jsDate: new Date(employee.date),
    }));
    setCurrentEmployees(employees.filter((employee) => getDaysDifference(employee.jsDate, new Date()) >= 30));
    setNewHires(employees.filter((employee) => getDaysDifference(employee.jsDate, new Date()) < 30));
  }, [employeesAdded]);

  useEffect(() => {
    groupNameWatch?.length > 0 &&
    ["microsoft", "morganstanley", "tesla", "upenn", "quinnipiac", "bankofamerica", "opm"].includes(groupNameWatch)
      ? setDisableRequiresFollowUpQuestions(false)
      : setDisableRequiresFollowUpQuestions(true);
  }, [groupNameWatch]);

  useEffect(() => {
    requiresFollowUpQuestionsWatch ? setDisableMasterReportsInput(true) : setDisableMasterReportsInput(false);
  }, [requiresFollowUpQuestionsWatch]);

  useEffect(() => {
    const loadReports = async (ids) => {
      const token = await getToken();
      const response = await getReportsHeader(ids, token);
      if (response.status === 200) {
        const reports = response.data.reports;
        const filteredReports = reports.filter((report) => report.year);
        setReports(filteredReports);
        setSelectedReportId(null);
        setYear(null);
      }
    };
    const reportIds = group.masterReports;
    if (reportIds.length > 0) {
      loadReports(reportIds);
    }
  }, [group]);

  useEffect(() => {
    const fetchPremiumTiers = async () => {
      const token = await getToken();
      const response = await getPremiumTiers({ name: group.name, year, reportId: selectedReportId, token });
      if (response.status === 200 && response.data) {
        setPremiumTiers(response.data);
      }
    };

    if (group && selectedReportId && year) {
      fetchPremiumTiers();
    }
  }, [selectedReportId]);

  return (
    <Card title="Update Group">
      <Form
        form={searchForm}
        layout="inline"
        onFinish={handleSearchGroup}
        style={{ marginBottom: "2%" }}
        initialValues={{
          search: "",
        }}
      >
        <Item name="search" rules={[{ required: true, message: "name required!" }]}>
          <Input autoComplete="off" />
        </Item>

        <Item>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Item>
      </Form>

      {group && group.name && (
        <Form
          form={form}
          onFinish={handleUpdateGroup}
          layout="horizontal"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          initialValues={{
            displayName: group.displayName,
            name: group.name,
            masterReports: group.masterReports,
            otherSearchTerms: group.otherSearchTerms,
            otherDomains: group.otherDomains,
            doesntHaveLinkedin: !group.hasLinkedin,
            lockEditing: !group.editable,
            prestigeRanking: group.prestigeRanking,
            linkedinUrl: group.linkedinUrl,
            ein: group.ein,
            requiresFollowUpQuestions: !group.skipFollowUpQuestions,
            requiresUserPremiums: group.requiresUserPremiums,
            requiresEmailVerification: group.requiresEmailVerification,
            onlyAllowListedDomains: group.onlyAllowListedDomains,
            excludePersonalDomains: group.excludePersonalDomains,
            skipEmailVerification: group.skipEmailVerification,
            verifyWorkEmailAgainstDatabase: group.verifyWorkEmailAgainstDatabase,
            verifyJobOfferAgainstDatabase: group.verifyJobOfferAgainstDatabase,
            startOnHealthcareSpendingOnly: group.startOnHealthcareSpendingOnly,
            hideOnSearchPage: !group.displayOnGroupsPage,
          }}
        >
          <GroupForm
            imageUrl={group.logoUrl}
            setLogo={setLogo}
            disableRequiresFollowUpQuestions={disableRequiresFollowUpQuestions}
            disableMasterReportsInput={disableMasterReportsInput}
            employeesAdded={employeesAdded}
            setEmployeesAdded={setEmployeesAdded}
            currentEmployees={currentEmployees}
            newHires={newHires}
            employeesRemoved={employeesRemoved}
            setEmployeesRemoved={setEmployeesRemoved}
            employeesWithWrongRole={employeesWithWrongRole}
            setEmployeesWithWrongRole={setEmployeesWithWrongRole}
            skipEmailVerificationWatch={skipEmailVerificationWatch}
            requiresEmailVerificationWatch={requiresEmailVerificationWatch}
            verifyWorkEmailAgainstDatabaseWatch={verifyWorkEmailAgainstDatabaseWatch}
            doesntHaveLinkedinWatch={doesntHaveLinkedinWatch}
            form={form}
            group={group}
            reports={reports}
            selectedReportId={selectedReportId}
            setSelectedReportId={setSelectedReportId}
            year={year}
            setYear={setYear}
            premiumTiers={premiumTiers}
          />
          <Item>
            {(!onlyAllowListedDomainsWatch && !excludePersonalDomainsWatch) ||
            otherDomainsWatch?.every((domain) => domain === "") ? (
              <Popconfirm
                title={
                  otherDomainsWatch?.every((domain) => domain === "")
                    ? "The domain list is empty. Are you sure you want to continue?"
                    : "This configuration allows any email as valid. Are you sure you want to continue?"
                }
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  setDisableOnlyAllowListedDomainsSwitch(true);
                  form.submit();
                }}
              >
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Popconfirm>
            ) : employeesWithWrongRole.length > 0 ? (
              <Button type="primary" htmlType="submit" disabled>
                Update
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            )}
            <Popconfirm
              title={`Are you sure you want to delete ${group.displayName}?`}
              okText="Yes"
              cancelText="No"
              onConfirm={async () => {
                const { status } = await deleteGroup({ name: group.name });
                if (status === 200) {
                  message.success("Group deleted successfully");
                  searchForm.resetFields();
                  setGroup({ name: "", displayName: "", masterReports: "" });
                  form.resetFields();
                  updateGroupsList();
                  setActiveKey("1");
                }
              }}
            >
              <Button type="danger" style={{ marginLeft: "10px" }}>
                Delete
              </Button>
            </Popconfirm>
          </Item>
        </Form>
      )}
    </Card>
  );
};

export default UpdatePlansPanel;
