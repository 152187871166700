export default {
  formId: "form",
  formField: {
    networksTable: {
      name: "networksTable",
    },
    providersList: {
      name: "providersList",
    },
    providersGrid: {
      name: "providersGrid",
    },
  },
};
