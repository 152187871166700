import React from "react";
import { Icon } from "@ant-design/compatible";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 10px 5px;
  z-index: 1;
`;

const styles = {
  color: "#fff"
};

const AntIcon = ({ type, onClick }) => {
  return (
    <Wrapper>
      <Icon onClick={onClick} type={type} style={styles} />
    </Wrapper>
  );
};

export default AntIcon;
