import React, { useState, createRef, useMemo } from "react";
import JSONTree from 'react-json-tree';
import styled from "styled-components";
import {
  Card,
  Row,
  Col,
  Button
} from "antd";
import { ErrorsFeedback } from 'components'
import DiffsFeedback from "../DiffsFeedback";

const SubmitButton = styled(Button)`
  max-width: 150px;
  margin: 10px;
`

const FeedbackCard = styled(Card)`
  flex-direction: row;
`

const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#272822',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
};

const PreviewResultsPlansCSV = ({errorsList, plansPreview, differences, onSubmit, submitDisabled}) => {
  return (
    <FeedbackCard title="Response">
        <Row gutter={16}>
          <Col span={differences? 8 : 12}>
            {
              errorsList &&
              <ErrorsFeedback errorsList={errorsList}/>
            }
          </Col>
          <Col span={differences? 8 : 12}>
            {
              plansPreview &&
              <Card title="Preview Results">
                <JSONTree theme={theme} data={plansPreview}></JSONTree>
              </Card>
            }
          </Col>
          <Col span={8}>
            {
              differences &&
              <DiffsFeedback differences={differences}/>
            }
          </Col>
        </Row>
        <SubmitButton onClick={onSubmit} type="primary" disabled={submitDisabled} htmlType="submit">Submit results</SubmitButton>
      </FeedbackCard>
  )
}

export default PreviewResultsPlansCSV
