import React from "react";

// import { Input, InputNumber } from "antd";
import { useField, useFormikContext } from "formik";
import Text from "components/atoms/Text";
import { Input, InputNumber } from "formik-antd";
import styled, { css } from "styled-components";
import RequiredField from "../../atoms/RequiredField";
import "./styles.css";
import { Button, Tooltip, Input as InputAntd } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const StyledInput = styled(Input)`
  height: 40px !important;
  width: 50% !important;
  ${({ size }) => size === "small" && `width: 15% !important`};
  ${({ size }) => size === "fullwidth" && `width: 100% !important`};
`;

const StyledInputNumber = styled(InputNumber)`
  height: 40px !important;

  div.ant-input-number-input-wrap {
    height: 100% !important;

    input.ant-input-number-input {
      height: 100% !important;
    }
  }
`;

const StyledButton = styled(Button)`
  height: 40px !important;
  :hover {
    border-color: #ff4d4f !important;
    color: #ff4d4f !important;
  }
  :focus {
    border-color: #ff4d4f !important;
    color: #ff4d4f !important;
  }
`;

const Wrapper = styled.div`
  ${({ inline }) =>
    inline &&
    css`
      display: flex;
      > * {
        margin-right: 10px;
      }
    `}
`;

const FormDetachField = ({
  label,
  type,
  name,
  setExpanded,
  condition,
  size,
  inline,
  required,
  key,
  touched,
  onButtonClick,
  handleOnChange = () => { },
  ...props
}) => {
  const [field, meta, helpers] = useField(name);
  const { setFieldValue } = useFormikContext();
  return (
    <div key={name}>
      <Wrapper inline={inline}>
        {label && (
          <Text formLabel>
            {label}
            {required && (
              <RequiredField
                error={(meta.touched || touched || touched) && meta.error}
              />
            )}
          </Text>
        )}
        <InputAntd.Group compact>
          {type === "number" ? (
            <StyledInputNumber
              min={0}
              {...field}
              {...props}
              key={name}
              onChange={(value) => {
                handleOnChange(value);
                helpers.setValue(Number(value));
                if (condition) {
                  if (value > 0) {
                    setExpanded(true);
                    setFieldValue(condition, "");
                  } else {
                    setExpanded(false);
                    setFieldValue(condition, "");
                  }
                }
              }}
              status={(meta.touched || touched) && meta.error && "error"}
            />
          ) : (
            <StyledInput
              {...field}
              {...props}
              size={size}
              key={name}
              status={(meta.touched || touched) && meta.error && "error"}
            />
          )}
          <StyledButton
            style={{
              display: props.style.display,
              visibility: props.style.visibility,
            }}
            tabindex='-1'
            onClick={onButtonClick}
            icon={<CloseOutlined style={{ fontSize: 14 }} />}
          />
        </InputAntd.Group>
      </Wrapper>
      {(meta.touched || touched) && meta?.error && (
        <Text red>{meta.error}</Text>
      )}
    </div>
  );
};

export default FormDetachField;
