import React from "react";
import styled from "styled-components";
import WaveHeader from "./wave-header.svg";
import { IconLink, Block, NewNavigation } from "components";

const Wrapper = styled(Block)`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 0 auto;
  width: 100%;
  background: #142444;
  margin: 0 auto;
  height: 70px;
  padding: 0 90px 0 38px;

  @media (max-width: 480px) {
    padding: 0;
  }

  ${({ invert }) =>
    invert &&
    `
    background-color: #fff;
  `}

  ${({ secondaryBackground }) =>
    secondaryBackground && `background-color: #d9f3f1;`}
`;

const HeaderContainer = styled.div`
  width: 100%;
  align-content: center;
`;

const Centralized = styled.div`
  width: 1000px;
  display: flex;
  margin: 0 auto;
  @media (max-width: 480px) {
    width: 100%;
    padding: 0;
  }
`;

const WaveBackground = styled.svg`
  background: url(${WaveHeader});
  margin-top: 70px;
  width: 100%;
  background-color: #142444;
  background-repeat: no-repeat;
  background-size: cover;
  height: 90px;

  @media (max-width: 800px) {
    height: 80px;
  }

  @media (max-width: 630px) {
    height: 45px;
  }

  @media (min-width: 1400px) {
    height: 120px;
  }

  @media (min-width: 2000px) {
    height: 180px;
  }
`;

const InnerWrapper = styled.div`
  padding: 6px;
  padding-bottom: 6px;
  padding-left: 3%;

  @media (max-width: 950px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 480px) {
    display: flex;
    padding-top: 7px;
  }
`;

const Header = (props) => {
  return (
    <HeaderContainer>
      <Wrapper opaque reverse {...props}>
        <Centralized>
          <InnerWrapper>
            <IconLink
              href="https://predictabill.com/"
              noMargin
              logo
              height={31}
              {...props}
            />
          </InnerWrapper>
          <NewNavigation />
        </Centralized>
      </Wrapper>
      <WaveBackground />
    </HeaderContainer>
  );
};

export default Header;
