import { Space } from "antd";
import Text from "components/atoms/Text";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { isEditingPlan } from "./utils/helpers";

const Wrapper = styled.div`
  margin-bottom: 100px;
  min-height: calc(100vh);
  padding: 2.5% 20px;

  @media (min-width: 768px) {
    width: 90%;
    margin: 50px auto 100px auto;
  }
`;

const WithEditTitleWrapper = ({ showTitle, children }) => {
  if (!showTitle) return children;
  return (
    <Space direction="vertical" size="large">
      <Text sectionTitle big>
        Verify Your Plan Details
      </Text>
      {children}
    </Space>
  );
};

const CreatePlanSteps = ({ setAdvancedTabActive }) => {
  const steps = useSelector((state) => state.plan.steps);

  const current = useSelector((state) => state.plan.current);

  const { values } = useFormikContext();
  const { enterAdvancedDetails } = values;

  useEffect(() => {
    setAdvancedTabActive(enterAdvancedDetails);
  }, [enterAdvancedDetails]);

  let filteredSteps = !enterAdvancedDetails
    ? steps.filter((item) => item.title !== "Advanced")
    : steps;
  if (isEditingPlan()) {
    filteredSteps = filteredSteps.filter((item) => item.title !== "Start");
  }

  return filteredSteps?.[current] ? (
    <Wrapper>
      <WithEditTitleWrapper showTitle={isEditingPlan()}>
        {filteredSteps[current].content}
      </WithEditTitleWrapper>
    </Wrapper>
  ) : null;
};

export default CreatePlanSteps;
