import React from "react";
import styled from "styled-components";
import {
  CalculateFormTemplate,
  Text,
  GrandTotal,
  CalculatePriceForm,
  Spinner,
} from "components";

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 380px;
`;

const InnerWrapper = styled.div`
  margin: auto;
  text-align: center;
`;

const Footer = styled.footer`
  text-align: center;
  padding: 0;
`;
const Main = styled.div`
  padding: 10px 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const FormText = styled(Text)`
  padding: 0 15px;
`;

const PremiumPredictedTotal = (props) => {
  const renderFirst = () => (
    <FormText arial>
      {`Tell us about your birth plan and insurance plan details... `}
    </FormText>
  );

  const renderBeforeData = () => (
    <CalculatePriceForm charges={props.charges.before} />
  );
  const renderAfterData = () => (
    <CalculatePriceForm charges={props.charges.after} noText={true} />
  );

  return (
    <>
      <CalculateFormTemplate
        blue
        title="Your Premiums & Predicted Out-of-Pocket Costs"
        size="big"
      >
        <Wrapper>
          {props.isRequesting ? (
            <InnerWrapper>
              <Spinner center />
            </InnerWrapper>
          ) : props.charges.before && props.charges.after ? (
            <>
              <Main>
                {props.charges.before.length > 0 && renderBeforeData()}
              </Main>
              <Footer>
                <GrandTotal
                  total={props.subTotal}
                  title="Subtotal Costs at Baby's Birth"
                  green
                />
              </Footer>
              <Main>{props.charges.after.length > 0 && renderAfterData()}</Main>
              <Footer>
                {props.charges.before && props.charges.after && (
                  <>
                    <GrandTotal
                      total={props.grandTotal}
                      title="Grand Total"
                      blue
                    />
                  </>
                )}
              </Footer>
            </>
          ) : (
            <InnerWrapper>{renderFirst()}</InnerWrapper>
          )}
        </Wrapper>
      </CalculateFormTemplate>
    </>
  );
};

export default PremiumPredictedTotal;
