// V3
// switch to include plans instead of exclude

import React, { useState } from "react";
import styled from "styled-components";
import { Collapse as antdCollapse } from "react-collapse";

import { AntModalView as ModalView, FormSelect as Select, Link, Text } from "components";

import { Icon } from "@ant-design/compatible";

import { Row, ScrollWrapper } from "components/organisms/YearlyCost";
import OptimizationResultColumn from "components/molecules/OptimizationResultColumn";
import { getToken } from "services/utils";
import { createNewPreference, getPlansArr, optimizeV2 } from "services/api";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/report/actions";
import useIsMobile from "components/hooks/useIsMobile";

const CustomModal = styled(ModalView)`
  max-width: 95vw !important;
  min-width: auto !important;
`;

const Collapse = styled(antdCollapse)`
  justify-content: "flex-start";
`;

const FlexWrapper = styled.div`
  overflow-y: hidden;
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  /* justify-content: ${(props) => (props.justify ? props.justify : "space-evenly")}; */
  width: 100%;

  @media (max-width: 768px) {
    overflow-x: scroll;
  }
`;

const IconWrapper = styled(Icon)`
  display: inline-block;
  margin-left: 5px;
`;

const StyledLink = styled(Link)`
  font-size: 16px;
`;
const StyledText = styled(Text)`
  font-size: 16px;
`;

const InnerWrapper = styled.div`
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: column;
  align-items: ${(props) => (props.align ? `flex-${props.align}` : "center")};
  justify-content: start;
  width: ${(props) => (props.header ? "" : props.width ? props.width : "95%")};
  margin: ${(props) => (props.margin ? props.margin : "0 15px")};
  ${({ gap }) => gap && `gap: ${gap};`}

  > .ReactCollapse--collapse {
    transition: height 500ms;

    .ReactCollapse--content {
      width: 100%;

      .ReactCollapse--collapse {
        width: 100%;
      }
    }

    > * {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
`;

const dolarUS = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

const TiedOptimizationsModal = ({
  show,
  close,
  updateState,
  otherOptimizations,
  selectedOptimizations,
  report,
  broaderNetworksFromAllMyOptions,
}) => {
  const dispatch = useDispatch();
  const [showRows, setShowRows] = useState({
    total: false,
    Premium: false,
    Surcharge: false,
    Insurance: false,
    "Out-Of-Network": false,
    "Opt-Out Payment": false,
    "HSA before": false,
    "HRA before": false,
    leftover: false,
    "HSA after": false,
    "HRA after": false,
  });

  const { isMobile } = useIsMobile();
  const serviceOption = useSelector((state) => state.report.serviceOption);

  const handleShow = (row, value) => {
    if (row === "total" || !showRows[row]) setShowRows({ ...showRows, [row]: value ? value : !showRows[row] });
    // if (show === "total") window.scrollTo(0, myRef.current.offsetTop);
  };

  const updateSelected = async (newSelected) => {
    const result = {
      selected: newSelected,
      otherOptimizations: [
        ...otherOptimizations.map((el) => el._id).filter((el) => el !== newSelected),
        selectedOptimizations._id,
      ],
    };

    const results = [...report.preferences["1"].results];
    const index = results.findIndex((i) => i.selected == selectedOptimizations._id);

    results[index] = result;

    const newPreference = {
      ...report.preferences["1"],
      results: results,
    };

    const token = await getToken();
    const res = await createNewPreference(report._id, "1", newPreference, token);
    if (res.status === 200) {
      try {
        const opt = await optimizeV2({
          reportId: report._id,
          preferenceKey: 1,
          onlyExpected: serviceOption,
        });
        updateState(opt, newPreference);
        if (show === 0) {
          const optimization = opt.data.filteredResults[0].selected;

          dispatch(Actions.Creators.loadRecommendedByOptmization({ ...optimization, version: report.version }));

          const { birthParent, partner } = optimization.optimizationDetails;
          const {
            broaderNetworks,
            providersGrid,
            event,
            isDue,
            partner: partnerFromReport,
            birthParent: birthParentFromReport,
            preferences,
          } = report;
          const { birthParentPlans, partnerPlans } = preferences[1].include;
          const plansIds = [birthParent.externalId, partner.externalId].filter((el) => Boolean(el));
          const data = await getPlansArr(plansIds, token);

          dispatch(
            Actions.Creators.loadRecommendedByReport({
              data,
              broaderNetworks,
              providersGrid,
              optimization: optimization,
              event,
              isDue,
              broaderNetworksFromAllMyOptions,
              plansFromPartner: partnerFromReport.planIds,
              plansFromBirthParent: birthParentFromReport.planIds,
              preferenceBirthParentPlans: birthParentPlans,
              preferencePartnerPlans: partnerPlans,
              join: report.preferences["1"].join,
            }),
          );
        }
      } catch (error) {
        await createNewPreference(report._id, "1", report.preferences["1"], token);
      }
    }
    close();
  };

  return (
    otherOptimizations?.length > 0 && (
      <CustomModal
        isShow={show !== false}
        close={close}
        textButton="close"
        handleAction={() => {
          close();
        }}
        title={`${otherOptimizations ? otherOptimizations.length + 1 : ""} coverage options with Grand Total of ${
          selectedOptimizations ? dolarUS.format(Math.floor(selectedOptimizations.unexpected.total)) : ""
        }`}
        size="big"
      >
        <FlexWrapper justify="center" align="start">
          <InnerWrapper header align="end">
            <Row height="100px"></Row>
            <Row header></Row>

            <Collapse isOpened={showRows.total}>
              <Collapse isOpened={showRows.Premium}>
                <Row justify="left">
                  <StyledText blue bold fontSize={16} lHeight="1rem" justBetween>
                    Premiums
                  </StyledText>
                </Row>
              </Collapse>

              <Collapse isOpened={showRows.Surcharge}>
                <Row justify="left">
                  <StyledText blue bold fontSize={16} lHeight="1rem" justBetween>
                    Surcharges
                  </StyledText>
                </Row>
              </Collapse>

              <Collapse isOpened={showRows.Insurance}>
                <Row justify="left">
                  <StyledText blue bold fontSize={16} lHeight="1rem" justBetween>
                    In-Network Healthcare Charges
                  </StyledText>
                </Row>
              </Collapse>

              <Collapse isOpened={showRows["Out-Of-Network"]} noWrap>
                <Row justify="left">
                  <StyledText blue bold fontSize={16} lHeight="1rem" justBetween>
                    Out-Of-Network Charges
                  </StyledText>
                </Row>
              </Collapse>

              <Collapse isOpened={showRows["Opt-Out Payment"]}>
                <Row justify="left">
                  <StyledText blue bold fontSize={16} lHeight="1rem" justBetween>
                    Opt-Out Payment
                  </StyledText>
                </Row>
              </Collapse>

              <Collapse isOpened={showRows["HSA before"]}>
                <Row justify="left">
                  <StyledText blue bold fontSize={16} lHeight="1rem" justBetween>
                    HSA
                  </StyledText>
                </Row>
              </Collapse>

              <Collapse isOpened={showRows["HRA before"]}>
                <Row justify="left">
                  <StyledText blue bold fontSize={16} lHeight="1rem" justBetween>
                    HRA
                  </StyledText>
                </Row>
              </Collapse>
            </Collapse>
            <Row
              justify="start"
              align="center"
              bgColor="#E8EEFA"
              padding={"4px 10px;"}
              style={{
                boxShadow: "60px 0px #E8EEFA",
              }}
            >
              <Link bold blue textAlign justBetween fontSize={20} onClick={() => handleShow("total")}>
                Estimated Grand Total
                <IconWrapper type={showRows.total ? "up" : "down"} />
              </Link>
            </Row>
          </InnerWrapper>
          <ScrollWrapper>
            <div style={{ display: "flex" }}>
              {otherOptimizations &&
                [selectedOptimizations].concat(otherOptimizations).map((option, index) => {
                  return (
                    <InnerWrapper width={"180px"}>
                      {/* <OptimizationResultColumn
                          optimization={option}
                          preferenceKey={option.preferenceKey}
                          showRows={showRows}
                          handleShow={handleShow}
                          isSelected={index === 0}
                          handleSelectOtherOption={updateSelected}
                        /> */}
                      <OptimizationResultColumn
                        optimization={option}
                        preferenceKey={1}
                        index={index + 1}
                        report={report}
                        showRows={showRows}
                        handleShow={handleShow}
                        isSelected={index === 0}
                        handleSelectOtherOption={() => updateSelected(option._id)}
                      />
                    </InnerWrapper>
                  );
                })}
            </div>
          </ScrollWrapper>
        </FlexWrapper>
      </CustomModal>
    )
  );
};

export default TiedOptimizationsModal;
