import React, { useRef, useState, useEffect } from "react";
import "./styles.css";
/**
 * A segmented control component.
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.name - The name of the segmented control.
 * @param {Array.<{value: number, ref: React.RefObject, id: string, label: string}>} props.segments - The segments of the control.
 * @param {Function} props.callback - The callback function to be called when the active segment changes.
 * @param {React.RefObject} props.controlRef - The ref of the control.
//  * @param {'small'|'medium'|'large'} props.size - The size of the control. Not currently being used
 * @param {number} props.initialValue - The initial value of the control.
 * @returns {React.Element} The rendered segmented control component.
 */
const SegmentedControl = ({ name, segments, callback, controlRef, initialValue }) => {
  const [activeValue, setActiveValue] = useState(initialValue != null ? initialValue : segments[0]?.value);
  const componentReady = useRef();

  // Determine when the component is "ready"
  useEffect(() => {
    componentReady.current = true;
  }, []);

  useEffect(() => {
    const activeSegment = segments.find((segment) => segment.value === activeValue);
    const activeSegmentRef = activeSegment ? activeSegment.ref : null;
    if (!activeSegmentRef?.current) return;

    const { offsetWidth, offsetLeft } = activeSegmentRef.current;
    const { style } = controlRef.current;
    // @todo: re-enable size prop in the future
    // const offsetW = size === "small" ? 40 : size === "medium" ? 100 : offsetWidth;
    // const offsetL = size === "small" ? 40 : size === "medium" ? 100 : offsetLeft;
    const offsetW = offsetWidth;
    const offsetL = offsetLeft;

    const highlightXPos = activeValue == 0 ? 0 : offsetL;
    const padding = activeValue == segments.length - 1 ? 1.5 : 0;
    const highlightWidth = offsetW + padding;
    style.setProperty("--highlight-width", `${highlightWidth}px`);
    style.setProperty("--highlight-x-pos", `${highlightXPos}px`);
  }, [activeValue, callback, controlRef, segments]);

  const onInputChange = (value) => {
    setActiveValue(value);
    callback(value);
  };

  return (
    <div className={`controls-container`} ref={controlRef}>
      <div className={`controls ${componentReady.current ? "ready" : "idle"}`}>
        {segments?.map((item) => (
          <div
            key={item.value}
            className={`segment ${item.value === activeValue ? "active" : "inactive"}`}
            ref={item.ref}
          >
            <input
              type="radio"
              value={item.value}
              id={item?.id || item.label}
              name={name}
              className={`block`}
              onChange={() => onInputChange(item.value)}
              checked={item.value === activeValue}
            />
            <label
              htmlFor={item.label}
              style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}
            >
              {item?.leftAddornment}
              {item.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SegmentedControl;
