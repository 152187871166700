import React, { createRef, useEffect, useState } from "react";
import {
  PageTemplate,
  Footer,
  Header,
  CalculateHeader,
  Link,
  ReportForm,
  ReportSearch,
  UserForm,
  UserSearch,
  Spinner,
} from "components";
import { Button, Card, Form, Input, InputNumber, message, Select, Space, DatePicker } from "antd";
import styled from "styled-components";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import _ from "lodash";
import {
  counties,
  validateZipCode,
} from "components/organisms/OnboardingLocationEventSectionV2";
import { getObamacarePlans, getProfile } from "services/api";
import { getToken } from "services/utils";

const formItemLayoutWithOutLabel = {
  wrapperCol: { span: 14, offset: 6 },
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

const Item = styled(Form.Item).attrs(({rules, ...props}) => ({rules: rules ? rules : [{required:true, message: 'Required Field'}], ...layout, ...props}))`
  width: 650px;
`;

const StyledCard = (props) => (
  <Card
    headStyle={{ background: props.main ? "#d9f3f1" : "#ddd" }}
    {...props}
  /> 
);

const StyledSelect = styled(Select).attrs((props) => ({ showSearch: true, optionFilterProp:"children", filterOption: (input, option) => ( option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0), ...props, }))``;


const ResponseDiv = styled.div`
  margin: 20px; 
`

const StyledForm = styled(Form)`
  display: grid;
`;

const calculateAge = (dob, {month, year}) => {
  const diff = new Date(year,month,1).getTime() - new Date(dob).getTime();
  const age = new Date(diff);
  return Math.abs(age.getUTCFullYear() - 1970);
};


const ObamacarePage = () => {
  const [form] = Form.useForm();


  const [formState, setFormState] = useState();
  const [formZipCode, setFormZipCode] = useState();

  const [downloadButton, setDownloadButton] = useState({})
  
  const [attachToUser, setAttachToUser] = useState(false);

  const [loadingRequest, setLoadingRequest] = useState(false);
  const [responseMessage, setResponseMessage] = useState();

  const [user, setUser] = useState();
  const [countyOptions, setCountyOptions] = useState([]);

  const searchUser = async (e) => {
    e.preventDefault()
    try{
      const token = await getToken();
      const response = await getProfile(null, token, user)
      if(response.status === 200){
        form.setFieldsValue({userId: response.data.user._id})
        const {zipCode, countyFips} = response.data.report.event.general
        onChangeZipCode(zipCode)
        if(countyFips !== 'n/a') form.setFieldsValue({place:{countyfips: countyFips}})
      }
      else {
        const errorMessage = response.response ? response.response.data.message : 'User not found'
        message.error(errorMessage)
      }
    }catch(err) {

    }
  }

  const onChangeZipCode = (value) => {
    setCountyOptions([]);
    setFormZipCode(value);
    const zipState = validateZipCode(value);
    if(zipState){
      setFormState(zipState);
      form.setFieldsValue({ place: {state: zipState} });
    }
  };

  useEffect(() => {
    form.setFieldsValue({place:{state:formState, zipcode: formZipCode}})
    // get all names of counties in given state
    const names =
      formState && counties[formState] ? counties[formState] : [];
    setCountyOptions(names);
    
  }, [formState, formZipCode]);

  const onSubmit = async (form, attachToUser) => {
    if(!attachToUser) {
      form.userId = undefined
    }
    setLoadingRequest(true)
    setResponseMessage({})
    form.household.people = form.household.people.map(person => ({
      age: calculateAge(person.birthDate,{year: form.year, month:form.month}),
      ...person,
      birthDate: undefined,
    }))
    try{
      const token = await getToken()
      const response = await getObamacarePlans(form, token)
      setDownloadButton({})
      if(response.status  === 204){
        message.warn("No plans found")
      }
      else if (response.status === 200 && !attachToUser) {
        const responseType = response.headers['content-type']

        const url = window.URL.createObjectURL(new Blob([response.data], 
          {type: responseType})
        );

        setDownloadButton({url, fileName: 'ServicesAndPlans'})
        const resultMessage="Plans and services ready to download"
        message.success(resultMessage)
        setResponseMessage({color:'green', text: resultMessage})

      }
      else if (response.status === 206) {
        const responseType = response.headers['content-type']

        const url = window.URL.createObjectURL(new Blob([response.data], 
          {type: responseType})
        );

        let fileName
        if( responseType.includes('csv')) {
          fileName = 'Services'
          const resultMessage = "Plans added and services ready to download" 
          message.success(resultMessage)
          setResponseMessage({color:'green', text: resultMessage})
        }
        else {
          fileName = 'ServicesAndPlans'
          const resultMessage = "Plans failed, files ready to download"
          message.warn(resultMessage)
          setResponseMessage({color:'orange', text: resultMessage})
        }
        
        setDownloadButton({url, fileName})
      }
      else {
        const errorMessage =  response.response ? 
          String.fromCharCode.apply(null, new Uint8Array(response.response.data)) :
          'Request Failed'
        
        setResponseMessage({text: errorMessage, color: 'red'})
        message.error(errorMessage)
      }
    } catch(e){
      console.log(e)
    }
    
    setLoadingRequest(false)
  };

  const initialValues = {
    initialValues: {
      year: new Date().getFullYear(),
      household: {
        people: [
          {
            relationship: 'Self'
          }
        ]
      }
    },
  };

  return (
    <>
    <StyledCard title="Marketplace Plans" main {...initialValues}>
      <Space direction="vertical">
        <Space style={{ marginBottom: 20 }}>
          <label>Search User Email</label>
          <Input onChange={(e) => setUser(e.target.value)} />
          <Button type="primary" onClick={searchUser}>
            Search
          </Button>
        </Space>
        <Space style={{ marginBottom: 20 }}>
          <label>Attach to User?</label>
          <Select defaultValue={false} onChange={setAttachToUser}>
            <Select.Option value={true}>Yes</Select.Option>
            <Select.Option value={false}>No</Select.Option>
          </Select>
        </Space>
      </Space> 
      <StyledForm
        layout={layout}
        form={form}
        onFinishFailed={() => {message.error('1 or more required fields are missing or invalid')}}
        {...initialValues}
        onFinish={(form) => onSubmit(form, attachToUser)}
      >
        <StyledCard title="General" style={{ width: "50%" }}>
          <Item
            label="User"
            name="userId"
            rules={[{required: attachToUser, message:'Must input user'}]}
          >
            <input disabled/>
          </Item>
          <Item label="Year" name="year">
            <StyledSelect>
              {_.range( new Date().getFullYear() + 1, 2013).map((year) => (
                <Select.Option key={"year:" + year} value={year}>
                  {''+year}
                </Select.Option>
              ))}
            </StyledSelect>
          </Item>
          <Item label="Start Month" name="month">
            <StyledSelect>
              {_.range(0, 12).map((month) => (
                <Select.Option key={"year:" + month} value={month}>
                  {new Date(0,month).toLocaleString('default',{month: 'long'})}
                </Select.Option>
              ))}
            </StyledSelect>
          </Item>
        </StyledCard>
        <StyledCard title="Place" name="place" style={{ width: "50%" }}>
          <Item
            label="ZIP Code" 
            name={["place","zipcode"]}
            rules={[
              () => ({
                validator: (rule, value) => {
                  if(!validateZipCode(value)) return Promise.reject("Invalid Zip Code")
                  else return Promise.resolve()
                } 
              })
            ]}
          >
            <Input onChange={e => onChangeZipCode(e.target.value)} />
          </Item>
          <Item label="State" name={["place","state"]}>
            <StyledSelect onChange={value => {setFormState(value)}}>
              {Object.keys(counties).map((state) => (
                <Select.Option key={"state:" + state} value={state}>
                  {state}
                </Select.Option>
              ))}
            </StyledSelect>
          </Item>
          <Item 
            label="County"
            name={["place","countyfips"]}
          >
            <StyledSelect
              placeholder={
                countyOptions.length
                  ? "Select One"
                  : "Set a State or Zip Code first"
              }
            >
              {Object.entries(countyOptions).map(([countyName, fips]) => (
                <Select.Option
                  key={'countyOptions'+fips}
                  value={fips}
                >
                  {countyName}
                </Select.Option>
              ))}
            </StyledSelect>
          </Item>
        </StyledCard>
        <StyledCard title="People" style={{ width: "50%" }}>
          <Form.List style={{ justifyContent: "center" }} name={["household","people"]}>
            {(fields, { add, remove }) => (
              <div style={{ justifyContent: "center" }}>
                {fields.map((field, i) => (
                  <Card>
                    <Item label="Birth Date" name={[field.name,"birthDate"]} >
                      <DatePicker format={"MM/DD/YYYY"}/>
                    </Item>
                    <Item label="Sex" name={[field.name,"sex"]}>
                      <StyledSelect>
                        <Select.Option value={"Male"}>Male</Select.Option>
                        <Select.Option value={"Female"}>Female</Select.Option>
                      </StyledSelect>
                    </Item>
                    <Item
                      label="Uses Tobacco" 
                      name={[field.name,"uses_tobacco"]}
                    >
                      <StyledSelect>
                        <Select.Option value={false}>No</Select.Option>
                        <Select.Option value={true}>Yes</Select.Option>
                      </StyledSelect>
                    </Item>
                    <Item
                      label="Relationship"
                      name={[field.name,"relationship"]}
                    >
                      <StyledSelect 
                      defaultValue={i === 0 ? "Self" : null}>
                        <Select.Option value={"Self"}>Self</Select.Option>
                        <Select.Option value={"Spouse"}>Spouse</Select.Option>
                        <Select.Option value={"Child"}>Child</Select.Option>
                      </StyledSelect>
                    </Item>
                    <Item
                      {...formItemLayoutWithOutLabel}
                    >
                      <Button
                        danger
                        onClick={() => {
                          if (fields.length <= 1) {
                            message.error(
                              "At Least one person must be inserted"
                            );
                          } else {
                            remove(field.name);
                          }
                        }}
                        block
                      >
                        <MinusOutlined /> Remove Person
                      </Button>
                    </Item>
                  </Card>
                ))}
                <br></br>
                <Item {...formItemLayoutWithOutLabel}>
                  <Button type="dashed" onClick={() => add()} block>
                    <PlusOutlined /> Add
                  </Button>
                </Item>
              </div>
            )}
          </Form.List>
        </StyledCard>
        <StyledCard title="Household Income" style={{ width: "50%" }}>
          <Item 
            label={`${form.getFieldValue('year') ? form.getFieldValue('year') + ' Calendar' : ''} Year Income`}
            name={["household","income"]}
          >
            <InputNumber 
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            />
          </Item>
        </StyledCard>
        <Item
          style={{ marginRight: "75%" }}
          wrapperCol={{ ...layout.wrapperCol, offset: 8 }}
        >
          <Button 
            type="primary"
            disabled={loadingRequest}
            htmlType="submit"
          >
            {
              attachToUser ?
              'Add Error-Free Plans to Database' : 
              'Download Plans'
            }
          </Button>
          {
           loadingRequest &&
           <Spinner/>
          }
          <ResponseDiv>
            {
              responseMessage && 
              <p style={{color:responseMessage.color}}>{responseMessage.text}</p>
            }
            <a target="_blank" download={downloadButton.fileName}  href={downloadButton.url}>{ downloadButton.fileName ? `Download ${downloadButton.fileName} here`: ''}</a>
          </ResponseDiv>
        </Item>
      </StyledForm>
    </StyledCard>
    </>
  );
};

export default ObamacarePage;




