import "./styles.css";

import React, { useState } from "react";
import styled from "styled-components";
import Text from "components/atoms/Text";
import Filter from "components/molecules/Filter";
import FilterV2 from "components/molecules/FilterV2";
import ComparisonMode from "components/molecules/ComparisonMode";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { TAB_ALL_MY_OPTIONS, TAB_IN_NETWORK_CARE, TAB_WHAT_IF, TAB_YEARLY_OPTIONS } from "utils/constants";
import Actions from "store/report/actions";
import { LeftOutlined, CloseOutlined } from "@ant-design/icons";
import { formatCurrency } from "utils/currency";
import SideBarOverlay from "./SideBarOverlay";
import { useFilterContext } from "components/molecules/FilterV2/FilterContext";

const WrapperParent = styled.div`
  width: 33vw;
  max-width: 100vw;
  max-height: 98vh;
  display: flex;
  top: 5px;
  z-index: 101;
  transition: 0.4s ease-in-out;
  ${({ open }) => (open ? `transform: translateX(0%);` : "transform: translateX(-100%);")}
  position: fixed;

  @media (max-width: 768px) {
    width: 100vw;
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: none !important;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 0 2.25vw;
  background-color: #fff; //#d9f3f1;
  // border: 1px solid #21355B;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  overflow-y: auto;
  scrollbar-width: none;
  position: relative;

  @media (max-width: 768px) {
    top: 5px;
    margin: 0;
    width: 100%;
    border-radius: 8px;
    max-width: 100vw;
    padding: 0 5vw;
  }
`;

const CloseBtn = styled.div`
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;

  button {
    background: none;
    border: none;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    cursor: pointer;
  }
`;

const SideBar = ({
  activeTab,
  report,
  // plans,
  sidebarOpen,
  setSideBarOpen,
  optimization,
  changeSideBar,
  updateState,
  // updateAllMyOptions,
  optimizations,
  otherOptimizations,
  broaderNetworksFromAllMyOptions,
  // columnHeadersBP,
  // columnHeadersPartner,
  // throwForceUpdateState,
}) => {
  const { applyLoading, canUpdate, setCanUpdate, filterUpdate } = useFilterContext();

  // const recommendedText = useSelector((state) => state.report.recommendedText);
  // const highlightText = useSelector((state) => state.report.highlightText);
  // const noteText = useSelector((state) => state.report.noteText);
  const costPeriod = useSelector((state) => state.report.costPeriod);
  const howManyDoctors = useSelector((state) => state.report.howManyDoctors);
  const howManyDrugs = useSelector((state) => state.report.howManyDrugs);
  const howManyHospitals = useSelector((state) => state.report.howManyHospitals);

  const join = useSelector((state) => state.report.join);

  let displayValue = {
    monthly: formatCurrency(optimization?.unexpected?.monthlyCost?.after),
    yearly: formatCurrency(optimization?.unexpected?.total),
  };

  if (optimization?.unexpected?.monthlyCost?.after < 0)
    displayValue.monthly = displayValue.monthly.substring(1) + " cash back";
  if (optimization?.unexpected?.total < 0) displayValue.yearly = displayValue.yearly.substring(1) + " cash back";

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  // const [filterUpdate, setFilterUpdate] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    switch (activeTab) {
      case TAB_ALL_MY_OPTIONS: {
        const valueAllMyOptions =
          costPeriod === "monthly" ? [displayValue.monthly, "per month"] : [displayValue.yearly, "per year"];
        dispatch(Actions.Creators.changeHighlightText(valueAllMyOptions));
        dispatch(Actions.Creators.changeNoteText(""));
        setIsSidebarVisible(true);
        break;
      }
      case TAB_YEARLY_OPTIONS: {
        const valueYearlyOptions =
          costPeriod === "monthly" ? [displayValue.monthly, "per month"] : [displayValue.yearly, "per year"];
        dispatch(Actions.Creators.changeHighlightText(valueYearlyOptions));
        dispatch(Actions.Creators.changeNoteText(""));
        setIsSidebarVisible(true);
        break;
      }
      case TAB_IN_NETWORK_CARE: {
        const text =
          howManyDoctors !== "hide"
            ? `${howManyDoctors} Doctors`
            : howManyHospitals !== "hide"
            ? `${howManyHospitals} Hospitals`
            : howManyDrugs !== "hide"
            ? `${howManyDrugs} Drugs`
            : "";

        const inNetworkCareTitleSuffix = join === "separatePlans" ? "for some of you" : "";
        text && dispatch(Actions.Creators.changeHighlightText([text, `are in-network ${inNetworkCareTitleSuffix}*`]));
        dispatch(
          Actions.Creators.changeNoteText("*Includes all of your providers you gave to Predictabill for analysis"),
        );
        setIsSidebarVisible(true);
        break;
      }
      case TAB_WHAT_IF: {
        const valueWhatIf =
          costPeriod === "monthly" ? [displayValue.monthly, "per month"] : [displayValue.yearly, "per year"];
        dispatch(Actions.Creators.changeHighlightText(valueWhatIf));
        dispatch(Actions.Creators.changeNoteText(""));

        setIsSidebarVisible(true);
        break;
      }

      default:
        setIsSidebarVisible(false);
        break;
    }
  }, [activeTab]);

  useEffect(() => {
    const value = costPeriod === "monthly" ? [displayValue.monthly, "per month"] : [displayValue.yearly, "per year"];
    dispatch(Actions.Creators.changeHighlightText(value));
  }, [costPeriod, filterUpdate]);

  const styleSide = sidebarOpen ? { overflowY: "scroll", maxHeight: "100%", overflowX: "visible" } : null;

  if (!report) return null;
  return (
    isSidebarVisible && (
      <>
        <SideBarOverlay
          sidebarOpen={sidebarOpen}
          setSideBarOpen={setSideBarOpen}
          applyLoading={applyLoading}
          canUpdate={canUpdate}
        />
        <WrapperParent open={sidebarOpen}>
          <Wrapper style={null} id="sideBarAllMyOptions">
            <CloseBtn>
              <button onClick={() => setSideBarOpen((current) => !current)}>
                <CloseOutlined />
              </button>
            </CloseBtn>
            <div
              style={{
                padding: 8,
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                width: "100%",
                alignItems: "baseline",
                marginTop: "20px",
              }}
            >
              <Text bold fontSize={26} blue style={{ marginBottom: 10 }}>
                Filter & Sort
              </Text>
              <Text blue fontSize={16}>
                Quickly update your results
              </Text>
            </div>
            {/* <div
            style={{
              backgroundColor: "#1c355e",
              borderRadius: 10,
              marginTop: 30,
              padding: 8,
              alignContent: "center",
            }}
          >
            <Text bold white fontSize={16}>
              {"Recommended"}
            </Text>
          </div>
          <Text style={{ margin: "15px 0" }} bold blue center fontSize={16}>
            {recommendedText[0]}
          </Text>
          {recommendedText[1] && (
            <Text style={{ margin: 10 }} bold blue center fontSize={16}>
              {recommendedText[1]}
            </Text>
          )}
          <div
            style={{
              backgroundColor: "#00a19b",
              width: "100%",
              borderRadius: 20,
              marginBottom: 10,
              padding: 20,
            }}
          >
            <Text white center bold fontSize={26} style={{ marginBottom: 10 }}>
              {highlightText[0]}
            </Text>
            <Text white center fontSize={16}>
              {highlightText[1]}
            </Text>
          </div>
          <Text italic center blue style={{ marginBottom: 20, fontSize: 14 }}>
            {noteText}
          </Text> */}

            {activeTab === TAB_ALL_MY_OPTIONS && (
              <>
                <ComparisonMode report={report} changeSideBar={changeSideBar} />
              </>
            )}

            {report?.version === "v1" ? (
              <Filter
                providersList={report.providersList}
                report={report}
                optimizations={optimizations}
                otherOptimizations={otherOptimizations}
                updateState={updateState}
                broaderNetworksFromAllMyOptions={broaderNetworksFromAllMyOptions}
                closeMenu={() => setSideBarOpen(false)}
                setCanUpdate={setCanUpdate}
              />
            ) : (
              <FilterV2
                // providersList={report.providersList}
                // plans={plans}
                report={report}
                // optimizations={optimizations}
                // otherOptimizations={otherOptimizations}
                // updateState={updateState}
                // updateAllMyOptions={updateAllMyOptions}
                // throwForceUpdateState={throwForceUpdateState}
                // broaderNetworksFromAllMyOptions={broaderNetworksFromAllMyOptions}
                // categories={[...columnHeadersBP, ...columnHeadersPartner]}
              />
            )}
          </Wrapper>
          <div className="buttonWrapper">
            <LeftOutlined
              className={`button`}
              style={{
                transform: sidebarOpen ? "rotateY(0)" : "rotateY(180deg)",
              }}
              onClick={() => setSideBarOpen((current) => !current)}
            />
          </div>
        </WrapperParent>
      </>
    )
  );
};

export default SideBar;
