import { put, delay, select } from "redux-saga/effects";
import Actions from "./actions";

export function* defaultOptions() {
  yield put(Actions.Creators.changeBirthPlan("planNames", "Hospital"));
  yield put(Actions.Creators.changeBirthPlan("hospitals", "all"));
  yield put(Actions.Creators.changeBirthPlanOptions("roomTypes", true));
  yield put(Actions.Creators.changeInsurancePlan("groups", "None"));
  yield delay(650);
  yield put(
    Actions.Creators.changeInsurancePlan(
      "insurancePlans",
      "Catastrophic Marketplace"
    )
  );
  yield delay(550);
  const plan = yield select(state => state.plan);
  const id = plan.plansNetwork.data.find(plan => plan.network === "EmblemHealth")._id;
  yield put(Actions.Creators.changeInsurancePlan("plansNetwork", id));
  yield put(Actions.Creators.changeInsurancePlan("firstBaby", true));
  yield put(
    Actions.Creators.changeInsurancePlan("insurancePlanType", "family")
  );
  yield put(
    Actions.Creators.changeInsurancePlan("isDoctorInNetwork", { value: true })
  );
}
