import React from "react";
import { useFormikContext } from "formik";
import FormField from "components/molecules/FormField";
import SelectField from "components/molecules/SelectField";
import CheckboxField from "components/molecules/CheckboxField";
import { SpaceDivider } from "./utils";

export const CheckboxWithMultiplePremiumTiers = () => {
  const { values } = useFormikContext();
  const {
    withMultiplePremiumTiers,
    premiumTiersNumber,
    selectedPremiumTier,
    table,
  } = values;

  const getPremiumTiersOptions = (num) => {
    const result = [];
    for (let i = 1; i <= num; i++) {
      const optionName = table[0].tiers?.[i]?.range ?? `Premium Tier ${i}`;
      result.push({ name: optionName, value: i });
    }
    return result;
  };

  const premiumRange = `table[0].tiers[${selectedPremiumTier}].range`;

  return (
    <>
      <CheckboxField
        name="withMultiplePremiumTiers"
        label="These plans have multiple premium tiers"
      />

      {withMultiplePremiumTiers && (
        <>
          <FormField
            name="premiumTiersNumber"
            type="number"
            label={`How many premium tiers do these plans have?`}
            min={1}
          />

          <SelectField
            name="selectedPremiumTier"
            options={
              premiumTiersNumber
                ? getPremiumTiersOptions(premiumTiersNumber)
                : []
            }
            label={`Premium Tier`}
          />

          <FormField
            name={premiumRange}
            type="text"
            label={`Label Premium Tier`}
          />
          <SpaceDivider />
        </>
      )}
    </>
  );
};
