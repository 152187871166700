import configure from "./configure";
import stringify from "./query";

const { axios } = configure;

export const getNetworksGrid = async ({ id }) => {
  return axios.get(`/networks-grids/${id}`).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const getNetworksGridsList = async ({ idList }) => {
  const query = stringify({ idList });
  return axios.get(`/networks-grids/${query}`).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const createNetworksGrid = async ({ form }) => {
  const body = { form };
  return axios.post(`/networks-grids`, body).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const updateNetworksGrid = async ({ id, form }) => {
  const body = { id, form };
  return axios.patch(`/networks-grids`, body).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const deleteNetworksGrid = async ({ id }) => {
  return axios.delete(`/networks-grids/${id}`).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};
