// V3
// switch to include plans instead of exclude

import React, { useState } from "react";
import styled from "styled-components";

import { Text } from "components";

import { Button, Modal, Divider, Radio, Table, Badge } from "antd";

import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/report/actions";

const CustomModal = styled(Modal)`
  @media (min-width: 768px) {
    width: 45vw;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 0;
  h1 {
    font-size: 24px;
    color: #21345b;
    font-weight: bolder;
  }
  p {
    font-size: 18px;
    color: #222;
    font-weight: lighter;
  }
`;

const AllPlansModal = ({ modal, show, close, withoutDecimalCases, isInNetworkLimits }) => {
  const { network, planName, plans, company, isSpouseTable, comparisonMode } = modal;
  const [selected, setSelected] = useState("");
  const plansMode = useSelector((state) => state.report.plansMode);
  const costPeriod = useSelector((state) => state.report.costPeriod);
  const recommendedId = useSelector((state) => state.report.recommendedId);
  const dispatch = useDispatch();
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRowKeys);
    },
    getCheckboxProps: (item) => ({
      disabled: recommendedId.includes(item.key),

      // Column configuration not to be checked
      name: item.name,
    }),
  };

  const valueFormat = {
    style: "currency",
    currency: "USD",
  };

  if (withoutDecimalCases) {
    valueFormat.minimumFractionDigits = 0;
  }

  const columns = [
    {
      title: "Plan Name",
      dataIndex: "name",
      render: (_, item) => (
        <div className="modalBadge">
          {item.name}
          {recommendedId.includes(item.key) && (
            <Badge count={"REC"} style={{ marginLeft: 5, width: 50, backgroundColor: "#21345b" }} />
          )}
        </div>
      ),
    },
    {
      title: modal.tableType === "inNetworkLimits" ? "Value" : costPeriod === "monthly" ? "Premium" : "Spend",
      dataIndex: "value",
      sorter: (a, b) => a.value - b.value,
      sortDirections: ["ascend", "descend"],
      render: (text) => <>{text?.toLocaleString("en-US", valueFormat)}</>,
    },
  ];

  const moreThanOnePlan = plans?.length > 1 ? true : false;
  return (
    <CustomModal
      visible={show}
      onOk={() => {}}
      onCancel={close}
      footer={
        comparisonMode && [
          <Button
            disabled={!selected}
            key="submit"
            type="primary"
            onClick={() => {
              const value = JSON.stringify({
                externalId: selected[0],
                network: network,
                planName: planName,
                isArray: true,
              });

              const obj = isSpouseTable
                ? plansMode === "samePlan"
                  ? { comparedPlanYou: value }
                  : { comparedPlanSpouse: value }
                : { comparedPlanYou: value };
              dispatch(Actions.Creators.switchState(obj));
              close();
            }}
          >
            Confirm
          </Button>,
        ]
      }
    >
      <Wrapper>
        <h1>
          {isInNetworkLimits ? "In-Network" : ""} {network} {isInNetworkLimits ? "" : planName}
        </h1>

        {isInNetworkLimits ? (
          <p>
            Below {moreThanOnePlan ? "are" : "is"} the in-network {network?.toLowerCase()}
            {moreThanOnePlan ? "s " : " "}
            for the {moreThanOnePlan ? plans?.length + " " : ""} {company} {planName} plan{moreThanOnePlan ? "s" : ""}
          </p>
        ) : (
          <p>
            {company} offers {plans?.length} {planName} with {network} as the network
          </p>
        )}
        <Table
          rowSelection={
            comparisonMode && {
              type: "radio",
              ...rowSelection,
            }
          }
          locale={{
            cancelSort: "Reset to default sorting",
          }}
          selectedRowKeys
          rowClassName={(item, index) => {
            return `${recommendedId.includes(item.key) && "table-row-recommended"} ${
              index % 2 === 0 ? `table-row-light` : `table-row-dark`
            }`;
          }}
          pagination={{ defaultPageSize: 5 }}
          columns={columns}
          dataSource={plans}
        />
      </Wrapper>
    </CustomModal>
  );
};

export default AllPlansModal;
