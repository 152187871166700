import { checkCustomPlanFormData } from "./checkCustomPlanFormData";

import { getPremiumFrequencyToUnit } from "services/utils/getFrequencyFormat";

export const checkCustomOptionsFormData = ({
  isDue,
  dueDate,
  firstBaby,
  useOutOfNetwork,
  birthParent,
  partner
}) => {
  const birthParentOptionsFormData = birthParent.options.map(option => {
    return checkCustomPlanFormData({
      ...option,
      group: birthParent.group,
      startDate: birthParent.startDate
    });
  });

  const partnerOptionsFormData = partner.options.map(option => {
    return checkCustomPlanFormData({
      ...option,
      group: partner.group,
      startDate: partner.startDate
    });
  });

  const formDataSend = {
    isDue,
    dueDate,
    isFirst: firstBaby,
    useOutOfNetwork,
    birthParent: {
      optionsAmount: birthParent.optionsAmount,
      group: birthParent.group,
      startDate: birthParent.startDate,
      optOut: {
        isApply: birthParent.optOut.isApply,
        amount: birthParent.optOut.amount,
        frequency: getPremiumFrequencyToUnit(birthParent.optOut.frequency.value)
      },
      premiumTier: 1,
      hsaTier: 1,
      plans: birthParentOptionsFormData
    },
    partner: {
      optionsAmount: partner.optionsAmount,
      group: partner.group,
      startDate: partner.startDate,
      optOut: {
        isApply: partner.optOut.isApply,
        amount: partner.optOut.amount,
        frequency: getPremiumFrequencyToUnit(partner.optOut.frequency.value)
      },
      premiumTier: 1,
      hsaTier: 1,
      plans: partnerOptionsFormData
    }
  };

  return formDataSend;
};
