import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Text,
  PageTemplate,
  AuthenticationFormSection,
  AuthenticationFormBlock,
  newHeader as Header,
} from "components";

import Actions from "store/auth/actions";

import { PageView } from "services/google";
import { useDispatch, useSelector } from "react-redux";
import { isValidToken } from "services/utils/tokens";

const ResetPasswordPage = () => {
  const [formState, setFormState] = useState({ password: "", password2: "" });

  const page = useSelector((state) => state.router.location.pathname.split("/")[1]);
  const resetToken = useSelector((state) => state.router.location.pathname.split("/")[2]);
  const isNew = useSelector((state) => state.router.location.pathname.split("/")[1] == "create-password");
  const error = useSelector((state) => state.auth.error);
  const dispatch = useDispatch();

  const validToken = useMemo(() => {
    try {
      return isValidToken(resetToken);
    } catch (e) {
      return false;
    }
  }, [resetToken]);

  const validation = useMemo(() => {
    return {
      isLong: formState.password.length >= 8,
      isUpperLower: /([A-Z])/.test(formState.password) && /([a-z])/.test(formState.password),
      isNumber: /([0-9])/.test(formState.password),
      isSpecial: /[#?!@$%^&*-]/.test(formState.password),
      isSame: formState.password === formState.password2,
    };
  }, [formState]);

  useEffect(() => {
    PageView();
  }, []);

  useEffect(() => {
    if (page === "reset-password") {
      dispatch(Actions.Creators.getUserByToken(resetToken));
    }
  }, [resetToken]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (resetToken && !Object.values(validation).includes(false)) {
        dispatch(Actions.Creators.resetPasswordRequest(formState.password, resetToken));
      }
    },
    [formState],
  );

  const validPasswordText = useMemo(
    () => (
      <>
        <Text>Password must contain:</Text>
        <Text red={!validation.isLong}> - at least 8 characters</Text>
        <Text red={!validation.isUpperLower}> - both uppercase and lowercase letters</Text>
        <Text red={!validation.isNumber}> - at least 1 number</Text>
        <Text red={!validation.isSpecial}> - at least 1 special character</Text>
      </>
    ),
    [validation],
  );

  const validPassword2Text = useMemo(
    () => (
      <>
        <Text red={!validation.isSame}>Passwords must match</Text>
      </>
    ),
    [validation.isSame],
  );

  const formInputs = useMemo(
    () => [
      {
        type: "password",
        name: "password",
        title: "Password",
        placeholder: "Password",
        tooltip: validPasswordText,
      },
      {
        type: "password",
        name: "password2",
        placeholder: "Confirm Password",
        title: "Confirm Password",
        tooltip: validPassword2Text,
      },
    ],
    [validPasswordText, validPassword2Text],
  );

  return (
    <PageTemplate newHeader header={<Header />}>
      <AuthenticationFormSection
        title={isNew ? "Create Password" : "Change Password."}
        showLink={!validToken}
        linkDestiny="/forgot-password"
        linkText="This link has expired. To reset your password please click"
        linkContent="here"
      >
        {validToken && (
          <AuthenticationFormBlock
            submitButtonText="Confirm"
            submitEnable={!Object.values(validation).includes(false)}
            inputs={formInputs}
            error={error}
            handleFormChange={handleFormChange}
            handleSubmit={handleSubmit}
          />
        )}
      </AuthenticationFormSection>
    </PageTemplate>
  );
};

export default ResetPasswordPage;
