import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import Text from "components/atoms/Text";
import TableField from "components/molecules/TableField";
import TableMenu from "components/molecules/TableMenu";
import { yesOrNo } from "../utils/options.js";

const columns = (networksTable) => {
  const showRow2 = networksTable.some((network) => network.row2);
  const middleColumns = networksTable.map((network) => {
    return {
      title: () => (
        <div
          style={{
            fontSize: "12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
          }}
        >
          <p>{`${network.name}`}</p>
          {showRow2 && <p>{`${network.row2 || ""}`}</p>}
          {network.url && (
            <a href={network.url} target="_blank" rel="noreferrer">
              View Directory
              <svg
                style={{ marginLeft: "3px" }}
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="10"
                height="10"
                viewBox="0,0,256,256"
              >
                <g transform="">
                  <g fill="#339af0">
                    <g transform="scale(5.12,5.12)">
                      <path d="M33.40625,0c-0.55078,0.05078 -0.95703,0.54297 -0.90625,1.09375c0.05078,0.55078 0.54297,0.95703 1.09375,0.90625h12.96875l-20.875,20.90625c-0.29687,0.24219 -0.43359,0.62891 -0.34766,1.00391c0.08594,0.37109 0.37891,0.66406 0.75,0.75c0.375,0.08594 0.76172,-0.05078 1.00391,-0.34766l20.90625,-20.875v12.96875c-0.00391,0.35938 0.18359,0.69531 0.49609,0.87891c0.3125,0.17969 0.69531,0.17969 1.00781,0c0.3125,-0.18359 0.5,-0.51953 0.49609,-0.87891v-16.40625h-16.40625c-0.03125,0 -0.0625,0 -0.09375,0c-0.03125,0 -0.0625,0 -0.09375,0zM2,10c-0.52344,0 -1.05859,0.18359 -1.4375,0.5625c-0.37891,0.37891 -0.5625,0.91406 -0.5625,1.4375v36c0,0.52344 0.18359,1.05859 0.5625,1.4375c0.37891,0.37891 0.91406,0.5625 1.4375,0.5625h36c0.52344,0 1.05859,-0.18359 1.4375,-0.5625c0.37891,-0.37891 0.5625,-0.91406 0.5625,-1.4375v-30c0.00391,-0.35937 -0.18359,-0.69531 -0.49609,-0.87891c-0.3125,-0.17969 -0.69531,-0.17969 -1.00781,0c-0.3125,0.18359 -0.5,0.51953 -0.49609,0.87891v30h-36v-36h30c0.35938,0.00391 0.69531,-0.18359 0.87891,-0.49609c0.17969,-0.3125 0.17969,-0.69531 0,-1.00781c-0.18359,-0.3125 -0.51953,-0.5 -0.87891,-0.49609z"></path>
                    </g>
                  </g>
                </g>
              </svg>
            </a>
          )}
        </div>
      ),
      dataIndex: `networks[${networksTable.findIndex(
        (el) => el.name === network.name && el.row2 === network.row2,
      )}].accept`,
      width: 50,
      editable: true,
      inputType: "select",
      options: yesOrNo,
      shouldCellUpdate: () => false,
    };
  });

  return [
    {
      title: "",
      dataIndex: "idx",
      width: 20,
      editable: false,
      fixed: "left",
    },
    {
      title: "Provider",
      dataIndex: "name",
      width: 20,
      editable: false,
      fixed: "left",
    },
    ...middleColumns,
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 1,
    },
  ].map((col) => {
    return {
      ...col,
      onCell: (_, idx) => ({
        index: idx,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: col.inputType,
        options: col.options,
        dropdownMatchSelectWidth: col.dropdownMatchSelectWidth,
        min: col.min,
        disabled: col.disabled || false,
        inline: col.inline || false,
        maxLength: col.maxLength || null,
      }),
    };
  });
};

const ProvidersGrid = () => {
  const { values, setFieldValue, setErrors, setTouched } = useFormikContext();
  const { networksTable, providersList } = values;

  useEffect(() => {
    document.getElementById("form-content").scrollTo({ top: 0, behavior: "smooth" });
    setErrors({});
    setTouched({}, false);

    const newProvidersList = providersList.map((provider, i) => {
      provider.networks = provider.networks || [];
      provider.tableName = "providersList";
      networksTable.forEach((network, j) => {
        if (!network.name) {
          providersList.networks.splice(j, 1);
        } else if (!provider.networks[j]) {
          provider.networks.push({
            name: network.name,
            row2: network.row2,
            accept: providersList[i]?.networks[j]?.accept || false,
          });
        } else if (
          provider.networks[j].name !== network.name ||
          (network.row2 && provider.networks[j].row2 !== network.row2)
        ) {
          provider.networks.splice(j, 1, {
            name: network.name,
            row2: network.row2,
            accept: false,
          });
        }
      });
      provider.networks.length = networksTable.length;
      return provider;
    });

    setFieldValue("providersList", newProvidersList);
  }, []);

  return (
    <>
      <Text sectionTitle big>
        Check Network Status
      </Text>
      <Text fontSize={16} lineHeightUnits={"1.7em"}>
        Go through each directory and change the dropdowns to &quot;Yes&quot; if a provider is in-network.
      </Text>
      <>
        <TableField
          name="providersList"
          columns={columns(networksTable)}
          dataSource={providersList}
          pageSize={10}
          tableLayout="fixed"
        />
      </>
    </>
  );
};

export default ProvidersGrid;
