import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  loadBillsRequest: ["data"],
  loadBillsSuccess: ["records", "summary"],
  loadBillsFailure: [],
  loadBillsHeaderRequest: [],
  loadBillsHeaderSuccess: ["payload"],
  loadBillsHeaderFailure: ["isAll"],
  changeAllSelect: [],
  checkedBill: ["idBill"],
  loadBillByIdRequest: ["id"],
  loadBillByIdSuccess: ["bill"],
  loadBillByIdFailure: [],
  resetStoreBill: ["name", "value"]
});

const Actions = {
  Types,
  Creators
};

export default Actions;
