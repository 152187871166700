import React from "react";
import styled, { css, keyframes } from "styled-components";
import { fadeIn } from "react-animations";

const animation = keyframes`${fadeIn}`;

const StyledButton = styled.button`
  cursor: pointer;
  margin: 0;
  height: 30px;
  border: none;
  background: none;
  vertical-align: bottom;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;

  color: ${({ close, cancel }) => (close || cancel ? "#123" : "white")};

  ${({ green, fontSize }) =>
    green &&
    css`
      background-color: #00a19b;
      padding: 10px 20px;
      height: 50px;
      width: 175px;
      border-radius: 50px;
      color: white;
      margin: 15px;
      text-align: center;
      font-weight: bold;
      font-size: ${fontSize}px;
      white-space: nowrap;
      overflow: hidden;
      :hover {
        color: white;
        background: #7ae1d7;
      }
    `}
  ${({ show }) => show && `visibility: visible;`}
  ${({ hidden }) => hidden && `visibility: hidden;`}
  ${({ disabled }) =>
    disabled &&
    `
    color: #666666;
    opacity: 0.65;
    cursor: not-allowed;
    `}
    ${({ fullwidth }) =>
    fullwidth &&
    css`
    width: 100%;
    font-size: 16px
    background-color: ${({ disabled }) =>
        disabled ? "#fff" : `rgb(0,161,150)`};
    `}
    
    ${({ full }) =>
    full &&
    css`
      ${({ cancel }) => cancel && "border: 1px solid #d9d9d9; "}
      background-color: ${({ cancel }) => (cancel ? "#fff" : `rgb(0,161,150)`)};
      margin-left: 20px;
    `}
    :focus {
    outline: none;
  }
  &[type="submit"] {
    background-color: #00a39e;
    border-radius: 1px;
    font-size: 10px;
    height: 18px;
    display: block;
    margin: 0;
    animation: 1s ${animation};
  }

  ${({ circular }) =>
    circular &&
    `      font-family: circular;`}

  ${({ modal }) =>
    modal &&
    css`
      height: 35px;
      width: 100px;
      font-size: 14px;
      font-weight: bold;
      line-height: 1em;
    `}
    ${({ color }) => color && `background-color: ${color}`}
    ${({ border }) =>
    border &&
    `background-color: #FFF; border: solid #186A67 2px; color: #186A67`}
`;

const CloseIcon = styled.span`
  display: block;
  font-style: normal;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  font-size: 14px;

  ::before {
    content: "X";
    display: block;
  }
`;

const Button = ({ close, children, ...props }) => {
  if (close)
    return (
      <StyledButton close {...props}>
        <CloseIcon />
      </StyledButton>
    );
  return <StyledButton {...props}>{children}</StyledButton>;
};

export default Button;
