import camelCase from "lodash/camelCase";
import { merge } from "lodash";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import history from "./history";

const reducers = {};

const req = require.context(".", true, /\.\/.+\/reducer\.js$/);

req.keys().forEach((key) => {
  const storeName = camelCase(key.replace(/\.\/(.+)\/.+$/, "$1"));
  reducers[storeName] = req(key).default;
});

export default combineReducers(
  merge({}, reducers, { router: connectRouter(history) })
);
