import ReactGA from "react-ga";
import { Fields } from "enums/google.enum";
import { insuranceType } from "enums";
import { Field } from "formik";

const trackingID = process.env.REACT_APP_GOOGLE_ANALYTIC_TRACKINGID;
const initGA = () => {
  ReactGA.initialize(trackingID);
};

export const PageView = async () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export function initializeReactGA() {
  initGA();
}

export function handleGA(isBeta) {
  window[`ga-disable-${trackingID}`] = isBeta ? true : false;
}

const getValue = (name, value, data) => {
  const Types = {
    roomTypes: {
      action: Fields.roomType,
      label: value => (value ? "Yes" : "No")
    },
    planNames: {
      action: Fields.planName,
      label: value => value
    },
    doctors: {
      action: Fields.doctor,
      label: (value, data) => data.find(({ _id }) => _id === value).name
    },
    hospitals: {
      action: Fields.hospital,
      label: (value, data) => data.find(({ _id }) => _id === value).name
    },
    groups: {
      action: Fields.group,
      label: value => value
    },
    insurancePlans: {
      action: Fields.name,
      label: value => value
    },
    plansNetwork: {
      action: Fields.networkPlan,
      label: (value, data) => data.find(({ _id }) => _id === value).network
    },
    insurancePlanType: {
      action: Fields.insurancePlanType,
      label: value =>
        value === insuranceType.Family
          ? "Joining plan I'm on with my spouse"
          : "Joining my individual plan"
    },
    isDoctorInNetwork: {
      action: Fields.isDoctorInNetwork,
      label: value =>
        value.value ? "Yes" : value.value === false ? "No" : null
    },
    firstBaby: {
      action: Fields.firstBaby,
      label: value => (value ? "Yes" : value === false ? "No" : null)
    },
    deliveryTypes: {
      action: Fields.deliveryType,
      label: value => value
    },
    deluxe: {
      action: Fields.deluxe,
      label: value => (value ? "Yes" : value === false ? "No" : null)
    },
    broaderNetworksMySelf: {
      action: Fields.broaderNetworksMySelf,
      label: value => value
    }
  };

  return {
    action: Types[name].action,
    label: Types[name].label(value, data)
  };
};

// records event interactions on google analytics
export const EventOne = async (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
    transport: "beacon"
  });
};

export const EventSelect = (name, value, data = []) => {
  const { action, label } = getValue(name, value, data);
  if (label) EventOne("Form", action, label);
};

const getPremiumValue = (name, value, data) => {
  const Types = {
    isDue: {
      action: Fields.isDue,
      label: value => value
    },
    dueDate: {
      action: Fields.dueDate,
      label: value => value
    },
    birthParent: {
      action: Fields.birthParentName,
      label: value => value
    },
    partner: {
      action: Fields.nonBirthParentName,
      label: value => value
    },
    firstBaby: {
      action: Fields.firstBaby,
      label: value => (value ? "Yes" : value === false ? "No" : null)
    },

    parentsPlan: {
      action: Field.parentsPlan,
      label: value => value
    },
    familyPlan: {
      action: Field.familyPlan,
      label: value => value
    },

    groups: {
      action: Fields.group,
      label: value => value
    },
    insurancePlans: {
      action: Fields.name,
      label: value => value
    },
    plansNetwork: {
      action: Fields.networkPlan,
      label: (value, data) => data.find(({ _id }) => _id === value).network
    },
    tiers: {
      action: Fields.tiers,
      label: value => value
    },
    hsa: {
      action: Fields.hsa,
      label: value => value
    },
    surcharges: {
      action: Fields.surcharges,
      label: value => (value.length > 0 ? value.join(" , ") : "")
    },

    partnerGroups: {
      action: Fields.partnerGroup,
      label: value => value
    },
    partnerInsurancePlans: {
      action: Fields.partnerName,
      label: value => value
    },
    partnerPlansNetwork: {
      action: Fields.partnerNetworkPlan,
      label: (value, data) => data.find(({ _id }) => _id === value).network
    },
    partnerTiers: {
      action: Fields.partnerTiers,
      label: value => value
    },
    partnerHsa: {
      action: Fields.partnerHsa,
      label: value => value
    },
    partnerSurcharges: {
      action: Fields.partnerSurcharges,
      label: value => (value.length > 0 ? value.join(" , ") : "")
    }
  };

  return {
    action: Types[name].action,
    label: Types[name].label(value, data)
  };
};

export const EventPremiumSelect = (name, value, data = []) => {
  const { action, label } = getPremiumValue(name, value, data);
  if (label) EventOne("Form", action, label);
};
