import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CalculateHeader as Header, Text } from "components";
import SegmentedControl from "components/molecules/SegmentedControl";
import TableTitle from "components/molecules/Table/Title";
import TableHeader from "components/molecules/Table/Header";
import TableBody from "components/molecules/Table/Body";
import useCollapse from "react-collapsed";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/report/actions";
import FilterSortButton from "components/molecules/FilterSortButton";
import { useAllMyOptionsPlansStatistics } from "./hooks";
import { PlansModeAddChildren, PlansModeControl } from "components/molecules/PlansMode";
import { getAllRecommendedPlansInformation } from "services/utils/getRecommendations";

export const SPACE_BEFORE_TABLES = 150;
export const SPACE_CELL_TABLES = 150;
export const SPACE_BETWEEN_TABLES = 30;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: left 0.4s ease-in-out;
  position: relative;
  left: 0;

  @media (min-width: 768px) {
    margin: 10px auto;
    width: 90%;
  }

  .display-options {
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    margin-bottom: 16px;
  }
`;

const TablesWrapper = styled.div`
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  width: max-content;
  margin: auto;
  padding: 5px 0;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const StyledCell = styled.div`
  ${(props) => {
    if (props.customWidth) {
      return `
        min-width: ${props.customWidth};
        width: ${props.customWidth};
      `;
    }
    return `
      min-width: ${SPACE_CELL_TABLES}px;
      width: ${SPACE_CELL_TABLES}px;
    `;
  }};
  font-size: 18px;
  font-family: circular;
  p {
    font-family: circular;
  }
  font-weight: bold;
  text-align: ${(props) => (props.align ? props.align : "center")};
  padding: 0px;
  ${({ recommended }) => recommended && `padding: 8px;`}
  height: ${(props) => (props.tall ? "80px" : props.short ? "30px" : "50px")};
  display: ${(props) => (props.hide ? "none" : "flex !important")};
  align-items: center !important;
  justify-content: center !important;
  transition: 400ms;
  ${({ background, opacity }) =>
    background === "green"
      ? `background: rgba(147,193,159, ${opacity ? opacity : 1})`
      : background === "drakgreen"
      ? "background: #93c19f"
      : background === "blue"
      ? "background: #21345b"
      : background === "lightblue"
      ? "background: #a2d5d3"
      : background === "red"
      ? `background: rgba(246,180,183, ${opacity ? opacity : 1})`
      : background === "grey"
      ? "background: rgba(249, 249, 249)"
      : background === "company"
      ? "background: rgba(2, 151, 144)"
      : background === "catastrophic"
      ? "background: rgba(177, 30, 26)"
      : background === "bronze"
      ? "background: #e4c3ae"
      : background === "silver"
      ? "background: #d8d7d7"
      : background === "gold"
      ? "background: #fce5bd"
      : background === "platinum"
      ? "background: #f1f1f1"
      : "background: #fff"};

  ${({ hFit }) => hFit && `height: max-content;`}
  ${({ customHeight }) => customHeight && `height: ${customHeight};`}
  ${({ relative }) => relative && `position: relative;`}
  ${({ customBg }) => customBg && `background: ${customBg};`}

  ${({ bordered }) => bordered && `border: 1px solid white`}

  ${({ isClickable }) => isClickable && `cursor: pointer`}
`;

const DisplayOptionsContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  @media (max-width: 768px) {
    & > * {
      .controls-container {
        width: 100%;
      }
    }
  }

  .first-row {
    display: flex;
    flex-direction: column;
    gap: 8px;
    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
`;

const HideMobile = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const AllMyOptions = ({
  report,
  birthParentPlans,
  partnerPlans,
  columnHeadersBP,
  columnHeadersPartner,
  broaderNetworks,
  inNetworkLimits,
  activeTab,
  allOptimizations,
  toggleSideBar,
  toggleAssumptions,
  loadingRecommendedByReport,
  allPlanOptions,
  groupObj,
}) => {
  const [isExpanded, setExpanded] = useState(true);
  const [isExpanded2, setExpanded2] = useState(true);
  const [inNetworkLimitsArray, setInNetworkLimitsArray] = useState([]);

  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const { getCollapseProps: getCollapseProps2, getToggleProps: getToggleProps2 } = useCollapse({
    isExpanded: isExpanded2,
  });

  const plansMode = useSelector((state) => state.report.plansMode);
  const childrenMode = useSelector((state) => state.report.children);
  const costPeriod = useSelector((state) => state.report.costPeriod);
  const hasKid = useSelector((state) => state.report.hasKid);

  const dispatch = useDispatch();

  const isJobOffer = report.partner?.justForComparison === "jobOffer" || false;

  const {
    // _id,
    birthParent,
    partner,
    type,
    isDue,
    event: {
      general: { children, isSpouse },
      // marriage: { considerSpousePlan },
    },
  } = report;

  const groupType =
    isSpouse && type == "household"
      ? isDue || children
        ? "mf"
        : "ms"
      : isDue || (children && !type == "individual")
      ? "mc"
      : "mo";

  const deductibleType = groupType === "mo" ? "individual" : "family";

  const premiumTierBP = birthParent.premiumTier;
  const premiumTierPartner = partner.premiumTier;

  const indexToSplit = columnHeadersBP.length;

  const plansCategoriesCount = columnHeadersBP.length;

  const { allPlansStats, birthParentPlansStats, partnerPlansStats, totalPlansCount } = useAllMyOptionsPlansStatistics({
    birthParentPlans,
    partnerPlans,
    broaderNetworks,
    plansCategoriesCount,
    plansMode,
    costPeriod,
    childrenMode,
    hasKid,
    report,
  });

  useEffect(() => {
    const isInNetworkLimits = !!inNetworkLimits;
    isInNetworkLimits &&
      setInNetworkLimitsArray([
        {
          broaderNetwork: "Medical Deductible",
          plansNames: inNetworkLimits["medicalDeductible"],
        },
        {
          broaderNetwork: "Out-Of-Pocket Maximum",
          plansNames: inNetworkLimits["maxOutOfPocket"],
        },
      ]);
  }, [inNetworkLimits]);

  const recommendedOptimization = allOptimizations?.[0]?.selected;
  const bestOptimization = getAllRecommendedPlansInformation(allOptimizations?.[0]);
  const currentPreference = report?.preferences?.["1"];

  return (
    <Wrapper>
      <HideMobile>
        <Header title="What are all my options?" bigTitle />
        <Text big center>
          We narrowed it down to <strong>1 out of {totalPlansCount}</strong> total coverage combinations for you
        </Text>
      </HideMobile>
      <div className="display-options">
        <DisplayOptionsContainer>
          <div className="first-row">
            <SegmentedControl
              name="group-per"
              callback={(val) => {
                dispatch(Actions.Creators.switchState({ costPeriod: val }));
              }}
              controlRef={useRef()}
              segments={[
                {
                  label: "Monthly Premiums",
                  value: "monthly",
                  id: "monthly-premiums-btn",
                  ref: useRef(),
                },
                {
                  label: "Yearly Spend",
                  value: "yearly",
                  id: "yearly-spend-btn",
                  ref: useRef(),
                },
              ]}
              initialValue={costPeriod}
            />

            <PlansModeControl
              reportType={report.type}
              isJobOffer={isJobOffer}
              optimization={recommendedOptimization}
              report={report}
              preference={currentPreference}
              loading={loadingRecommendedByReport}
            />
          </div>
          <PlansModeAddChildren
            reportType={report.type}
            isJobOffer={isJobOffer}
            optimization={recommendedOptimization}
            report={report}
            preference={currentPreference}
            loading={loadingRecommendedByReport}
          />
        </DisplayOptionsContainer>
      </div>

      <FilterSortButton toggleSideBar={toggleSideBar} toggleAssumptions={toggleAssumptions} />

      <TablesWrapper>
        <TableTitle
          columnHeadersBP={columnHeadersBP}
          columnHeadersPartner={columnHeadersPartner}
          birthParent={birthParent}
          partner={partner}
          report={report}
          activeTab={activeTab}
          preference={currentPreference}
          groupObj={groupObj}
        />

        <TableHeader
          columnHeadersBP={columnHeadersBP}
          columnHeadersPartner={columnHeadersPartner}
          setExpanded={setExpanded}
          isExpanded={isExpanded}
          getToggleProps={getToggleProps}
          label={costPeriod === "monthly" ? "Monthly Premiums" : "Spend"}
          isJobOffer={isJobOffer}
          showHeaderAndRecommended={true}
          hideHeaderBar={true}
          preference={currentPreference}
        />

        <TableBody
          broaderNetworks={broaderNetworks}
          columnHeadersBP={columnHeadersBP}
          columnHeadersPartner={columnHeadersPartner}
          premiumTierBP={premiumTierBP}
          premiumTierPartner={premiumTierPartner}
          groupType={groupType}
          birthParentPlansStats={birthParentPlansStats}
          partnerPlansStats={partnerPlansStats}
          allPlansStats={allPlansStats}
          getCollapseProps={getCollapseProps}
          birthParentCompany={birthParent.company}
          partnerCompany={partner.company}
          indexToSplit={indexToSplit}
          reportVersion={report.version}
          isJobOffer={isJobOffer}
          preference={currentPreference}
          bestOptimization={bestOptimization}
          allPlanOptions={allPlanOptions}
          tableType="plans"
        />
        {/* <BoxWrapper>
          <Text italic green fontSize="14" padding="2px">
            Green
          </Text>
          <Text italic lightblack fontSize="14">
            indicates lower monthly premiums;
          </Text>
          <Text italic red fontSize="14" padding="2px">
            red
          </Text>
          <Text italic lightblack fontSize="14">
            indicates higher monthly premiums.
          </Text>
        </BoxWrapper> */}
        <TableHeader
          columnHeadersBP={columnHeadersBP}
          columnHeadersPartner={columnHeadersPartner}
          deductibleType={deductibleType}
          setExpanded={setExpanded2}
          isExpanded={isExpanded2}
          getToggleProps={getToggleProps2}
          label="In-Network Limits"
          showHeaderAndRecommended={false}
          preference={currentPreference}
        />
        <TableBody
          columnHeadersBP={columnHeadersBP}
          columnHeadersPartner={columnHeadersPartner}
          deductibleType={deductibleType}
          inNetworkLimits={inNetworkLimitsArray}
          getCollapseProps={getCollapseProps2}
          birthParentCompany={birthParent.company}
          partnerCompany={partner.company}
          indexToSplit={indexToSplit}
          reportVersion={report.version}
          preference={currentPreference}
          tableType="inNetworkLimits"
        />
      </TablesWrapper>
    </Wrapper>
  );
};

export default AllMyOptions;
