import { createReducer } from "reduxsauce";
import Actions from "./actions";
import { merge, assign } from "lodash";
import {
  changeGroupInsuranceGroup,
  changeGroupInsurancePlan,
  changeNetworkPlan,
  changeDoctorHospital,
  changeMyNetwork,
} from "services/utils";
import { broaderAllNetworks, roomTypes } from "store/formData";
import { steps } from "components/pages/CreatePlanPage/utils/steps";
import { serviceSteps } from "components/pages/AddServicesPage/utils/serviceSteps";
import { premiumSteps } from "components/pages/AddPremiumsPage/utils/steps";
import { networkSteps } from "components/pages/AddNetworksPage/utils/steps";

const { Types } = Actions;
const INITIAL_STATE = {
  packageTier: 0,
  birth: {},
  insurance: {},
  planNames: {
    value: "",
    isVisible: false,
    data: ["Doctor", "Hospital"],
    prevValue: null,
  },
  doctors: {
    value: null,
    isVisible: false,
    data: [],
    prevValue: null,
    current: null,
    practice: {
      _id: null,
      name: null,
    },
  },
  hospitals: {
    value: null,
    isVisible: false,
    data: [],
    prevValue: null,
    current: null,
  },
  deliveryTypes: {
    value: "All Deliveries",
    isVisible: false,
    data: [],
    prevValue: null,
  },
  roomTypes: {
    value: null,
    prevValue: null,
    isVisible: false,
    data: roomTypes,
  },
  deluxe: {
    data: ["No", "Yes"],
    value: null,
    prevValue: null,
    isVisible: false,
  },
  isDoctorInNetwork: {
    value: null,
    isVisible: false,
    data: ["No", "Yes"],
    prevValue: null,
    hospitals: {},
    doctors: {},
  },

  isDue: {
    value: null,
    data: ["No", "Yes"],
    isVisible: false,
    preValue: null,
  },
  dueDate: {
    value: null,
    data: [],
    isVisible: false,
    preValue: null,
  },

  parentsPlan: {
    value: "",
    data: ["Separate plans"],
    isVisible: false,
    prevValue: null,
  },

  familyPlan: {
    value: "",
    data: [],
    isVisible: false,
    prevValue: null,
  },
  birthParent: {
    value: "Birth Parent",
    isVisible: false,
    prevValue: null,
  },
  groups: {
    value: "",
    data: [],
    isVisible: false,
    prevValue: null,
  },
  premiumGroups: {
    value: "",
    data: [],
    isVisible: false,
    prevValue: null,
  },
  insurancePlans: {
    value: "",
    data: [],
    isVisible: false,
    prevValue: null,
  },
  plansNetwork: {
    value: "",
    data: [],
    isVisible: false,
    prevValue: null,
  },
  broaderNetworksMySelf: {
    value: "",
    data: [],
    isVisible: false,
  },
  tiers: {
    value: "",
    data: [],
    isVisible: false,
    prevValue: null,
  },
  hsa: {
    value: "",
    data: [],
    isVisible: false,
    preValue: null,
  },
  surcharges: {
    value: [],
    data: [],
    isVisible: false,
    prevValue: null,
  },
  partner: {
    value: "Partner",
    isVisible: false,
    prevValue: null,
  },
  partnerGroups: {
    value: "",
    data: [],
    isVisible: false,
    prevValue: null,
  },
  partnerPremiumGroups: {
    value: "",
    data: [],
    isVisible: false,
    prevValue: null,
  },
  partnerInsurancePlans: {
    value: "",
    data: [],
    isVisible: false,
    prevValue: null,
  },
  partnerPlansNetwork: {
    value: "",
    data: [],
    isVisible: false,
    prevValue: null,
  },
  partnerTiers: {
    value: "",
    data: [],
    isVisible: false,
    prevValue: null,
  },
  partnerHsa: {
    value: "",
    data: [],
    isVisible: false,
    preValue: null,
  },
  partnerSurcharges: {
    value: [],
    data: [],
    isVisible: false,
    prevValue: null,
  },
  steps: steps,
  serviceSteps: serviceSteps,
  current: 0,
  premiumSteps: premiumSteps,
  networkSteps: networkSteps,
};

const changeBirthPlanOptions = (state = INITIAL_STATE, { name, value }) => {
  if (name === "practice")
    return merge({}, state, {
      doctors: { practice: value },
    });
  return merge({}, state, {
    [name]: { value, prevValue: state[name].value },
  });
};

const changeBirthPlan = (state = INITIAL_STATE, { name, value }) => {
  if (name === "planNames") {
    const isDoc = value.toLowerCase() === "doctor";
    return merge({}, state, {
      planNames: { value, prevValue: state.planNames.value },
      doctors: {
        value: null,
        isVisible: isDoc,
        prevValue: state.doctors.value || state.doctors.prevValue,
        practice: {
          _id: null,
          name: null,
        },
        current: null,
      },
      deluxe: {
        value: null,
      },
      hospitals: {
        value: null,
        prevValue: state.hospitals.value || state.hospitals.prevValue,
        isVisible: !isDoc,
        current: null,
      },
      deliveryTypes: { isVisible: false },
      roomTypes: { isVisible: false },
      isDoctorInNetwork: {
        prevValue: state.isDoctorInNetwork.prevValue,
        value: null,
        doctors: {
          select: !isDoc ? state.doctors.value : state.isDoctorInNetwork.doctors.select,
          prevValue: !isDoc ? state.isDoctorInNetwork.value : state.isDoctorInNetwork.doctors.prevValue,
        },
        hospitals: {
          select: isDoc ? state.hospitals.value : state.isDoctorInNetwork.hospitals.select,
          prevValue: isDoc ? state.isDoctorInNetwork.value : state.isDoctorInNetwork.hospitals.prevValue,
        },
      },
    });
  }
  const data = changeDoctorHospital({
    isPrivate: state.roomTypes.value,
    name,
    value,
    ...state,
  });
  return merge({}, state, {
    ...data,
  });
};

const changeInsurancePlan = (state = INITIAL_STATE, { name, value }) => {
  let data = { insurance: { ...state.insurance, [name]: value } };
  if (name === "groups") {
    data = changeGroupInsuranceGroup(value, state);
    data.groups.prevValue = state.groups.value;
  }
  if (name === "insurancePlans") {
    data = changeGroupInsurancePlan(value, state);
  }
  if (name === "plansNetwork") {
    data = changeNetworkPlan(value, state);
  }
  if (name === "broaderNetworksMySelf") {
    data = changeMyNetwork(value, state);
  }
  if (name === "isDoctorInNetwork") {
    return merge({}, state, {
      isDoctorInNetwork: value,
    });
  }
  return {
    ...state,
    ...data,
  };
};

const changePremiumPlan = (state = INITIAL_STATE, { name, value }) => {
  let newState;
  if (name === "firstBaby") {
    newState = {
      ...state,
      insurance: { ...state.insurance, [name]: value },
    };
  } else if (name === "groups") {
    newState = {
      ...state,
      [name]: {
        isVisible: true,
        data: state[name].data,
        value: value,
        prevValue: state[name].value,
      },
      plansNetwork: {
        isVisible: false,
        data: [],
        value: null,
        prevValue: state.plansNetwork.value,
      },
    };
  } else if (name === "partnerGroups") {
    newState = {
      ...state,
      [name]: {
        isVisible: true,
        data: state[name].data,
        value: value,
        prevValue: state[name].value,
      },
      partnerPlansNetwork: {
        isVisible: false,
        data: [],
        value: null,
        prevValue: state.partnerPlansNetwork.value,
      },
    };
  } else {
    newState = {
      ...state,
      [name]: {
        isVisible: true,
        data: state[name].data,
        value: value,
        prevValue: state[name].value,
      },
    };
  }
  return newState;
};

const loadSuccess = (state = INITIAL_STATE, { payload: { doctors, hospitals, deliveryTypes } }) => {
  return merge({}, state, {
    doctors: { data: doctors },
    hospitals: { data: hospitals },
    deliveryTypes: { data: deliveryTypes },
  });
};

const reset = (state = INITIAL_STATE) => {
  return INITIAL_STATE;
};

const loadSelect = (state = INITIAL_STATE, { name, data = [] }) => {
  let value = null;
  let plan = {
    [name]: {
      value,
      data,
      isVisible: true,
      isRequesting: false,
      prevValue: state[name].prevValue,
    },
  };

  if ((name === "tiers" || name === "partnerTiers" || name === "hsa" || name === "partnerHsa") && data.length <= 1) {
    plan = {
      [name]: {
        value: data.length === 0 ? 0 : data[0],
        data: [],
        isVisible: false,
        isRequesting: false,
        prevValue: state[name].prevValue,
      },
    };
  }
  if (name === "surcharges" || name === "partnerSurcharges") {
    const surchargeData = data.filter((obj) => obj.value !== null);
    if (surchargeData.length === 0) {
      plan = {
        [name]: {
          value: [],
          data: [],
          isVisible: false,
          isRequesting: false,
          prevValue: state[name].prevValue,
        },
      };
    }
  }
  if (name === "plansNetwork" && data.length === 1) {
    const broaderNetworks = [broaderAllNetworks, ...data];
    plan[name].prevValue = state.plansNetwork.prevValue;
    plan.broaderNetworksMySelf = {
      isVisible: true,
      data: broaderNetworks,
      value: broaderNetworks[0]._id,
    };
  }

  if (name === "partnerPlansNetwork" && data.length === 1) {
    plan = {
      [name]: {
        value: data[0]._id,
        data,
        isVisible: true,
        isRequesting: false,
        prevValue: state[name].prevValue,
      },
    };
  }

  return {
    ...state,
    ...plan,
  };
};

const changePrevValue = (state = INITIAL_STATE, { name, prevValue }) => {
  return merge({}, state, { [name]: { prevValue, value: null } });
};

const changePrevValueBirth = (state = INITIAL_STATE, { name, prevValue }) => {
  return merge({}, state, {
    [name]: { prevValue, value: null },
    isDoctorInNetwork: { [name]: { select: state[name].prevValue } },
  });
};

const changeCurrent = (state = INITIAL_STATE, { value }) => {
  return assign({}, state, { current: value });
};

export const HANDLERS = {
  [Types.CHANGE_BIRTH_PLAN_OPTIONS]: changeBirthPlanOptions,
  [Types.CHANGE_BIRTH_PLAN]: changeBirthPlan,
  [Types.CHANGE_INSURANCE_PLAN]: changeInsurancePlan,
  [Types.LOAD_OPTIONS_SUCCESS]: loadSuccess,
  [Types.RESET_STORE_PLAN]: reset,
  [Types.LOAD_INSURANCE_SELECT]: loadSelect,
  [Types.CHANGE_PREV_VALUE]: changePrevValue,
  [Types.CHANGE_PREV_VALUE_BIRTH]: changePrevValueBirth,
  [Types.CHANGE_INSURANCE_IS_NETWORK]: changeInsurancePlan,
  [Types.CHANGE_PREMIUM_PLAN]: changePremiumPlan,
  [Types.CHANGE_CURRENT]: changeCurrent,
};

const reducer = createReducer(INITIAL_STATE, HANDLERS);

export default reducer;
