import React, { useState } from "react";
import styled from "styled-components";
import { FormText } from "components";

import { Upload, message, Button } from "antd";
import { Icon } from "@ant-design/compatible";

const Wrapper = styled.div`
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

const StyledBtn = styled(Button)`
  ${({ width }) =>
    width
      ? `
    width: ${width}px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  `
      : `
    width: 100%;
  `}
`;

const StyledUpload = styled(Upload)`
  .ant-upload-list {
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-btn {
    height: 31px;
    width: 303px;
    border-radius: 30px;
    color: white;
    background-color: #1c355e;
    font-family: circular;
    font-size: 18px;
    line-height: 22.63px;
  }
`;

const UploadCustom = ({ name, handleChange, width }) => {
  const [fileList, setFileList] = useState([]);

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      handleChange(name, newFileList);
    },

    beforeUpload: (file) => {
      let duplicateError = false;

      fileList.forEach((arr) => {
        arr && arr.name === file.name
          ? (duplicateError = true)
          : (duplicateError = false);
      });
      if (fileList.length >= 4) {
        message.error("Please upload your pamphlets before adding more.");
      } else if (duplicateError === true) {
        message.error("Duplicate file name.");
      } else if (file.size >= 50000000) {
        message.error("File size cannot exceed 50 MB");
      } else {
        const newFileList = [...fileList, file];
        setFileList(newFileList);
        handleChange(name, [...fileList, file]);
      }

      return false;
    },
    fileList,
  };

  return (
    <Wrapper>
      <StyledUpload {...props}>
        <StyledBtn width={width}>
          <Icon type="upload" /> Upload My Pamphlet
        </StyledBtn>
        <FormText
          text="You can upload multiple files if needed, but select them one at a time."
          small="true"
        />
      </StyledUpload>
    </Wrapper>
  );
};

export default React.memo(UploadCustom);
