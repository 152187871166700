import React, { useState } from "react";
import styled from "styled-components";
import {
  CalculateHeader as Header,
  ButtonLink,
  FormInput as Input,
} from "components";

const Wrapper = styled.section``;

const FlexWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InnerWrapper = styled.div`
  display: flex;
  width: 200px;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 45px 0;
`;

const MemberCodeSection = ({ handleNext, onChange }) => {
  const [code, setCode] = useState("");
  const [isInput, setIsInput] = useState(null);

  const handleChange = ({ target: { name, value } }) => {
    setCode(value);
  };

  const handleSubmit = () => {
    onChange("memberCode", code);
    handleNext();
  };

  return (
    <Wrapper>
      <Header
        bigTitle={true}
        title="Do you have a member code?"
        greenText={isInput && "Enter your code below"}
      />
      <FlexWrapper>
        {isInput ? (
          <FlexWrapper>
            <InnerWrapper>
              <Input
                placeholder="Member Code"
                name="memberCode"
                handleChange={handleChange}
                value={code}
              />
            </InnerWrapper>
            {code.length > 0 && (
              <ButtonLink withBG button fontSize="18" onClick={handleSubmit}>
                Continue
              </ButtonLink>
            )}
          </FlexWrapper>
        ) : (
          <InnerWrapper>
            <ButtonLink
              withBG
              button
              fontSize="18"
              onClick={() => setIsInput(true)}
            >
              Yes
            </ButtonLink>
            <ButtonLink
              withBG
              button
              fontSize="18"
              href="https://predictabill.com/request-invitation"
            >
              No
            </ButtonLink>
          </InnerWrapper>
        )}
      </FlexWrapper>
    </Wrapper>
  );
};

export default MemberCodeSection;
