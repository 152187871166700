import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Header, Footer, PageTemplate, Text, InNetworkCare } from "components";
import {
  getNetworksGrid,
  registerProfessionalUser,
  addGroupToUser,
  logLogin,
  getProfile,
  getGroupByNetworkGridId,
  isProfessional,
  sendProfessionalEmail,
  updateRole,
} from "services/api";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { getUserId, getToken, setToken } from "services/utils";
import { Input, Modal, message } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import authActions from "store/auth/actions";
import Actions from "store/plan/actions";

const HeadingWrapper = styled.div`
  /* height: 220px; */
  width: 100%;
  background-color: #1c355e;
  display: flex;
  align-items: flex-start;
  justify-content: ${(props) => (props.justify ? props.justify : "space-evenly")};
  padding: ${(props) => (props.justify ? "0 10% 20px 10%" : "0 100px 20px 100px")};

  @media (max-width: 768px) {
    height: auto;
    padding: 0px 20px 20px 20px;
    flex-direction: column;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  justify-content: ${(props) => (props.justify ? props.justify : "space-evenly")};
  margin-top: 25px;
  width: ${(props) => (props.width ? props.width : "100%")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;

const StyledTitle = styled(Text)`
  @media (max-width: 768px) {
    font-size: 0.6em;
  }
`;

const StyledText = styled(Text)`
  @media (max-width: 768px) {
    margin-top: 0.3em;
    line-height: 1.1em;
    font-size: 0.9em;
  }
`;

const StickyButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 40px;
  bottom: 10px;
  z-index: 1;
`;

const StickyButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #21355b;
  border-radius: 25px;
  transition: 0.2s;
  padding: 18px;
  margin-bottom: 10px;
  max-width: 180px;

  :hover {
    color: white;
    background-color: #283e67;
    cursor: pointer;
  }
`;

const CustomModal = styled(Modal)`
  min-width: 40vw;
  .ant-modal-body {
    padding-bottom: 24px;
  }

  @media (min-width: 769px) {
    width: 90vw;
  }

  label {
    width: 100% !important;
  }
`;

const NetworksGridPage = ({ ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = props.match.params;
  const [networksGrid, setNetworksGrid] = useState(null);
  const [userId, setUserId] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [group, setGroup] = useState(null);
  const [finishedGetRoleCall, setFinishedGetRoleCall] = useState(false);
  const [isProfessionalUser, setIsProfessionalUser] = useState(false);

  const validateEmail = (email) => {
    const emailRegex =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

    setIsValidEmail(emailRegex.test(email));
  };

  const loadUserId = async () => {
    const id = await getUserId();
    setUserId(id);
    roleCheck();
  };

  const loadGroupByNetworkGridId = async () => {
    const { status, data } = await getGroupByNetworkGridId({ gridId: id });
    if (status === 200) {
      setGroup(data.group);
    }
  };

  const loadNetworksGrid = async () => {
    const { status, data } = await getNetworksGrid({ id });
    if (status === 200) {
      setNetworksGrid(data.grid);
    }
  };

  const roleCheck = async () => {
    const id = await getUserId();
    if (!id) {
      setIsProfessionalUser(false);
      setFinishedGetRoleCall(true);
      return;
    }
    const token = await getToken();
    const isProfessionalResponse = await isProfessional(token);
    setIsProfessionalUser(isProfessionalResponse.status === 200);
    setFinishedGetRoleCall(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadNetworksGrid();
    loadUserId();
    loadGroupByNetworkGridId();
  }, []);

  const getUser = async () => {
    const token = await getToken();
    const { status, data } = await getProfile(null, token, email);
    if (status === 200) {
      return data.user;
    }
  };

  const registerUser = async () => {
    const { status, data } = await registerProfessionalUser(email);
    if (status !== 200) {
      message.error("Something went wrong, please try again later.");
      return null;
    }
    return data;
  };

  const addGroup = async (user) => {
    await addGroupToUser({
      userId: user._id,
      group: group.name,
    });
  };

  const loginUser = async (user) => {
    const { username, token } = user;
    setToken(token);
    dispatch(authActions.Creators.loginSuccess(username, token));
    await logLogin({ username, route: "networks" });
  };

  const sendToCreatePlan = () => {
    localStorage.removeItem("form");
    dispatch(Actions.Creators.changeCurrent(0));
    history.push("/create-plan", {
      customReportId: id,
      displayName: group.displayName,
      group: group.name,
      option: "birthParent",
      groupObj: group,
    });
  };

  const sendToAddNetworks = () => history.push("/add-networks");

  return (
    <PageTemplate
      header={
        <Header
          V2
          userId={userId}
          href={finishedGetRoleCall && isProfessionalUser ? "/my-plans" : "/recommendations"}
        />
      }
      footer={<Footer />}
    >
      <HeadingWrapper justify="start">
        <FlexWrapper col align="flex-start" maxWidth={"70%"}></FlexWrapper>
      </HeadingWrapper>
      {networksGrid && (
        <InNetworkCare broaderNetworks={networksGrid.networks} providersGrid={networksGrid.providersGrid} gridId={id} />
      )}
      <StickyButtonsWrapper>
        {!userId && group && (
          <StickyButton
            onClick={() => {
              setModalType("save-progress");
              setModal(true);
            }}
          >
            <SaveOutlined style={{ padding: "0 15px 0 0" }} />
            <Text fontSize={16}>SAVE FOR LATER</Text>
          </StickyButton>
        )}
        {group && !group.masterReports.includes(id) && (
          <StickyButton
            onClick={() => {
              if (!userId) {
                setModalType("add-plans");
                setModal(true);
              } else {
                sendToCreatePlan();
              }
            }}
          >
            <PlusOutlined style={{ padding: "0 15px 0 0" }} />
            <Text fontSize={16}>ADD PLAN DESIGN</Text>
          </StickyButton>
        )}
        <StickyButton
          onClick={() => {
            if (!userId) {
              setModalType("new-networks");
              setModal(true);
            } else {
              sendToAddNetworks();
            }
          }}
        >
          <PlusOutlined style={{ padding: "0 15px 0 0" }} />
          <Text fontSize={16}>NEW DASHBOARD</Text>
        </StickyButton>
      </StickyButtonsWrapper>
      <CustomModal
        title="What is your email address?"
        open={modal}
        destroyOnClose={true}
        onCancel={() => {
          setModal(false);
          setModalType("");
          setEmail("");
        }}
        onOk={async () => {
          if (isValidEmail) {
            let user = await getUser();
            if (!user) {
              user = await registerUser();
            }
            if (user) {
              if (user.role !== "admin" && user.role !== "professional") {
                await updateRole({ username: user.username, role: "professional" });
              }
              await sendProfessionalEmail({ email });
              await addGroup(user);
              await loginUser(user);
              loadUserId();
            }
            setModal(false);
            if (modalType === "save-progress") {
              message.info("Progress saved!");
            } else if (modalType === "add-plans") {
              sendToCreatePlan();
            } else if (modalType === "new-networks") {
              sendToAddNetworks();
            }
          } else {
            message.error("Please enter a valid email address.");
          }
        }}
      >
        <Input
          type="email"
          style={{ width: "100%" }}
          placeholder="Enter email"
          onChange={(e) => {
            const email = e.target.value?.toLowerCase();
            setEmail(email);
            validateEmail(email);
          }}
        />
      </CustomModal>
    </PageTemplate>
  );
};

export default NetworksGridPage;
