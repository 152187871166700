import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100vw;
`;

const AuthPageTemplate = ({ children, ...props }) => {
  return <Wrapper {...props}>{children}</Wrapper>;
};

AuthPageTemplate.propTypes = {
  children: PropTypes.any.isRequired
};

export default AuthPageTemplate;
