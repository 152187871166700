import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Text, Link } from "components";
import { Button, Space, Tooltip } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import AddNewEmail from "components/molecules/AddNewEmail";

const Wrapper = styled.div`
  min-height: 194px;
  width: 35vw;
  border-radius: 10px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
`;

const Header = styled.div`
  height: 35px;
  width: inherit;
  background-color: #51a5a2;
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

const Body = styled.div`
  min-height: 163px;
  width: inherit;
  background-color: #f8f7f7;
  padding: 5px 15px;
`;

const BodyItem = styled.div`
  padding: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const Footer = styled.div`
  height: 40px;
  width: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 55px;
  padding-right: 55px;
  background-color: #e5f2f1;
`;

const EmailsWrapper = styled(Space)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

const ButtonWrapper = styled.span`
  margin-left: 7.5px;
  height: 16px;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-btn.ant-btn-primary,
  .ant-btn-primary {
    background-color: #51a5a1;
    border-color: #51a5a1;
    &:hover {
      background-color: #408480 !important;
      border-color: #408480 !important;
    }
  }
`;

const StyledButton = styled(Button)`
  width: 16px !important;
  min-width: 16px !important;
  height: 16px !important;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPlusOutlined = styled(PlusOutlined)`
  font-size: 12px;
  width: 12px;
  height: 12px;
  margin-left: 1px;
  margin-bottom: 1px;
`;

// display profile details in Account Page
function Profile({ profile }) {
  const [form, setForm] = useState({
    _id: null,
    firstName: null,
    lastName: null,
    username: null,
    birthDate: null,
    zipCode: null,
  });

  const getAlternativeEmails = (alternativeEmails = []) => {
    const workEmail = alternativeEmails?.filter(
      (item) => item.emailType === "workEmail"
    );
    const extraNonWorkEmail = alternativeEmails?.filter(
      (item) => item.emailType === "extraNonWorkEmail"
    );

    return { workEmail, extraNonWorkEmail };
  };

  useEffect(() => {
    const { workEmail, extraNonWorkEmail } = getAlternativeEmails(
      profile.alternativeEmails
    );
    setForm({
      _id: profile._id,
      firstName: profile.firstName,
      lastName: profile.lastName,
      username: profile.username,
      birthDate: profile.birthDate,
      zipCode: profile.zipCode,
      workEmail: workEmail,
      extraNonWorkEmail: extraNonWorkEmail,
    });
  }, [profile]);

  const handleAddEmail = (type, email) => {
    setForm({ ...form, [type]: [...form[type], email] });
  };

  const handleRemoveEmail = (type, email) => {
    const filteredEmails = form[type].filter((item) => item.email !== email);
    setForm({ ...form, [type]: filteredEmails });
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <Wrapper>
      <Header>
        <Text white bold fontSize="16">
          {form.firstName
            ? form.firstName + "'s Information"
            : "Your Information"}
        </Text>
      </Header>
      <Body>
        <BodyItem>
          <Text blue fontSize="16">
            First Name
          </Text>
          <Text blue fontSize="16">
            {form.firstName || "n/a"}
          </Text>
        </BodyItem>
        <BodyItem>
          <Text blue fontSize="16">
            Last Name
          </Text>
          <Text blue fontSize="16">
            {form.lastName || "n/a"}
          </Text>
        </BodyItem>
        <BodyItem>
          <Text blue fontSize="16">
            Primary Email
          </Text>
          <Text blue fontSize="16">
            {form.username}
          </Text>
        </BodyItem>
        <BodyItem>
          <Text blue fontSize="16">
            Work Email{" "}
          </Text>
          <ButtonWrapper>
            <StyledButton
              size="small"
              type="primary"
              shape="circle"
              onClick={() => {
                setShow("workEmail");
              }}
              icon={<StyledPlusOutlined />}
            />
          </ButtonWrapper>
          {!!form?.workEmail?.length ? (
            <EmailsWrapper align="end">
              {form?.workEmail.map((item) => (
                <Text blue fontSize="16">
                  {item.email}
                </Text>
              ))}
            </EmailsWrapper>
          ) : (
            <Text blue fontSize="16">
              n/a
            </Text>
          )}
        </BodyItem>
        <BodyItem>
          <Text blue fontSize="16">
            Extra Non-Work Email{" "}
          </Text>
          <ButtonWrapper>
            <StyledButton
              size="small"
              type="primary"
              shape="circle"
              onClick={() => {
                setShow("extraNonWorkEmail");
              }}
              icon={<StyledPlusOutlined />}
            />
          </ButtonWrapper>
          {!!form?.extraNonWorkEmail?.length ? (
            <EmailsWrapper align="end">
              {form?.extraNonWorkEmail.map((item) => (
                <Text blue fontSize="16">
                  {item.email}
                </Text>
              ))}
            </EmailsWrapper>
          ) : (
            <Text blue fontSize="16">
              n/a
            </Text>
          )}
        </BodyItem>
        <BodyItem>
          <Text blue fontSize="16">
            Birth Date
          </Text>
          <Text blue fontSize="16">
            {form.birthDate || "n/a"}
          </Text>
        </BodyItem>
        <BodyItem>
          <Text blue fontSize="16">
            Zip Code
          </Text>
          <Text blue fontSize="16">
            {form.zipCode || "n/a"}
          </Text>
        </BodyItem>
      </Body>
      <Footer>
        <Text fontSize="12" center>
          Email{" "}
          <Link blue text fontSize="12">
            hi@predictabill.com
          </Link>{" "}
          if any of this information is incorrect.
        </Text>
      </Footer>
      <AddNewEmail
        show={show}
        form={form}
        handleAddEmail={handleAddEmail}
        handleClose={handleClose}
        handleRemoveEmail={handleRemoveEmail}
      />
    </Wrapper>
  );
}

export default Profile;
