import React, { PureComponent } from "react";
import styled from "styled-components";
import { EventOne } from "services/google";
import {
  PageTemplate,
  Header,
  Footer,
  CalculateHeader,
  DoctorsTable,
  DoctorsFooter,
  Spinner,
} from "components";
import { PageView } from "services/google";

const InnerWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Wrapper = styled.div`
  position: relative;
  min-height: 200px;
`;

const Container = styled.div`
  margin: 0 auto 10px auto;
`;

class TopDoctorsPage extends PureComponent {
  componentDidMount() {
    const { load } = this.props;
    document.title = "Top Doctors - predictaBill";
    PageView();
    EventOne("Top Doctors Page", "Click", "Back on page");
    load();
  }

  handleClickLink = () => {
    EventOne("Top Doctors Page", "Click", "forward on calculation");
  };
  render() {
    return (
      <PageTemplate header={<Header tryIt />} footer={<Footer />}>
        <Container>
          <CalculateHeader
            big
            title="Ever wonder what the most recommended OBs in Manhattan cost?"
            greenText="We overlaid a real health plan with actual pregnancy bills"
            text="to compare your predicted out-of-pocket costs. "
          ></CalculateHeader>
        </Container>
        <Wrapper>
          {this.props.isRequesting ? (
            <Spinner center top />
          ) : (
            <InnerWrapper>
              <DoctorsTable
                {...this.props}
                handleClick={this.handleClickLink}
              />
            </InnerWrapper>
          )}
        </Wrapper>
        <DoctorsFooter handleClick={this.handleClickLink} />
      </PageTemplate>
    );
  }
}

export default TopDoctorsPage;
