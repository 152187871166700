import React, { useEffect, useState } from "react";
import { ModalView, Text } from "components";
import styled from "styled-components";
import { Button, Table, Input, Form, Popconfirm } from "antd";
import {
  DeleteOutlined,
  MailOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { getToken } from "services/utils";
import { addEmail, deleteEmail } from "services/api";

const Wrapper = styled.div`
  padding: 30px 50px;
  width: 45vw;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  .ant-btn.ant-btn-primary,
  .ant-btn-primary {
    background-color: #51a5a1;
    border-color: #51a5a1;
    &:hover {
      background-color: #408480 !important;
      border-color: #408480 !important;
    }
  }
`;

const EmailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  .ant-table-cell {
    padding-left: 0;
    padding-right: 0;
  }
`;

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const AddNewEmail = ({
  show,
  handleClose,
  handleAddEmail,
  handleRemoveEmail,
  form,
}) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [token, setToken] = useState();
  const { _id: userId } = form;

  const add = async (value, event) => {
    if (validateEmail(value)) {
      const res = await addEmail(userId, value.toLowerCase(), show, token);
      if (res.status == 200) {
        setError("");
        setValue("");
        handleAddEmail(show, { email: value.toLowerCase() });
      } else {
        setError(res?.response.data.message);
      }
    } else {
      setError("Invalid Email");
    }
  };

  const remove = async (show, email) => {
    const res = await deleteEmail(userId, email.toLowerCase(), token);
    if (res.status == 200) {
      handleRemoveEmail(show, email.toLowerCase());
    } else {
      setError(res?.data);
    }
  };

  useEffect(() => {
    const onLoad = async () => {
      const token = await getToken();
      setToken(token);
    };
    onLoad();
  }, []);

  const columns = [
    {
      title: "Emails",
      dataIndex: "email",
      width: "80%",
    },
    {
      title: "",
      key: "operation",
      width: "20%",
      render: (_, record) => (
        <Popconfirm
          title="Are you sure？"
          onConfirm={() => {
            remove(show, record.email);
          }}
          okType="danger"
          icon={
            <QuestionCircleOutlined
              style={{
                color: "red",
              }}
            />
          }
        >
          <Button
            style={{ width: "100%", borderRadius: 0 }}
            size="middle"
            type="danger"
            ghost
          >
            Remove <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <ModalView
      close={handleClose}
      isShow={!!show}
      isCancel
      textButton="Close"
      title={`${show === "extraNonWorkEmail" ? "Non-" : ""}Work Email Adresses`}
    >
      <Wrapper>
        <InputWrapper>
          <Input.Search
            size="large"
            placeholder={`Enter your ${
              show === "extraNonWorkEmail" ? "non-" : ""
            }work email adress`}
            prefix={<MailOutlined style={{ marginRight: 5 }} />}
            style={{ width: "100%", borderRadius: 0 }}
            allowClear
            enterButton="Add +"
            type="email"
            onChange={(e) => {
              setValue(e.target.value);
              error && setError("");
            }}
            value={value}
            onSearch={(value, event) => add(value, event)}
            status={error && "error"}
          />
        </InputWrapper>
        {error && (
          <Text red fontSize="16" style={{ marginTop: 5 }}>
            {error}
          </Text>
        )}
        <EmailsWrapper>
          {!!form[show]?.length && (
            <Table
              dataSource={form[show]}
              columns={columns}
              pagination={{
                defaultPageSize: 5,
                hideOnSinglePage: true,
              }}
            />
          )}
        </EmailsWrapper>
      </Wrapper>
    </ModalView>
  );
};
export default AddNewEmail;
