import React from "react";
import { FormattedNumber } from "react-intl";

const Currency = ({ price, style = "currency" }) => (
  <FormattedNumber
    value={price | 0}
    style={style}
    currency="USD"
    minimumFractionDigits={0}
    maximumFractionDigits={0}
  />
);

export default Currency;
