import React, { useRef, useState, useEffect } from "react";
import "./styles.css";
const PlansCost = ({ cost, type, recommendation, range }) => {
  return (
    <div className="wrapper">
      <div className={"cost"}>
        <h1>$866</h1>
        <p>per year</p>
      </div>
      <div className={"reccomendation"}>
        <p>
          Your recommended plan - Walmart Aetna Open Access <br /> Saver plan -
          is <span>less expensive</span> than the majority of other plans
          available to you
        </p>
        <br />
        <p>
          Annual premiums Across 48 different plans range from <span>$792</span>{" "}
          to <span>$18,072</span>
        </p>
      </div>
    </div>
  );
};

export default PlansCost;
