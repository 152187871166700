import React from "react";
import { TableRow, TableCell, Link, Text, Tooltip } from "components";
import { Icon } from "@ant-design/compatible";
import { setReportSeen } from "services/api/report.api";
import { getDateFormat } from "services/utils";

const ReportTableBody = ({ reports: body }) => {
  const reports = body || [];

  const renderReport = (
    {
      _id,
      isSeen,
      title,
      fileId,
      fulfilledDate,
      status,
      uploadIsComplete,
      created_at,
      deadline,
      dueDate,
      question,
    },
    i
  ) => {
    const handleSeen = async (reportId) => {
      return setReportSeen(reportId);
    };

    const notReadyText = (
      <Text>Your data isn't ready yet - please check back soon!</Text>
    );

    return (
      <TableRow key={_id}>
        <TableCell className="mobile-hide" small title={i}>
          {i + 1}
        </TableCell>
        <TableCell width="125px" big title={title}>
          {title === "Pending" ? (
            <Text arial>{title}</Text>
          ) : (
            <Link
              onClick={() => handleSeen(_id)}
              target="_blank"
              rel="noopener noreferrer"
              href={fileId}
              green
              noMargin
            >
              {title}
            </Link>
          )}
        </TableCell>
        <TableCell center>
          {status === 3 ? (
            <Link green to={`/optimize/${_id}`}>
              <Icon type="form" />
            </Link>
          ) : (
            <Tooltip placement="left" title={notReadyText}>
              <Icon type="question" />
            </Tooltip>
          )}
        </TableCell>
        <TableCell
          width="85px"
          center
          title={fulfilledDate}
          className="mobile-hide"
        >
          {!isSeen && status === 3 ? (
            <Text arial green bolder>
              {" "}
              New!{"\n"}
              {fulfilledDate}
            </Text>
          ) : (
            <Text arial>{fulfilledDate}</Text>
          )}
        </TableCell>
        <TableCell
          width="85px"
          center
          title="orderPlaced"
          className="mobile-hide"
        >
          <Text arial>
            {uploadIsComplete ? (
              getDateFormat(created_at)
            ) : (
              <Text bolder orange>
                incomplete
              </Text>
            )}
          </Text>
        </TableCell>
        <TableCell
          width="180px"
          big
          center
          title={question}
          className="mobile-hide"
        >
          <Text arial>{question}</Text>
        </TableCell>
      </TableRow>
    );
  };
  return <tbody>{reports.map(renderReport)}</tbody>;
};

export default ReportTableBody;
