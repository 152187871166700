import { roomTypes } from "store/formData";

export const isNotAll = (doctor, hospital) => {
  return (
    (doctor !== "all" && hospital === "") ||
    (hospital !== "all" && doctor === "") ||
    (hospital !== "" && doctor !== "")
  );
};

export const isNotAllAndIsDoc = (isDoc, hospitalCurrent, doctor, hospital) => {
  return isDoc && isNotAll(doctor, hospital) && hospitalCurrent;
};

export const getRooms = hospital => {
  let rooms = roomTypes;
  if (!hospital || (!hospital.privateRoomRate && !hospital.isShared)) return [];
  if (!hospital.privateRoomRate && hospital.isShared) rooms = [roomTypes[0]];
  else if (hospital.privateRoomRate && !hospital.isShared)
    rooms = [roomTypes[1]];
  return rooms;
};
