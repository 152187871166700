import { FieldOptions, PeriodOptions, CompanyOptions } from "./constants";

/**
 * Generates a key based on the field, companyType, and period.
 *
 * @param {Object} params - Function parameters.
 * @param {string} params.field - The field name. oneOf(FIELD_OPTIONS)
 * @param {"companyA"|"companyB"} [params.companyType] - The company type. If provided, it's appended to the field name.
 * @param {"Before"|"After"} [params.period] - The period. If provided and companyType is not, it's appended to the field name.
 *
 * @returns {string} The generated key.
 * @throws {Error} If the field, companyType, or period is not a valid option.
 */
export const getFieldKey = ({ field, companyType, period }) => {
  if (!Object.values(FieldOptions).includes(field)) {
    throw new Error(`Invalid field: ${field}. Must be one of ${Object.values(FieldOptions).join(", ")}`);
  }
  if (companyType && !Object.values(CompanyOptions).includes(companyType)) {
    throw new Error(`Invalid companyType: ${companyType}. Must be one of ${Object.values(CompanyOptions).join(", ")}`);
  }
  if (period && !Object.values(PeriodOptions).includes(period)) {
    throw new Error(`Invalid period: ${period}. Must be one of ${Object.values(PeriodOptions).join(", ")}`);
  }

  if (companyType) {
    return `${field} ${companyType}`;
  }
  if (period) {
    return `${field} ${period}`;
  }
  return field;
};
