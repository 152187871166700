import React from "react";
import moment from "moment";
import { DatePicker } from "antd";
import styled from "styled-components";
import { Text } from "components";

const styles = {
  width: "100%",
  height: "100%",
};

const stylesRequired = {
  width: "100%",
  height: "100%",
  fontSize: 14,
  borderRadius: "4px",
  color: "#CD667A",
  border: "1px solid",
};

const Date = styled(DatePicker)`
  .ant-calendar-picker-input.ant-input {
    color: rgb(0, 161, 155);
  }
  ${({ onboardingSection }) =>
    onboardingSection &&
    `
    .ant-calendar-picker-input.ant-input {
      border: none;
      border-radius: 22px;
      background: transparent;
      text-align: center;
      outline: none;
    }
    .ant-calendar-picker-icon {
      display: none;
    }
    .ant-calendar-picker-clear {
      display: none;
    }
  `}
`;

const Wrapper = styled.div`
  margin-bottom: ${({ isNotMargin }) => !isNotMargin && `5px`};
  width: 100%;
`;

const DateCustom = ({
  text,
  placeholder,
  handleChange,
  noMargin,
  name,
  value,
  open,
  start,
}) => {
  const onChange = (date, value) => {
    // value: "MM/DD/YYYY"
    const target = { name, value };
    handleChange({ target });
  };

  const disabledYear = (current) => {
    const before = current <= moment().startOf("year").subtract(2, "months");
    const after = current >= moment().add(1, "year");

    return before || after;
  };

  const disabledDate = (current) => {
    const before = moment().startOf("year").subtract(5, "years");

    // return start ? current < start : current < moment().startOf("year");
    return start ? current < start : current < before;
  };

  return (
    <Wrapper isNotMargin={noMargin}>
      <Text select>{text}</Text>
      {open ? (
        <Date
          onChange={onChange}
          placeholder={placeholder}
          format="MM/DD/YYYY"
          disabledDate={name === "dueDate" ? disabledYear : null}
          showToday={false}
          defaultPickerValue={
            name === "dueDate" ? moment().add(9, "month") : moment()
          }
          value={value ? moment(value, "MM/DD/YYYY") : null}
          open={open}
        />
      ) : (
        <Date
          onChange={onChange}
          style={value || value === false ? styles : stylesRequired}
          placeholder={placeholder}
          format="MM/DD/YYYY"
          disabledDate={disabledDate}
          showToday={false}
          defaultPickerValue={
            name === "dueDate" ? moment().add(9, "month") : moment()
          }
          value={value ? moment(value, "MM/DD/YYYY") : null}
        />
      )}
    </Wrapper>
  );
};

export default React.memo(DateCustom);
