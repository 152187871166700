import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyEmail, verifyCode, getProfile, addEmail, logLogin } from "services/api";
import styled from "styled-components";
import Text from "components/atoms/Text";
import { message } from "antd";
import { getToken, setToken, getUserId } from "services/utils";
import authActions from "store/auth/actions";

const FooterWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
`;

const Button = styled.div`
  background-color: ${(props) => (props.disabled ? "#8e9fbd" : "#1c355e")}
  border-radius: 15px;
  border: none;
  outline: none;
  padding: 4px 30px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  justify-self: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")}};
  transition: 0.1s;
  &:hover {
    background-color: ${(props) => (props.disabled ? "#8e9fbd" : "#2e528c")}};
  }
  @media (max-width: 900px){
    width: 150px;
    font-size: 16px;
  }
`;

const successMessage = (text) => {
  message.success({
    content: <>{text}</>,
    icon: (
      <span
        role="img"
        aria-label="check-circle"
        className="anticon anticon-check-circle"
        style={{
          color: "#00a19b",
        }}
      >
        <svg
          viewBox="64 64 896 896"
          focusable="false"
          data-icon="check-circle"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path>
        </svg>
      </span>
    ),
  });
};

const CompleteOnboardingFooter = ({
  type,
  attachPersonalEmailOption,
  emailFromTypeform,
  workEmail,
  email,
  canSendEmail,
  code,
  step,
  setStep,
  setPreviousStep,
  group,
  url,
}) => {
  email = email.replace("qu.edu", "quinnipiac.edu");
  const dispatch = useDispatch();
  const [sendingEmail, setSendingEmail] = useState(false);
  const history = useHistory();
  return (
    <FooterWrapper>
      <Button
        onClick={() => {
          if (step > 0) {
            setPreviousStep(step);
            setStep(step - 1);
          } else {
            history.push("/groups");
          }
        }}
      >
        <Text white center>
          Back
        </Text>
      </Button>
      <Button
        disabled={
          sendingEmail ||
          (step === 1 && type === "attachPersonalEmail" && attachPersonalEmailOption === "yes" && !canSendEmail)
        }
        onClick={async () => {
          if (step === 0 && type === "reVerifyWorkEmail") {
            setSendingEmail(true);
            const response = await verifyEmail(workEmail);
            if (response.status === 200) {
              setSendingEmail(false);
              const { codeId } = response.data;
              localStorage.setItem("codeId", codeId);
              setPreviousStep(step);
              setStep(step + 1);
            }
          } else if (step === 1 && type === "reVerifyWorkEmail") {
            const response = await verifyCode(localStorage.getItem("codeId"), code);
            if (response.status === 200) {
              const { isValid } = response.data;
              if (isValid) {
                const token = await getToken();
                const { status, data } = await getProfile(null, token, workEmail.toLowerCase());
                if (status === 200) {
                  const { username, token } = data.user;
                  setToken(token);
                  dispatch(authActions.Creators.loginSuccess(username, token));
                  const userId = await getUserId();
                  await addEmail(userId, emailFromTypeform, "extraNonWorkEmail", token);
                  await logLogin({ username });
                  successMessage("Email verified!");
                  history.push("/typeform/new-recommendation", {
                    url: url + `&primary=${username}`,
                    group,
                    primary: username,
                    newReport: true,
                  });
                }
              } else {
                message.error("Invalid code");
              }
            } else {
              message.error("Unable to verify code");
            }
          } else if (step === 0 && type === "attachPersonalEmail") {
            setPreviousStep(step);
            setStep(step + 1);
          } else if (step === 1 && type === "attachPersonalEmail") {
            if (attachPersonalEmailOption === "no") {
              setSendingEmail(true);
              const response = await verifyEmail(workEmail);
              if (response.status === 200) {
                setSendingEmail(false);
                const { codeId } = response.data;
                localStorage.setItem("codeId", codeId);
              }
            } else {
              if (email !== workEmail) {
                const token = await getToken();
                const { status, data } = await getProfile(null, token, email.toLowerCase());
                if (status === 200) {
                  const { username, token } = data.user;
                  setToken(token);
                  dispatch(authActions.Creators.loginSuccess(username, token));
                  const userId = await getUserId();
                  await addEmail(userId, workEmail, "workEmail", token);
                  await logLogin({ username });
                  history.push("/typeform/new-recommendation", {
                    url: url + `&primary=${username}`,
                    group,
                    primary: username,
                    newReport: true,
                  });
                } else {
                  const userTypeformResponse = JSON.parse(localStorage.getItem("userTypeformResponse"));
                  userTypeformResponse["email"] = email.toLowerCase();
                  localStorage.setItem("userTypeformResponse", JSON.stringify(userTypeformResponse));
                  history.push("/typeform/new-recommendation", {
                    url: url + `&primary=${email.toLowerCase()}`,
                    group,
                    primary: email.toLowerCase(),
                  });
                }
                successMessage("Proceeding...");
              } else {
                message.error("Please enter a personal email address");
              }
            }
            setPreviousStep(step);
            setStep(step + 1);
          } else if (step === 2 && type === "attachPersonalEmail") {
            const response = await verifyCode(localStorage.getItem("codeId"), code);
            if (response.status === 200) {
              const { isValid } = response.data;
              if (isValid) {
                successMessage("Email verified!");
                const token = await getToken();
                const { status, data } = await getProfile(null, token, workEmail.toLowerCase());
                if (status === 200) {
                  const { username, token } = data.user;
                  setToken(token);
                  dispatch(authActions.Creators.loginSuccess(username, token));
                  await logLogin({ username });
                  history.push("/typeform/new-recommendation", {
                    url: url + `&primary=${username}`,
                    group,
                    primary: username,
                    newReport: true,
                  });
                } else {
                  history.push("/typeform/new-recommendation", {
                    url: url + `&primary=${workEmail.toLowerCase()}`,
                    group,
                    primary: workEmail.toLowerCase(),
                  });
                }
              } else {
                message.error("Invalid code");
              }
            }
          }
        }}
      >
        <Text white center>
          {sendingEmail ? "Sending..." : "Next"}
        </Text>
      </Button>
    </FooterWrapper>
  );
};

export default CompleteOnboardingFooter;
