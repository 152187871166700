import { connect } from "react-redux";
import { CalculateInsuranceBroader } from "components";
import Action from "store/plan/actions";

const mapStateToProps = (state) => ({
  isDoctorInNetwork: state.plan.isDoctorInNetwork,
  broaderNetworksMySelf: state.plan.broaderNetworksMySelf,
  myNetwork: state.plan.insurance.myNetwork,
});

const mapDispatchToProps = (dispatch) => ({
  change: (name, value) => {
    dispatch(Action.Creators.changeInsurancePlan(name, value));
  },
  changeNetwork: (value) => {
    dispatch(
      Action.Creators.changeInsurancePlan("isDoctorInNetwork", {
        value,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculateInsuranceBroader);
