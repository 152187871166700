import React from "react";
import { DynamicField } from "components";
import { UploadOutlined } from "@ant-design/icons";
import { Form, Input, Space, Upload, Button, Select, Checkbox } from "antd";
import "./styles.css";
const { Item } = Form;

const GroupFormMyPlansModal = ({ imageUrl, setLogo, disableLinkedinUrl, doesntHaveLinkedinWatch, form }) => {
  const todaysYear = new Date().getFullYear();
  const nextYear = todaysYear + 1;

  const handleEINChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, "");

    let formattedValue = "";
    for (let i = 0; i < value.length && i < 9; i++) {
      if (i === 2) {
        formattedValue += "-";
      }
      formattedValue += value[i];
    }

    form.setFieldsValue({ ein: formattedValue });
  };

  return (
    <>
      <Space direction="horizontal" align="start">
        <div>
          <Item label="Display Name" name="displayName" labelCol={{ span: 24 }}>
            <Input placeholder="Untitled" autoComplete="off" />
          </Item>
          {doesntHaveLinkedinWatch ? (
            <Item
              label="Federal Employer Identification Number (EIN)"
              name="ein"
              labelCol={{ span: 24 }}
              style={{ marginBottom: 0, width: "350px" }}
            >
              <Input
                placeholder="99-9999999"
                autoComplete="off"
                onChange={handleEINChange}
                maxLength={10}
                disabled={disableLinkedinUrl}
              />
            </Item>
          ) : (
            <Item
              label="LinkedIn URL"
              name="linkedinUrl"
              labelCol={{ span: 24 }}
              style={{ marginBottom: 0, width: "350px" }}
            >
              <Input
                placeholder="https://www.linkedin.com/company/untitled"
                autoComplete="off"
                disabled={disableLinkedinUrl}
              />
            </Item>
          )}
          <Item name="doesntHaveLinkedin" valuePropName="checked" labelCol={{ span: 24 }}>
            <Checkbox className={"has-linkedin-checkbox"}>This company doesn&apos;t have a LinkedIn page</Checkbox>
          </Item>
          <Item label="Logo" name="logo" labelCol={{ span: 24 }}>
            <div style={{ display: "inline" }}>
              <Upload
                maxCount={1}
                beforeUpload={() => false}
                onChange={(event) => {
                  setLogo(event.file);
                }}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
              {imageUrl && <img src={imageUrl} style={{ marginTop: "24px", maxWidth: "60px", maxHeight: "60px" }} />}
            </div>
          </Item>
          <Item label="Plan Year" name="year" labelCol={{ span: 24 }}>
            <Select
              options={[
                {
                  label: todaysYear,
                  value: todaysYear,
                },
                {
                  label: nextYear,
                  value: nextYear,
                },
              ]}
            />
          </Item>
        </div>
        <Space direction="vertical" align="start">
          <Space direction="horizontal" align="start">
            <DynamicField name="otherSearchTerms" label="Other Search Terms" />
            <DynamicField name="otherDomains" label="Valid Email Domain(s)" placeholder="untitled.com, unttld.com" />
          </Space>
        </Space>
      </Space>
    </>
  );
};

export default GroupFormMyPlansModal;
