import React from "react";
import { Card, message } from "antd";

const ListAllGroupsPanel = ({ groups }) => {
  const bestGroups = groups.filter(group => group.prestigeRanking > 0 && group.logoUrl).sort((a, b) => a.prestigeRanking - b.prestigeRanking)
  const newLength = Math.floor((bestGroups.length + 1) / 5) * 5 - 1
  bestGroups.length = newLength ? newLength > 4 ? Math.floor((bestGroups.length + 1) / 5) * 5 - 1 : bestGroups.length : 0
  const otherGroups = groups.filter(group => !bestGroups.find(bestGroup => bestGroup?.name === group?.name)).sort((a, b) => a.name.localeCompare(b.name))
  return (
    <Card title="Groups List">
      {bestGroups && bestGroups.map((group, idx) => (
        <div key={idx}>{group.name} - {group.displayName} - {group.masterReports} - {group.prestigeRanking}</div>
      ))}
      {bestGroups.length + otherGroups.length > 0 && ("------------------------------------------")}
      {otherGroups && otherGroups.map((group, idx) => (
        <div key={idx}>{group.name} - {group.displayName} - {group.masterReports} - {group.prestigeRanking}</div>
      ))}
    </Card>
  );
};

export default ListAllGroupsPanel;
