import React, { useState } from "react";
import styled from "styled-components";
import {
  TableRow,
  TableHead,
  TableCell,
  Text,
  Link,
  Currency,
  AntModalView as ModalView,
} from "components";
import { Icon } from "@ant-design/compatible";
import useModal from "services/hooks/useModal.hook";

const Rotater = styled(Link)`
  display: inline-block;
  margin: auto 5px;
  transition: all 0.8s ease;
  transform: ${(state) => (state.show ? `rotate(-90deg)` : `rotate(0deg)`)};
`;

const findSubRow = (row, data, i, cols) => {
  let newRow = row;

  const total = data.reduce((total, charge) => {
    if (newRow.ids.includes(charge.id)) return total + charge.value;
    return total;
  }, 0);

  let dataFill = !newRow.data.length ? new Array(cols).fill(0) : newRow.data;
  dataFill[i] = total;
  newRow.data = dataFill;

  if (row.title === "Cost of Visits") {
    // displays modal details for cost of visits, but not reimbursement from insurance
    const chargeDetail =
      data.find((charge) => newRow.ids.includes(charge.id)) || null;

    newRow.details = chargeDetail ? chargeDetail : null;
  }

  return newRow;
};

const findCatRows = ({ tier, titles, data, ref, num, cols }) => {
  let rows = tier;

  titles.forEach((title, i) => {
    let row;
    const existingRow = tier.find((row) => row.title === title.title);

    const details = data.filter((charge) => {
      return charge.id === title.id;
    });

    const total = details.length
      ? details.reduce((total, charge) => {
          return total + charge.value;
        }, 0)
      : 0;

    if (existingRow) {
      existingRow.data[num] = total;
      existingRow.details = findDetailRows({
        tier: existingRow.details,
        data: details,
        ref: existingRow.id,
        num: num,
        cols: cols,
      });
      row = existingRow;
      rows = rows.filter((level) => level.id !== existingRow.id);
    } else {
      const id = parseFloat(ref.toString() + "2" + i);
      const dataFill = new Array(cols).fill(0);
      dataFill[num] = total;
      row = {
        id: id,
        title: title.title,
        data: dataFill,
        details: findDetailRows({
          tier: [],
          data: details,
          ref: id,
          num: num,
          cols: cols,
        }),
        ref: ref,
      };
    }
    row && rows.push(row);
  });

  return rows;
};

const findDetailRows = ({ tier, data, ref, num, cols }) => {
  let rows = tier;

  data.forEach((charge, i) => {
    let row;
    const existingRow = tier.find((level) => {
      return level.title === charge.title && level.ref === ref;
    });

    if (existingRow) {
      rows.splice(rows.indexOf(existingRow), 1);
      existingRow.data[num] = { value: charge.value, math: charge.math };
      row = existingRow;
    } else {
      const id = parseFloat(ref.toString() + i);
      const dataFill = new Array(cols).fill({ value: 0 });
      dataFill[num] = { value: charge.value, math: charge.math };
      row = {
        id: id,
        title: charge.title,
        data: dataFill,
        ref: ref,
      };
    }
    row && rows.push(row);
  });

  return rows;
};

const formatMath = (title, { value, math }) => {
  const slice = title.toLowerCase().includes("family") ? 1 : 2;
  const responsibility = title.split(" ").slice(0, slice).join(" ");
  // let mathText, insuranceText,
  let heading;

  if (
    title.toLowerCase().includes("deductible") ||
    title.toLowerCase().includes("copay") ||
    title.toLowerCase().includes("coinsurance")
  ) {
    // mathText = title.toLowerCase().includes("coinsurance")
    //   ? "After Deductible x Coinsurance Percentage"
    //   : title.toLowerCase().includes("copay")
    //   ? "After Deductible"
    //   : undefined;
    // insuranceText = math[1];

    // heading = mathText
    //   ? `Charges to ${responsibility} ${mathText}`
    //   : `Charges to ${responsibility}`;
    heading = `Charges to ${responsibility}`;
  } else if (title.toLowerCase().includes("premiums")) {
    heading = responsibility + " " + math[1];
  } else {
    heading = math[1];
  }

  const text = math.map((calc, i) => {
    return (
      <Text modal center lg lineHeight="30px" key={i}>
        {calc}
      </Text>
    );
  });

  return (
    <>
      {heading}
      {text}
      {/* <Text modal bold center lg lineHeight="30px">
        {heading}
      </Text>
      <Text modal center lg lineHeight="30px">
        {math[0]}
      </Text>
      <Text modal center lg lineHeight="30px">
        {insuranceText}
      </Text> */}
    </>
  );
};

const TotalModal = ({
  id,
  modalId,
  isShow,
  handleClick,
  cost,
  title,
  total,
  fontSize,
}) => {
  return total.value !== 0 && total.math ? (
    <>
      <Link bold gray fontSize={fontSize} onClick={() => handleClick(id)}>
        {fontSize === "18" ? "-" : ""}
        {cost(total.value || total)}
      </Link>
      <ModalView
        close={handleClick}
        isShow={isShow && modalId === id}
        title={`${title} Breakdown`}
      >
        {formatMath(title, total)}
      </ModalView>
    </>
  ) : total.value !== 0 ? (
    <Text arial>{cost(Math.abs(total.value))}</Text>
  ) : (
    <Text arial>-</Text>
  );
};

const RowTotalCost = ({ type, data, isOther, reportType }) => {
  const { handleClick, isShow } = useModal();
  const [showTiers, setShowTiers] = useState([]);
  const [modalId, setModalId] = useState("");
  const handleShow = (rowId) => {
    const tiers = showTiers;
    const isTierOpen = tiers.includes(rowId);
    const openTiers = isTierOpen
      ? tiers.filter((id) => id !== rowId)
      : [...tiers, rowId];

    setShowTiers(openTiers);
  };

  const handleModalLoad = (id) => {
    handleClick();
    setModalId(id);
  };

  const insuranceEvent = reportType === "baby" ? "Due Date" : "";
  const cols =
    data.before.length > data.after.length
      ? data.before.length
      : data.after.length;

  const num = data[isOther ? "after" : "before"][0] ? 0 : 1;

  let titles =
    type === "premium"
      ? {
          0: {
            id: 0,
            title: data.before[num] && data.before[num].total.title,
            data: [],
          },
          1: [
            {
              id: 1.1,
              title: `Premiums Before ${insuranceEvent}`,
              data: new Array(cols).fill(0),
            },
            {
              id: 1.2,
              title: `Premiums After ${insuranceEvent}`,
              data: new Array(cols).fill(0),
            },
          ],
        }
      : type === "insurance"
      ? {
          0: {
            id: 0,
            title: data.before[num] && data.before[num].total.title,
            data: [],
          },
          1: [
            {
              id: 1.1,
              ids: ["COP", "COP-B", "COP-F"],
              title: "Copays",
              data: [],
            },
            {
              id: 1.2,
              ids: ["DED", "DED-B", "DED-F"],
              title: "Deductibles",
              data: [],
            },
            {
              id: 1.3,
              ids: ["COIN", "COIN-B", "COIN-F"],
              title: "Coinsurance",
              data: [],
            },
          ],
          2: [
            {
              id: "COP",
              title:
                reportType === "individual" || reportType === "household"
                  ? "Patient Copays"
                  : "Parent Copays",
            },
            { id: "COP-B", title: "Baby Copays" },
            { id: "COP-F", title: "Family Copays" },
            {
              id: "DED",
              title:
                reportType === "individual" || reportType === "household"
                  ? "Patient Deductibles"
                  : "Parent Deductibles",
            },
            { id: "DED-B", title: "Baby Deductibles" },
            { id: "DED-F", title: "Family Deductibles" },
            {
              id: "COIN",
              title:
                reportType === "individual" || reportType === "household"
                  ? "Patient Coinsurance"
                  : "Parent Coinsurance",
            },
            { id: "COIN-B", title: "Baby Coinsurance" },
            { id: "COIN-F", title: "Family Coinsurance" },
          ],
        }
      : type === "out-of-network"
      ? {
          0: {
            id: 0,
            title: data.before[num] && data.before[num].title,
            data: [],
          },
          1: [
            {
              id: 1.1,
              ids: ["COST"],
              title: "Cost of Visits",
              data: [],
            },
            {
              id: 1.2,
              ids: ["REIM", "RATE", "OOP-ACCRUAL"],
              title: "Reimbursement from Insurance",
              data: [],
            },
          ],
          2: [
            {
              id: "RATE",
              title: "Negotiated Rate",
            },
            { id: "OOP-ACCRUAL", title: "Out-of-Pocket Accrual" },
          ],
          3: [
            { id: "OOP-DED", title: "Deductibles" },
            { id: "OOP-COIN", title: "Coinsurance" },
          ],
        }
      : {
          0: isOther
            ? {
                id: 0,
                title: data.after[num] && data.after[num].total.title,
                data: [],
              }
            : {
                id: 0,
                title: data.before[num] && data.before[num].total.title,
                data: [],
              },
          1: isOther && [
            {
              id: 1.1,
              title: `Unused ${type.toUpperCase()} Balance from Before ${insuranceEvent}`,
              data: new Array(cols).fill(0),
            },
            {
              id: 1.2,
              title: `${type.toUpperCase()} Accrued After ${insuranceEvent}`,
              data: new Array(cols).fill(0),
            },
          ],
        };

  let primary = titles[0],
    subTier = titles[1] || [];
  let catTier = [],
    subCatTier = [],
    detailTier = [];

  for (let i = 0; i < cols; i++) {
    let before = 0,
      after = 0;

    if (!isOther && data.before[i]) {
      before = data.before[i].total.value || data.before[i].total;

      switch (type) {
        case "premium":
          subTier[0].data[i] = before || 0;
          detailTier = findDetailRows({
            tier: detailTier,
            data: data.before[i].details,
            ref: subTier[0].id,
            num: i,
            cols: cols,
          });
          break;
        case "insurance":
          subTier[0] = findSubRow(subTier[0], data.before[i].details, i, cols);
          subTier[1] = findSubRow(subTier[1], data.before[i].details, i, cols);
          subTier[2] = findSubRow(subTier[2], data.before[i].details, i, cols);

          catTier =
            subTier[0].data.length &&
            findCatRows({
              tier: catTier,
              titles: titles[2].slice(0, 3),
              data: data.before[i].details,
              ref: subTier[0].id,
              num: i,
              cols: cols,
            });
          catTier =
            subTier[1].data.length &&
            findCatRows({
              tier: catTier,
              titles: titles[2].slice(3, 6),
              data: data.before[i].details,
              ref: subTier[1].id,
              num: i,
              cols: cols,
            });
          catTier =
            subTier[2].data.length &&
            findCatRows({
              tier: catTier,
              titles: titles[2].slice(6),
              data: data.before[i].details,
              ref: subTier[2].id,
              num: i,
              cols: cols,
            });
          break;
        case "out-of-network":
          subTier[0] = findSubRow(subTier[0], data.before[i].details, i, cols);
          // "Cost of Visits"

          subTier[1] = findSubRow(subTier[1], data.before[i].details, i, cols);
          // "Reimbursement from Insurance"

          catTier =
            subTier[1].data.length &&
            findCatRows({
              tier: catTier,
              titles: titles[2], // NEG RATE, OOP-ACCRUAL
              data:
                data.before[i].details[1] && data.before[i].details[1].extra
                  ? data.before[i].details[1].extra
                  : data.before[i].details, // RATE, OOP-ACCRUAL details
              ref: subTier[1].id,
              num: i,
              cols: cols,
            });

          subCatTier =
            catTier[1].data.length &&
            findCatRows({
              tier: subCatTier,
              titles: titles[3],
              data:
                data.before[i].details[1] && data.before[i].details[1].extra
                  ? data.before[i].details[1].extra[1].extra
                  : data.before[i].details,
              ref: catTier[1].id,
              num: i,
              cols: cols,
            });

          break;
        case "hsa":
          detailTier = findDetailRows({
            tier: detailTier,
            data: data.before[i].details,
            ref: primary.id,
            num: i,
            cols: cols,
          });
          break;
        case "hra":
          detailTier = findDetailRows({
            tier: detailTier,
            data: data.before[i].details,
            ref: primary.id,
            num: i,
            cols: cols,
          });
          break;
        default:
          break;
      }
    }

    if (((type !== "hsa" && type !== "hra") || isOther) && data.after[i]) {
      after = data.after[i].total.value || data.after[i].total;

      switch (type) {
        case "premium":
          subTier[1].data[i] = after || 0;
          detailTier = findDetailRows({
            tier: detailTier,
            data: data.after[i].details,
            ref: subTier[1].id,
            num: i,
            cols: cols,
          });
          break;
        case "hsa":
          subTier[0].data[i] = data.after[i].remaining;
          subTier[1].data[i] = after || 0;
          detailTier = findDetailRows({
            tier: detailTier,
            data: data.after[i].details,
            ref: subTier[1].id,
            num: i,
            cols: cols,
          });
          break;
        case "hra":
          subTier[0].data[i] = data.after[i].remaining;
          subTier[1].data[i] = after || 0;
          detailTier = findDetailRows({
            tier: detailTier,
            data: data.after[i].details,
            ref: subTier[1].id,
            num: i,
            cols: cols,
          });
          break;
        default:
          break;
      }
    }

    const total =
      type !== "hsa" && type !== "hra"
        ? before + after
        : isOther
        ? after + (data.after[i] ? data.after[i].remaining : 0)
        : before;

    primary.data = [...primary.data, total];
  }

  const cost = (price) => <Currency price={price} />;

  const renderTotal = (total, i, heading, isTotal, charge) => {
    return charge && charge.details ? (
      <TableCell center key={`${type}-${charge.id}-${i}`}>
        <TotalModal
          id={charge.id + i / 10}
          modalId={modalId}
          isShow={isShow}
          handleClick={handleModalLoad}
          cost={cost}
          title={charge.title}
          total={
            (total = {
              value: charge.details.value || charge.details[0].data[i].value,
              math: charge.details.math || charge.details[0].data[i].math,
            })
          }
          fontSize="12"
        />
      </TableCell>
    ) : (
      <TableCell key={`${total}-${Math.random()}-${i}`} center top={isTotal}>
        <Text arial fontSize={heading ? "18" : "14"}>
          {total > 0 && (type === "hsa" || type === "hra") && !isOther && "-"}
          {total !== 0 && isTotal
            ? cost(Math.abs(total))
            : total !== 0
            ? cost(total)
            : "-"}
        </Text>
      </TableCell>
    );
  };

  const renderDetails = (charge, i, colSpan = "1") => (
    <TableRow key={`second-${charge.id}-${i}`}>
      {colSpan === "3" ? (
        <TableCell indentWidth="20px" />
      ) : colSpan === "2" ? (
        <>
          <TableCell indentWidth="20px" />
          <TableCell indentWidth="40px" />
        </>
      ) : (
        <>
          <TableCell indentWidth="20px" />
          <TableCell indentWidth="20px" />
          <TableCell indentWidth="40px" />
        </>
      )}
      <TableHead short colSpan={colSpan}>
        <Text arial fontSize="12">
          {charge.title}
        </Text>
      </TableHead>
      {charge.details
        ? charge.details[0].data.map((total, i) => {
            return (
              <TableCell center key={`${type}-${charge.id}-${i}`}>
                <TotalModal
                  id={charge.id + i / 10}
                  modalId={modalId}
                  isShow={isShow}
                  handleClick={handleModalLoad}
                  cost={cost}
                  title={charge.title}
                  total={total}
                  fontSize="12"
                />
              </TableCell>
            );
          })
        : charge.data.map((total, i) => {
            return (
              <TableCell center key={`${type}-${charge.id}-${i}`}>
                <TotalModal
                  id={charge.id + i / 10}
                  modalId={modalId}
                  isShow={isShow}
                  handleClick={handleModalLoad}
                  cost={cost}
                  title={charge.title}
                  total={total}
                  fontSize="12"
                />
              </TableCell>
            );
          })}
    </TableRow>
  );

  const hasData = primary.data[0] !== 0 || primary.data[1] !== 0;
  return (
    hasData && (
      <>
        <TableRow>
          <TableHead colSpan="4">
            <Text arial fontSize="18">
              <Rotater
                green
                show={!showTiers.includes(primary.id)}
                onClick={() => handleShow(primary.id)}
              >
                <Icon type="down" />
              </Rotater>
              {primary.title}
            </Text>
          </TableHead>
          {!showTiers.includes(primary.id) &&
            primary.data.map((total, i) => renderTotal(total, i, true, false))}
        </TableRow>
        {showTiers.includes(primary.id) && (
          <React.Fragment>
            {!subTier.length
              ? detailTier.map((charge, i) => renderDetails(charge, i, "3"))
              : subTier.map((sub, i) => {
                  let nextRows = catTier.length
                    ? catTier.filter((next) => next.ref === sub.id)
                    : detailTier.filter((next) => next.ref === sub.id);

                  // nextRows = RATE, OOP-ACCRUAL
                  if (type === "out-of-network") {
                    return (
                      <React.Fragment key={`${type}-${sub.id}`}>
                        <TableRow>
                          <TableCell indentWidth="20px" />
                          <TableHead colSpan="3">
                            <Text arial fontSize="14">
                              {sub.title === "Reimbursement from Insurance" ? (
                                <Rotater
                                  green
                                  show={!showTiers.includes(sub.id)}
                                  onClick={() => handleShow(sub.id)}
                                >
                                  <Icon type="down" />
                                </Rotater>
                              ) : (
                                <span
                                  style={{
                                    display: "inline-block",
                                    margin: "0 13px",
                                  }}
                                ></span>
                              )}
                              {sub.title}
                            </Text>
                          </TableHead>
                          {!showTiers.includes(sub.id) &&
                            sub.data.map((total, i) =>
                              renderTotal(total, i, false, false, sub)
                            )}
                        </TableRow>
                        {showTiers.includes(sub.id) && (
                          <>
                            {nextRows.length
                              ? nextRows.map((cat, i) => {
                                  let nextSubRows = subCatTier.filter(
                                    (next) => next.ref === cat.id
                                  );

                                  return cat.details.length ? (
                                    <React.Fragment key={`${cat.title}-${i}`}>
                                      <TableRow>
                                        <TableCell indentWidth="20px" />
                                        <TableCell indentWidth="40px" />
                                        <TableHead colSpan="2">
                                          <Text arial fontSize="12">
                                            {cat.title ===
                                            "Out-of-Pocket Accrual" ? (
                                              <Rotater
                                                green
                                                show={
                                                  !showTiers.includes(cat.id)
                                                }
                                                onClick={() =>
                                                  handleShow(cat.id)
                                                }
                                              >
                                                <Icon type="down" />
                                              </Rotater>
                                            ) : (
                                              <span
                                                style={{
                                                  display: "inline-block",
                                                  margin: "0 11px",
                                                }}
                                              ></span>
                                            )}
                                            {cat.title}
                                          </Text>
                                        </TableHead>
                                        {!showTiers.includes(cat.id) &&
                                          cat.data.map((total, i) =>
                                            renderTotal(
                                              total,
                                              i,
                                              false,
                                              false,
                                              cat
                                            )
                                          )}
                                      </TableRow>

                                      {showTiers.includes(cat.id) && (
                                        <>
                                          {nextSubRows.length
                                            ? nextSubRows.map((subCat, i) => {
                                                return renderDetails(subCat, i);
                                              })
                                            : null}

                                          <TableRow>
                                            <TableCell indentWidth="20px" />
                                            <TableCell indentWidth="20px" />
                                            <TableCell indentWidth="40px" />
                                            <TableHead top>
                                              <Text arial bold fontSize="14">
                                                Total {cat.title}
                                              </Text>
                                            </TableHead>
                                            {cat.data.map((total, i) =>
                                              renderTotal(total, i, false, true)
                                            )}
                                          </TableRow>
                                        </>
                                      )}
                                    </React.Fragment>
                                  ) : null;
                                })
                              : nextRows.map((charge, i) =>
                                  renderDetails(charge, i, "2")
                                )}

                            <TableRow>
                              <TableCell indentWidth="20px" />
                              <TableCell indentWidth="20px" />
                              <TableHead top colSpan="2">
                                <Text arial bold fontSize="14">
                                  Total {sub.title}
                                </Text>
                              </TableHead>
                              {sub.data.map((total, i) =>
                                renderTotal(total, i, false, true)
                              )}
                            </TableRow>
                          </>
                        )}
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment key={`${type}-${sub.id}`}>
                        <TableRow>
                          <TableCell indentWidth="20px" />
                          <TableHead colSpan="3">
                            <Text arial fontSize="14">
                              {(catTier.length &&
                                (nextRows[0].details.length ||
                                  nextRows[1].details.length ||
                                  nextRows[2].details.length)) ||
                              (!catTier.length && nextRows.length) ? (
                                <Rotater
                                  green
                                  show={!showTiers.includes(sub.id)}
                                  onClick={() => handleShow(sub.id)}
                                >
                                  <Icon type="down" />
                                </Rotater>
                              ) : (
                                <Rotater
                                  green
                                  show={!showTiers.includes(sub.id)}
                                  onClick={() => handleShow()}
                                ></Rotater>
                              )}
                              {sub.title}
                            </Text>
                          </TableHead>
                          {!showTiers.includes(sub.id) &&
                            sub.data.map((total, i) =>
                              renderTotal(total, i, false, false)
                            )}
                        </TableRow>
                        {showTiers.includes(sub.id) && (
                          <>
                            {catTier.length
                              ? nextRows.map((cat, i) => {
                                  return cat.details.length ? (
                                    <React.Fragment key={`${cat.title}-${i}`}>
                                      <TableRow>
                                        <TableCell indentWidth="20px" />
                                        <TableCell indentWidth="40px" />
                                        <TableHead colSpan="2">
                                          <Text arial fontSize="12">
                                            <Rotater
                                              green
                                              show={!showTiers.includes(cat.id)}
                                              onClick={() => handleShow(cat.id)}
                                            >
                                              <Icon type="down" />
                                            </Rotater>
                                            {cat.title}
                                          </Text>
                                        </TableHead>
                                        {!showTiers.includes(cat.id) &&
                                          cat.data.map((total, i) =>
                                            renderTotal(total, i, false, false)
                                          )}
                                      </TableRow>
                                      {showTiers.includes(cat.id) && (
                                        <>
                                          {cat.details.map((charge, i) =>
                                            renderDetails(charge, i)
                                          )}
                                          <TableRow>
                                            <TableCell indentWidth="20px" />
                                            <TableCell indentWidth="20px" />
                                            <TableCell indentWidth="40px" />
                                            <TableHead top>
                                              <Text arial bold fontSize="14">
                                                Total {cat.title}
                                              </Text>
                                            </TableHead>
                                            {cat.data.map((total, i) =>
                                              renderTotal(total, i, false, true)
                                            )}
                                          </TableRow>
                                        </>
                                      )}
                                    </React.Fragment>
                                  ) : null;
                                })
                              : nextRows.map((charge, i) =>
                                  renderDetails(charge, i, "2")
                                )}
                            <TableRow>
                              <TableCell indentWidth="20px" />
                              <TableCell indentWidth="40px" />
                              <TableHead colSpan="2" top>
                                <Text arial bold fontSize="14">
                                  Total {sub.title}
                                </Text>
                              </TableHead>
                              {sub.data.map((total, i) =>
                                renderTotal(total, i, false, true)
                              )}
                            </TableRow>
                          </>
                        )}
                      </React.Fragment>
                    );
                  }
                })}
            <TableRow>
              <TableCell indentWidth="20px" />
              <TableHead top colSpan="3">
                <Text arial bold fontSize="14">
                  Total {primary.title}
                </Text>
              </TableHead>
              {primary.data.map((total, i) => {
                return renderTotal(total, i, false, true);
              })}
            </TableRow>
          </React.Fragment>
        )}
      </>
    )
  );
};

export default RowTotalCost;
