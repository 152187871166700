import React, { useState } from "react";
import styled from "styled-components";
import {
  Text,
  CalculateHeader as Header,
  Table,
  UploadTableBody,
  TableRow,
  TableCell,
  Spinner,
  Link,
  ButtonLink,
} from "components";

const FlexWrapper = styled.section`
  display: flex;

  justify-content: center;
  ${({ row }) =>
    row
      ? `
    flex-direction: column;
  `
      : " padding: 0;"}

  flex-wrap: wrap;
  padding: 20px 0;
`;

const InnerWrapper = styled.div`
  text-align: center;
  margin-top: 50px;
  width: 700px;

  @media (max-width: 480px) {
    width: 95%;
  }
`;

const InnerText = styled.span`
  color: rgb(198, 88, 28);
  font-weight: bolder;
`;

const BoldInnerText = styled.span`
  font-weight: bolder;
`;

const Wrapper = styled.div`
  font-family: 15px;
  color: #123;
  padding: 20px;
  padding-top: 0;
`;

const TableUploads = ({ header, body, previous, isRequestingUploads }) => {
  const [previousShow, setPreviousShow] = useState(false);

  const handleShow = () => {
    const isPreviousShow = !previousShow;
    setPreviousShow(isPreviousShow);
  };

  let report = [];
  if (body.birthParent.company !== undefined) report.push(body.birthParent);
  if (body.partner.company !== undefined) report.push(body.partner);

  let reports = [];
  previous &&
    previous.slice(1).forEach((report) => {
      reports.push(report.birthParent);
      reports.push(report.partner);
    });

  const renderHeaderName = () => (
    <>
      <TableRow header>
        <TableCell width="85px" className="mobile-hide" />
        <TableCell width="85px" center />
        <TableCell left width="125px" short className="mobile-hide" />
        <TableCell center colSpan="3" className="mobile-hide">
          predictaBill reviewed Document Details
        </TableCell>
      </TableRow>
      <TableRow header>
        <TableCell ms className="mobile-hide">
          Company/{"\n"}Organization
        </TableCell>
        <TableCell center short>
          Status
        </TableCell>
        <TableCell left width="125px" big short className="mobile-hide">
          Document Name
        </TableCell>
        <TableCell center width="85px" className="mobile-hide">
          Plan Design {"\n"} (Deductibles, etc.)
        </TableCell>
        <TableCell center width="85px" className="mobile-hide">
          Your Premium {"\n"} Contributions
        </TableCell>
        <TableCell center width="85px" className="mobile-hide">
          Company HSA {"\n"} Contributions
        </TableCell>
      </TableRow>
    </>
  );

  const renderMissingUploads = ({ company, doc }, i) => (
    <Text arial lg key={i}>
      You are missing a document for <InnerText>{company}</InnerText> that shows
      your <InnerText>{doc}</InnerText>.
    </Text>
  );

  const findMissing = ({
    company,
    generalIsComplete,
    premiumIsComplete,
    hsaIsComplete,
  }) => {
    if (!generalIsComplete && !premiumIsComplete && !hsaIsComplete)
      return {
        company: company,
        doc:
          "plan design, premium contributions, and company HSA contributions",
      };
    if (!generalIsComplete && !premiumIsComplete)
      return {
        company: company,
        doc: "plan design and premium contributions",
      };
    if (!generalIsComplete && !hsaIsComplete)
      return {
        company: company,
        doc: "plan design and company HSA contributions",
      };
    if (!premiumIsComplete && !hsaIsComplete)
      return {
        company: company,
        doc: "premium contributions and company HSA contributions",
      };
    if (!generalIsComplete)
      return {
        company: company,
        doc: "plan design",
      };
    if (!premiumIsComplete)
      return {
        company: company,
        doc: "premium contributions",
      };
    if (!hsaIsComplete)
      return {
        company: company,
        doc: "company HSA contributions",
      };
  };

  let missing = [];
  const bpMissing = findMissing(body.birthParent);
  const nbpMissing = findMissing(body.partner);
  bpMissing && missing.push(findMissing(body.birthParent));
  nbpMissing && missing.push(findMissing(body.partner));

  const countPending = ({ type }) => {
    if (type === 0) return true;
  };
  const findPending = ({ birthParent, partner }) => {
    return (
      birthParent.pamphlet.reduce((tot, curr) => {
        if (countPending(curr)) tot++;
        return tot;
      }, 0) +
      partner.pamphlet.reduce((tot, curr) => {
        if (countPending(curr)) tot++;
        return tot;
      }, 0)
    );
  };

  const pending = findPending(body);

  return (
    <>
      <Wrapper>
        <Header bigTitle={true} title="Your Uploads" icon="file-done" />
        <hr />
        <Header text="These are all the documents predictaBill is basing our advice on. Something missing? Add it." />
        <FlexWrapper>
          {isRequestingUploads ? (
            <Spinner center />
          ) : (
            <>
              {!body.birthParent.company &&
              !body.partner.company &&
              !isRequestingUploads ? (
                <>
                  <InnerWrapper>
                    <Text arial bolder lg>
                      We're sorry, we don't have any documents for your
                      optimization yet. Please upload your documents below.
                    </Text>
                  </InnerWrapper>
                  {reports[0] && (
                    <Table>
                      <tbody>
                        <TableRow>
                          <TableCell colSpan="3">
                            <Text arial italic>
                              <Link
                                green
                                previousShow={previousShow}
                                onClick={handleShow}
                              >
                                Show Previous Uploads
                              </Link>
                            </Text>
                          </TableCell>
                        </TableRow>
                        {previousShow &&
                          reports.map((report) => {
                            return <UploadTableBody report={report} />;
                          })}
                      </tbody>
                    </Table>
                  )}
                </>
              ) : (
                <>
                  <Table id="pamphlet-uploads">
                    <thead>{renderHeaderName()}</thead>
                    <tbody>
                      {report.map((report) => {
                        return <UploadTableBody report={report} />;
                      })}
                    </tbody>
                    {reports[0] && (
                      <tbody>
                        <TableRow />
                        <TableRow>
                          <Text arial italic>
                            <Link
                              green
                              previousShow={previousShow}
                              onClick={handleShow}
                            >
                              Show Previous Uploads
                            </Link>
                          </Text>
                        </TableRow>
                        {previousShow &&
                          reports.map((report) => {
                            return <UploadTableBody report={report} />;
                          })}
                      </tbody>
                    )}
                  </Table>
                  {!body.uploadIsComplete && (
                    <InnerWrapper>
                      {missing.length ? (
                        missing.map(renderMissingUploads)
                      ) : (
                        <Text arial lg />
                      )}
                      {pending > 0 && (
                        <Text arial lg italic>
                          (There {pending > 1 ? "are " : "is "}
                          <BoldInnerText>{pending}</BoldInnerText> document(s)
                          pending our review.)
                        </Text>
                      )}
                    </InnerWrapper>
                  )}
                </>
              )}
            </>
          )}
        </FlexWrapper>
        {!body.uploadIsComplete && (
          <FlexWrapper>
            <ButtonLink button withBG to="/account/upload_pamphlets" textAlign>
              Upload documents
            </ButtonLink>
          </FlexWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default TableUploads;
