import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import Text from "components/atoms/Text";
import TableFieldExternalPagination from "components/molecules/TableFieldExternalPagination";
import TableMenu from "components/molecules/TableMenu";
import { providersOptions } from "../utils/options.js";

const columns = [
  {
    title: "",
    dataIndex: "idx",
    width: 20,
    editable: false,
    fixed: "left",
  },
  {
    title: "Provider's Name",
    dataIndex: "name",
    width: 200,
    editable: true,
    inputType: "text",
    shouldCellUpdate: () => false,
    inline: true,
  },
  {
    title: "Provider's Type",
    dataIndex: "type",
    width: 100,
    editable: true,
    inputType: "select",
    options: providersOptions,
    shouldCellUpdate: () => false,
  },
  {
    title: "",
    key: "operation",
    fixed: "right",
    width: 48,
    render: (_, record) => <TableMenu record={record} />,
  },
].map((col) => {
  return {
    ...col,
    onCell: (_, idx) => ({
      index: idx,
      editable: col.editable,
      dataIndex: col.dataIndex,
      title: col.title,
      inputType: col.inputType,
      options: col.options,
      dropdownMatchSelectWidth: col.dropdownMatchSelectWidth,
      min: col.min,
      disabled: col.disabled || false,
      inline: col.inline || false,
      maxLength: col.maxLength || null,
    }),
  };
});

const handleAddButton = (table, setFieldValue, tableName) => {
  const nextIndex = table.length !== 0 ? Math.max(...table.map((element) => element.idx)) + 1 : 1;
  const obj = {
    idx: Math.floor(nextIndex),
    type: "Hospital",
    tableName: tableName,
  };
  table.push(obj);
  setFieldValue(tableName, [...table]);
};

const ProvidersList = () => {
  const { values, setFieldValue, setErrors, setTouched } = useFormikContext();
  const { providersList } = values;

  useEffect(() => {
    document.getElementById("form-content").scrollTo({ top: 0, behavior: "smooth" });
    setErrors({});
    setTouched({}, false);
  }, []);

  return (
    <>
      <Text sectionTitle big>
        Add Providers
      </Text>
      <Text fontSize={16} lineHeightUnits={"1.7em"}>
        Add the hospitals, doctors, and other providers that are your top priority, and then fill out the page so you
        can keep track of who is in-network. If you aren&apos;t sure which providers to add, we&apos;d suggest adding
        the top-ranked hospitals in your city or geographic area (for example, from{" "}
        <a href="https://health.usnews.com/best-hospitals" target="_blank" rel="noreferrer">
          U.S. News &amp; World Report{" "}
          <svg
            style={{ marginLeft: "-3px" }}
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="10"
            height="10"
            viewBox="0,0,256,256"
          >
            <g transform="">
              <g fill="#339af0">
                <g transform="scale(5.12,5.12)">
                  <path d="M33.40625,0c-0.55078,0.05078 -0.95703,0.54297 -0.90625,1.09375c0.05078,0.55078 0.54297,0.95703 1.09375,0.90625h12.96875l-20.875,20.90625c-0.29687,0.24219 -0.43359,0.62891 -0.34766,1.00391c0.08594,0.37109 0.37891,0.66406 0.75,0.75c0.375,0.08594 0.76172,-0.05078 1.00391,-0.34766l20.90625,-20.875v12.96875c-0.00391,0.35938 0.18359,0.69531 0.49609,0.87891c0.3125,0.17969 0.69531,0.17969 1.00781,0c0.3125,-0.18359 0.5,-0.51953 0.49609,-0.87891v-16.40625h-16.40625c-0.03125,0 -0.0625,0 -0.09375,0c-0.03125,0 -0.0625,0 -0.09375,0zM2,10c-0.52344,0 -1.05859,0.18359 -1.4375,0.5625c-0.37891,0.37891 -0.5625,0.91406 -0.5625,1.4375v36c0,0.52344 0.18359,1.05859 0.5625,1.4375c0.37891,0.37891 0.91406,0.5625 1.4375,0.5625h36c0.52344,0 1.05859,-0.18359 1.4375,-0.5625c0.37891,-0.37891 0.5625,-0.91406 0.5625,-1.4375v-30c0.00391,-0.35937 -0.18359,-0.69531 -0.49609,-0.87891c-0.3125,-0.17969 -0.69531,-0.17969 -1.00781,0c-0.3125,0.18359 -0.5,0.51953 -0.49609,0.87891v30h-36v-36h30c0.35938,0.00391 0.69531,-0.18359 0.87891,-0.49609c0.17969,-0.3125 0.17969,-0.69531 0,-1.00781c-0.18359,-0.3125 -0.51953,-0.5 -0.87891,-0.49609z"></path>
                </g>
              </g>
            </g>
          </svg>
        </a>{" "}
        or{" "}
        <a href="https://www.castleconnolly.com/top-hospitals" target="_blank" rel="noreferrer">
          Castle Connolly{" "}
          <svg
            style={{ marginLeft: "-3px" }}
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="10"
            height="10"
            viewBox="0,0,256,256"
          >
            <g transform="">
              <g fill="#339af0">
                <g transform="scale(5.12,5.12)">
                  <path d="M33.40625,0c-0.55078,0.05078 -0.95703,0.54297 -0.90625,1.09375c0.05078,0.55078 0.54297,0.95703 1.09375,0.90625h12.96875l-20.875,20.90625c-0.29687,0.24219 -0.43359,0.62891 -0.34766,1.00391c0.08594,0.37109 0.37891,0.66406 0.75,0.75c0.375,0.08594 0.76172,-0.05078 1.00391,-0.34766l20.90625,-20.875v12.96875c-0.00391,0.35938 0.18359,0.69531 0.49609,0.87891c0.3125,0.17969 0.69531,0.17969 1.00781,0c0.3125,-0.18359 0.5,-0.51953 0.49609,-0.87891v-16.40625h-16.40625c-0.03125,0 -0.0625,0 -0.09375,0c-0.03125,0 -0.0625,0 -0.09375,0zM2,10c-0.52344,0 -1.05859,0.18359 -1.4375,0.5625c-0.37891,0.37891 -0.5625,0.91406 -0.5625,1.4375v36c0,0.52344 0.18359,1.05859 0.5625,1.4375c0.37891,0.37891 0.91406,0.5625 1.4375,0.5625h36c0.52344,0 1.05859,-0.18359 1.4375,-0.5625c0.37891,-0.37891 0.5625,-0.91406 0.5625,-1.4375v-30c0.00391,-0.35937 -0.18359,-0.69531 -0.49609,-0.87891c-0.3125,-0.17969 -0.69531,-0.17969 -1.00781,0c-0.3125,0.18359 -0.5,0.51953 -0.49609,0.87891v30h-36v-36h30c0.35938,0.00391 0.69531,-0.18359 0.87891,-0.49609c0.17969,-0.3125 0.17969,-0.69531 0,-1.00781c-0.18359,-0.3125 -0.51953,-0.5 -0.87891,-0.49609z"></path>
                </g>
              </g>
            </g>
          </svg>
        </a>
        ).
      </Text>
      <>
        <TableFieldExternalPagination
          name="providersList"
          columns={columns}
          dataSource={providersList}
          pageSize={10}
          tableLayout="fixed"
          handleAddButton={() => handleAddButton(providersList, setFieldValue, "providersList")}
        />
      </>
    </>
  );
};

export default ProvidersList;
