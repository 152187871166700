import styled, { css } from "styled-components";
import { AlignmentEnums } from "enums/alignments.enums";

const position = ({ position }) =>
  position &&
  css`
    text-align: ${AlignmentEnums[position]};
  `;


const TableCell = styled.td`
  ${({ middle }) =>
    middle && ` width:150px; max-width: 150px; min-width: 120px;`};
  ${({ small }) => small && ` width:40px; max-width: 40px;`};
  ${({ big }) => big && ` width:180px; max-width: 180px;`};
  ${({ wide }) => wide && ` width:350px; min-width: 350px; max-width: 350px;`};
  ${({ slim }) => slim && ` width:160px; max-width: 160px`};
  ${({ ms }) => ms && ` width:100px; max-width: 100px; min-width: 80px;`};
  ${({ width }) =>
    width &&
    ` width: ${width}; max-width:${width}; min-width:calc(${width} - 20px)`};
  ${({ indentWidth }) => indentWidth && `width:${indentWidth}; min-width:${indentWidth}`}
  margin: 0;
  padding: 2px 10px;
  height: 20px;
  text-align: ${({ right, center }) =>
    right ? "end" : center ? "center" : "start"};
  ${({ white }) => white && "background: #fff;"};
  ${({ tall }) => tall && "height: min-content;"};
  ${({ padding }) => padding && `padding: ${padding};`};
  ${({ short }) =>
    short &&
    `white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`}
  ${({ orange }) => orange && "background-color: rgb(198,88,28, 0.2);"}
  ${position};
  ${({ top }) => top && `border-top: 0.75pt solid #54565a;`}
  ${({ grandTotal }) => grandTotal && `
    border-top-${grandTotal}-radius: 14px;
    border-bottom-${grandTotal}-radius: 14px;
  `}
  ${({ headerBgColor }) => headerBgColor && "background-color: #E8EEFA;"}
  ${({ bRadius }) => bRadius && `border-radius: ${bRadius};`}


  @media(max-width: 480px) {
    max-width: 150px;
  }
`;
export default TableCell;
