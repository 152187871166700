import React, { useState } from "react";
import styled from "styled-components";
import { Icon } from "@ant-design/compatible";
import { Card } from "antd";

const CardWrapper = styled(Card)`
`

const Tag = styled.div`
 height: 25px;
 width: 5px;
 margin-right: 5px;
 ${({color}) => `background-color: ${color}`}
`

const InsideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;
`

const DifferencesSection = styled.div`
  margin-left: 30px;
`

const RemovedSection = styled.div`
  margin-left: 30px;
`

const List = styled.ul`
  list-style-type: none;
`

const ServicesDiffs = ({plansServicesList}) => {

  const renderDiffs = (differences, servicesNames) => {
    return differences.map( (diff, index) => {
      return (
        <li>
          <p>{`ROW ${diff.line}:    ${servicesNames[index]}`}</p>
          <InsideWrapper>
            {
              diff.diffs.length === 0 ?
                <p>{"No changes"}</p> :
                diff.diffs.map(diff => <p>{`- ${diff}`}</p>) 
            }
          </InsideWrapper>
        </li>
      )
    })
  }

  return (
    <Card title="Differences">
      {
        plansServicesList.map( plan => 
          (
            <React.Fragment key={plan.externalId}>
              <p style={{fontWeight: 900}}>{plan.externalId}</p>
              <RemovedSection>
                {
                  plan.removedServices.length ?
                  <>
                    <p>{"Removed Services:"}</p>
                    <ul>
                      {plan.removedServices.map(el=> <li>{el}</li>)}
                    </ul> 
                  </> : <></>
                }
              </RemovedSection> 
              <DifferencesSection>
                <p>{"Services changed:"}</p>
                <List>
                  {renderDiffs(plan.diffs, plan.servicesNames)}
                </List>
              </DifferencesSection>
            </React.Fragment>
          )
        )
      }           
    </Card>
  );
};

export default ServicesDiffs;
