import safeStringify from "fast-safe-stringify";
import { decode } from "jsonwebtoken";

export async function setToken(token) {
  if (token === undefined) return null;
  await localStorage.setItem("token", safeStringify(token));
}

export async function getToken() {
  const token = await JSON.parse(localStorage.getItem("token"));
  return token;
}

export async function removeToken() {
  await localStorage.removeItem("token");
}

export const isValidToken = (token) => {
  if (!token) return false;
  const expr = decode(token).exp;
  const date = new Date().getTime() / 1000 + 50;
  return expr > date;
};

export async function getUserId() {
  const token = await getToken();
  const decodedToken = decode(token);
  return decodedToken ? decodedToken.id : null;
}
