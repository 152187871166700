import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 100px;
  min-height: calc(100vh);

  @media (min-width: 768px) {
    width: 90%;
    margin: 50px auto 100px auto;
  }
`;

const AddNetworksSteps = () => {
  const steps = [...useSelector((state) => state.plan.networkSteps)];
  const current = useSelector((state) => state.plan.current);

  return <Wrapper>{steps[current].content}</Wrapper>;
};

export default AddNetworksSteps;
