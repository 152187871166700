import React from "react";
import styled, { css, keyframes } from "styled-components";
import { Tag, Badge } from "antd";
import Icon from "../Icon";
import "./index.css";

const StyledTag = styled(Tag)`
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  height: 30px !important;
  padding: 0 20px !important;
  font-weight: bold !important;
  border-radius: 4px !important;
  color: #fff !important;
  width: 100% !important;
  font-size: 14px !important;
  background-color: #aededb !important;
  margin-bottom: 20px !important;
  border-radius: 40px !important;
  box-shadow: 0 0 1px rgb(0 0 0 / 10%) !important;
  ${({ coverage }) => coverage && `background-color: #00a19b !important;`}
  span {
    color: #fff !important;
  }
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }
`;

const CustomTag = ({ children, icon, ...props }) => {
  return (
    <Badge
      count={
        icon && (
          <div className="iconContainer">
            <Icon kid />
          </div>
        )
      }
    >
      <StyledTag closable {...props}>
        <p>{children}</p>
      </StyledTag>
    </Badge>
  );
};

export default CustomTag;
