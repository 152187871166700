import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  loadProfileRequest: ["profileId", "navigateToProfile"],
  loadProfileSuccess: ["user", "report"],
  loadProfileFailure: ["error"],

  updateProfile: ["name", "value"],

  loadReportsHeaderRequest: [],
  loadReportsHeaderSuccess: ["reports"],
  loadReportsHeaderFailure: ["isAll"],

  loadDocumentByIdRequest: ["documentId"],
  loadDocumentByIdSuccess: ["document"],
  loadDocumentByIdFailure: [],

  uploadPamphletsRequest: ["data"],
  uploadPamphletsSuccess: ["data"],
  uploadPamphletsFailure: ["error"],
  resetStoreProfile: [null]
});

const Actions = {
  Types,
  Creators
};

export default Actions;
