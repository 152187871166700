import { connect } from "react-redux";
import { CalculateInsurance } from "components";
import Action from "store/plan/actions";
import { plansBaby } from "store/formData";

const find = (value, plans, name) => {
  const data = plans.filter((plan) => plan[name] === value);
  return data || [];
};

const mapStateToProps = (state) => ({
  groups: state.plan.groups,
  plans: state.plan.insurancePlans,
  plansNetwork: state.plan.plansNetwork,
  insurance: state.plan.insurance,
  plansBaby: find(state.plan.insurance.firstBaby, plansBaby, "isFirst"),
  broaderNetwork: state.plan.insurance.broaderNetwork,
});

const mapDispatchToProps = (dispatch) => ({
  change: (name, value) => {
    dispatch(Action.Creators.changeInsurancePlan(name, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CalculateInsurance);
