import React, { useEffect, useState } from "react";
import { Text } from "components";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/plan/actions";
import styled from "styled-components";
import { useFormikContext } from "formik";
import { Popconfirm, Space, message } from "antd";
import { gtag } from "ga-gtag";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: white;
  padding: 4px;
  gap: 4px;
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.2);
  z-index: 2;
`;

const FlexWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 4px;
  align-items: center;
  padding: 0 20px;
`;

const Button = styled.div`
  background-color: ${({ active }) => (active ? "#1c355e" : "rgba(146, 146, 146, 0.37)")};
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 22px !important;
  padding: 26px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  cursor: ${({ active, type }) => (active || type === "back") && "pointer"};
  transition: 0.3s;
  &:hover {
    background-color: ${({ active }) => (active ? "#132541" : "rgba(146, 146, 146, 0.37)")};
  }
  ${({ type }) => type === "back" && "background-color: white; border: 1px solid #1c355e"};

  @media (min-width: 768px) {
    width: 200px;
  }
  @media (max-width: 768px) {
    ${({ grow }) => grow && `flex-grow: 1`}
  }
`;

const ButtonClear = styled.div`
  background-color: transparent;
  border: none;
  color: #667085;
  cursor: pointer;
  text-decoration: underline;
  font-size: 18px;
`;

window.sessionStorage.setItem("costData", "null");

const AddServicesFooter = ({ isSubmitting, report, isMasterReport }) => {
  const dispatch = useDispatch();
  const hasPartner = report && report.partner?.planIds?.length;
  const { validateForm, submitForm, isValid, setFieldValue, resetForm, setFieldTouched, setErrors, values } =
    useFormikContext();

  const { expectedTable, table, partnerTable } = values;

  const current = useSelector((state) => state.plan.current);

  let steps = JSON.parse(JSON.stringify(useSelector((state) => state.plan.serviceSteps)));
  if (isMasterReport) {
    steps = [steps[1]];
  } else if (!report.birthParent.canEditPlans && !report.partner.canEditPlans) {
    steps.splice(1, 2);
  } else if (!report.partner.canEditPlans || !report.partner.planIds?.length) {
    steps.splice(2, 1);
  } else if (!report.birthParent.canEditPlans) {
    steps.splice(1, 1);
  }
  if (
    expectedTable.every((element) => element.type === "inNetwork") ||
    table.concat(partnerTable).every((element) => element.outOfNetworkCoverage === "no")
  ) {
    steps = steps.filter((item) => item.title !== "Extra Details");
  }

  const handleSubmit = () => {
    submitForm();
  };

  const messageValidation = (res) => {
    const fields = Object.keys(res);
    if (fields.length) {
      fields.map((field) => setFieldTouched(field));
      message.error("Missing coverage for some required services. Please check dropdown.");
    }
  };

  const onValidate = (res) => {
    const fields = Object.keys(res);
    if (fields.length) {
      fields.map((field) => setFieldTouched(field));
      message.error("Missing coverage for some required services. Please check dropdown.");
    } else {
      dispatch(Actions.Creators.changeCurrent(current + 1));
      setErrors({});
    }
  };

  return (
    <Wrapper>
      <FlexWrapper>
        {current > 0 && (
          <Button type="back" onClick={() => dispatch(Actions.Creators.changeCurrent(current - 1))}>
            <Text blue center fontSize="24">
              {`< Back`}
            </Text>
          </Button>
        )}

        {current < steps.length - 1 && (
          <Button
            type="next"
            grow
            active={true}
            onClick={() => {
              validateForm().then((res) => {
                onValidate(res);
              });
            }}
          >
            <Text white center fontSize="24">
              {`Next >`}
            </Text>
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            active={true}
            disabled={isSubmitting}
            type="submit"
            grow
            onClick={() => {
              validateForm().then((res) => {
                messageValidation(res);
              });
              handleSubmit();
            }}
          >
            <Text white center fontSize="24">
              {`Done`}
            </Text>
          </Button>
        )}
      </FlexWrapper>
      {!isMasterReport && (
        <FlexWrapper>
          <Popconfirm
            title="Are you sure?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              if (current === 0) {
                setFieldValue("expectedTable", [
                  {
                    idx: 1,
                    tableName: "expectedTable",
                    expectedOrUnexpected: "expected",
                    type: "inNetwork",
                  },
                ]);
                setFieldValue(
                  "table",
                  table.map((element) => {
                    return {
                      idx: element.idx,
                      label: element.label,
                      insuranceNetwork: element.insuranceNetwork,
                      outOfNetworkCoverage: element.outOfNetworkCoverage,
                    };
                  }),
                );
                setFieldValue(
                  "partnerTable",
                  partnerTable.map((element) => {
                    return {
                      idx: element.idx,
                      label: element.label,
                      insuranceNetwork: element.insuranceNetwork,
                      outOfNetworkCoverage: element.outOfNetworkCoverage,
                    };
                  }),
                );
              } else if (current === 1) {
                setFieldValue(
                  "table",
                  table.map((element) => {
                    return {
                      idx: element.idx,
                      label: element.label,
                      insuranceNetwork: element.insuranceNetwork,
                      outOfNetworkCoverage: element.outOfNetworkCoverage,
                    };
                  }),
                );
              } else if (current === 2) {
                if (hasPartner) {
                  setFieldValue(
                    "partnerTable",
                    partnerTable.map((element) => {
                      return {
                        idx: element.idx,
                        label: element.label,
                        insuranceNetwork: element.insuranceNetwork,
                        outOfNetworkCoverage: element.outOfNetworkCoverage,
                      };
                    }),
                  );
                } else {
                  setFieldValue(
                    "expectedTable",
                    expectedTable.map((element) => {
                      return {
                        ...element,
                        reimbursements: null,
                      };
                    }),
                  );
                }
              }
            }}
          >
            <ButtonClear>{`Clear Fields`}</ButtonClear>
          </Popconfirm>
        </FlexWrapper>
      )}
    </Wrapper>
  );
};

export default AddServicesFooter;
