import React from "react";
import styled from "styled-components";
import { CalculateHeader as Header } from "components";

import { PremiumPlan, PremiumPredictedTotal, TableBills } from "containers";
const Wrapper = styled.div`
  font-family: 15px;
  color: #123;
  padding: 20px;
  padding-top: 0;
`;

const FlexWrapper = styled.section`
  display: flex;

  justify-content: center;
  ${({ row }) =>
    row
      ? `
    flex-direction: column;
  `
      : " padding: 0;"}

  flex-wrap: wrap;
`;

const InnerWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

function PremiumMain() {
  return (
    <Wrapper>
      <Header
        title="Which insurance is my best option?"
        greenText="We overlaid your health insurance plan on real pregnancy bills"
        text="to calculate your predicted out-of-pocket costs."
      />
      <FlexWrapper>
        <PremiumPlan />
        <PremiumPredictedTotal />
      </FlexWrapper>
      <InnerWrapper>
        <TableBills />
      </InnerWrapper>
    </Wrapper>
  );
}

export default PremiumMain;
