import { connect } from "react-redux";
import Calculate from "../components/organisms/PremiumPredictedTotal";

const mapStateToProps = state => {
  return {
    ...state.formData,
    isDoctorInNetwork: true,
    isDoctorInNetworkPrev: true
  };
};

export default connect(mapStateToProps)(Calculate);
