import { connect } from "react-redux";
import { TopDoctorsPage } from "components";
import Actions from "store/doctors/actions";

const mapStateToProps = (state) => ({
  ...state.doctors,
});

const mapDispatchToProps = (dispatch) => {
  return {
    load: () => {
      dispatch(Actions.Creators.loadTopDoctorsRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopDoctorsPage);
