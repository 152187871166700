import React, { useState } from "react";
import Text from "components/atoms/Text";
import { AutoComplete } from "antd";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { capitalize } from "lodash";

const Wrapper = styled.div`
  padding: 2.5% 25% 0 25%;
  background-color: #fafafa;
  @media (max-width: 1800px) {
    padding: 2.5% 20% 0 20%;
  }
  @media (max-width: 1600px) {
    padding: 2.5% 17.5% 0 17.5%;
  }
  @media (max-width: 1280px) {
    padding: 2.5% 10% 0 10%;
  }
  @media (max-width: 900px) {
    padding: 2.5% 5% 0 5%;
  }
`;

const StickyWrapper = styled.div`
  position: sticky !important;
  top: 0 !important;
  z-index: 5;
  padding: 2.5% 25% 1% 25%;
  background-color: #fafafa;
  @media (max-width: 1800px) {
    padding: 2.5% 20% 1% 20%;
  }
  @media (max-width: 1600px) {
    padding: 2.5% 17.5% 1% 17.5%;
  }
  @media (max-width: 1280px) {
    padding: 2.5% 10% 1% 10%;
  }
  @media (max-width: 900px) {
    padding: 2.5% 5% 3% 5%;
  }
  .ant-select.ant-select-auto-complete.ant-select-single {
    min-width: 320px !important;
    width: auto !important;
    max-width: 100% !important;
    font-variant: none !important;
    font-feature-settings: normal !important;
    word-spacing: 0px !important;
  }
`;

const TextWrapper = styled.div`
  @media (max-width: 900px) {
    display: none;
  }
`;

const ResultItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  .result-icon {
    width: 30px;
    height: 30px;
    border-radius: 4px;

    &.no-image {
      background-color: lightgray;
      border: 1px solid #fff;
    }

    &.empty-image {
      border: 1px solid rgba(255, 255, 255, 0);
    }
  }
`;

const PLEASE_3_CHARS = "Please enter at least 3 characters";

const encodeCompanyName = (companyName) => {
  return encodeURIComponent(companyName?.replace?.(/\s/g, "-")?.toLowerCase());
};
const capitalizeWords = (str) => {
  return str?.split(" ")?.map(capitalize).join(" ");
};

const GroupsListHeader = ({ options }) => {
  const history = useHistory();
  const username = useSelector((state) => state.profile.username);
  const [value, setValue] = useState("");
  const [optionsState, setOptionsState] = useState([]);
  const [hasExactSearch, setHasExactSearch] = useState(false);

  const { state } = useLocation();
  const { reportId, partner } = state || {};

  function isTextInputInside(targetStr, InputStr) {
    return (
      targetStr?.toUpperCase()?.indexOf(InputStr.toUpperCase()) > -1 ||
      targetStr?.toUpperCase()?.indexOf(InputStr.toUpperCase().trim().replaceAll(" ", "").replaceAll(".", "")) > -1
    );
  }

  function turnInputIntoOption(InputStr) {
    const labelStr = capitalizeWords(InputStr);
    const value = encodeCompanyName(labelStr);

    return {
      value: `[NEW-EMPLOYER]-${value}`,
      label: (
        <ResultItem>
          <div className="result-icon empty-image" src="" />
          <span>
            Add <strong style={{ fontWeight: "bold" }}>{labelStr}</strong>
          </span>
        </ResultItem>
      ),
    };
  }

  function notFoundOption() {
    return {
      value: "[NEW-CLIENT]",
      label: (
        <ResultItem>
          <div className="result-icon empty-image" src="" />
          <span>Don&apos;t see yours here?</span>
        </ResultItem>
      ),
    };
  }

  return (
    <>
      <Wrapper>
        <TextWrapper>
          <Text sectionTitle style={{ margin: 0 }}>
            What employer-sponsored health insurance do you want to compare?
          </Text>
        </TextWrapper>
      </Wrapper>
      <StickyWrapper>
        <TextWrapper>
          <Text small>Browse popular employers or add your own</Text>
        </TextWrapper>
        <AutoComplete
          options={optionsState}
          filterOption={(inputValue, option) => {
            const isTooShort = inputValue.length < 3;
            const isPlease3CharsMsg = option.value === PLEASE_3_CHARS;
            const isNewEmployer = option?.value?.startsWith?.("[NEW-EMPLOYER]");
            const isNewClient = option?.value?.startsWith?.("[NEW-CLIENT]");
            const isExactTerm = option?.terms?.some((term) => {
              return term?.toUpperCase() === inputValue?.toUpperCase();
            });

            // We can ignore characters length requirement for inputs of the exact search term
            if (isExactTerm) {
              setHasExactSearch(true); // This is to affect next iterations of the filter so we don't show the 'least 3 characters' message
              return true;
            }
            if (hasExactSearch) return false;

            // If the message is shorter then 3 characters we only return the option that tells them to add more characters
            if (isTooShort) {
              return isPlease3CharsMsg;
            }
            if (isNewEmployer) return true;
            if (isNewClient) return true;

            const hasTextInValue = isTextInputInside(option.value, inputValue);
            const hasTextInTerm = option?.terms?.some((term) => {
              return isTextInputInside(term, inputValue);
            });

            return hasTextInValue || hasTextInTerm;
          }}
          placeholder="Type to search or add an employer, union, or school"
          onChange={(value) => setValue(value)}
          onSearch={(value) => {
            // Reset at value change because each input may be a different exact value match
            setHasExactSearch(false);

            if (!value) {
              setOptionsState([]);
            } else {
              const newOptions = options.map((thisOpt) => {
                return {
                  ...thisOpt,
                  label: (
                    <ResultItem>
                      {thisOpt?.icon ? (
                        <img className="result-icon" src={thisOpt.icon} />
                      ) : (
                        <div className="result-icon no-image" />
                      )}
                      <span>{thisOpt.value}</span>
                    </ResultItem>
                  ),
                };
              });
              newOptions.push({ value: PLEASE_3_CHARS, disabled: true });
              newOptions.push(turnInputIntoOption(value));
              newOptions.push(notFoundOption());
              setOptionsState(newOptions);
            }
          }}
          onSelect={async (value) => {
            localStorage.removeItem("codeId");
            const isNewEmployer = value?.startsWith?.("[NEW-EMPLOYER]");

            if (isNewEmployer) {
              const encodedEmployer = value?.replace("[NEW-EMPLOYER]-", "");
              window.location.href = `https://predictabill.typeform.com/to/E56Aa77w?typeform-welcome=0&email=${
                username || ""
              }&employer=${encodedEmployer}`;
            }

            const otherHref = username
              ? `https://predictabill.typeform.com/to/E56Aa77w?typeform-welcome=0&email=${username || ""}`
              : "https://predictabill.typeform.com/to/MlPebEpM";

            const isNewClient = value?.startsWith?.("[NEW-CLIENT]");
            if (isNewClient) {
              window.location.href = otherHref;
            }

            const selected = options.filter((option) => option.value === value)[0];
            history.push("/groups/verification", {
              group: selected?.group || "no group",
              title: selected?.title,
              reportId,
              partner,
            });
          }}
          value={value}
          dropdownMatchSelectWidth={false}
        />
      </StickyWrapper>
    </>
  );
};

export default GroupsListHeader;
