import React from "react";
import styled from "styled-components";
import {
  Text,
  Table,
  ModalView,
  TableBody,
  CalculateFormTemplate,
  TableRow,
  TableCell,
  BillContent,
  Spinner,
} from "components";
import printBill from "services/utils/print-react";
import useModal from "services/hooks/useModal.hook";

const InnerWrapper = styled.div`
  text-align: center;
  width: 700px;
  margin: 50px auto 0;
  @media (max-width: 580px) {
    width: 95%;
  }
`;

const StyledTableCell = styled(TableCell)`
  color: #5A7184;
  font-family: circular;
`;

const Wrapper = styled.div`
  position: relative;
  min-height: 150px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  border-spacing: 0;

  /* overflow-x: scroll; */
  > * {
    display: block;
  }
`;

const TableBills = ({
  body,
  currentBill: bill,
  isDisabled,
  isSelectAll,
  changeAllSelect,
  chooseCheck,
  loadBill,
  isRequesting,
  isRequestingBills,
}) => {
  const { handleClick, isShow } = useModal();

  const handleClickLoad = (id) => {
    handleClick();
    loadBill(id);
  };

  const renderHeaderName = () => (
    <TableRow bold lineHeight={"1.2em"} borderBottom height={100} fontSize={14} header>
      <StyledTableCell small>
        <input
          type="checkbox"
          checked={isSelectAll}
          disabled={isDisabled}
          onChange={changeAllSelect}
          style={{ left: "-8px", top: "8px" }}
        />
      </StyledTableCell>
      <StyledTableCell center className="mobile-hide">
        Year
      </StyledTableCell>
      <StyledTableCell>
        Practice
      </StyledTableCell>

      <StyledTableCell short  className="mobile-hide">
        Hospital
      </StyledTableCell>
      <StyledTableCell short   className="mobile-hide">
        Procedures
      </StyledTableCell>

      <StyledTableCell center className="mobile-hide">
        Network
      </StyledTableCell>
      <StyledTableCell center className="mobile-hide">
        {`Doctor In\u2011Network`}
      </StyledTableCell>

      <StyledTableCell center className="mobile-hide">
        {`Out\u2011of\u2011Network Charges`}
      </StyledTableCell>
      <StyledTableCell middle center className="mobile-hide">
        In-Network Insurance Rates
      </StyledTableCell>

      <StyledTableCell    center>
        Total Charges
      </StyledTableCell>
      <StyledTableCell   small />
    </TableRow>
  );

  return (
    <>
      <ModalView
        title="Bill Details"
        close={handleClick}
        size="big"
        isShow={isShow}>
        <BillContent bill={bill} isRequesting={isRequesting} />
      </ModalView>

      <CalculateFormTemplate
        title="Actual Bills from Real Pregnancies"
        borderShadow
        size="x-big"
        noBorder
        padding={'25px 0'}
        headerHeight={"50px"}
      >
        <>
      {body.length === 0 && !isRequestingBills ? 
        (
          <InnerWrapper>
            <Text circular arial>
              We're sorry, we couldn't find any real pregnancy bills that match
              the criteria you entered above. Please update your selections to see
              real bills and calculate your predicted out-of-pocket costs.
            </Text>
          </InnerWrapper>
        ):
        (
        <Wrapper>
        {isRequestingBills ? (
          <div>
            <Spinner center top />
          </div>
          ) : (
            <Table id="real-bills">
            <thead >{renderHeaderName()}</thead>
            <TableBody
              bills={body}
              isDisabled={isSelectAll}
              handleShow={handleClickLoad}
              load={loadBill}
              handleCheck={chooseCheck}
              />
          </Table>
        )}
        </Wrapper>
        )}
      <div />
      </>

    </CalculateFormTemplate>
    </>

  );
};

export default TableBills;
