export const formatDelivery = deliveryTypes => {
  const entries = Object.entries(deliveryTypes);
  const newEntries = entries.map(entry => {
    return {
      value: entry[1],
      id: entry[0]
    };
  });

  newEntries.unshift({ value: "All Delivery", id: "all" });
  return newEntries;
};

export const getDoctorAndHospital = (id, doctors) => {
  const data = {
    hospital: null,
    doctor: null
  };

  const doctor = doctors.find(({ _id }) => _id === id);
  if (doctor.practice) {
    data.hospital = doctor.practice.hospital;
  }
  data.doctor = doctor;

  return data;
};
