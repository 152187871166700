import React from "react";
import styled from "styled-components";
import Text from "components/atoms/Text";

const Wrapper = styled.div`
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${(props) =>
    props.step === 1
      ? "none"
      : props.previousStep > props.step || props.previousStep === props.step
      ? "translateX(100%)"
      : "translateX(-100%)"};
  opacity: ${(props) => (props.step === 1 ? 1 : 0)};
  visibility: ${(props) => (props.step === 1 ? "visible" : "hidden")};
  transition: 600ms;
  // position: static;
`;

const ConfirmWorkEmailValidation = ({ step, previousStep, title }) => {
  return (
    <div style={{ position: "relative" }}>
      <Wrapper step={step} previousStep={previousStep}>
        <Text sectionTitle left styles={{ marginBottom: 0 }}>
          {title}
        </Text>
      </Wrapper>
    </div>
  );
};

export default ConfirmWorkEmailValidation;
