import React from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const AuthRoute = ({
  component: ComponentToRender,
  isAuth,
  match,
  destinationRoute,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        !isAuth ? (
          <ComponentToRender {...props} />
        ) : (
          <Redirect to={{ pathname: "/recommendations" }} />
        )
      }
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuth: PropTypes.bool
};

export default withRouter(AuthRoute);
