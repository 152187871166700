import React, { useState } from "react";
import styled from "styled-components";
import {
  CalculateHeader as Header,
  FormFooter,
  FormCheckboxAltV2 as Checkbox,
  FormDatepickerV2 as Datepicker,
  Icon,
} from "components";
import { merge } from "lodash";

const Wrapper = styled.section`
  display: grid;
  height: 100%;
  place-items: center;
`;

const GridWrapper = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 15px;
`;

const questions = {
  dob: "When is your date of birth?",
  considerParentPlan: "Would you consider a parent's plan?",
  graduationDate: "When are you graduating from school?",
};

const valToBool = (_val) => {
  return _val.toLowerCase() === "yes" ? true : false;
};

const calculateAge = (dob) => {
  const diff = Date.now() - dob.getTime();
  const age = new Date(diff);
  return Math.abs(age.getUTCFullYear() - 1970);
};

const OnboardingAgeEventSection = ({ _form, handleNext }) => {
  const [steps, setSteps] = useState(["dob"]);

  const step = steps[steps.length - 1];

  const [form, setForm] = useState({
    dob: _form.dob,
    under26: _form.under26,
    ageDate: _form.ageDate,
    considerParentPlan: _form.considerParentPlan,
    graduating: _form.graduating,
    graduationDate: _form.graduationDate,
    onboardingStep: _form.onboardingStep.includes("review")
      ? "review"
      : "spouse",
  });

  const handleChange = ({ name, value, text }) => {
    switch (name) {
      case "dob":
        const array = value.split("-");
        const month = array[0];
        const day = array[1];
        const year = array[2];
        const age = calculateAge(new Date(year, month, day));
        const diff = 26 - age;
        const birthday =
          diff > 0 ? `${month}/${day}/${parseInt(year) + diff}` : null;

        setForm({
          ...form,
          [name]: value,
          under26: age < 26,
          ageDate: birthday,
        });
        break;
      case "considerParentPlan":
        setForm({
          ...form,
          [name]: valToBool(text),
        });
        break;
      case "graduationDate":
        setForm({
          ...form,
          [name]: value,
        });
        break;
      default:
        setForm({
          ...form,
          onboardingStep: _form.onboardingStep.includes("review")
            ? "review"
            : "spouse",
        });
        break;
    }
  };

  const handleStep = () => {
    const updatedForm = merge({}, _form, form);
    switch (step) {
      case "dob":
        form.under26
          ? setSteps([...steps, "considerParentPlan"])
          : form.graduating
          ? setSteps([...steps, "graduationDate"])
          : handleNext(updatedForm);
        break;
      case "considerParentPlan":
        form.graduating
          ? setSteps([...steps, "graduationDate"])
          : handleNext(updatedForm);
        break;
      case "graduationDate":
        handleNext(updatedForm);
        break;
      default:
        handleNext(updatedForm);
        break;
    }
  };

  const handleBack = () => {
    const updatedSteps = steps.slice(0, -1);
    const updatedForm = merge({}, _form, {
      ...form,
      onboardingStep: _form.onboardingStep.includes("review")
        ? "review"
        : "job",
    });
    switch (steps.length) {
      case 1:
        handleNext(updatedForm);
        break;
      default:
        setSteps(updatedSteps);
        break;
    }
  };

  const active = form[step] !== null && form[step] !== undefined;

  return (
    <Wrapper>
      <Header bigTitle={true} title={questions[step]} />
      <GridWrapper>
        {step.includes("Date") || step.includes("dob") ? (
          <GridWrapper>
            <Datepicker
              name={step}
              value={form[step]}
              handleChange={handleChange}
              allowNA={step.includes("Date")}
            />
          </GridWrapper>
        ) : (
          <GridWrapper>
            <Checkbox
              name={step}
              value={form[step]}
              Icon={<Icon yesIcon small />}
              text="Yes"
              handleChange={handleChange}
            />
            <Checkbox
              name={step}
              value={form[step] === false}
              Icon={<Icon noIcon small />}
              text="No"
              handleChange={handleChange}
            />
          </GridWrapper>
        )}
      </GridWrapper>
      <FormFooter
        leftAction={handleBack}
        mainAction={handleStep}
        mainActionActive={active}
      />
    </Wrapper>
  );
};

export default OnboardingAgeEventSection;
