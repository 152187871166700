import { connect } from "react-redux";
import TableBills from "../components/organisms/TableBills";
import Actions from "store/bills/actions";

const mapStateToProps = state => ({
  ...state.bills
});

const mapDispatchToProps = dispatch => {
  return {
    changeAllSelect: () => {
      dispatch(Actions.Creators.changeAllSelect());
    },
    chooseCheck: idBill => {
      dispatch(Actions.Creators.checkedBill(idBill));
    },
    loadBill: id => {
      dispatch(Actions.Creators.loadBillByIdRequest(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableBills);
