import React from "react";
import styled from "styled-components";
import {
  AntModalView as ModalView,
  FormDate as Date,
  FormInput as Input,
  FormInputNumber as InputNumber,
  FormSelect as Select,
  Table,
  TableRow,
  TableCell,
  Text,
} from "components";

import { strToBoolTrue, strToBoolFalse } from "services/utils";
import useModal from "services/hooks/useModal.hook";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 10px;
  padding: 20px 5px;
  align-items: center;
  border: 1px solid black;
  border-radius: 40px;
`;

const CustomOptions = ({
  changeOptions,
  birthParent,
  partner,
  useOutOfNetwork,
  isDue,
  dueDate,
  firstBaby,
}) => {
  const { handleClick, isShow } = useModal();

  const handleChange = ({ target: { name, value } }) => {
    changeOptions(name, value);
  };

  const handleChangeBool = ({ target: { name, value } }) => {
    if (strToBoolTrue(value)) {
      if (name.includes("extraChildren") || name.includes("optOut isApply")) {
        handleClick();
      } else changeOptions(name, true);
    } else if (strToBoolFalse(value)) changeOptions(name, false);
  };

  return (
    <Wrapper>
      <ModalView
        close={handleClick}
        isShow={isShow}
        isInfo
        isCancel
        textButton="I understand"
        title="Unique Plan"
      >
        <Text modal arial>
          Your plan is a bit unique. If you would like to calculate the costs
          with this option in mind, please upload your pamphlet. You can
          continue on with the current calculation by selecting no to this
          option.
        </Text>
      </ModalView>
      <Table>
        <tbody style={{ overflow: "scroll" }}>
          <TableRow header>
            <TableCell wide>
              <Text arial bolder>
                Your Options
              </Text>
            </TableCell>
            <TableCell big center>
              <Text arial bolder>
                Birth Parent
              </Text>
            </TableCell>
            <TableCell big center>
              <Text arial bolder>
                Non-Birth Parent
              </Text>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell wide>
              <Text arial indent>
                How many plan options does this person have? (don't include
                Marketplace)
              </Text>
            </TableCell>
            <TableCell big>
              <InputNumber
                handleChange={handleChange}
                name="birthParent options"
                value={birthParent.optionsAmount}
                min={0}
              />
            </TableCell>
            <TableCell big>
              <InputNumber
                handleChange={handleChange}
                name="partner options"
                value={partner.optionsAmount}
                min={0}
              />
            </TableCell>
          </TableRow>

          {(birthParent.optionsAmount > 0 || partner.optionsAmount > 0) && (
            <>
              <TableRow>
                <TableCell wide>
                  <Text arial indent>
                    Name this option
                  </Text>
                </TableCell>
                <TableCell big>
                  <Input
                    handleChange={handleChange}
                    name="birthParent group"
                    value={birthParent.group}
                    placeholder="ex) Morgan Stanley"
                    disabled={!birthParent.optionsAmount}
                  />
                </TableCell>
                <TableCell big>
                  <Input
                    handleChange={handleChange}
                    name="partner group"
                    value={partner.group}
                    placeholder="ex) Union"
                    disabled={!partner.optionsAmount}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell wide>
                  <Text arial indent>
                    When is this plan's start date?
                  </Text>
                </TableCell>
                <TableCell big>
                  {birthParent.optionsAmount > 0 && (
                    <Date
                      placeholder="MM-DD-YYYY"
                      name="birthParent startDate"
                      handleChange={handleChange}
                      value={birthParent.startDate}
                    />
                  )}
                </TableCell>
                <TableCell big>
                  {partner.optionsAmount > 0 && (
                    <Date
                      placeholder="MM-DD-YYYY"
                      name="partner startDate"
                      handleChange={handleChange}
                      value={partner.startDate}
                    />
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell wide>
                  <Text arial indent>
                    Does this plan pay you if you don't take it?
                  </Text>
                </TableCell>
                {birthParent.optionsAmount > 0 ? (
                  <TableCell big>
                    <Select
                      handleChange={handleChangeBool}
                      isBool
                      name="birthParent optOut isApply"
                      value={birthParent.optOut.isApply}
                      selection={["No", "Yes"]}
                      wide="true"
                    />
                  </TableCell>
                ) : (
                  <TableCell big></TableCell>
                )}
                {partner.optionsAmount > 0 && (
                  <TableCell big>
                    <Select
                      handleChange={handleChangeBool}
                      isBool
                      name="partner optOut isApply"
                      value={partner.optOut.isApply}
                      selection={["No", "Yes"]}
                      wide="true"
                    />
                  </TableCell>
                )}
              </TableRow>

              {(birthParent.optOut.isApply || partner.optOut.isApply) && (
                <>
                  <TableRow>
                    <TableCell wide>
                      <Text arial indent>
                        How much do they pay you to not take it? this plan pay
                        you if you don't take it?
                      </Text>
                    </TableCell>
                    <TableCell big>
                      {birthParent.optOut.isApply &&
                      birthParent.optionsAmount ? (
                        <InputNumber
                          handleChange={handleChange}
                          format="currency"
                          name="birthParent optOut amount"
                          value={birthParent.optOut.amount}
                          min={0}
                        />
                      ) : (
                        <TableCell big></TableCell>
                      )}
                    </TableCell>
                    <TableCell big>
                      {partner.optOut.isApply && partner.optionsAmount && (
                        <InputNumber
                          handleChange={handleChange}
                          format="currency"
                          name="partner optOut amount"
                          value={partner.optOut.amount}
                          min={0}
                        />
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell wide>
                      <Text arial indent>
                        How often?
                      </Text>
                    </TableCell>
                    <TableCell big>
                      {birthParent.optOut.isApply && (
                        <Select
                          handleChange={handleChange}
                          name="birthParent optOut frequency"
                          value={birthParent.optOut.frequency.value}
                          selection={birthParent.optOut.frequency.data}
                          wide="true"
                        />
                      )}
                    </TableCell>
                    <TableCell big>
                      {partner.optOut.isApply && (
                        <Select
                          handleChange={handleChange}
                          name="partner OptOut frequency"
                          value={partner.optOut.frequency.value}
                          selection={partner.optOut.frequency.data}
                          wide="true"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </>
              )}
            </>
          )}

          <TableRow>
            <TableCell wide>
              <Text arial indent>
                Is the birth parent planning to use an Out-of-Network OB/GYN?
                (if not, we won't make you enter the out-of-network coverage
                numbers!)
              </Text>
            </TableCell>
            <TableCell big>
              <Select
                handleChange={handleChangeBool}
                isBool
                name="useOutOfNetwork"
                value={useOutOfNetwork}
                selection={["No", "Yes"]}
                wide="true"
              />
            </TableCell>
            <TableCell big></TableCell>
          </TableRow>

          <TableRow>
            <TableCell wide>
              <Text arial indent>
                Is this your first baby?
              </Text>
            </TableCell>
            <TableCell big>
              <Select
                handleChange={handleChangeBool}
                isBool
                name="firstBaby"
                value={firstBaby}
                selection={["No", "Yes"]}
                wide="true"
              />
            </TableCell>
            <TableCell big></TableCell>
          </TableRow>

          {firstBaby === false && (
            <TableRow>
              <TableCell wide>
                <Text arial indent>
                  Does this plan charge you extra if you add more than one
                  child?
                </Text>
              </TableCell>
              {birthParent.optionsAmount > 0 ? (
                <TableCell big>
                  <Select
                    handleChange={handleChangeBool}
                    name={"birthParent extraChildren"}
                    selection={birthParent.extraChildren.data}
                    value={birthParent.extraChildren.value}
                    isBool
                  />
                </TableCell>
              ) : (
                <TableCell big></TableCell>
              )}
              {partner.optionsAmount > 0 && (
                <TableCell big>
                  <Select
                    handleChange={handleChangeBool}
                    name={"partner extraChildren"}
                    selection={partner.extraChildren.data}
                    value={partner.extraChildren.value}
                    isBool
                  />
                </TableCell>
              )}
            </TableRow>
          )}

          <TableRow>
            <TableCell wide>
              <Text arial indent>
                Do you have a due date?
              </Text>
            </TableCell>
            <TableCell big>
              <Select
                handleChange={handleChangeBool}
                isBool
                name="isDue"
                value={isDue}
                selection={["No", "Yes"]}
                wide="true"
              />
            </TableCell>
            <TableCell big></TableCell>
          </TableRow>

          {isDue && (
            <TableRow>
              <TableCell wide>
                <Text arial indent>
                  When is your due date?
                </Text>
              </TableCell>
              <TableCell big>
                <Date
                  placeholder="MM-DD-YYYY"
                  name="dueDate"
                  handleChange={handleChange}
                  value={dueDate}
                />
              </TableCell>
              <TableCell big></TableCell>
            </TableRow>
          )}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default React.memo(CustomOptions);
