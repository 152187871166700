import { getBillsId } from "./checkFormData";

export const checkPremiumFormData = ({
  birth,
  insurance,
  insurancePlans,
  isDoctorInNetwork,
  bills,
  hospitals,
  deliveryTypes,
  doctors,
  plansNetwork,
  partnerPlansNetwork,
  roomTypes,
  birthParent,
  partner,
  isDue,
  dueDate,
  preBirthPlan,
  postBirthPlan,
  parentsPlan,
  familyPlan,
  groups,
  hsa,
  partnerGroups,
  partnerInsurancePlan,
  tiers,
  partnerTiers,
  surcharges,
  partnerSurcharges,
  partnerHsa
}) => {
  const noInsurance =
    groups.value === "No company insurance" || parentsPlan.value === "partner";
  const noPartnerInsurance =
    partnerGroups.value === "No company insurance" ||
    parentsPlan.value === "birthParent";

  const premiumFormDataSend = {
    billIds: getBillsId(bills),
    dueDate: dueDate.value,
    name: birthParent.value,
    partnerName: partner.value,
    isFirst: insurance.firstBaby,
    parentsPlan: parentsPlan.value,
    familyPlan:
      parentsPlan.value === "Separate plans" ? familyPlan.value : null,

    insurancePlanId: noInsurance ? null : plansNetwork.value,
    premiumTier: noInsurance ? 0 : tiers.value,
    surcharges: surcharges.value === null ? [] : surcharges.value,
    hsaTier: noInsurance ? 0 : hsa.value,

    partnerInsurancePlanId: noPartnerInsurance
      ? null
      : partnerPlansNetwork.value,
    partnerPremiumTier: noPartnerInsurance ? 0 : partnerTiers.value,
    partnerSurcharges:
      partnerSurcharges.value === null ? [] : partnerSurcharges.value,
    partnerHsaTier: noPartnerInsurance ? 0 : partnerHsa.value
  };

  return premiumFormDataSend;
};
