import React, { useCallback } from "react";
import { Tooltip, Text } from "components";

import styled from "styled-components";
import Spinner from "components/atoms/Spinner";
import Actions from "store/auth/actions";
import { connect } from "react-redux";
import { Link as LinkRouter } from "react-router-dom";

const Wrapper = styled.div`
  display: block;
  margin: 10px auto 20px;
`;

const Link = styled(LinkRouter)`
  text-decoration: underline;
  color: inherit;
`;

const InnerWrapper = styled.div`
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  @media (max-width: 580px) {
    margin: 0;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  font-size: 18px;
  margin-left: 8px;
  color: #657ba5;
  font-family: circular;
    @media (max-width: 580px) {
    margin-left: 5vw;
  }
`;

const Button = styled.button`
  width: 360px;
  height: 56px;
  margin: 10px 0 0 0;
  border: 1px transparent;
  border-radius: 8px;
  outline: none;
  background-color: #377b78;
  color: #fff;
  font-size: 23px;
  font-family: circular;
    font-weight: 700;
  ${({ disabled }) => disabled && `background-color: #b3bac5`}

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  @media (max-width: 580px) {
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }
`;

const ErrorText = styled(Text)`
  text-align: center;
  line-height: 1.2em;
  padding: 0;
  font-size: 16px;
  margin-top: 10px;
`;

const OptionsSection = styled.div`
  width: 360px;
  display: flex;
  margin: 10px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 580px) {
    a {
      margin-left: 5vw;
    }
  }
`;

const Input = styled.input`
  width: 360px;
  height: 56px;
  border: 1px solid #b3bac5;
  border-radius: 10px;
  outline: none;
  padding: 0 20px;
  margin: 0 8px 15px;
  color: #21355b;
  font-weight: bold;
  font-family: circular;

  font-size: 18px;
  ::placeholder {
    color: #b3bac5;
    font-family: circular;
    font-size: 18px;
  }

  @media (max-width: 580px) {
    width: 90vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }
`;

const TooltipConditionalWrapper = (props) =>
  props.condition ? (
    <Tooltip trigger="focus" placement="left" {...props}>
      {props.children}
    </Tooltip>
  ) : (
    props.children
  );

class PasswordBlockForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentWillUnmount() {
    if (this.props.error) this.props.clearError();
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.props.handleSubmit(event);
    }
  };

  render() {
    const {
      error,
      isRequest,
      submitEnable = true,
      submitButtonText,
      handleFormChange,
      inputs,
      handleSubmit,
      errorLink,
      optionsComponent,
    } = this.props;

    return (
      <Wrapper>
        <form submit={handleSubmit}>
          <FlexWrapper>
            {inputs.map((input, index) => (
              <>
                <StyledLabel for={input.name}>{input.title}</StyledLabel>
                <TooltipConditionalWrapper
                  condition={input.tooltip}
                  title={input.tooltip}
                >
                  <Input
                    autoFocus={index === 0}
                    autoComplete="off"
                    onKeyPress={this.handleKeyPress}
                    onChange={handleFormChange}
                    {...input}
                  />
                </TooltipConditionalWrapper>
              </>
            ))}
            <InnerWrapper>
              <OptionsSection>
                {optionsComponent}
                {error && (
                  <ErrorText circular indent red>
                    {error}{" "}
                    {errorLink && (
                      <Link color="red" to={{ pathname: errorLink.path }}>
                        {errorLink.text}
                      </Link>
                    )}
                  </ErrorText>
                )}
              </OptionsSection>
              <Button
                type="button"
                name="submit"
                disabled={!submitEnable}
                onClick={handleSubmit}
              >
                {isRequest ? <Spinner small /> : submitButtonText}
              </Button>
            </InnerWrapper>
          </FlexWrapper>
        </form>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearError: () => {
      dispatch(Actions.Creators.clearAuthError());
    },
  };
};

export default connect(null, mapDispatchToProps)(PasswordBlockForm);
