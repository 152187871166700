export default {
  formId: "form",
  formField: {
    // step 1
    planOptions: {
      name: "planOptions",
      requiredErrorMsg: "This field is required",
    },
    withMultiplePremiumTiers: {
      name: "withMultiplePremiumTiers",
    },
    premiumTiersNumber: {
      name: "premiumTiersNumber",
    },
    selectedPremiumTier: {
      name: "selectedPremiumTier",
    },
    premiumTier: {
      name: "premiumTier",
      requiredErrorMsg: "This field is required",
    },
    withWaiverPayment: {
      name: "withWaiverPayment",
    },
    waiverTiersNumber: {
      name: "waiverTiersNumber",
    },
    waivers: {
      name: "waivers",
    },
    selectedWaiverTier: {
      name: "selectedWaiverTier",
    },
    waiverPaymentValue: {
      name: "waiverPaymentValue",
    },
    waiverPaymentFrequency: {
      name: "waiverPaymentFrequency",
    },
    withSpouseSurcharge: {
      name: "withSpouseSurcharge",
    },
    spousalSurcharge: {
      name: "spousalSurcharge",
    },
    spousalSurchargeFrequency: {
      name: "spousalSurchargeFrequency",
    },
    costFor: {
      name: "costFor",
      requiredErrorMsg: "This field is required",
    },
    negativeValue: {
      name: "negativeValue",
    },
    outOfNetworkCoverage: {
      name: "outOfNetworkCoverage",
      requiredErrorMsg: "This field is required",
    },
    label: {
      name: "label",
      requiredErrorMsg: "This field is required",
    },
    deductibles: {
      name: "deductibles",
      requiredErrorMsg: "This field is required",
    },
    table: {
      name: "table",
      requiredErrorMsg: "All table fields are required",
    },
    importedFromTypeform: {
      name: "importedFromTypeform",
    },
    emailFromTypeform: {
      name: "emailFromTypeform",
    },
    // step 2
    frequency: {
      name: "frequency",
      requiredErrorMsg: "This field is required",
    },
    // step 3
    startDate: {
      name: "startDate",
      requiredErrorMsg: "This field is required",
    },
    showIndividualInFamilyDeductibles: {
      name: "showIndividualInFamilyDeductibles",
    },
    // step 4
    showIndividualInFamilyOOP: {
      name: "showIndividualInFamilyOOP",
    },
    OOPStarts: {
      name: "OOPStarts",
      requiredErrorMsg: "This field is required",
    },
    // step Advanced
    notSureAboutThese: {
      name: "notSureAboutThese",
    },
    showNotSoSureAboutThese: {
      name: "showNotSoSureAboutThese",
    },
    // step 5
    subjectToDeductible: {
      name: "subjectToDeductible",
    },
    // step 6
    enterAdvancedDetails: {
      name: "enterAdvancedDetails",
    },
    serviceList: {
      name: "serviceList",
    },
    // step 7
    group: {
      name: "group",
      requiredErrorMsg: "This field is required",
    },
    dontAddMoreCategories: {
      name: "dontAddMoreCategories",
    },
    networkTable: {
      name: "networkTable",
    },
    allowedLabels: {
      name: "allowedLabels",
    },
    allowedInsuranceNetworks: {
      name: "allowedInsuranceNetworks",
    },
  },
};
