import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import Text from "components/atoms/Text";
import { Space, Button } from "antd";
import {
  expectedUnexpectedOptions,
  serviceOptions,
  forOptions,
  networkOptions,
  frequencyOptions,
  drugsFrequencyOptions,
} from "../utils/options";
import TableFieldExternalPagination from "components/molecules/TableFieldExternalPagination";
import TableMenu from "components/molecules/TableMenu";
import styled from "styled-components";
import { InputChangeProvider } from "../InputChangeProvider";

const PremiumsTitlesWrapper = styled.div`
  @media (max-width: 768px) {
    padding: 28px;
  }
`;

const lowerCaseServices = serviceOptions.map((el) => el.value.toLowerCase());
const editForOptions = (household, children, isDue) => {
  let options = [
    {
      value: "Self",
      name: "Self",
    },
  ];
  if (household === "household" && children > 0) {
    options = options
      .concat([
        {
          value: "Spouse",
          name: "Spouse",
        },
      ])
      .concat(
        Array.from({ length: children }, (_, i) => {
          return {
            value: `Child ${i + 1}`,
            name: `Child ${i + 1}`,
          };
        }),
      );
  } else if (household === "household (with no spouse)" && children > 0) {
    options = options.concat(
      Array.from({ length: children }, (_, i) => {
        return {
          value: `Child ${i + 1}`,
          name: `Child ${i + 1}`,
        };
      }),
    );
  } else if (household === "household" && !children) {
    options.push({
      value: "Spouse",
      name: "Spouse",
    });
  }

  if (isDue) {
    options.push({
      value: "Baby",
      name: "Baby",
    });
  }

  return options;
};

const columns = (filteredServiceOptions, customForOptions, setFieldValue, forceUpdate) => {
  const mappedColumns = [
    {
      title: "",
      dataIndex: "idx",
      width: 30,
      editable: false,
      fixed: "left",
    },
    {
      title: "Label",
      dataIndex: "label",
      width: 80,
      editable: true,
      inputType: "text",
      shouldCellUpdate: () => forceUpdate,
      fixed: "left",
    },
    {
      title: "Expected or Unexpected?",
      dataIndex: "expectedOrUnexpected",
      width: 70,
      editable: true,
      inputType: "select",
      options: expectedUnexpectedOptions,
      shouldCellUpdate: () => forceUpdate,
    },
    {
      title: "Billed as",
      dataIndex: "billedAs",
      width: 85,
      editable: true,
      inputType: "select",
      options: filteredServiceOptions,
      dropdownMatchSelectWidth: 300,
      shouldCellUpdate: () => forceUpdate,
    },
    {
      title: "For",
      dataIndex: "for",
      width: 60,
      editable: true,
      inputType: "select",
      options: customForOptions,
      dropdownMatchSelectWidth: 200,
      shouldCellUpdate: () => forceUpdate,
    },
    {
      title: "Type",
      dataIndex: "type",
      width: 60,
      editable: true,
      inputType: "select",
      options: networkOptions,
      dropdownMatchSelectWidth: 150,
      shouldCellUpdate: () => forceUpdate,
    },
    {
      title: () => (
        <>
          <div>Cost Charged by Provider:</div>
          <div style={{ fontSize: "10px", fontStyle: "italic" }}>(Doctor, Pharmaceutical Company, or Facility)</div>
        </>
      ),
      dataIndex: ["price", "frequency", "unit"],
      width: 215,
      editable: true,
      inputType: "pricePerVisit",
      options: [frequencyOptions, drugsFrequencyOptions],
      shouldCellUpdate: () => forceUpdate,
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 48,
      render: (_, record) => <TableMenu record={record} />,
    },
  ].map((col) => {
    return {
      ...col,
      onCell: (_, idx) => {
        return {
          index: idx,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          inputType: col.inputType,
          options: col.options,
          dropdownMatchSelectWidth: col.dropdownMatchSelectWidth,
          min: col.min,
          disabled: col.disabled || false,
        };
      },
    };
  });
  if (forceUpdate) setFieldValue("forceUpdate", false);
  return mappedColumns;
};

const handleAddButton = (table, setFieldValue, tableName) => {
  const nextIndex = table.length !== 0 ? Math.max(...table.map((element) => element.idx)) + 1 : 1;
  const obj = {
    idx: Math.floor(nextIndex),
    tableName: tableName,
    expectedOrUnexpected: "expected",
    type: "inNetwork",
  };
  table.push(obj);
  setFieldValue("expectedTable", [...table]);
};

const ExpectedTable = () => {
  let { values, setFieldValue, setErrors, setTouched } = useFormikContext();
  const { household, children, isDue } = values;
  let { expectedTable, allowedServices } = values;

  useEffect(() => {
    document.getElementById("form-content").scrollTo({ top: 0, behavior: "smooth" });
    setFieldValue("forceUpdate", true);
    values.forceUpdate = true;
  }, []);

  useEffect(() => {
    expectedTable.forEach((element, index) => {
      if (lowerCaseServices.indexOf(element.billedAs) !== -1) {
        element.billedAs = serviceOptions[lowerCaseServices.indexOf(element.billedAs)].value;
      }
      if ((element.label == null || element.label === "") && element.billedAs) {
        expectedTable[index].label = element.billedAs;
      }
      if (element.billedAs === "Out-of-network Therapy") {
        expectedTable[index].type = "outOfNetwork";
      }
    });
    if (expectedTable.some((element) => !element.idx)) {
      expectedTable = expectedTable.map((item, i) => {
        return { ...item, idx: i + 1 };
      });
    }
    setFieldValue("expectedTable", expectedTable);
  }, [expectedTable]);

  return (
    <InputChangeProvider
      onSelectInputChanged={(fieldName) => {
        const expectedTableRegex = /^expectedTable\[(\d+)\]\.billedAs$/;
        const expectedTableMatch = fieldName.match(expectedTableRegex);

        if (expectedTableMatch) {
          const expectedTableIndex = expectedTableMatch[1];
          ["price", "frequency", "unit"].forEach((fieldName) => {
            const expectedTableFieldName = `expectedTable[${expectedTableIndex}].${fieldName}`;
            setFieldValue(expectedTableFieldName, "");
            setErrors({ [expectedTableFieldName]: undefined });
            setTouched({ [expectedTableFieldName]: false });
          });
        }
      }}
    >
      <PremiumsTitlesWrapper>
        <Text sectionTitle>What are you using your health insurance for this year?</Text>

        <TableFieldExternalPagination
          name="expectedTable"
          columns={columns(
            allowedServices ? allowedServices : serviceOptions,
            household ? editForOptions(household, children, isDue) : forOptions,
            setFieldValue,
            values.forceUpdate,
          )}
          dataSource={[...expectedTable]}
          pageSize={10}
          tableLayout="fixed"
          handleAddButton={() => handleAddButton(expectedTable, setFieldValue, "expectedTable")}
          scroll={{ x: "80vw" }}
        />
      </PremiumsTitlesWrapper>
    </InputChangeProvider>
  );
};

export default ExpectedTable;
