import React from "react";
import styled from "styled-components";
import { getRole, optimizeV2, updateMarketplacePreferences } from "services/api/admin.api";
import { message, Tabs, Card, Modal } from "antd";
import { getToken } from "services/utils";

import {
  PageTemplate,
  Footer,
  Header,
  CalculateHeader,
  Link,
  ReportForm,
  ReportSearch,
  // UnconfirmedReportSearch,
  UserForm,
  UserSearch,
  Spinner,
} from "components";
import AdminPlansPage from "../AdminPlansPage";
import ObamacarePage from "../ObamacarePage";
import AdminGroupsPage from "../AdminGroupsPage";

const { TabPane } = Tabs;

const Wrapper = styled.div`
  display: block;
  width: 100%;
  padding: 0 20px;
`;

const InnerWrapper = styled.div`
  width: 100%;
  padding: 20px 0;
`;

class AdminPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      userId: null,
      user: null,
      reportId: null,
      report: null,
      reports: null,
      services: [],
      birthParentPlans: [],
      partnerPlans: [],
      error: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUpdatePreferences = this.handleUpdatePreferences.bind(this);
    this.getOptimizations = this.getOptimizations.bind(this);
  }

  async getOptimizations() {
    const { report, optimizations } = this.state;
    const { preferences } = report;
    const preferenceKeys = Object.keys(preferences);
    var newOptimizations = {};

    preferenceKeys.forEach(async (preferenceKey) => {
      if (preferences[preferenceKey]) {
        const opt = await optimizeV2({ reportId: report._id, preferenceKey });
        if (opt.status === 200) {
          if (optimizations != null) {
            newOptimizations = optimizations;
            newOptimizations[preferenceKey] = opt.data.filteredResults;
          } else {
            newOptimizations[preferenceKey] = opt.data.filteredResults;
          }
          this.setState({ optimizations: newOptimizations });
        }
      }
    });
  }

  async componentDidMount() {
    document.title = "Admin - predictaBill";

    const token = await getToken();
    const response = await getRole(token);

    if (response.status === 200) {
      this.setState({ loaded: true });
    } else {
      this.props.history.push("/recommendations");
    }
  }

  async handleUpdatePreferences() {
    this.setState({ report: null });

    const { report, birthParentPlans } = this.state;
    const token = await getToken();
    const response = await updateMarketplacePreferences(report._id, birthParentPlans, token);

    if (response.status === 200) {
      const { report } = response.data;

      this.setState({ report });
      message.success("Report Updated Successfully");
    } else {
      message.error("Error Updating Report");
    }
  }

  handleChange(fields) {
    this.setState({ ...this.state, ...fields });
    if (this.state.search && this.state.loaded) {
      this.getOptimizations();
    }
  }

  render() {
    const { loaded, birthParentPlans, partnerPlans, report, reports, services, user, optimizations } = this.state;
    if (!loaded) return <Spinner loading center />;

    return (
      <PageTemplate header={<Header />} footer={<Footer />}>
        <CalculateHeader title="Admin Panel" />

        <Wrapper>
          <Tabs defaultActiveKey="1">
            <TabPane tab="User Lookup" key="1">
              <UserSearch disable={user} handleChange={this.handleChange} />

              {user && (
                <InnerWrapper>
                  <UserForm user={user} handleChange={this.handleChange} />
                </InnerWrapper>
              )}
            </TabPane>

            <TabPane tab="Recommendation Lookup" key="2">
              <InnerWrapper>
                <Card>
                  <ReportSearch disable={report} handleChange={this.handleChange} />
                </Card>
                {/* <UnconfirmedReportSearch
                  disable={reports}
                  handleChange={this.handleChange}
                /> */}
                {reports && (
                  <Card>
                    {reports.map((report) => {
                      return (
                        <div>
                          <b>{`${report._id}`}</b> <i>{`${report.title}`}</i>
                        </div>
                      );
                    })}
                  </Card>
                )}
              </InnerWrapper>

              {report && (
                <InnerWrapper>
                  <Link blue to={`/optimizeV2/${report._id}`} target="_blank">
                    View Optimization As User
                  </Link>

                  {report.birthParent.company && report.birthParent.company.includes("Marketplace") && (
                    <button onClick={this.handleUpdatePreferences}>Update Preferences For Marketplace Plans</button>
                  )}

                  <ReportForm
                    report={report}
                    birthParentPlans={birthParentPlans}
                    partnerPlans={partnerPlans}
                    optimizations={optimizations}
                    services={services}
                  />
                  <Link blue to={`/optimizeV2/${report._id}`} target="_blank">
                    View Optimization As User
                  </Link>
                </InnerWrapper>
              )}
            </TabPane>

            <TabPane tab="Add/Edit Plans" key="3">
              <InnerWrapper>
                <AdminPlansPage />
              </InnerWrapper>
            </TabPane>

            <TabPane tab="Generate Marketplace Plans" key="4">
              <InnerWrapper>
                <ObamacarePage />
              </InnerWrapper>
            </TabPane>

            <TabPane tab="Groups Lookup" key="5">
              <InnerWrapper>
                <AdminGroupsPage />
              </InnerWrapper>
            </TabPane>
          </Tabs>
        </Wrapper>
      </PageTemplate>
    );
  }
}

export default AdminPage;
