import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/report/actions";
import { CheckboxGroup } from "../components/CheckboxGroup";

export const HospitalOptionsFilter = ({ hospitalOptions }) => {
  const dispatch = useDispatch();
  const hospital = useSelector((state) => state.report.hospital);

  if (!hospitalOptions?.length) return null;

  return (
    <CheckboxGroup
      label="In-Network Hospital"
      options={hospitalOptions.map((option) => ({ value: option, label: option }))}
      onChange={(value) => {
        dispatch(
          Actions.Creators.switchState({
            hospital: value,
          }),
        );
      }}
      value={hospital}
    />
  );
};
