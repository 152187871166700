import { put, call, select, delay, fork } from "redux-saga/effects";
import Actions from "./actions";
import {
  validation,
  formSendSchema,
  premiumFormSendSchema
} from "services/validation";
import {
  checkFormData,
  checkPremiumFormData,
  getToken,
  removeField
} from "services/utils";
import { calculateForm, calculatePremiumForm } from "services/api";
import { EventOne } from "services/google";
import { removeTokenSaga } from "../auth/sagas";

const { Creators } = Actions;
const getPlan = state => state.plan;
const getBills = state => state.bills;
const getBillsCondition = state => state.bills.isRequestingBills;

export function* sendForm() {
  const plan = yield select(getPlan);
  const { body } = yield select(getBills);
  const BillsAreRequesting = yield select(getBillsCondition);
  const token = yield getToken();
  const formDataSend = yield checkFormData({ ...plan, bills: body });
  const valid = yield call(validation, formSendSchema, formDataSend);
  const data = removeField(formDataSend);
  let error = null;
  if (
    data.billIds.length === 0 &&
    formDataSend.insurancePlanId &&
    !BillsAreRequesting
  )
    error =
      "Your selected criteria are too specific – try looking at All Deliveries, All Hospitals, or All Networks";
  if (valid) {
    try {
      yield put(Creators.calculateRequest());
      yield delay(1000);
      const response = yield call(calculateForm, data, token);
      if (response.data) {
        yield fork(EventOne, "Form", "Calculate", "Success");
        yield put(Creators.calculateSuccess(response.data));
      } else yield resetStoreForm(error);
    } catch (error) {
      yield resetStoreForm();
      yield fork(EventOne, "Form", "Calculate", "Failure");
      yield removeTokenSaga(error);
    }
  } else yield resetStoreForm(error);
}

export function* resetStoreForm(error = null) {
  yield put(Creators.calculateFailure(error));
}

export function* sendPremiumForm() {
  const plan = yield select(getPlan);
  const { body } = yield select(getBills);
  const BillsAreRequesting = yield select(getBillsCondition);
  const token = yield getToken();
  const premiumFormDataSend = yield checkPremiumFormData({
    ...plan,
    bills: body
  });
  const valid = yield call(
    validation,
    premiumFormSendSchema,
    premiumFormDataSend
  );
  const data = removeField(premiumFormDataSend);
  let error = null;
  if (
    data.billIds.length === 0 &&
    premiumFormDataSend.insurancePlanId &&
    !BillsAreRequesting
  )
    error = "Your selected criteria are too specific";
  if (valid) {
    try {
      yield put(Creators.calculateRequest());
      yield delay(1000);
      const response = yield call(calculatePremiumForm, data, token);
      if (response.data) {
        yield fork(EventOne, "Form", "Calculate", "Success");
        yield put(Creators.calculateSuccess(response.data));
      } else yield resetStoreForm(error);
    } catch (error) {
      yield resetStoreForm();
      yield fork(EventOne, "Form", "Calculate", "Failure");
      yield removeTokenSaga(error);
    }
  } else yield resetStoreForm(error);
}
